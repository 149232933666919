import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const StyledContainerContent = styled.div`
  display: flex;
  gap: 70px;
`;

export const StyledPageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: ${({ bottom = null }) => (bottom ? 'end' : 'center')};
  gap: 20px;
  justify-content: ${({ align = 'center' }) => align};
  width: 100%;

  > div {
    width: ${({ single }) => (single ? 'calc(50% - 10px)' : '100%')};
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;

    ${({ align }) =>
      align === 'end' && {
        'flex-direction': 'row',
      }}
  }
`;

export const StyledButtonsWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  gap: 20px;

  @media (max-width: 860px) {
    margin-top: 0px;
  }
`;

export const StyledPatientDataDesktop = styled.div`
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const StyledPatientDataMobile = styled.div`
  @media (min-width: 1080px) {
    display: none;
  }
`;

//Nova Div TELEFONE
export const CustomDiv = styled.div`
  margin-bottom: 12px;
  margin-top: 8px;
`;
