// libs
import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../helpers';

import { IUserLoginState } from '../../reducers/login.reducer';
import { userService } from '../../services';
import { alertActions } from '../../actions';
import { LoginActions, RegisterUserInScope } from '../../actions/login.actions';

import { LoginMessages } from '../messages';

import { PageProps } from '../types';

import { MfaFooter } from '../MfaFooter';

import { Form } from './components/form';

import { redirectUserByData } from './utils';

import * as S from './style';

interface ILoginReducerProps {
  loginReducer: IUserLoginState;
}

interface MfaCodeInputProps {
  pageProps: PageProps;
}

export function MfaCodeInput({ pageProps }: MfaCodeInputProps) {
  const data = useSelector((state: ILoginReducerProps) => state.loginReducer);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  const titleMessage = useMemo<string>(() => {
    if (data.method === 'EMAIL') return ` e-mail ${data.mfaData.email}`;

    return ` número de telefone ${data.mfaData.phoneNumber}`;
  }, [data]);

  const requestValidation = useCallback(
    async (mfaCode: string) => {
      if (isLoading) return;

      setIsLoading(true);
      setHasFailed(false);

      try {
        const result = await userService.verifyMfaCode(mfaCode);
        const parsed = userService.ResolveUserData(result);

        localStorage.setItem('user', JSON.stringify(parsed));

        const redirectTo = redirectUserByData(parsed);

        if (!redirectTo) {
          pageProps.dispatch(
            alertActions.error('Usuário com perfil sem permissão.')
          );

          setIsLoading(false);

          return pageProps.dispatch(LoginActions.Reset());
        }

        pageProps.dispatch(RegisterUserInScope(parsed));

        history.push(redirectTo as string);

        pageProps.dispatch(LoginActions.Reset());
      } catch (err: any) {
        setHasFailed(true);
        setIsLoading(false);

        if (err?.message.includes('token')) {
          return pageProps.dispatch(
            alertActions.error(LoginMessages.INPUT.CANNOT_VALIDATE_CODE)
          );
        }

        if (err?.message.includes('expirado')) {
          return pageProps.dispatch(
            alertActions.error(LoginMessages.INPUT.EXPIRED_CODE)
          );
        }

        if (err?.message.includes('inválido')) {
          return pageProps.dispatch(
            alertActions.error(LoginMessages.INPUT.INVALID_CODE)
          );
        }

        if (err?.message.includes('expirou')) {
          return pageProps.dispatch(
            alertActions.error(LoginMessages.INPUT.EXPIRED_TOKEN)
          );
        }

        return pageProps.dispatch(
          alertActions.error(LoginMessages.INPUT.CANNOT_CREATE_SESSION)
        );
      }
    },
    [isLoading]
  );

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LogoContainer>
          <S.Logo />
        </S.LogoContainer>
        <S.LoginWrapper>
          <S.LoginHeader>
            <S.LoginTitle style={{ margin: '3px 0' }}>
              Validação de código
            </S.LoginTitle>
            <S.Subtitle>
              Digite o código de verificação que foi enviado para o seu
              {titleMessage}
            </S.Subtitle>
          </S.LoginHeader>
          <S.Wrapper>
            <Form
              onSubmit={requestValidation}
              pageProps={pageProps}
              isLoading={isLoading}
              hasQueryFailed={hasFailed}
            />
            <S.Divider />
            <MfaFooter pageProps={pageProps} />
          </S.Wrapper>
        </S.LoginWrapper>
      </S.LoginContainer>
      <S.CompanyImage />
    </S.Container>
  );
}
