import React from 'react';
import { debounce } from 'lodash'
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import { StyledContainer } from './styles';

import { CustomDivider, CustomText } from '../../..';

import useSelect from './hooks/useSelect';

const defaultEmptyData = 'Não há valores a serem exibidos.';

const CustomSelect = ({
  title,
  options,
  onChange,
  value,
  placeholder,
  emptyData,
}) => {
  const { useSelectData, setSelectData } = useSelect(
    value,
    placeholder,
    options
  );

  const styles = {
    control: () => ({
      display: 'flex',
      width: 358,
      height: 45,
      backgroundColor: 'white',
      borderRadius: 4,
      marginTop: 7,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      width: '300px',
      color: '#76818F',
    }),
  };

  const loadOptions = (inputValue, callback) => options(inputValue).then(results => callback(results))

  return (
    <StyledContainer>
      {title !== '' && <CustomText>{title}</CustomText>}

      <CustomDivider space={0} />

      {typeof options === 'function' ? (
        <AsyncSelect
          className='customSelect'
          cacheOptions
          defaultOptions
          styles={styles}
          loadOptions={debounce(loadOptions, 300, {
            'leading': true,
            'trailing': false
          })}
          onChange={(data) => {
            setSelectData(data);
            onChange(data);
          }}
          value={useSelectData}
          noOptionsMessage={() => emptyData || defaultEmptyData}
          loadingMessage={() => 'Carregando...'}
        />
      ) : (
        <Select
          className='customSelect'
          styles={styles}
          options={options}
          value={useSelectData}
          onChange={(data) => {
            setSelectData(data);
            onChange(data);
          }}
        />
      )}
    </StyledContainer>
  );
};

export default CustomSelect;
