import React from 'react';

import { Chip } from '@material-ui/core';

import styled from 'styled-components';

import { addAlpha } from '../../../utils/styles';

import { colors } from './constants';

const getColor = (color, applyBaseColor) => {
  return {
    ...(applyBaseColor
      ? {
          background: `${addAlpha(
            colors[color] || colors.default,
            0.1
          )} !important;`,
          color: `${colors[color] || colors.default} !important;`,
        }
      : {
          background: `${colors[color] || colors.default} !important;`,
          color: `${colors.white} !important`,
        }),
  };
};

export const MaterialChip = styled(
  ({ $color, bold, width, applyBaseColor, ...otherProps }) => (
    <Chip {...otherProps} />
  )
)`
  padding: 6px 18px !important;
  box-sizing: content-box !important;
  font-weight: ${({ bold }) => bold && 'bold !important'};
  border: ${({ outlined, $color }) =>
    outlined ? `1px solid ${colors[$color] || colors.default}` : 'unset'};
  ${({ $color, applyBaseColor }) => getColor($color, applyBaseColor)};

  .MuiChip-label {
    padding: 0px 8px;
    ${({ width }) =>
      width && {
        width: width + 'px',
      }}
  }
`;
