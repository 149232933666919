import styled from 'styled-components';

export const ContainerDropdown = styled.div`
  z-index: 0;
`;

export const Dropdown = styled.div`
  font-size: 14px;
  height: 32px;
  color: #6b7786;
  cursor: pointer;
  background: #f1f4f6;
  border-radius: 50px;
  z-index: 1;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  & span {
    padding-left: 13px;
    padding-right: 5px;
  }
`;

export const Icon = styled.div`
  margin: 6px;
`;

export const ItemDropdown = styled.ul`
  width: 170px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  padding: 7px 0px 7px 10px;
  left: 0;
  bottom: -157px;
`;

export const Item = styled.li`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;

  cursor: pointer;
  :hover {
    background: #f1f4f6;
  }
`;
