import { cloneElement } from 'react';

import useSlide from './hooks/useSlide';

const Slide = ({ children, duration = 1000, from = 'right' }) => {
  const { currentDirection, style, useDistance, useElement, childrenRef } =
    useSlide(children, from);

  return cloneElement(children, {
    ref: childrenRef,
    style: {
      ...style,
      ...(useDistance && {
        [currentDirection]: useDistance,
      }),
      opacity: useElement ? 1 : 0,
      transition: useElement ? `${duration / 1000}s` : 'unset',
    },
  });
};

export default Slide;
