import styled from 'styled-components'

export const ButtonBlueContained = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background: #378ff7;
  border-radius: 4px;
  color: #fff;
  font: normal normal 600 14px/18px;
  font-family:'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.02s ease-in-out;
  cursor: pointer;

  :hover {
    background: #247ce4;
  }
`