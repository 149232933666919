import React from 'react';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { actionComunication } from '../../actions';
import { modalAlertActions } from '../../actions';

import { history } from '../../helpers';
import ModalAlert from '../ModalAlert/index';
import { Create } from './Form';

import { ContentForm, Container, TitleForm } from './styles/formComunicados';

const styles = (theme) => ({
  main: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    width: '100%',
    fontFamily: 'Poppins',
    paddingTop: '0',
    margin: '0 auto 0 auto',
  },
  // margin: {
  //   margin: theme.spacing(),
  // },
  // withoutLabel: {
  //   marginTop: theme.spacing(3),
  // },
  textField: {
    flexBasis: 200,
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  img_inputs: {
    top: '10px !important',
    width: '20px',
    height: '20px',
  },
  img_inputs_sem: {
    width: '0 !important',
    height: '0 !important',
  },
  formulario: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  tituloPagina: {
    color: 'black',
    fontFamily: 'Arial',
    fontSize: '32px !important',
    fontWeight: 'bold',
  },
  erroValidation: {
    color: 'red',
  },
  alertBox: {
    zIndex: '10001 !important',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },

  loaderAddress: {
    position: 'relative',
    left: '280px',
    bottom: '43px',
  },

  alertTextTop: {
    marginTop: -15,
  },
  alertTextBottom: {
    marginTop: -8,
  },
  inputMobile: {
    width: '50%',
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  inputBirth: {
    width: '100%',
    padding: '8px 0 0 0',
    fontSize: '12px',
    '#::placeholder': {
      fontSize: '12px',
    },
  },
  input_phone: {
    fontSize: '12px',
    padding: '10px 0 2px 0',
    '#::placeholder': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
});
class CreateComunicado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalAlert: false,
      typeModalAlert: '',
      messageModalAlert: '',
      openDialogModal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCloseNewModal = this.handleCloseNewModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleSubmit = (values, actions) => {
    if (
      values.reception ||
      values.administration ||
      values.support ||
      values.manager
    ) {
      const { dispatch } = this.props;
      dispatch(actionComunication.insertComunication(values));
      actions.resetForm();
    }
    this.props.dispatch(
      modalAlertActions.error('Selecione um perfil para ser notificado')
    );
  };

  handleBack = () => {
    history.push('/adm');
  };

  handleCloseNewModal() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({
      openModalAlert: false,
      messageModalAlert: '',
      loadingsub: false,
    });
  }

  handleCancel() {
    this.setState({
      openDialogModal: false,
    });
  }

  handleOpen() {
    this.setState({
      openDialogModal: true,
    });
  }

  render() {
    const {
      openModalAlert,
      messageModalAlert,
      typeModalAlert,
      disabled,
      initialValues,
    } = this.props;
    return (
      <ContentForm>
        <Container>
          <ModalAlert
            open={openModalAlert}
            message={messageModalAlert}
            type={typeModalAlert}
            handleCancel={(typeModalAlert, messageModalAlert) =>
              this.handleCloseNewModal(typeModalAlert, messageModalAlert)
            }
          />
          <TitleForm>Novo aviso</TitleForm>
          <Create
            initialValues={initialValues}
            disabled={disabled}
            handleSubmit={this.handleSubmit}
          />
        </Container>
      </ContentForm>
    );
  }
}

function mapStateToProps(state) {
  const { messageModalAlert, typeModalAlert, openModalAlert } =
    state.modalAlert;

  return {
    messageModalAlert,
    typeModalAlert,
    openModalAlert,
  };
}

const connectedCreatComunicado = withStyles(styles)(
  connect(mapStateToProps)(CreateComunicado)
);
export { connectedCreatComunicado as CreateComunicado };
