import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';

const CustomSelect = ({
  name,
  customClass,
  onChange,
  value,
  dataSource,
  placeholder,
  label,
  disable,
  width = '100%',
  marginTop = '0px',
  marginBottom = '0px',
  showLabel = true,
}) => {
  return (
    <>
      <FormControl style={{ width, marginTop, marginBottom }}>
        {/* todo pass Custom css Class */}
        <InputLabel>{label}</InputLabel>
        <Select
          placeholder='teste'
          label='Status'
          disabled={disable}
          input={
            <Input
              placeholder='teste'
              id='select-multipl  label,
            disable,e-placeholder'
            />
          }
          name={name}
          className={customClass}
          onChange={onChange}
          value={value}
        >
          <MenuItem value=''>
            {/* todo pass Custom css Class */}
            <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
              {placeholder}
            </em>
          </MenuItem>
          {dataSource &&
            dataSource.map((element, id) => (
              <MenuItem key={id} value={element.value}>
                {element.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  customClass: PropTypes.string,
  onChange: PropTypes.func,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disable: PropTypes.bool,
};

export default CustomSelect;
