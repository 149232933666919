// libs
import { useCallback, useEffect } from 'react';
import { MenuItem, Collapse } from '@material-ui/core';

import * as S from './styles';

interface CancelScheduleFormProps {
  reason: string;
  observations: string;
  reasonError: boolean;
  observationError: boolean;
  changeReason: (event: string) => void;
  changeObservations: (event: string) => void;
  changeReasonError: (event: boolean) => void;
  changeObservationsError: (event: boolean) => void;
  isOthers?: boolean;
}

export function CancelScheduleForm({
  changeObservations,
  changeReason,
  reason,
  observations,
  reasonError,
  observationError,
  changeObservationsError,
  changeReasonError,
  isOthers,
}: CancelScheduleFormProps) {
  const renderSelectValue = useCallback(
    (value: any) => {
      if (!reason || !value) return 'Selecione uma opção';

      return value;
    },
    [reason]
  );

  const handleChangeReason = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changeReason(event.target.value);
      changeReasonError(false);
      changeObservationsError(false);
    },
    []
  );

  const handleChangeObservations = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changeObservations(event.target.value);
      changeObservationsError(false);
    },
    []
  );

  return (
    <S.Wrapper>
      <S.Fieldset>
        <S.LabelWrapper htmlFor='reasonSelect'>Motivo</S.LabelWrapper>
        <S.StyledSelect
          $hasNoValue={!reason}
          id='reasonSelect'
          name='reasonSelect'
          value={reason}
          onChange={handleChangeReason}
          InputProps={{
            value: reason,
          }}
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            displayEmpty: true,
            renderValue: renderSelectValue,
          }}
          error={reasonError}
          helperText={reasonError && 'Este campo é obrigatório'}
          hiddenLabel
          select
        >
          {cancelReasonOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </S.StyledSelect>
      </S.Fieldset>

      <Collapse in={isOthers}>
        <S.Fieldset>
          <S.LabelWrapper htmlFor='reasonDetails'>Observações</S.LabelWrapper>
          <S.StyledSelect
            id='reasonDetails'
            name='reasonDetails'
            placeholder='Digite o motivo do cancelamento'
            value={observations}
            error={observationError}
            onChange={handleChangeObservations}
            helperText={observationError && 'Este campo é obrigatório'}
            minRows={4}
            hiddenLabel
            multiline
          />
        </S.Fieldset>
      </Collapse>
    </S.Wrapper>
  );
}

const cancelReasonOptions = [
  { label: 'Outros', value: 'Outros' },
  {
    label: 'Desistência sem Reagendamento - Atraso Profissional',
    value: 'Desistência sem Reagendamento - Atraso Profissional',
  },
  {
    label: 'Desistência sem Reagendamento - Profissional Indisponível',
    value: 'Desistência sem Reagendamento - Profissional Indisponível',
  },
  {
    label: 'Desistência com Reagendamento - Atraso Profissional',
    value: 'Desistência com Reagendamento - Atraso Profissional',
  },
  {
    label: 'Desistência com Reagendamento - Profissional Indisponível',
    value: 'Desistência com Reagendamento - Profissional Indisponível',
  },
];
