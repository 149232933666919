import styled, { css } from 'styled-components';

export const ContainerAlignCenter = styled.div`
  text-align: center;
`;

export const ContainerForm = styled.div`
  background-color: #f9f9f9;

  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

export const ContainerTextarea = styled.div`
  margin: 10px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-around;

  text-align: center;
`;

export const ContainerBottomButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 10px;
`;

export const LeftAreaButton = styled.div`
  width: 100%;
  border: 1px solid #e5e5e5;
  border-right: unset;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  background-color: ${({ selected }) => (selected ? '#f9f9f9' : '#e1e1e1')};

  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 1px solid #f9f9f9;
      border-right: 1px solid #e5e5e5;

      cursor: unset;
    `}

  ${({ selected }) =>
    !selected &&
    css`
      transition: all 0.3s;

      &:hover {
        background-color: #f5f5f5;
      }
    `}
`;

export const RightAreaButton = styled.div`
  width: 100%;

  border: 1px solid #e5e5e5;
  border-left: unset;

  cursor: pointer;

  background-color: ${({ selected }) => (selected ? '#f9f9f9' : '#e1e1e1')};

  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 1px solid #f9f9f9;
      border-left: 1px solid #e5e5e5;

      cursor: unset;
    `}

  ${({ selected }) =>
    !selected &&
    css`
      transition: all 0.3s;

      &:hover {
        background-color: #f5f5f5;
      }
    `}
`;

export const SliderContainer = styled.div`
  overflow-y: hidden;
  padding-top: ${({ show }) => (show ? '10px' : '0px')};

  transition: all 1s;

  max-height: ${({ show }) => (show ? '500px' : '0px')};
`;

export const RadioGroupContainer = styled.div`
  padding: 10px 10px 0 10px;
`;
