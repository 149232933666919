import styled from 'styled-components';

//import centralImage from '../img/Einstein.png';
import centralImage from '../img/einstein.jpg';
import logoCentral from '../img/logo_central.svg';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const LoginContainer = styled.div`
  width: 40%;
  height: 100%;
  background: #f0f4f7;

  @media (max-width: 1010px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 60%;
  }

  @media (max-width: 680px) {
    width: 70%;
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  position: relative;
  top: 6%;
  left: 16%;
  width: 283px;

  @media (max-width: 580px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Logo = styled.div`
  background-image: url(${logoCentral});
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 133px 34px;
  background-repeat: no-repeat;
  width: 133px;
  height: 34px;
`;

export const LoginWrapper = styled.div`
  position: relative;
  top: 18%;
  left: 16%;
  width: 283px;
  box-shadow: 1px solid black;

  @media (max-width: 580px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 1368px) {
    top: 8%;
  }
`;

export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 80px;
`;

export const LoginTitle = styled.h1`
  font: normal normal 600 24px/32px Inter;
`;

export const Subtitle = styled.h1`
  width: 100%;
  text-align: left;
  font: normal normal normal 14px/22px Inter;
  color: #5c5a5a;
  margin-top: 4px;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 304px;
`;

export const Label = styled.label`
  font: normal normal 600 13px/15px Inter;
  text-transform: uppercase;
`;

export const InputLabel = styled.label`
  text-align: left;
  font: normal normal 600 13px/15px Inter;
  color: #000000;
  margin: 5px 0;
  text-transform: uppercase;
`;

export const InputText = styled.input`
  height: 38px;
  padding: 0 15px;
  border: none;
  outline: none;
  border: 1px solid #dfe3e8;

  ${(props) =>
    props.isWithError &&
    `
  border: 1px solid #f00;
  `}

  border-radius: 4px;
  color: #6b7786;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  font: normal normal normal 14px/15px Inter;

  :focus {
    border-color: #2699fb;
    box-shadow: 0 0 6px #3381ee33;
    cursor: text;
  }
`;

export const ContainerPassword = styled.div`
  background: #fff !important;
  width: 304px;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
`;

export const InputTextPassword = styled.input`
  height: 38px;
  padding: 0 15px;
  border: none;
  outline: none;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  color: #6b7786;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  font: normal normal normal 14px/15px Inter;

  :focus {
    border-color: #2699fb;
    box-shadow: 0 0 6px #3381ee33;
    cursor: text;
  }
`;

export const TogglePasswordVisibleBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 20px;
  transform: translateY(50%);
  outline: 0;
  border: none;
  background: transparent;
  color: #999;
  width: 24px;
  cursor: pointer;

  :hover {
    color: #888;
  }

  > svg {
    fill: #9ba8ba;
    min-width: 24px;

    > g > line {
      stroke: #9ba8ba;
    }
  }
`;

export const Eye = styled.img`
  width: 10%;
  cursor: pointer;
  padding-right: 10px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 6px;
  margin-bottom: 12px;
  font-family: 'Inter';
  width: 100%;
`;

export const LabelBlue = styled.label`
  cursor: pointer;
  text-align: right;
  font: normal normal 600 12px/22px Inter;
  letter-spacing: 0px;
  color: #378ff7;
  opacity: 1;
`;

export const ButtonBlueContained = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background: #378ff7;
  border-radius: 4px;
  color: #fff;
  font: normal normal 600 14px/18px Inter;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.02s ease-in-out;
  cursor: pointer;

  :hover {
    background: #247ce4;
  }
`;

export const ButtonBlueOutlined = styled.button`
  cursor: pointer;
  outline: none;
  border: 1px solid #378ff7;
  background: transparent;
  border-radius: 4px;
  text-transform: uppercase;
  color: #378ff7;
  width: 100%;
  height: 40px;
  font: normal normal 600 14px/18px Inter;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: #247ce4;
    color: #fff;
  }
`;

export const LabelError = styled.label`
  width: 100%;
  text-align: left;
  font: normal normal normal 14px/18px Inter;
  color: #fe1d14;
  opacity: 1;
  display: block;

  :nth-child(0) {
    margin-top: 10px;
  }
`;

export const CompanyImage = styled.div`
  width: 60%;
  height: 100%;
  background-image: url(${centralImage});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1010px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 40%;
  }

  @media (max-width: 680px) {
    width: 30%;
  }

  @media (max-width: 580px) {
    display: none;
  }
`;
