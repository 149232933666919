import httpClient from '../../../utils/httpClient';

const resource = 'scheduling-pause';

const schedulingPause = async (body) => {
  const response = await httpClient.POST(`${resource}`, { body });

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

export default {
  schedulingPause,
};
