import {
  HttpClientAdapter,
  HttpRequest,
  HttpResponse,
} from '../../infra/adapters';

export class HttpClient {
  httpClient: HttpClientAdapter;
  httpRequest: HttpRequest;
  constructor(httpClient: HttpClientAdapter, httpRequest: HttpRequest) {
    this.httpClient = httpClient;
    this.httpRequest = httpRequest;
  }

  async handle<T, B>(body?: B): Promise<HttpResponse<T>> {
    return await this.httpClient.request({
      url: this.httpRequest.url,
      method: this.httpRequest.method,
      timeout: this.httpRequest.timeout,
      headers: this.httpRequest.headers,
      data: body,
    });
  }
}
