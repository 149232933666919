import React from 'react';
import { CssBaseline } from '@material-ui/core';
import MenuPainel from '../components/MenuPainelNew';
import AdmAcionamentoEditarSuporte from '../components/Lib/Forms/FormSupport/index';
import { ChangePassword } from '../components/ChangePassword';

class AcionamentoSupport extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <ChangePassword />
        <MenuPainel stepper=''>
          <AdmAcionamentoEditarSuporte />
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = AcionamentoSupport;
export { connectedAssinar as AcionamentoSupport };
