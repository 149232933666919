import { cFinalizadosConstants } from '../constants';
import { cFinalizadosService } from '../services';
import { alertActions } from '.';

export const cFinalizadosActions = {
  getCancelamentosFinalizados,
};

function getCancelamentosFinalizados() {
  return (dispatch) => {
    dispatch(request());

    cFinalizadosService
      .GetCancelamentosFinalizados()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cFinalizadosConstants.CANCELAMENTO_FINALIZADOS_REQUEST };
  }

  function success(cFinalizados) {
    return {
      type: cFinalizadosConstants.CANCELAMENTO_FINALIZADOS_SUCCESS,
      cFinalizados,
    };
  }

  function failure(error) {
    return {
      type: cFinalizadosConstants.CANCELAMENTO_FINALIZADOS_FAILURE,
      error,
    };
  }
}
