import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
		font-size: 100%;
		font-family: 'Inter', sans-serif!important;
	}
	body {
		background-color: #f1f4f6;
	}
	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	.rubik-medium{
		font-weight: bold;
		color:#2E384D;
	}
	.rubik-upper{
		color:#a7abb2;
		text-transform: uppercase;
		font-size:0.875em;
		letter-spacing: 1.12px;
	}
	.rubik-regular{
		color:	#2E384D;
		font-size:0.875em;
	}
`;

export default GlobalStyles;

// import { createGlobalStyle } from 'styled-components';
// import rubikMedium from '../assets/fonts/rubik-medium-webfont.woff';
// import rubikRegular from '../assets/fonts/rubik-regular-webfont.woff';

// const GlobalStyle = createGlobalStyle`
// /* http://meyerweb.com/eric/tools/css/reset/
//    v2.0 | 20110126
//    License: none (public domain)
// */

// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed,
// figure, figcaption, footer, header, hgroup,
// menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	font-size: 100%;
// 	font: inherit;
// 	vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure,
// footer, header, hgroup, menu, nav, section {
// 	display: block;
// }
// body {
// 	line-height: 1;
// }
// ol, ul {
// 	list-style: none;
// }
// blockquote, q {
// 	quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
// 	content: '';
// 	content: none;
// }
// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }
// font-family: 'rubikregular';
// font-size:12px;

// @font-face {
//     font-family: 'rubikmedium';
//     src:url(${rubikMedium}) format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'rubikregular';
// 	src:url(${rubikRegular}) format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// .rubik-medium{
// 	font-family: 'rubikmedium';
// 	font-weight: bold;
// 	color:#2E384D;	font-size:0.9em;
// 	@media (max-width: 1369px) {
//     font-size: small;
//   }
//   }

// .rubik-upper{
// 	color:#a7abb2;
// 	text-transform: uppercase;
// 	letter-spacing: 1.12px;
// 	font-family: 'rubikregular';
// 	font-size: small;

// }
//  .rubik-regular{
// 	font-family: 'rubikregular';
// 	color:	#2E384D;
// 	font-size:0.9em;
//  }

// `;

// export default GlobalStyle;
