import { useEffect, useState, useRef } from 'react';

const useTable = (data, onScroll) => {
  const loaderRef = useRef(null);

  const [useLoadMore, setLoadMore] = useState(false);

  const intersectionObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  };

  const scrollEndObserverCallback = async (payload) => {
    const [intersection] = payload;
    const scrollEnd = intersection.isIntersecting;

    if (scrollEnd) {
      setLoadMore(true);
      await onScroll(data);
      setLoadMore(false);
    }
  };

  const observer = new IntersectionObserver(
    scrollEndObserverCallback,
    intersectionObserverOptions
  );

  const isTableRendered = () => !!(loaderRef.current && data?.length);

  useEffect(() => {
    const isTableMounted = isTableRendered();
    const pageHasScroll =
      document.body.scrollHeight >= document.body.clientHeight;

    const shouldObserveScroll = isTableMounted && pageHasScroll && onScroll;

    if (shouldObserveScroll === true) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (shouldObserveScroll) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [data]);

  return {
    useLoadMore,
    loaderRef,
  };
};

export default useTable;
