import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';

export const clearIntervalTreatment = (interval) => {
  try {
    clearInterval(interval);
    console.debug('Set Interval finalizado');
  } catch (err) {
    console.error(err);
  }
};

export const dateTreatment = async (time) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const timeAttendance =
    (await time) ?? dayjs().tz('America/Sao_Paulo').format().toString();
  console.debug('timeAttendance', timeAttendance);
  return timeAttendance;
};

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format('DD-MM-YYYY HH:MM');
  return buildDate;
};

/**
 * Function returning the actual date like "2021-02-04"
 */
export const getFormatedDate = () => {
  let objDate = new Date();
  let day = objDate.getDate().toString().padStart(2, '0');
  let month = (objDate.getMonth() + 1).toString().padStart(2, '0'); //+1 pois no getMonth Janeiro começa com zero.
  let year = objDate.getFullYear();
  return year + '-' + month + '-' + day;
};

/**
 * Function to scan array of objects looking for duplicates
  @param arr array of objects
  @param prop key of the object that will serve as a reference for comparison
  @return object without repeats and with counter with number of repeats

   */

export const countAndSingle = (arr, prop) => {
  const accumulated =
    arr &&
    arr.reduce(
      (acum, o) => {
        const key = JSON.stringify(o[prop]);
        acum.ctrl[key] = acum.ctrl[key] || { ...o, count: 0 };
        if (acum.ctrl[key].count++ === 0) acum.list.push(acum.ctrl[key]);
        return acum;
      },
      { ctrl: {}, list: [] }
    ).list;

  return accumulated;
};

/**
 * Function to transform object do array
  @param obj minor date
  @return array
   */
export const objectToarray = (obj) => Object.values(obj);

export const actualAfterOneDate = (date) => {
  const actualDate = moment();
  const selectedDate = moment(date).format('DD/MM/YYYY HH:mm');
  if (!selectedDate.includes('Data inválida')) {
    let days = moment(date).diff(actualDate, 'days');
    return days > 0;
  }
  return false;
};

/**
 * Function to compare dates of days after another
  @param before minor date
  @param after bigger date
  @return bolean
   */

export const compareIsAfter = (lowest, biggest) => {
  let actualDate = moment(lowest).format('YYYY-MM-DD');
  let selectedDate = moment(biggest).format('YYYY-MM-DD');
  if (
    !selectedDate.includes('Data inválida') &&
    !actualDate.includes('Data inválida')
  )
    return moment(selectedDate).isAfter(actualDate, 'day');
};

/**
 * Function to compare dates of days after another and same day
  @param before minor date
  @param after bigger date
  @return bolean
   */

export const compareIsSameOrAfter = (lowest, biggest) => {
  let actualDate = moment(lowest).format('YYYY-MM-DD');
  let selectedDate = moment(biggest).format('YYYY-MM-DD');
  if (
    !selectedDate.includes('Data inválida') &&
    !actualDate.includes('Data inválida')
  )
    return moment(selectedDate).isSameOrAfter(actualDate, 'day');
};

/**
 * Function to execute some function pass in callback after time passed and removes istself from memory
 * useful also with autocompletes consuming api
  @param callback function !
  @param wait time in miliseconds to wait
  @return bolean
   */

//todo pass more args
export const debounce = (callback, wait) => {
  const timer = setTimeout(() => {
    callback();
  }, wait);
  //removes timer from memory
  return () => clearTimeout(timer);
};

export const isoToDatePTBR = (str) => moment(str).utc().format('DD/MM/YYYY');
export const format = (str) =>
  moment(str).utc().local().format('HH:mm - DD/MM/YYYY');
export const formatSemHora = (str) =>
  moment(str).utc().local().format('DD/MM/YYYY');
export const formatDateHour = (str) =>
  moment(str).utc().local().format('DD/MM/YYYY - HH:mm');
export const addDaysToDate = (date, days) => moment(date).add(days, 'day');
export const addDaysToActualDate = (days) => moment().add(days, 'day');
export const objectKeysToString = (obj) => Object.keys(obj).toString();
export const objectIsEmpty = (obj) => Object.keys(obj).length === 0;
export const stringSplit = (str, char, index) => str.split(char)[index];

export const isoToDate = (str) => moment(str).utc().format('YYYY-MM-DD H:mm ');

//stackoverflow.com/questions/13146418/find-all-the-days-in-a-month-with-date-object

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */

export const getDaysInMonth = (month, year) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const formateDatesPerMonth = (dates) =>
  dates &&
  dates.map((element) => {
    return moment(element).format('YYYY-MM-DD');
  });

export const objDate = () => {
  let objDate = new Date();
  objDate.date = objDate.getDate();
  objDate.month = objDate.getMonth();
  objDate.year = objDate.getFullYear();
  return objDate;
};
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;
export const getDate = (str) => moment(str, 'DD/MM/YYYY').format('MM/DD/YYYY');

export const getAge = (str) => {
  const date = getDate(str);
  if (date.includes('Invalid date')) return;
  return moment(new Date()).diff(date, 'years');
};

export const isMinor = (str) => {
  if (str === undefined) return;
  let age = getAge(str);
  return age <= 17;
};
export const floatToString = (param) =>
  typeof param === 'number' ? param?.toString().replace(/\D+/g, '') : param;
