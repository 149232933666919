import { REQUEST_TIMEOUT } from '../constants';

export const passwordService = {
  changePassword,
};

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

function changePassword(password, user, recaptchaToken) {
  const { oldPassword, newPassword, confirmedNewPassword } = password;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.token,
      'g-recaptcha': recaptchaToken,
    },
    body: JSON.stringify({
      id: user.user.id,
      login: user.user.login,
      oldPassword: oldPassword,
      newPassword: newPassword,
      newPasswordConfirm: confirmedNewPassword,
    }),
  };
  return fetchWithTimeout(
    `${URL_CONECTA}/servico/login/changepasswordV2`,
    requestOptions,
    REQUEST_TIMEOUT
  )
    .then(handleResponse)
    .then((message) => {
      return message;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 511) {
        // auto logout if 401 response returned from api
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

function fetchWithTimeout(url, requestOptions, timeout) {
  return new Promise((resolve, reject) => {
    let timer = setTimeout(
      () => reject(new Error('Request timed out')),
      timeout
    );
    fetch(url, requestOptions)
      .then(
        (response) => resolve(response),
        (err) => reject(err)
      )
      .catch((err) => {
        reject(err);
      });
  });
}
