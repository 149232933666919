import React from 'react';
import MenuPainel from '../components/MenuPainel';
import PaperForm from '../components/PaperForm';
import { PaAcionamentos } from '../components/PaAcionamentos';
import { ChangePassword } from '../components/ChangePassword';
import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';
class PaAcionamentosList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        <MenuPainel>
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<PaAcionamentos />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = PaAcionamentosList;
export { connectedAssinar as PaAcionamentosList };
