import { editAcionamentoConstants } from '../constants';

export function editAcionamento(state = { editAcionamento: {} }, action) {
  switch (action.type) {
    case editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS:
      return {
        type: 'success',
        editAcionamento: action.acionamento,
      };
    case editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        editAcionamento: {},
      });
    case editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    case editAcionamentoConstants.ACIONAMENTO_EDIT_CLEAR:
      return Object.assign({}, state, {
        type: 'clear',
        editAcionamento: {},
      });

    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
