// libs
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, type ModalTemplateProps } from '../Modal';

import { CancelScheduleForm } from './form';
import { acionamentoActionsVonage, alertActions } from '../../../actions';

export interface CancelScheduleFormEvent {
  reason: string;
  observations: string;
}

interface CancelScheduleModalProps extends ModalTemplateProps {
  selectedAttendance?: Record<string, any> | null;
}

export function CancelScheduleModal({
  open,
  onClose,
  selectedAttendance,
}: CancelScheduleModalProps) {
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [observations, setObservations] = useState('');
  const [reasonError, setReasonError] = useState(false);
  const [observationError, setObservationError] = useState(false);

  const isOthers = reason === 'Outros';

  const [isCancelScheduleLoading, setIsCancelScheduleLoading] = useState(false);
  const [cancelScheduleError, setCancelScheduleError] = useState<
    'request' | 'invalid' | null
  >(null);

  const resetModalStatus = () => {
    setReason('');
    setObservations('');
    setReasonError(false);
    setObservationError(false);
    setIsCancelScheduleLoading(false);
    setCancelScheduleError(null);
  };

  const handlePromiseTreatment = () => {
    resetModalStatus();
    onClose();
  };

  const handleCancelScheduleSuccess = () => {
    handlePromiseTreatment();
    dispatch(alertActions.success('Agendamento Cancelado com sucesso'));
  };

  const handleCancelScheduleError = () => {
    setCancelScheduleError('request');
    handlePromiseTreatment();
    dispatch(alertActions.error('Falha ao cancelar o agendamento.'));
  };

  const handleSubmit = () => {
    if (cancelScheduleError) return;

    if (!reason) {
      return setReasonError(true);
    }

    if (isOthers && !observations) {
      return setObservationError(true);
    }

    if (!selectedAttendance?.isScheduled)
      return setCancelScheduleError('invalid');

    setIsCancelScheduleLoading(true);

    const objectRequest = {
      reasonSelected: reason,
      reasonInputOther: observations,
      ...selectedAttendance,
    };

    acionamentoActionsVonage.cancelScheduleAction(
      objectRequest,
      handleCancelScheduleSuccess,
      handleCancelScheduleError
    );
  };

  const handleClose = () => {
    if (isCancelScheduleLoading) return;

    resetModalStatus();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Deseja cancelar o agendamento?'
      primaryAction={{
        label: 'Sim, cancelar',
        variant: 'danger',
        type: 'submit',
      }}
      secondaryAction={{
        label: 'Voltar',
        variant: 'primaryHighlight',
        type: 'reset',
      }}
      onReset={handleClose}
      onSubmit={handleSubmit}
      renderAsError={!selectedAttendance?.isScheduled}
      errorMessage='O acionamento possui dados inválidos e não para ser cancelado, por favor contate o suporte.'
      isSubmitLoading={isCancelScheduleLoading}
    >
      <CancelScheduleForm
        reason={reason}
        changeReason={setReason}
        reasonError={reasonError}
        observationError={observationError}
        observations={observations}
        changeObservations={setObservations}
        changeReasonError={setReasonError}
        changeObservationsError={setObservationError}
        isOthers={isOthers}
      />
    </Modal>
  );
}
