function getRectApiConecta() {
  if (!process.env.REACT_APP_API_CONECTA) {
    throw new Error('REACT_APP_API_CONECTA não definido');
  }
  return process.env.REACT_APP_API_CONECTA;
}
const URL_CONECTA = getRectApiConecta();

import axios from 'axios';

export const contingenciaService = {
  getURLs,
  sendEmail,
  sendSMS,
};
const milliseconds = (h, m, s) => (h * 60 * 60 + m * 60 + s) * 1000;

async function getURLs(minutos, queue) {
  try {
    minutos = milliseconds(0, minutos, 0);
    let userData = window.localStorage.user;
    userData = JSON.parse(userData);
    const url = `${URL_CONECTA}/servicoVonage/adm/acionamentos/contingencia`;
    const data = {
      timeAttendance: minutos,
      idFila: queue,
    };

    const result = await axios({
      method: 'POST',
      url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': userData.accessToken,
      },
    });

    return {
      err: false,
      data: result.data,
    };
  } catch (err) {
    return {
      error: true,
      message: `Erro ao buscar URL´s`,
    };
  }
}

async function sendEmail(payload) {
  try {
    let userData = window.localStorage.user;
    userData = JSON.parse(userData);
    const endpoint = payload.origem == 'medico' ? 'medico' : 'paciente';
    const url = `${URL_CONECTA}/servicoVonage/adm/acionamentos/contingencia/email/${endpoint}`;
    const minutos = milliseconds(0, payload.validade, 0);

    const data = {
      _id: payload.idAtendimento,
      email:
        payload.origem == 'medico'
          ? payload.email_medico
          : payload.email_paciente,
      timeAttendance: minutos,
      pacientLink: payload.link_paciente,
      doctorLink: payload.link_medico,
    };

    const result = await axios({
      method: 'POST',
      url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': userData.accessToken,
      },
    });

    return {
      err: false,
      data: result.data,
    };
  } catch (err) {
    return {
      error: true,
      message: `Erro ao enviar E-mail`,
    };
  }
}

async function sendSMS(payload) {
  try {
    let userData = window.localStorage.user;
    userData = JSON.parse(userData);
    const endpoint = payload.origem == 'medico' ? 'medico' : 'paciente';
    const url = `${URL_CONECTA}/servicoVonage/adm/acionamentos/contingencia/sms/${endpoint}`;

    const data = {
      _id: payload.idAtendimento,
      telefone:
        payload.origem == 'medico'
          ? payload.telefone_medico
          : payload.telefone_paciente,
      pacientLink: payload.link_paciente,
      doctorLink: payload.link_medico,
    };

    const result = await axios({
      method: 'POST',
      url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': userData.accessToken,
      },
    });

    return {
      err: false,
      data: result.data,
    };
  } catch (err) {
    return {
      error: true,
      message: `Erro ao enviar SMS`,
    };
  }
}
