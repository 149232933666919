import React, { useState, useEffect } from 'react';
import { status } from './config';
import * as S from './styles';
import CustomButtom from '../CustomButton';
import { userService } from '../../../services/user.service';

const FilterStatus = ({
  setFilterStatus,
  setObjectRequest,
  setOpenStatus,
  objectRequest,
  receivedStatus,
  setPageNumber,
  update,
  className,
}) => {
  let arrStatus = [];

  useEffect(() => {
    let clist = document.getElementsByName('status');
    for (const el of clist) {
      let elSplited = el.value.split(',');
      if (receivedStatus?.includes(elSplited[0])) {
        el.checked = true;
      }
    }
  }, [receivedStatus]);

  let getFiltred = async () => {
    setPageNumber(1);
    let clist = document.getElementsByName('status');
    for (const el of clist) {
      if (el.checked) {
        arrStatus.push(...el.value.split(','));
      }
    }

    setFilterStatus(arrStatus);
    setOpenStatus(false);
    await userService.setPersonalizedStatus(arrStatus);

    // update();
  };

  const clearAll = () => {
    let clist = document.getElementsByName('status');
    for (const el of clist) {
      el.checked = false;
    }
    arrStatus = [];
  };
  return (
    <S.Container className={className}>
      <S.Title>Filtrar Status</S.Title>

      <S.ContainerCheck>
        {status.map((e, index) => {
          return (
            e.text && (
              <S.Check key={e.text + index.toString()}>
                <S.Input
                  type='checkbox'
                  name={'status'}
                  id={index}
                  value={e.value}
                />
                <S.Label htmlFor={index}>{e.text}</S.Label>
              </S.Check>
            )
          );
        })}
      </S.ContainerCheck>
      <S.ContainerButton>
        <CustomButtom
          text={'Limpar filtro'}
          typeButtom={'gray2'}
          onClick={() => clearAll()}
        />
        <CustomButtom
          text={'Aplicar'}
          typeButtom={'gray'}
          onClick={() => {
            getFiltred();
          }}
        />
      </S.ContainerButton>
    </S.Container>
  );
};

export default FilterStatus;
