import React from 'react';
import MenuPainel from '../components/MenuPainel';
import { FilaCancelamento } from '../components/FilaCancelamento';
import PaperForm from '../components/PaperForm';
import PropTypes from 'prop-types';
import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';

class Canc extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <MenuPainel stepper='Cancelamentos - B2C'>
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<FilaCancelamento />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

Canc.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = Canc;
export { connectedRegisterPage as Cancelamentos };
