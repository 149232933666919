import { useState, useEffect } from 'react';

import { workShiftService } from '../../../../../services/core';

import { formatEntryTime } from '../utils';

const useModalWorkShift = (show, onError, onSuccess, workShift) => {
  const [useScale, setScales] = useState(null);
  const [useMotive, setMotive] = useState('');
  const [useLoading, setLoading] = useState(false);



  const onMotiveChange = (e) => {
    setMotive(e.target.value);
  };

  useEffect(() => {
    if (show) {
      setScales(formatEntryTime(workShift));
    }

    return () => {
      if (!show) {
        resetMotive();
      }
    };
  }, [show]);

  const resetMotive = () => {
    setMotive('');
    setScales(null);
  };

  const onConfirm = async () => {
    if (useMotive === '') {
      onError(true);
    } else {
      const {
        status,
        data: { message },
      } = await workShiftService.delayReason({
        delayReason: useMotive,
      });

      if (status) onSuccess(message);
      else onError(true, message);
    }
  };

  return {
    useLoading,
    useScale,
    useMotive,
    onMotiveChange,
    onConfirm,
  };
};

export default useModalWorkShift;
