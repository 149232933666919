export const conveniosConstants = {
  CONVENIOS_REQUEST: 'CONVENIOS_REQUEST',
  CONVENIOS_SUCCESS: 'CONVENIOS_SUCCESS',
  CONVENIOS_FAILURE: 'CONVENIOS_FAILURE',
};

export const conveniosConstantsPortal = {
  CONVENIOS_REQUEST_PORTAL: 'CONVENIOS_REQUEST_PORTAL',
  CONVENIOS_SUCCESS_PORTAL: 'CONVENIOS_SUCCESS_PORTAL',
  CONVENIOS_FAILURE_PORTAL: 'CONVENIOS_FAILURE_PORTAL',
};
