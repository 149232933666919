import { ShowQueueRecordsState } from '../../../../domain/state';
import { QueueRecordEntity } from '../../../../domain/entities';
import { dispatch, selector } from '../../../../application/store/root';
import { setShow, setLoading, setModal } from './find-all-slice';

export class ShowQueueRecordsStore {
  dispatch = dispatch();

  showQueueRecordsSelector: ShowQueueRecordsState = selector(
    (state) => state.showQueueRecords
  );

  handleShow = (show: QueueRecordEntity) => {
    this.dispatch(setShow(show));
  };

  handleLoading = (loading: boolean) => {
    this.dispatch(setLoading(loading));
  };

  handleModal = (modal: boolean) => {
    this.dispatch(setModal(modal));
  };
}
