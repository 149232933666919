import * as Yup from 'yup';

import { formErrors } from '../../../../../../../Spread/components/ui/webform/utils/errors';

import { regexOnlyLetters } from '../../../../../../../Spread/components/ui/webform/utils/regex';

import { validateFormNumberSchema } from '../../utils';

export const formSchema = Yup.object().shape({
  socialName: Yup.string()
    .matches(regexOnlyLetters, formErrors.notAllowedNumbers)
    .nullable(true),
  phone: Yup.string().trim().required('Campo obrigatório.'),
});

export const phoneMask = (value) =>
  value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');

export const unmask = (value) =>
  value.replace(/\D+/g, '').replace(/([0-9])/, '$1');
