import React from 'react';

import logoCentral from '../../../../img/logo_central.svg';

import { withStyles } from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';

import MenuLogado from '../../../../components/MenuLogadoNew';

import NotificationsIcon from '@material-ui/icons/Notifications';

import {
  Logo,
  Title,
  Header,
  Profile,
  StyledContainer,
} from './styles';

import { Container } from '../../../components/context';

import { CustomDivider, CustomLoader, CustomSnackbar } from '../../../components/ui';

import { ModalLock, ModalPause } from './components';

import { WorkShiftControl } from '../../../modules/pauses/components/WorkShiftControlButton'
import usePauses from '../../../modules/pauses/hooks/usePauses';

import useLockStation from './hooks/useLockStation';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -1,
    top: 14,
    padding: '0 4px',
    height: '20px',
    width: '15px',
  },
}))(Badge);

const LockStation = () => {
  const { user } = JSON.parse(localStorage.getItem('user'));

  const { useBlock, usePause, useLoading, onPauseFinish } = useLockStation();
  const { trigger } = CustomSnackbar();

  const {
    nextPause,
    selected,
    scheduledPause,
    schedulingPause,
    pauseIndex,
    pauseTypes,
  } = usePauses(trigger);

  return (
    <>
      <Header>
        <Logo>
          <img src={logoCentral} alt='Logo Central' />
        </Logo>
        <Title>
          <h1>Gestão de escalas</h1>
        </Title>

        <StyledContainer>
          <WorkShiftControl
            nextPause={nextPause}
            selected={selected}
            scheduledPause={scheduledPause}
            schedulingPause={schedulingPause}
            pauseIndex={pauseIndex}
            pauseTypes={pauseTypes}
          />
        </StyledContainer>

        <Profile>
          <button
            style={{
              display: 'block',
              background: 'none',
              border: 'none',
            }}
          >
            <StyledBadge style={{}} color='secondary'>
              <NotificationsIcon
                style={{
                  color: 'black',
                  marginTop: 3,
                  position: 'float',
                  marginLeft: 10,
                  fontSize: '28px',
                }}
              />
            </StyledBadge>
          </button>
          <MenuLogado bt_name={user.nome} MenuButtons={[]} />
        </Profile>
      </Header>

      <Container>
        <CustomDivider />
        {useLoading && <CustomLoader fixed color='info' />}
        {useBlock?.show && <ModalLock data={useBlock} />}
        {usePause && (
          <ModalPause data={usePause} onPauseFinish={() => onPauseFinish()} />
        )}
      </Container>
    </>
  );
};

export default LockStation;
