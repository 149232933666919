// @ts-nocheck
import React from 'react';
import { styled as styledMui } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const ButtonSubmit = styledMui(Button)({
  fontSize: '10px',
  width: '95%',
  height: '36px',
  color: '#2196F3',
  background: '#F1F1F1',
  boxShadow: 'none',
  border: '1px white solid',
  margin: '20px 0 0 0',
  borderRadius: '18px',
});
