import { ActionPassageConstants } from '../constants';
import { validations } from '../helpers/validations';
import moment from 'moment';

export function acionamentospassagem(state = { paAcionamentos: {} }, action) {
  switch (action.type) {
    case ActionPassageConstants.ACIONAMENTO_PASSAGEM_SUCCESS:
      return {
        type: 'success',
        paAcionamentos: trataJsonCorporate(action.result.ListaDadosPaciente[0]),
      };
    case ActionPassageConstants.ACIONAMENTO_PASSAGEM_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        paAcionamentos: {},
      });
    case ActionPassageConstants.ACIONAMENTO_PASSAGEM_REQUEST:
      return Object.assign({}, state, {
        paAcionamentos: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}

export function trataJsonCorporate(result) {
  if (result && result.Nome) {
    let documentType = validations.validarCPF(result.Cpf) ? 'CPF' : '';

    let json = {
      ...result,
      convenio: `${result.convenio}|${result.contrato}`,
      contrato: result.contrato,
      cpfrequire: false,
      name: result.Nome.split(/ (.+)/)[0],
      lastName: result.Nome.split(/ (.+)/)[1],
      cpf: result.Cpf,
      email: result.Email,
      birthDate: moment(result.DataNascimento, 'YYYY-MM-DD').format(
        'DD/MM/YYYY'
      ),
      password: '',
      cpassword: '',
      loadingsub: false,
      submitted: false,
      checkTerms: false,
      shWowNotifications: false,
      sex: result.Sexo.substring(0, 1),
      phone: result.Telefone,
      phone2: result.homePhone,
      phone3: result.phone2,
      rg: result.rg,
      passport: result.Cpf,
      nacionalidade: documentType ? (documentType === 'CPF' ? 'B' : 'E') : 'E',
      numberCard: result.numberCard,
      userType: result.userType,
      id_requisicao_parceiro: result.partnerID,
    };
    return json;
  }
}
