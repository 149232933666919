import React from 'react'

import { Tooltip } from '@material-ui/core'

const direction = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
}

const CustomTooltip = ({ children, text, align }) => (
  <Tooltip title={text} aria-label={text} placement={direction[align] || direction.right}>
    <div>{children}</div>
  </Tooltip>
)

export default CustomTooltip