export default async function handleNotifications(
  getNotifications,
  getIdNotifications,
  perfil
) {
  function handleSegregation(array, perfil) {
    let recepcao = [];
    let suporte = [];
    let administracao = [];
    let manager = [];

    array.map((element) => {
      if (element.reception) {
        recepcao.push(element);
      }
      if (element.support) {
        suporte.push(element);
      }
      if (element.administration) {
        administracao.push(element);
      }
      if (element.manager) {
        manager.push(element);
      }
    });
    if (perfil == 'Suporte') {
      return suporte;
    }
    if (perfil == 'Recepção') {
      return recepcao;
    }
    if (perfil == 'Administrativo') {
      return administracao;
    }
    if (perfil == 'Gestor') {
      return manager;
    }
  }

  if (getNotifications) {
    const array = getNotifications;
    let result = {};
    result.docs = handleSegregation(array, perfil);
    return result;
  }
  return getNotifications;
}
