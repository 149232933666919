import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 105px;
  max-width: 140px;
  align-items: center;
`;
export const StatusText = styled.div`
  color: #6b7786;
  font-size: 0.7em;
  padding: 5px;
  height: 5px;
  text-align: center;
  width: 105px;
`;
export const ContainerInfo = styled.div`
  min-width: 58px;
`;
