import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/PersonOutlineOutlined';

const styles = (theme) => ({
  root: {
    float: 'right',
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  root_paper: {
    borderRadius: '10px',
    right: '30px',
  },
  popper: {
    zIndex: 1000,
    '@media only screen and (max-width: 900px)': {
      right: '10px',
      padding: '0 1px 0 10px',
      width: '100%',
    },
  },
});

class MenuListComposition extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes, bt_name, MenuButtons = [] } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <Button
          style={{ color: 'white' }}
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={this.handleToggle}
        >
          <AddIcon />
          {bt_name}
        </Button>
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.root_paper} elevation={10}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {MenuButtons.map((image) => (
                      <MenuItem key={image.title} onClick={image.click}>
                        {image.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuListComposition);
