// libs
import { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GOOGLE_RECAPTCHA_KEY: string = process.env.REACT_APP_GOOGLE_KEY || '';

interface ReCaptchaProviderProps {
  children?: ReactNode;
}
export function ReCaptchaProvider({ children }: ReCaptchaProviderProps) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={GOOGLE_RECAPTCHA_KEY}
      language='pt-BR'
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}
