import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import sexo from '../icons/icon_gen.svg';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '11px',
    color: 'red',
  },
  sucess: {
    backgroundColor: green[600],
  },
  boxdialog: {
    margin: '0 auto 0 auto',
    '@media only screen and (max-width: 900px)': {
      padding: '0 0 20px 0',
      margin: '0 auto 0 auto',
      '@media screen and (orientation: landscape)': {
        margin: '0 auto',
      },
    },
  },
  dialog_modal: {
    borderRadius: '50%',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    margin: '0 auto',
  },
  titleDialog: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'Poppins',
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
  },
  close: {
    position: 'absolute',
    width: '45px',
    top: '2px',
  },
  rootStyle: {
    borderRadius: 20,
  },
  dialogAlert: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
});

class ModalDeleteAcionamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vertical: 'top',
      horizontal: 'center',
      currentTime: 10,
      copySuccess: '',
      maxWidth: 'xs',
      motivo: '',
      motivosRecusa: [
        { value: 'Outros' },
        { value: 'Paciente não estava conectado ao iniciarmos o contato' },
        { value: 'Perda de conexão durante o atendimento' },
        { value: 'Conexão ruim / Inapropriada para atendimento' },
        { value: 'Atendimento para outro paciente' },
        { value: 'Não quis aguardar o tempo de espera médica' },
        { value: 'Local inapropriado para atendimento' },
        {
          value:
            'Não estava com documento com foto em mãos (Digital ou Físico)',
        },
        { value: 'Não sabia o número da carteirinha' },
        { value: 'Assuntos relacionados ao convênio' },
        { value: 'Testes' },
        { value: 'Queria conhecer o funcionamento do serviço' },
        { value: 'Dúvidas não atendidas pela telemedicina' },
        { value: 'Agendamento de consultas / exames' },
        { value: 'Atendimento de Emergência' },
      ],
      motivosRecusaUrgency: [{ value: 'Atendimento de Emergência' }],
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleChangeSelect(evt) {
    const { name, value } = evt.target;
    if (value == 'Outros') {
      evt.target.value = '';
    }
    this.props.handlerChangeReason(evt);
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      classes,
      open,
      close,
      message,
      onSubmit,
      dataDeleteAcionamento,
      inputValueReason,
      handlerChangeReason,
      isUrgency = false,
    } = this.props;

    return (
      <React.Fragment>
        <Dialog
          classes={{ paper: classes.rootStyle }}
          open={open}
          maxWidth={this.state.maxWidth}
          disableAutoFocus={true}
          title={message || ''}
          fullWidth={true}
        >
          <DialogActions>
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={close}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </DialogActions>
          {dataDeleteAcionamento && (
            <DialogTitle className={classes.titleDialog}>
              <span className={classes.titleDialog}>
                Deseja deletar o acionamento ?
              </span>
            </DialogTitle>
          )}
          <DialogContent>
            <FormControl className={classes.input}>
              <Select
                value={this.state.motivo}
                onChange={this.handleChangeSelect}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='motivo'
                className={classes.input}
                startAdornment={
                  <InputAdornment position='start'>
                    <img className={classes.img_inputs} src={sexo} />
                  </InputAdornment>
                }
              >
                {
                  <MenuItem value=''>
                    <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                      Motivo
                    </em>
                  </MenuItem>
                }
                {this.state.motivosRecusa.map((motivo) => (
                  <MenuItem value={motivo.value}>{motivo.value}</MenuItem>
                ))}
              </Select>
              {this.state.motivo == 'Outros' && (
                <Input
                  id='component-helper'
                  value={inputValueReason}
                  onChange={handlerChangeReason}
                  aria-describedby='component-helper-text'
                  multiline
                />
              )}
              <FormHelperText id='component-helper-text'>
                Essa campo é obrigatório para deletar o acionamento
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions justify={'center'}>
            <Grid container justify={'center'}>
              <Fab
                onClick={close}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '40%',
                  height: '36px',
                  background: 'white',
                  color: 'red',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Cancelar
              </Fab>
              <Fab
                variant='extended'
                aria-label='Delete'
                onClick={() => onSubmit(this)}
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '40%',
                  height: '36px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Deletar Acionamento
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  async componentDidMount() {
    let { isUrgency = false } = this.props;
    if (isUrgency) {
      this.setState({ motivo: 'Atendimento de Emergência' });
      this.props.handlerChangeReason({
        target: { value: 'Atendimento de Emergência' },
      });
    }
  }
}

ModalDeleteAcionamento.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModalDeleteAcionamento);
