import { conveniosConstantsPortal } from '../constants';

export function conveniosPortal(state = { convenios: [] }, action) {
  switch (action.type) {
    case conveniosConstantsPortal.CONVENIOS_SUCCESS_PORTAL:
      return {
        type: 'success',
        conveniosPortal: action.convenios,
      };
    case conveniosConstantsPortal.CONVENIOS_FAILURE_PORTAL:
      return Object.assign({}, state, {
        type: 'failure',
        conveniosPortal: [],
      });
    case conveniosConstantsPortal.CONVENIOS_REQUEST_PORTAL:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
