import React from 'react';
import { validations } from '../../helpers/validations';
import { isoToDatePTBR } from '../../utils/utils';
import { DataAlign, DataContent, LineContent, Bold } from './style';

const mask = (type, document) => {
  if (type === 'document') {
    if (validations.validarCPF(document)) {
      return validations.formataCPF(document);
    } else {
      return document;
    }
  }
  if (type === 'date') {
    return isoToDatePTBR(document);
  }
};

export default function DataModal({ dataModal }) {
  return (
    <DataAlign>
      <DataContent>
        <LineContent>
          <Bold>Convenio</Bold> {dataModal?.convenioDescricao}
        </LineContent>
        <LineContent>
          <Bold>Nome</Bold> {'' + dataModal?.name + dataModal?.lastName}
        </LineContent>
        {dataModal?.socialName ? (
          <LineContent>
            <Bold>Nome social</Bold> {dataModal?.socialName}
          </LineContent>
        ) : (
          <></>
        )}
        <LineContent>
          <Bold>Documento</Bold> {mask('document', dataModal?.document)}
        </LineContent>
        <LineContent>
          {' '}
          <Bold>Sexo</Bold>{' '}
          {dataModal?.gender === 'M' ? 'Masculino' : 'Feminino'}
        </LineContent>
        <LineContent>
          <Bold>Data de nascimento</Bold> {mask('date', dataModal?.birthDate)}
        </LineContent>
        <LineContent>
          <Bold>Telefone</Bold> {dataModal?.phone}
        </LineContent>
        <LineContent>
          <Bold>Email</Bold> {dataModal?.email}
        </LineContent>
        {dataModal?.email2 ? (
          <LineContent>
            <Bold>Email2</Bold> {dataModal?.email2}
          </LineContent>
        ) : (
          <></>
        )}
        <LineContent>
          {' '}
          <Bold>Nacionalidade</Bold>{' '}
          {dataModal?.nationality === 'E' ? 'Estrangeira' : 'Brasileira'}
        </LineContent>
        {dataModal?.nationality == 'E' ? (
          <LineContent>
            <Bold>País</Bold> {dataModal?.country}
          </LineContent>
        ) : (
          <div>
            {dataModal?.streetCep ? (
              <LineContent>
                <Bold>Cep</Bold> {dataModal?.streetCep}
              </LineContent>
            ) : (
              <></>
            )}
            <LineContent>
              <Bold>País</Bold> {dataModal?.country}{' '}
            </LineContent>
            <LineContent>
              <Bold>Cidade</Bold> {dataModal?.city}{' '}
            </LineContent>
            <LineContent>
              <Bold>Estado</Bold> {dataModal?.state}{' '}
            </LineContent>
          </div>
        )}
        {dataModal?.disruptiveBehavior ? (
          <LineContent>
            <Bold>Comportamento Disruptivo</Bold>{' '}
            {dataModal?.disruptiveBehavior}
          </LineContent>
        ) : (
          <></>
        )}
      </DataContent>
    </DataAlign>
  );
}
