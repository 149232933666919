import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { IUserLoginState } from '../../reducers/login.reducer';

const isDateBefore = (date1: Date, date2: Date): boolean => {
  const dayjsDate1 = dayjs(date1);
  const dayjsDate2 = dayjs(date2);

  return dayjsDate1.isBefore(dayjsDate2);
};

const getTimeLeft = (registeredAt: Date) => {
  const expiresAt = dayjs(registeredAt).add(1, 'minute');

  const now = dayjs();

  if (!isDateBefore(now.toDate(), expiresAt.toDate())) {
    return 0;
  }

  return expiresAt.diff(now, 'second');
};

interface ILoginReducerProps {
  loginReducer: IUserLoginState;
}
export const useExpireTimer = () => {
  const data = useSelector(
    (state: ILoginReducerProps) => state.loginReducer.lastCodeSentAt
  );

  const memoizedExpiresAt = useMemo(() => data || null, [data]);

  const [intervalId, setIntervalId] = useState<null | number>(null);

  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    if (!memoizedExpiresAt) return;

    if (intervalId !== null) window.clearInterval(intervalId);

    const amount = getTimeLeft(memoizedExpiresAt);

    setTimeLeft(amount);

    const createdInterval = window.setInterval(() => {
      if (!memoizedExpiresAt) return;

      const nextTimeLeft = getTimeLeft(memoizedExpiresAt);

      setTimeLeft(nextTimeLeft);
    }, 1000);

    setIntervalId(createdInterval);

    return () => {
      if (intervalId !== null) window.clearInterval(intervalId);
      if (createdInterval !== null) window.clearInterval(createdInterval);
    };
  }, [memoizedExpiresAt]);

  return {
    timeLeft,
    willExpireAt: memoizedExpiresAt,
  };
};
