import styled from 'styled-components';

export const DataAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DataContent = styled.div`
  text-align: justify;
`;

export const LineContent = styled.p`
  word-break: normal;
  max-width: 400px;
`;

export const Bold = styled.strong`
  font-weight: 800;
  margin-right: 20px;
`;
