// @ts-nocheck
import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { cpfMask } from '../../../application/shared/utils';
import { formatDate } from '../../../application/shared/adapters';
import { ShowQueueRecordsStore } from '../../../application/store';
import * as S from './styles';

type ParagraphProps = {
  children: React.ReactNode;
};

type Props = {
  showQueueRecordsStore: ShowQueueRecordsStore;
};

const Paragraph = ({ children }: ParagraphProps) => {
  return (
    <S.Paragraph>
      <Typography component='p' variant='body2' gutterBottom>
        {children}
      </Typography>
    </S.Paragraph>
  );
};

export const ShowModal = ({ showQueueRecordsStore }: Props) => {
  const { handleModal, showQueueRecordsSelector } = showQueueRecordsStore;
  const { modal, show } = showQueueRecordsSelector;

  return (
    <S.ShowModal
      open={modal}
      onClose={() => {
        handleModal(false);
      }}
    >
      <DialogActions>
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={() => handleModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle>
        <span>Detalhes do acionamento</span>
      </DialogTitle>
      <DialogContent>
        <Paragraph>
          <strong>Convênio: </strong>
          {show?.convenioDescricao}
        </Paragraph>

        {show?.encounterHealthOperator && (
          <Paragraph>
            <strong>Operadora de saúde: </strong>
            {show?.encounterHealthOperator}
          </Paragraph>
        )}
        {show?.numberCard && (
          <Paragraph>
            <strong>Carteirinha: </strong>
            {show?.numberCard}
          </Paragraph>
        )}
        {show?.encounterPlan && (
          <Paragraph>
            <strong>Plano: </strong>
            {show?.encounterPlan}
          </Paragraph>
        )}
        {show?.encounterHealthOperator && (
          <Paragraph>
            <strong>Elegível a empresa?: </strong>
            {show?.isPatientEligible ? 'Sim' : 'Não'}
          </Paragraph>
        )}
        {show?.purchaseCode && (
          <Paragraph>
            <strong>Id do pedido: </strong>
            {show?.purchaseCode}
          </Paragraph>
        )}
        <Paragraph>
          <strong>Criado por:</strong> {show?.createdBy ?? 'Não Informado'}
        </Paragraph>
        <Paragraph>
          <strong>Criado ás:</strong>{' '}
          {formatDate(show?.createDate, 'HH:mm') ?? 'Não Informado'}
        </Paragraph>
        <Paragraph>
          <strong>Nome: </strong>
          {`${show?.name} ${show?.lastName}`}
        </Paragraph>
        {show?.socialName && (
          <Paragraph>
            <strong>Nome social: </strong> {show?.socialName}
          </Paragraph>
        )}
        <Paragraph>
          <strong>Documento: </strong>{' '}
          {show?.document && cpfMask(show?.document)}
        </Paragraph>
        <Paragraph>
          <strong>Sexo:</strong>{' '}
          {show?.gender === 'M' ? 'Masculino' : 'Feminino'}
        </Paragraph>
        <Paragraph>
          <strong>Data de nascimento:</strong>{' '}
          {formatDate(show?.birthDate, 'DD/MM/YYYY')}
        </Paragraph>
        <Paragraph>
          <strong>Telefone:</strong> {show?.phone}
        </Paragraph>
        <Paragraph>
          <strong>Email:</strong> {show?.email}
        </Paragraph>
        {show?.email2 && (
          <Paragraph>
            <strong>Email2:</strong> {show?.email2}
          </Paragraph>
        )}
        <Paragraph>
          <strong>Nacionalidade:</strong>{' '}
          {show?.nationality === 'E' ? 'Estrangeira' : 'Brasileira'}
        </Paragraph>
        {show?.nationality == 'E' ? (
          <Paragraph>
            <strong>País:</strong> {show?.country}
          </Paragraph>
        ) : (
          <div>
            {show?.streetCep ? (
              <Paragraph>
                <strong>Cep:</strong> {show?.streetCep}
              </Paragraph>
            ) : (
              <></>
            )}
            <Paragraph>
              <strong>País:</strong> {show?.country}{' '}
            </Paragraph>
            <Paragraph>
              <strong>Cidade:</strong> {show?.city}{' '}
            </Paragraph>
            <Paragraph>
              <strong>Estado:</strong> {show?.state}{' '}
            </Paragraph>
          </div>
        )}
        {show?.disruptiveBehavior && (
          <Paragraph>
            <strong>Comportamento Disruptivo:</strong>{' '}
            {show?.disruptiveBehavior}
          </Paragraph>
        )}
      </DialogContent>
    </S.ShowModal>
  );
};
