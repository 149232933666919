import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import Refresh from '@material-ui/icons/Refresh';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Send from '@material-ui/icons/Send';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import { validations } from '../helpers/validations';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ModalConvenio from '../components/ModalConvenio';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { historicoActions, alertActions } from '../actions';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MTableFilterRow from 'material-table';
import LastPageIcon from '@material-ui/icons/LastPage';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import Alert from '../components/Alert';
import classNames from 'classnames';
import AlertLink from '../components/AlertLink';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { json2csv } from 'json-2-csv';
import Loader from 'react-loader-spinner';
import InputMask from 'react-input-mask';
import * as paparse from 'papaparse';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ModalHistory from './ModalHistory/ModalHistory';
import DataModal from './ModalHistory/DataModal';

const useStylesFilterComponent = (theme) => ({
  root: {
    padding: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  formAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  textField: {
    width: '9%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  dateField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-100px',
    fontWeight: 500,
  },
  dateFieldEntry: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-30px',
    fontWeight: 500,
  },
  dateFieldHistory: {
    width: '30%',
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
  loaderAlign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
    marignTop: '20%',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '15px',
    color: 'red',
  },
});

const useStylesPaginationComponent = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class Historico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      historicosData: [],
      errorCsv: false,
      loaderPercent: 0,
      rowsPerPage: [5, 10, 15],
      dataInicioCsv: '',
      rows: [],
      dataInicioCsvValida: false,
      dataFimCsvValida: false,
      periodoCsvValido: false,
      numberOfRows: 5,
      modalCsvIsOpen: false,
      page: 1,
      total: undefined,
      itemByPage: 1,
      form: {
        idCentral: '',
        createPassageStart: '',
        createPassageEnd: '',
        prontuario: '',
        name: '',
        lastName: '',
        document: '',
        convenioDescricao: '',
        entrada: '',
      },
      open: false,
      openData: undefined,
      filterData: false,
      sendfilter: [],
    };
    this.itemByPage = 5;
    this.docs = [];
    this.page = 1;
    this.total = 0;
    this.handleClose = this.handleClose.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.handleChangeDoc = this.handleChangeDoc.bind(this);
    this.changeValueCsvInicio = this.changeValueCsvInicio.bind(this);
    this.changeValueCsvFim = this.changeValueCsvFim.bind(this);
    this.downloadCsvModal = this.downloadCsvModal.bind(this);
    this.closeCsvModal = this.closeCsvModal.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.filterCsvDatas = this.filterCsvDatas.bind(this);
    this.changeNamesColumnsAndFilterCsvDatas =
      this.changeNamesColumnsAndFilterCsvDatas.bind(this);
    this.changeNamesColumns = this.changeNamesColumns.bind(this);
    this.changedNamesColumnsCsv = this.changedNamesColumnsCsv.bind(this);
  }

  componentDidMount() {
    //this.props.dispatch(historicoActions.getAllHistoricos());
    //this.filterRows()
    this.props.dispatch(
      historicoActions.filterAllHistoricos(this.state.form, 1, 5)
    );
  }

  filterDate(date) {
    if (moment(date, 'DD/MM/YYYY HH:mm').isValid()) {
      return moment(date, 'DD/MM/YYYY HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    } else {
      return moment(date, 'YYYY-MM-DD HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    }
  }

  formataCPF(cpf = '') {
    //retira os caracteres indesejados...
    cpf = cpf ? cpf.replace(/[^\d]/g, '') : '';
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  TablePaginationActions(props) {
    const classes = useStylesPaginationComponent();
    let { count, page, rowsPerPage, onChangePage } = props;
    return (
      <div className={classes.root}>
        <IconButton
          onClick={(event) => {
            onChangePage(event, 1);
          }}
          disabled={false}
          aria-label='first page'
        >
          <FirstPageIcon />
        </IconButton>

        <IconButton
          onClick={(event) => {
            if (page > 1) {
              onChangePage(event, page - 1);
            }
          }}
          aria-label='previous page'
        >
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          onClick={(event) => {
            if (page < count) {
              onChangePage(event, page + 1);
            }
          }}
          disabled={false}
          aria-label='next page'
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={(event) => {
            onChangePage(event, count);
          }}
          disabled={false}
          aria-label='last page'
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }

  handleSendCockpit = (rowData, list) => {
    this.props.dispatch(historicoActions.sendToCockpit(rowData, list, this));
  };

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  updateRows(page, qtd) {
    this.setState({ filterData: false });
    this.props.dispatch(historicoActions.getAllHistoricos(page, qtd));
  }

  filterRows(page, qtd) {
    this.setState({ filterData: false });
    this.props.dispatch(
      historicoActions.filterAllHistoricos(this.state.form, page, qtd)
    );
  }

  downloadCsvModal() {
    this.setState({ modalCsvIsOpen: true });
  }

  resolvePageDownload(dataInicioCsv, dataFimCsv, page, pages) {
    return new Promise((resolve, reject) => {
      historicoActions
        .downloadJson(dataInicioCsv, dataFimCsv, page)
        .then(async (res) => {
          page = page <= pages ? page : pages;
          let percentage = parseInt(((page / pages) * 100).toFixed(0));
          this.setState({ loaderPercent: percentage });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resolvePageDownloadWithoutExecutePromise(
    dataInicioCsv,
    dataFimCsv,
    page,
    pages
  ) {
    return historicoActions.downloadJson(dataInicioCsv, dataFimCsv, page);
  }

  // 'Data registro',
  //       'convenio',
  //       'Nome convenio',
  //       'Nome paciente',
  //       'Sobre nome paciente',
  //       'Documento paciente',
  //       'documentType',
  //       'rg',
  //       'birthDate',
  //       'phone',
  //       'gender',
  //       'nationality',
  //       'naturalness',
  //       'state',
  //       'country',
  //       'codPais',
  //       'confirmedTime',
  //       'editionTime',
  //       'externalId',
  //       'confirmationUserId',
  //       'Email usuario confirmacao',
  //       'Nome usuario confirmacao',
  //       'Login usuario confirmacao',
  //       'idUserAdm',
  //       'Central confirmacao',
  //       'centralId',
  //       'Data confirmacao central',
  //       'CockPit confirmacao',
  //       'idMotivoTriagem',
  //       'motivoTriagem',
  //       'idVida',
  //       'numberCard',
  //       'city',
  //       'startTime',
  //       'idClient',
  //       'Motivo cancelamento',
  //       'deviceDataDeviceType',
  //       'deviceDataOS',
  //       'deviceDataNavigator',
  //       'deviceDataRoute',
  //       'deviceDataDeviceBrand',
  //       'deviceDataAppVersion',
  //       'deviceDataAccessChannel',
  //       'cancelUserId',
  //       'Email usuario cancelamento',
  //       'Nome usuario cancelamento',
  //       'Login usuario cancelamento',
  //       'cancelUserTelefone',
  //       'Plataforma video chamada',
  //       'disruptiveBehavior',
  //       'docRemovedByEmail',
  //       'docRemovedByLogin',
  //       'docRemovedByNome',
  //       'docRemovedByTelefone',
  //       'socialName',
  //       'cabine',

  convertCsv(array) {
    array = this.changeNamesColumnsAndFilterCsvDatas(array);
    let perseCSV = paparse;
    let response = perseCSV.unparse({
      fields: [
        'Paciente_email',
        'Paciente_id_convenio',
        'Paciente_convenio_nome',
        'Paciente_descricao_produto',
        'Paciente_nome',
        'Paciente_sobrenome',
        'Paciente_documento',
        'Paciente_tipo_documento',
        'Paciente_rg',
        'Paciente_data_nascimento',
        'Paciente_telefone',
        'Paciente_genero',
        'Paciente_nacionalidade',
        'Paciente_naturalidade',
        'Paciente_estado',
        'Paciente_país',
        'Paciente_codigo_pais',
        'Paciente_numero_carteirinha',
        'Paciente_cidade',
        'Paciente_comportamento_disruptivo',
        'Paciente_nome_social',
        'Triagem_id',
        'Triagem_motivo',
        'Dispositivo_tipo',
        'Dispositivo_sistema_operacional',
        'Dispositivo_navegacao',
        'Dispositivo_url',
        'Dispositivo_fabricante',
        'Dispositivo_aplicativo_versao',
        'Dispositivo_canal_de_acesso',
        'Confirmacao_usuario_id',
        'Confirmacao_usuario_email',
        'Confirmacao_usuario_nome',
        'Confirmacao_usuario_login',
        'Confirmacao_status',
        'Confirmacao_central_id',
        'Confirmacao_central_data',
        'Confirmacao_envio_cockpit',
        'Acionamento_data',
        'Acionamento_data_confirmacao',
        'Acionamento_data_edicao',
        'Acionamento_plataforma_chamada',
        'Acionamento_cabine',
        'Cancelamento_usuario_id',
        'Cancelamento_usuario_email',
        'Cancelamento_usuario_nome',
        'Cancelamento_usuario_login',
        'Cancelamento_usuario_telefone',
        'Cancelamento_motivo',
        'Documento_remocao_email',
        'Documento_remocao_login',
        'Documento_remocao_nome',
        'Documento_remocao_telefone',
        'Identificação_external',
        'Identificação_usuário_adm',
        'Criação_passagem',
        'Identificacao_vida',
        'Tempo_inicio',
        'Identificação_cliente',
      ],
      data: array,
    });

    response = response.replace(/undefined/g, '');
    response = response.replace(/null/g, '');
    var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'historicocsv.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    /*return json2csv(array,function(error,response){
            response = response.replace(/undefined/g,'')
            response = response.replace(/null/g,'')
            var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
            var link = document.createElement("a");
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "historicocsv.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })*/
  }

  filterCsvDatas(array) {
    const newArray = array.map((arr) => {
      if (arr['deviceData']) {
        arr.deviceDataDeviceType = arr['deviceData']['deviceType'];
        arr.deviceDataOS = arr['deviceData']['OS'];
        arr.deviceDataNavigator = arr['deviceData']['navigator'];
        arr.deviceDataRoute = arr['deviceData']['route'];
        arr.deviceDataDeviceBrand = arr['deviceData']['deviceBrand'];
        arr.deviceDataAppVersion = arr['deviceData']['appVersion'];
        arr.deviceDataAccessChannel = arr['deviceData']['accessChannel'];
      }

      if (arr['confirmationUser']) {
        arr.confirmationUserEmail = arr['confirmationUser']['email'];
        arr.confirmationUserLogin = arr['confirmationUser']['login'];
        arr.confirmationUserNome = arr['confirmationUser']['nome'];
        arr.confirmationUserId = arr['confirmationUser']['_id'];
      }

      if (arr['cancelUser']) {
        arr.cancelUserEmail = arr['cancelUser']['email'];
        arr.cancelUserLogin = arr['cancelUser']['login'];
        arr.cancelUserNome = arr['cancelUser']['nome'];
        arr.cancelUserTelefone = arr['cancelUser']['telefone'];
        arr.cancelUserID = arr['cancelUser']['_id'];
      }

      if (arr['docRemovedBy']) {
        arr.docRemovedByEmail = arr['docRemovedBy']['email'];
        arr.docRemovedByLogin = arr['docRemovedBy']['login'];
        arr.docRemovedByNome = arr['docRemovedBy']['nome'];
        arr.docRemovedByTelefone = arr['docRemovedBy']['telefone'];
      }

      if (arr.cabine) {
        arr.cabine = 'Sim';
      } else {
        arr.cabine = 'Nao';
      }
      return arr;
    });
    return newArray;
  }

  changeNamesColumnsAndFilterCsvDatas(array) {
    const filteredDataArray = this.filterCsvDatas(array);
    const changedArrayColumns = this.changeNamesColumns(filteredDataArray);
    return changedArrayColumns;
  }

  changeNamesColumns(array) {
    const translate = {
      email: 'Paciente_email',
      convenio: 'Paciente_id_convenio',
      convenioDescricao: 'Paciente_convenio_nome',
      descricaoProduto: 'Paciente_descricao_produto',
      name: 'Paciente_nome',
      lastName: 'Paciente_sobrenome',
      document: 'Paciente_documento',
      documentType: 'Paciente_tipo_documento',
      rg: 'Paciente_rg',
      birthDate: 'Paciente_data_nascimento',
      phone: 'Paciente_telefone',
      gender: 'Paciente_genero',
      nationality: 'Paciente_nacionalidade',
      naturalness: 'Paciente_naturalidade',
      state: 'Paciente_estado',
      country: 'Paciente_país',
      codPais: 'Paciente_codigo_pais',
      numberCard: 'Paciente_numero_carteirinha',
      city: 'Paciente_cidade',
      disruptiveBehavior: 'Paciente_comportamento_disruptivo',
      socialName: 'Paciente_nome_social',
      idMotivoTriagem: 'Triagem_id',
      motivoTriagem: 'Triagem_motivo',
      deviceDataDeviceType: 'Dispositivo_tipo',
      deviceDataOS: 'Dispositivo_sistema_operacional',
      deviceDataNavigator: 'Dispositivo_navegação',
      deviceDataRoute: 'Dispositivo_url',
      deviceDataDeviceBrand: 'Dispositivo_fabricante',
      deviceDataAppVersion: 'Dispositivo_aplicativo_versao',
      deviceDataAccessChannel: 'Dispositivo_canal_de_acesso',
      confirmationUserId: 'Confirmacao_usuario_id',
      confirmationUserEmail: 'Confirmacao_usuario_email',
      confirmationUserNome: 'Confirmacao_usuario_nome',
      confirmationUserLogin: 'Confirmacao_usuario_login',
      confirmed: 'Confirmacao_status',
      centralId: 'Confirmacao_central_id',
      createIdCentral: 'Confirmacao_central_data',
      sendCockPit: 'Confirmacao_envio_cockpit',
      createDate: 'Acionamento_data',
      confirmedTime: 'Acionamento_data_confirmacao',
      editionTime: 'Acionamento_data_edicao',
      videoChamadaPlataforma: 'Acionamento_plataforma_chamada',
      cabine: 'Acionamento_cabine',
      cancelUserId: 'Cancelamento_usuario_id',
      cancelUserEmail: 'Cancelamento_usuario_email',
      cancelUserNome: 'Cancelamento_usuario_nome',
      cancelUserLogin: 'Cancelamento_usuario_login',
      cancelUserTelefone: 'Cancelamento_usuario_telefone',
      reason: 'Cancelamento_motivo',
      docRemovedByEmail: 'Documento_remocao_email',
      docRemovedByLogin: 'Documento_remocao_login',
      docRemovedByNome: 'Documento_remocao_nome',
      docRemovedByTelefone: 'Documento_remocao_telefone',
      externalId: 'Identificação_external',
      idUserAdm: 'Identificação_usuário_adm',
      createPassage: 'Criação_passagem',
      idVida: 'Identificação_vida',
      startTime: 'Tempo_inicio',
      idClient: 'Identificação_cliente',
    };

    let changedColumnsNameArray = [];

    array.map((arr) => {
      changedColumnsNameArray.push(this.changedNamesColumnsCsv(arr, translate));
    });

    return changedColumnsNameArray;
  }

  changedNamesColumnsCsv(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      if (newKeys[key] != undefined) {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
      } else {
        return { [key]: obj[key] };
      }
    });
    return Object.assign({}, ...keyValues);
  }

  async submitCsvModal() {
    let { dataInicioCsv, dataFimCsv } = this.state;
    let periodoInvalido = validations.validationCsvRangeDate(
      dataInicioCsv,
      dataFimCsv
    );

    if (periodoInvalido) {
      this.setState({ periodoCsvValido: true });
    } else {
      this.setState({ errorCsv: false, showLoader: true });
      let items = [];
      let hasError = false;
      let firstPage = await this.resolvePageDownload(
        dataInicioCsv,
        dataFimCsv,
        1,
        1
      ).catch((err) => {
        this.setState({ loaderPercent: 0, showLoader: false, errorCsv: true });
      });
      let { page = 0, pages = 0, limit = 0, docs = [] } = firstPage;
      for (let doc of docs) {
        items.push(doc);
      }
      let arrayPromises = [];
      do {
        page++;
        arrayPromises.push(
          this.resolvePageDownloadWithoutExecutePromise(
            dataInicioCsv,
            dataFimCsv,
            page,
            pages
          )
        );
      } while (page <= pages);
      await Promise.all(arrayPromises)
        .then((values) => {
          for (let value of values) {
            let { docs } = value;
            for (let doc of docs) {
              items.push(doc);
            }
          }
        })
        .catch((err) => {
          hasError = true;
          this.setState({
            periodoCsvValido: false,
            loaderPercent: 0,
            showLoader: false,
            dataInicioCsv: '',
            dataFimCsv: '',
            errorCsv: true,
          });
        });

      if (!hasError) {
        this.convertCsv(items);
        this.setState({
          periodoCsvValido: false,
          loaderPercent: 0,
          showLoader: false,
          dataInicioCsv: '',
          dataFimCsv: '',
          errorCsv: false,
        });
      }
    }
  }

  closeCsvModal() {
    this.setState({ modalCsvIsOpen: false });
  }

  changeValue(event) {
    let { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  }

  handleChangeDoc(event) {
    let { value } = event.target;
    this.setState({
      form: { document: value.replace(/([^\w]+|\s+)/g, '') },
    });
  }

  changeValueCsvInicio(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataInicioCsvValida: validations.validationCsvDate(value),
    });
  }

  changeValueCsvFim(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataFimCsvValida: validations.validationCsvDate(value),
    });
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        id='formatted-text-mask-input'
        placeholder='Data de criação'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  styleComp() {
    let style = useStylesPaginationComponent();
    return style;
  }

  async cleanFields() {
    await this.setState({
      form: {
        centralId: '',
        createPassageStart: '',
        createPassageEnd: '',
        name: '',
        lastName: '',
        document: '',
        convenioDescricao: '',
        entrada: '',
      },
    });
    this.filterRows();
  }

  handleOpenModal(rowData) {
    this.setState({ open: true, openData: rowData });
  }

  handleCloseModal() {
    this.setState({ open: false, openData: undefined });
  }
  render() {
    let { historicos, open, message, type, loading } = this.props;
    let { classes } = this.props;
    const { historicosData, modalCsvIsOpen, loaderPercent, showLoader } =
      this.state;
    let { pages } = historicos;
    let { page } = historicos;
    let { total } = historicos;
    const columns = [
      { title: 'ID Central', field: 'centralId' },
      { title: 'Data e Hora da ID Central', field: 'createIdCentral' },
      { title: 'Nome', field: 'name' },
      { title: 'Sobrenome', field: 'lastName' },
      { title: 'Documento', field: 'document' },
      { title: 'Produto', field: 'descricaoProduto' },
      { title: 'Contrato', field: 'convenioDescricao' },
      { title: 'Entrada', field: 'createDate' },
      { title: 'Canal de acesso', field: 'canalAcesso' },
    ];
    this.page = page;
    this.total = total;
    const data = historicos.docs;
    let col = data?.map((ret) => {
      let { centralId } = ret;
      let { documentType } = ret;
      let { createDate, createIdCentral } = ret;
      if (createIdCentral) {
        ret.createIdCentral = this.filterDate(ret.createIdCentral);
      }
      if (createDate) {
        ret.createDate = this.filterDate(ret.createDate);
      }
      if (documentType == 'CPF') {
        ret.document = this.formataCPF(ret.document);
      }

      if (!ret.deviceData)
        ret.deviceData = {
          deviceType: '',
          OS: '',
          navigator: '',
          route: '',
          accessChannel: '',
          appVersion: '',
          deviceBrand: '',
        };
      if (!ret.deviceData.deviceType) ret.deviceData.deviceType = '';
      if (!ret.deviceData.accessChannel) ret.deviceData.accessChannel = '';

      ret.canalAcesso =
        ret.deviceData.deviceType +
        ' ' +
        ret.deviceData.accessChannel +
        ' ' +
        ret.deviceData.OS;

      return { ...ret };
    });
    return (
      <div>
        {loading && (
          <div className={classes.overlay}>
            <div className='loader'>Loading...</div>
          </div>
        )}
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <ModalConvenio
          open={modalCsvIsOpen}
          close={() => this.closeCsvModal()}
          message='Selecione o periodo de extração'
          onSubmit={async () => {
            await this.submitCsvModal();
          }}
          component={
            <React.Fragment>
              {showLoader && (
                <div className={classes.loaderAlign}>
                  <Loader type='Oval' color='#ccc' height={60} width={60} />
                </div>
              )}
              {!showLoader && (
                <div className={classes.formAlign}>
                  <TextField
                    label='De'
                    margin='normal'
                    name='dataInicioCsv'
                    value={this.state.dataInicioCsv}
                    onChange={this.changeValueCsvInicio}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>

                  <TextField
                    label='Até'
                    margin='normal'
                    name='dataFimCsv'
                    value={this.state.dataFimCsv}
                    onChange={this.changeValueCsvFim}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                </div>
              )}
              {this.state.periodoCsvValido && (
                <div className={classNames(classes.alertText)}>
                  O periodo entre as datas não pode ultrapassar 3 meses
                </div>
              )}
              {this.state.errorCsv && (
                <div className={classNames(classes.alertText)}>
                  Não foi possível efetuar o download. Por favor, tente
                  novamente.
                </div>
              )}
            </React.Fragment>
          }
        />
        <div className={classes.root}>
          <TextField
            className={classes.textField}
            id='standard-select-currency'
            label='ID Central'
            name='centralId'
            value={this.state.form.centralId}
            onChange={this.changeValue}
            margin='normal'
          ></TextField>
          <div style={{ width: '9%', marginRight: '10px' }}>
            <InputLabel htmlFor='formatted-text-mask-input'>
              Data inicio ID Central
            </InputLabel>
            <Input
              className={classes.dateField}
              id='standard-select-currency'
              label='Data e hora ID Central'
              name='createPassageStart'
              value={this.state.form.createPassageStart}
              onChange={this.changeValue}
              inputComponent={this.TextMaskCustom}
              margin='normal'
            />
          </div>
          <div style={{ width: '9%' }}>
            <InputLabel htmlFor='formatted-text-mask-input'>
              Data fim ID Central
            </InputLabel>
            <Input
              className={classes.dateField}
              id='standard-select-currency'
              label='Data e hora ID Central'
              name='createPassageEnd'
              value={this.state.form.createPassageEnd}
              onChange={this.changeValue}
              inputComponent={this.TextMaskCustom}
              margin='normal'
            />
          </div>

          <TextField
            className={classes.textField}
            id='standard-select-currency'
            label='Nome'
            name='name'
            value={this.state.form.name}
            onChange={this.changeValue}
            margin='normal'
          ></TextField>
          <TextField
            className={classes.textField}
            id='standard-select-currency'
            label='Sobrenome'
            name='lastName'
            value={this.state.form.lastName}
            onChange={this.changeValue}
            margin='normal'
          ></TextField>
          <TextField
            className={classes.textField}
            id='standard-select-currency'
            label='Documento'
            name='document'
            value={this.state.form.document}
            onChange={this.handleChangeDoc}
            margin='normal'
          ></TextField>
          <TextField
            className={classes.textField}
            id='standard-select-currency'
            label='Contrato'
            name='convenioDescricao'
            value={this.state.form.convenioDescricao}
            onChange={this.changeValue}
            margin='normal'
          ></TextField>
          <div style={{ width: '7%' }}>
            <InputLabel htmlFor='formatted-text-mask-input'>Entrada</InputLabel>
            <Input
              className={classes.dateFieldEntry}
              id='standard-select-currency'
              label='Entrada'
              name='entrada'
              value={this.state.form.entrada}
              onChange={this.changeValue}
              inputComponent={this.TextMaskCustom}
              margin='normal'
            />
          </div>

          <Search
            onClick={() => {
              this.filterRows();
            }}
          />
          <a
            href='#'
            onClick={() => {
              this.filterRows();
            }}
            className={classes.searchClick}
          >
            Pesquisar
          </a>
          <a href='#' className={classes.refreshClick}>
            <GetAppIcon
              onClick={() => {
                this.downloadCsvModal();
              }}
            />
          </a>
          <CloseIcon
            onClick={() => {
              this.cleanFields();
            }}
          />
          <a
            href='#'
            onClick={() => {
              this.cleanFields();
            }}
            className={classes.refreshClick}
          >
            Limpar
          </a>
        </div>
        <ModalHistory
          title={'Detalhes do acionamento'}
          open={this.state.open}
          close={() => this.handleCloseModal()}
          disableAutoFocus={true}
          componente={<DataModal dataModal={this.state.openData} />}
        />{' '}
        <MaterialTable
          icons={tableIcons}
          title='Histórico'
          columns={columns}
          options={{
            pageSize: this.itemByPage,
            filtering: false,
            toolbar: true,
          }}
          data={col}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há dados no Histórico',
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
          }}
          actions={[
            (rowData) => ({
              icon: AddCircleOutlineRoundedIcon,
              tooltip: 'Expandir',
              onClick: (event, rowData) => {
                this.handleOpenModal(rowData);
              },
            }),
            (rowData) => ({
              icon: Send,
              tooltip: 'Reenviar acionamento',
              hidden:
                rowData?.sendCockPit === true ||
                rowData.sendCockPit === undefined
                  ? true
                  : false,
              onClick: (event, rowData) => {
                this.handleSendCockpit(rowData, historicos);
              },
            }),
          ]}
          components={{
            Pagination: (props) => (
              <TablePagination
                labelRowsPerPage='Linhas'
                rowsPerPageOptions={this.state.rowsPerPage}
                count={pages}
                page={this.page}
                onChangePage={(e, page) =>
                  this.filterRows(page++, this.itemByPage)
                }
                onChangeRowsPerPage={(event) => {
                  this.itemByPage = parseInt(event.target.value);
                  this.filterRows(1, parseInt(event.target.value));
                }}
                labelDisplayedRows={() => {
                  return `pagina: ${this.page} de ${pages}`;
                }}
                ActionsComponent={(props) => {
                  return this.TablePaginationActions(props);
                }}
              />
            ),
            Toolbar: (props) => <div></div>,
          }}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { historicos } = state.historico;
  const { message, type, open } = state.alert;
  const { loading } = state.historicoLoader;
  return {
    historicos,
    message,
    type,
    open,
    loading,
  };
}

Historico.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(Historico)
);
export { connectedLoginPage as Historico };
