import { useState } from 'react';

const useSnackbar = () => {
  const toasts = [];
  const [useQueue, setQueue] = useState([]);

  const add = ({
    type = 'info',
    message,
    align = 'BottomRight',
    duration = 6000,
  }) => {
    const toast = {
      id: `${Math.floor(Math.random() * 1000)}${Date.now()}`,
      type,
      message,
      duration,
      align,
    };

    toasts.push(toast);
    process();
  };

  const process = () => {
    if (toasts.length > 0) {
      const lastToastAdded = toasts[toasts.length - 1];
      setQueue((toast) => [...toast, lastToastAdded]);
    }
  };

  const remove = (id) => {
    setQueue((queue) => [...queue.filter((toast) => toast.id !== id)]);
  };

  return {
    useQueue,
    add,
    remove,
  };
};

export default useSnackbar;
