import request from '../request';

const execute = async ({ userIdBlock, motive }) => {
  try {
    const requestData = {
      ...(userIdBlock ? { userIdBlock } : {}),
      ...(motive ? { motive } : {}),
    };

    const response = await request.post(`/request-unlock`, requestData);

    if (response?.err) {
      return {
        err: true,
        data: {
          errorMessage: 'Erro ao receber dados do servidor',
        },
      };
    }

    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
