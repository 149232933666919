import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';
import moment from 'moment';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const filaTelepsicologiaService = {
  getQueueTelepsicologia,
  paginateAllQueue,
  DownloadJSON,
  confirmedTpisicoAcionamento,
  register,
  put,
};

function put(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  // let doctorRefId =
  //   userData.user.codigo_classe +
  //   userData.user.classificacao_cod_classe +
  //   dicionarioCod[userData.user.uf_cod_classe];

  var {
    email,
    name,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    numberCard,
    streetCep,
    schedulingDate,
    schedulingDateTime,
    program,
    idProgram,
    period,
    //Profissional,
    telepsicoProgram: telepsicoProgram,
    idProgram: idProgram,
    doctorRefName: doctorRefName,
    origin: origin,
    maxSessions,
  } = user;

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      ...user,
      email: email,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      lastName: lastName,
      document:
        nacionalidade === 'B'
          ? (cpf = cpf.toString().replace(/\./g, '').replace('-', ''))
          : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      numberCard: numberCard,
      streetCep: streetCep,
      state: regiao,
      country: pais,
      schedulingDateTime:
        schedulingDateTime.split(' ')[schedulingDateTime.split(' ').length - 1],
      schedulingDate: schedulingDateTime.split(' ')[0],

      telepsicoProgram: telepsicoProgram,
      idProgram: idProgram,
      program: program,
      telepsicoProgram: telepsicoProgram,
      idProgram: idProgram,
      period: period,
      //professional: Profissional,
      //  doctorRefId: doctorRefId,
      doctorRefName: doctorRefName,
      origin: origin,
      maxSessions: maxSessions,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/queue/telepsychology/put/${user._id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function register(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  var {
    email,
    name,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    codPais,
    numberCard,
    startTime,
    streetCep,
    city,
    regiao,
    pais,
    schedulingDateTime,
    schedulingDate,
    schedulingTime,
    program,
    telepsicoProgram: telepsicoProgram,
    idProgram: idProgram,
    origin: origin,
    idSlot: idSlot,
    doctorRefId,
    doctorName,
    maxSessions,
  } = user;

  cpf.replace(/\./g, '').replace('-', '');

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      ...user,
      email: email,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      lastName: lastName,
      document:
        nacionalidade === 'B'
          ? (cpf = cpf.toString().replace(/\./g, '').replace('-', ''))
          : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      startTime: startTime,
      numberCard: numberCard,
      streetCep: streetCep,
      schedulingDateTime: moment(schedulingDateTime, 'DD/MM/YYYY hh:mm')
        .add('-3', 'hours')
        .toISOString(),
      schedulingDate: moment(schedulingDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      schedulingTime: schedulingTime,
      doctorRefName: doctorName,
      doctorRefId: doctorRefId,
      telepsicoProgram: telepsicoProgram,
      idProgram: idProgram,
      program: program,
      origin: origin,
      idSlot: idSlot,
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/queue/telepsychology/new`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function DownloadJSON(dataInicioCsv, dataFimCsv, page) {
  let user = JSON.parse(window.localStorage.user);
  dataInicioCsv = dataInicioCsv.replace(/\//g, '-');
  dataFimCsv = dataFimCsv.replace(/\//g, '-');

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  //  console.log(user.accessToken);
  // console.log(user.user._id);
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/queue/telepsychology/downloadjson/${dataInicioCsv}/${dataFimCsv}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

async function getQueueTelepsicologia(fields, page, qtd) {
  const user = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(fields),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/queue/telepsychology/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);

  return getService;
}

async function paginateAllQueue(pageSize = 5, page = 1, queryString = '') {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/queue/telepsychology/paginate/${pageSize}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function confirmedTpisicoAcionamento(acionamento) {
  let user = JSON.parse(window.localStorage.user);

  let dicionarioCod = {
    11: 'RO',
    12: 'AC',
    13: 'AM',
    14: 'RR',
    15: 'PA',
    16: 'AP',
    17: 'TO',
    21: 'MA',
    22: 'PI',
    23: 'CE',
    24: 'RN',
    25: 'PB',
    26: 'PE',
    27: 'AL',
    28: 'SE',
    29: 'BA',
    31: 'MG',
    32: 'ES',
    33: 'RJ',
    35: 'SP',
    41: 'PR',
    42: 'SC',
    43: 'RS',
    50: 'MS',
    51: 'MT',
    52: 'GO',
    53: 'DF',
  };

  var {
    email,
    name,
    lastName,
    birthDate,
    cpf,
    passport,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    regiao,
    pais,
    codPais,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    idSlot,
    schedulingDate,
    schedulingTime,
    schedulingDateTime,
    doctorRefName,
    telepsicoProgram,
    idProgram,
    dependentWithoutDocument,
    program,
    doctorRefId,
    maxSessions,
    currentSession,
  } = acionamento;
  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      createDate: createDate,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      incompany: incompany,
      name: name,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
      },
      deviceData: deviceData,
      idSlot: idSlot,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      dependentWithoutDocument: dependentWithoutDocument,
      schedulingDate: schedulingDate,
      schedulingTime: schedulingTime,
      schedulingDateTime: moment(schedulingDateTime, 'DD/MM/YYYY hh:mm')
        .add('-3', 'hours')
        .toISOString(),
      doctorRefId: doctorRefId,
      doctorRefName: doctorRefName,
      telepsicoProgram: telepsicoProgram,
      idProgram: idProgram,
      program: program,
      maxSessions: maxSessions,
      currentSession,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/queue/telepsychology/acionamentos/confirmed/${acionamento._id}`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}
