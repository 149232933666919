import { LabelError } from './style';

export interface LoginErrorProps {
  title: string;
  description?: string;
}

export function LoginError({ description, title }: LoginErrorProps) {
  return (
    <>
      <LabelError>{title}</LabelError>
      {description && <LabelError>{description}</LabelError>}
    </>
  );
}
