import styled from 'styled-components'

export const StyledTitle = styled.h2`
  color: #2e384d;
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 1rem;
  font-size: 28px;
  font-weight: 900;
  line-height: 30px;
`

export const StyledSubtitle = styled.p`
  line-height: 22px;
  font-size: 15px;
  color: rgba(121, 124, 129);
  width: 87%;
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`