import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users, enabledQueuesSet, enabledQueuesGet } from './users.reducer';
import { acionamento } from './acionamento.reducer';
import { cancelamento } from './cancelamento.reducer';
import { atividades } from './atividades.reducer';
import { editAcionamento } from './editAcionamento.reducer';
import { historico } from './historico.reducer';
import { convenios } from './convenios.reducer';
import { alert } from './alert.reducer';
import { alertContingencia } from './alertContingencia.reducer';
import { password } from './passwordReducer';
import { editHistoricos } from './editHistorico.reducer';
import { country } from './country.reducer';
import { region } from './region.reducer';
import { citie } from './citie.reducer';
import { acionamentospassagem } from './actionpassage.reducer';
import { paacionamentolist } from './paacionamentolist.reducer';
import { paacionamentocreate } from './paacionamentocreate.reducer';
import { editPAcionamento } from './editPAcionamento.reducer';
import { cockpit } from './cockpit.reducer';
import { cFinalizados } from './cFinalizados.reducer';
import { cancelamentoUser } from './cancelamentoUser.reducer';
import { cepToAddress } from './getAddresFromCep.reducer';
import { cancelamentoList } from './cancelamentoList.reducer';
import { deletedDocs } from './deleteDocs.reducer';
import { modalAlert } from './modalAlert.reducer';
import { forwardingSupport } from './forwardingSupport.reducer';
import { filaSuporte } from './suporte.reducer';
import { supportAcionamento } from './acionamentoSupport.reducer';
import { filaHistoricoSuporte } from './suporteHistory.reducer';
import { searchUser } from './searchUser.reducer';
import { agendamento } from './agendamento.reducer';
import { agendamentoTPsicol } from './agendamentoTPsicol.reducer';
import { doctor } from './doctorId.reducer';
import { editAgendamentoTPsicol } from './editAgendamentoTPsicol.reducer';
import { filaTelepsicologia } from './filaTelepsicologia.reducer';
import {
  getNotifications,
  getIdNotifications,
} from './getNotifications.reducer';
import { historicoLoader } from './historicoloader.reducer';
import { conveniosPortal } from './conveniosPortal.reducer';
import { subMenuItems,subMenuItemsBySocket } from './subMenu.reducer';
import { queueItems } from './queue.reducer';
import { socketInfos } from './socketTransport.reducer';
import { socketInfosTime } from './socketTransport.reducer';
import { loaderEditReducer } from './loaderEdit.reducer';
import { queues } from './allQueues.reducer';
import { products } from './products.reducer';

import { combineReducers } from '@reduxjs/toolkit';

import findAllQueueRecordsReducer from '../clean-arch/application/store/queue-records/find-all/find-all-slice';
import showQueueRecordsReducer from '../clean-arch/application/store/queue-records/show/find-all-slice';
import reportQueueRecordsReducer from '../clean-arch/application/store/queue-records/report/report-slice';

import reduxReducersScales from '../Spread/redux/reducers';

import { loginReducer } from './login.reducer';

const rootReducer = combineReducers({
  agendamento,
  agendamentoTPsicol,
  historicoLoader,
  doctor,
  editAgendamentoTPsicol,
  filaTelepsicologia,
  alertContingencia,
  authentication,
  registration,
  users,
  alert,
  password,
  convenios,
  cancelamento,
  acionamento,
  editAcionamento,
  historico,
  editHistoricos,
  country,
  queues,
  products,
  region,
  citie,
  acionamentospassagem,
  paacionamentolist,
  paacionamentocreate,
  editPAcionamento,
  cockpit,
  atividades,
  cFinalizados,
  cancelamentoUser,
  cepToAddress,
  cancelamentoList,
  deletedDocs,
  modalAlert,
  forwardingSupport,
  filaSuporte,
  supportAcionamento,
  filaHistoricoSuporte,
  searchUser,
  getNotifications,
  getIdNotifications,
  conveniosPortal,
  subMenuItems,
  subMenuItemsBySocket,
  queueItems,
  socketInfos,
  socketInfosTime,
  findAllQueueRecords: findAllQueueRecordsReducer,
  showQueueRecords: showQueueRecordsReducer,
  reportQueueRecords: reportQueueRecordsReducer,
  loaderEditReducer,
  enabledQueuesSet,
  enabledQueuesGet,
  loginReducer,
  ...reduxReducersScales,
});

export default rootReducer;

// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
// import { configureStore } from '@reduxjs/toolkit'

// import findAllQueueRecordsReducer from '../store/queue-records/find-all/find-all-slice'
// import showQueueRecordsReducer from '../store/queue-records/show/find-all-slice'
// import reportQueueRecordsReducer from '../store/queue-records/report/report-slice'

// export const store = configureStore({
//   reducer: {
//     findAllQueueRecords: findAllQueueRecordsReducer,
//     reportQueueRecords: reportQueueRecordsReducer,
//     showQueueRecords: showQueueRecordsReducer,
//   },
// })

// type AppDispatch = typeof store.dispatch
// type RootState = ReturnType<typeof store.getState>

// const useAppDispatch = () => useDispatch<AppDispatch>()
// const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// export const dispatch = useAppDispatch
// export const selector = useAppSelector

// export type UseAppDispatch = typeof useAppDispatch
// export type UseAppSelector = typeof useAppSelector