import React from 'react';
import { CreateComunicado } from './FormCreateComunicado';

import { HistoricoDetails } from '../SearchCpf/CustomTable';
import { ContainerTable } from './styles/formComunicados';
import Button from '../CustomButtom';
export function LogHistorico(props) {
  return (
    <div>
      <CreateComunicado
        initialValues={props.initialValues}
        disabled={props.disabled}
      />

      <ContainerTable>
        <HistoricoDetails
          type={'log'}
          rowData={props.initialValues.usersRead}
        />
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: 15,
          }}
        >
          <Button
            size={'medium'}
            typeButtom={'lightBlue'}
            text={'Voltar'}
            padding={'15'}
            onClick={() => {
              props.back();
            }}
          />
        </div>
      </ContainerTable>
    </div>
  );
}
