import styled from 'styled-components';

export const ContainerInitialsName = styled.div`
  width: 40px;
  height: 40px;
  background: ${(props) => props.backgroundColor};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #485cc1;
  font-size: 16px;
  margin-left: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ContainerNames = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 10px;
  max-width: 200px;
  text-align: start;
  p {
    width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: clip;
  }
`;

export const SmallText = styled.p`
  font-size: 12px;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;

  transition-property: opacity;
  transition-duration: 2s;

  span {
    word-wrap: break-word;
    padding-left: 0px;
  }
`;

export const DivActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContent = styled.span`
  padding: 0px;
  font-size: 15px;

  .iconWarning {
    color: #d35400;
    margin-top: 2px;
  }
`;
