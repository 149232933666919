import styled from 'styled-components';

export * from '../style';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 32px;
`;

export const MethodWrapper = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  align-items: center;
  padding: 16px 8px;
  border-radius: 4px;
  background-color: #f0f4f7;

  &:hover {
    border: 1px solid #b3b7c0;
  }

  &:disabled {
    cursor: not-allowed;

    p {
      color: #848994;
    }

    &:hover {
      border: 1px solid #f0f4f7;
    }
  }

  border: 1px solid #f0f4f7;
  margin: 0;
  outline: 0;
  cursor: pointer;

  .Icon {
    width: 20px;
    height: 20px;
  }

  .Arrow {
    width: 25px;
    height: 25px;
  }
`;

export const MethodTexts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dce0e8;
`;
