import { useEffect, useState } from 'react';

import { triggerService } from '../../../../../../../../../../Spread/services/core';

const useModalNotAttend = (show, onClose, data, trigger) => {
  const [useDataType, setDataType] = useState(null);
  const [useTypeFault, setTypeFault] = useState(null);
  const [useObervationInput, setObervationInput] = useState('');

  useEffect(() => {
    if (!show) {
      setDataType(null);
      setObervationInput('');
    } else {
      data?.currentEditionAttendanceInfo?.attendanceType ===
        'IN_WAIT_FOR_DATE_SCHEDULE' &&
        useDataType === null &&
        setDataType(true);
    }

    setTypeFault(null);
  }, [show, useDataType]);

  const handleObervationInputChange = (e) => {
    const value = e.target.value;

    setObervationInput(value);
  };

  const handleChangeTypeFault = (e) => {
    const value = e.target.value;

    setTypeFault(value);
  };

  const saveFault = async () => {
    if (!useTypeFault) {
      trigger({
        message: 'Selecione o motivo da falta.',
        type: 'danger',
        duration: 3000,
      });

      return;
    }

    const dataToUpdate = {
      contactSucess: useDataType,
      reason: useTypeFault,
      observation: useObervationInput,
    };

    const response = await triggerService.updateOne(data._id, dataToUpdate);

    response.status
      ? trigger({
          message: 'Falta registrada com sucesso.',
          type: 'success',
          duration: 3000,
        })
      : trigger({
          message: 'Houve um erro ao registrar falta.',
          type: 'danger',
          duration: 3000,
        });

    onClose(true);
  };

  return {
    useDataType,
    setDataType,
    useObervationInput,
    handleObervationInputChange,
    useTypeFault,
    handleChangeTypeFault,
    saveFault,
  };
};

export { useModalNotAttend };
