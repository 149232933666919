import styled from 'styled-components';

export const RadioRow = styled.div`
  width: 100%;

  .MuiFormGroup-root {
    display: flex;
    column-gap: 24px;
    flex-direction: row;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
`;

export const Page = styled.div`
  background-color: #f1f4f6;
  width: 100%;
`;

export const Container = styled.div`
  width: 60%;
  margin: auto;
  @media screen and (min-device-width: 800px) and (max-device-width: 1900px) {
    transform: scale(0.8);
  }
`;

export const ContainerPage = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconButton = styled.img`
  width: 30px;
  margin-right: 5px;
`;

export const FormAdmContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerInputs = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : '110px')};
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-right: 24px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
`;

export const LabelInput = styled.label`
  text-transform: uppercase;
  margin: 10px 0px 10px 0px;
  color: #2e384d;
  font-size: 12px;
`;

export const SelectDiv = styled.div`
  width: 100%;
`;

export const ErrorInput = styled.h5`
  color: #ff2424;
  margin: 0.5px 0 0.5px 0;
  font-size: 10px;
`;

export const ButtonsForm = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 30px 0;
  width: 100%;
`;

export const ContainerButton = styled.div`
  height: 40px;
  padding-right: 10px;
  position: ${(props) => (props.position ? props.position : '')};
  right: ${(props) => (props.right ? 0 : '')};
`;

export const Line = styled.hr`
  border: 1px solid #dfe3e8;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 730px;
`;

export const ContainerAccordion = styled.div`
  margin-top: 30px;
  padding-left: 30px;
`;

export const ContainerBodyAccordion = styled.div`
  width: 320px;
  padding: 10px;
  background: white;
`;

export const ImageDocument = styled.img`
  width: 300px;
  cursor: pointer;
`;

export const ImageDocumentZoom = styled.img`
  display: block;
  margin: 0 auto;
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 800px;
`;

export const ContainerListActions = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 200px;
  background: white;
`;

export const ItemList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #f1f4f6;
  margin: 4px 0px;
  padding: 10px 8px;
  cursor: pointer;

  &:nth-child(1) {
    margin-top: 12px;
  }
  &:nth-child(3) {
    margin-bottom: 10px;
  }
`;

export const ItemDescription = styled.p`
  color: var(---6b7786-texto2);
  text-align: left;
  font: normal normal normal 12px/14px Rubik;
  letter-spacing: 0px;
  color: #6b7786;
  opacity: 1;
  padding-left: 12px;
`;

export const AccordionIcon = styled.div`
  padding-left: 10px;
  color: #343e52;
  font-size: 14px;
`;

export const ContainerLoaderFastActions = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 160px;
  height: 40px;
`;
export const TryLoad = styled.div``;
export const TrySpan = styled.span``;

export const ModalText = styled.p`
  font-size: 16px;
`;

export const PushCallsCard = styled.div`
  background-color: #fff;
  padding: 16px 32px;
  width: 100%;
  margin-top: 3px;
`;

export const PushCallsCardTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  .chip {
    height: 32px;
    width: 58px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
  }
  .icon {
    margin-right: 8px;
  }
  h3 {
    font-size: 16px;
    text-align: start;
    padding-left: 16px;
  }
`;

export const PushCallsCardItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  height: 28px;
  width: 100%;
  font-size: 16px;
  padding: 16px 0;
  .time {
    font-weight: 600;
    width: 58px;
    text-align: center;
  }
  .username {
    padding: 0 16px;
  }
`;
