import React, { Component } from 'react';
import { Container } from './styles';
import moment from 'moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { avaliableMonth } from '../../../../helpers/getEnableOrDisableDates';
import { addDaysToDate, debounce } from '../../../../utils/utils';
import { CircularProgress } from '@material-ui/core';

class DateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      timeout: 100,
    };
  }

  handleDateChange = (e) => {
    this.setState({
      schedulingDate: e,
    });
    let formatedDate = moment(e).format('DD/MM/YYYY');
    let newState = { schedulingDate: formatedDate };
    this.props.handleSetState(newState, 'datemodal');
  };

  async componentWillMount() {
    console.log('this.props', this.props);

    this.setState({
      disabledDates: await avaliableMonth(
        1,
        false,
        await this.props.doctorRefId,
        true
      ),
    });
  }
  componentDidMount() {
    debounce(() => this.setState({ loader: false }), this.state.timeout);
  }
  componentWillUnmount() {}

  disableDates(date) {
    if (
      this.state.disabledDates !== undefined &&
      this.state.disabledDates.length > 0
    )
      return this.state.disabledDates.includes(
        moment(date).format('YYYY-MM-DD')
      );
  }

  render() {
    return (
      <Container>
        {this.state.loader ? (
          <CircularProgress color='primary' />
        ) : (
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={moment.locale('pt-br')}
          >
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              autoOk={true}
              margin='normal'
              name='schedulingDate'
              id='date-picker-inline'
              disablePast={true}
              format='DD/MM/YYYY'
              value={this.state.schedulingDate}
              shouldDisableDate={(date) => this.disableDates(date)}
              onChange={(e) => this.handleDateChange(e, 'schedulingDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDateMessage={'A data deve ser posterior a data atual!'}
              invalidDateMessage={'A data deve ser posterior a data atual!'}
            />
          </MuiPickersUtilsProvider>
        )}
      </Container>
    );
  }
}
export default DateModal;
