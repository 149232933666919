import React from 'react';
import { Alert as MuiAlert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

type Props = {
  handleClose: () => void;
  open: boolean;
  message: string;
  severity: 'error' | 'success' | 'info' | 'warning' | undefined;
};

export const Alert = ({ handleClose, open, message, severity }: Props) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <MuiAlert variant='filled' severity={severity} onClose={handleClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
