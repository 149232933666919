import React from 'react';

import {
  formatMask,
  displayValue,
  formatDate,
  getDiff,
} from '../../utils/format';

import { BoxInfo } from './components';

import {
  CustomModal,
  CustomText,
  CustomDivider,
  CustomAvatar,
  CustomButton,
} from '../../components/ui';

import {
  StyledModalContainer,
  StyledModalDataContainer,
  StyledModalData,
  StyledPatientDataContainer,
  StyledPatientDataBox,
  StyledButtonContainer,
} from './styles';

import useNotifications from './hooks/useNotifications';

const Notifications = () => {
  const { useNotification, handleTriggerStart } = useNotifications();

  return (
    <>
      {useNotification && (
        <CustomModal show={!!useNotification} width='900'>
          <StyledModalContainer>
            <CustomText color='dark' size='xlarge' title={1}>
              Acionamento encaminhado
            </CustomText>
            <CustomDivider />
            <CustomDivider border height={1} space={0} />
            <CustomDivider />

            <StyledModalDataContainer>
              <CustomAvatar
                name={
                  useNotification?.name && useNotification?.lastName
                    ? `${useNotification.name} ${useNotification.lastName}`
                    : ''
                }
                size='80'
              />
              <StyledModalData>
                <CustomText color='dark' title={1} size='large'>
                  {displayValue(useNotification?.name, '')}
                </CustomText>

                <CustomText color='dark' title={1} size='large'>
                  {displayValue(useNotification?.socialName, '')}
                </CustomText>

                <CustomDivider height={2} />

                <StyledPatientDataContainer>
                  <div>
                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Produto:'
                        value={displayValue(useNotification?.queue)}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Convênio:'
                        value={displayValue(useNotification?.convenioDescricao)}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='CPF:'
                        value={displayValue(
                          formatMask(
                            useNotification?.document,
                            '###.###.###-##'
                          ),
                          ''
                        )}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Data de nascimento:'
                        value={displayValue(
                          formatDate(useNotification?.birthDate),
                          ''
                        )}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Idade:'
                        value={displayValue(
                          `${getDiff(
                            new Date(),
                            useNotification?.birthDate,
                            'years'
                          )} anos`,
                          ''
                        )}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Sexo:'
                        value={displayValue(useNotification?.gender, '')}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Agendamento:'
                        value={displayValue(
                          formatDate(
                            useNotification?.appointment,
                            'DD/MM/YYYY HH:mm'
                          )
                        )}
                      />
                    </StyledPatientDataBox>
                  </div>

                  <div>
                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Email:'
                        value={displayValue(useNotification?.email, '')}
                      />
                    </StyledPatientDataBox>

                    <CustomDivider space={0} height={5} />

                    <StyledPatientDataBox>
                      <BoxInfo
                        label='Telefone:'
                        value={displayValue(
                          formatMask(useNotification?.phone, '## #########'),
                          ''
                        )}
                      />
                    </StyledPatientDataBox>
                  </div>
                </StyledPatientDataContainer>
              </StyledModalData>
            </StyledModalDataContainer>

            <CustomDivider height={5} />
            <CustomDivider border height={1} space={0} />
            <CustomDivider height={5} />

            <StyledButtonContainer>
              <CustomButton
                text='Iniciar atendimento'
                color='success'
                size='medium'
                onClick={() => handleTriggerStart()}
              />
            </StyledButtonContainer>
          </StyledModalContainer>
        </CustomModal>
      )}
    </>
  );
};

export default Notifications;
