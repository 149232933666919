import * as Yup from 'yup';

import { formErrors } from '../../../../../../../Spread/components/ui/webform/utils/errors';

import { regexOnlyLetters } from '../../../../../../../Spread/components/ui/webform/utils/regex';

import { validateFormNumberSchema } from '../../utils';

export const formSchema = Yup.object().shape({
  socialName: Yup.string()
    .matches(regexOnlyLetters, formErrors.notAllowedNumbers)
    .nullable(true),
  phone: Yup.string()
    .test({
      test: (value) => validateFormNumberSchema(value),
      message: formErrors.phoneNumberNotAllowed,
    })
    .nullable(true),
});
