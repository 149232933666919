import { useLayoutEffect, useRef, useState } from 'react';

const useSlide = (children, from) => {
  const allowedDirections = {
    left: 'left',
    right: 'right',
  };

  const currentDirection = allowedDirections[from] || allowedDirections.right;

  const childrenRef = useRef(null);
  const [useElement, setElement] = useState(false);
  const [useDistance, setDistance] = useState(null);
  const style = children.props.style || {};

  useLayoutEffect(() => {
    const timerRef = setTimeout(() => {
      const elementWidth = childrenRef.current.offsetWidth;

      setDistance(`-${elementWidth}px`);
    }, 0);

    const timerShow = setTimeout(() => {
      setElement(true);
      setDistance(null);
    }, 100);

    return () => {
      clearTimeout(timerRef);
      clearTimeout(timerShow);
    };
  }, []);

  return {
    currentDirection,
    useElement,
    useDistance,
    style,
    childrenRef,
  };
};

export default useSlide;
