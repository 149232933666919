import { useSelector } from 'react-redux';

import { PageProps } from './types';
import { IUserLoginState, ELoginSteps } from '../reducers/login.reducer';
import { LoginActions } from '../actions/login.actions';

import { LoginForm } from './Login';
import { RecoverPasswordForm } from './RecoverPassword';
import { PasswordSuccessForm } from './PasswordSuccess';
import { MfaSelectWay } from './MfaSelectWay';
import { MfaCodeInput } from './MfaCodeInput';

interface ILoginReducerProps {
  loginReducer: IUserLoginState;
}

export function LoginPage(props: PageProps) {
  const currentStep = useSelector(
    (state: ILoginReducerProps) => state?.loginReducer?.step
  );

  switch (currentStep) {
    case ELoginSteps.RECOVER_PASSWORD:
      return (
        <RecoverPasswordForm
          pageProps={props}
          onGoBackToLogin={() =>
            props.dispatch(LoginActions.ChangeStep(ELoginSteps.LOGIN))
          }
          onSuccess={() =>
            props.dispatch(
              LoginActions.ChangeStep(ELoginSteps.RECOVER_PASSWORD_SUCCESS)
            )
          }
        />
      );

    case ELoginSteps.RECOVER_PASSWORD_SUCCESS:
      return (
        <PasswordSuccessForm
          onConfirm={() =>
            props.dispatch(LoginActions.ChangeStep(ELoginSteps.LOGIN))
          }
        />
      );
    case ELoginSteps.MFA_SELECT:
      return <MfaSelectWay pageProps={props} />;
    case ELoginSteps.MFA_ENTER_CODE:
      return <MfaCodeInput pageProps={props} />;

    case ELoginSteps.LOGIN:
    default:
      return <LoginForm pageProps={props} />;
  }
}
