import {
  filaTelepsicologiaConstants,
  editFilaTelepsicologiaConstants,
  editAgendamentoTPsicolConstants,
  userConstants,
} from '../constants';
import { filaTelepsicologiaService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const filaTelepsicologia = {
  editAgendamentosTPisicol,
  editAgendamentosTPisicolWithoutRedirect,
  paginateFilaTelepsicologia,
  downloadJson,
  confirmedFilaTPisicol,
  register,
  put,
  newAgendamentoTPsicol,
  // confirmedAgendamentosTPisicol,
  // CreateSlot,
  // paginateFilaTelepsicologia,
  // filteredAllAgendaTPisicol,
};

function put(user) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    filaTelepsicologiaService
      .put(user)
      .then(
        (dados) => {
          dispatch(success());
          dispatch(alertActions.success('Acionamento atualizado.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function register(user, onNext) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    filaTelepsicologiaService
      .register(user)
      .then(
        (dados) => {
          dispatch(alertActions.success('Agendamento Criado.'));
          dispatch(success(user));
          history.push('/adm/fila/telepsicologia');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function editAgendamentosTPisicolWithoutRedirect(filaTelepsicologia) {
  return (dispatch) => {
    dispatch(request(filaTelepsicologia));
    if (filaTelepsicologia) {
      dispatch(success(filaTelepsicologia));
    } else {
      dispatch(failure());
    }
  };

  function request() {
    return {
      type: editFilaTelepsicologiaConstants.FILATELEPSICOLOGIA_EDIT_REQUEST,
    };
  }
  function success(filaTelepsicologia) {
    return {
      type: editFilaTelepsicologiaConstants.FILATELEPSICOLOGIA_EDIT_SUCCESS,
      filaTelepsicologia,
    };
  }
  function failure() {
    return {
      type: editFilaTelepsicologiaConstants.FILATELEPSICOLOGIA_EDIT_FAILURE,
    };
  }
}

function downloadJson(dataInicioCsv, dataFimCsv, page) {
  return filaTelepsicologiaService.DownloadJSON(
    dataInicioCsv,
    dataFimCsv,
    page
  );
}

function paginateFilaTelepsicologia(fields, page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request());

    filaTelepsicologiaService
      .getQueueTelepsicologia(fields, page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: filaTelepsicologiaConstants.FILATPISICOL_REQUEST };
  }
  function success(fila) {
    return {
      type: filaTelepsicologiaConstants.FILATPISICOL_SUCCESS,
      fila,
    };
  }
  function failure(error) {
    return {
      type: filaTelepsicologiaConstants.FILATPISICOL_FAILURE,
      error,
    };
  }
}

function editAgendamentosTPisicol(editAcionamentoTelepsicologia) {
  return (dispatch) => {
    dispatch(success(editAcionamentoTelepsicologia));
    history.push('/adm/fila/acionamento-telepsicologia');
  };
  function success(editAcionamentoTelepsicologia) {
    return {
      type: editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_SUCCESS,
      editAcionamentoTelepsicologia,
    };
  }
}

function newAgendamentoTPsicol() {
  return (dispatch) => {
    dispatch(request());
    history.push('/adm/fila/acionamento-telepsicologia');
  };
  function request() {
    return {
      type: editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_REQUEST,
    };
  }
}

function confirmedFilaTPisicol(acionamento) {
  return (dispatch) => {
    dispatch(request());

    filaTelepsicologiaService
      .confirmedTpisicoAcionamento(acionamento)
      .then(
        (body) => {
          history.push('/adm/fila/telepsicologia');
          dispatch(success(body));
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
