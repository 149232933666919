// Modules
import React from 'react';

//Styles
import {
  ContainerDashed,
  Container,
  ContainerLoader,
  CustomizedTable,
  ThCustom,
} from './styles';

//Components
import RowCustomDashed from '../../RowCustomDashed';
import TableActions from '../../TableActions';
import { QueueItem } from '../QueueItem';

/**
 * Queue component
 * @param {Object} props
 * @param {Array<Object>} props.data - The data array
 * @param {Array<Object>} props.columns
 * @param {any} props.messageForEmpty
 * @param {any} props.children
 * @param {any} props.loading
 * @param {any} props.loader
 * @param {any} props.defineEdit
 * @param {any} props.openSupport
 * @param {any} props.typeProfile
 * @param {any} props.setOpenScheduleCancel
 * @param {any} props.setItemSchedule
 * @param {any} props.setShowContentScheduleError
 * @param {any} props.cronometer
 */
const Queue = ({
  data,
  columns,
  messageForEmpty,
  children,
  loading,
  loader,
  defineEdit,
  openSupport,
  typeProfile,
  setOpenScheduleCancel,
  setItemSchedule,
  setShowContentScheduleError,
  cronometer,
  alertProfile,
}) => {
  const doctorOrSupportAttendance = (element) => {
    const typesNoEdit = [
      'IN_ATTENDANCE_DOCTOR',
      'IN_WAIT_ATTENDANCE_DOCTOR',
      'VONAGE_IN_ATTENDANCE_SUPPORT',
      'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT',
      'IN_WAIT_FOR_DATE_SCHEDULE',
      'NO_SHOW',
    ];

    return typesNoEdit.includes(
      element?.currentEditionAttendanceInfo?.attendanceType
    );
  };
  const isSupportAttendance = (element) => {
    const typesNoEdit = [
      'VONAGE_IN_ATTENDANCE_SUPPORT',
      'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT',
    ];

    return typesNoEdit.includes(
      element?.currentEditionAttendanceInfo?.attendanceType
    );
  };

  const updatedTime = () => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    return editionTime;
  };

  const handleClick = (element) => {
    return () => {
      const editionTime = updatedTime();
      localStorage.setItem(
        'userEdition',
        JSON.stringify({ ...element, editionTime })
      );
      const isInSupport = isSupportAttendance(element);
      const isReturnedFromReception = element?.return_to_reception;
      const isInAttendance = doctorOrSupportAttendance(element);
      const enableProfiles = typeProfile;

      const shouldNotEditWhenReturnedInSupport =
        isInSupport && !enableProfiles && isReturnedFromReception;
      if (shouldNotEditWhenReturnedInSupport) return alertProfile();

      if (!isInAttendance && !(enableProfiles && isInSupport)) {
        defineEdit(element._id, element);
      } else {
        if (enableProfiles && isInSupport) {
          openSupport(element);
        }
      }
    };
  };

  return (
    <Container>
      <CustomizedTable width='100%' border='0' cellSpacing='0' cellPadding='0'>
        <tbody>
          <tr key={data._id} className={'rubik-upper'}>
            {columns &&
              columns.map((el, index) => {
                let customAlign = index === 0 ? 'left' : 'center';
                let customWidth =
                  index === 0
                    ? '250px'
                    : document.getElementById(el.title)?.clientWidth + 1 + 'px';

                return (
                  <ThCustom
                    key={el.title}
                    customWidth={customWidth}
                    id={el.title}
                    align={customAlign}
                    heigth={'20px'}
                    padding={'0 5px'}
                  >
                    <span> {el.title}</span>
                    {typeof el.title != 'string' ? <div> {children} </div> : ''}
                  </ThCustom>
                );
              })}
          </tr>
        </tbody>

        {data?.length
          ? data.map((element, index) => (
              <QueueItem
                key={element._id + index}
                itemData={element}
                isHover={
                  element?.currentEditionAttendanceInfo?.attendanceType ===
                    'PENDING_CONTACT' ||
                  !doctorOrSupportAttendance(element) ||
                  (typeProfile && isSupportAttendance(element))
                }
                onClick={handleClick(element)}
                columns={columns}
                chronometer={cronometer}
                isClinicQueue={element?.queue === 'clinica'}
              >
                <TableActions
                  setShowContentScheduleError={setShowContentScheduleError}
                  disabledForSupport={!isSupportAttendance(element)}
                  acionamento={element}
                  setOpenScheduleCancel={setOpenScheduleCancel}
                  setItemSchedule={setItemSchedule}
                />
              </QueueItem>
            ))
          : null}
      </CustomizedTable>
      {data.length == 0 ? (
        <ContainerDashed>
          <RowCustomDashed
            opacity='1'
            borderColor='#A7ABB2'
            customHeight={75}
            align='center'
            justify='center'
          >
            <span>{messageForEmpty}</span>
          </RowCustomDashed>
        </ContainerDashed>
      ) : (
        loading && <ContainerLoader>{loader}</ContainerLoader>
      )}
    </Container>
  );
};

export default Queue;
