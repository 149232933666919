import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: 'xs',
    marginTop: '10px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '11px',
    color: 'red',
  },
  select: {
    height: '30px',
    width: '370px',
  },
  sucess: {
    backgroundColor: green[600],
  },
  boxdialog: {
    borderRadius: '20px',
    margin: '0 auto 0 auto',
    '@media only screen and (max-width: 900px)': {
      padding: '0 0 20px 0',
      margin: '0 auto 0 auto',
      '@media screen and (orientation: landscape)': {
        margin: '0 auto',
      },
    },
  },
  dialog_modal: {
    borderRadius: '20px',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    margin: '0 auto',
  },
  titleDialog: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'Poppins',
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
  },
  close: {
    position: 'absolute',
    width: '45px',
    top: '2px',
  },
  rootStyle: {
    borderRadius: 20,
  },
  dialogAlert: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
}));

export default function DialogModalCancelSchedule({
  user,
  open,
  title,
  text,
  handleCancel,
  handleConfirm,
  motivo,
  handleChange,
}) {
  const classes = useStyles();

  const [log, setLog] = useState([]);
  const [motivosRecusa, setMotivosRecusa] = useState([
    { value: 'Outros' },
    { value: 'Paciente não estava conectado ao iniciarmos o contato' },
    { value: 'Perda de conexão durante o atendimento' },
    { value: 'Conexão ruim / Inapropriada para atendimento' },
    { value: 'Atendimento para outro paciente' },
    { value: 'Não quis aguardar o tempo de espera médica' },
    { value: 'Local inapropriado para atendimento' },
    { value: 'Não estava com documento com foto em mãos (Digital ou Físico)' },
    { value: 'Não sabia o número da carteirinha' },
    { value: 'Assuntos relacionados ao convênio' },
    { value: 'Testes' },
    { value: 'Queria conhecer o funcionamento do serviço' },
    { value: 'Dúvidas não atendidas pela telemedicina' },
    { value: 'Agendamento de consultas / exames' },
  ]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        className={classes.boxdialog}
        open={open}
        onEnter={handleConfirm}
      >
        <DialogActions>
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            className={classes.close}
            onClick={handleCancel()}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </DialogActions>
        <div
          style={{ width: '400px', display: 'flex', justifyContent: 'center' }}
        >
          <DialogTitle
            style={{ fontSize: '18px', fontWeight: '500', lineHeight: '27px' }}
            id='alert-dialog-title'
          >
            {title}
          </DialogTitle>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            margin: '0 10px',
          }}
        >
          <FormControl
            variant='outlined'
            className={classes.formControl}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '400px',
            }}
          >
            <DialogTitle style={{ padding: '0', marginBottom: '8px' }}>
              <span
                style={{
                  fontSize: '16px',
                  color: '#424242',
                  fontWeight: '400',
                  lineHeight: '21px',
                }}
              >
                {text}
              </span>
            </DialogTitle>
            <Select
              name='motivoCancel'
              className={classes.select}
              value={motivo}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value=''>
                <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>Motivo</em>
              </MenuItem>
              {motivosRecusa.map((motivo, index) => (
                <MenuItem key={index} value={motivo.value}>
                  {motivo.value}
                </MenuItem>
              ))}
            </Select>
            {motivo === '' && (
              <FormHelperText
                style={{ margin: '0' }}
                id='component-helper-text'
              >
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '64px',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='extended'
            aria-label='Delete'
            className={classes.fab}
            color='primary'
            style={{
              fontSize: '10px',
              width: '30%',
              height: '36px',
              background: 'white',
              color: '#6C6C6C',
              boxShadow: 'none',
              border: '1px solid',
              margin: '1%',
              borderRadius: '20px',
            }}
            onClick={handleCancel()}
          >
            Cancelar
          </Button>
          <Button
            variant='extended'
            aria-label='Delete'
            className={classes.fab}
            color='primary'
            style={{
              fontSize: '10px',
              width: '30%',
              height: '36px',
              background: 'white',
              color: 'red',
              boxShadow: 'none',
              border: '1px solid',
              margin: '1%',
              borderRadius: '20px',
            }}
            onClick={handleConfirm()}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
