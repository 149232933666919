import { ActionPassageCreateConstants } from '../constants';

export function paacionamentocreate(state = { paacionamentolist: {} }, action) {
  switch (action.type) {
    case ActionPassageCreateConstants.PAACIONAMENTO_CREATE_SUCCESS:
      return {
        type: 'success',
        paacionamentocreated: action.result,
      };
    case ActionPassageCreateConstants.PAACIONAMENTO_CREATE_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        paacionamentocreated: {},
      });
    case ActionPassageCreateConstants.PAACIONAMENTO_CREATE_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
