import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const notificationsService = { Notifications, getIdNotifications };

async function Notifications(data) {
  let user = JSON.parse(window.localStorage.user);
  let payload = {
    userId: data._id,
    notificationsId: data.idAviso,
    user: user.user,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': data.token,
      _id: user.user._id,
    },
    body: JSON.stringify({ payload }),
  };

  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/notifications/save`,
      requestOptions,
      2000
    )
    .then(userService.handleResponse);
}

async function getIdNotifications(data) {
  let user = JSON.parse(window.localStorage.user);
  let payload = { userId: user.user._id };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': data.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({ payload }),
  };

  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/notifications/id`,
      requestOptions,
      2000
    )
    .then(userService.handleResponse);
}
