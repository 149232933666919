import styled from 'styled-components';

const getTableAlign = (align) => {
  const aligns = {
    left: 'left',
    right: 'right',
    center: 'center',
    default: 'left',
  };

  return aligns[align] || aligns.default;
};

export const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px 20px;

  td,
  th {
    text-align: ${({ align }) => getTableAlign(align)};
  }
`;

export const StyledTableHeader = styled.thead`
  tr {
    th {
      @media (max-width: 480px) {
        &:not(:first-child) {
          display: none;
        }
      }

      padding: 5px 20px;
      text-transform: uppercase;
    }

    font-size: 13px;
    font-weight: 200;
    font-family: 'rubik';
    color: #a8a8a8;
  }
`;

export const StyledTableBody = styled.tbody`
  background: #fff;
  border-radius: 8px;

  ${({ empty }) => empty && `display: none;`}

  tr {
    td {
      @media (max-width: 480px) {
        &:not(:first-child) {
          display: none;
        }
      }

      padding: 20px;
    }

    td:first-child {
      border-radius: 6px 0px 0px 6px;
    }

    td:last-child {
      border-radius: 0px 6px 6px 0px;
    }

    font-size: 13px;
    font-weight: 200;
    font-family: 'rubik';
    color: #a8a8a8;
    text-align: center;
  }
`;

export const StyledContainerNoData = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px 20px 0px;
`;

export const StyledTableMessage = styled.span`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #a8a8a8;
  color: #a8a8a8;
  text-align: center;
  font-weight: bold;
`;
