const name = `SCHEDULE`

const states = {
	pause: {
		execute: `${name}_PAUSE`,
		success: `${name}_PAUSE_SUCCESS`,
		error: `${name}_PAUSE_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	status: {
		execute: `${name}_STATUS`,
		success: `${name}_STATUS_SUCCESS`,
		error: `${name}_STATUS_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	unlock: {
		execute: `${name}_UNLOCK`,
		success: `${name}_UNLOCK_SUCCESS`,
		error: `${name}_UNLOCK_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	requestUnlock: {
		execute: `${name}_REQUEST_UNLOCK`,
		success: `${name}_REQUEST_UNLOCK_SUCCESS`,
		error: `${name}_REQUEST_UNLOCK_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	nextPause: {
		execute: `${name}_NEXT_PAUSE`,
		success: `${name}_NEXT_PAUSE_SUCCESS`,
		error: `${name}_NEXT_PAUSE_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},
}

const types = { states }
export default types
