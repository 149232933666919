import { suporteConstants, historySuporteConstants } from '../constants';
import { filaSuporteService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';

export const filaSuporte = {
  deleteAcionamentosSuporte,
  paginateFilaSuporte,
  paginateFilaSuporteHistory,
  filterAllHistoricosSupport,
  downloadJson,
  getCount,
};

function getCount() {
  return filaSuporteService.getCountSuport();
}

function downloadJson(dataInicioCsv, dataFimCsv, page) {
  return filaSuporteService.DownloadJSON(dataInicioCsv, dataFimCsv, page);
}

function paginateFilaSuporte(page = 1, qtd = 5, queryString = '') {
  return (dispatch) => {
    dispatch(request());
    filaSuporteService
      .getQueueSuport(page, qtd, queryString)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: suporteConstants.SUPORT_REQUEST };
  }
  function success(suporte) {
    return {
      type: suporteConstants.SUPORT_SUCCESS,
      suporte,
    };
  }
  function failure(error) {
    return {
      type: suporteConstants.SUPORT_FAILURE,
      error,
    };
  }
}

function paginateFilaSuporteHistory(fields, page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request());

    filaSuporteService
      .getQueueSuportHistory(fields, page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: historySuporteConstants.SUPORT_HISTORY_REQUEST };
  }
  function success(filaSuporte) {
    return {
      type: historySuporteConstants.SUPORT_HISTORY_SUCCESS,
      filaSuporte,
    };
  }
  function failure(error) {
    return {
      type: historySuporteConstants.SUPORT_HISTORY_FAILURE,
      error,
    };
  }
}

function deleteAcionamentosSuporte(
  id,
  reason,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  return (dispatch) => {
    dispatch(request());
    filaSuporteService
      .deleteAcionamentoSuporte(
        id,
        reason,
        urgencyAttendance,
        urgencyAttendenceReason
      )
      .then(
        (body) => {
          dispatch(success(body));
          history.push('/adm');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: suporteConstants.SUPORT_REQUEST };
  }
  function success() {
    return { type: suporteConstants.SUPORT_SUCCESS };
  }
  function failure(error) {
    return { type: suporteConstants.SUPORT_FAILURE, error };
  }
}

function filterAllHistoricosSupport(fields, page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request);
    filaSuporteService
      .GetFilterHistoricos(fields, page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: historySuporteConstants.SUPORT_HISTORY_REQUEST };
  }
  function success(historicos) {
    return { type: historySuporteConstants.SUPORT_HISTORY_SUCCESS, historicos };
  }
  function failure(error) {
    return { type: historySuporteConstants.SUPORT_HISTORY_FAILURE, error };
  }
}
