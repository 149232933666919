import {
  editAgendamentoTPsicolConstants,
  agendamentoTPsicolConstants,
  userConstants,
} from '../constants';
import { agendamentoTPsicolService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';

export const agendamentoTPsicol = {
  editAgendamentosTPsicol,
  editAgendamentosTPsicolWithoutRedirect,
  confirmedAgendamentosTPisicol,
  CreateSlot,
  paginateAgendamentosTPsicol,
  filteredAllAgendaTPsicol,
  getAllAgendamentosTPsicol,
  filterAgenda,
};

// function filteredAllAgendaTPsicol(fields, page = 1, qtd = 5) {
//   return (dispatch) => {
//     dispatch(request);
//     agendamentoTPisicolService
//       .getFilteredAllScheduleTPsicol(fields, page, qtd)
//       .then(
//         (body) => {
//           dispatch(success(body));
//         },
//         (error) => {
//           dispatch(failure(error));
//           dispatch(alertActions.error(error.message));
//         }
//       )
//       .catch((error) => {
//         dispatch(failure(error.toString()));
//       });
//   };
//   function request() {
//     return { type: agendamentoTPisicolConstants.AGENDAMENTOTPISICOL_REQUEST };
//   }
//   function success(agendamentos) {
//     return {
//       type: agendamentoTPisicolConstants.AGENDAMENTOTPISICOL_SUCCESS,
//       agendamentos,
//     };
//   }
//   function failure(error) {
//     return {
//       type: agendamentoTPisicolConstants.AGENDAMENTOTPISICOL_FAILURE,
//       error,
//     };
//   }
// }

function editAgendamentosTPsicolWithoutRedirect(editagendamentostpsicol) {
  return (dispatch) => {
    dispatch(request(editagendamentostpsicol));
    if (editagendamentostpsicol) {
      dispatch(success(editagendamentostpsicol));
    } else {
      dispatch(failure());
    }
  };

  function request() {
    return {
      type: editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_REQUEST,
    };
  }
  function success(editagendamentostpsicol) {
    return {
      type: editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_SUCCESS,
      editagendamentostpsicol,
    };
  }
  function failure() {
    return {
      type: editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_FAILURE,
    };
  }
}

function paginateAgendamentosTPsicol(pageSize = 5, page = 1, queryString = '') {
  return new Promise((resolve) => {
    agendamentoTPsicolService
      .paginateAllAgendamentosTPisicol(pageSize, page, queryString)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        return resolve({
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        });
      });
  });
}

function confirmedAgendamentosTPisicol(agendamentoTPsicol) {
  var tempData = agendamentoTPsicol.birthDate.split('/');
  agendamentoTPsicol.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());

    agendamentoTPsicolService
      .confirmedAcionamentos(agendamentoTPsicol)
      .then(
        (body) => {
          history.push('/adm');
          dispatch(success(body));
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function editAgendamentosTPsicol(agendamento) {
  return (dispatch) => {
    dispatch(request(agendamento));
    if (agendamento) {
      dispatch(success(agendamento));
    } else {
      dispatch(failure());
    }
    history.push('/adm/agendamentoTPsicol');
  };
  function request() {
    return { type: editAgendamentoTPsicolConstants.AGENDAMENTO_EDIT_REQUEST };
  }
  function success(agendamento) {
    return {
      type: editAgendamentoTPsicolConstants.AGENDAMENTO_EDIT_SUCCESS,
      agendamento,
    };
  }
  function failure() {
    return { type: editAgendamentoTPsicolConstants.AGENDAMENTO_EDIT_FAILURE };
  }
}

//create slots de horarios
//todo fix
function CreateSlot(agendamentoTPsicol) {
  return (dispatch) => {
    agendamentoTPsicolService
      .createSlot(agendamentoTPsicol)
      .then(
        (body) => {
          if (body.status === 422) dispatch(alertActions.error(body.message));
          else
            dispatch(
              alertActions.success(
                body.message || 'Agendamento criado com sucesso!'
              )
            );
        },
        (error) => {
          dispatch(
            alertActions.error(error.message || 'Erro ao criar o agendamento.')
          );
        }
      )
      .catch((error) => {
        dispatch(alertActions.error(error.message));
      });
  };
}

function filterAgenda(agendamentos) {
  return (dispatch) => {
    dispatch(clear());
    dispatch(filter(agendamentos));

    function clear() {
      return {
        type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_CLEAR,
        agendamentos: [],
      };
    }

    function filter(agendamentos) {
      return {
        type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_FILTER,
        agendamentos,
      };
    }
  };
}

function filteredAllAgendaTPsicol(fields, page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request);
    agendamentoTPsicolService
      .getFilteredAllScheduleTPsicol(fields, page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
        //dispatch(alertActions.error(error.message));
      });
  };
  function request() {
    return { type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_REQUEST };
  }
  function success(agendamentos) {
    return {
      type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_SUCCESS,
      agendamentos,
    };
  }
  function failure(error) {
    return {
      type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_FAILURE,
      error,
    };
  }
}

function getAllAgendamentosTPsicol(payload) {
  return (dispatch) => {
    dispatch(request);
    agendamentoTPsicolService
      .getSchedules(payload)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
        //dispatch(alertActions.error(error.message));
      });
  };
  function request() {
    return { type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_REQUEST };
  }
  function success(agendamentos) {
    return {
      type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_SUCCESS,
      agendamentos,
    };
  }
  function failure(error) {
    return {
      type: agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_FAILURE,
      error,
    };
  }
}

// function getAllAgendamentosTPsicol(fields, page = 1, qtd = 5) {
//   return (dispatch) => {
//     dispatch(request());

//     agendamentoTPsicolService
//       .getSchedules(fields, page, qtd)
//       .then(
//         (body) => {
//           dispatch(success(body));
//         },
//         (error) => {
//           dispatch(failure(error));
//           dispatch(alertActions.error(error.message));
//         }
//       )
//       .catch((error) => {
//         dispatch(failure(error.toString()));
//       });
//   };

//   function request() {
//     return { type: agendamentoTPsicolConstants.AGENDAMENTOTPISICOL_REQUEST };
//   }
//   function success(agendamentos) {
//     return {
//       type: agendamentoTPsicolConstants.AGENDAMENTOTPISICOL_SUCCESS,
//       agendamentos,
//     };
//   }
//   function failure(error) {
//     return {
//       type: agendamentoTPsicolConstants.AGENDAMENTOTPISICOL_FAILURE,
//       error,
//     };
//   }
// }
