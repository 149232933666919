export const notificationsConstants = {
  NOTIFICATIONS_REQUEST: 'NOTIFICATIONS_REQUEST',
  NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS_SUCCESS',
  NOTIFICATIONS_FAILURE: 'NOTIFICATIONS_FAILURE',
};

export const getIdNotificationsConstants = {
  GET_ID_NOTIFICATIONS_REQUEST: 'GET_ID_NOTIFICATIONS_REQUEST',
  GET_ID_NOTIFICATIONS_SUCCESS: 'GET_ID_NOTIFICATIONS_SUCCESS',
  GET_ID_NOTIFICATIONS_FAILURE: 'GET_ID_NOTIFICATIONS_FAILURE',
};
