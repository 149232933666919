import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, 'Deve conter no minimo 6 caracteres')
    .required('Campo obrigatório'),
  newPassword: Yup.string()
    .min(8, 'Deve conter no minimo 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Senha inválida. A senha deve conter letra maiúscula, minúscula, números, caracteres especiais e no mínimo 8 dígitos.'
    )
    .required('Campo obrigatório'),
  confirmedNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'As senhas divergem')
    .required('Campo obrigatório'),
});
