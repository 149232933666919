import { deleteDocs } from '../constants/deletedocs.constants';
import { deleteService } from '../services/deleteDocs.service';
import { modalAlertActions } from './modalAlert.action';

export const actionDeleteDocs = {
  deleteDocsUser,
};

function deleteDocsUser(user, docUpdate, setLoading, setSignUpDocuments) {
  return (dispatch) => {
    dispatch(request());
    deleteService
      .deleteDocs(user)
      .then((body) => {
        let userEdition = JSON.parse(localStorage.getItem('userEdition'));
        userEdition.signUpDocuments = [];
        setLoading(false);
        dispatch(success(body));
        dispatch(modalAlertActions.success(body.message));
        docUpdate(true);
        localStorage.setItem('userEdition', JSON.stringify(userEdition));
        setSignUpDocuments([]);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(failure(error.toString()));
        dispatch(modalAlertActions.error(error.message));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: deleteDocs.DELETE_DOCS_REQUEST };
  }

  function success(deleted) {
    return { type: deleteDocs.DELETE_DOCS_SUCCESS, deleted };
  }

  function failure(error) {
    return { type: deleteDocs.DELETE_DOCS_ERROR, error };
  }
}
