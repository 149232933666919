import { useState } from 'react';

import { cepMaxLength } from '../constants';

import { sleep } from '../../../../../../utils/common';

const useInput = (onChange, onRequestCallback, type) => {
  const [useIsLoading, setIsLoading] = useState(false);

  const handleChange = async (e) => {
    onChange?.(e);

    if (type === 'CEP' && e.target.value.length === cepMaxLength) {
      setIsLoading(true);

      await onRequestCallback?.(e.target.value);
      await sleep(100);

      setIsLoading(false);
    }
  };

  return {
    handleChange,
    useIsLoading,
  };
};

export default useInput;
