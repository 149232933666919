export const colors = {
  white: '#fff',
  warning: '#FFB324',
  primary: '#2699fb',
  danger: '#FF2424',
  success: '#33AC2E',
  default: '#a7abb2',
};

export const rootStyle = {
  border: 0,
  height: 'max-content',
};

export const types = {
  info: colors.primary,
  danger: colors.danger,
  success: colors.success,
  warning: colors.warning,
  default: colors.default,
};
