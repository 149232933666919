import React from 'react';
import MenuPainel from '../components/MenuPainel';
import AdmAgendamento from '../components/Agendamento';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';

class Agendamento extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ChangePassword />
        <MenuPainel>
          <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={<AdmAgendamento />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = Agendamento;
export { connectedAssinar as Agendamento };
