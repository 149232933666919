import React from 'react';
import { Container } from './styles';

const CustomTitle = ({
  title,
  pos,
  level,
  className,
  children,
  width,
  height,
}) => {
  return (
    <Container
      className={className}
      pos={pos}
      level={level}
      width={width}
      height={height}
    >
      <h1> {title} </h1>
      {children}
    </Container>
  );
};

export default CustomTitle;
