import { useEffect } from 'react';

const useModal = (show) => {
  useEffect(() => {
    const body = document.querySelector('body');
    const app = document.querySelector('#root');

    app.style.overflow = show ? 'hidden' : 'initial';
    body.style.overflow = show ? 'hidden' : 'auto';

    return () => {
      body.style.overflow = 'auto';
      app.style.overflow = 'initial';
    };
  }, [show]);
};

export default useModal;
