import { loadingHistoricoConstants } from '../constants';

export const historicoLoader = {
  success,
  error,
  request,
};

function success(message) {
  return { type: loadingHistoricoConstants.HISTORICO_LOADING_SUCCESS };
}

function error(message) {
  return { type: loadingHistoricoConstants.HISTORICO_LOADING_FAILURE };
}

function request(props, type, message) {
  return { type: loadingHistoricoConstants.HISTORICO_LOADING_REQUEST };
}
