import { cancelamentoUserConstants } from '../constants';

export function cancelamentoUser(state = { cancelamentosUser: {} }, action) {
  switch (action.type) {
    case cancelamentoUserConstants.CANCELAMENTO_USER_SUCCESS:
      return {
        type: 'success',
        cancelamentosUser: action.cancelamentosUser,
      };
    case cancelamentoUserConstants.CANCELAMENTO_USER_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        cancelamentosUser: {},
      });
    case cancelamentoUserConstants.CANCELAMENTO_USER_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
