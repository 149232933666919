import { doctorIdConstants } from '../constants/doctorId.constants';
export function doctor(state = { doctorRefId: {} }, action) {
  switch (action.type) {
    case doctorIdConstants.SET_DOCTOR_ID:
      return {
        type: 'set',
        doctorRefId: action.doctorRefId,
      };
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
