import { useState, useEffect, useRef } from 'react';
import { CustomSnackbar } from '../../../../components/ui';
import actions from '../../../../redux/scale/actions';
import { scalesService } from '../../../../services/core';

const pageLimit = 10;

const initialState = {
  entryTime: '',
  departureTime: '',
  snackBreakTime: '',
  restBreakTime: '',
  snackTime: '20',
  restTime: '10',
  unit: 'Morumbi',
  dates: [],
};

const useRegisterUserScale = () => {
  const { trigger, snack } = CustomSnackbar();
  const offset = useRef(0);

  const [professional, setProfessional] = useState('');
  const [useLoading, setLoading] = useState(false);
  const [scales, setScales] = useState(null);
  const [useLoadingPage, setLoadingPage] = useState(false);
  const [useLoadingDeleteScales, setLoadingDeleteScales] = useState(false);
  const [
    {
      entryTime,
      departureTime,
      snackBreakTime,
      restBreakTime,
      snackTime,
      restTime,
      unit,
      dates,
    },
    setState,
  ] = useState(initialState);

  const [date, setDate] = useState({});
  const [dateForm, setDateForm] = useState([]);
  const [sendForm, setSendForm] = useState([]);

  const searchMoreData = async () => {
    const updatedOffset = offset.current + pageLimit;

    const { data } = await actions.serviceGrid(
      professional,
      pageLimit,
      updatedOffset
    );

    const loadedScales = data?.scales || [];

    if (loadedScales.length) {
      offset.current = offset.current + pageLimit;

      const totalScales = [...scales, ...loadedScales];

      setScales(totalScales);
    }
  };

  const clearState = () => {
    setState({ ...initialState });
    setDateForm([]);
    setScales(null);
    handleProfessional('');
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateField = (field) => {
    const { label, value } = field;

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const updateGrid = async () => {
    if (professional) {
      const { data } = await actions.serviceGrid(professional);

      const scales = data?.scales.length > 0 ? data.scales : null;

      setScales(scales);
    } else {
      setScales(null);
    }
  };

  const handleSubmit = async () => {
    const dataSend = {
      entryTime,
      departureTime,
      snackBreakTime,
      restBreakTime,
      snackTime,
      restTime,
      unit,
      dates,
      professional: professional.label,
    };

    const errors = validate(dataSend);

    if (Object.keys(errors).length === 0) {
      setLoading(true);

      await sendData(dataSend);

      setLoading(false);
    } else {
      for (let error of Object.values(errors)) {
        trigger({
          message: error,
          type: 'danger',
          duration: 5000,
        });
      }
    }
  };

  const mapResponseToValuesAndLabels = (data) => ({
    value: data.id,
    label: data.name,
  });

  const validate = (data) => {
    let errors = {};
    if (data.dates.length === 0) {
      errors.dates = 'Selecione ao menos uma data';
    }
    if (!data.professional) {
      errors.professional = 'Selecione o Profissional';
    }
    if (!data.entryTime) {
      errors.entryTime = 'Preencha com uma hora de entrada válida';
    }
    if (!data.departureTime) {
      errors.departureTime = 'Preencha com uma hora de saída válida';
    }
    if (!data.snackBreakTime) {
      errors.snackBreakTime = 'Preencha com uma hora de lanche válida';
    }
    if (!data.restBreakTime) {
      errors.restBreakTime = 'Preencha com uma hora de descanso válida';
    }
    return errors;
  };

  const resetOffset = () => {
    if (offset.current) {
      offset.current = 0;
    }
  };

  useEffect(() => {
    async function loadUsers() {
      if (professional) {
        setScales([]);

        resetOffset();

        let scales = null;

        const { data } = await actions.serviceGrid(professional, 90 );

        if (data?.scales?.length) {
          scales = data.scales;
        }

        setScales(scales);
      }
    }
    loadUsers();
  }, [professional]);

  useEffect(() => {
    if (date.dateClicked !== undefined) {
      const dateClick = date.dateClicked?.format?.();

      let [dia, mes, ano] = dateClick.split(/[\/ :]/).map((v) => parseInt(v));

      const dataParse = new Date(Date.UTC(ano, mes - 1, dia))
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);

      dateForm.push(dataParse);
    }
  }, [date]);

  useEffect(() => {
    if (sendForm.length > 0) {
      const dateFilter = sendForm.filter(
        (ele, pos) => sendForm.indexOf(ele) == pos
      );

      setSendForm([]);
      onChange({ target: { name: 'dates', value: dateFilter } });
    }
  }, [sendForm]);

  const sendData = async (data) => {
    const payload = {
      userId: professional?.value.toString(),
      userName: professional?.label,
      entryTime: data.entryTime,
      departureTime: data.departureTime,
      snackBreakTime: data.snackBreakTime,
      snackBreakDuration: data.snackTime,
      restBreakTime: data.restBreakTime,
      restBreakDuration: data.restTime,
      unit: data.unit,
      dates: data.dates,
    };

    const { status } = await scalesService.create(payload);

    if (status) {
      trigger({
        message: 'Escala criada com sucesso',
        type: 'success',
        duration: 3000,
      });
    } else {
      trigger({
        message: 'Falha ao criar escala',
        type: 'danger',
        duration: 3000,
      });
    }

    clearState();
  };

  const handleRemove = async (id) => {
    setLoadingPage(true);

    const { status } = await scalesService.remove(id);

    if (status) {
      trigger({
        message: 'Escala removida com sucesso',
        type: 'success',
        duration: 3000,
      });

      await updateGrid();
    } else {
      trigger({
        message: 'Falha ao remover escala',
        type: 'danger',
        duration: 3000,
      });
    }

    setLoadingPage(false);
  };

  const handleProfessional = (e) => {
    setProfessional(e);
  };

  const getUsers = async (value) => {
    if (value?.length < 4) return;
    const { data } = await actions.serviceUser(value);
    const users = data.users.map(mapResponseToValuesAndLabels);
    return users.filter((i) =>
      i.label.toLowerCase().includes(value.toLowerCase())
    );
  };

  const removeAllScales = async (scales) => {
    setLoadingDeleteScales(true);
    const { status, message } = await scalesService.removeScales(professional.value, scales);

    setLoadingDeleteScales(false);

    if (status) {
      trigger({
        message: 'Escalas removidas com sucesso',
        type: 'success',
        duration: 3000,
      });

      updateGrid();
    } else {
      trigger({
        message: message || 'Falha ao remover escalas',
        type: 'danger',
        duration: 3000,
      });
    }
  };

  return {
    useLoadingDeleteScales,
    removeAllScales,
    useLoadingPage,
    snack,
    useLoading,
    professional,
    handleProfessional,
    getUsers,
    setDate,
    setSendForm,
    dates,
    entryTime,
    onChange,
    departureTime,
    snackBreakTime,
    snackTime,
    restBreakTime,
    restTime,
    unit,
    handleSubmit,
    scales,
    handleRemove,
    dateForm,
    updateField,
    searchMoreData,
    clearState,
  };
};

export default useRegisterUserScale;
