import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import iconconvenio from '../icons/icon_convenio.svg';
import data from '../icons/icon_data.svg';
import pciente from '../icons/icon_paciente.svg';
import sexo from '../icons/icon_gen.svg';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import { acionamentoService } from '../services/acionamento.service';
import ModalDeleteAcionamento from './ModalDeleteAcionamento';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import BuildIcon from '@material-ui/icons/Build';
import Tooltip from 'react-simple-tooltip';
import ModalSupport from './ModalSupport';
import { FormLabel, Radio } from '@material-ui/core';
import {
  alertActions,
  userActions,
  modalAlertActions,
  alertContingenciaActions,
  acionamentoActionsVonage,
  actionDeleteDocs,
  acionamentoActions,
  actionForwarding,
} from '../actions';

import { validations } from '../helpers/validations';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Fab from '@material-ui/core/Fab';
import './App.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '../components/Alert';
import ModalConvenio from '../components/ModalConvenio';
import ModalImage from '../components/ModalImage';
//import ModalConfirmPassage from '../components/ModalConfirmPassage';
import classNames from 'classnames';
import AlertLink from '../components/AlertLink';
import { history } from '../helpers';
import InputMask from 'react-input-mask';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DevicesIcon from '@material-ui/icons/Devices';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormatAlignLeftSharp, ThreeSixty } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import DialogModal from './DialogModal';

import ModalAlert from './ModalAlert';
import { isMinor } from '../utils/utils';

const styles = (theme) => ({
  main: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    width: '100%',
    fontFamily: 'Poppins',
    paddingTop: '0',
    margin: '0 auto 0 auto',
  },
  margin: {
    margin: theme.spacing(),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  img_inputs: {
    top: '10px !important',
    width: '20px',
    height: '20px',
  },
  img_inputs_sem: {
    width: '0 !important',
    height: '0 !important',
  },
  formulario: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  tituloPagina: {
    color: 'black',
    fontFamily: 'Arial',
    fontSize: '32px !important',
    fontWeight: 'bold',
  },
  erroValidation: {
    color: 'red',
  },
  alertBox: {
    zIndex: '10001 !important',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },

  loaderAddress: {
    position: 'relative',
    left: '280px',
    bottom: '43px',
  },

  alertTextTop: {
    marginTop: -15,
  },
  alertTextBottom: {
    marginTop: -8,
  },
  inputMobile: {
    width: '50%',
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  inputBirth: {
    width: '100%',
    padding: '8px 0 0 0',
    fontSize: '12px',
    '#::placeholder': {
      fontSize: '12px',
    },
  },
  input_phone: {
    fontSize: '12px',
    padding: '10px 0 2px 0',
    '#::placeholder': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
});

class AcionamentoVonage extends React.Component {
  constructor(props) {
    localStorage.setItem('playalert', false);
    props.dispatch(acionamentoActionsVonage.getRegionsByCountry());
    props.dispatch(acionamentoActionsVonage.getCityByRegions());
    props.dispatch(acionamentoActionsVonage.getAllCountryAcionamentos());
    props.dispatch(acionamentoActionsVonage.getAllConvenios());
    super(props);
    let dataDocs = {};
    this.state = {
      birthDate: '    -    ',
      numberformat: '1320',
      showCard: false,
      showButtonIsEdition: false,
      loading: false,
      signedRGURL: '',
      urgencyAttendance: false,
      isDependent: false,
      inputValueReason: '',
      modalDeleteAcionamento: false,
      newOpen: false,
      showAttendanceVonage: false,
      modalSupport: false,
      zeraDisplay: false,
      user: {
        _id: '',
        idAttendanceVonage: '',
        idConvenio: '',
        passage: false,
        userLogin: {},
        cpfrequire: false,
        ceprequire: false,
        name: '',
        socialName: '',
        emailSecondary: '',
        editionTime: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        startTime: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: '',
        nacionalidade: '',
        pais: '',
        codPais: '',
        city: '',
        id_requisicao_parceiro: '',
        regiao: '',
        flgExistPassage: false,
        convenioDescricao: '',
        streetCep: '',
        cep: '',
        deviceData: {
          deviceType: '',
          OS: '',
          navigator: '',
          route: '',
          accessChannel: '',
          appVersion: '',
          deviceBrand: '',
        },
        signUpDocuments: [],
        docsReferences: [],
        urlAttendanceVonage: '',
      },
      country: {
        result: [],
      },
      aliasConvenio: null,
      convenioValid: false,
      numberCardValid: false,
      openModal: false,
      openModalCard: false,
      openModalConfirmacao: false,
      blurPhone: false,
      message: '',
      type: '',
      open: false,
      paisValid: false,
      phoneValid: false,
      regionValid: false,
      cityValid: false,
      vertical: 'top',
      horizontal: 'center',
      valDate: 'text',
      submitted: false,
      registry: false,
      formValid: false,
      cepValid: false,
      loaderForAddress: false,
      openModalRemoveImage: false,
      openModalStatusOk: false,
      openModalStatusErr: false,
      dataDocs: dataDocs,
      openModalAlert: false,
      typeModalAlert: '',
      messageModalAlert: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitConfirmed = this.handleSubmitConfirmed.bind(this);
    this.TextMaskCustom = this.TextMaskCustom.bind(this);
    this.TextMaskCustomCPF = this.TextMaskCustomCPF.bind(this);
    this.PhoneMask = this.PhoneMask.bind(this);
    this.PhoneMask2 = this.PhoneMask2.bind(this);
    this.PhoneMask3 = this.PhoneMask3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseAlertLink = this.handleCloseAlertLink.bind(this);
    this.handleCloseModalConvenio = this.handleCloseModalConvenio.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValidFormulario = this.handleValidFormulario.bind(this);
    this.validationCadastro = this.validationCadastro.bind(this);
    this.handleClickOpenModalConfirmacao =
      this.handleClickOpenModalConfirmacao.bind(this);
    this.handleCloseModalConfirmacao =
      this.handleCloseModalConfirmacao.bind(this);
    this.handleShowNotification = this.handleShowNotification.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleChangePais = this.handleChangePais.bind(this);
    this.handleChangeRegiao = this.handleChangeRegiao.bind(this);
    this.handleChangeRegiaoKeyDown = this.handleChangeRegiaoKeyDown.bind(this);
    this.handleChangeCidadeKeyDown = this.handleChangeCidadeKeyDown.bind(this);
    this.handleChangePaisKeyDown = this.handleChangePaisKeyDown.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeCard = this.handleChangeCard.bind(this);
    this.handleSameModalGeneratePassage =
      this.handleSameModalGeneratePassage.bind(this);
    this.handleNewGeneratePassage = this.handleNewGeneratePassage.bind(this);
    this.handleCloseModalCard = this.handleCloseModalCard.bind(this);
    this.handleChangeConvenio = this.handleChangeConvenio.bind(this);
    this.handleChangeCep = this.handleChangeCep.bind(this);
    this.TextMaskCep = this.TextMaskCep.bind(this);
    this.handleSearchAddress = this.handleSearchAddress.bind(this);
    this.handleChangeAttendance = this.handleChangeAttendance.bind(this);
    this.handleIsDependent = this.handleIsDependent.bind(this);
    this.handlerModalUrgency = this.handlerModalUrgency.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleRemoveCancel = this.handleRemoveCancel.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.refreshSingUpDocucuments = this.refreshSingUpDocucuments.bind(this);
    this.handlerModalSupport = this.handlerModalSupport.bind(this);
    this.handleOpenModaSupport = this.handleOpenModaSupport.bind(this);
    this.handleChangeBehaviorSocialName =
      this.handleChangeBehaviorSocialName.bind(this);
  }

  //Modal de confirmção --> deleção Documetnos
  handleRemoveCancel() {
    this.setState({ openModalRemoveImage: false });
  }

  handleRemoveConfirm() {
    const { user, isDependent } = this.state;
    this.props.dispatch(
      actionDeleteDocs.deleteDocsUser(
        { user, isDependent },
        this.refreshSingUpDocucuments
      )
    );
    this.setState({ openModalRemoveImage: false });
  }

  refreshSingUpDocucuments() {
    let { user } = this.state;

    this.setState({ user: { ...user, signUpDocuments: [] } });
  }

  openRemoveImage() {
    this.setState({ openModalRemoveImage: true });
  }

  handleChange(event) {
    let { name, value } = event.target;
    value = value.replace(/\s\s+/g, ' ');
    value = value.replace(/\s\s+/g, ' ');
    if (value.includes(':')) value = value.replace(/\:/g, '');

    const { user } = this.state;

    value = value.replace(/\s\s+/g, ' ');
    value = value.replace(/\s\s+/g, ' ');
    if (value.includes(':')) value = value.replace(/\:/g, '');

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handlerModalUrgency() {
    const { modalDeleteAcionamento } = this.state;
    this.setState({
      modalDeleteAcionamento: !modalDeleteAcionamento,
    });
  }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }

  handleChangeAttendance() {
    let { urgencyAttendance } = this.state;
    urgencyAttendance = urgencyAttendance ? false : true;
    if (!urgencyAttendance) this.setState({ urgencyAttendenceReason: '' });
    this.setState({ urgencyAttendance });
  }

  handleIsDependent() {
    let { isDependent } = this.state;
    isDependent = isDependent ? false : true;
    this.setState({ isDependent });
  }

  handleChangeUrgencyReason(e) {
    const { value } = e.target;
    this.setState({ urgencyAttendenceReason: value });
  }

  handleCloseNewModal() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({
      openModalAlert: false,
      messageModalAlert: '',
      loadingsub: false,
    });
  }

  handleChangeNacionality(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
        streetCep: '',
        pais: '',
        codPais: '',
        regiao: '',
        city: '',
      },
    });
  }

  handleChangeCep(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });

    if (this.state.user.streetCep.length < 8) {
      this.setState({ loading: false });
    }
  }

  handleChangeConvenio(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    let idConvenio = value.split('|')[0];
    let showNumber = false;
    let validateNumberCard = true;
    if (
      idConvenio === '5' ||
      idConvenio === '30' ||
      idConvenio === '51' ||
      idConvenio === '79'
    ) {
      showNumber = true;
      validateNumberCard = false;
    }
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
      showCard: showNumber,
      numberCardValid: validateNumberCard,
    });
  }

  handleChangeCard(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    let validateNumberCard = true;
    let idConvenio = user.convenio.split('|')[0];
    if (
      idConvenio === '5' ||
      idConvenio === '30' ||
      idConvenio === '51' ||
      idConvenio === '79'
    ) {
      validateNumberCard = validations.validNumberCard(value);
    }
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
      numberCardValid: validateNumberCard,
    });
  }

  handleCloseModalCard() {
    this.setState({ openModalCard: false });
  }
  handleChangePais(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          name: '',
          code: '',
        };
        break;
      default:
        val = {
          ...values,
          name: !values.hasOwnProperty('name') ? '' : values.name,
          code: !values.hasOwnProperty('code') ? '' : values.code,
        };
        break;
    }
    const { name, code } = val;
    const { user } = this.state;

    let json = {
      Brasil: 'br',
      Brazil: 'br',
      'United States of America': 'us',
      'Estados Unidos': 'us',
    };

    this.setState({
      user: {
        ...user,
        streetCep: '',
        pais: name,
        codPais: json[name],
        regiao: '',
      },
    });

    this.props.dispatch(
      acionamentoActionsVonage.getRegionsByCountry(json[name])
    );
  }

  handleChangeRegiaoKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          region: '',
        };
        break;
      default:
        val = {
          region: value,
        };
        break;
    }
    const { region } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        regiao: region,
        city: '',
      },
    });
  }

  handleChangePaisKeyDown(event) {
    const { value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          name: '',
        };
        break;
      default:
        val = {
          name: value,
        };
        break;
    }

    const { name, code } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        pais: name,
        regiao: '',
      },
    });
  }

  handleChangeCity(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          Estado: '',
          Nome: '',
          ID: '',
        };
        break;
      default:
        val = {
          ...values,
          Nome: !values.hasOwnProperty('Nome') ? '' : values.Nome,
        };
        break;
    }

    const { Nome } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        city: Nome,
      },
    });
  }

  handleChangeCidadeKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          Nome: '',
        };
        break;
      default:
        val = {
          Nome: value,
        };
        break;
    }
    const { Nome } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        city: Nome,
      },
    });
  }

  handleChangeRegiao(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          region: '',
        };
        break;
      default:
        val = {
          ...values,
          region: !values.hasOwnProperty('region') ? '' : values.region,
        };
        break;
    }

    const { region } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        regiao: region,
        city: '',
      },
    });
    this.props.dispatch(acionamentoActionsVonage.getCityByRegions(region));
  }

  async componentDidMount() {
    this.props.dispatch(acionamentoActionsVonage.getAllCountryAcionamentos());
    this.props.dispatch(acionamentoActionsVonage.getCityByRegions());
    this.props.dispatch(acionamentoActionsVonage.getRegionsByCountry());
    this.props.dispatch(acionamentoActionsVonage.getAllConvenios());

    const { editAcionamento } = this.props;
    let user = editAcionamento;
    let validateNumberCard = true;
    if (user.name) {
      const { editAcionamento } = this.props;
      let user = editAcionamento;
      if (user._id) {
        let intervalManagememnt = setInterval(async () => {
          await acionamentoActionsVonage
            .inAttendance({ ...this.state, idAttendance: user._id })
            .then(() => {
              if (!this.state.showButtonIsEdition)
                this.setState({ showButtonIsEdition: true });
              clearInterval(intervalManagememnt);
            })
            .catch((err) => {
              history.push('/admVonage');
              clearInterval(intervalManagememnt);
            });
        }, 3000);
      }

      this.props.dispatch(acionamentoActionsVonage.getAllConveniosPortal());
      let {
        deviceData = {
          deviceType: 'Não Recebido',
          OS: '',
          navigator: '',
          route: '/',
          accessChannel: '',
          appVersion: '',
          deviceBrand: '',
        },
      } = user;
      if (
        user.convenio === '5' ||
        user.convenio === '30' ||
        user.convenio === '51' ||
        user.convenio === '79'
      ) {
        this.setState({
          showCard: true,
        });

        if (user.numberCard)
          validateNumberCard = validations.validNumberCard(user.numberCard);
      }
      let { streetCep = '', signUpDocuments = [], docsReferences = [] } = user;
      let nacUser =
        user.document && !validations.validationCPF(user.document) ? 'B' : 'E';

      if (!user.document) nacUser = 'B';
      this.setState({
        aliasConvenio: true,
        numberCardValid: validateNumberCard,
        deletedDocs: this.props.deletedDocs,
        showAttendanceVonage: true,
        user: {
          objectModel: {
            collectionName: 'acionamentosVonage',
            schemaName: 'AcionamentosVonage',
          },
          ...editAcionamento,
          _id: user._id,
          idConvenio: user.convenio,
          userLogin: this.props.userLogin,
          editionTime: user.editionTime,
          convenio: `${user.convenio}|${user.contrato}`,
          convenioDescricao: user.contrato,
          contrato: user.contrato,
          cpfrequire: false,
          ceprequire: false,
          name: user.name,
          socialName: user.socialName,
          lastName: user.lastName,
          cpf: user.document,
          email: user.email ? user.email.trim() : '',
          birthDate: moment(user.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          password: '',
          cpassword: '',
          loadingsub: false,
          submitted: false,
          checkTerms: false,
          showNotifications: false,
          sex: user.gender,
          phone: user.phone,
          phone2: user.homePhone,
          phone3: user.phone2,
          rg: user.rg,
          nacionalidade: nacUser,
          numberCard: user.numberCard,
          startTime: user.startTime,
          userType: user.userType,
          city: user.city,
          regiao: user.state,
          codPais: user.codPais,
          pais: user.country,
          id_requisicao_parceiro: user.partnerID,
          streetCep: streetCep,
          deviceData: deviceData,
          signUpDocuments: signUpDocuments,
          docsReferences: docsReferences,
          openModalRemoveImage: false,
          dataDocs: {},
          urlAttendanceVonage: user.urlAttendanceVonage,
          idAttendanceVonage: user.idAttendanceVonage,
          userProducts: user.userProducts ? user.userProducts : '',
        },
      });
    }
  }

  async componentWillUnmount() {
    this.props.dispatch(modalAlertActions.clear());
    localStorage.setItem('playalert', true);
  }

  handleValidFormulario(event, edit) {
    event.preventDefault();
    this.setState({ submitted: true });
    this.validationCadastro('pais');
    this.validationCadastro('regiao');
    this.validationCadastro('city');
    const { user, aliasConvenio } = this.state;

    var birthDate = user.birthDate;
    if (birthDate) {
      var arrayDate = birthDate.split('/');
      var day = Number(arrayDate[0]),
        month = Number(arrayDate[1]),
        year = Number(arrayDate[2]);

      var formBirth = moment([year, month, day]);
      var now = moment();
      var years = now.diff(formBirth, 'years');
      if (years >= 18 && !this.state.user.cpf) {
        this.setState({
          cpfrequire: true,
        });
      } else {
        this.setState({
          cpfrequire: false,
        });
      }
    }
    if (this.state.formValid && edit) {
      this.handleSubmitEdit();
    }
    if (this.state.formValid && !aliasConvenio) {
      this.handleSubmit();
    } else if (this.state.formValid && aliasConvenio) {
      this.handleClickOpenModalConfirmacao();
    }
  }

  handleSubmit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    let idConvenio = user.convenio.split('|')[0];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let startTime = new Date(Date.now() - tzoffset).toISOString();
    //if(idConvenio === '5' || idConvenio === '30' || idConvenio === '51' || idConvenio === '79'){
    //this.setState({openModalCard:true,openModal:false})
    if (user.convenio !== '') {
      dispatch(userActions.registerVonage({ ...user, startTime }));
      this.handleCloseModalConvenio();
    }
  };

  handleSubmitEdit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    dispatch(userActions.putVonage(user));
    this.handleCloseModalConvenio();
    this.handleBack();
  };

  async componentWillReceiveProps(received) {
    const docs = async () => await this.props.deletedDocs;
    this.setState({ deletedDocs: docs });

    let { conveniosPortal } = received;
    let updatedConvenios = {};

    if (conveniosPortal) {
      conveniosPortal.map((convenio) => {
        updatedConvenios[convenio.id] = convenio.descricao;
      });
    }

    let { user } = this.state;

    if (updatedConvenios[user.idConvenio]) {
      this.setState({
        user: {
          ...user,
          convenio: updatedConvenios[user.idConvenio]
            ? `${user.idConvenio}|${updatedConvenios[user.idConvenio]}`
            : `${user.convenio}|${user.contrato}`,
          convenioDescricao: updatedConvenios[user.idConvenio]
            ? updatedConvenios[user.idConvenio]
            : user.contrato,
          contrato: updatedConvenios[user.idConvenio]
            ? updatedConvenios[user.idConvenio]
            : user.contrato,
        },
      });
    }

    if (!(!user.streetCep && !user.pais)) {
      if (user.streetCep.length === 9) {
        const address = await this.getAddress(user.streetCep);
        if (address) {
          let { regions } = received;
          this.setState({ loading: false });
          let state = {};
          if (regions.length > 0 && regions[0].states) {
            regions[0].states.map((reg) => {
              if (reg.letters == address.uf) return (state = reg);
            });
            this.setState({
              cepValid: true,
              user: {
                ...user,
                pais: 'Brasil',
                codPais: 'br',
                regiao: state.region,
                city: address.localidade,
                cepToAddress: address,
              },
            });
          }
        }
      }
    }
  }

  handleSearchAddress = async (cep) => {
    this.props.dispatch(acionamentoActionsVonage.getRegionsByCountry('br'));
  };

  getAddress = async (cep) => {
    return await acionamentoService.GetAddressFromCep(cep);
  };

  handleSubmitConfirmed = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let confirmedTime = new Date(Date.now() - tzoffset).toISOString();
    if (user.passport) user.document = user.passport;
    if (user.convenio !== '') {
      dispatch(
        acionamentoActionsVonage.confirmedAcionamentos({
          ...user,
          confirmedTime,
        })
      );
      this.handleCloseModalConfirmacao();
    }
  };

  handleChangeBehaviorSocialName(e) {
    let { socialNames } = this.state;
    socialNames = socialNames ? false : true;
    if (!socialNames) this.setState({ pay: '' });
    this.setState({ socialNames });
  }

  handleShowNotification = () => {
    const { user } = this.state;

    if (this.state.user.showNotifications) {
      this.setState({
        user: {
          ...user,
          showNotifications: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          showNotifications: true,
        },
      });
    }
  };

  handleChangeCheckbox = () => {
    const { user } = this.state;
    if (user.checkTerms) {
      this.setState({
        user: {
          ...user,
          checkTerms: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          checkTerms: true,
        },
      });
    }
    this.validationCadastro('terms');
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowCPassword = () => {
    this.setState((state) => ({ showCPassword: !state.showCPassword }));
  };

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  handleClear() {
    this.setState({
      ...this.state,
      user: {
        docRemovedBy: {},
        passage: false,
        userLogin: {},
        cpfrequire: false,
        ceprequire: false,
        name: '',
        socialName: '',
        editionTime: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        startTime: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: '',
        nacionalidade: '',
        pais: '',
        codPais: '',
        city: '',
        id_requisicao_parceiro: '',
        regiao: '',
        flgExistPassage: false,
        convenioDescricao: '',
        streetCep: '',
        cep: '',
        deviceData: {
          deviceType: '',
          OS: '',
          navigator: '',
          route: '',
          accessChannel: '',
          appVersion: '',
          deviceBrand: '',
        },
        signUpDocuments: [],
        docsReferences: [],
      },
    });
  }

  validationCadastro(event) {
    const {
      name,
      lastName,
      socialName,
      nacionalidade,
      cpf,
      passport,
      email,
      birthDate,
      password,
      cpassword,
      checkTerms,
      sex,
      phone,
      rg,
      id_requisicao_parceiro,
      pais,
      regiao,
      city,
      convenio,
      streetCep,
    } = this.state.user;
    switch (event) {
      case 'name':
        if (name) {
          this.state.nameValid = validations.validationName(name);
        }
        break;
      case 'lastName':
        if (lastName) {
          this.state.lastNameValid = validations.validationLastName(lastName);
        }
        break;
      case 'passport':
        if (passport) {
          this.state.passportValid = validations.validationPassaport(passport);
        }
        break;
      case 'cpf':
        if (cpf) {
          this.state.cpfValid = validations.validationCPF(cpf);
        }
        break;
      case 'email':
        if (email) {
          this.state.emailValid = validations.validationEmail(email);
        }
        break;
      case 'birthDate':
        if (birthDate) {
          this.state.birthDateValid =
            validations.validationbirthDate(birthDate);
        }
        break;
      case 'phone':
        if (phone) {
          this.state.phoneValid = validations.validationTelefone(phone);
        }
        break;
      case 'terms':
        this.state.termsValid = validations.validationTerms(checkTerms);
        break;
      case 'password':
        if (password) {
          this.state.passwordValid = validations.validationPassword(password);
        }
        break;
      case 'cpassword':
        if (cpassword) {
          this.state.cpasswordValid = validations.validationCPassword(
            password,
            cpassword
          );
        }
        break;
      case 'rg':
        if (rg) {
          // this.state.rgValid = validations.validationRg(rg);
        }
        break;
      case 'pais':
        if (pais) {
          this.state.paisValid = validations.validatePais(pais);
        }
        break;
      case 'regiao':
        this.state.regionValid = validations.validateRegion(
          regiao,
          this.state.user.codPais
        );
        break;
      case 'city':
        this.state.cityValid = validations.validateCity(
          city,
          this.state.user.codPais
        );
        break;
      case 'convenio':
        this.state.convenioValid = validations.validConvenio(
          this.state.user.convenio
        );
        break;
      default:
        break;
    }
    const {
      nameValid,
      lastNameValid,
      emailValid,
      passwordValid,
      cpasswordValid,
      termsValid,
      birthDateValid,
      paisValid,
      regionValid,
      cityValid,
      convenioValid,
      numberCardValid,
      phoneValid,
      cepValid,
      cpfValid,
      passportValid,
    } = this.state;

    if (nacionalidade === 'B') {
      if (birthDate) {
        var formBirth = moment(birthDate, 'DD/MM/YYYY');
        var now = moment();
        var years = now.diff(formBirth, 'years');
        if (
          years >= 18 &&
          (!this.state.user.cpf || this.state.user.cpf == '')
        ) {
          this.state.formValid =
            phoneValid &&
            numberCardValid &&
            cityValid &&
            paisValid &&
            regionValid &&
            convenioValid &&
            cpfValid &&
            rg &&
            !nameValid &&
            !lastNameValid &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        } else {
          this.state.formValid =
            phoneValid &&
            numberCardValid &&
            convenioValid &&
            cityValid &&
            paisValid &&
            regionValid &&
            !nameValid &&
            !lastNameValid &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        }
      } else {
        this.state.formValid =
          phoneValid &&
          numberCardValid &&
          convenioValid &&
          cityValid &&
          paisValid &&
          regionValid &&
          nacionalidade &&
          !nameValid &&
          !lastNameValid &&
          !emailValid &&
          !passwordValid &&
          !cpasswordValid &&
          !termsValid &&
          !birthDateValid &&
          (sex === 'M' || sex === 'F');
      }
    } else if (!isMinor(birthDate)) {
      this.state.formValid =
        phoneValid &&
        numberCardValid &&
        convenioValid &&
        passportValid &&
        cityValid &&
        paisValid &&
        regionValid &&
        nacionalidade &&
        !nameValid &&
        !lastNameValid &&
        !emailValid &&
        !passwordValid &&
        !cpasswordValid &&
        !termsValid &&
        !birthDateValid &&
        (sex === 'M' || sex === 'F');
    } else {
      this.state.formValid =
        phoneValid &&
        numberCardValid &&
        convenioValid &&
        nacionalidade &&
        !nameValid &&
        !lastNameValid &&
        !emailValid &&
        !passwordValid &&
        !cpasswordValid &&
        !termsValid &&
        !birthDateValid &&
        (sex === 'M' || sex === 'F');
    }
  }

  deleteAcionamento(data, oldData, reason) {
    if (this.state.inputValueReason !== '') {
      this.setState({ inputValueReason: '' }, () =>
        this.handleDeleteAcionamento(oldData._id, reason)
      );
      this.handlerModalUrgency();
    }
  }

  handleDeleteAcionamento = (id, reason) => {
    let { urgencyAttendance, urgencyAttendenceReason } = this.state;
    this.props.dispatch(
      acionamentoActionsVonage.deleteAcionamentos(
        id,
        reason,
        urgencyAttendance,
        urgencyAttendenceReason
      )
    );
  };

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.birthDate}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='birthDate'
        id='formatted-text-mask-input'
        placeholder='Data de Nascimento'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  TextMaskCep(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.streetCep}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='streetCep'
        placeholder='Digite o CEP'
        mask='99999-999'
        maskChar={null}
      />
    );
  }

  TextMaskCustomCPF(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.cpf}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='cpf'
        mask='999.999.999-99'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  async handleBack() {
    let { urgencyAttendance, user } = this.state;
    if (user._id) {
      await acionamentoActionsVonage.updateStatusAttendance({ _id: user._id });
    }

    if (urgencyAttendance) {
      this.setState({ modalDeleteAcionamento: true });
    } else {
      history.push('/admVonage');
    }
  }

  PhoneMask(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone'}
        onBlur={this.validationCadastro('phone')}
        value={this.state.user.phone}
        placeholder={'DDD + Telefone'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask2(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone2'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone2'}
        value={this.state.user.phone2}
        placeholder={'DDD + Telefone 2'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask3(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone3'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone3'}
        placeholder={'DDD + Telefone 3'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        value={this.state.user.phone3}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith('-') &&
      userInput !== '-' &&
      !this.state.value.endsWith('-')
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  async handleOpenRG(doc) {
    let { signedURL } = await acionamentoActionsVonage.generateImageUrl(doc);

    this.setState({ signedRGURL: signedURL });
  }

  closeImage() {
    this.setState({ signedRGURL: '' });
  }

  handleCloseAlertLink() {
    const { dispatch } = this.props;
    dispatch(alertContingenciaActions.clear());
  }

  handleCloseModalConvenio() {
    this.setState({ openModal: false });
  }

  handleClickOpenModalConfirmacao() {
    this.setState({ openModalConfirmacao: true });
  }

  handleCloseModalConfirmacao() {
    this.setState({ openModalConfirmacao: false });
  }

  handleSameModalGeneratePassage() {
    let { user } = this.state;
    const { dispatch } = this.props;
    this.setState({
      user: { ...user, passage: false, flgExistPassage: false },
    });
    dispatch(
      acionamentoActionsVonage.confirmedAcionamentos({
        ...{ flgNewPassage: true },
        ...user,
      })
    );
  }

  refreshData() {
    let { userLogin } = this.props;
    if (userLogin.user.id_perfil.descricao != 'Administrativo')
      return history.push('/suporte');
    history.push('/admVonage');
  }

  handleNewGeneratePassage() {
    let { user } = this.state;
    const { dispatch } = this.props;
    this.setState({ user: { ...user, flgExistPassage: true } });
    dispatch(
      acionamentoActionsVonage.confirmedAcionamentos({
        ...{ flgNewPassage: false },
        ...user,
      })
    );
  }

  handlerModalSupport(oldData) {
    const { modalSupport } = this.state;
    this.setState({
      modalSupport: !modalSupport,
      dataSupport: oldData,
      zeraDisplay: true,
    });
  }

  async handleConfirmForwarding(inputValue) {
    const { dataSupport } = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const Professional = {
      name: user.user.nome,
      id: user.user._id,
      email: user.user.email,
    };
    dataSupport.birthDate = moment(dataSupport.birthDate, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    dataSupport.token = user.accessToken;
    dataSupport.reason = inputValue.value;
    dataSupport.reasonDesc = inputValue.desc;
    dataSupport.originProfessional = Professional;
    dataSupport.origin = {
      channel: 'acionamentos-vonage',
      received: 'central',
      from: user?.user?.id_perfil?.descricao,
    };
    dataSupport.objectModel = {
      collectionName: 'acionamentosVonage',
      schemaName: 'AcionamentosVonage',
    };
    dataSupport.convenio = dataSupport.convenio.split('|')[0];

    if (inputValue.value.length > 1) {
      this.props.dispatch(actionForwarding.forwarding(dataSupport));
      await acionamentoActionsVonage.updateStatusConecta(
        dataSupport,
        'suporte',
        user.token,
        Professional
      );
      this.setState({
        modalSupport: false,
        zeraDisplay: true,
      });
      this.refreshData();
    }
  }

  handleOpenModaSupport() {
    let { user } = this.state;
    this.setState({
      modalSupport: true,
      dataSupport: user,
      zeraDisplay: false,
    });
  }

  handleOpenLink = () => {
    let userData = this.state.user;
    userData.date = userData.birthDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let confirmedTime = new Date(Date.now() - tzoffset).toISOString();
    if (userData.convenio !== '' && userData.name) {
      this.props.dispatch(
        acionamentoActionsVonage.attendanceAcionamentos(
          { ...userData, confirmedTime },
          this
        )
      );
    }
  };

  render() {
    const {
      registering,
      open,
      classes,
      message,
      type,
      convenios = [],
      countries = [],
      cities = [],
      openModalAlert,
      typeModalAlert,
      messageModalAlert,
    } = this.props;
    let { regions } = this.props;

    const {
      user,
      submitted,
      openModal,
      openModalCard,
      openModalConfirmacao,
      aliasConvenio,
      showCard,
      signedRGURL,
      cepValid,
      modalDeleteAcionamento,
      inputValueReason,
      showAttendanceVonage,
      modalSupport,
      zeraDisplay,
      showButtonIsEdition,
    } = this.state;

    const width = window.innerWidth;
    if (regions.length >= 1) {
      regions = regions[0].states;
    }
    const inputs_1 = [
      {
        float: 'left',
        imagem: pciente,
        pergunta: 'Nome',
        state: user.name,
        type: 'name',
        blur: this.validationCadastro('name'),
        nValid: this.state.nameValid,
        validacao: 'No mínimo 2 caracteres.',
      },
      {
        float: 'right',
        imagem: pciente,
        pergunta: 'Sobrenome Completo',
        state: user.lastName,
        type: 'lastName',
        blur: this.validationCadastro('lastName'),
        nValid: this.state.lastNameValid,
        validacao: 'No mínimo 3 caracteres.',
      },
    ];

    const inputSocialName = [
      {
        float: 'left',
        imagem: pciente,
        pergunta: 'Nome Social',
        state: user.socialName,
        type: 'socialName',
      },
    ];

    const input_cpf = [
      {
        float: 'left',
        imagem: iconconvenio,
        pergunta: 'CPF',
        state: user.document,
        type: 'cpf',
        blur: this.validationCadastro('cpf'),
        nValid: this.state.cpfValid,
        require: this.state.cpfrequire,
        validacao: 'CPF inválido.',
        disable: aliasConvenio,
      },
    ];

    const input_rg = [
      {
        float: 'right',
        imagem: iconconvenio,
        pergunta: 'RG',
        state: user.rg,
        type: 'rg',
        blur: this.validationCadastro('rg'),
        nValid: this.state.rgValid,
        validacao: 'Digite o RG.',
      },
    ];

    const inputs_3 = [
      {
        imagem: pciente,
        pergunta: 'E-mail',
        state: user.email,
        type: 'email',
        blur: this.validationCadastro('email'),
        nValid: this.state.emailValid,
        validacao: 'Email inválido.',
      },
      {
        imagem: pciente,
        pergunta: 'E-mail secundário',
        state: user.emailSecondary,
        type: 'emailSecondary',
        blur: null,
        nValid: false,
        validacao: 'Email inválido.',
      },
    ];

    const inputs_4 = [
      {
        imagem: iconconvenio,
        pergunta: 'Passaporte',
        state: this.state.user.passport,
        type: 'passport',
        blur: this.validationCadastro('passport'),
        nValid: this.state.passportValid,
        validacao: 'Passaporte inválido.',
      },
    ];

    const inputs_5 = [
      {
        imagem: iconconvenio,
        pergunta: 'Identificador parceiro',
        state: user.id_requisicao_parceiro,
        type: 'id_requisicao_parceiro',
        blur: this.validationCadastro('id_requisicao_parceiro'),
        nValid: this.state.id_requisicao_parceiroValid,
        validacao: 'Identificador parceiro inválido.',
      },
    ];

    const inputs_6 = [
      {
        imagem: iconconvenio,
        pergunta: 'Numero Carteirinha',
        state: user.numberCard,
        type: 'numberCard',
        nValid: true,
        validacao: 'Identificador parceiro inválido.',
      },
    ];

    const phones = [
      {
        inputRef: this.PhoneMask,
        float: 'left',
        text: 'Campo obrigatório.',
      },
      {
        inputRef: this.PhoneMask2,
        float: 'center',
      },
      {
        inputRef: this.PhoneMask3,
        float: 'right',
      },
    ];
    const convenioSplit = user.convenio.split('|');
    return (
      <div className={classes.main}>
        <ModalImage
          open={signedRGURL}
          close={() => this.closeImage()}
          message='Documento'
          component={
            <React.Fragment>
              <img
                style={{
                  width: '90%',
                  height: '90%',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                src={signedRGURL}
              ></img>
            </React.Fragment>
          }
        />

        <ModalDeleteAcionamento
          open={modalDeleteAcionamento}
          close={this.handlerModalUrgency}
          dataDeleteAcionamento={this.state.user}
          onSubmit={() =>
            this.deleteAcionamento(data, this.state.user, inputValueReason)
          }
          inputValueReason={inputValueReason}
          dataAcionamento={data}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
        />

        <ModalSupport
          open={modalSupport}
          close={this.handlerModalSupport}
          onSubmit={() => {
            this.handleConfirmForwarding(inputValueReason);
          }}
          inputValueReason={inputValueReason}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
          emptyMotivo={zeraDisplay}
        />

        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />

        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          handleCancel={(typeModalAlert, messageModalAlert) =>
            this.handleCloseNewModal(typeModalAlert, messageModalAlert)
          }
        />

        <AlertLink close={() => this.handleCloseAlertLink()} />

        <Grid
          container
          justify={'flex-end'}
          style={{ padding: '10px 0', marginTop: '10px' }}
        >
          {showButtonIsEdition && showAttendanceVonage && (
            <Tooltip content='Entrar na chamada'>
              <div
                onClick={this.handleOpenLink}
                style={{
                  width: '50px',
                  height: '50px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  marginRight: '20px',
                  cursor: 'pointer',
                  borderRadius: '3px',
                }}
              >
                <VideoCallIcon style={{ margin: 13, color: '#aaa' }} />
              </div>
            </Tooltip>
          )}
        </Grid>

        <ModalConvenio
          open={openModal}
          close={() => this.handleCloseModalConvenio()}
          message='Escolha um Convênio'
          onSubmit={() => this.handleSubmit()}
          component={<React.Fragment></React.Fragment>}
        />

        <ModalConvenio
          open={openModalCard}
          close={() => this.handleCloseModalCard()}
          message='Digite o numero da carteirinha'
          onSubmit={() => this.handleSubmitCard()}
          component={<React.Fragment></React.Fragment>}
        />

        <ModalConvenio
          open={openModalConfirmacao}
          close={() => this.handleCloseModalConfirmacao()}
          message='Confirmar acionamento?'
          onSubmit={() => this.handleSubmitConfirmed()}
        />

        <form
          id='Cadastro_Usuario'
          name='Cadastro_Usuario'
          className={classes.formulario}
          onSubmit={this.handleValidFormulario}
        >
          <TextField
            id='standard-select-currency'
            select
            disabled={aliasConvenio}
            label='Convênio'
            onChange={this.handleChangeConvenio}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin='normal'
            value={user.convenio}
            input={<Input id='select-multiple-placeholder' />}
            displayEmpty
            onBlur={this.validationCadastro('convenio')}
            name='convenio'
            className={classes.input}
          >
            {aliasConvenio ? (
              <MenuItem
                key={convenioSplit[0]}
                value={`${convenioSplit[0]}|${convenioSplit[1]}`}
              >
                {convenioSplit[1]}
              </MenuItem>
            ) : (
              convenios.map((convenio) => (
                <MenuItem
                  key={convenio.id}
                  value={`${convenio.id}|${convenio.descricao}`}
                >
                  {convenio.descricao}
                </MenuItem>
              ))
            )}
          </TextField>
          {!this.state.convenioValid && (
            <div
              className={classNames(classes.alertText, classes.alertTextTop)}
            >
              Campo obrigatório.
            </div>
          )}

          {(user.numberCard || showCard) && (
            <TextField
              autoFocus
              name='numberCard'
              margin='dense'
              id='card'
              label='Carteirinha'
              type='number'
              value={user.numberCard}
              onChange={this.handleChangeCard}
              fullWidth
            />
          )}
          {showCard && !this.state.numberCardValid && (
            <div className={classNames(classes.alertText)}>
              Por favor, digite um numero de carteirinha válido
            </div>
          )}
          <Grid container spacing={width > 900 ? 8 : 0}>
            {inputs_1.map((questao) => (
              <Grid
                key={questao.type}
                item
                style={{ float: questao.float }}
                className={classNames(
                  classes.inputMobile +
                    (submitted && !questao.state ? ' has-error' : '')
                )}
              >
                <TextField
                  margin='normal'
                  placeholder={questao.validacao}
                  label={questao.pergunta}
                  name={questao.type}
                  value={questao.state}
                  className={classes.input}
                  key={questao.type}
                  onBlur={questao.blur}
                  type={questao.date}
                  onFocus={questao.focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {submitted && !questao.state && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {questao.nValid && !(submitted && !questao.state) && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    {questao.validacao}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>

          <FormLabel
            component='legend'
            style={{
              float: 'left',
              position: 'relative',
              left: 0,
              fontSize: 12,
              top: 16,
            }}
          >
            Nome Social
          </FormLabel>
          <Radio
            style={{ position: 'relative', left: 5, top: 2 }}
            size='small'
            //color="alert"
            // value={this.state.socialNames}
            label='top'
            labelPlacement='top'
            control={<Radio />}
            checked={!this.state.socialNames === false} //{selectedValue === 'a'}
            //onChange={this.handleChangeBehaviorSocialName}
            onClick={(e) => this.handleChangeBehaviorSocialName(e)}
            //value="a"
            name='radio-buttons'
            //inputProps={{ 'aria-label': 'A' }}
          />
          <div>
            {this.state.socialNames && (
              <Grid item xs={12} sm={5}>
                {inputSocialName.map((questao) => (
                  <div
                    key={questao.type}
                    style={{ width: '240%' }}
                    className={
                      'form-group' +
                      (submitted && !questao.state ? ' has-error' : '')
                    }
                  >
                    <TextField
                      // hidden={display='none'}
                      margin='normal'
                      name={questao.type}
                      value={questao.state}
                      className={classes.input}
                      placeholder={questao.pergunta}
                      onBlur={questao.blur}
                      type={questao.date}
                      onFocus={questao.focus}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <img
                              className={
                                questao.imagem
                                  ? classes.img_inputs
                                  : classes.img_inputs_sem
                              }
                              src={questao.imagem ? questao.imagem : null}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                ))}
              </Grid>
            )}
          </div>

          <Select
            disabled={aliasConvenio}
            value={user.nacionalidade}
            onChange={(e) => this.handleChangeNacionality(e)}
            input={<Input id='select-multiple-placeholder' />}
            displayEmpty
            name='nacionalidade'
            className={classes.input}
            startAdornment={
              <InputAdornment position='start'>
                <img className={classes.img_inputs} src={sexo} />
              </InputAdornment>
            }
          >
            <MenuItem value=''>
              <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                Nacionalidade
              </em>
            </MenuItem>
            <MenuItem value={'B'}>Brasileira</MenuItem>
            <MenuItem value={'E'}>Estrangeira</MenuItem>
          </Select>
          {submitted && !user.nacionalidade && (
            <div className={classes.alertText}>Campo obrigatório.</div>
          )}

          <Grid>
            {submitted && this.state.ceprequire && (
              <div
                className={classNames(
                  classes.alertText,
                  classes.alertTextBottom
                )}
              >
                Campo obrigatório.
              </div>
            )}
            {user.nacionalidade === 'B' ? (
              <Grid>
                <TextField
                  label='CPF'
                  margin='normal'
                  placeholder='Digite o CPF.'
                  className={classes.inputBirth}
                  disabled={aliasConvenio}
                  onBlur={this.validationCadastro('cpf')}
                  InputProps={{
                    inputComponent: this.TextMaskCustomCPF,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={classes.img_inputs}
                          src={iconconvenio}
                        />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>

                {submitted && !user.cpf && this.state.cpfrequire && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {submitted &&
                  this.state.cpfValid &&
                  !(!user.cpf && this.state.cpfrequire) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {'CPF inválido.'}
                    </div>
                  )}
                {submitted &&
                  this.state.cpfValid &&
                  !(!user.cpf && this.state.cpfrequire) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {'CPF inválido.'}
                    </div>
                  )}
              </Grid>
            ) : (
              ''
            )}
          </Grid>

          {user.nacionalidade === 'E' ? (
            <Grid container justify='center'>
              {inputs_4.map((questao) => (
                <Grid
                  key={questao.type}
                  item
                  style={{ width: '100%' }}
                  className={'form-group'}
                >
                  <TextField
                    label='Passaporte'
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    disabled={aliasConvenio}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!isMinor(this.state.user.birthDate) &&
                    submitted &&
                    !questao.state && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextBottom
                        )}
                      >
                        Campo obrigatório.
                      </div>
                    )}
                  {questao.nValid && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                  {!questao.nValid && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            ''
          )}

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                label='Data de Nascimento'
                margin='normal'
                className={classes.inputBirth}
                onBlur={this.validationCadastro('birthDate')}
                InputProps={{
                  inputComponent: this.TextMaskCustom,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={data} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {submitted && !user.birthDate && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
              {submitted && this.state.birthDateValid && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  {'Data de nascimento inválida.'}
                </div>
              )}
            </Grid>

            <Grid
              item
              style={{ float: 'right' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                id='standard-select-currency'
                select
                label='Sexo'
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
                value={user.sex}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='sex'
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={sexo} />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Feminino</MenuItem>
              </TextField>
              {submitted && !user.sex && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextTop
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            {phones.map((phone) => (
              <Grid key={phone.float} item xs={12} sm={4}>
                <TextField
                  label='Telefone'
                  margin='normal'
                  className={classes.input_phone}
                  InputProps={{
                    inputComponent: phone.inputRef,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img className={classes.img_inputs} src={pciente} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {submitted && !user.phone && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    {phone.text}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid>
            {user.nacionalidade === 'B' && (
              <div>
                <TextField
                  label='CEP'
                  margin='normal'
                  value={this.state.user.streetCep}
                  placeholder='Digite o CEP.'
                  className={classes.inputBirth}
                  onChange={this.handleChangeCep}
                  onBlur={() => {
                    this.handleSearchAddress(this.state.user.streetCep);
                  }}
                  InputProps={{
                    inputComponent: this.TextMaskCep,
                    startAdornment: (
                      <InputAdornment position='start'></InputAdornment>
                    ),
                  }}
                ></TextField>
              </div>
            )}
          </Grid>
          {this.state.showButtonIsEdition && (
            <div>
              <Grid container spacing={width > 900 ? 8 : 0}>
                <Grid
                  item
                  style={{ float: 'left' }}
                  className={classNames(classes.inputMobile, 'form-group')}
                >
                  <Autocomplete
                    id='combo-box-demo'
                    options={countries}
                    defaultValue={''}
                    value={{
                      code: this.state.user.codPais
                        ? this.state.user.codPais
                        : '',
                      name: this.state.user.pais ? this.state.user.pais : '',
                    }}
                    onChange={this.handleChangePais}
                    getOptionLabel={(pais) => pais.name}
                    getOptionSelected={(option) =>
                      (option.code = this.state.user.codPais)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label='País' fullWidth />
                    )}
                  />
                  {submitted && !this.state.user.pais && (
                    <div className={classes.alertText}>Campo obrigatório.</div>
                  )}
                </Grid>
                {!(
                  this.state.user.pais === 'Brasil' ||
                  this.state.user.pais === 'Brazil' ||
                  this.state.user.pais === 'United States of America' ||
                  this.state.user.pais === 'Estados Unidos'
                ) && (
                  <Grid
                    item
                    style={{ float: 'right' }}
                    className={classNames(classes.inputMobile, 'form-group')}
                  >
                    <Autocomplete
                      id='combo-box-demo'
                      options={regions}
                      value={{
                        region: this.state.user.regiao,
                      }}
                      onChange={this.handleChangeRegiao}
                      getOptionLabel={(region) => region.region}
                      getOptionSelected={(region) => region.region}
                      renderInput={(params) => (
                        <TextField
                          onBlur={(evt) => {
                            this.handleChangeRegiaoKeyDown(evt);
                            this.validationCadastro('regiao');
                          }}
                          {...params}
                          label='Estado'
                          fullWidth
                        />
                      )}
                    />
                    {submitted &&
                      (!this.state.user.regiao ||
                        this.state.user.regiao == 'nda') &&
                      (this.state.user.pais === 'Brasil' ||
                        this.state.user.pais === 'Brazil' ||
                        this.state.user.pais === 'United States of America' ||
                        this.state.user.pais === 'Estados Unidos') && (
                        <div className={classes.alertText}>
                          Campo obrigatório.
                        </div>
                      )}
                  </Grid>
                )}
                {(this.state.user.pais === 'Brasil' ||
                  this.state.user.pais === 'Brazil' ||
                  this.state.user.pais === 'United States of America' ||
                  this.state.user.pais === 'Estados Unidos') && (
                  <Grid
                    item
                    style={{ float: 'right' }}
                    className={classNames(classes.inputMobile, 'form-group')}
                  >
                    <Autocomplete
                      id='combo-box-demo'
                      options={regions}
                      value={{
                        region: this.state.user.regiao,
                      }}
                      onChange={this.handleChangeRegiao}
                      getOptionLabel={(region) => region.region}
                      getOptionSelected={(region) => region.region}
                      renderInput={(params) => (
                        <TextField {...params} label='Estado' fullWidth />
                      )}
                    />
                    {/*                     {submitted &&
                      (!this.state.user.regiao ||
                        this.state.user.regiao == 'nda') &&
                      (this.state.user.pais === 'Brasil' ||
                        this.state.user.pais === 'Brazil' ||
                        this.state.user.pais === 'United States of America' ||
                        this.state.user.pais === 'Estados Unidos') && (
                      <div className={classes.alertText}>
                          Campo obrigatório.
                        </div> 
                      )} */}
                  </Grid>
                )}
              </Grid>

              {this.state.user.regiao &&
                !(
                  this.state.user.pais === 'Brasil' ||
                  this.state.user.pais === 'Brazil'
                ) &&
                this.state.user.regiao.length > 0 &&
                this.state.user.regiao != 'nda' && (
                  <Autocomplete
                    id='combo-box-demo'
                    value={{
                      Nome: this.state.user.city ? this.state.user.city : '',
                    }}
                    options={cities}
                    onChange={this.handleChangeCity}
                    getOptionLabel={(result) => result.Nome}
                    getOptionSelected={(result) => result.Nome}
                    renderInput={(params) => (
                      <TextField
                        onBlur={(evt) => {
                          this.handleChangeCidadeKeyDown(evt);
                        }}
                        {...params}
                        label='Cidade'
                        fullWidth
                      />
                    )}
                  />
                )}

              {this.state.user.regiao &&
                (this.state.user.pais === 'Brasil' ||
                  this.state.user.pais === 'Brazil') &&
                this.state.user.regiao.length > 0 &&
                this.state.user.regiao != 'nda' && (
                  <Autocomplete
                    id='combo-box-demo'
                    value={{ Nome: this.state.user.city }}
                    options={cities}
                    onChange={this.handleChangeCity}
                    getOptionLabel={(result) => result.Nome}
                    getOptionSelected={(result) => result.Nome}
                    renderInput={(params) => (
                      <TextField
                        ref={this.brazilianRef}
                        {...params}
                        label='Cidade'
                        fullWidth
                      />
                    )}
                  />
                )}
              {submitted &&
                !this.state.user.city &&
                (this.state.user.pais === 'Brasil' ||
                  this.state.user.pais == 'Brazil') && (
                  <div className={classes.alertText}>Campo obrigatório.</div>
                )}
            </div>
          )}
          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item style={{ width: '100%' }}>
              <TextField
                label='Canal de acesso'
                margin='normal'
                value={
                  this.state.user.deviceData.deviceType +
                  ' ' +
                  this.state.user.deviceData.accessChannel +
                  ' ' +
                  this.state.user.deviceData.OS
                }
                disabled={true}
                style={{
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <DevicesIcon />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item xs={12} sm={7}>
              <div
                key={inputs_3[0].type}
                style={{ width: '100%' }}
                className={
                  'form-group' +
                  (submitted && !inputs_3[0].state ? ' has-error' : '')
                }
              >
                <TextField
                  label={inputs_3[0].pergunta}
                  margin='normal'
                  name={inputs_3[0].type}
                  value={inputs_3[0].state}
                  className={classes.input}
                  placeholder={inputs_3[0].pergunta}
                  onBlur={inputs_3[0].blur}
                  type={inputs_3[0].date}
                  onFocus={inputs_3[0].focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            inputs_3[0].imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={inputs_3[0].imagem ? inputs_3[0].imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {submitted && !inputs_3[0].state && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {inputs_3[0].nValid && !(submitted && !inputs_3[0].state) && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    {inputs_3[0].validacao}
                  </div>
                )}
              </div>

              <div
                key={inputs_3[1].type}
                style={{ width: '100%' }}
                className={
                  'form-group' +
                  (submitted && !inputs_3[1].state ? ' has-error' : '')
                }
              >
                <TextField
                  label={inputs_3[1].pergunta}
                  margin='normal'
                  name={inputs_3[1].type}
                  value={inputs_3[1].state}
                  className={classes.input}
                  placeholder={inputs_3[1].pergunta}
                  onBlur={inputs_3[1].blur}
                  type={inputs_3[1].date}
                  onFocus={inputs_3[1].focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            inputs_3[1].imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={inputs_3[1].imagem ? inputs_3[1].imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              {inputs_5.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label={questao.pergunta}
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    startAdornment={
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              ))}
            </Grid>
            <Grid container justify={'center'} style={{ padding: '0 0' }}>
              {user.signUpDocuments.map((doc) => (
                <div
                  style={
                    window.innerWidth > 900
                      ? {
                          width: '90%',
                          padding: '20px',
                          cursor: 'pointer',
                          background: '#fff',
                          borderStyle: 'solid',
                          borderColor: '#C8C8C8',
                          borderWidth: '1px',
                          borderRadius: '5px',
                          marginTop: '5px',
                        }
                      : {
                          width: '70%',
                          padding: '20px',
                          cursor: 'pointer',
                          background: '#fff',
                          borderStyle: 'solid',
                          borderColor: '#C8C8C8',
                          borderWidth: '1px',
                          borderRadius: '5px',
                          marginTop: '5px',
                        }
                  }
                  onClick={async () => {
                    await this.handleOpenRG(doc);
                  }}
                >
                  <div style={{ float: 'left' }}>
                    {doc.document == 'PASSPORT' ? 'PASSAPORTE' : doc.document}
                  </div>
                </div>
              ))}
            </Grid>

            {/* {!showAttendanceVonage && aliasConvenio && (
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleChangeAttendance}
                      color='default'
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      value={this.state.urgencyAttendance}
                    />
                  }
                  label='Atendimento de Emergência'
                />
              </Grid>
            )} */}

            {this.state.urgencyAttendance && (
              <Grid item style={{ width: '70%' }} xs={12} sm={12}>
                <TextField
                  label='Motivo emergência'
                  margin='normal'
                  onChange={(event) => this.handleChangeUrgencyReason(event)}
                  value={this.state.urgencyAttendenceReason}
                  style={{
                    width: '100%',
                  }}
                ></TextField>
              </Grid>
            )}
          </Grid>
          {showAttendanceVonage && (
            <Grid
              container
              justify={'center'}
              style={{ padding: '20px 0', marginTop: '50px' }}
            >
              <Fab
                onClick={this.handleBack}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'red',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Cancelar
              </Fab>
            </Grid>
          )}

          {!showAttendanceVonage && aliasConvenio && (
            <Grid
              container
              justify={'center'}
              style={{ padding: '20px 0', marginTop: '50px' }}
            >
              <Fab
                onClick={this.handleBack}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'red',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Cancelar
              </Fab>

              <Fab
                onClick={this.handleOpenModaSupport}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Enviar para suporte
              </Fab>

              <DialogModal
                open={this.state.openModalRemoveImage}
                text='Tem certeza que deseja remover os documentos anexados?'
                handleCancel={() => this.handleRemoveCancel}
                handleConfirm={() => this.handleRemoveConfirm}
                footerpos={'center'}
                titlepos={'center'}
                buttomSize={'small'}
                withClose
                withActions
                labelOk={'Remover'}
                labelCancel={'Cancelar'}
                typeButtomCancel={'lightRounded'}
                typeButtomConfirm={'dangerLightRounded'}
              >
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleIsDependent}
                        color='default'
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        value={this.state.isDependent}
                      />
                    }
                    label='Deseja remover os documentos do dependente ?'
                  />
                </Grid>
              </DialogModal>

              {!this.state.urgencyAttendance && (
                <Fab
                  variant='extended'
                  aria-label='Delete'
                  className={classes.fab}
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginRight: '5px',
                    margin: '1%',
                  }}
                  type={'submit'}
                >
                  Confirmar
                </Fab>
              )}
            </Grid>
          )}
        </form>
        {registering && (
          <div className={classes.overlay}>
            <div className='loader'>Loading...</div>
          </div>
        )}
      </div>
    );
  }
}
//pra ter
function mapStateToProps(state) {
  const { deletedDocs } = state.deletedDocs;
  const userLogin = state.authentication.user;
  const { registering } = state.registration;
  const { editAcionamento } = state.editAcionamento;
  const { convenios } = state.convenios;
  const { regions } = state.region;
  const { countries } = state.country;
  const { conveniosPortal } = state.conveniosPortal;
  const { cities } = state.citie;
  const { openModalAlert, typeModalAlert, messageModalAlert } =
    state.modalAlert;
  const { message, type, open, loading } = state.alert;

  const { user } = state;

  return {
    conveniosPortal,
    registering,
    editAcionamento,
    message,
    type,
    open,
    loading,
    convenios,
    countries,
    regions,
    cities,
    user,
    userLogin,
    deletedDocs,
    openModalAlert,
    typeModalAlert,
    messageModalAlert,
  };
}

AcionamentoVonage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(AcionamentoVonage);
export default withStyles(styles)(connectedRegisterPage);
