import { codUFNumberToString } from '../commons/index';
import { doctorIdConstants } from '../constants/doctorId.constants';
export const doctorIdActions = { createDoctorId };

function createDoctorId() {
  return (dispatch) => {
    dispatch(set(mergeDoctor()));
  };
  function set(doctorRefId) {
    return { type: doctorIdConstants.SET_DOCTOR_ID, doctorRefId };
  }
}

function mergeDoctor() {
  let { user } = JSON.parse(localStorage.getItem('user'));
  return (
    user.codigo_classe +
    user.classificacao_cod_classe +
    codUFNumberToString[user.uf_cod_classe]
  );
}
