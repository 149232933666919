import React, { useState, useEffect } from 'react';
import * as S from './style';
import CustomButtom from '../CustomButton';
const FilterGeneric = ({
  setFilterStatus,
  setOpenStatus,
  setPageNumber,
  data,
  filterName,
  countFilters,
}) => {
  let arrStatus = [];
  const { id } = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    const receivedStatus = JSON.parse(
      localStorage.getItem(`${id}||${filterName}`)
    );
    let clist = document.getElementsByName(filterName);
    for (const el of clist) {
      let elSplited = el.value.split(',');
      if (receivedStatus?.includes(elSplited[0])) {
        el.checked = true;
      }
      countFilters(receivedStatus?.length);
    }
  }, []);
  let getFiltred = async () => {
    setPageNumber(1);
    let clist = document.getElementsByName(filterName);
    for (const el of clist) {
      if (el.checked) {
        arrStatus.push(...el.value.split(','));
      }
    }
    localStorage.setItem(`${id}||${filterName}`, JSON.stringify(arrStatus));
    countFilters(arrStatus?.length);
    setFilterStatus(arrStatus);
    setOpenStatus(false);
  };
  const clearAll = () => {
    let clist = document.getElementsByName(filterName);
    for (const el of clist) {
      el.checked = false;
    }
    arrStatus = [];
  };
  return (
    <S.Container>
      <S.Title>{filterName}</S.Title>
      <S.ContainerCheck>
        {data?.map((e, index) => {
          return (
            e.text && (
              <S.Check key={e.text + index.toString()}>
                <S.Input
                  type='checkbox'
                  name={filterName}
                  id={index}
                  value={e.value}
                />
                <S.Label htmlFor={index}>{e.text}</S.Label>
              </S.Check>
            )
          );
        })}
      </S.ContainerCheck>
      <S.ContainerButton>
        <CustomButtom
          text={'Limpar filtro'}
          typeButtom={'gray2'}
          onClick={() => clearAll()}
        />
        <CustomButtom
          text={'Aplicar'}
          typeButtom={'primary'}
          onClick={() => {
            getFiltred();
          }}
        />
      </S.ContainerButton>
    </S.Container>
  );
};
export default FilterGeneric;
