import React from 'react';

import { createPortal } from 'react-dom';

import { StyledOverlay } from './styles';

import useBackdrop from './hooks/useBackdrop';

const Backdrop = ({ children }) => {
  useBackdrop();

  return createPortal(<StyledOverlay>{children}</StyledOverlay>, document.body);
};

export default Backdrop;
