import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const ButtonWrapper = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer !important;

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    border-radius: 50%;

    background-color: #00000030;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover::after {
    opacity: 1;
  }
`;
