import React from 'react';

import { CustomButton, CustomSnackbar } from '../';

import { StyledClipboardWrapper } from './styles';

const Clipboard = ({ text, value, notifyOnCopy = true }) => {
  const { snack, trigger } = CustomSnackbar();

  const onClipBoardChange = () => {
    const inputElement = document.createElement('input');

    inputElement.value = value;

    document.body.appendChild(inputElement);

    inputElement.select();
    inputElement.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(value);

    inputElement.remove();

    if (notifyOnCopy) {
      trigger({
        message: 'Link copiado com sucesso!',
        duration: 3000,
        type: 'success',
      });
    }
  };

  return (
    <StyledClipboardWrapper>
      <CustomButton
        text={text}
        onClick={onClipBoardChange}
        icon='FileCopy'
        iconDir='l'
      />
      {snack}
    </StyledClipboardWrapper>
  );
};

export default Clipboard;
