import { loaderConstants } from '../constants';

export const LoaderEdit = {
  loadingEdition,
};

function loadingEdition(edit) {
  return (dispatch) => {
    if (edit) {
      dispatch(start(true));
    } else {
      dispatch(stop(false));
    }
  };

  function start(edit) {
    return { type: loaderConstants.LOADER_START, edit };
  }
  function stop(edit) {
    return { type: loaderConstants.LOADER_STOP, edit };
  }
}
