export const tasks = [
  { nome: 'Descanso' },
  { nome: 'Lanche' },
  { nome: 'Feedback' },
  { nome: 'Pessoal' },
];

export const pauseTypes = [
  { name: 'descanso', selectedIndex: 2 },
  { name: 'lanche', selectedIndex: 3 },
  { name: 'feedback', selectedIndex: 4 },
  { name: 'pessoal', selectedIndex: 5 },
];

export const updateAlertPauseMessages = {
  success: [
    'Alerta de pausa está desabilitado.',
    'Alerta de pausa está habilitado.',
  ],
  danger: [
    'Erro ao desabilitar o alerta de pausa.',
    'Erro ao habilitar o alerta de pausa.',
  ],
};

export const socketSignature = 'changeDataPauseNotification';

export const notificationMessage = 'Faltam 3 minutos para a sua próxima pausa';

export const formMessages = {
  success: 'Jornada iniciada com sucesso!',
  error: 'Preencha o campo motivo corretamente',
  danger: 'Falha ao iniciar jornada',
};