import httpClient from '../../../utils/httpClient';

const resource = 'patient';

const getOne = async (id) => {
  const response = await httpClient.GET(`${resource}/${id}`);

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const getOneByDocument = async (document) => {
  const response = await httpClient.GET(`${resource}/type/${document}`);

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const updateOne = async (id, body) => {
  const response = await httpClient.PUT(`${resource}/${id}`, { body });

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const createOne = async (body) => {
  const response = await httpClient.POST(`${resource}`, { body });

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

export default {
  getOneByDocument,
  createOne,
  updateOne,
  getOne,
};
