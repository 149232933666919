import React from 'react'

import MenuPainel from '../../../../components/MenuPainelNew'

import { Container } from '../../../components/context'

import { CustomButton } from '../../../components/ui'

import { useNavigator } from '../../../hooks'

const Administrator = () => {
	const { push } = useNavigator()

	const handleScalesManagementClick = () => {
		const { user } = JSON.parse(localStorage.getItem('user'))

		const profileType = user.id_perfil.descricao.toLowerCase()

		const views = {
			suporte: '/adm/scales',
			gestor: '/adm/scales',
			administrativo: '/adm/scales/me'
		}

		const routeRedirect = views[profileType] || null

		if (routeRedirect) {
			push(routeRedirect)
		}
	}

	return (
		<>
			<MenuPainel stepper='Gestão de escalas' />

			<Container>
				<CustomButton
					onClick={handleScalesManagementClick}
					rounded={false}
					icon='Timeline'
					size='small'
					type="outlined"
					text='Gestão de escalas'
				/>
			</Container>
		</>

	)
}

export default Administrator
