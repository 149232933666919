import { Text } from '../../../components/Text';

interface HelperTextProps {
  hasQueryFailed?: boolean;
  isFormDirty?: boolean;
  hasFieldErrors?: boolean;
}

export function HelperText({
  hasFieldErrors,
  hasQueryFailed,
  isFormDirty,
}: HelperTextProps) {
  if (isFormDirty) return null;

  if (hasFieldErrors)
    return (
      <Text $color='#d32f2f' $size='12px'>
        Preencha todos os campos para continuar
      </Text>
    );

  if (hasQueryFailed)
    return (
      <Text $color='#d32f2f' $size='12px'>
        O código inserido é inválido
      </Text>
    );

  return null;
}
