import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const SearchService = {
  SearchUser,
};

async function SearchUser(doc, user) {
  let document = doc.document
    .toString()
    .trim()
    .replace(/\./g, '')
    .replace('-', '');
  let documentType = doc.documentType;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({ document, documentType }),
  };

  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/relacionamento/search`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
