import { productsConstants } from '../constants';

export function products(state = { products: [] }, action) {
  switch (action.type) {
    case productsConstants.PRODUCTS_SUCCESS:
      return {
        type: 'success',
        products: action.result,
      };
    case productsConstants.PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        products: [],
      });
    case productsConstants.PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
