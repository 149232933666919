import moment from 'moment';

export const validations = {
  validationEmail,
  validationPassword,
  validationsPasswordPortal,
  validationName,
  validationGenero,
  validationLastName,
  validationCPF,
  validationCPassword,
  validationTerms,
  validationMessage,
  validationSubject,
  validationTelefone,
  validationbirthDate,
  validationRg,
  validationIdRequisicaoParceiro,
  validateRegion,
  validatePais,
  validateCidade,
  formataCPF,
  validarCPF,
  validationPassage,
  validateCity,
  validNumberCard,
  validationCsvDate,
  validationCsvRangeDate,
  validConvenio,
  validationActivitiesCsvRangeDate,
  verifyAgeAcionamento,
  validNumberCardEdit,
  invalidateSpecialCharacters,
  validationPassaport,
};

function validConvenio(convenio) {
  return (validConvenio = convenio !== '');
}

function validNumberCard(numberCard, convenio) {
  switch (convenio) {
    case '5|Care Plus':
      return numberCard.length === 12;
    case '226|Abas':
      return numberCard.length === 20;
    case '301|Cemig':
      return numberCard.length === 14;
    default:
      return numberCard.length >= 12 && numberCard.length <= 20;
  }
}

function validNumberCardEdit(numberCard, convenio) {
  switch (convenio) {
    case '5':
      return numberCard.length === 12;
    case '226':
      return numberCard.length === 20;
    case '301':
      return numberCard.length === 14;
    default:
      return numberCard.length >= 12 && numberCard.length <= 20;
  }
}

function validateCity(city, codPais) {
  let valid = codPais != 'br' || (city && city.length >= 3 && codPais == 'br');
  return valid;
}

function validationPassage(passagem) {
  var validPassage = passagem.length >= 3;
  return validPassage;
}

function validationPassaport(passaport) {
  var validPassport = passaport.length >= 3;
  return validPassport;
}

function validationEmail(email) {
  var validEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  return !validEmail;
}

function validationPassword(password) {
  var validPassword = password.length >= 6;
  return !validPassword;
}

function validationsPasswordPortal(password) {
  return !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);
}

function validationCPassword(password, cpassword) {
  return String(password) === String(cpassword) ? false : true;
}

function validationName(name) {
  var validName = name.length > 1;
  return !validName;
}

function validateRegion(region, pais) {
  var validRegion = region && region.length <= 3;
  if (pais == 'br' || pais == 'us') {
    validRegion = region.length >= 2;
  } else {
    validRegion = true;
  }
  return validRegion;
}

function validatePais(pais) {
  var validPais = pais && pais.length <= 3;
  return !validPais;
}

function validateCidade(cidade, pais) {
  var validCity = false;
  switch (pais == 'br') {
    case true:
      validCity = cidade.length <= 3;
      break;
    case false:
      validCity = false;
      break;
  }
  return !validCity;
}

function validationGenero(age) {
  return !(age === 'M' || age === 'F');
}

function validationTerms(terms) {
  return !terms;
}

function validationLastName(lastname) {
  var validLastName = lastname.length > 1;
  return !validLastName;
}

function validationCPF(CPF) {
  var validCPF = TestaCPF(CPF);
  return !validCPF;
}

function validationbirthDate(birthDate) {
  var data_1 = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ');
  var data = moment(data_1).isAfter(
    moment(moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'))
  );
  var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
  return !(birthDate.match(reg) && !data);
}
function verifyAgeAcionamento(birthDate) {
  const actualDate = moment(new Date()).format('DD-MM-YYYY');
  const age = moment(actualDate).diff(birthDate, 'years');
  return age;
}

function validationCsvDate(csvDate) {
  var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
  return csvDate.match(reg);
}

function validationCsvRangeDate(csvDateIni, csvDateFim) {
  var data_1 = moment(csvDateIni, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ');
  var diff = moment(
    moment(csvDateFim, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ')
  ).diff(data_1, 'months');
  return diff > 3;
}

function validationActivitiesCsvRangeDate(csvDateIni, csvDateFim) {
  var data_1 = moment(csvDateIni, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ');
  var diff = moment(
    moment(csvDateFim, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ')
  ).diff(data_1, 'days');
  return diff > 7;
}

function validationMessage(message) {
  var validMessage = message.length >= 3;
  return !validMessage;
}

function validationSubject(subject) {
  var validSubject = subject.length >= 3;
  return !validSubject;
}

function validationTelefone(telefone) {
  var validTelefone = telefone.toString().length >= 5;
  return validTelefone;
}

function validationRg(rg) {
  var validRg = rg.length >= 3;
  return !validRg;
}

function validarCPF(cpf) {
  let total = 0;
  let rest;

  if (cpf?.length > 0) {
    cpf = cpf.toString().replace(/\./g, '').replace(/-/g, '');
    if (
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }
    for (let i = 1; i <= 9; i++)
      total = total + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (total * 10) % 11;

    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(cpf.substring(9, 10))) return false;

    total = 0;
    for (let i = 1; i <= 10; i++)
      total = total + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (total * 10) % 11;

    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  return false;
}

function formataCPF(cpf) {
  cpf = cpf.toString().replace(/[^\d]/g, '');
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

function TestaCPF(strCPF) {
  strCPF = strCPF?.replace(/\./g, '').replace('-', '');
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function validationIdRequisicaoParceiro(id) {
  var validID = id.length >= 3;
  return validID ? false : true;
}

function invalidateSpecialCharacters(value) {
  var regex = new RegExp('^[a-zA-Z0-9-.]+$');
  if (value === '' || regex.test(value)) return value;
}
