import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const passagemAcionamentoService = {
  findAcionamentoByPassage,
  newPassagemAcionamento,
  getHistoricoEditionData,
  getAllPaAcionamentos,
  editPassagemAcionamento,
  filterAllPaACionamentos,
};

function filterAllPaACionamentos(fields, page, qtd) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(fields),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/pacionamentos/filter/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getAllPaAcionamentos(qtd, pag) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/pacionamentos/filtro/${qtd}/${pag}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function findAcionamentoByPassage(prontuario, passagem) {
  let pront = prontuario
    ? prontuario.length <= 0
      ? 'pront'
      : prontuario
    : 'pront';
  let pass = passagem ? (passagem.length <= 0 ? 'pass' : passagem) : 'pass';
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/pacionamentos/find/${pront}/${pass}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getHistoricoEditionData(user) {
  let historyEdition = {};
  user = JSON.parse(user);
  let {
    _id,
    email,
    name,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    telefone,
  } = user.user;
  historyEdition = {
    _id,
    email,
    cpf,
    telefone,
  };
  return historyEdition;
}

function newPassagemAcionamento(data) {
  let user = window.localStorage.user;
  let editionHistory = this.getHistoricoEditionData(user);
  let {
    email,
    name,
    lastName,
    passagem,
    cpf,
    passport,
    birthDate,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    telefone,
    pais,
    regiao,
    city,
    codPais,
  } = data;
  user = JSON.parse(user);
  let tempData = birthDate.split('/');
  birthDate = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      name: name,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      historicoEdicao: editionHistory,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      plano: 'PA Fisico',
      produto: 'Teletriagem',
      convenioDescricao: user.dataConvenio.descricao,
      convenio: user.dataConvenio.id,
      passage: passagem,
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/pacionamentos/new`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function editPassagemAcionamento(data) {
  let user = window.localStorage.user;
  let editionHistory = this.getHistoricoEditionData(user);
  let {
    _id,
    email,
    name,
    lastName,
    cpf,
    passport,
    birthDate,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    telefone,
    pais,
    regiao,
    city,
    codPais,
  } = data;
  user = JSON.parse(user);
  let tempData = birthDate.split('/');
  birthDate = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      _id: _id,
      email: email,
      name: name,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      historicoEdicao: editionHistory,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      plano: 'PA Fisico',
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/pacionamentos/put`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
