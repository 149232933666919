import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const getNotificationsService = {
  getNotifications,
};

async function getNotifications(
  user,
  pageSize = 5,
  page = 1,
  queryString = ''
) {
  const userStorage = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userStorage.accessToken,
      _id: userStorage.user._id,
    },
    body: JSON.stringify({
      query: queryString,
    }),
  };

  const getService = userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/comunicados/${page}/${pageSize}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);

  return getService;
}
