import React from 'react';
import { CssBaseline } from '@material-ui/core';
import MenuPainel from '../components/MenuPainel';
import AdmAcionamentoEditarSuporte from '../components/AcionamentoSupDisabled';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import GlobalStyle from '../styles/globalStyle';

class AcionamentoEditarSuporte extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        <MenuPainel stepper='Fila de atendimento'>
          <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={<AdmAcionamentoEditarSuporte />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = AcionamentoEditarSuporte;
export { connectedAssinar as AcionamentoEditarSuporte };
