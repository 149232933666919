import { HttpClient } from '../../../../domain/http';
import { HttpClientAdapter, HttpRequest } from '../../../../infra/adapters';
import { RecoverPasswordData } from '../../../../application/data';
import React from 'react';
export const MakeRecoverPassword = () => {
  const httpRequest: HttpRequest = {
    url: `${process.env.REACT_APP_API_CONECTA}/servico/login/forgot`,
    method: 'post',
  };

  const httpClientAdapter = new HttpClientAdapter();
  const httpClient = new HttpClient(httpClientAdapter, httpRequest);

  return new RecoverPasswordData(httpClient);
};
