import { CustomSnackbar, CustomLoader } from '../../components/ui';

import usePauses from './hooks/usePauses';

import { tasks } from './contants';

import { ModalConfirm } from './components';

import { WorkShiftControl } from './components/WorkShiftControlButton';

import { StyledContainer, StyledHeader, StyledHeaderContent } from './styles';

const Schedulle = () => {
  const { snack, trigger } = CustomSnackbar();

  const {
    employee,
    useLoading,
    useAlertPause,
    nextPause,
    selected,
    setSelected,
    blockStake,
    scheduledPause,
    setScheduledPause,
    schedulingPause,
    pauseIndex,
    setPauseIndex,
    pauseTypes,
    changeAlertPause,
    stopWorkShift,
    startWorkShift,
    buttonWorkShiftProps,
    useWorkShiftStart,
    isOutOfScheduleModalOpen,
    closeIsOutOfScheduleModal,
  } = usePauses(trigger);

  function handleConfirmStopWorkShift() {
    closeIsOutOfScheduleModal();
    stopWorkShift({ forceStop: true });
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeaderContent>
          {employee && (
            <WorkShiftControl
              tasks={tasks}
              useAlertPause={useAlertPause}
              nextPause={nextPause}
              selected={selected}
              setSelected={setSelected}
              blockStake={blockStake}
              scheduledPause={scheduledPause}
              setScheduledPause={setScheduledPause}
              schedulingPause={schedulingPause}
              pauseIndex={pauseIndex}
              setPauseIndex={setPauseIndex}
              pauseTypes={pauseTypes}
              changeAlertPause={changeAlertPause}
              buttonWorkShiftProps={buttonWorkShiftProps}
              startWorkShift={startWorkShift}
              useWorkShiftStart={useWorkShiftStart}
              handleStopWorkShift={stopWorkShift}
            />
          )}
        </StyledHeaderContent>
      </StyledHeader>

      <ModalConfirm
        show={isOutOfScheduleModalOpen}
        onCancel={closeIsOutOfScheduleModal}
        onConfirm={handleConfirmStopWorkShift}
      />

      {useLoading && <CustomLoader fixed />}

      {snack}
    </StyledContainer>
  );
};

export default Schedulle;
