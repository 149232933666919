import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: ${(props) => props.pos};
  font-size: 2em;
  font-family: 'Inter' sans-serif;
  font-weight: 500;

  @media (max-width: 1366px) {
    width: 99%;
    h1 {
      padding-left: 4%;
    }
  }

  @media (min-width: 1367px) {
    width: 93%;
    h1 {
      padding-left: 9%;
    }
  }

  height: ${(props) => props.height};
  align-items: center;
`;
