import styled, { css } from 'styled-components';
import InputMaskCustom from '../../../Forms/InputMaskCustom';

export const LabelInput = styled.label`
  text-transform: uppercase;
  margin: 10px 0px 10px 0px;
  color: #2e384d;
  font-size: 12px;
`;

export const DivInput = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SelectDiv = styled.div`
  width: 100%;
`;
export const StyledInputMask = styled(InputMaskCustom)`
  ${(props) =>
    props.errorsName && props.errorsTouch
      ? css`
          border: solid 1px #ff2424;
        `
      : css`
          border: solid 1px #dfe3e8;
        `}
  width : 100%;
  height: 42px;
  outline: none;
  padding-left: 6px;
`;

export const Input = styled.input`
  ${({ color, disabled }) => css`
    width: 100%;
    height: 42px;
    outline: none;
    padding-left: 6px;
    border: ${color || ''};
    border-radius: 5px;
    background-color: #f1f4f6;

    :focus {
      border: 1px solid #2699fb;
      background-color: white;
    }
    ${!disabled &&
    css`
      :hover {
        border: 1px solid #2699fb;
        background-color: white;
      }
    `}
  `}
`;

export const ErrorInput = styled.h5`
  color: #ff2424;
  margin: 0.5px 0 0.5px 0;
  font-size: 10px;
`;
