import React, { useEffect, useState } from 'react';
import moment from 'moment'

import MenuPainel from '../../../../components/MenuPainelNew';

import { Container } from '../../../components/context';

import {
  CustomInput,
  CustomSelect,
  CustomDatePicker,
} from '../../../components/ui/webform';

import {
  CustomDivider,
  CustomButton,
  CustomTable,
  CustomIcon,
  CustomText,
  CustomSnackbar,
  CustomLoader,
} from '../../../components/ui';

import { StyledInputsContainer } from './styles';

import { formatDate, displayValue } from '../../../utils/format';

import {
  locationOptions,
  restPauseOptions,
  snackPauseOptions,
} from './constants';

import useRegisterUserScale from './hooks/useRegisterUserScale';

import useListPauseScale from '../ManagerScales/hooks/useListPauseScale';

import { useNavigator } from '../../../hooks';

const RegisterUserScale = () => {
  const {
    tableRef,
    loadUsers,
    useUsers
  } = useListPauseScale()

  const {
    useLoadingPage,
    snack,
    professional,
    handleProfessional,
    getUsers,
    setDate,
    setSendForm,
    dates,
    entryTime,
    departureTime,
    snackBreakTime,
    snackTime,
    restBreakTime,
    restTime,
    unit,
    handleSubmit,
    scales,
    handleRemove,
    dateForm,
    updateField,
    searchMoreData,
    useLoading,
    clearState,
    useLoadingDeleteScales,
    removeAllScales,
  } = useRegisterUserScale();

  useEffect(() => {
    loadUsers()
  }, [])

  const { push } = useNavigator();
  const [hasScaleWhithoutLogoutTime, setScaleWhithoutLogoutTime] = useState([])
  const scalesToBeRemoved = [...new Set(hasScaleWhithoutLogoutTime)]

  function handleCanDelete(userId) {
    const workShiftUser = useUsers?.filter(user => user.userId === userId)

    if (workShiftUser?.length) {
      const [{ timeLogout }] = workShiftUser ?? {}
      return timeLogout
    }
    return false
  }

  useEffect(() => {
    if (!scales?.length && scalesToBeRemoved?.length) {
      setScaleWhithoutLogoutTime([])
    }
  }, [scales])

  const columns = [
    {
      title: 'FUNCIONÁRIO',
      value: ({ userName }) => (
        <CustomText color='dark' title={1}>
          {displayValue(userName)}
        </CustomText>
      ),
    },
    {
      title: 'DATA',
      value: ({ date }) => (
        <CustomText color='dark'>{displayValue(formatDate(date))}</CustomText>
      ),
    },
    ,
    {
      title: 'HORÁRIO ENTRADA',
      value: ({ entryTime }) => (
        <CustomText color='dark'>
          {displayValue(formatDate(entryTime, 'HH:mm'))}
        </CustomText>
      ),
    },
    {
      title: 'HORÁRIO SAÍDA',
      value: ({ departureTime }) => (
        <CustomText color='dark'>
          {displayValue(formatDate(departureTime, 'HH:mm'))}
        </CustomText>
      ),
    },
    {
      title: 'HORÁRIO LANCHE',
      value: ({ snackBreakTime }) => (
        <CustomText color='dark'>{displayValue(snackBreakTime)}</CustomText>
      ),
    },
    {
      title: 'TEMPO LANCHE',
      value: ({ snackBreakDuration }) => (
        <CustomText color='dark'>{displayValue(snackBreakDuration)}</CustomText>
      ),
    },
    {
      title: 'HORÁRIO DESCANSO',
      value: ({ restBreakTime }) => (
        <CustomText color='dark'>{displayValue(restBreakTime)}</CustomText>
      ),
    },
    {
      title: 'TEMPO DESCANSO',
      value: ({ restBreakDuration }) => (
        <CustomText color='dark'>{displayValue(restBreakDuration)}</CustomText>
      ),
    },
    {
      title: '',
      value: ({ departureTime, _id, userId }) => {
        const isAllowedToDelete = moment(departureTime).isSameOrAfter(moment().subtract(24, 'hours'))
        const timeLogout = handleCanDelete(userId)

        if (isAllowedToDelete && !timeLogout) {
          return (
            <CustomIcon icon='Delete' onClick={() => handleRemove(_id)} />
          )
        }

        const hasScale = hasScaleWhithoutLogoutTime.find((id) => id === _id)

        if (!hasScale) {
          setScaleWhithoutLogoutTime((old) => [...old, _id])
        }

        return (
          <></>
        )
      },
    },

  ];

  return (
    <>
      <MenuPainel stepper='Gestão de escalas' />

      <Container>
        <CustomDivider />
        <CustomButton
          rounded={false}
          onClick={() => push('/adm/scales')}
          icon='Timeline'
          size='small'
          type='outlined'
          text='Gestão de escalas'
        />
        <CustomDivider />
        <StyledInputsContainer>
          <CustomSelect
            value={professional}
            onChange={(e) => handleProfessional(e)}
            options={(e) => getUsers(e)}
            title='PROFISSIONAL'
            placeholder='Selecione um profissional'
          />
          <CustomDatePicker
            title='DATAS'
            name='datas'
            onFocusedDateChange={(dateFocused, dateClicked) => {
              setDate({ dateFocused, dateClicked });
            }}
            onClose={() => setSendForm(dateForm)}
            value={dates}
          />
          <CustomInput
            value={entryTime}
            onChange={(e) =>
              updateField({ label: 'entryTime', value: e.target.value })
            }
            title='HORÁRIO DE ENTRADA'
            type='time'
          />
          <CustomInput
            value={departureTime}
            onChange={(e) =>
              updateField({ label: 'departureTime', value: e.target.value })
            }
            title='HORÁRIO DE SAÍDA'
            type='time'
          />
          <CustomInput
            title='HORÁRIO DE PAUSA LANCHE'
            value={snackBreakTime}
            onChange={(e) =>
              updateField({ label: 'snackBreakTime', value: e.target.value })
            }
            type='time'
          />
          <CustomSelect
            value={snackTime}
            onChange={(e) =>
              updateField({ label: 'snackTime', value: e.value })
            }
            options={snackPauseOptions}
            title='TEMPO DE PAUSA LANCHE'
          />
          <CustomInput
            title='HORÁRIO DE PAUSA DESCANSO'
            value={restBreakTime}
            onChange={(e) =>
              updateField({ label: 'restBreakTime', value: e.target.value })
            }
            type='time'
          />
          <CustomSelect
            value={restTime}
            onChange={(e) => updateField({ label: 'restTime', value: e.value })}
            options={restPauseOptions}
            title='TEMPO DE PAUSA DESCANSO'
          />
          <CustomSelect
            value={unit}
            onChange={(e) => updateField({ label: 'unit', value: e.value })}
            options={locationOptions}
            title='UNIDADE'
          />
          <CustomButton
            onClick={clearState}
            rounded={false}
            color='danger'
            size='large'
            text='Limpar'
            type='outlined'
          />
          <CustomButton
            onClick={handleSubmit}
            loading={useLoading}
            rounded={false}
            size='large'
            text='Inserir'
            type='outlined'
          />
        </StyledInputsContainer>

        {scales?.length > 0 && (
          <>
            <CustomDivider space={5} />
            <CustomDivider space={0} height={1} border={1} />
            <CustomDivider space={5} />
            <CustomButton
              onClick={() => {
                removeAllScales(scalesToBeRemoved);
                setScaleWhithoutLogoutTime([])
              }}
              loading={useLoadingDeleteScales}
              rounded={false}
              size='medium'
              text='Excluir todas as escalas'
              color='danger'
            />
          </>
        )}

        <CustomTable
          columns={columns}
          data={scales}
          onScroll={searchMoreData}
        />

        {useLoadingPage && <CustomLoader fixed={true} />}

        {snack}
        <div ref={tableRef} />
      </Container>
    </>
  );
};

export default RegisterUserScale;
