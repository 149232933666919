import { socketConstants, socketConstantsTime } from '../constants';

export function socketInfos(state = { socketInfos: {} }, action) {
  switch (action.type) {
    case socketConstants.SOCKETINFOS_SUCCESS:
      return {
        type: 'success',
        socketInfos: action.result,
      };
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}

export function socketInfosTime(state = { socketInfosTime: {} }, action) {
  switch (action.type) {
    case socketConstantsTime.SOCKETINFOS_SUCCESS_TIME:
      return {
        type: 'success',
        socketInfosTime: action.time,
      };
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
