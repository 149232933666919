import {
  StyledContainerNoData,
  StyledTableContainer,
  StyledTable,
  StyledTableBody,
  StyledTableHeader,
  StyledTableMessage,
} from './styles';

import { FadeIn } from '../../context';

import { findValue } from '../../../utils/common';

import useTable from './hooks/useTable';

const Table = ({
  align = 'left',
  columns = [],
  data,
  onScroll,
  loadMore = false,
}) => {
  const { loaderRef, useLoadMore } = useTable(data, onScroll);

  return (
    <StyledTableContainer>
      <StyledTable align={align}>
        <StyledTableHeader>
          <tr>
            {columns.map((c, i) => (
              <th key={i}>{c.title}</th>
            ))}
          </tr>
        </StyledTableHeader>
        <StyledTableBody empty={data === null || (data && data.length === 0)}>
          {data &&
            data.length > 0 &&
            data.map((listObject, listObjectIdx) => (
              <tr key={listObjectIdx}>
                {columns.map((_, listObjectEntriesKey) => (
                  <td key={listObjectEntriesKey}>
                    {columns[listObjectEntriesKey]?.value
                      ? columns[listObjectEntriesKey].value(listObject)
                      : findValue(
                          listObject,
                          columns[listObjectEntriesKey]?.field
                        )}
                  </td>
                ))}
              </tr>
            ))}
        </StyledTableBody>
      </StyledTable>
      <StyledContainerNoData>
        <div ref={loaderRef} />

        {data && data.length > 1 && (
          <FadeIn visible={useLoadMore || loadMore}>
            <StyledTableMessage>
              Carregando mais informações...
            </StyledTableMessage>
          </FadeIn>
        )}

        {(!data || !data.length) && (
          <StyledTableMessage>
            Não há dados a serem exibidos.
          </StyledTableMessage>
        )}

      </StyledContainerNoData>
    </StyledTableContainer>
  );
};

export default Table;
