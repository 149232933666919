import React from 'react';

import { MaterialChip } from './styles';

import { CustomIcon } from '..';

const CustomChip = ({
  label,
  icon,
  applyBaseColor,
  color = 'default',
  width = null,
  bold = false,
  outlined = false,
}) => (
  <MaterialChip
    icon={icon ? <CustomIcon icon={icon} /> : undefined}
    applyBaseColor={applyBaseColor}
    $color={color}
    $outlined={outlined}
    label={label}
    bold={bold}
    width={width}
  />
);

export default CustomChip;
