import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import actions from '../../../redux/actions';

import {
  scheduledPauseService,
  userSettingsService,
  workShiftService,
} from '../../../services/core';

import { pauseTypes } from '../contants/index';

import { useLocalStorage } from '../../../hooks';

import {
  updateAlertPauseMessages,
  socketSignature,
  notificationMessage,
  formMessages,
} from '../contants';

import { isPauseAlertToCurrentUser } from '../utils';

import { customEvent } from '../../../utils/constants';

import { useNavigator } from '../../../hooks';
import { sleep } from '../../../utils/common';
import { socket } from '../../../../utils/socket';

const WORKSHIFT_ERROR_CODES = {
  OutOfSchedule: {
    code: 'FINISHING_OUT_OF_SCHEDULE',
    message: 'Você está finalizando sua jornada fora do horário previsto',
  },
};

const usePauses = (trigger) => {
  const [useWorkShiftStart, setWorkShiftStart] = useState(false);
  const [useWorkShiftLate, setWorkShiftLate] = useState(false);
  const [useWorkShiftEntryTime, setWorkShiftEntryTime] = useState(null);
  const [useLoading, setLoading] = useState(false);
  const [useAlertPause, setAlertPause] = useLocalStorage(
    'hasAlertPause',
    false
  );
  const [selected, setSelected] = useState(false);
  const [nextPause, setNextPause] = useState({
    pause: '-',
    pauseHour: '-',
  });
  const [employee, setEmployee] = useState(false);
  const [scheduledPause, setScheduledPause] = useState({
    marked: false,
    typeSelected: false,
  });
  const [pauseIndex, setPauseIndex] = useState(0);
  const [isOutOfScheduleModalOpen, setIsOutOfScheduleModalOpen] =
    useState(false);

  const stateSP = useSelector((s) => s.SCHEDULE_PAUSE);

  const { user } = useSelector((s) => s.users);

  const { push } = useNavigator();

  useEffect(() => {
    socket.on(socketSignature, (payload) => {
      const { operationType, updateDescription } = payload;

      if (
        operationType === 'update' &&
        useAlertPause &&
        isPauseAlertToCurrentUser(updateDescription.updatedFields)
      ) {
        triggerSnackBarAlertPause();
      }
    });

    document.addEventListener(customEvent.lockUser, () => {
      push('/adm/lock-station');
    });

    if (stateSP) {
      const { loaded, loading, error, response } = stateSP;
      if (loading || !loaded) return;
      if (error) return;
    }

    if (user) {
      if (user?.user?.id_perfil?.descricao === 'Administrativo') {
        (async () => {
          setEmployee(true);

          mountNextPause();

          mountPause();
        })();
      }
    }
  }, [stateSP]);

  const triggerSnackBarAlertPause = () => {
    trigger({
      message: notificationMessage,
      type: 'warning',
      duration: 6000,
    });
  };

  const blockStake = async (typeRest) => {
    if (scheduledPause.marked) return;

    setSelected(false);

    const pauseAction = await actions.schedule.servicePause({
      start: true,
      intervalType: typeRest,
      reactivateStation: false,
    });

    setLoading(true);
    await sleep(10000);

    if (pauseAction) {
      mountPause();
    }
  };

  const verifyNextPause = async () => {
    return await actions.schedule.serviceNextPause();
  };

  const verifyPauseStatus = async () => {
    return await actions.schedule.serviceStatus();
  };

  const mountPause = async () => {
    const { data } = await verifyPauseStatus();

    if (data?.status === 403 || data?.userId) {
      push('/adm/lock-station');
    }
  };

  const mountNextPause = async () => {
    const { data: dataNextPause } = await verifyNextPause();

    typeof dataNextPause?.userSettings === 'boolean' &&
      setAlertPause(dataNextPause.userSettings);

    if (dataNextPause.nextPause) {
      setWorkShiftEntryTime(dataNextPause.entryTime);
      setWorkShiftStart(dataNextPause.workShift);
      setNextPause({
        pause: dataNextPause.nextPause,
        pauseHour: dataNextPause.nextPauseHour,
      });
    }
  };

  const schedulingPause = async (intervalType) => {
    const response = await scheduledPauseService.schedulingPause(intervalType);

    const error = !response?.status ? await response.response.text() : '';

    const result = {
      status: response?.status || false,
      error: error && JSON.parse(error),
    };

    return result;
  };

  const changeAlertPause = async () => {
    const newAlertPause = !useAlertPause;

    const response = await userSettingsService.update({
      isNotificationActive: newAlertPause,
    });

    const triggerType = response.status ? 'success' : 'danger';

    trigger({
      message: updateAlertPauseMessages[triggerType][+newAlertPause],
      type: triggerType,
      duration: 3000,
    });

    setAlertPause(newAlertPause);
  };

  const onClose = () => {
    setWorkShiftLate(false);
  };

  const onSuccess = (message = null) => {
    setWorkShiftLate(false);
    trigger({
      message: message ? message : formMessages.success,
      type: 'success',
      duration: 3000,
    });
  };

  const onError = (isInvalidation = false, message = null) => {
    if (isInvalidation) {
      trigger({
        message: message ? message : formMessages.error,
        type: 'danger',
        duration: 3000,
      });
    } else {
      trigger({
        message: message ? message : formMessages.danger,
        type: 'danger',
        duration: 3000,
      });
    }
  };

  const onAlert = () => {
    trigger({
      message: 'Inicie sua jornada para ter acesso e realizar pausas.',
      type: 'warning',
      duration: 3000,
    });
  };

  const closeIsOutOfScheduleModal = () => setIsOutOfScheduleModalOpen(false);

  const openIsOutOfScheduleModal = () => setIsOutOfScheduleModalOpen(true);

  const startWorkShift = async () => {
    setLoading(true);

    const { status, data } = await workShiftService.toggleWorkshift({
      isStart: true,
    });

    setLoading(false);

    if (status) {
      setWorkShiftStart(true);
      if (data?.isLate) {
        setWorkShiftLate(true);
      }

      trigger({
        message: 'Sucesso ao iniciar uma jornada de trabalho',
        type: 'success',
        duration: 3000,
      });

      return;
    }

    trigger({
      message: data?.message ?? 'Falha ao iniciar uma jornada de trabalho',
      type: 'danger',
      duration: 3000,
    });
  };

  const stopWorkShift = async ({ forceStop } = {}) => {
    setLoading(true);

    const { status, data } = await workShiftService.toggleWorkshift({
      isStart: false,
      forceStop,
    });

    setLoading(false);

    if (status) {
      setWorkShiftStart(false);
      return trigger({
        message: 'Sucesso ao finalizar uma jornada de trabalho',
        type: 'success',
        duration: 3000,
      });
    }

    if (data?.message === WORKSHIFT_ERROR_CODES.OutOfSchedule.code) {
      openIsOutOfScheduleModal();
      return;
    }

    trigger({
      message: data.message ?? 'Falha ao finalizar uma jornada de trabalho',
      type: 'danger',
      duration: 3000,
    });
  };

  const buttonWorkShiftProps = {
    ...(useWorkShiftStart
      ? {
          icon: 'StopOutlined',
          text: 'Finalizar jornada',
          type: 'outlined',
          color: 'danger',
          onClick: () => stopWorkShift(),
        }
      : {
          icon: 'PlayArrow',
          text: 'Iniciar jornada',
          onClick: () => startWorkShift(),
        }),
    rounded: true,
    full: true,
  };

  return {
    useLoading,
    onSuccess,
    onError,
    onClose,
    onAlert,
    buttonWorkShiftProps,
    useWorkShiftLate,
    setSelected,
    mountPause,
    employee,
    nextPause,
    selected,
    useAlertPause,
    changeAlertPause,
    blockStake,
    scheduledPause,
    setScheduledPause,
    schedulingPause,
    pauseIndex,
    setPauseIndex,
    pauseTypes,
    useWorkShiftEntryTime,
    stopWorkShift,
    startWorkShift,
    useWorkShiftStart,
    useWorkShiftStart,
    isOutOfScheduleModalOpen,
    closeIsOutOfScheduleModal,
  };
};

export default usePauses;
