/* eslint-disable no-throw-literal */
/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { userService } from '../../services';
import { customEvent } from '../utils/constants';
const url_base = process.env.REACT_APP_API_CONECTA;
const typesErrors = [301, 3000, 400, 401, 500];
const UNAUTHORIZED_MESSAGE = 'Não Autorizado';

const getRequestOptions = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  const defaultHeaders = {
    ...(user
      ? {
          'x-access-token': user?.accessToken,
          _id: user?.user?._id,
        }
      : {}),
  };
  return { timeout: 1200000, headers: { ...defaultHeaders } };
};

axios.interceptors.response.use(
  (config) => config,
  (error) => {
    const { response } = error;

    if (response.status === 403 && response?.data?.isPaused) {
      const event = new Event(customEvent.lockUser);

      document.dispatchEvent(event);
    }

    return Promise.reject(error);
  }
);

const handleUnauthorizedUser = ( message = UNAUTHORIZED_MESSAGE ) => {
  userService.logout();
  localStorage.setItem('unauthorizedMessage', message);
}

const get = async (uri) => {
  try {
    const url = `${url_base || ''}${uri}`;
    const response = await axios.get(url, getRequestOptions());

    if (typesErrors.includes(response.status)) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    const data =
      typeof response.data === 'string'
        ? response.data
        : {
            ...response.data,
          };

    return {
      err: false,
      data,
    };
  } catch (error) {
    const data = error?.response?.data
    if (error?.response.status === 401) handleUnauthorizedUser(data?.message)
    const dataError = {
      code: `GET[${uri}] error`,
      status: error?.response.status || 500,
      data: data || 500,
      message: error?.message || 'unexpected error',
    };
    return {
      err: true,
      data: dataError,
    };
  }
};

const post = async (uri, payload) => {
  try {
    const url = `${url_base || ''}${uri}`;
    const response = await axios.post(url, { ...payload }, getRequestOptions());

    if (typesErrors.includes(response.status)) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return {
      err: false,
      data: {
        ...response.data,
      },
    };
  } catch (error) {
    const data = error?.response?.data
    if (error?.response.status === 401) handleUnauthorizedUser(data?.message)
    const dataReturn = {
      err: true,
      code: `POST[${uri}] error`,
      status: error?.status || error?.response?.status || 500,
      message: error?.message || 'unexpected error',
      data: data || 500,
    };
    return dataReturn;
  }
};

const put = async (uri, payload) => {
  try {
    const url = `${url_base || ''}${uri}`;
    const response = await axios.put(url, { ...payload }, getRequestOptions());

    if (typesErrors.includes(response.status)) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return {
      err: false,
      data: {
        ...response.data,
      },
    };
  } catch (error) {
    const data = error?.response?.data
    if (error?.response.status === 401) handleUnauthorizedUser(data?.message)
    throw {
      err: true,
      code: `POST[${uri}] error`,
      status: error?.status || error?.response?.status || 500,
      message:
        error?.response?.data?.message || error?.message || 'unexpected error',
      data: data || 500,
    };
  }
};
const Delete = async (uri) => {
  try {
    const url = `${url_base || ''}${uri}`;
    const response = await axios.delete(url, getRequestOptions());

    if (typesErrors.includes(response.status)) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return {
      err: false,
      data: {
        ...response.data,
      },
    };
  } catch (error) {
    const data = error?.response?.data
    if (error?.response.status === 401) handleUnauthorizedUser(data?.message)
    throw {
      err: true,
      code: `DELETE[${uri}] error`,
      status: error?.status || error?.response?.status || 500,
      message:
        error?.response?.data?.message || error?.message || 'unexpected error',
      data: data || 500,
    };
  }
};
export default {
  get,
  put,
  post,
  Delete,
};
