import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const Container = styled(Grid)`
  padding: 10px 5px;
  margin-bottom: 21px;
  border: solid 1px #ccc;
  border-radius: 10px;
`;
const arrayStatus = [
  'CANCELED_CHARGEBACK',
  'CANCELED_WITH_PENALITY',
  'CANCELED_WITHOUT_PENALITY',
];

const ContainerItens = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;

  h4 {
    padding: 0 5px;
    height: 30px;
  }

  div {
    margin: 4px;
  }
  .subtitle {
    fontsize: 12px;
    color: #52575d;
  }
`;

const ContainerMessage = styled.div`
  fontsize: 14px;
`;

const ContainerJustification = styled.div`
  fontsize: 14px;
`;

export const HistoryCancelments = ({ dataSource, moment }) => {
  return dataSource.map((el) => (
    <Container>
      <ContainerItens>
        <div>
          <h4>Status</h4>
          <p className='subtitle'>{el.description}</p>
        </div>
        <div>
          <h4>Motivo</h4>
          <div className='subtitle'>{el.observation}</div>
        </div>
        <div>
          <h4>Responsável pela tratativa</h4>
          <div className=''> {el.operator}</div>
        </div>
        <div>
          <h4>Última Atualização</h4>
          <div className='subtitle'>
            {el.updatedTime &&
              moment(el.updatedTime, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )}
          </div>
        </div>
        <div>
          <div>
            {el.description === 'Cliente Retido' ||
            el.description === 'Atraso de pagamento' ||
            el.description === 'Cancelamento Com Estorno' ||
            el.description === 'Cancelamento com multa' ||
            el.description === 'Cancelamento sem multa' ||
            el.description === 'Aberto' ? (
              <> </>
            ) : (
              <>
                <h4>Contato futuro</h4>
                {el.futureDate && (
                  <div>
                    {moment(el.futureDate, 'YYYY-MM-DDTHH:mm:ss').format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </ContainerItens>
      <ContainerJustification>
        {arrayStatus.includes(el.status) ? (
          el.erroBroker ? (
            <b>Status broker: ❌</b>
          ) : (
            <b>Status broker: ✔️</b>
          )
        ) : (
          ''
        )}
      </ContainerJustification>
      <ContainerJustification>
        {!el.justification ? <p></p> : <p>Justificativa: {el.justification}</p>}
      </ContainerJustification>
      <ContainerMessage>
        {!el.message ? <p></p> : <p>Mensagem: {el.message}</p>}
      </ContainerMessage>
    </Container>
  ));
};
