import React from 'react';
import { LabelInput, DivInput, Input } from './styles';
import PropTypes from 'prop-types';

function FormInput({
  labelTitle,
  handleBlur,
  handleChange,
  placeholder,
  name,
  type,
  id,
  value,
  disabled,
  fullWidth,
  maskChar,
  mask,
  validation,
  color,
  ...props
}) {
  return (
    <>
      <LabelInput>{labelTitle}</LabelInput>
      <DivInput>
        <Input
          {...props}
          placeholder={placeholder}
          name={name}
          type={type}
          id={id}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          mask={mask}
          maskChar={maskChar}
          fullWidth={fullWidth}
          disabled={disabled}
          color={
            validation?.errors && validation?.touched
              ? color
              : '1px solid #ced4da'
          }
        />
      </DivInput>
    </>
  );
}

FormInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  placeholder: PropTypes.string,
  disable: PropTypes.bool,
};

export default FormInput;
