import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ButtonContentCreate = styled.button`
  width:50%;
  color:  ${(props) => (props.createComunication ? 'blue' : 'black')};
  font-weight: 600;
  font-size:15px;
  background:none;
  height: 60px;
  cursor:pointer;

  border-top:#BEBDB8 0.2px solid;
  border-bottom:${(props) =>
    props.createComunication ? 'none' : '#BEBDB8 0.2px solid'};
  border-left:none;
  border-right: ${(props) =>
    props.createComunication ? '#BEBDB8 0.2px solid' : 'none'};
  box-shadow: ${(props) =>
    props.createComunication ? 'box-shadow: 5px 0 5px -5px red' : 'none'}
  border-top-right-radius: ${(props) =>
    props.createComunication ? '10px 10px' : 'none'}
`;
export const ButtonContentHistory = styled.button`
  width: 50%;
  color: ${(props) => (props.historyComunication ? 'blue' : 'black')};
  font-weight: 600;
  font-size: 15px;
  background: none;
  height: 60px;
  cursor: pointer;

  border-top: #bebdb8 0.2px solid;
  border-bottom: ${(props) =>
    props.historyComunication ? 'none' : '#BEBDB8 0.2px solid'};
  border-left: ${(props) =>
    props.historyComunication ? '#BEBDB8 0.2px solid' : 'none'};
  border-right: none;
  border-top-left-radius: ${(props) =>
    props.historyComunication ? '10px 10px' : 'none'};
`;
