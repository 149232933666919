import { useNavigate } from 'react-router-dom';

const useNavigator = () => {
  const navigate = useNavigate();

  const push = (nextRoute) => {
    navigate(nextRoute);
  };

  const back = () => {
    navigate(-1);
  };

  return {
    push,
    back,
  };
};

export default useNavigator;
