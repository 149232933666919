import React from 'react';
import { Formik, Form, FieldArray } from 'formik';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { Title } from '../TitulosGenericos/TitleTextField';
import { history } from '../../helpers';

import { Links, Error, ErrorMessageRed } from './styles/formComunicados';

import * as Yup from 'yup';

export function Create({ initialValues, disabled, handleSubmit }) {
  function handleBack() {
    history.push('/adm');
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .trim()
          .max(100, 'máximo de 100 caracteres')
          .required('Comunicado precisa de Título'),
        content: Yup.string()
          .trim()
          .max(700, 'máximo de 700 caracteres')
          .required('Comunicado precisa de uma descrição'),

        links: Yup.array().of(
          Yup.object().shape({
            link: Yup.string().matches(
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
              'Insira uma URL válida'
            ),
          })
        ),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form>
          <Grid item xs={12}>
            <Title string={'Título'} />
            <TextField
              name='title'
              placeholder='Título'
              value={values.title}
              onChange={handleChange}
              fullWidth
              variant='outlined'
              disabled={disabled}
              onBlur={handleBlur}
            />
            {errors.title && touched.title && <Error>{errors.title}</Error>}
          </Grid>
          <Grid item xs={12}>
            <Title string={'Descrição'} />
            <TextField
              name='content'
              placeholder='Descrição'
              value={values.content}
              onChange={handleChange}
              fullWidth
              variant='outlined'
              multiline
              rows={10}
              disabled={disabled}
              onBlur={handleBlur}
            />
            {errors.content && touched.content && (
              <Error>{errors.content}</Error>
            )}
          </Grid>
          <Form>
            <Title string={'Links'} />
            <FieldArray
              name='links'
              render={({ push }) => (
                <div>
                  {values.links.length > 0 &&
                    values.links.map((link, index) => (
                      <Links>
                        <Grid
                          item
                          xs={12}
                          key={index}
                          style={{ marginTop: '12px' }}
                        >
                          <TextField
                            name={`links.${index}.link`}
                            placeholder='Link'
                            type='text'
                            value={values.links[index].link}
                            onChange={handleChange}
                            fullWidth
                            variant='outlined'
                            disabled={disabled}
                          />
                          {errors.links && touched.links && (
                            <ErrorMessageRed name={`links.${index}.link`}>
                              {(msg) => (
                                <div style={{ color: 'red' }}>{msg}</div>
                              )}
                            </ErrorMessageRed>
                          )}
                        </Grid>

                        {values.links.length - 1 == index && (
                          <Button
                            onClick={() => push({ link: '' })}
                            pointer
                            disabled={disabled}
                          >
                            <AddBoxIcon />
                          </Button>
                        )}
                      </Links>
                    ))}
                </div>
              )}
            />
          </Form>
          <Grid item xs={6}>
            <Title string='Data de início' />
            <TextField
              name='startDate'
              type='date'
              placeholder='Título'
              value={values.startDate}
              onChange={handleChange}
              fullWidth
              variant='outlined'
              disabled={disabled}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <Title string='Enviar para:' />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.reception}
                  onChange={handleChange}
                  name='reception'
                  color='primary'
                  disabled={disabled}
                />
              }
              label='Recepção'
              onBlur={handleBlur}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.support}
                  onChange={handleChange}
                  name='support'
                  color='primary'
                  disabled={disabled}
                />
              }
              label='Suporte'
              onBlur={handleBlur}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.administration}
                  onChange={handleChange}
                  name='administration'
                  color='primary'
                  disabled={disabled}
                />
              }
              label='Administrativo'
              onBlur={handleBlur}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.manager}
                  onChange={handleChange}
                  name='manager'
                  color='primary'
                  disabled={disabled}
                />
              }
              label='Gestor'
              onBlur={handleBlur}
            />
          </Grid>

          <Grid
            container
            justify={'center'}
            style={{ padding: '20px 0', marginTop: '50px' }}
          >
            {!disabled && (
              <>
                <Fab
                  onClick={handleBack}
                  variant='extended'
                  aria-label='Delete'
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background: 'white',
                    color: 'red',
                    boxShadow: 'none',
                    border: '1px solid',
                    margin: '1%',
                  }}
                >
                  Cancelar
                </Fab>
                <Fab
                  variant='extended'
                  aria-label='Delete'
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginRight: '5px',
                    margin: '1%',
                  }}
                  type={'submit'}
                >
                  Confirmar
                </Fab>
              </>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
