import React from 'react';
import MenuPainel from '../components/MenuPainel';
import { FilaPortalConecta } from '../components/FilaPortalConecta';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';
class FilaPortalConectaAdm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message, type, open } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        <MenuPainel stepper='Fila Portal Conecta'>
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<FilaPortalConecta />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { message, type, open } = state.alert;
  return {
    message,
    type,
    open,
  };
}

FilaPortalConectaAdm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(FilaPortalConectaAdm);
export { connectedRegisterPage as FilaPortalConectaAdm };
