export const reasonRemoveData = [
  { label: 'Outros', value: 'Outros' },
  {
    label: 'Paciente não estava conectado ao iniciarmos o contato',
    value: 'Paciente não estava conectado ao iniciarmos o contato',
  },
  {
    label: 'Perda de conexão durante o atendimento',
    value: 'Perda de conexão durante o atendimento',
  },
  {
    label: 'Conexão ruim / Inapropriada para atendimento',
    value: 'Conexão ruim / Inapropriada para atendimento',
  },
  {
    label: 'Atendimento para outro paciente',
    value: 'Atendimento para outro paciente',
  },
  {
    label: 'Perda de conexão durante o atendimento',
    value: 'Perda de conexão durante o atendimento',
  },
  {
    label: 'Conexão ruim / Inapropriada para atendimento',
    value: 'Conexão ruim / Inapropriada para atendimento',
  },
  {
    label: 'Não estava com documento com foto em mãos (Digital ou Físico)',
    value: 'Não estava com documento com foto em mãos (Digital ou Físico)',
  },
  {
    label: 'Não sabia o número da carteirinha',
    value: 'Não sabia o número da carteirinha',
  },
  {
    label: 'Assuntos relacionados ao convênio',
    value: 'Assuntos relacionados ao convênio',
  },
  { label: 'Testes', value: 'Testes' },
  {
    label: 'Queria conhecer o funcionamento do serviço',
    value: 'Queria conhecer o funcionamento do serviço',
  },
  {
    label: 'Dúvidas não atendidas pela telemedicina',
    value: 'Dúvidas não atendidas pela telemedicina',
  },
  {
    label: 'Agendamento de consultas / exames',
    value: 'Agendamento de consultas / exames',
  },
  { label: 'Atendimento de Emergência', value: 'Atendimento de Emergência' },
];
