import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: ${({ full }) => (full ? '100%' : 'unset')};

  @media (max-width: 568px) {
    width: 100%;

    input {
      width: 100% !important;
      box-sizing: border-box;
    }
  }
`;

export const StyledInputValue = styled.input`
  outline: 0;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  color: #76818f;
  width: ${({ full }) => (full ? '100%' : 'max-content')};
  cursor: ${({ type, disabled }) => {
    if (disabled) return 'not-allowed';

    return type === 'text' ? type : 'pointer';
  }};
  border: ${({ border }) => border};

  &:focus {
    border: ${({ error }) => !error && '1px solid #2699FB'};
  }
`;

// @TODO: remove fixed font style and inject it on app
export const StyledTextareaValue = styled.textarea`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  outline: 0;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  color: #76818f;
  background-color: #e5e5e550;
  width: ${({ full, $width }) =>
    full ? '100%' : $width ? $width : 'max-content'};
  cursor: ${({ type, disabled }) => {
    if (disabled) return 'not-allowed';

    return type === 'text' ? type : 'pointer';
  }};
  border: ${({ border }) => border};

  resize: none;

  &:focus {
    border: ${({ error }) => !error && '1px solid #2699FB'};
  }
`;

export const StyledInputDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
`;
