import React, { useState } from 'react'

import { CustomDivider, CustomText } from '../'
import { StyledSegmentContainer, StyledSegment } from './styles'

const Segment = ({ elements, title, onChange }) => {
  const [useSegment, setSegment] = useState(0)

  return (
    <>
      {title && (
        <>
          <CustomText>{title}</CustomText>
          <CustomDivider space={0} height={5} />
        </>
      )}

      <StyledSegmentContainer>
        {elements.map((e, i) => (
          <StyledSegment
            key={i}
            active={i === useSegment}
            onClick={() => {
              setSegment(i)
              onChange(i)
            }}
          >
            {e}
          </StyledSegment>
        ))}
      </StyledSegmentContainer>
    </>
  )
}

export default Segment
