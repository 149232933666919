import { comunicadosConstants } from '../constants/comunicados.constants';
import { filaComunicadosService } from '../services/filaComunicados.service';
import { modalAlertActions } from './modalAlert.action';

export const actionComunication = {
  comunication,
  insertComunication,
};

function comunication(user) {
  return (dispatch) => {
    dispatch(request());
    filaComunicadosService
      .getQueueComunication(user)
      .then((body) => {
        dispatch(success(body));
        dispatch(modalAlertActions.success(body.message));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(modalAlertActions.error(error.message));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: comunicadosConstants.COMUNICATION_REQUEST };
  }

  function success(comunications) {
    return { type: comunicadosConstants.COMUNICATION_SUCCESS, comunications };
  }

  function failure(error) {
    return { type: comunicadosConstants.COMUNICATION_ERROR, error };
  }
}
function insertComunication(user) {
  return (dispatch) => {
    dispatch(request());
    filaComunicadosService
      .insertComunication(user)
      .then((body) => {
        dispatch(success(body));
        dispatch(modalAlertActions.success(body.message));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(modalAlertActions.error(error.message));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: comunicadosConstants.COMUNICATION_REQUEST };
  }

  function success(comunications) {
    return { type: comunicadosConstants.COMUNICATION_SUCCESS, comunications };
  }

  function failure(error) {
    return { type: comunicadosConstants.COMUNICATION_ERROR, error };
  }
}
