import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import GetApp from '@material-ui/icons/GetApp';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  atividadesActions,
  alertActions,
  alertContingenciaActions,
} from '../actions';
import { history } from '../helpers';
import TextField from '@material-ui/core/TextField';
import ModalConvenio from '../components/ModalConvenio';
import moment from 'moment';
import Alert from '../components/Alert';
import AlertLink from '../components/AlertLink';
import Loader from 'react-loader-spinner';
import InputMask from 'react-input-mask';
import { validations } from '../helpers/validations';
import { mappedObjLogActivities } from '../helpers/creatingActivityLogMappedObject';

import * as paparse from 'papaparse';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class Activities extends React.Component {
  constructor(props) {
    //props.dispatch(acionamentoActions.getAllAcionamentos());
    super(props);
    this.state = {
      acionamentosData: [],
      inputValueReason: '',
      showModalDownloadCsv: false,
      diasDownload: 0,
      showErrorDownload: false,
      open: false,
      message: '',
      loadingsub: false,
      showLoader: false,
      dataInicioCsv: '',
      dataFimCsv: '',
      dataInicioCsvValida: false,
      dataFimCsvValida: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseAlertLink = this.handleCloseAlertLink.bind(this);
    this.changeValueCsvInicio = this.changeValueCsvInicio.bind(this);
    this.changeValueCsvFim = this.changeValueCsvFim.bind(this);
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  handleCloseAlertLink() {
    const { dispatch } = this.props;
    dispatch(alertContingenciaActions.clear());
  }

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  submitCsvModal = async () => {
    let { dataInicioCsv, dataFimCsv } = this.state;
    let periodoInvalido = validations.validationActivitiesCsvRangeDate(
      dataInicioCsv,
      dataFimCsv
    );
    if (periodoInvalido || !dataInicioCsv || !dataFimCsv)
      return this.setState({ periodoCsvValido: true });
    this.setState({ showLoader: true });
    this.setState({ showErrorDownload: false });
    this.setState({ periodoCsvValido: false });
    let firstPageRecord = await this.handleResolvePagination(
      dataInicioCsv,
      dataFimCsv,
      1,
      1
    );
    if (firstPageRecord && firstPageRecord.error_description)
      return this.props.dispatch(atividadesActions.errorCsv());

    let { page, pages, docs = [] } = firstPageRecord;
    let items = mappedObjLogActivities(docs);

    let arrayPromises = [];

    do {
      page++;
      arrayPromises.push(
        this.handleResolvePagination(dataInicioCsv, dataFimCsv, page, pages)
      );
    } while (page <= pages);
    await Promise.all(arrayPromises).then((values) => {
      let qtdErrors = 0;
      for (let val of values) {
        if (val && val.error_description) qtdErrors++;
      }
      if (qtdErrors > 0) {
        items = [];
        return this.props.dispatch(atividadesActions.errorCsv());
      }
      for (let val of values) {
        if (val.docs) {
          items = items.concat(mappedObjLogActivities(val.docs));
        }
      }
    });
    this.setState({ showLoader: false, dataInicioCsv: '', dataFimCsv: '' });
    if (items.length > 0) this.convertCsv(items);
  };

  convertCsv(array) {
    let perseCSV = paparse;
    let response = perseCSV.unparse({
      fields: ['Nome', 'Email', 'Perfil', 'Login', 'Hora login', 'Hora logout'],
      data: array,
    });

    response = response.replace(/undefined/g, '');
    response = response.replace(/null/g, '');
    var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'historicocsv.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleResolvePagination = (dataInicioCsv, dataFimCsv, page) => {
    return new Promise((resolve, reject) => {
      atividadesActions
        .JSONDownloadActivities(dataInicioCsv, dataFimCsv, page)
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve({ error_description: err });
        });
    });
  };

  handleOpenChatBotCovida = () => {
    let { showModalDownloadCsv } = this.state;
    if (showModalDownloadCsv) {
      return this.setState({ showModalDownloadCsv: false });
    }
    return this.setState({ showModalDownloadCsv: true });
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(atividadesActions.getListActivities());
    //this.intervalID = setInterval(() => dispatch(acionamentoActions.getAllAcionamentos()), 4000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    //this.props.dispatch(acionamentoActions.editAcionamentosWithoutRedirect());
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        id='formatted-text-mask-input'
        placeholder='Data de criação'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  changeValueCsvFim(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataFimCsvValida: validations.validationCsvDate(value),
    });
  }

  changeValueCsvInicio(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataInicioCsvValida: validations.validationCsvDate(value),
    });
  }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { acionamentos, open, type, message } = this.props;
    const { acionamentosData, showModalDownloadCsv, showLoader } = this.state;
    // Lógica para manter acionamentos, eu pego os acionamento que estão com props e passo para State quando
    // realizo alguma acão. E aqui eu verifico se existe algum acionamento nos props, se não existir eu pego
    // os acionamentos que estão no state. É preciso fazer isso por que as props são zeradas a cada ação disparada.
    const data = acionamentos
      ? acionamentos.length >= 1
        ? acionamentos
        : []
      : acionamentosData;
    const columns = [
      { title: 'Operação Executada', field: 'actionExecuted' },
      { title: 'Horário de Login', field: 'timeLogin' },
      { title: 'Horário de Logout', field: 'timeLogout' },
      { title: 'Horário de Registro', field: 'timeActivitie' },
      { title: 'Usuário', field: 'userLogged.nome' },
      { title: 'Email', field: 'userLogged.email' },
    ];
    return (
      <div>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <ModalConvenio
          open={showModalDownloadCsv}
          close={() => this.handleOpenChatBotCovida()}
          component={
            <React.Fragment>
              {showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Loader type='Oval' color='#ccc' height={60} width={60} />
                </div>
              )}
              {!showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    label='De'
                    margin='normal'
                    name='dataInicioCsv'
                    value={this.state.dataInicioCsv}
                    onChange={this.changeValueCsvInicio}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                  <TextField
                    label='Até'
                    margin='normal'
                    name='dataFimCsv'
                    value={this.state.dataFimCsv}
                    onChange={this.changeValueCsvFim}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                </div>
              )}
              {this.state.periodoCsvValido && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  O periodo entre as datas não pode ultrapassar 7 dias.
                </div>
              )}
              {this.state.errorCsv && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  Erro ao baixar csv, tente novamente mais tarde
                </div>
              )}
            </React.Fragment>
          }
          message='Selecione o periodo de extração'
          onSubmit={async () => {
            await this.submitCsvModal();
          }} //}
        />
        <React.Fragment>
          <MaterialTable
            icons={tableIcons}
            title=''
            columns={columns}
            data={async (query) => {
              let result;
              result = await atividadesActions.getListActivitiesComponent(
                query.page + 1,
                query.pageSize
              );
              if (query.search.length > 4) {
                result = await atividadesActions.getListActivitiesComponent(
                  query.page + 1,
                  query.pageSize,
                  query.search
                );
              }
              return new Promise((resolve, reject) => {
                if (result && result.docs) {
                  result.docs = result.docs.map((obj) => {
                    obj.timeLogin = moment(obj.timeLogin, 'YYYY-MM-DDThh:mm:ss')
                      .format('DD/MM/YYYY HH:mm:ss')
                      .toString();
                    if (obj.timeLogout) {
                      obj.timeLogout = moment(
                        obj.timeLogout,
                        'YYYY-MM-DDThh:mm:ss'
                      )
                        .format('DD/MM/YYYY HH:mm:ss')
                        .toString();
                    }

                    if (obj.timeActivitie) {
                      obj.timeActivitie = moment(
                        obj.timeActivitie,
                        'YYYY-MM-DDThh:mm:ss'
                      )
                        .format('DD/MM/YYYY HH:mm:ss')
                        .toString();
                      obj.timeLogin = '';
                    }

                    return obj;
                  });
                }
                // prepare your data and then call resolve like this:
                resolve({
                  data: result.docs, // your data array
                  page: result.page - 1, // current page number
                  totalCount: result.total, // total row number
                });
              });
            }}
            localization={{
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
              },
              pagination: {
                labelRowsSelect: 'Linhas',
                labelDisplayedRows: '{from} - {to} de {count}',
                firstTooltip: 'Voltar para a primeira página',
                previousTooltip: 'Voltar',
                nextTooltip: 'Proxima',
                lastTooltip: 'Ir para ultima página',
              },
            }}
            actions={[
              {
                icon: GetApp,
                isFreeAction: true,
                tooltip: 'Download Activities',
                // onClick: () => history.push('/adm/acionamento')
                onClick: (event, rowData) => {
                  this.handleOpenChatBotCovida();
                },
              },
            ]}
          />
        </React.Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { atividades } = state.atividades;
  const { message, type, open, loading } = state.alert;
  return {
    atividades,
    message,
    type,
    open,
    loading,
  };
}

Activities.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(Activities);
export { connectedLoginPage as Activities };
