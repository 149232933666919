import React, { useState, useRef, useEffect } from 'react';
import {
  ContainerDropdown,
  Dropdown,
  Option,
  Icon,
  ItemDropdown,
  Item,
} from './styles';
import CaretDown from './Icon/caret-down-fill.svg';
export default function DropwdownMenu({ menuItensDropdown, defaultValue }) {
  const [dropdown, setDropdownOpen] = useState(false);

  const searchIndex = (searcheableArray, valueToSearch) => {
    let ret = null;
    searcheableArray.forEach((element, index) => {
      if (element.description.includes(valueToSearch)) {
        ret = index;
      }
    });
    return ret;
  };
  const [optionValue, setOptionValue] = useState(
    menuItensDropdown[searchIndex(menuItensDropdown, defaultValue)].description
  );
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && ref.current && !ref.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dropdown]);

  return (
    <ContainerDropdown ref={ref}>
      <Dropdown
        onClick={() => {
          setDropdownOpen(!dropdown);
        }}
      >
        <Option>
          <span>{optionValue}</span>
          <Icon>
            <img src={CaretDown} />
          </Icon>
        </Option>
      </Dropdown>
      {dropdown && (
        <ItemDropdown>
          {menuItensDropdown.map((element, index) => {
            return (
              <Item
                key={index}
                onClick={() => {
                  setDropdownOpen(false);
                  element.option();
                  setOptionValue(element.description);
                }}
              >
                {element.description}
              </Item>
            );
          })}
        </ItemDropdown>
      )}
    </ContainerDropdown>
  );
}
