import React, { useState, useEffect } from 'react';
import CustomButtom from '../../../CustomButtom';
import { Container, ContainerButtom, ContainerTag, Failback } from './styles';
import { agendamentoService } from '../../../../services/agendamento.service';
import {
  countAndSingle,
  debounce,
  objectIsEmpty,
} from '../../../../utils/utils';
import { CircularProgress } from '@material-ui/core';

const stringSplit = (str) => str.split(' ')[1];
const Slots = ({
  searchDate,
  handleSetState,
  specialty,
  state,
  scheduleType,
}) => {
  const [load, setLoad] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [clicked, setClicked] = useState(null);
  const [count, setCount] = useState(0);
  const [focusColor, setFocusColor] = useState('#7ADC30');
  const [agrupedData, setAgrupedData] = useState([]);

  const [eventTimeout, setEventTimeout] = useState(false);

  const checkSocketObject = () => {
    if (objectIsEmpty(agrupedData)) setEventTimeout(true);
    else setEventTimeout(false);
  };

  debounce(checkSocketObject, 3000);

  const handleClick = (id, element) => {
    setCount(count + 1);
    if (count === 0) {
      setIsDisabled(true);
      setClicked(id);
      element.clicked = true;
      setFocusColor('#7ADC30');

      handleSetState(element, 'slots');
      //  console.log(element);
      // handleSetState(
      //   stringSplit(element.startAt).slice(0, 5),
      //   'schedulingTime'
      // );
    }

    if (count === 1) {
      setIsDisabled(false);
      setClicked(null);
      setCount(0);
      setFocusColor('');
    }
  };

  useEffect(() => {
    async function fetchData() {
      let sucess = await agendamentoService.getSlots(
        searchDate,
        '',
        specialty,
        state,
        scheduleType
      );

      if ((sucess !== undefined && sucess.error) || sucess.stack) {
        setAgrupedData([]);
        setEventTimeout(true);
        setLoad(false);
      } else {
        const newData = countAndSingle(sucess.data, 'startAt');

        setLoad(false);
        setEventTimeout(false);
        setAgrupedData(newData);
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      {load && <CircularProgress color='primary' />}
      {agrupedData.length > 0 ? (
        agrupedData.map((element, index) => {
          return (
            <ContainerButtom>
              <CustomButtom
                disable={isDisabled}
                id={index}
                clicked={clicked}
                onClick={() => handleClick(index, element)}
                key={index}
                focusColor={focusColor}
                typeButtom='lightRounded'
                size='medium'
              >
                <ContainerTag>
                  <span>{`${stringSplit(element.startAt).slice(0, 5)} `}</span>
                </ContainerTag>
              </CustomButtom>
            </ContainerButtom>
          );
        })
      ) : (
        <Failback eventTimeout={eventTimeout}> </Failback>
      )}
    </Container>
  );
};

export default Slots;
