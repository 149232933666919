
import styled from 'styled-components'

const titleStyle = {
  'font-size': '16px',
  'font-weight': 'bold',
}

const sizes = {
  small: '12px',
  medium: '14px',
  large: '16px',
  xlarge: '28px'
}

const colors = {
  dark: '#2e384d',
  light: '#fff',
  default: '#76818f',
  info: '#2699fb',
  success: '#33ac2e',
  danger: '#ff2424'
}

const getSize = (size) => {
  return sizes[size] || sizes.medium
}

const getColor = (color) => {
  return colors[color] || colors.default
}

export const CustomStyledText = styled.span`
  ${({ $title }) => $title && titleStyle};
  color: ${({ $color }) => getColor($color)};
  font-size: ${({ size }) => getSize(size)};
`
