import styled from 'styled-components'

export const StyledWrapper = styled.div`
  margin-top: 5%;
  display: flex;
  gap: 20px;
  justify-content: center;
  
  @media (max-width: 568px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

export const StyledDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
`

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: ${({ align = 'center' }) => align};
  width: 100%;

  ${({ align }) =>
    align === 'space-between' &&`
    @media (max-width: 860px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`

export const StyledForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
`
