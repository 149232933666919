import React from 'react';
import {
  HttpClientAdapter,
  HttpRequest,
  HttpResponse,
} from '../../../infra/adapters';

export type HttpBodyResendCockpit = {
  _id?: string;
  email?: string;
  name?: string;
  document?: string;
  lastName?: string;
  cpf?: string;
  passport?: string;
  centralId?: string;
  birthDate?: string;
  nacionalidade?: string;
  sex?: string;
  phone?: string;
  phone2?: string;
  phone3?: string;
  rg?: string;
  id_requisicao_parceiro?: string;
  telefone?: string;
  pais?: string;
  regiao?: string;
  city?: string;
  codPais?: string;
  createDate?: string;
  triggerId?: string;
};

type Response = any;

export class HttpQueueRecordResendCockpit {
  constructor(private readonly httpClient: HttpClientAdapter) {}

  async handle(httpRequest: HttpRequest): Promise<HttpResponse<Response>> {
    return await this.httpClient.request(httpRequest);
  }
}
