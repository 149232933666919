import request from '../request';

const execute = async () => {
  try {
    const response = await request.get(`/next-pause`);

    if (response.err) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
