export const cutString = (string = '') => {
  if (string !== '') {
    const words = string.split(' ');

    if (words.length > 1) {
      const [a, b] = words;

      return `${a.charAt(0).toUpperCase() + b.charAt(0).toUpperCase()}`;
    }

    return string.charAt(0).toUpperCase();
  }

  return string;
};
