import { acionamentoConstants } from '../constants';

export function acionamento(state = { acionamentos: {} }, action) {
  switch (action.type) {
    case acionamentoConstants.ACIONAMENTO_SUCCESS:
      return {
        type: 'success',
        acionamentos: action.acionamentos,
      };
    case acionamentoConstants.ACIONAMENTO_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        acionamentos: {},
      });
    case acionamentoConstants.ACIONAMENTO_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
