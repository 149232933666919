//Modules
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as RelogioIcon } from '../../../icons/agendamentos.svg';

//Imports Style
import {
  DivTableAction,
  ContainerSelectModal,
  TextAreaModal,
  TableColStyled,
  TextAreaModal,
  DivIconDropdown,
  MenuDropdown,
  SelectTitle,
  ModalDialogContent,
} from './style';

//Imports Utils
import ModalDialog from '../ModalDialog';
import TableCol from '../../Lib/TableCol';
import { acionamentoService } from '../../../services/acionamento.service';
import GenericSelect from '../GenericSelect';
import { reasonRemoveData } from './data';
import {
  acionamentoActions,
  alertActions,
  acionamentoActionsVonage,
} from '../../../actions';

//Imports MUI
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';

//Imports Icons
import { BiDotsVerticalRounded, BiTrash, BiEdit } from 'react-icons/bi';
import { AiOutlineSend, AiOutlineWarning } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import { differenceTime } from './utils';

const TableActions = ({
  acionamento,
  disabledForSupport,
  setOpenScheduleCancel,
  setItemSchedule,
}) => {
  const dispatch = useDispatch();
  const [openReenvio, setOpenReenvio] = useState(false);
  const [reenvioLink, setReenvioLink] = useState('');
  const [sendMailValues, setSendMailValues] = useState({});
  const [itemDelete, setItemDelete] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [reasonRemove, setReasonRemove] = useState(0);
  const [openLate, setOpenLate] = useState(false);
  const [attendanceLoading, setAttendanceLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ValueCheck = (value) => {
    if (value == undefined) return false;
    return value != '';
  };

  async function ResendEmail() {
    try {
      let { name, lastName, socialName, email, email2, url } = sendMailValues;

      await acionamentoService
        .sendEmail(name, lastName, socialName, email, email2, url)
        .then(dispatch(alertActions.success('Link reenviado por e-mail!.')));
    } catch (erro) {
      dispatch(alertActions.error(erro.message));
    }
  }

  const handleOpenReenvio = () => {
    setReenvioLink(acionamento.urlAttendanceVonage);
    setSendMailValues({
      name: acionamento.name,
      lastName: acionamento.lastName,
      socialName: acionamento.socialName,
      email: acionamento.email,
      emai2: acionamento.email2,
      url: acionamento.urlAttendanceVonage,
    });
    setOpenReenvio(true);
  };

  const handleReason = (e) => {
    setReasonRemove(e.target.value);
  };

  const handleConfirmDelete = () => {
    const { id, data } = itemDelete;
    if (reasonRemove === '' || reasonRemove === 0) {
      setDeleteError(true);
    } else {
      setDeleteError(false);
      deleteQueueItem(id, reasonRemove, data);
      setOpenDelete(false);
    }
  };

  async function deleteQueueItem(id, reason, pacientData) {
    try {
      dispatch(
        acionamentoActions.deleteAcionamentosNew(id, reason, pacientData)
      );
      dispatch(alertActions.success('Acionamento excluído com sucesso!'));
    } catch (erro) {
      console.log(erro);
    }
  }

  const handleCloseReenvio = () => {
    setOpenReenvio(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const userAdmin = JSON.parse(localStorage.getItem('user'));

  const textAreaCopy = useRef(null);
  const handleCopyReenvio = (e) => {
    textAreaCopy.current.select();
    document.execCommand('copy');
  };

  const updatedTime = () => {
    let replacedDate = new Date().toISOString();
    return replacedDate;
  };

  const handleAttendance = (data, setOpenLate, setLoading) => {
    setAttendanceLoading(true);
    let editionTime = updatedTime();
    dispatch(
      acionamentoActionsVonage.attendanceAcionamentos(
        { ...data, editionTime },
        '',
        setOpenLate,
        setLoading
      )
    );
    acionamentoActionsVonage.updateAcionamentos(data._id, {
      ...data,
      notifyHealthProfessionalLate: true,
    });
  };

  const FIVE_MINUTES_IN_MS = 300_000;

  const diffTime = differenceTime(
    acionamento?.currentEditionAttendanceInfo?.dateStarted,
    FIVE_MINUTES_IN_MS
  );

  const vonageAttendanceTypes = [
    'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST',
    'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
    'PENDING_CONTACT',
  ];

  const doctorAttendanceTypes = ['IN_WAIT_ATTENDANCE_DOCTOR'];

  const hasAttendanceType = (attendanceType, attendanceTypes) =>
    attendanceTypes.includes(attendanceType);

  const attendanceType =
    acionamento.currentEditionAttendanceInfo.attendanceType;

  //Ativar modal e botão de aviso apenas pelos id de fila estipulados abaixo (PEDIDO TEMPORARIO PRA DEPLOY)
  const identify_queues = [
    '3b08e0db-f952-4702-a91d-3c2f6348c5db', // Urgencia e emergencia
    '72f410c4-9dd5-40d9-8e72-b11d3560a7b1', // Neurologista
  ];

  const identify_queue = acionamento.identify_queue;

  const hasIdFila = (identify_queue) =>
    identify_queues.includes(identify_queue);
  //Ativar modal e botão de aviso apenas pelos id de fila estipulados acima (PEDIDO TEMPORARIO PRA DEPLOY)

  return (
    <>
      <TableColStyled>
        <DivTableAction onClick={handleClick}>
          {!hasAttendanceType(attendanceType, vonageAttendanceTypes) ? (
            <>
              {disabledForSupport && <BiDotsVerticalRounded />}
              <MenuDropdown
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onClose={handleClose}
                onBlur={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {diffTime &&
                  hasAttendanceType(attendanceType, doctorAttendanceTypes) &&
                  hasIdFila(identify_queue) && (
                    <>
                      <Tooltip
                        title='Avisar sobre atraso'
                        placement='top-start'
                      >
                        <MenuItem
                          className='item-menu'
                          onClick={() => setOpenLate(true)}
                        >
                          <DivIconDropdown>
                            <AiOutlineWarning />
                          </DivIconDropdown>
                        </MenuItem>
                      </Tooltip>
                    </>
                  )}
                {acionamento.urlAttendanceVonage && (
                  <>
                    <Tooltip
                      title='Reenviar link por e-mail'
                      placement='top-start'
                    >
                      <MenuItem
                        className='item-menu'
                        onClick={handleOpenReenvio}
                      >
                        <DivIconDropdown>
                          <AiOutlineSend />
                        </DivIconDropdown>
                      </MenuItem>
                    </Tooltip>
                  </>
                )}
                {attendanceType != 'NO_SHOW' &&
                  acionamento?.idSchedule &&
                  acionamento?.currentEditionAttendanceInfo?.attendanceType !=
                    'IN_WAIT_ATTENDANCE_DOCTOR' && (
                    <>
                      <Tooltip title='Cancelar Agendamento'>
                        <MenuItem
                          className='item-menu'
                          onClick={() => {
                            setItemSchedule(acionamento);
                            setOpenScheduleCancel(true);
                          }}
                        >
                          <DivIconDropdown>
                            <BiTrash />
                          </DivIconDropdown>
                        </MenuItem>
                      </Tooltip>
                    </>
                  )}

                {attendanceType != 'NO_SHOW' &&
                  acionamento?.currentEditionAttendanceInfo?.attendanceType ==
                    'IN_WAIT_ATTENDANCE_DOCTOR' && (
                    <>
                      <Tooltip title='Excluir acionamento'>
                        <MenuItem
                          className='item-menu'
                          onClick={() => {
                            setItemDelete({
                              id: acionamento._id,
                              data: acionamento,
                            });
                            setOpenDelete(true);
                          }}
                        >
                          <DivIconDropdown>
                            <BiTrash />
                          </DivIconDropdown>
                        </MenuItem>
                      </Tooltip>
                    </>
                  )}
              </MenuDropdown>
            </>
          ) : (
            <></>
          )}
        </DivTableAction>
      </TableColStyled>

      <ModalDialog
        open={openLate}
        handleClose={() => setOpenLate(false)}
        handleConfirm={() =>
          handleAttendance(acionamento, setOpenLate, setAttendanceLoading)
        }
        handleCopy={(e) => handleCopyReenvio(e)}
        title={'Aviso de atraso!'}
        withActions
        withClose
        labelOk={
          attendanceLoading ? 'Carregando...' : 'Avisar e acessar chamada'
        }
        typeButtomConfirm={'primaryHighlight'}
      >
        <ModalDialogContent>
          {acionamento.notifyHealthProfessionalLate
            ? 'Paciente já foi informado, deseja realizar um novo aviso?'
            : 'Deseja realizar o aviso de atraso do profissional?'}
        </ModalDialogContent>
      </ModalDialog>

      <ModalDialog
        open={openReenvio}
        handleClose={handleCloseReenvio}
        handleConfirm={ResendEmail}
        handleCopy={(e) => handleCopyReenvio(e)}
        title={'Reenvio de link de acesso'}
        withActions
        withClose
        labelCancel={'Cancelar'}
        labelCopy={'Copiar'}
        labelOk={'Enviar e-mail'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCopy={'primary'}
        typeButtomCancel={'primary'}
      >
        <ContainerSelectModal>
          <div>
            <TextAreaModal
              ref={textAreaCopy}
              value={reenvioLink}
            ></TextAreaModal>
          </div>
        </ContainerSelectModal>
      </ModalDialog>

      <ModalDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
        title={'Deseja remover o acionamento ?'}
        withActions
        withClose
        labelCancel={'Cancelar'}
        labelOk={'Remover'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCancel={'danger'}
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              height={'medium'}
              value={reasonRemove}
              placeholder='Selecione'
              onChange={handleReason}
              data={reasonRemoveData}
            />
          </div>
          {deleteError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>
    </>
  );
};

export default TableActions;
