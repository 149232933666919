import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueueRecordEntity } from '../../../../domain/entities';
import { ReportQueueRecordsState } from '../../../../domain/state';

const initialState: ReportQueueRecordsState = {
  error: false,
  errorMessage: '',
  loading: false,
  report: [],
  modal: false,
  dateFrom: new Date(),
  dateUntil: new Date(),
};

export const reportQueueRecordsSlice = createSlice({
  name: 'reportQueueRecord',
  initialState,
  reducers: {
    setQueueRecordReport: (
      state,
      action: PayloadAction<QueueRecordEntity[]>
    ) => {
      state.report = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setModal: (state, action: PayloadAction<boolean>) => {
      state.modal = action.payload;
    },
    setDateFrom: (state, action: PayloadAction<Date | string>) => {
      state.dateFrom = action.payload;
    },
    setDateUntil: (state, action: PayloadAction<Date | string>) => {
      state.dateUntil = action.payload;
    },
  },
});

export const {
  setQueueRecordReport,
  setLoading,
  setError,
  setErrorMessage,
  setModal,
  setDateFrom,
  setDateUntil,
} = reportQueueRecordsSlice.actions;

export default reportQueueRecordsSlice.reducer;
