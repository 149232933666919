import React from 'react';

import {
  CustomDivider,
  CustomButton,
  CustomModal,
  CustomText,
  CustomDivider,
  CustomLoader,
} from '../../../../components/ui';

import { CustomInput } from '../../../../components/ui/webform';

import {
  StyledButtonGroup,
  StyledModalContent,
  StyledLoaderWrapper,
} from './styles';

import { getCurrentDate } from './utils';

import useModalWorkShift from './hooks/useModalWorkShift';

const ModalWorkShift = ({ show, onClose, onError, onSuccess, workShift }) => {
  const { onConfirm, onMotiveChange, useMotive, useScale, useLoading } =
    useModalWorkShift(show, onError, onSuccess, workShift);

  return (
    <CustomModal show={show} width='550'>
      <StyledModalContent>
        {useLoading ? (
          <StyledLoaderWrapper>
            <CustomText title={1} color='dark' size='large'>
              Carregando dados
            </CustomText>
            <CustomLoader />
          </StyledLoaderWrapper>
        ) : (
          <>
            <CustomText title={1} color='dark' size='large'>
              {useScale && (
                <>
                  Sua escala de hoje estava prevista para iniciar as
                  {` ${useScale}.`} <br />
                </>
              )}
              Por favor, informe abaixo o motivo de sua entrada as
              {` ${getCurrentDate()}`}.
            </CustomText>
          </>
        )}
        <CustomDivider />
        <CustomInput
          style='outlined'
          title=''
          full
          value={useMotive}
          onChange={onMotiveChange}
        />
        <CustomDivider />
        <StyledButtonGroup>
          <CustomButton
            size='large'
            text='Cancelar'
            color='danger'
            type='outlined'
            onClick={onClose}
          />
          <CustomButton
            size='large'
            text='Confirmar'
            color='primary'
            onClick={onConfirm}
          />
        </StyledButtonGroup>
        <CustomDivider space={0.5} />
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalWorkShift;
