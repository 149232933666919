import { getDaysInMonth, formateDatesPerMonth, objDate } from '../utils/utils';
import { agendamentoTPsicolService } from '../services/agendamentoTPsicol.service';

export const avaliableMonth = async (
  numberMonths,
  reverse,
  doctorId,
  debug
) => {
  let arrayDaysOfMonths = [];

  for (let i = 0; i < numberMonths; i++) {
    arrayDaysOfMonths.push(getDaysInMonth(objDate().month + i, objDate().year));
  }

  arrayDaysOfMonths = arrayDaysOfMonths.flatMap((element) => element);
  arrayDaysOfMonths = formateDatesPerMonth(arrayDaysOfMonths);

  try {
    var newData = [];
    let sucess = await agendamentoTPsicolService.getDatesByDoctor(doctorId);
    if (
      (sucess !== undefined && !sucess.error) ||
      !sucess.stack ||
      !sucess.message.includes('Não existem dias disponibilizados')
    ) {
      newData = sucess.data.rangeDates.map((element) => {
        return element.date;
      });
    }

    let disabledDates = arrayDaysOfMonths.filter((element) => {
      if (reverse === true) return newData.includes(element);
      else return !newData.includes(element);
    });

    if (debug) {
    }

    return await disabledDates;
  } catch (error) {
    console.log('ERROR', error);
  }
};
