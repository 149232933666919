export const maskedDocumentTypes = ['rg', 'cpf', 'RG', 'CPF'];

export const productsTriggerQueue = [
  'clinica',
  'checkup',
  'predicta',
  'reab',
  'HMVSC',
];

export const productQueue = {
  clinica: 'Clínica Eistein e Cuidar',
  checkup: 'Checkup',
  reab: 'Reabilitação',
  predicta: 'Predicta',
};

export const snackBarMessageTypes = {
  update: {
    success: 'Observação atualizada com sucesso.',
    danger: 'Ocorreu um erro ao atualizar a observação.',
  },
  insert: {
    success: 'Nova observação cadastrada com sucesso.',
    danger: 'Ocorreu um erro ao cadastrar nova observação.',
  },
  delete: {
    success: 'Observação removida com sucesso.',
    danger: 'Ocorreu um erro ao remover a observação.',
  },
};
