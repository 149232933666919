import React from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

export default function InputMaskCustom({
  label,
  placeholder,
  value,
  disabled,
  mask,
  onChange,
  type,
  maxLength,
  validate,
  onBlur,
  helperText,
  InputProps,
  ...props
}) {
  return (
    <InputMask
      {...props}
      helperText={helperText}
      mask={mask}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          fullWidth
          value={value}
          helperText={helperText}
          type={type}
          label={label}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          InputProps={InputProps}
        />
      )}
    </InputMask>
  );
}
