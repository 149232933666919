import {
  agendamentoConstants,
  editAgendamentoConstants,
  userConstants,
  countryConstants,
  regionConstants,
  citiesConstants,
  cepConstants,
} from '../constants';
import { conveniosConstants } from '../constants';
import { agendamentoService } from '../services';
import { acionamentoService } from '../services';
import { conveniosService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';
//import { resolveConfig } from 'prettier';

export const agendamentoActions = {
  getAllAgendamentos,
  deleteAgendamentos,
  editAgendamentos,
  editAgendamentosWithoutRedirect,
  getAllConvenios,
  confirmedAgendamentos,
  getAllCountryAcionamentos,
  getRegionsByCountry,
  getCityByRegions,
  getAddressFromCep,
  paginateAgendamentos,
  generateImageUrl,
};

function generateImageUrl(bucketObject = {}) {
  return new Promise((resolve, reject) => {
    acionamentoService
      .getUrlImages(bucketObject)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve({ signedURL: '' });
      });
  });
}

function getAllCountryAcionamentos() {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getCountryAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: countryConstants.COUNTRY_REQUEST };
  }
  function success(result) {
    return { type: countryConstants.COUNTRY_SUCCESS, result };
  }
  function failure(error) {
    return { type: countryConstants.COUNTRY_FAILURE, error };
  }
}

function getAddressFromCep(cep) {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .GetAddressFromCep(cep)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cepConstants.CEP_REQUEST };
  }
  function success(result) {
    return { type: cepConstants.CEP_SUCCESS, result };
  }
  function failure(error) {
    return { type: cepConstants.CEP_FAILURE, error };
  }
}

function getRegionsByCountry(region = 'nd') {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getRegionsByCountry(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: regionConstants.REGION_REQUEST };
  }
  function success(result) {
    return { type: regionConstants.REGION_SUCCESS, result };
  }
  function failure(error) {
    return { type: regionConstants.REGION_FAILURE, error };
  }
}

function getCityByRegions(region = 'nda') {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getCityByRegions(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: citiesConstants.CITIE_REQUEST };
  }
  function success(result) {
    return { type: citiesConstants.CITIE_SUCCESS, result };
  }
  function failure(error) {
    return { type: citiesConstants.CITIE_FAILURE, error };
  }
}

function editAgendamentosWithoutRedirect(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
  };
  function request() {
    return { type: editAgendamentoConstants.AGENDAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAgendamentoConstants.AGENDAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAgendamentoConstants.AGENDAMENTO_EDIT_FAILURE };
  }
}

function paginateAgendamentos(pageSize = 5, page = 1, queryString = '') {
  return new Promise((resolve) => {
    acionamentoService
      .paginateAllAgendamentos(pageSize, page, queryString)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        return resolve({
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        });
      });
  });
}

function getAllAgendamentos() {
  return (dispatch) => {
    dispatch(request());

    agendamentoService
      .GetAllAgendamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: agendamentoConstants.AGENDAMENTO_REQUEST };
  }
  function success(agendamentos) {
    return { type: agendamentoConstants.AGENDAMENTO_SUCCESS, agendamentos };
  }
  function failure(error) {
    return { type: agendamentoConstants.AGENDAMENTO_FAILURE, error };
  }
}

function confirmedAgendamentos(agendamento) {
  var tempData = agendamento.birthDate.split('/');
  agendamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());

    agendamentoService
      .confirmedAcionamentos(agendamento)
      .then(
        (body) => {
          history.push('/adm');
          dispatch(success(body));
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function deleteAgendamentos(id, reason) {
  return (dispatch) => {
    dispatch(request());

    acionamentoService
      .deleteAgendamentos(id, reason)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: agendamentoConstants.AGENDAMENTO_REQUEST };
  }
  function success() {
    return { type: agendamentoConstants.AGENDAMENTO_SUCCESS };
  }
  function failure(error) {
    return { type: agendamentoConstants.AGENDAMENTO_FAILURE, error };
  }
}

function editAgendamentos(agendamento) {
  return (dispatch) => {
    dispatch(request(agendamento));
    if (agendamento) {
      dispatch(success(agendamento));
    } else {
      dispatch(failure());
    }
    history.push('/adm/agendamento');
  };
  function request() {
    return { type: editAgendamentoConstants.AGENDAMENTO_EDIT_REQUEST };
  }
  function success(agendamento) {
    return {
      type: editAgendamentoConstants.AGENDAMENTO_EDIT_SUCCESS,
      agendamento,
    };
  }
  function failure() {
    return { type: editAgendamentoConstants.AGENDAMENTO_EDIT_FAILURE };
  }
}

function getAllConvenios() {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }
  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }
  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}
