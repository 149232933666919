import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* display: grid;
  grid-template-rows: 1fr; */

  width: 100%;
`;

export const Icon = styled.div`
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

export const ContainerDashed = styled.div`
  width: '100%';
  margin-top: 0px;
`;

export const ContainerField = styled.div`
  display: flex;
  justify-content: center;
  min-width: 105px;
  margin-left: 7px;
  margin-right: 7px;
`;
export const ContainerLoader = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CustomizedTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 15px;
  position: relative;
  white-space: nowrap;
  tbody tr th:first-child span {
    margin-left: -115px;
  }

  tr td span {
    padding-left: 5px;
    padding-right: 5px;
    color: #5a6a7e;
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const TableColSpacing = styled.td`
  padding: 0 0.25em;
  vertical-align: ${(props) => props.vAlign};
  border-left: ${(props) => props.borderTypeColor};
  ${(props) =>
    props.isId &&
    css`
      border-left: ${(props) => props.borderTypeColor};
    `};
`;

export const TrCustom = styled.tr`
  transition: all 0.4s ease;
  text-align: center;
  height: 75px;
  background-color: white;
  opacity: 0.2;

  ${(props) =>
    !props.isHover &&
    css`
      div {
        cursor: default;
      }
      button {
        cursor: default;
      }
    `};

  ${(props) =>
    props.isHover &&
    css`
      transition: all 0.8s ease;
      &&:hover {
        font-weight: 500;
        cursor: pointer;

        td span {
          color: #000;
        }
      }
    `};

  opacity: ${(props) => props.opacity};
`;

export const ThCustom = styled.th`
  height: ${(props) => props.heigth};
  ${(props) => props.heigth};
  padding: ${(props) => props.padding};
  transition: all 0.4s ease;
  width: ${(props) => props.customWidth};
`;

export const Chip = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  height: 32px;
  width: 58px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  padding: 0 12px;
  font-size: 12px;
`;

export const ChipCol = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81px;
`;
