import { useEffect, useState } from 'react';

import { scalesService } from '../../../../../../services/core';

const useModalLock = (data) => {
  const [inputValue, setInputValue] = useState('');
  const [inputEnable, setinputEnable] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(true);

  const handleRequestBlocked = async (value) => {
    if (value) {
      const { user } = JSON.parse(localStorage.getItem('user'));

      const res = await scalesService.unlockUserScale(value, user._id);
      if (res.status) {
        setButtonEnable(true);
        setinputEnable(true);
      }
    }
  };

  useEffect(() => {
    if (inputValue.length > 0) {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }

    if (data.requestUnlock) {
      setinputEnable(true);
    }
  }, [inputValue, data]);

  return {
    inputValue,
    setInputValue,
    inputEnable,
    setInputValue,
    buttonEnable,
    setButtonEnable,
    handleRequestBlocked,
  };
};

export default useModalLock;
