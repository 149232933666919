import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { format } from 'date-fns';
import actions from '../../../../redux/scale/actions';
import { exportToCsv } from '../../../../utils/file';
import { scalesService } from '../../../../services/core';

import {
  parseScalesToTimeLine,
  orderByStatus,
  parseUserToNewObject,
  isSameDate,
  orderByScale
} from '../utils';

import { socket } from '../../../../../utils/socket';

const pageLimit = 10;

const uselistPauseScale = () => {
  const tableRef = useRef(null);
  const offset = useRef(null)

  const dateParse = new Date();
  const arrayDate = [
    new Date(formatDate(dateParse)),
    new Date(
      formatDate(new Date(dateParse.getFullYear(), dateParse.getMonth() + 1, 0))
    ),
  ];

  const initialState = { dates: arrayDate };

  const [useCounter, setCounter] = useState(false);
  const [useScalesResource, setScalesResource] = useState(null);
  const [useTimeLine, setTimeLine] = useState(null);
  const [useUnlockModal, setUnlockModal] = useState(false);
  const [useModalData, setModalData] = useState('');
  const [useLoading, setLoading] = useState(false);
  const [useUsers, setUsers] = useState([]);
  const [professional, setProfessional] = useState('');
  const [reset, setReset] = useState(false);
  const [useTabOpen, setTabOpen] = useState(null);
  const [useScaleOrder, setScaleOrder] = useState(0);
  const [{ dates }, setState] = useState(initialState);
  const [date, setDate] = useState({});
  const [dateForm, setDateForm] = useState([]);
  const [sendForm, setSendForm] = useState([]);
  const [searchScaleInDate, setScaleInDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [isSearchingMoreData, setSearchingMoreData] = useState(false);
  const [updateSocket, setUpdateSocket] = useState(false);

  const intersectionObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  };

  const scrollEndObserverCallback = async (payload) => {
    const [intersection] = payload;
    const scrollEnd = intersection.isIntersecting;

    if (scrollEnd) {
      searchMoreData();
    }
  };

  function formatDate(date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss')
      .local()
      .format('YYYY-MM-DD HH:mm:ss');
  }

  const openUnlockModal = (userId, userName) => {
    setUnlockModal(true);
    setModalData({ userId, userName });
  };

  const mapResponseToValuesAndLabels = (data) => ({
    value: data.id,
    label: data.name,
  });

  const searchMoreData = async () => {
    const updatedOffset = offset.current + pageLimit;
    const exactlyDate = true;
    clearState();
    setSearchingMoreData(true);

    const { data } = await actions.serviceListGrid(
      searchScaleInDate,
      pageLimit,
      updatedOffset,
      exactlyDate
    );

    setSearchingMoreData(false);

    const loadedScales = data?.scales || [];

    if (loadedScales.length) {
      offset.current = offset.current + pageLimit;

      const totalScales = [...useUsers, ...loadedScales];

      setUsers(totalScales);
    }
  };

  const getUsers = async (value) => {
    if (value?.length < 4) return;
    const { data } = await actions.serviceUser(value);
    const users = data.users.map(mapResponseToValuesAndLabels);
    return users.filter((i) =>
      i.label.toLowerCase().includes(value.toLowerCase())
    );
  }

  const sendData = async (dataSend) =>
    await actions.serviceReportUser(dataSend, professional);

  const handleSubmit = async () => {
    setLoading(true);

    const dateFormatSend = dates.map((dateSend) => formatDate(dateSend));

    if (dates.length === 2) {
      const response = await sendData({ dates: dateFormatSend, professional });

      const fileName = `relatorio-escala-${new Date()}.csv`;

      if (response.data && !response?.data?.message) {
        exportToCsv(fileName, response.data);
      }
    }

    setLoading(false);
  };

  const clearState = () => {
    setState({ ...initialState });
    setDateForm([]);
    handleProfessional('');
    setReset(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onOrderChange = (order) => {
    order === 0 ? setListBasedOnScale() : setListBasedOnStatus();

    setScaleOrder(order);
  };

  const orderGridBySocket = (newUsers) => {
    return useScaleOrder === 0
      ? orderByScale(newUsers)
      : orderByStatus(newUsers);
  };

  const setListBasedOnScale = () => {
    if (useScalesResource?.length) {
      const listScale = orderByScale(useScalesResource);

      setUsers(listScale);
    }
  };

  const setListBasedOnStatus = () => {
    if (useScalesResource?.length) {
      const listStatus = orderByStatus(useScalesResource);

      setUsers(listStatus);
    }
  };

  const loadUsers = async () => {
    //colocar uma condicional aqui junto a separação dos status

    let users = null;
    const exactlyDate = true;

    setUsers([]);

    if (searchScaleInDate?.length > 0) {
      const { data } = await scalesService.getByDay({ day: searchScaleInDate, exactlyDate });

      if (data?.scales?.length) {
        users = data.scales;
      }
    }

    setUsers(users);
  };

  const loadTimeLine = async () => {
    let timeline = null;
    let users = [];
    let scales = [];
    const exactlyDate = true;

    setTimeLine([]);

    const { data } = await scalesService.getByDay({ day: searchScaleInDate, exactlyDate });

    if (data?.scales?.length) {
      const parsedTimeline = parseScalesToTimeLine(orderByScale(data.scales));
      const parsedUsers = orderByScale(data.scales);

      timeline = parsedTimeline;
      users = parsedUsers;
      scales = data.scales;
    }

    setScalesResource(scales);
    setTimeLine(timeline);
    setUsers(users);
  };

  useEffect(() => {
    if (useTabOpen !== 1 || !greaterThanOrEqualToToday(searchScaleInDate)) {
      // socket.removeAllListeners('changeDataScaleSocket');
    } else {
      socket.on('changeDataScaleSocket', (payload) => {
        usersSocketUpdade[payload.operationType]?.(payload);
      });
    }
  }, [useTabOpen]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      scrollEndObserverCallback,
      intersectionObserverOptions
    );

    observer.observe(tableRef.current);

    if (searchScaleInDate) {
      loadTimeLine();
    }

    return () => {
      observer.unobserve(tableRef.current);
    };
  }, [searchScaleInDate]);

  useEffect(() => {
    if (reset) {
      setReset(!reset);
    }
  }, [reset]);

  useEffect(() => {
    if (date.dateClicked !== undefined) {
      const dateClick = date.dateClicked?.format?.();
      let [dia, mes, ano] = dateClick.split(/[\/ :]/).map((v) => parseInt(v));
      const dataParse = new Date(Date.UTC(ano, mes - 1, dia))
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);
      dateForm.push(dataParse);
    }
  }, [date]);

  useEffect(() => {
    if (sendForm.length > 0) {
      const dateFilter = sendForm.filter(function (ele, pos) {
        return sendForm.indexOf(ele) == pos;
      });
      setSendForm([]);
      onChange({ target: { name: 'dates', value: dateFilter } });
    }
  }, [sendForm]);

  function handleProfessional(e) {
    setProfessional(e);
  }

  const handleTab = (e) => {
    setTabOpen(e);
  };

  const greaterThanOrEqualToToday = (date) => {
    const searchScale = moment(date).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const today = moment();

    return searchScale.isSame(today, 'day') || searchScale.isAfter(today);
  };

  const usersSocketUpdade = {
    delete: (payload) => {
      const scaleSocketId = payload.documentKey?._id;

      setUsers((usersState) => {
        const newUsersState = [...(usersState || [])].filter(
          (e) => e.scaleSocketId !== scaleSocketId
        );

        setScalesResource(newUsersState);

        return orderGridBySocket(newUsersState);
      });
    },
    insert: (payload) => {
      const { fullDocument, documentKey } = payload;

      setUsers((usersState) => {
        if (isSameDate(fullDocument.entryTime, searchScaleInDate)) {
          const parsedUser = parseUserToNewObject(
            { ...fullDocument, scaleSocketId: documentKey._id },
            {},
            searchScaleInDate
          );

          const newUsersState = [...(usersState || []), parsedUser];

          setScalesResource(newUsersState);

          return orderGridBySocket(newUsersState);
        } else {
          setScalesResource(usersState);

          return usersState;
        }
      });
    },
    update: (payload) => {
      const scaleSocketId = payload.documentKey?._id;
      setUpdateSocket(true);

      setUsers((usersState) => {
        if (usersState?.find((e) => e.scaleSocketId === scaleSocketId)) {
          const { updatedFields } = payload.updateDescription;

          const user = usersState.find(
            (e) => e.scaleSocketId === scaleSocketId
          );

          const parsedUpdateFields = parseUserToNewObject(
            updatedFields,
            user,
            searchScaleInDate
          );

          const newUsersState = [...(usersState || [])].map((e) =>
            e.scaleSocketId === scaleSocketId
              ? { ...e, ...parsedUpdateFields }
              : e
          );

          setScalesResource(newUsersState);

          return orderGridBySocket(newUsersState);
        } else {
          setScalesResource(usersState);

          return usersState;
        }
      });
      setUpdateSocket(false);
    },
  };

  return {
    useCounter,
    setCounter,
    useTimeLine,
    isSearchingMoreData,
    tableRef,
    useLoading,
    handleProfessional,
    professional,
    getUsers,
    setDateForm,
    setDate,
    dates,
    arrayDate,
    reset,
    handleSubmit,
    searchScaleInDate,
    setScaleInDate,
    useUsers,
    setSendForm,
    dateForm,
    clearState,
    openUnlockModal,
    useModalData,
    useUnlockModal,
    loadUsers,
    onOrderChange,
    handleTab,
    updateSocket
  };
};

export default uselistPauseScale;
