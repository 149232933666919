import React from 'react';
import MenuPainel from '../components/MenuPainelNew';
import CssBaseline from '@material-ui/core/CssBaseline';
import AdmAcionamentoNew from '../components/Lib/Forms/FormAdm/indexCreate';
import { ChangePassword } from '../components/ChangePassword';

import PaperForm from '../components/PaperForm';

class AcionamentoNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blockStatusReset: false,
    };
  }
  render() {
    return (
      <React.Fragment>
        <CssBaseline />

        <ChangePassword />

        <MenuPainel stepper=''>
          {/* <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={}
          ></PaperForm> */}
        </MenuPainel>

        <AdmAcionamentoNew fatherState={this} typeForm='manual' />
      </React.Fragment>
    );
  }
}

const connectedAssinar = AcionamentoNew;
export { connectedAssinar as AcionamentoNew };
