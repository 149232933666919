export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
};

export const alertContingenciaConstants = {
  SUCCESS: 'ALERT_CONTINGENCIA_SUCCESS',
  ERROR: 'ALERT_CONTINGENCIA_ERROR',
  CLEAR: 'ALERT_CONTINGENCIA_CLEAR',
};
