import React from 'react';
import InputMaskCustom from '../../components/Forms/InputMaskCustom';
import { Formik, Form } from 'formik';
import { history } from '../../helpers';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Fab, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { cancelamentoUserActions } from '../../actions/cancelamenstoUser.action';
import moment from 'moment';
import DialogModal from '../DialogModal';
import {
  statusTypeForm,
  retained,
  reasonCancellation,
  unsuccessfulContact,
  statusTypeTranslation,
  reasonCancellationTranslation,
} from '../../commons/';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { IconButton, InputAdornment } from '@material-ui/core';
import SelectCustom from '../Forms/SelectCustom';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Errors } from './Errors';

import 'moment/locale/pt-br';
import { HistoryCancelments } from './HistoryCancelments';
import { check } from 'prettier';
const useStylesFilterComponent = (theme) => ({
  root: {
    padding: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  formAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  textField: {
    width: '9%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  dateField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-100px',
    fontWeight: 500,
  },
  dateFieldEntry: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-30px',
    fontWeight: 500,
  },
  dateFieldHistory: {
    width: '30%',
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
  loaderAlign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
    marignTop: '20%',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  height: {
    height: '60px',
  },
});

const ContainerDate = styled(DateTimePicker)`
  .MuiInput-underline.Mui-error:after {
    transform: scaleX(0);
    border-bottom-color: #e2e2e2;
  }
`;

class FormCancelUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledCancelFlag: true,
      user: {
        statusCancel: {
          message: '',
          reason: '',
          status: '',
          futureDate: {},
          justification: '',
          operator: '',
          operatorId: '',
        },
        _id: '',
      },
      tempLog: [],
      observationValid: false,
      reasonValid: false,
      statusValid: false,
      futureDateValid: true,
      justificationValid: true,
      commentValid: true,
      futureDate: {},
      isCancelSelected: false,
      isOpen: false,
      isRequired: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.removeEmote = this.removeEmote.bind(this);
  }

  removeEmote(string) {
    var regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
  }

  handleChange(e) {
    const { user } = this.state;
    const operator = JSON.parse(localStorage.getItem('user'));

    if (e.target.name === 'message') {
      const comment = this.removeEmote(e.target.value);

      e.target.value = comment;
      this.setState({
        ...this.state,

        user: {
          ...user,
          statusCancel: {
            ...user.statusCancel,
            [e.target.name]: e.target.value,
            operator: operator.user.nome,
            operatorId: operator.user.id_perfil._id,
          },
        },
      });
    } else {
      this.setState({
        ...this.state,

        user: {
          ...user,
          statusCancel: {
            ...user.statusCancel,
            [e.target.name]: e.target.value,
            operator: operator.user.nome,
            operatorId: operator.user.id_perfil._id,
          },
        },
      });
    }
  }

  handleChangeJustification(e) {
    const { user } = this.state;
    this.setState({
      ...this.state,
      isErrorJustification: false,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          [e.target.name]: e.target.value,
        },
      },
    });
  }

  validDate() {
    const actualDate = moment();
    const selectedDate = moment(this.state.user.statusCancel.futureDate).format(
      'DD/MM/YYYY HH:mm'
    );
    if (!selectedDate.includes('Data inválida')) {
      let days = moment(this.state.user.statusCancel.futureDate).diff(
        actualDate,
        'days'
      );
      let hours = moment(this.state.user.statusCancel.futureDate).diff(
        actualDate,
        'hours'
      );
      if (days == 0) return hours > 0;
      return days > 0;
    }
    return false;
  }

  handleChangedate(value) {
    const { user } = this.state;
    this.setState({
      ...this.state,
      isErrorFutureDate: false,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          futureDate: value,
        },
      },
    });
  }

  handleChangeStatus(e) {
    const { user } = this.state;
    const operator = JSON.parse(localStorage.getItem('user'));
    this.setState({
      ...this.state,
      isErrorStatus: false,
      isCancelSelected: this.checkCancellationType(e.target.value),
      isRequired: e.target.value === 'OPEN' ? false : true,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          status: e.target.value,
          reason: '',
          observation: '',
          description: statusTypeTranslation[e.target.value],
          operator: operator.user.nome,
          operatorId: operator.user.id_perfil._id,
        },
      },
    });
  }

  handleChangeReason(e) {
    const { user } = this.state;
    this.setState({
      ...this.state,
      isErrorReason: false,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          reason: e.target.value,
          observation: reasonCancellationTranslation[e.target.value],
        },
      },
    });
  }

  handleCancel() {
    this.setState({ open: false });
  }

  handleConfirm() {
    this.setState({ open: false });
    if (this.state.user.statusCancel.futureDate)
      this.state.user.statusCancel.futureDate = moment(
        this.state.user.statusCancel.futureDate
      )
        .subtract('3', 'hours')
        .toISOString();
    this.props.dispatch(
      cancelamentoUserActions.ConfirmationCancelamentoUser(this.state.user)
    );
  }

  checkCancellationType(status) {
    return (
      status === 'CANCELED_CHARGEBACK' ||
      status === 'CANCELED_WITH_PENALITY' ||
      status === 'CANCELED_WITHOUT_PENALITY' ||
      status === 'CANCELED_INVOLUNTARY'
    );
  }

  checkStatus(status) {
    return status === 'FUTURE_CONTACT' || status === 'CONTACT_UNSUCCESSFUL';
  }

  isoToDate(str) {
    let date = moment(str).format('DD/MM/YYYY HH:mm').toString();
    return date;
  }

  componentDidMount() {
    let { log = [] } = this.props.data;
    if (this.props.data.statusCancel && this.props.data.statusCancel.futureDate)
      this.props.data.statusCancel.futureDate = moment(
        this.props.data.statusCancel.futureDate,
        'DD/MM/YYYY HH:mm:ss'
      ).toISOString();
    let arrayBlockedStatus = [
      'RETAINED_COSTUMER',
      'CANCELED_CHARGEBACK',
      'CANCELED_WITH_PENALITY',
      'CANCELED_WITHOUT_PENALITY',
    ];
    let disabledCancelFlag =
      this.props.data.statusCancel && this.props.data.statusCancel.status
        ? arrayBlockedStatus.includes(this.props.data.statusCancel.status)
        : true;

    this.setState({
      ...this.state,
      user: {
        statusCancel: {
          message: '',
          reason: '',
          status: '',
          futureDate: '',
          justification: '',
        },
        ...this.props.data,
      },
      tempLog: log.reverse(),
      open: false,
      isErrorStatus: false,
      isRequired: this.state.user.statusCancel.status === 'OPEN' ? false : true,
      disabledCancelFlag: disabledCancelFlag,
    });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      user: {},
    });
  }

  validForm() {
    let justificationValid = true;
    let futureDateValid = true;

    if (
      this.checkCancellationType(this.state.user.statusCancel.status) &&
      this.state.user.statusCancel.justification.trim() === ''
    ) {
      justificationValid = false;
      this.setState({
        isErrorJustification: true,
      });
    }

    // } else {
    //   justificationValid = true;
    //   this.setState({
    //     isErrorJustification: false,
    //   });

    if (
      this.checkCancellationType(this.state.user.statusCancel.status) &&
      this.state.user.statusCancel.justification === undefined
    ) {
      justificationValid = false;
      this.setState({
        isErrorJustification: true,
      });
    }

    // } else {
    //   justificationValid = true;
    //   this.setState({
    //     isErrorJustification: false,
    //   });

    if (
      this.checkStatus(this.state.user.statusCancel.status) &&
      !this.validDate()
    ) {
      this.setState({ isErrorFutureDate: true });
      futureDateValid = false;
    }

    if (this.state.user.statusCancel.status === '')
      this.setState({ statusValid: false, isErrorStatus: true });

    if (this.state.user.statusCancel.reason === '')
      this.setState({ reasonValid: false, isErrorReason: true });

    if (this.state.user.statusCancel.observation === '') {
      this.setState({ observationValid: false });
    }

    if (
      this.state.user.statusCancel.status != '' &&
      this.state.user.statusCancel.reason != '' &&
      this.state.user.statusCancel.observation != '' &&
      justificationValid &&
      futureDateValid
    ) {
      return true;
    }

    return false;
  }

  opemModal() {
    let { user } = this.state;

    if (this.state.user.statusCancel.status === 'OPEN') {
      this.setState({ ...this.state, open: true });
    } else {
      if (this.validForm()) this.setState({ ...this.state, open: true });
    }
  }

  render() {
    const classes = useStylesFilterComponent();
    return (
      <Formik enableReinitialize initialValues={{ ...this.state.user }}>
        {() => (
          <Form>
            <Grid justify='space-between' container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  disabled={true}
                  name='customerId'
                  label='ID Cliente'
                  placeholder='customerId'
                  value={this.state.user.customerId}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={true}
                  name='productDescription'
                  label='Produto'
                  placeholder='Produto'
                  value={this.state.user.productDescription}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={true}
                  name='name'
                  label='Nome'
                  placeholder='Nome'
                  value={this.state.user.name}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={true}
                  name='lastName'
                  label='Sobrenome'
                  placeholder='Sobrenome'
                  value={this.state.user.lastName}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>

              <DialogModal
                title='Inclusão de status'
                open={this.state.open}
                handleCancel={() => this.handleCancel()}
                handleConfirm={() => this.handleConfirm()}
                withActions
                labelOk={'Confirmar'}
                labelCancel={'Cancelar'}
                typeButtomCancel={'lightRounded'}
                typeButtomConfirm={'dangerLightRounded'}
                text={
                  this.state.user.statusCancel === 'CANCELED_WITH_PENALITY'
                    ? 'Atenção! Não se esqueça de gerar link com valor da multa de cancelamento e envia-lo ao paciente'
                    : 'Confirme abaixo a mudança de status. Essa ação não poderá ser desfeita.'
                }
              ></DialogModal>

              {this.state.user.validCpf && (
                <Grid item xs={6}>
                  <InputMaskCustom
                    disabled={true}
                    name='cpf'
                    label='CPF'
                    placeholder='cpf'
                    value={this.state.user.document}
                    mask='999.999.999-99'
                    maskChar=' '
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>
              )}

              {!this.state.user.validCpf && (
                <Grid item xs={6}>
                  <InputMaskCustom
                    disabled={true}
                    name='cpf'
                    label='Passaporte'
                    placeholder='passaporte'
                    value={this.state.user.document}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>
              )}

              {!this.state.user.validCpf && (
                <Grid item xs={6}>
                  <InputMaskCustom
                    disabled={true}
                    mask='(99) 99999-9999'
                    name='phone'
                    label='DDI + Telefone '
                    placeholder='DDI + Telefone'
                    value={this.state.user.phone}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>
              )}

              {this.state.user.validCpf && (
                <Grid item xs={6}>
                  <InputMaskCustom
                    disabled={true}
                    name='phone'
                    label='DDD + Telefone '
                    placeholder='DDI + Telefone'
                    value={this.state.user.phone}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  name='email'
                  label='E-mail '
                  placeholder='E-mail'
                  value={this.state.user.email}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  multiline
                  rows={3}
                  type='text-area'
                  name='reason'
                  label='Motivo do cancelamento'
                  value={this.state.user.reason}
                  placeholder='Motivo do cancelamento'
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  name='purchaseDate'
                  label='Data de Compra'
                  placeholder='Data de Compra'
                  type='Mensagem'
                  className={classes.textField}
                  value={this.state.user.purchaseDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '30px',
              }}
            >
              <h3>Tratativa da Solicitação </h3>
            </div>

            <Grid justify='space-between' container spacing={3}>
              <Grid item xs={12}>
                <Errors
                  errorText='Campo invalido!'
                  isError={this.state.isErrorStatus}
                  isRequired={
                    this.state.user.statusCancel.status === 'OPEN'
                      ? false
                      : true
                  }
                >
                  <SelectCustom
                    disable={this.state.disabledCancelFlag}
                    name='status'
                    label='Status'
                    onChange={(e) => this.handleChangeStatus(e)}
                    value={this.state.user.statusCancel.status}
                    dataSource={statusTypeForm}
                    placeholder='Status'
                  />
                </Errors>
              </Grid>
              <Grid item xs={12}>
                {this.state.user.statusCancel &&
                  this.checkStatus(this.state.user.statusCancel.status) && (
                    <Errors
                      errorText='Campo invalido!'
                      isError={this.state.isErrorReason}
                      isRequired={this.state.isRequired}
                    >
                      <SelectCustom
                        disable={this.state.disabledCancelFlag}
                        type='reason'
                        reson='reason'
                        label='Motivo'
                        onChange={(e) => this.handleChangeReason(e)}
                        value={this.state.user.statusCancel.reason}
                        dataSource={unsuccessfulContact}
                        placeholder='Motivo'
                      />
                    </Errors>
                  )}

                {this.state.user.statusCancel.status !== 'RETAINED_COSTUMER' &&
                  !this.checkStatus(this.state.user.statusCancel.status) && (
                    <Errors
                      errorText='Campo invalido!'
                      isError={this.state.isErrorReason}
                      isRequired={this.checkCancellationType(
                        this.state.user.statusCancel.status
                      )}
                    >
                      <SelectCustom
                        disable={this.state.disabledCancelFlag}
                        name='reason'
                        label='Motivo'
                        onChange={(e) => this.handleChangeReason(e)}
                        value={this.state.user.statusCancel.reason}
                        dataSource={reasonCancellation}
                        placeholder='Motivo'
                      />
                    </Errors>
                  )}

                {this.state.user.statusCancel.status ===
                  'RETAINED_COSTUMER' && (
                  <Errors
                    errorText='Campo invalido!'
                    isError={this.state.isErrorReason}
                    isRequired={this.state.isRequired}
                  >
                    <SelectCustom
                      disable={this.state.disabledCancelFlag}
                      name='reason'
                      label='Motivo'
                      onChange={(e) => this.handleChangeReason(e)}
                      value={this.state.user.statusCancel.reason}
                      dataSource={retained}
                      placeholder='Motivo'
                    />
                  </Errors>
                )}
              </Grid>

              {this.state.user.statusCancel &&
                this.checkStatus(this.state.user.statusCancel.status) && (
                  <Grid item xs={12}>
                    <Errors
                      errorText='Data e Horário do próximo contato deve ser superior a data e horário atual'
                      isError={this.state.isErrorFutureDate}
                      isRequired={this.state.isRequired}
                    >
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        locale={moment.locale('pt-br')}
                      >
                        <ContainerDate
                          disabled={this.state.disabledCancelFlag}
                          label='Data e horário do próximo contato'
                          fullWidth
                          disablePast={true}
                          hideTabs
                          ampm={false}
                          value={this.state.user.statusCancel.futureDate}
                          onChange={(e) => this.handleChangedate(e)}
                          allowKeyboardControl={false}
                          helperText=''
                          format='DD/MM/YYYY HH:mm'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton>
                                  <CalendarTodayIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Errors>
                  </Grid>
                )}
              <Grid item xs={12}>
                {!this.checkCancellationType(
                  this.state.user.statusCancel.status
                ) ? (
                  <div style={{ paddingLeft: '11px' }}>
                    <TextField
                      disabled={this.state.disabledCancelFlag}
                      name='message'
                      label='Comentários'
                      placeholder='Comentários'
                      type='Comentários'
                      value={this.state.user.statusCancel.message}
                      onChange={(e) => this.handleChange(e)}
                      fullWidth
                      id='standard-multiline-flexible'
                      multiline
                      rowsMax={4}
                    />
                  </div>
                ) : (
                  <Errors
                    errorText='Campo inválido!'
                    isError={this.state.isErrorJustification}
                    isRequired={this.state.isRequired}
                  >
                    <TextField
                      disabled={this.state.disabledCancelFlag}
                      name='justification'
                      label='Justificativa'
                      placeholder='Justificativa'
                      type='Justificativa'
                      value={this.state.user.statusCancel.justification}
                      onChange={(e) => this.handleChangeJustification(e)}
                      fullWidth
                      multiline
                      rowsMax={4}
                    />
                  </Errors>
                )}
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: '30px', marginBottom: '30px' }}
              justify='center'
              container
              spacing={6}
            >
              {!this.state.disabledCancelFlag && (
                <Grid item xs={6}>
                  <Fab
                    variant='extended'
                    aria-label='Delete'
                    //className={classes.fab}
                    color='primary'
                    onClick={(e) => this.opemModal(e)}
                    style={{
                      fontSize: '10px',
                      width: '100%',
                      height: '36px',
                      background:
                        'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                      boxShadow: 'none',
                      border: '1px white solid',
                      marginRight: '5px',
                      margin: '1%',
                    }}
                  >
                    Salvar Dados
                  </Fab>
                </Grid>
              )}
            </Grid>

            <Grid justify='center' container>
              <Grid item xs={7}>
                <Fab
                  variant='extended'
                  aria-label='Delete'
                  //className={classes.fab}
                  color='primary'
                  onClick={(e) => history.push('/canc/ativos')}
                  style={{
                    fontSize: '10px',
                    width: '40%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginLeft: '107px',
                  }}
                >
                  Voltar
                </Fab>
              </Grid>
            </Grid>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '30px',
              }}
            >
              <h3>Histórico</h3>
            </div>

            <HistoryCancelments
              dataSource={this.state.tempLog.reverse()}
              moment={moment}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.alert;
  return {
    ...user,
  };
}

const connenctCancelPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(FormCancelUser)
);
export default connenctCancelPage;
