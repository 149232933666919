import React from 'react'

import { Skeleton } from '@material-ui/lab';

const CustomSkeleton = ({ variant = "text", width = '100%', height}) => {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
    />
  )
}

export default CustomSkeleton
