import React from 'react';

import { Container } from '@material-ui/core';

const CustomContainer = ({ children, maxWidth = 'lg' }) => (
	<Container maxWidth={maxWidth}>
		{children}
	</Container>
)

export default CustomContainer;
