import React from 'react';
import { SelectLabel, SelectStyled, SVG } from './styles';
import PropTypes from 'prop-types';

const GenericSelect = ({
  data,
  height,
  name,
  id,
  placeholder,
  disabled,
  iconDisable,
  startIcon,
  endIcon,
  icon1,
  icon2,
  onChange,
  value,
  onBlur,
  bgColor,
  validation,
  color,
  onHover,
  opacity,
  textColor,
}) => {
  const Icon1 = icon1;
  const Icon2 = icon2;

  if (placeholder && !data[0]?.label.includes(placeholder)) {
    data.unshift({ label: placeholder, value: '' });
  }
  return (
    <SelectLabel>
      {startIcon && (
        <SVG>
          <Icon1 />
        </SVG>
      )}
      {data && data.length > 0 && (
        <SelectStyled
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          height={height}
          placeholder={placeholder}
          disabled={disabled}
          iconDisable={iconDisable}
          onBlur={onBlur}
          border={
            validation?.errors && validation?.touched
              ? color
              : '1px solid #ced4da'
          }
          borderRadius={'5px'}
          bgColor={bgColor}
          onHover={onHover}
          opacity={opacity}
          textColor={textColor}
        >
          {data &&
            data.map((element, index) => {
              return (
                <option key={index} value={element.value}>
                  {element.label}
                </option>
              );
            })}
        </SelectStyled>
      )}
      {endIcon && (
        <SVG>
          <Icon2 />
        </SVG>
      )}
    </SelectLabel>
  );
};
GenericSelect.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disable: PropTypes.bool,
  iconDisable: PropTypes.bool,
  endIcon: PropTypes.bool,
  startIcon: PropTypes.bool,
};
export default GenericSelect;
