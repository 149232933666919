export const cepMaxLength = 8;

export const styles = {
  height: 45,
  width: 363,
  borderRadius: 4,
  borderColor: '#dfe3e8',
  borderWidth: 1,
  paddingLeft: 16,
  borderStyle: 'solid',
  color: '#6b7786',
  marginTop: 7,
};

export const customBorderStyles = {
  solo: 0,
  outlined: '1px solid lightgray',
  error: '1px solid #FF2424',
};

export const types = {
  date: 'date',
  text: 'text',
  cep: 'text',
  time: 'time',
  textarea: 'textarea',
};
