import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import { translationSupport } from '../../commons';
import { isoToDatePTBR, formatDateHour } from '../../utils/utils';

const useStylesFilterComponent = (theme) => ({
  root: {
    padding: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  formAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  textField: {
    width: '9%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  dateField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-100px',
    fontWeight: 500,
  },
  dateFieldEntry: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-30px',
    fontWeight: 500,
  },
  dateFieldHistory: {
    width: '30%',
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
  loaderAlign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
    marignTop: '20%',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '15px',
    color: 'red',
  },
});

const useStylesPaginationComponent = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class HistoricoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: [5, 10, 15],
      rows: [],
      numberOfRows: 5,
      page: 1,
      total: 5,
      itemByPage: 1,
      tableData: props.data,
      type: props.type,
      form: {
        prontuario: '',
        name: '',
        lastName: '',
        document: '',
        convenioDescricao: '',
        entrada: '',
      },
    };
    this.itemByPage = 5;
    this.handleClose = this.handleClose.bind(this);
  }

  filterDate(date) {
    if (moment(date, 'DD/MM/YYYY HH:mm').isValid()) {
      return moment(date, 'DD/MM/YYYY HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    } else {
      return moment(date, 'YYYY-MM-DD HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    }
  }

  formataCPF(cpf = '') {
    //retira os caracteres indesejados...
    cpf = cpf ? cpf.replace(/[^\d]/g, '') : '';
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  handleClose() {
    this.setState({ open: false, message: '', loadingsub: false });
  }

  changeValue(event) {
    let { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        id='formatted-text-mask-input'
        placeholder='Data de criação'
        mask='99/99/9999'
        maskChar={null}
      />
    );
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  styleComp() {
    let style = useStylesPaginationComponent();
    return style;
  }
  rowData(type) {
    if (type == 'acionamento') {
      let { searchUser } = this.props.searchUser;
      let data = searchUser.historicoAcionamento;
      data.map((r) => {
        r.name = r.name + ' ' + r.lastName;
        if (r.createDate) {
          r.createDate = this.filterDate(r.createDate);
        }
        if (r.documentType == 'CPF') {
          r.document = this.formataCPF(r.document);
        }
        return { ...r };
      });
      return data;
    }

    if (type == 'suporte') {
      let { searchUser } = this.props.searchUser;
      let data = searchUser.historicoSuporte;
      data.map((r) => {
        r.origem = r.origin.from;
        r.motivo = translationSupport[r.reason];
        r.name = r.name + ' ' + r.lastName;
        if (r.supportCompleted) {
          r.statusDoSuporte = 'Concluído';
        } else {
          r.statusDoSuporte = 'Não concluído';
        }
        if (r.createDate) {
          r.createDate = this.filterDate(r.createDate);
        }
        if (r.documentType == 'CPF') {
          r.document = this.formataCPF(r.document);
        }
        return { ...r };
      });
      return data;
    }

    if (type == 'convenio') {
      let { searchUser } = this.props.searchUser;
      let data =
        searchUser.relacionamentoPortal &&
        searchUser.relacionamentoPortal.length > 0
          ? searchUser.relacionamentoPortal
          : [];
      let newArr = [];

      data.map((r) => {
        r.origem = 'Portal';
        r.name = r.nome;
        if (r.document_type == 'CPF') {
          r.documento = this.formataCPF(r.documento);
        }

        if (r.titularidade == 'TITU') newArr.push(r);

        return { ...r };
      });

      return newArr.length > 0 ? newArr : data;
    }
    if (type == 'log') {
      const data = this.props.rowData;
      const response = data.map((element) => {
        const lido = ['', 'Lido'][Number(element.read)];
        element.hourRead = formatDateHour(element.hourRead);
        return { ...element, lido };
      });
      return response;
    }
  }

  rowColumns(type) {
    if (type == 'acionamento') {
      const columns = [
        { title: 'Nome', field: 'name' },
        { title: 'Documento', field: 'document' },
        { title: 'Convênio', field: 'convenio' },
        { title: 'Contrato', field: 'convenioDescricao' },
        { title: 'Entrada', field: 'createDate' },
        { title: 'Email', field: 'email' },
      ];
      return columns;
    }
    if (type == 'suporte') {
      const columns = [
        { title: 'Nome', field: 'name' },
        { title: 'Documento', field: 'document' },
        { title: 'Contrato', field: 'convenioDescricao' },
        { title: 'Entrada', field: 'createDate' },
        { title: 'Origem', field: 'origem' },
        { title: 'Motivo', field: 'motivo' },
        { title: 'Status do Suporte', field: 'statusDoSuporte' },
      ];
      return columns;
    }
    if (type == 'convenio') {
      const columns = [
        { title: 'Nome', field: 'nome' },
        { title: 'Titularidade', field: 'titularidade' },
        { title: 'Documento', field: 'documento' },
        { title: 'Data de Nascimento', field: 'data_nascimento' },
        { title: 'Produto', field: 'produto' },
        { title: 'Contrato', field: 'descricao' },
        { title: 'Telefone', field: 'telefone' },
        { title: 'E-mail', field: 'email' },
        { title: 'Data de Início', field: 'data_val_de' },
        { title: 'Data de Expiração', field: 'data_vigencia' },
        { title: 'Origem', field: 'origem' },
      ];
      return columns;
    }
    if (type == 'log') {
      const columns = [
        { title: 'Status', field: 'lido' },
        { title: 'Data', field: 'hourRead' },
        { title: 'Nome', field: 'name' },
        { title: 'Função', field: 'description' },
      ];
      return columns;
    }
  }

  render() {
    return (
      <div>
        <MaterialTable
          title=''
          icons={tableIcons}
          columns={this.rowColumns(this.state.type)}
          data={this.rowData(this.state.type)}
          options={{
            search: false,
            sorting: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Não há nenhum dado no histórico ',
            },

            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  let searchUser = state.searchUser;
  return {
    searchUser,
  };
}

HistoricoDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(HistoricoDetails)
);
export { connectedLoginPage as HistoricoDetails };
