import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HistoricoComunicado } from '../Comunicados/HistoricoComunicado';
import { CreateComunicado } from '../Comunicados/FormCreateComunicado';
import {
  Content,
  ButtonContentCreate,
  ButtonContentHistory,
} from './styles/comunicados';
import { history } from '../../helpers';
import { initiaValues } from '../../commons';

class GestaoComunicados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createComunication: true,
      historyComunication: false,
    };
    this.handleCreateComunication = this.handleCreateComunication.bind(this);
    this.handleHistoryComunication = this.handleHistoryComunication.bind(this);
  }

  handleCreateComunication() {
    history.push('/adm/comunicados');
    this.setState({
      createComunication: true,
      historyComunication: false,
    });
  }

  handleHistoryComunication() {
    history.push('/adm/comunicados');
    this.setState({
      createComunication: false,
      historyComunication: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Content>
          <ButtonContentCreate
            createComunication={this.state.createComunication}
            onClick={this.handleCreateComunication}
          >
            Cadastro
          </ButtonContentCreate>
          <ButtonContentHistory
            historyComunication={this.state.historyComunication}
            onClick={this.handleHistoryComunication}
          >
            Histórico
          </ButtonContentHistory>
        </Content>
        {this.state.createComunication ? (
          <CreateComunicado initialValues={initiaValues} disabled={false} />
        ) : (
          <HistoricoComunicado />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { message, type, open } = state.alert;
  return {
    message,
    type,
    open,
  };
}

GestaoComunicados.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(GestaoComunicados);
export { connectedLoginPage as GestaoComunicados };
