import styled from 'styled-components';

export const LabelError = styled.label`
  width: 100%;
  text-align: left;
  font: normal normal normal 14px/18px Inter;
  color: #fe1d14;
  opacity: 1;
  display: block;

  :nth-child(0) {
    margin-top: 10px;
  }
`;
