import React from 'react';

import { useNavigator } from '../../../hooks';

import {
  CustomDivider,
  CustomButton,
  CustomIcon,
  CustomText,
} from '../../../components/ui';

import { CustomInput, CustomSelect } from '../../../components/ui/webform';

import {
  StyledWrapper,
  StyledDataWrapper,
  StyledRow,
  StyledForm,
} from './styles';

import {
  snackBreakDurationOptions,
  restBreakDurationOptions,
} from './constants';

import useEditScale from './hooks/useEditScale';

import { useParams } from 'react-router-dom';

const EditScalePage = ({}) => {
  const { _id: id } = useParams();

  const { back, push } = useNavigator();

  const {
    departureTime,
    entryTime,
    handlerSubmit,
    restBreakDuration,
    restBreakTime,
    setDepartureTime,
    setEntryTime,
    setRestBreakDuration,
    setRestBreakTime,
    setSnackBreakDuration,
    setSnackBreakTime,
    snackBreakDuration,
    snackBreakTime,
    userName,
    useError,
  } = useEditScale(id);

  return (
    <StyledWrapper>
      {useError ? (
        <CustomText title>Falha ao carregar escala</CustomText>
      ) : (
        <>
          <CustomIcon color='default' icon='ArrowBack' onClick={() => back()} />

          <StyledDataWrapper>
            <CustomText
              size='xlarge'
              color='dark'
              style={{
                height: '24px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {userName}
            </CustomText>

            <CustomDivider space={5} />

            <StyledRow align='start'>
              <CustomText title>Informações gerais</CustomText>
              <CustomText title color='info'>
                Controle de horários
              </CustomText>
            </StyledRow>

            <CustomDivider space={10} />

            <StyledForm>
              <StyledRow align='space-between'>
                <CustomInput
                  value={entryTime}
                  onChange={(e) => setEntryTime(e.target.value)}
                  title='HORÁRIO DE ENTRADA'
                  type='time'
                />

                <CustomInput
                  value={departureTime}
                  onChange={(e) => setDepartureTime(e.target.value)}
                  title='HORÁRIO DE SAÍDA'
                  type='time'
                />
              </StyledRow>

              <StyledRow align='space-between'>
                <CustomInput
                  value={snackBreakTime}
                  onChange={(e) => setSnackBreakTime(e.target.value)}
                  title='HORÁRIO DE PAUSA LANCHE'
                  type='time'
                />

                <CustomSelect
                  title='TEMPO DE PAUSA LANCHE'
                  value={snackBreakDuration}
                  onChange={({ value }) => setSnackBreakDuration(value)}
                  options={snackBreakDurationOptions}
                />
              </StyledRow>

              <StyledRow align='space-between'>
                <CustomInput
                  value={restBreakTime}
                  onChange={(e) => setRestBreakTime(e.target.value)}
                  title='HORÁRIO DE PAUSA DESCANSO'
                  type='time'
                />

                <CustomSelect
                  title='TEMPO DE PAUSA DESCANSO'
                  value={restBreakDuration}
                  onChange={({ value }) => setRestBreakDuration(value)}
                  options={restBreakDurationOptions}
                />
              </StyledRow>
            </StyledForm>

            <CustomDivider space={30} height={2} border />

            <StyledRow align='end'>
              <CustomButton
                onClick={() => push('/adm/scales')}
                size='large'
                color='danger'
                type='outlined'
                text='Cancelar'
              />

              <CustomButton
                onClick={handlerSubmit}
                size='large'
                type='outlined'
                text='Salvar alterações'
              />
            </StyledRow>
          </StyledDataWrapper>
        </>
      )}
    </StyledWrapper>
  );
};

export default EditScalePage;
