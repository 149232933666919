import {
  notificationsConstants,
  getIdNotificationsConstants,
} from '../constants';

export function getNotifications(state = { getNotifications: {} }, action) {
  switch (action.type) {
    case notificationsConstants.NOTIFICATIONS_SUCCESS:
      return {
        type: 'success',
        getNotifications: action.notifications,
      };
    case notificationsConstants.NOTIFICATIONS_FAILURE:
      return Object.assign({}, state, {
        type: 'error',
        getNotifications: action.error,
      });
    case notificationsConstants.NOTIFICATIONS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}

export function getIdNotifications(state = { getIdNotifications: {} }, action) {
  switch (action.type) {
    case getIdNotificationsConstants.GET_ID_NOTIFICATIONS_SUCCESS:
      return {
        type: 'success',
        getIdNotifications: action.notificationsId,
      };
    case getIdNotificationsConstants.GET_ID_NOTIFICATIONS_FAILURE:
      return Object.assign({}, state, {
        type: 'error',
        getIdNotifications: action.error,
      });
    case getIdNotificationsConstants.GET_ID_NOTIFICATIONS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
