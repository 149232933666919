import styled, { css } from 'styled-components';

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Fieldset = styled.fieldset`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 12px;
  justify-content: space-evenly;
  margin-top: 16px;
`;

interface StyledInputProps {
  error?: boolean;
}
export const StyledInput = styled.input<StyledInputProps>`
  ${({ error }) => css`
    width: 40px;
    height: 40px;
    border: 1px solid ${error ? '#d32f2f' : '#b3b7c0'};
    border-radius: 4px;
    padding: 8px;
    background-color: #ffffff;
    font-size: 18px;
    text-align: center;
  `}
`;

export const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const StyledSubmitButton = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background: #378ff7;
  border-radius: 4px;
  color: #fff;
  font: normal normal 600 14px/18px Inter;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.02s ease-in-out;
  cursor: pointer;

  :not(:disabled):hover {
    background: #247ce4;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const StyledResetButton = styled(StyledSubmitButton)`
  background: #ffffff;

  :not(:disabled):hover {
    background: #f7f4f0;
  }
`;
