import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import styled from 'styled-components';

const ContainerCheck = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #7d7c7c;
  }

  .PrivateSwitchBase-root-1 {
    padding: 5px 0 5px 0;
  }
`;

const ContainerCheckBox = styled(Checkbox)`
  .MuiCheckbox-root {
    color: #f50057;
  }
`;

export default function CheckboxCustom({
  label,
  color,
  disabled,
  onChange,
  checked,
  name,
}) {
  return (
    <ContainerCheck
      label={label}
      name={name}
      control={
        <ContainerCheckBox
          disabled={disabled}
          onChange={onChange}
          color='default'
          inputProps={{
            'aria-label': 'checkbox with default color',
          }}
          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
          checkedIcon={<CheckBoxIcon fontSize='small' />}
          checked={checked}
        />
      }
    />
  );
}
