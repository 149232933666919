import React from 'react';
import MenuPainel from '../components/MenuPainelNew';
import { ChangePassword } from '../components/ChangePassword';
import Alert from '../components/Alert';
import { alertActions, userActions } from '../actions';
import { connect } from 'react-redux';
import QueuePage from '../components/Lib/QueuePage';
import GlobalStyle from '../components/Lib/styles/globalStyles';
import { CssBaseline } from '@material-ui/core';
import LoaderNew from '../components/Lib/LoaderNew/LoadingNew';
import { socket } from '../utils/socket';

class AdmNew extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      firstRow: [],
    };
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  render() {
    const { message, type, open } = this.props;
    const loaderEditReducer = this.props;
    return (
      <div>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        {loaderEditReducer?.loaderEdit && <LoaderNew />}
        <MenuPainel
          socket={socket}
          style={{ width: '100%' }}
          stepper='Acionamentos'
        >
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
        </MenuPainel>
        <QueuePage
          socket={socket}
          type={'Acionamento'}
          selfPaginate={false}
          fatherState={this}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loaderEdit } = state.loaderEditReducer;
  const { enabledQueuesGet } = state.enabledQueuesGet;
  return {
    loaderEdit,
    enabledQueuesGet,
  };
}
const connectedRegisterPage = connect(mapStateToProps)(AdmNew);
export { connectedRegisterPage as AdmNew };
