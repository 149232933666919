import { ActionPassageListConstants } from '../constants';

export function paacionamentolist(state = { paacionamentolist: {} }, action) {
  switch (action.type) {
    case ActionPassageListConstants.PAACIONAMENTO_LIST_SUCCESS:
      return {
        type: 'success',
        paacionamentolist: action.result,
      };
    case ActionPassageListConstants.PAACIONAMENTO_LIST_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        paacionamentolist: {},
      });
    case ActionPassageListConstants.PAACIONAMENTO_LIST_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
