import React from 'react';
import styled, { css } from 'styled-components';

//Todo Create palete
const primary = '#2699fb'; //também fonte color atendimento 2699fb
const white = '#fff';
const danger = '#FF2424';
const hardOnHold = '#FF8721';
const onHold = '#FFB324';
//const light = '#F1F4F6';
const info = '#e3ebf2';
const green = '#33AC2E';
const blue = '#0000ff';
const fontColorPrymaryLight = '#6B7786';
const defaultBorderRadius = '4px';
const roundedBorderRadius = '50px';

const buttonSize = {
  small: '4px 14px',
  medium: '2px 30px',
  large: '10px 30px',
};

const customType = {
  primary: {
    background: primary,
    borderRadius: defaultBorderRadius,
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    font: 'normal normal medium 14px/22px Rubik',
    backgroundActive: '',
  },
  primaryHighlight: {
    background: '#FFF',
    borderRadius: defaultBorderRadius,
    color: primary,
    border: `solid 1px ${primary}`,
    opacity: '0.8',
    font: 'normal normal medium 14px/22px Rubik',
    backgroundActive: '',
  },

  dangerHighlight: {
    background: '#FFF',
    borderRadius: defaultBorderRadius,
    color: danger,
    border: `solid 1px ${danger}`,
    opacity: '0.8',
    font: 'normal normal medium 14px/22px Rubik',
    backgroundActive: '',
  },

  danger: {
    background: danger,
    font: 'normal normal medium 14px/22px Rubik',
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    borderRadius: defaultBorderRadius,
    backgroundActive: '',
  },
  tele: {
    background: '#FFF',
    borderRadius: defaultBorderRadius,
    color: '#424242',
    border: `solid 1px #E1E1E1`,
    opacity: '0.8',
    font: 'normal normal medium 12px/22px Rubik',
    backgroundActive: '',
  },

  dangerRounded: {
    background: danger,
    font: 'normal normal medium 14px/22px Rubik',
    color: white,
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  onHold: {
    background: onHold,
    font: 'normal normal medium 14px/22px Rubik',
    color: white,
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  hardOnHold: {
    background: hardOnHold,
    font: 'normal normal medium 14px/22px Rubik',
    color: white,
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  //Todo button ghost
  lightRounded: {
    background: white,
    font: 'normal normal medium 14px/22px Rubik',
    color: fontColorPrymaryLight,
    border: 'solid 1px #CCC',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  lightBlue: {
    background: white,
    font: 'normal normal medium 14px/22px Rubik',
    color: blue,
    border: 'solid 1px #0000ff',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  Blue: {
    background: blue,
    font: 'normal normal medium 14px/22px Rubik',
    color: white,
    border: 'solid 1px #0000ff',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  dangerLightRounded: {
    background: '#FFF',
    borderRadius: roundedBorderRadius,
    color: danger,
    border: `solid 1px ${danger}`,
    opacity: '0.8',
    font: 'normal normal medium 14px/22px Rubik',
    backgroundActive: '',
  },

  light: {
    background: white,
    font: 'normal normal medium 14px/22px Rubik',
    color: fontColorPrymaryLight,
    border: 'none',
    borderRadius: defaultBorderRadius,
    opacity: '1',
    backgroundActive: info,
  },

  greenRounded: {
    background: green,
    font: 'normal normal medium 14px/22px Rubik',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  green: {
    background: green,
    font: 'normal normal medium 14px/22px Rubik',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: defaultBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  info: {
    background: info,
    font: 'normal normal medium 14px/22px Rubik',
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    borderRadius: defaultBorderRadius,
    backgroundActive: '',
  },
};

const Button = styled.button`
  font-size: ${(props) =>
    props.size === 'medium'
      ? '14px'
      : props.size === 'large'
      ? '16px'
      : '12px'};

  padding: ${(props) => buttonSize[props.size]};
  cursor: pointer;
  background: ${(props) => customType[props.typeButtom].background};
  border: ${(props) => customType[props.typeButtom].border};
  border-radius: ${(props) => customType[props.typeButtom].borderRadius};
  color: ${(props) => customType[props.typeButtom].color};

  &:hover {
    opacity: ${(props) => (props.opacity ? props.opacity : 0.9)};
  }

  &:active {
    background: ${(props) => customType[props.typeButtom].backgroundActive};
  }
  &:disabled {
    opacity: 0.4;
  }
  &:focus {
    outline: none;
    border-radius: ${(props) => customType[props.typeButtom].borderRadius};
    font-weight: bold;
    ${(props) =>
      props.focusColor !== '' &&
      css`
        background: ${(props) => props.focusColor};
      `}

    img {
      width: ${(props) =>
        props.size === 'medium'
          ? '16px'
          : props.size === 'large'
          ? '18px'
          : '14px'};

      padding-right: 5px;
    }
    opacity: ${(props) => props.opacity};
    ${(props) =>
      props.fontSize &&
      css`
        font-size: ${props.fontSize};
      `}

    ${(props) =>
      props.border &&
      css`
        border: ${(props) => props.border};
      `}

  height:100%;
    padding: ${(props) => props.padding};
    width: ${(props) => props.width};
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
`;

const handleClick = (e) => {
  if (typeof e === 'function') e();
};

const CustomButtom = ({
  typeButtom = 'primary',
  onClick,
  icon,
  size = 'small',
  text,
  children,
  focusColor,
  fontSize,
  border,
  disable,
  padding,
  color,
  width,
  id,
  clicked,
  toBeDisabled,
}) => {
  if (id === clicked) disable = false;
  if (
    id == toBeDisabled &&
    (toBeDisabled !== null) & (toBeDisabled !== undefined)
  ) {
    disable = true;
  }
  return (
    <Button
      disabled={disable}
      typeButtom={typeButtom}
      focusColor={focusColor}
      border={border}
      size={size}
      width={width}
      fontSize={fontSize}
      padding={padding}
      color={color}
      id={id}
      onClick={() => {
        handleClick(onClick);
      }}
    >
      <Container color={color}>
        {icon && icon} {text && <span> {text} </span>}
        {children && children}
      </Container>
    </Button>
  );
};

export default CustomButtom;
