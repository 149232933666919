// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Menu, MenuItem } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import Logo from '../../assets/logo.png';
import * as S from './styles';

type Props = {
  title: string;
};

export const Template = ({ title }: Props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setUsername(JSON.parse(localStorage.getItem('user') || '').user.nome);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (to: string) => {
    setAnchorEl(null);
    navigate(to);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    handleNavigate('/login');
  };

  return (
    <Container maxWidth='xl'>
      <S.Header container>
        <Grid item xs={4}>
          <S.Logo onClick={() => navigate('/adm')}>
            <img src={Logo} alt='logo' />
          </S.Logo>
        </Grid>
        <Grid item xs={4}>
          <S.Title justifyContent='center'>
            <h1>{title}</h1>
          </S.Title>
        </Grid>
        <Grid item xs={4}>
          <S.Menu>
            <S.User>
              <Button
                variant='text'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                Olá, {username}
              </Button>
              <FaUserCircle />
            </S.User>

            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleNavigate('/adm')}>
                Atendimentos
              </MenuItem>
              <MenuItem onClick={() => handleNavigate('/historico')}>
                Histórico
              </MenuItem>
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </S.Menu>
        </Grid>
      </S.Header>
    </Container>
  );
};
