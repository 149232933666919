import { modalAlertConstants } from '../constants';

export function modalAlert(state = {}, action) {
  switch (action.type) {
    case modalAlertConstants.SUCCESS:
      return {
        typeModalAlert: 'success',
        messageModalAlert: action.messageModalAlert,
        openModalAlert: true,
        loading: true,
      };
    case modalAlertConstants.ERROR:
      return Object.assign({}, state, {
        typeModalAlert: 'error',
        messageModalAlert: action.messageModalAlert,
        openModalAlert: true,
        loading: true,
      });
    case modalAlertConstants.CLEAR:
      return Object.assign({}, state, {
        typeModalAlert: action.varient ? action.varient : 'error',
        messageModalAlert: action.messageModalAlert,
        openModalAlert: false,
        loading: null,
      });
    default:
      console.debug(
        'notification reducer :: hit default',
        action.typeModalAlert
      );
      return state;
  }
}
