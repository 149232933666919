import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  boxdialog: {
    width: 600,
    margin: '0 auto 0 auto',
    '@media only screen and (max-width: 900px)': {
      padding: '0 0 20px 0',
      margin: '0 auto 0 auto',
      '@media screen and (orientation: landscape)': {
        margin: '0 auto',
      },
    },
  },
  dialog_modal: {
    borderRadius: '50%',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    margin: '0 auto',
  },
  titleDialog: {
    textAlign: 'center',
    fontWeight: '800',
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
  },
  icon: {
    fontSize: 20,
  },
  close: {
    position: 'absolute',
    width: '45px',
    top: '2px',
  },
  rootStyle: {
    width: '500px',
    height: '380px',
    padding: '-90px 0 -10px 0',
    borderRadius: 20,
    '@media only screen and (max-width: 900px)': {
      width: '100%',
      height: '100%',
    },
  },
});

class ModalDialog extends React.Component {
  render() {
    const { classes, title, open, close, componente, enter } = this.props;
    return (
      <React.Fragment>
        <Dialog
          classes={{ paper: classes.rootStyle }}
          fullScreen={true}
          open={open}
          onClose={close}
          onEnter={enter}
        >
          <DialogActions>
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={close}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </DialogActions>
          <DialogTitle className={classes.titleDialog}>
            <span className={classes.titleDialog}>{title}</span>
          </DialogTitle>
          <DialogContent>
            <Grid className={classes.dialog}>{componente}</Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

ModalDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = ModalDialog;
export default withStyles(styles)(connectedRegisterPage);
