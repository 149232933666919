import styled, { css } from 'styled-components';

export const InputLabel = styled.label`
  text-align: left;
  font: normal normal 600 13px/15px Inter;
  color: #000000;
  margin: 5px 0;
  text-transform: uppercase;
`;

interface ContainerPasswordProps {
  $showBorder?: boolean;
}
export const ContainerPassword = styled.div<ContainerPasswordProps>`
  ${({ $showBorder }) => css`
    background: #fff !important;
    width: 304px;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    border-color: ${$showBorder ? '#ff6347' : 'transparent'};
  `}
`;

interface InputTextPasswordProps {
  $hasBoxShadow?: boolean;
}
export const InputTextPassword = styled.input<InputTextPasswordProps>`
  ${({ $hasBoxShadow }) => css`
    box-shadow: ${$hasBoxShadow ? '0 0 6px #3381ee33' : 'none'};
    padding-right: '45px';
    width: calc(100%);
    height: 38px;
    padding: 0 15px;
    border: none;
    outline: none;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    color: #6b7786;
    transition: border 0.3s ease-in-out;
    cursor: pointer;
    font: normal normal normal 14px/15px Inter;
    transition: all 0.2s;

    :focus {
      border-color: #2699fb;
      box-shadow: 0 0 6px #3381ee33;
      cursor: text;
    }
  `}
`;

export const Eye = styled.img`
  width: 10%;
  cursor: pointer;
  padding-right: 10px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 6px;
  margin-bottom: 12px;
  font-family: 'Inter';
  width: 100%;
`;

export const LabelBlue = styled.label`
  cursor: pointer;
  text-align: right;
  font: normal normal 600 12px/22px Inter;
  letter-spacing: 0px;
  color: #378ff7;
  opacity: 1;
`;
