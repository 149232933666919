import React from 'react';

import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  StyledContainer,
  StyledForm,
  StyledContainerContent,
  StyledPageTitle,
  StyledRow,
  StyledButtonsWrapper,
  StyledPatientDataDesktop,
  StyledPatientDataMobile,
} from './styles';

import { formSchema } from './utils';

import { Container } from '../../../../../../Spread/components/context';

import { sleep } from '../../../../../../Spread/utils/common';

import {
  displayValue,
  formatDate,
  formatDocumentMask,
} from '../../../../../../Spread/utils/format';

import {
  CustomText,
  CustomIcon,
  CustomDivider,
  CustomButton,
  CustomModalConfirmation,
  CustomSnackbar,
  CustomClipboard,
} from '../../../../../../Spread/components/ui';

import { CustomInput } from '../../../../../../Spread/components/ui/webform';

import { useNavigator } from '../../../../../../Spread/hooks';

import { parsePhoneNumber } from '../utils';

import { BoxInfo, PatientData } from '../components';

import {
  productQueue,
  maskedDocumentTypes,
  snackBarMessageTypes,
} from '../../constants';

import usePredictaForm from './hooks/usePredictaForm';

const PredictaForm = () => {
  const { snack, trigger } = CustomSnackbar();
  const { back } = useNavigator();

  const { editAcionamento } = useSelector((state) => state.editAcionamento);

  const {
    handleModal,
    useModal,
    searchAddressByCep,
    setFormValues,
    sendMailTrigger,
    updateTrigger,
    formValuesRef,
    useLoading,
    usePatient,
    updatePatient,
  } = usePredictaForm(editAcionamento);

  return (
    <Container>
      <CustomDivider space={10} />
      <StyledContainer>
        <CustomIcon
          size={32}
          onClick={() => back()}
          icon='ArrowBack'
          color='default'
        />

        <StyledContainerContent>
          <div>
            <StyledPageTitle>
              <CustomText color='dark' size='xlarge'>
                Detalhes do acionamento
              </CustomText>
            </StyledPageTitle>

            <CustomDivider space={10} />

            <BoxInfo
              value={displayValue(productQueue[editAcionamento?.queue], '')}
              text='Produto:'
            />

            <CustomDivider space={10} />

            <StyledPatientDataMobile>
              <PatientData
                data={(usePatient?.observations || []).map((e) => e.text)}
                onSave={async (observations = [], messageType) => {
                  const dataToUpdate = observations.map((text) => ({ text }));

                  const updateResponseStatus = await updatePatient({
                    observations: dataToUpdate,
                  });

                  const triggerType = updateResponseStatus
                    ? 'success'
                    : 'danger';

                  trigger({
                    message: snackBarMessageTypes[messageType][triggerType],
                    type: triggerType,
                    duration: 3000,
                  });
                }}
              />
            </StyledPatientDataMobile>

            <CustomDivider space={10} />

            <Formik
              initialValues={{
                cep: editAcionamento.streetCep
                  ? displayValue(editAcionamento.streetCep.replace('-', ''))
                  : '',
                phone: displayValue(
                  parsePhoneNumber(editAcionamento?.phone),
                  ''
                ),
                state: displayValue(editAcionamento?.state, ''),
                city: displayValue(editAcionamento?.city, ''),
                country: displayValue(editAcionamento?.country, ''),
                socialName: displayValue(editAcionamento?.socialName, ''),
              }}
              validationSchema={formSchema}
              onSubmit={() => {
                handleModal(true);
              }}
              innerRef={formValuesRef}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                dirty,
              }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <StyledRow>
                    <CustomInput
                      value={displayValue(
                        formatDate(editAcionamento?.appointment)
                      )}
                      title='Data de agendamento'
                      disabled
                      full
                    />

                    <CustomInput
                      value={
                        maskedDocumentTypes.includes(
                          editAcionamento?.documentType
                        )
                          ? formatDocumentMask(
                              editAcionamento?.documentType,
                              editAcionamento?.document
                            )
                          : displayValue(editAcionamento?.document)
                      }
                      style='outlined'
                      title={`Documento (${editAcionamento?.documentType})`}
                      disabled
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      value={displayValue(editAcionamento?.identify_queue)}
                      style='outlined'
                      title='Serviço'
                      disabled
                      full
                    />

                    <CustomInput
                      value={displayValue(editAcionamento?.medical_record)}
                      style='outlined'
                      title='Prontuário'
                      disabled
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      value={displayValue(editAcionamento?.convenio)}
                      style='outlined'
                      title='Convênio'
                      disabled
                      full
                    />

                    <CustomInput
                      value={displayValue(editAcionamento?.name)}
                      style='outlined'
                      title='Nome'
                      disabled
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      value={displayValue(editAcionamento?.lastName)}
                      style='outlined'
                      title='Sobrenome'
                      disabled
                      full
                    />

                    <CustomInput
                      onChange={handleChange}
                      value={values.socialName}
                      hasError={errors.socialName && touched.socialName}
                      error={
                        touched?.socialName &&
                        errors?.socialName?.replace('"', '')
                      }
                      name='socialName'
                      style='outlined'
                      title='Nome Social'
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      value={displayValue(editAcionamento?.gender)}
                      style='outlined'
                      title='Sexo'
                      disabled
                      full
                    />

                    <CustomInput
                      value={displayValue(
                        formatDate(editAcionamento?.birthDate)
                      )}
                      style='outlined'
                      title='Data de aniversário'
                      disabled
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      onChange={handleChange}
                      value={values.phone}
                      hasError={errors.phone && touched.phone}
                      error={touched?.phone && errors?.phone?.replace('"', '')}
                      name='phone'
                      style='outlined'
                      title='Telefone'
                      mask='(99) 999999999'
                      full
                    />

                    <CustomInput
                      value={displayValue(editAcionamento?.email)}
                      style='outlined'
                      title='E-mail'
                      disabled
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      disabled
                      onRequestCallback={async (cep) => {
                        const cepData = await searchAddressByCep(cep);

                        setFormValues(cepData, setFieldValue);
                      }}
                      onChange={handleChange}
                      value={values.cep}
                      name='cep'
                      style='outlined'
                      title='CEP'
                      type='CEP'
                      full
                    />

                    <CustomInput
                      disabled
                      onChange={handleChange}
                      value={values.city}
                      name='city'
                      style='outlined'
                      title='Cidade'
                      full
                    />
                  </StyledRow>

                  <StyledRow>
                    <CustomInput
                      disabled
                      onChange={handleChange}
                      value={values.state}
                      name='state'
                      style='outlined'
                      title='Estado'
                      full
                    />

                    <CustomInput
                      disabled
                      onChange={handleChange}
                      value={values.country}
                      name='country'
                      style='outlined'
                      title='País'
                      full
                    />
                  </StyledRow>

                  <StyledRow align='start' bottom={1}>
                    <CustomInput
                      disabled
                      title='Link da videoconferência'
                      style='outlined'
                      value={editAcionamento?.urlAttendanceVonage || ''}
                      full
                    />

                    <StyledRow align='start'>
                      <StyledButtonsWrapper>
                        <CustomClipboard
                          text='Copiar link'
                          value={editAcionamento?.urlAttendanceVonage || ''}
                        />
                        <CustomButton
                          onClick={async () => {
                            (await sendMailTrigger())
                              ? trigger({
                                  message: 'Email enviado com sucesso',
                                  type: 'success',
                                  duration: 3000,
                                })
                              : trigger({
                                  message: 'Falha ao enviar email',
                                  type: 'danger',
                                  duration: 3000,
                                });
                          }}
                          type='outlined'
                          text={
                            useLoading
                              ? 'Enviando e-mail'
                              : 'Reenvio por e-mail'
                          }
                          icon='EmailOutlined'
                          iconDir='l'
                        />
                      </StyledButtonsWrapper>
                    </StyledRow>
                  </StyledRow>

                  <CustomDivider space={30} height={2} border />

                  <StyledRow align='end'>
                    <CustomButton
                      onClick={() => back()}
                      size='medium'
                      color='danger'
                      type='outlined'
                      text='Cancelar'
                    />

                    <CustomButton size='medium' text='Confirmar' submit />
                  </StyledRow>
                </StyledForm>
              )}
            </Formik>
          </div>

          <StyledPatientDataDesktop>
            <PatientData
              data={(usePatient?.observations || []).map((e) => e.text)}
              onSave={async (observations = [], messageType) => {
                const dataToUpdate = observations.map((text) => ({ text }));

                const updateResponseStatus = await updatePatient({
                  observations: dataToUpdate,
                });

                const triggerType = updateResponseStatus ? 'success' : 'danger';

                trigger({
                  message: snackBarMessageTypes[messageType][triggerType],
                  type: triggerType,
                  duration: 3000,
                });
              }}
            />
          </StyledPatientDataDesktop>
        </StyledContainerContent>
      </StyledContainer>

      <CustomDivider />

      {snack}
      <CustomModalConfirmation
        onConfirm={async () => {
          handleModal(false);
          const hasTriggeredSuccess = await updateTrigger(editAcionamento._id);

          if (hasTriggeredSuccess) {
            trigger({
              message: 'Atendimento confirmado',
              type: 'success',
              duration: 1500,
            });

            await sleep(2000);

            back();
          } else {
            trigger({
              message: 'Falha ao confirmar o atendimento',
              type: 'danger',
              duration: 3000,
            });
          }
        }}
        onClose={() => handleModal(false)}
        show={useModal}
        title='Deseja confirmar o atendimento?'
      />
    </Container>
  );
};

export default PredictaForm;
