import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const historicoService = {
  GetAllHistoricos,
  GetFilterHistoricos,
  SendToCockpit,
  DownloadJSON,
};

function SendToCockpit(rowData) {
  let user = JSON.parse(window.localStorage.user);
  let {
    email,
    name,
    document,
    lastName,
    cpf,
    passport,
    centralId,
    birthDate,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    telefone,
    pais,
    regiao,
    city,
    codPais,
    createDate,
    _id,
    processing,
  } = rowData;
  if (document) document = document.replace(/\./g, '').replace('-', '');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      ...user,
      centralId: centralId,
      email: email,
      name: name,
      lastName: lastName,
      document: document,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      plano: 'PA Fisico',
      produto: 'Teletriagem',
      convenioDescricao: user.dataConvenio,
      convenio: user.dataConvenio,
      createDate: createDate,
      _id: _id,
      processing: processing,
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/historico/send`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function DownloadJSON(dataInicioCsv, dataFimCsv, page) {
  let user = JSON.parse(window.localStorage.user);
  dataInicioCsv = dataInicioCsv.replace(/\//g, '-');
  dataFimCsv = dataFimCsv.replace(/\//g, '-');

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/historico/downloadjson/${dataInicioCsv}/${dataFimCsv}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetAllHistoricos(page, qtd) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/historico/list/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetFilterHistoricos(fields, page, qtd) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(fields),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/historico/filtro/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
