import moment from 'moment';

export const formatDate = (date, schema = 'DD/MM/YYYY') => {
  if (date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').local().format(schema);
  }

  return null;
};

export const getDiff = (a, b, diff = 'minutes', exact = false) => {
  const timeA = moment(a, 'YYYY-MM-DD HH:mm:ss').local();
  const timeB = moment(b, 'YYYY-MM-DD HH:mm:ss').local();

  return timeA.diff(timeB, diff, exact);
};

export const addDuration = (
  date,
  duration,
  type = 'hours',
  schema = 'YYYY-MM-DD HH:mm:ss'
) => {
  return moment(date, schema).add(type, duration);
};

export const isHourAfter = (target, afterTime) => {
  return moment(target).isAfter(afterTime);
};

export const isHourBefore = (target, beforeTime) => {
  return moment(target).isBefore(beforeTime);
};

export const isHourBetween = (
  target,
  beforeTime,
  afterTime,
  delimiter = '()'
) => {
  const momentTarget = moment(target, 'YYYY-MM-DD HH:mm:ss')
    .local()
    .format('YYYY-MM-DD HH:mm:ss');

  return moment(momentTarget).isBetween(
    beforeTime,
    afterTime,
    undefined,
    delimiter
  );
};

export const formatLocalHour = (date) => {
  var optionsDate = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  };

  return date
    ? Intl.DateTimeFormat('pt-BR', optionsDate).format(new Date(date))
    : null;
};

export const displayValue = (value = '', customMessage) => {
  if (value) return value;

  return customMessage === '' ? '' : customMessage || 'Não encontrado(a)';
};

export const formatMask = (value, pattern) => {
  if (!value || !pattern) return '';

  let i = 0;
  const v = value.toString();

  return pattern.replace(/#/g, (_) => v[i++]);
};

export const formatDocumentMask = (doc = '', value = '') => {
  const documentType = {
    rg: '##.###.###-#',
    cpf: '###.###.###-##',
  };

  if (doc !== '' && value !== '') {
    return formatMask(value, documentType[doc.toLowerCase()] || '');
  }

  return '';
};
