import styled from 'styled-components'

export const StyledSegmentContainer = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  filter: brightness(98%);
  background: #E8EBED;
  padding: 8px 10px;
  border-radius: 4px;
`

const activeSegment = {
  background: '#fff',
  color: '#a8a8a8',
}

export const StyledSegment = styled.div`
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;

  ${({ active }) => active ? {
    ...activeSegment,
  } : {
    color: 'rgb(107, 119, 134)'
  }}
`