import React from 'react';
import {
  RecoverPasswordEntity,
  HttpBodyRecoverPassword,
} from '../../../domain/entities';
import { HttpClient } from '../../../domain/http';

export class RecoverPasswordData {
  httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async handle(body: HttpBodyRecoverPassword) {
    return await this.httpClient.handle<
      RecoverPasswordEntity,
      HttpBodyRecoverPassword
    >(body);
  }
}
