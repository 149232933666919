import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const queueItemsService = {
  getFiltredqueueItems,
};

function getFiltredqueueItems(
  id,
  page,
  pageSize,
  objectRequest,
  query,
  filterStatus,
  filterProfessionals,
  filterUnitsOfAttendance,
  codFila
) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      idRows: id,
      page: page,
      pageSize: pageSize,
      query: query,
      objectRequest: objectRequest,
      filterStatus,
      filterProfessionals,
      filterUnitsOfAttendance,
      codFila,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/filas/all`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
