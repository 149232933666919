import React, { forwardRef } from 'react';
import { IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';
import ModalDeleteAcionamento from './ModalDeleteAcionamento';
import AddBox from '@material-ui/icons/AddBox';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history } from '../helpers';
import { filaSuporteService } from '../services';
import {
  modalAlertActions,
  acionamentoSuporteActions,
  actionForwarding,
  acionamentoActionsVonage,
} from '../actions';
import { filaSuporte } from '../actions';
import { translationSupport } from '../commons';
import allAcionamentosLocal from '../helpers/acionamentosComArquivosexclusao.json';
import ModalAlert from './ModalAlert';
import moment from 'moment';
import bellSuport from './bellSuport.wav';
import { socket } from '../utils/socket';
import { isDotNotation, dotToNestedObj } from './Lib/utils/utils';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class FilaSuporte extends React.Component {
  tableRef = React.createRef();
  constructor(props) {
    //props.dispatch(acionamentoActions.getAllAcionamentos())
    super(props);
    this.state = {
      acionamentosData: [],
      modalDeleteAcionamento: false,
      dataDeleteAcionamento: {},
      inputValueReason: '',
    };
    this.handlerModalDeleteAcionamento =
      this.handlerModalDeleteAcionamento.bind(this);
    this.handleChangeStatusSupportStart =
      this.handleChangeStatusSupportStart.bind(this);
    this.startSupport = this.startSupport.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(filaSuporte.paginateFilaSuporte());
    localStorage.setItem('playalertsupport', true);
  }

  handleDeleteAcionamento = (id, reason) => {
    this.props.dispatch(filaSuporte.deleteAcionamentosSuporte(id, reason));
  };

  handleChangeStatusSupportStart = async (data) => {
    await this.startSupport(data);
  };

  startSupport = (data) => {
    const objectForFoward = {
      origin: {
        channel: 'acionamentos',
        received: 'central',
        from: '',
      },
      originProfessional: {
        name: '',
        id: '',
      },
    };
    const localUser = JSON.parse(localStorage.getItem('user'));
    objectForFoward.origin.from = localuser?.user?.id_perfil?.descricao;
    objectForFoward.originProfessional.name = localUser.user.nome;
    objectForFoward.originProfessional.id = localUser.user._id;
    const dataForFowarding = { ...data, ...objectForFoward };
    this.props.dispatch(actionForwarding.startSupport(dataForFowarding));
  };

  handleEditAcionamento = (acionamento) => {
    this.handleChangeStatusSupportStart(acionamento);
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    this.props.dispatch(
      acionamentoSuporteActions.editScreenAcionamentosSupport({
        ...acionamento,
        editionTime,
      })
    );
  };

  getProfile() {
    const user = JSON.parse(window.localStorage.user);
    return user?.user?.id_perfil?.descricao;
  }
  handleOpenLink = (url, data) => {
    data.date = data.birthDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let confirmedTime = new Date(Date.now() - tzoffset).toISOString();
    if (data.convenio !== '' && data.name) {
      this.props.dispatch(
        acionamentoActionsVonage.attendanceAcionamentosSuport(
          { ...data, confirmedTime },
          this
        )
      );
    }
  };

  componentWillMount() {
    const audioSup = new Audio(bellSuport);
    const user = JSON.parse(window.localStorage.user);
    const perfil = user?.user?.id_perfil?.descricao;
    const location = window.location.pathname;
    const profileOn = perfil == 'Suporte' || perfil == 'Gestor';
    socket.on('changeData', (payload) => {
      const { operationType, fulldocument } = payload;

      if (profileOn && this.tableRef) {
        this.tableRef.current.onQueryChange();
      }

      if (operationType === 'update') {
        let { updatedFields } = payload?.updateDescription;
        const update = fulldocument
          ? fulldocument
          : isDotNotation(updatedFields)
          ? dotToNestedObj(updatedFields)
          : updatedFields;

        if (
          profileOn &&
          update.currentEditionAttendanceInfo?.attendanceType ===
            'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT' &&
          location === '/suporte' &&
          this.tableRef
        ) {
          console.log('SOMMM', this.tableRef);
          audioSup.play();
        }
      }
    });
  }

  componentWillUnmount() {
    //clearInterval(this.intervalID);
    this.tableRef = false;
    // this.props.dispatch(acionamentoActions.editAcionamentosWithoutRedirect())
  }

  handlerModalDeleteAcionamento(oldData) {
    const { modalDeleteAcionamento } = this.state;
    this.setState({
      modalDeleteAcionamento: !modalDeleteAcionamento,
      dataDeleteAcionamento: oldData,
    });
  }

  onEditClick() {
    history.push('/adm/acionamento');
  }

  deleteAcionamento(data, oldData, reason) {
    const index = data.indexOf(oldData);
    data.splice(index, 1);
    if (this.state.inputValueReason !== '') {
      this.setState(
        { data, acionamentosData: data, inputValueReason: '' },
        () => this.handleDeleteAcionamento(oldData._id, reason)
      );
      this.handlerModalDeleteAcionamento();
    }
  }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }

  handleCloseNewModal() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({
      openModalAlert: false,
      messageModalAlert: '',
      loadingsub: false,
    });
  }

  async fetchSupportHistory({ pageSize, page, search }) {
    let result = await filaSuporteService.getQueueSuport(
      pageSize,
      page + 1,
      search
    );
    let arr = result.docs.map((element) => {
      element.reason = translationSupport[element.reason];
      element.origin = element.origin.from;
      element.entradaRecepcao = moment(
        element.createDate,
        'YYYY-MM-DDT HH:mm'
      ).format('DD/MM/YYYY HH:mm');
      element.entradaSuporte = moment(
        element.entradaSuporte,
        'YYYY-MM-DDTHH:mm'
      ).format('DD/MM/YYYY HH:mm');
      return { ...element };
    });
    return {
      data: arr,
      page: result.page - 1,
      totalCount: result.total,
    };
  }

  render() {
    const { filaSuporte, openModalAlert, typeModalAlert, messageModalAlert } =
      this.props;
    const { modalDeleteAcionamento, dataDeleteAcionamento, inputValueReason } =
      this.state;

    let columns = [
      { title: 'Nome', field: 'name' },
      { title: 'Nome Social', field: 'socialName' },
      { title: 'Documento', field: 'document' },
      { title: 'Contrato', field: 'convenioDescricao' },
      { title: 'Idade', field: 'idade' },
      { title: 'Origem', field: 'origin' },
      { title: 'Data Entrada', field: 'entradaRecepcao' },
      { title: 'Data Suporte', field: 'entradaSuporte' },
      { title: 'Motivo', field: 'reason' },
    ];

    const data = filaSuporte
      ? filaSuporte.length >= 1
        ? filaSuporte
        : []
      : allAcionamentosLocal;

    return (
      <React.Fragment>
        <ModalDeleteAcionamento
          open={modalDeleteAcionamento}
          close={this.handlerModalDeleteAcionamento}
          dataDeleteAcionamento={dataDeleteAcionamento}
          onSubmit={() =>
            this.deleteAcionamento(
              data,
              dataDeleteAcionamento,
              inputValueReason
            )
          }
          inputValueReason={inputValueReason}
          dataAcionamento={data}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
        />

        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          handleCancel={(typeModalAlert, messageModalAlert) =>
            this.handleCloseNewModal(typeModalAlert, messageModalAlert)
          }
        />

        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          options={{
            loadingType: 'linear',
            debounceInterval: 1000,
          }}
          title=''
          columns={columns}
          data={(query) => this.fetchSupportHistory(query)}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Ótimo, não há paciente esperando.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse acionamento?',
              },
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => ({
              icon: VideoCallIcon,
              tooltip: 'VideoCall',
              disabled: !rowData.urlCall,
              hidden: !rowData.urlCall,
              onClick: (event, rowData) =>
                this.handleOpenLink(rowData.urlCall, rowData),
            }),
            (rowData) => ({
              icon: Edit,
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                this.handleEditAcionamento(rowData);
              },
            }),
          ]}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { filaSuporte } = state.filaSuporte;
  const { message, type, open } = state.alert;
  const { openModalAlert, typeModalAlert, messageModalAlert } =
    state.modalAlert;

  return {
    message,
    type,
    open,
    filaSuporte,
    openModalAlert,
    typeModalAlert,
    messageModalAlert,
  };
}

FilaSuporte.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(FilaSuporte);
export { connectedLoginPage as FilaSuporte };
