import React from 'react'

import { CustomStyledText } from './styles'

const CustomText = ({ children, size, color, title, style = {} }) =>
  <CustomStyledText
    size={size}
    $color={color}
    $title={title}
    style={{ ...style }}
  >
    {children}
  </CustomStyledText>

export default CustomText
