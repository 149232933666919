import FormInput from '../../../FormInput';

interface PurchaseCodeFieldProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  error?: string;
  touched?: boolean;
  name: string;
}

export function PurchaseCodeField({
  onBlur,
  onChange,
  name,
  error,
  touched,
  ...props
}: PurchaseCodeFieldProps) {


  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange !== 'function') {
      evt.stopPropagation();
      return evt.preventDefault();
    }

    const value = evt.target.value;

    if (!value) return onChange(evt);


    const hasAllowedLength = value.length <= 6;

    return onChange(evt);

  };

  return (
    <FormInput
      {...(props as any)}
      id={name}
      name={name}
      handleChange={handleChange}
      autoComplete='off'
      autoCorrect='off'
      type='text'
      color='1px solid #ff2424'
    />
  );
}
