export const isPauseAlertToCurrentUser = (pause) => {
  const userLocalStorage = localStorage.getItem('user');

  const userData = JSON.parse(userLocalStorage);

  if (userData.user._id) {
    return (
      userData.user._id === pause?.userId && pause?.status === 'notificado'
    );
  }

  return false;
};
