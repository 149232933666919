import { useState, useEffect } from 'react'

const usePopover = () => {
  const [useAnchor, setAnchor] = useState(null)

  const handleClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  useEffect(() => {
    return () => {
      if (useAnchor) {
        setAnchor(null)
      }
    }
  }, [])
  

  return {
    handleClose,
    handleClick,
    useAnchor,
  }
}

export default usePopover
