import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GetApp from '@material-ui/icons/GetApp';
import { validations } from '../helpers/validations';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../helpers';
import ModalConvenio from '../components/ModalConvenio';
import Loader from 'react-loader-spinner';
import { filaTelepsicologiaService } from '../services';
import { filaTelepsicologia } from '../actions';
import * as paparse from 'papaparse';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { isoToDate, stringSplit } from '../utils/utils';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStylesFilterComponent = (theme) => ({
  internalPadding: {
    padding: '0 40px',
  },

  titlePage: {
    padding: '12px 0',
    fontSize: '1.3em',
    fontWeight: '600',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  root: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },

  textField: {
    width: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },

  dateField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 500,
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
  },

  search: {
    display: 'flex',
    alignItems: 'center',
  },

  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },

  button: {
    cursor: 'pointer',
  },
});

class FilaTelepsicologia extends React.Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showModalDownloadCsv: false,
      dataInicioCsv: '',
      dataFimCsv: '',
      showLoader: false,
      dataInicioCsvValida: false,
      dataFimCsvValida: false,
      periodoCsvValido: false,
      pageList: 1,
      itemsByPage: 5,
      agendaTelepsicologia: [],
    };
    this.changeValueCsvInicio = this.changeValueCsvInicio.bind(this);
    this.changeValueCsvFim = this.changeValueCsvFim.bind(this);
    this.downloadCsvModal = this.downloadCsvModal.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
  }

  handleEditQueue = (rowData) => {
    this.props.dispatch(
      filaTelepsicologia.editAgendamentosTPisicol({ ...rowData })
    );
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  handleAgendamentoTelepsicologia = (url) => {
    this.props.dispatch(filaTelepsicologia.newAgendamentoTPsicol());
  };

  componentWillMount() {
    this.intervalID = setInterval(() => {
      if (this.tableRef.current) this.tableRef.current.onQueryChange();
    }, 10000);
  }

  componentDidMount() {}

  downloadCsvModal() {
    this.setState({ modalCsvIsOpen: true });
  }

  changeValueCsvInicio(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataInicioCsvValida: validations.validationCsvDate(value),
    });
  }

  changeValueCsvFim(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataFimCsvValida: validations.validationCsvDate(value),
    });
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        id='formatted-text-mask-input'
        placeholder='Data de criação'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  convertCsv(array) {
    array = array.map((arr) => {
      if (arr['deviceData']) {
        arr.deviceDataDeviceType = arr['deviceData']['deviceType'];
        arr.deviceDataOS = arr['deviceData']['OS'];
        arr.deviceDataNavigator = arr['deviceData']['navigator'];
        arr.deviceDataRoute = arr['deviceData']['route'];
        arr.deviceDataDeviceBrand = arr['deviceData']['deviceBrand'];
        arr.deviceDataAppVersion = arr['deviceData']['appVersion'];
        arr.deviceDataAccessChannel = arr['deviceData']['accessChannel'];
      }

      if (arr['confirmationUser']) {
        arr.confirmationUserEmail = arr['confirmationUser']['email'];
        arr.confirmationUserLogin = arr['confirmationUser']['login'];
        arr.confirmationUserNome = arr['confirmationUser']['nome'];
        arr.confirmationUserId = arr['confirmationUser']['_id'];
      }

      if (arr['cancelUser']) {
        arr.cancelUserEmail = arr['cancelUser']['email'];
        arr.cancelUserLogin = arr['cancelUser']['login'];
        arr.cancelUserNome = arr['cancelUser']['nome'];
        arr.cancelUserTelefone = arr['cancelUser']['telefone'];
        arr.cancelUserID = arr['cancelUser']['_id'];
      }
      return arr;
    });
    let perseCSV = paparse;
    let response = perseCSV.unparse({
      fields: [
        'email',
        'createDate',
        'convenio',
        'convenioDescricao',
        'name',
        'lastName',
        'document',
        'documentType',
        'rg',
        'birthDate',
        'phone',
        'gender',
        'nationality',
        'naturalness',
        'state',
        'country',
        'codPais',
        'confirmedTime',
        'editionTime',
        'externalId',
        'confirmationUserId',
        'confirmationUserEmail',
        'confirmationUserNome',
        'confirmationUserLogin',
        'idUserAdm',
        'confirmed',
        'passage',
        'prontuario',
        'createPassage',
        'sendCockPit',
        'idMotivoTriagem',
        'motivoTriagem',
        'idVida',
        'numberCard',
        'city',
        'startTime',
        'idClient',
        'reason',
        'deviceDataDeviceType',
        'deviceDataOS',
        'deviceDataNavigator',
        'deviceDataRoute',
        'deviceDataDeviceBrand',
        'deviceDataAppVersion',
        'deviceDataAccessChannel',
        'cancelUserId',
        'cancelUserEmail',
        'cancelUserNome',
        'cancelUserLogin',
        'cancelUserTelefone',
      ],
      data: array,
    });

    response = response.replace(/undefined/g, '');
    response = response.replace(/null/g, '');
    var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'historicocsv.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    /*return json2csv(array,function(error,response){
        response = response.replace(/undefined/g,'')
        response = response.replace(/null/g,'')
        var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement("a");
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "historicocsv.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })*/
  }

  resolvePageDownload(dataInicioCsv, dataFimCsv, page, pages) {
    return new Promise((resolve, reject) => {
      filaTelepsicologia
        .downloadJson(dataInicioCsv, dataFimCsv, page)
        .then(async (res) => {
          page = page <= pages ? page : pages;
          let percentage = parseInt(((page / pages) * 100).toFixed(0));
          this.setState({ loaderPercent: percentage });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resolvePageDownloadWithoutExecutePromise(
    dataInicioCsv,
    dataFimCsv,
    page,
    pages
  ) {
    return filaTelepsicologia.downloadJson(dataInicioCsv, dataFimCsv, page);
  }

  async submitCsvModal() {
    let { dataInicioCsv, dataFimCsv } = this.state;
    let periodoInvalido = validations.validationCsvRangeDate(
      dataInicioCsv,
      dataFimCsv
    );

    if (periodoInvalido) {
      this.setState({ periodoCsvValido: true });
    } else {
      this.setState({ errorCsv: false, showLoader: true });
      let items = [];
      let hasError = false;
      let firstPage = await this.resolvePageDownload(
        dataInicioCsv,
        dataFimCsv,
        1,
        1
      ).catch((err) => {
        this.setState({ loaderPercent: 0, showLoader: false, errorCsv: true });
      });
      let { page = 0, pages = 0, limit = 0, docs = [] } = firstPage;
      // items = items.concat(docs)
      for (let doc of docs) {
        items.push(doc);
      }
      let arrayPromises = [];
      do {
        page++;
        arrayPromises.push(
          this.resolvePageDownloadWithoutExecutePromise(
            dataInicioCsv,
            dataFimCsv,
            page,
            pages
          )
        );
      } while (page <= pages);
      await Promise.all(arrayPromises)
        .then((values) => {
          for (let value of values) {
            let { docs } = value;
            for (let doc of docs) {
              items.push(doc);
            }
          }
        })
        .catch((err) => {
          hasError = true;
          this.setState({
            periodoCsvValido: false,
            loaderPercent: 0,
            showLoader: false,
            dataInicioCsv: '',
            dataFimCsv: '',
            errorCsv: true,
          });
        });

      if (!hasError) {
        this.convertCsv(items);
        this.setState({
          periodoCsvValido: false,
          loaderPercent: 0,
          showLoader: false,
          dataInicioCsv: '',
          dataFimCsv: '',
          errorCsv: false,
        });
      }
    }
  }

  handleOpenModalDownloadCSV = () => {
    let { showModalDownloadCsv } = this.state;
    if (showModalDownloadCsv) {
      return this.setState({ showModalDownloadCsv: false });
    }
    return this.setState({ showModalDownloadCsv: true });
  };

  render() {
    let { classes } = this.props;
    let { pageList, itemsByPage, showModalDownloadCsv, showLoader } =
      this.state;
    // const data = fila.docs

    const columns = [
      { title: 'Nome ', field: 'name' },
      { title: 'Contrato', field: 'convenioDescricao' },
      { title: 'E-mail', field: 'email' },
      { title: 'Consultas', field: 'consultas' },
      { title: 'Sessão', field: 'currentSession' },
      { title: 'Profissional', field: 'doctorRefName' },
      { title: 'Programa', field: 'program' },
      { title: 'Data', field: 'schedulingDate' },
      { title: 'Horário', field: 'schedulingTime' },
      { title: 'Status', field: 'status' },
    ];

    return (
      <div className={classes.internalPadding}>
        <ModalConvenio
          open={showModalDownloadCsv}
          close={() => this.handleOpenModalDownloadCSV()}
          component={
            <React.Fragment>
              {showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Loader type='Oval' color='#ccc' height={60} width={60} />
                </div>
              )}
              {!showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    label='De'
                    margin='normal'
                    name='dataInicioCsv'
                    value={this.state.dataInicioCsv}
                    onChange={this.changeValueCsvInicio}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                  <TextField
                    label='Até'
                    margin='normal'
                    name='dataFimCsv'
                    value={this.state.dataFimCsv}
                    onChange={this.changeValueCsvFim}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                </div>
              )}
              {this.state.periodoCsvValido && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  O periodo entre as datas não pode ultrapassar 3 meses
                </div>
              )}
              {this.state.errorCsv && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  Erro ao baixar csv, tente novamente mais tarde
                </div>
              )}
            </React.Fragment>
          }
          message='Selecione o periodo de extração'
          onSubmit={async () => {
            await this.submitCsvModal();
          }} //}
        />

        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          options={{
            loadingType: 'linear',
          }}
          title='Fila Telepsicologia'
          columns={columns}
          data={(query) =>
            new Promise(async (resolve) => {
              let result = await filaTelepsicologiaService.paginateAllQueue(
                query.pageSize,
                query.page + 1,
                query.search
              );

              result.docs.forEach((element) => {
                //   element.schedulingTime = stringSplit(
                //     isoToDate(element.schedulingDateTime),
                //     ' ',
                //     1
                //   );
                //element.consultas = 10 + String.fromCharCode(176);

                element.name =
                  element.name.trim() + ' ' + element.lastName.trim();
                element.schedulingDate = moment(
                  stringSplit(isoToDate(element.schedulingDateTime), ' ', 0)
                ).format('DD/MM/YYYY');
              });

              resolve({
                data: result.docs,
                page: result.page - 1,
                totalCount: result.total,
              });
            })
          }
          // data={data}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Ótimo, não há paciente esperando.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse acionamento?',
              },
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => ({
              icon: Edit,
              tooltip: 'Editar',
              disabled: rowData.status == 'Reagendada',
              onClick: (event, rowData) => {
                this.handleEditQueue(rowData);
              },
            }),
            {
              icon: AddBox,
              isFreeAction: true,
              tooltip: 'Adicionar Agendamento',
              onClick: (event, rowData) => {
                this.handleAgendamentoTelepsicologia(null, rowData._id);
              },
            },
            {
              icon: GetApp,
              isFreeAction: true,
              tooltip: 'Download Activities',
              // onClick: () => history.push('/adm/acionamento')
              onClick: (event, rowData) => {
                this.handleOpenModalDownloadCSV();
              },
            },
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { fila } = state.filaTelepsicologia;
  const { message, type, open } = state.alert;
  return {
    message,
    type,
    open,
    fila,
  };
}

FilaTelepsicologia.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(FilaTelepsicologia)
);
export { connectedLoginPage as FilaTelepsicologia };
