import { suporteConstants } from '../constants';

export function filaSuporte(state = { filaSuporte: {} }, action) {
  switch (action.type) {
    case suporteConstants.SUPORT_SUCCESS:
      return {
        type: 'success',
        filaSuporte: action.suporte,
      };
    case suporteConstants.SUPORT_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        filaSuporte: {},
      });
    case suporteConstants.SUPORT_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
