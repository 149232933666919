import { useState, useEffect } from 'react';

const useDatePicker = (value, reset) => {
  const [valueDate, setValueDate] = useState(value);

  useEffect(() => {
    if (value.length === 2 && reset) {
      setValueDate(value);
    }

    if (value.length === 0) {
      setValueDate([]);
    }
  }, [value]);

  return {
    valueDate,
    setValueDate,
  };
};

export default useDatePicker;
