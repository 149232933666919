import { socketConstants, socketConstantsTime } from '../constants';

export const socketInfos = {
  socketTransportInfos,
  socketTransportTime,
};

function socketTransportInfos(payload) {
  return (dispatch) => {
    dispatch(success(payload));
  };

  function success(result) {
    return { type: socketConstants.SOCKETINFOS_SUCCESS, result };
  }
}
function socketTransportTime(payload) {
  return (dispatch) => {
    dispatch(success(payload));
  };

  function success(time) {
    return { type: socketConstantsTime.SOCKETINFOS_SUCCESS_TIME, time };
  }
}
