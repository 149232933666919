import { subMenuConstants } from '../constants';

export function subMenuItems(state = { subMenuItems: [] }, action) {
  switch (action.type) {
    case subMenuConstants.SUBMENU_SUCCESS:
      return {
        type: 'success',
        subMenuItems: action.result,
      };
    case subMenuConstants.SUBMENU_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        subMenuItems: {},
      });
    case subMenuConstants.SUBMENU_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}

export function subMenuItemsBySocket(
  state = { subMenuItemsBySocket: [] },
  action
) {
  switch (action.type) {
    case subMenuConstants.SUBMENU_SUCCESS_BY_SOCKET:
      return {
        type: 'success',
        subMenuItemsBySocket: action.result,
      };

    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
