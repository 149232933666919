import React from 'react';
import DialogSimpleModal from '../DialogSimpleModal/';
import SucessIcon from '../../icons/success.svg';
import WarnginIcon from '../../icons/warning.svg';
import ErrorIcon from '../../icons/error-icon.svg';
import { CircularProgress } from '@material-ui/core';
import { ContainerModal } from './styles';

export const CustomAlert = ({
  typeModal,
  open,
  handleCancel,
  handleConfirm,
  message,
}) => {
  const objectModal = {
    empty: <div></div>,

    loading: (
      <DialogSimpleModal open={open} handleCancel={handleCancel} text='' simple>
        <ContainerModal>
          {' '}
          <div>
            <CircularProgress color='primary' />
          </div>
          <div></div>
        </ContainerModal>
      </DialogSimpleModal>
    ),

    dialog: (
      <DialogSimpleModal
        open={open}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        footerpos='center'
        text=''
      >
        <ContainerModal>
          {' '}
          <div>
            Tem certeza que deseja <br /> desvincular o número?
          </div>
        </ContainerModal>
      </DialogSimpleModal>
    ),

    success: (
      <DialogSimpleModal
        message={message}
        open={open}
        handleCancel={handleCancel}
        text=''
        simple
      >
        <ContainerModal>
          {' '}
          <div>
            <div>{message}</div>
          </div>
          <div>
            <img src={SucessIcon} alt='sucess' />
          </div>
        </ContainerModal>
      </DialogSimpleModal>
    ),

    warning: (
      <DialogSimpleModal
        open={open}
        handleCancel={handleCancel}
        text=''
        title=''
        message={message}
        footerpos='center'
        simple
      >
        <ContainerModal>
          {' '}
          <div>{message}</div>
          <div>
            <img src={WarnginIcon} alt='warning' />
          </div>
        </ContainerModal>
      </DialogSimpleModal>
    ),

    notFound: (
      <DialogSimpleModal open={open} handleCancel={handleCancel} simple>
        <ContainerModal>
          {' '}
          <div>Responsável Financeiro não encontrado.</div>
          <div>
            <img src={ErrorIcon} alt='erro' />
          </div>
        </ContainerModal>
      </DialogSimpleModal>
    ),

    error: (
      <DialogSimpleModal open={open} handleCancel={handleCancel} simple>
        <ContainerModal>
          {' '}
          <div>Não foi possível realizar a solicitação!</div>
          <div>
            <img src={ErrorIcon} alt='erro' />
          </div>
        </ContainerModal>
      </DialogSimpleModal>
    ),
  };

  const ret = objectModal[typeModal];
  return ret;
};
