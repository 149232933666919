import React from 'react';

import { CustomText, CustomDivider, CustomBackdrop } from '../';

import { MaterialCircularProgress } from './styles';

const loaderIconSize = 25;

const Loader = ({ color = 'info', fixed, children }) => (
  <>
    {fixed ? (
      <CustomBackdrop>
        {children ? (
          children
        ) : (
          <>
            <MaterialCircularProgress size={36} customColor={color} />

            <CustomDivider />

            <CustomText size='large' color='light' title={1}>
              Carregando
            </CustomText>
          </>
        )}
      </CustomBackdrop>
    ) : children ? (
      children
    ) : (
      <MaterialCircularProgress size={loaderIconSize} customColor={color} />
    )}
  </>
);

export default Loader;
