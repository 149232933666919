import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import InputMaskCustom from '../../components/Forms/InputMaskCustom';
import CustomButtom from '../../components/CustomButtom';
import IconDesvinculo from '../../icons/vinculo.svg';
import { desvinculoService } from '../../services/desvinculo.service';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import {
  Title,
  ContainerInput,
  ContainerButton,
  ContainerGeneric,
} from './styles';

import { CustomTable } from './CustomTable';

import { CustomAlert } from './CustomAlert';

class Desvinculo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hasContract: false,
      hasSearch: false,
      loading: false,
      claro: {},
      message: 'Nenhuma busca realizada',
      phone: '',
      typeModal: 'empty',
      alertMessage: '',
    };
  }

  handleClear(e) {
    this.setState({
      hasContract: false,
      hasSearch: false,
      loading: false,
      phone: '',
      claro: {},
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { phone } = this.state;

    this.setState({
      [name]: value.replace(/[^\d]+/g, ''),
    });
  }

  componentDidMount() {}
  async handlePerform(e) {
    this.setState({ open: true, typeModal: 'loading' });

    const cancelContract = await desvinculoService.cancelContract(
      this.state.phone
    );

    if (
      (cancelContract !== undefined &&
        cancelContract.toString().includes('Failed to fetch')) ||
      (cancelContract !== undefined &&
        cancelContract.toString().includes('Request timed out')) ||
      cancelContract.error
    ) {
      this.setState({
        open: true,
        typeModal: 'error',
      });
    } else {
      if (cancelContract !== undefined && cancelContract.status == 0)
        this.setState({
          open: true,
          typeModal: 'warning',
        });

      if (
        cancelContract !== undefined &&
        cancelContract.message
          .toString()
          .includes('Número não encontrado ou já desvinculado')
      ) {
        this.setState({
          open: true,
          typeModal: 'notFound',
          alertMessage: 'Número não encontrado ou já desvinculado.',
        });
      }

      if (
        (cancelContract !== undefined && cancelContract.status === 1) ||
        cancelContract.status === 0
      ) {
        this.setState({
          open: true,
          typeModal: 'success',
          alertMessage: cancelContract.message,
        });
      }
    }
  }

  handleVerify(e) {
    if (this.state.phone.length === 11)
      this.setState({ open: true, typeModal: 'dialog' });
    else this.setState({ error: true });
  }

  async getContract() {
    this.setState({
      loading: true,
    });

    if (this.state.phone) {
      const claro = await desvinculoService.getContract(this.state.phone);

      if (
        claro !== undefined &&
        claro.toString().includes('Request timed out')
      ) {
        this.setState({
          hasContract: false,
          phone: '',
          open: true,
          loading: false,
          typeModal: 'error',
        });
      }

      this.setState({
        claro: claro,
      });

      if (claro !== undefined && this.state.claro.hasOwnProperty('telefone')) {
        this.setState({
          hasContract: true,
          loading: false,
        });
      }

      if (claro !== undefined && claro.toString().includes('Failed to fetch')) {
        this.setState({
          hasContract: false,
          phone: '',
          open: true,
          loading: false,
          typeModal: 'error',
        });
      }

      if ((claro !== undefined && claro.error) || claro.stack) {
        this.setState({
          hasContract: false,
          open: true,
          loading: false,
          typeModal: 'notFound',
        });
      }
    } else {
      return this.setState({
        loading: false,
        hasContract: false,
      });
    }
  }

  handleCancel(e) {
    this.setState({
      open: false,
    });
    this.handleClear();
  }
  render() {
    const { claro, hasContract, message, open, typeModal, loading } =
      this.state;
    let { alertMessage } = this.state;

    return (
      <>
        <section>
          <CustomAlert
            open={open}
            handleCancel={(e) => this.handleCancel(e)}
            handleConfirm={(e) => this.handlePerform(e)}
            typeModal={typeModal}
            message={alertMessage}
          />
          <Title>Vínculo Claro-Empresas</Title>
          <Grid justify='center' container spacing={3}>
            <ContainerInput style={{ display: 'flex' }}>
              <InputMaskCustom
                name='phone'
                value={this.state.phone}
                mask='(99) 99999-9999'
                maskChar=' '
                placeholder='Número do telefone'
                onChange={(e) => this.handleChange(e)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginLeft: '10px' }}>
                <button
                  style={{
                    border: 0,
                    borderRadius: 15,
                    height: 30,
                    color: '#fff',
                    backgroundColor: '#409aa5',
                    fontWeight: 'bold',
                    width: 120,
                    cursor: 'pointer',
                    outline: '0',
                  }}
                  onClick={async () => {
                    await this.getContract();
                  }}
                >
                  Buscar
                </button>
              </div>
            </ContainerInput>

            <ContainerGeneric>
              <CustomTable
                message={message}
                object={claro}
                hasContract={hasContract}
                loading={loading}
              />
            </ContainerGeneric>

            {hasContract && (
              <ContainerGeneric>
                <ContainerButton>
                  <CustomButtom
                    onClick={(e) => this.handleClear(e)}
                    typeButtom='lightRounded'
                    fontSize='10px'
                    width='167px'
                    size='medium'
                  >
                    Limpar
                  </CustomButtom>
                </ContainerButton>

                <ContainerButton>
                  <CustomButtom
                    onClick={(e) => this.handleVerify(e)}
                    typeButtom='lightRounded'
                    fontSize='10px'
                    size='medium'
                    width='167px'
                    color='#FF0000'
                    border='1px solid #FF0000'
                  >
                    <img src={IconDesvinculo} alt='Desvincular' />
                    Desvincular
                  </CustomButtom>
                </ContainerButton>
              </ContainerGeneric>
            )}
          </Grid>
        </section>
      </>
    );
  }
}

export { Desvinculo };
