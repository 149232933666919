import styled from 'styled-components';

import {
  RiPauseFill,
  RiPlayFill,
  RiStopCircleLine
} from 'react-icons/ri';

import {
  FiMoreVertical
} from 'react-icons/fi';

export const InfoContainer = styled.div`
  display: none;
  padding: 4.5px 8px;
  border-radius: 4px;

  font-size: 12px;
  font-weight: medium;

  color: #fff;
  background: #8A9098;
  opacity: 0.76;

  z-index: 1;
  position: absolute;
  top: 55px;

  width: max-content;
`;

export const InfoStartContainer = styled(InfoContainer)`
  left: -45px;
`;
export const InfoPauseContainer = styled(InfoContainer)`
  left: 0px;  
`;
export const InfoStopContainer = styled(InfoContainer)`
  left: 45px;
`;

export const InfoOptionsContainer = styled(InfoContainer)`
  right: 0px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledProfileTimer = styled.button`
  border-radius: 4px;
  padding: 10px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;
`;

export const StyledProfileTimerStop = styled(StyledProfileTimer)`
  border: 1px solid ${({ isBlocked  }) => isBlocked ? '#F53748' : '#6b7786'};  
  cursor: ${({ isBlocked }) => isBlocked ? 'pointer' : 'not-allowed'};
  
  svg {
    color: ${({ isBlocked  }) => isBlocked ? '#F53748' : '#6b7786'};
  }

  :hover {
    background: ${({ isBlocked }) => isBlocked ? '#F53748' : undefined};
    svg {
      color: ${({ isBlocked }) => isBlocked ? '#f5f5f5' : '#6b7786'}; 
    }

    .stop-info-container {
      display: block;
    }
  }  
`;

export const StyledProfileTimerPause = styled(StyledProfileTimer)`
  border: 1px solid ${({ isBlocked }) => isBlocked ? '#FF8720' : '#6b7786'};
  background: ${({ selected }) => selected ? '#FF8720' : undefined};
  color: ${({ selected }) => selected ? '#f5f5f5' : '#6b7786'}; 
  cursor: ${({ isBlocked }) => isBlocked ? 'pointer' : 'not-allowed'};

  svg {
    color: ${({ isBlocked, selected }) => isBlocked && selected ? '#f5f5f5' : isBlocked ? '#FF8720' : !isBlocked && selected ? '#f5f5f5' : '#6b7786'};
  }

  :hover {
    background: ${({ isBlocked }) => isBlocked ? '#FF8720' : undefined};
    svg {
      color: ${({ isBlocked }) => isBlocked ? '#f5f5f5' : '#6b7786'}; 
    }

    .pause-info-container {
      display: block;
    }
  }  

`;

export const StyledProfileTimerStart = styled(StyledProfileTimer)`
  border: 1px solid ${({ isBlocked  }) => isBlocked ? '#6b7786' : '#378FF7'};
  background: ${({ isBlocked }) => isBlocked ? '#6b7786' : '#378FF7'};
  cursor: ${({ isBlocked }) => isBlocked ? 'not-allowed' : 'pointer'};

  :hover .start-info-container {
    display: block;
  }
`;

export const MoreVertical = styled(FiMoreVertical)`
  color: #9BA8BA;
  font-size: 20px;

  :hover {
    color: #378FF7;
  }
`

export const PlayIcon = styled(RiPlayFill)`
  color: #f5f5f5;  
  font-size: 20px;
`;

export const PauseIcon = styled(RiPauseFill)`
  font-size: 20px; 
`;

export const StopIcon = styled(RiStopCircleLine)`
  font-size: 20px;
`;

export const StyledContainer = styled.div`
  padding: 20px 0px;
  background-color: #f5f5f5;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledSchedullingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  color: #6b7786;
  background: #fff;
  position: relative;
  gap: 20px;

  padding: 5px 10px;
  margin-right: 10px;
`;

export const StyledProfileTimerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledProfileTimerTextTitle = styled.div`
  font-size: 12px;
  color: #6b7786;
`;

export const StyledProfileTimerTextSubTitle = styled.div`
  font-weight: bold;
`;

export const StyledProfileTimerSelected = styled.div`
  cursor: pointer;

  :hover {
    .options-info-container {
      display: block;
    }
  }
`;

export const StyledSelectedExpanded = styled.div`
  z-index: 1;
  position: absolute;
  top: 58px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  opacity: ${(props) => (props.selected ? '1' : '0')};
  transition: opacity 0.1s ease 0.02s;
`;

export const StyledProfileTimerPauseSelectedExpanded = styled(StyledSelectedExpanded)`
  left: ${(props) => (props.selected ? '0px' : undefined)};

`;

export const StyledProfileTimerStopSelectedExpanded = styled(StyledSelectedExpanded)`
  right: ${(props) => (props.selected ? '0px' : undefined)};
`;

export const StyledProfileTimerSelectedContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px;

  .selected-pause-container:nth-child(${(props) => props.pauseIndex ?? 0}) {
    div {
      color: ${(props) => (props.pauseIndex > 0 ? '#2196f3' : '#000')};
    }
  }
`;

export const StyledProfileTimerSelectedTextTitle = styled.div`
  padding: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
`;

export const StyledProfileTimerSelectedContentSwith = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const StyledProfileTimerSelectedContentLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`;

export const StyledProfileTimerSelectedTextSwith = styled.div`
  color: #000;
  padding-right: 8px;
`;

export const StyledProfileTimerSelectedTextLink = styled.div``;

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
