export const aligns = {
  left: {
    anchor: {
      vertical: 'center',
      horizontal: 'left'
    }, transform: {
      vertical: 'center',
      horizontal: 'right'
    }
  },

  right: {
    anchor: {
      vertical: 'center',
      horizontal: 'right'
    }, transform: {
      vertical: 'center',
      horizontal: 'left'
    }
  },

  bottom: {
    anchor: {
      vertical: 'bottom',
      horizontal: 'center'
    }, 
    transform: {
      vertical: 'top',
      horizontal: 'center',
    }
  },

  top: {
    anchor: {
      vertical: 'top',
      horizontal: 'center'
    }, 
    transform: {
      vertical: 'bottom',
      horizontal: 'center',
    }
  }
}
