import { alertContingenciaConstants } from '../constants';

export function alertContingencia(state = { open: false }, action) {
  switch (action.type) {
    case alertContingenciaConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
        open: true,
        url: action.url,
      };
    case alertContingenciaConstants.CLEAR:
      return {};
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
