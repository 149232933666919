import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filaComunicadosService } from '../../services';
import { actionNotification } from '../../actions/index';
import moment from 'moment';

import { LogHistorico } from './LogHistorico';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  LockIcon: forwardRef((props, ref) => <LockIcon {...props} ref={ref} />),
  LockOpenIcon: forwardRef((props, ref) => (
    <LockOpenIcon {...props} ref={ref} />
  )),
};

class HistoricoComunicado extends React.Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      data: {},
      open: false,
    };

    this.linkTo = this.linkTo.bind(this);
  }
  componentDidMount() {}

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  componentWillMount() {
    const { dispatch } = this.props;
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleLogHistorico(rowData) {
    this.setState({
      open: true,
      data: rowData,
      disabled: true,
    });
  }
  async handleChangeActive(rowData) {
    actionNotification.changeActive(rowData, !rowData.active, this);
  }

  linkTo() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { userLogin } = this.props;

    let columns = [
      { title: 'Titulo', field: 'title' },
      { title: 'Data', field: 'createDate' },
      { title: 'Nome', field: 'name' },
      { title: 'Perfis com acesso', field: 'string' },
    ];

    return (
      <React.Fragment>
        {this.state.open ? (
          <LogHistorico
            back={this.linkTo}
            initialValues={this.state.data}
            disabled={true}
            none={'none'}
          />
        ) : (
          <MaterialTable
            tableRef={this.tableRef}
            icons={tableIcons}
            title=''
            options={{
              loadingType: 'linear',
            }}
            columns={columns}
            data={(query) =>
              new Promise(async (resolve) => {
                let result = await filaComunicadosService.getAllComunication(
                  userLogin,
                  query.pageSize,
                  query.page + 1,
                  query.search
                );

                const filtred = result.docs.map((element) => {
                  var iconsComunications = {
                    iconName: '',
                    iconDescription: '',
                  };
                  const perfis =
                    ['', 'Recepção /'][Number(element.reception)] +
                    ['', 'Gestor /'][Number(element.manager)] +
                    ['', 'Administrativo /'][Number(element.administration)] +
                    ['', 'Suporte /'][Number(element.support)];
                  const string = perfis.slice(0, perfis.length - 1);
                  element.createDate = moment(element.startDate).format(
                    'DD/MM/YYYY - HH:mm'
                  );
                  if (element.active) {
                    iconsComunications = {
                      iconName: LockOpenIcon,
                      iconDescription: 'Desabilitar',
                    };
                  } else {
                    iconsComunications = {
                      iconName: LockIcon,
                      iconDescription: 'Habilitar',
                    };
                  }
                  element.newIcons = iconsComunications;
                  return { ...element, string };
                });
                resolve({
                  data: filtred,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              })
            }
            localization={{
              header: {
                actions: 'Ações',
              },
              body: {
                emptyDataSourceMessage: 'Não existe novos comunicados.',
                editRow: {
                  deleteText: 'Gostaria de deletar e esse acionamento?',
                },
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
              },
              pagination: {
                labelRowsSelect: 'Linhas',
                labelDisplayedRows: '{from} - {to} de {count}',
                firstTooltip: 'Voltar para a primeira página',
                previousTooltip: 'Voltar',
                nextTooltip: 'Proxima',
                lastTooltip: 'Ir para ultima página',
              },
            }}
            actions={[
              (rowData) => ({
                icon: Visibility,
                tooltip: 'visualizar',
                onClick: (event, rowData) => {
                  this.handleLogHistorico(rowData);
                },
              }),
              (rowData) => ({
                icon: rowData.newIcons.iconName,
                tooltip: rowData.newIcons.iconDescription,
                onClick: (event, rowData) => {
                  this.handleChangeActive(rowData);
                },
              }),
            ]}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const userLogin = state.authentication.user;
  const { message, type, open } = state.alert;
  return {
    message,
    type,
    open,
    userLogin,
  };
}

HistoricoComunicado.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(HistoricoComunicado);
export { connectedLoginPage as HistoricoComunicado };
