import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
0% {opacity: 0;}
100% {opacity: 1;}
`;

export const ModalOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-fill-mode: both;
`;

export const Modal = styled.div`
  background: #fff;
  //width: 474px;
  max-width: 100%;
  //height: 280px;
  width: ${(props) => (props.width ? props.width : `474px`)};
  height: ${(props) => (props.height ? props.height : `280px`)};
  max-height: 100%;
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0px 27px 41px #00000026;
  border-radius: 8px;
  opacity: 1;
  display: flex;
`;

export const CloseDiv = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
`;

export const Close = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const InnerLayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 85%;
`;

export const ChildrenContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ContainerSelect = styled.div`
  width: 202px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectTitle = styled.span`
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const ValueCheck = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 8px;
`;

export const TitleModal = styled.div`
  text-align: center;
  font-size: 24px;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
  width: 100%;
`;
export const SubtitleModal = styled.div`
  text-align: center;
  font-size: 14p;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
  letter-spacing: 0;
  color: #6b7786;
  line-height: 1.6;
  width: 100%;
`;

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
  height: 40px;
`;
export const ButtonDiv = styled.div`
  margin-right: 16px;
`;
export const ContainerButton = styled.div`
  height: 40px;
  padding-right: 10px;
  width: 100%;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
