import { REQUEST_TIMEOUT } from '../constants';
import { history } from '../helpers';
import { filterAccessByProfileDescription } from '../utils/profiles';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

/**
 * Retorna um objeto com o header do google-recaptcha
 *
 * @param {string} token - A resposta do reCaptcha V3.
 * @returns {Object} - O header do reCaptcha.
 */
const applyRecaptchaHeader = (token /** @type {string} */) => ({
  'g-recaptcha': token,
});

export const userService = {
  // ! This is not used anymore
  // login,
  // ! This is not used anymore
  // loginV2,
  loginV2Robot,
  logout,
  register,
  registerVonage,
  registerNovo,
  registerEmpAdm,
  put,
  putVonage,
  RecoverPassword,
  handleResponse,
  fetchWithTimeout,
  filterResponse,
  setPersonalizedQueues,
  getPersonalizedQueues,
  setPersonalizedStatus,
  loginWithMfa,
  selectMethodVerify,
  sentMfaCode,
  verifyMfaCode,
  ResolveUserData,
};

// ! This is not used anymore
// function login(username, password) {
//   localStorage.removeItem('user');
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({
//       username,
//       password,
//     }),
//   };

//   return fetchWithTimeout(
//     `${URL_CONECTA}/servico/login/session`,
//     requestOptions,
//     REQUEST_TIMEOUT
//   )
//     .then(handleResponse)
//     .then((user) => {
//       if (user.token) {
//         localStorage.setItem('user', JSON.stringify(user));
//       }
//       return user;
//     });
// }

async function loginWithMfa(username, password, reCaptcha) {
  localStorage.removeItem('user');

  const data = JSON.stringify({
    username,
    password,
  });

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'g-recaptcha': reCaptcha,
    },
    body: data,
    credentials: 'include',
  };

  return await fetchWithTimeout(
    `${URL_CONECTA}/servico/login/session/mfa`,
    requestOptions,
    REQUEST_TIMEOUT
  )
    .then(handleResponse)
    .then((message) => {
      return message;
    });
}

async function selectMethodVerify(method, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await fetchWithTimeout(
    `${URL_CONECTA}/servico/login/session/mfa/${method}`,
    requestOptions,
    REQUEST_TIMEOUT
  );
  return await handleResponse(response);
}

// ! This is not used anymore
// async function loginV2(username, password, recaptchaToken) {
//   localStorage.removeItem('user');
//   const requestOptions = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       ...applyRecaptchaHeader(recaptchaToken),
//     },
//     body: JSON.stringify({
//       username,
//       password,
//     }),
//   };

//   try {
//     const response = await fetchWithTimeout(
//       `${URL_CONECTA}/servico/login/sessionv2`,
//       requestOptions,
//       1000000
//     );
//     const user = await handleResponse(response);
//     const profileDescription = filterAccessByProfileDescription(
//       user?.user?.access
//     );
//     if (user.token) {
//       user.user._id = user.user._id;
//       user.user.nome = user.user.name;
//       user.user.login = user.user.username;
//       user.user.id_perfil = {
//         descricao: profileDescription,
//       };
//       user.user.telefone = user.user.phone;

//       if (user.user.health_professional_data) {
//         user.user.classificacao_cod_classe =
//           user.user.health_professional_data.class_codes[0].description.toLowerCase();
//         if (profileDescription === 'Profissional de Saúde') {
//           user.user.id_perfil.descricao = 'Médico';
//         }
//       }

//       if (profileDescription === 'Suporte Técnico') {
//         user.user.id_perfil.descricao = 'Suporte';
//       }
//       if (profileDescription === 'Gestor da Unidade de Negócio') {
//         user.user.id_perfil.descricao = 'Gestor';
//       }
//       localStorage.setItem('user', JSON.stringify(user));
//     }
//     return user;
//   } catch (error) {
//     dispatch(failure(error.toString()));
//   }
// }

function loginV2Robot(username, password) {
  localStorage.removeItem('user');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  };

  return fetchWithTimeout(
    `${URL_CONECTA}/servico/login/sessionv2`,
    requestOptions,
    1000000
  )
    .then(handleResponse)
    .then((user) => {
      const profileDescription = filterAccessByProfileDescription(
        user?.user?.access
      );
      if (user.token) {
        user.user._id = user.user.id;
        user.user.nome = user.user.name;
        user.user.login = user.user.username;
        user.user.id_perfil = {
          descricao: profileDescription,
        };
        user.user.telefone = user.user.phone;

        if (user.user.health_professional_data) {
          user.user.classificacao_cod_classe =
            user.user.health_professional_data.class_codes[0].description.toLowerCase();
          if (profileDescription === 'Profissional de Saúde') {
            user.user.id_perfil.descricao = 'Médico';
          }
        }

        if (profileDescription === 'Suporte Técnico') {
          user.user.id_perfil.descricao = 'Suporte';
        }
        if (profileDescription === 'Gestor da Unidade de Negócio') {
          user.user.id_perfil.descricao = 'Gestor';
        }
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    })
    .catch((error) => {
      dispatch(failure(error.toString()));
    });
}

function RecoverPassword(username, reCaptchaToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...applyRecaptchaHeader(reCaptchaToken),
    },
    body: JSON.stringify({
      email: username,
    }),
  };
  return fetchWithTimeout(
    `${URL_CONECTA}/servico/login/forgot`,
    requestOptions,
    REQUEST_TIMEOUT
  )
    .then(handleResponse)
    .then((message) => {
      return message;
    });
}

function logout() {
  if (window.localStorage.user) {
    const userData = JSON.parse(window.localStorage.getItem('user'));
    if (typeof userData?.user?.login === 'string') {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': userData.accessToken,
        },
        body: JSON.stringify(userData),
      };

      return fetchWithTimeout(
        `${URL_CONECTA}/servico/adm/logout`,
        requestOptions,
        REQUEST_TIMEOUT
      )
        .then(handleResponse)
        .then((message) => {
          return message;
        });
    }
  }
}

function registerEmpAdm(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  var {
    email,
    name,
    socialName,
    cabine,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    codPais,
    numberCard,
    startTime,
    streetCep,
    city,
    regiao,
    pais,
    objectModel,
    deviceData,
  } = user;

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      deviceData: deviceData,
      objectModel: objectModel,
      email: email,
      cabine: cabine,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      socialName: socialName,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: nacionalidade,
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      startTime: startTime,
      numberCard: numberCard,
      streetCep: streetCep,
      isExternalCr: true,
    }),
  };

  return fetch(
    `${URL_CONECTA}/servicoVonage/adm/acionamentos/create`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function register(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  var {
    email,
    name,
    socialName,
    cabine,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    codPais,
    numberCard,
    startTime,
    streetCep,
    city,
    regiao,
    pais,
    objectModel,
    deviceData,
  } = user;

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      deviceData: deviceData,
      objectModel: objectModel,
      email: email,
      cabine: cabine,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      socialName: socialName,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      startTime: startTime,
      numberCard: numberCard,
      streetCep: streetCep,
    }),
  };

  return fetch(
    `${URL_CONECTA}/servicoVonage/adm/acionamentos/create`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}
//Register do formulario novo
function registerNovo(user) {
  let userData = JSON.parse(window.localStorage.user);

  var {
    email,
    email2,
    name,
    socialName,
    cabine,
    lastName,
    document,
    products,
    idFila,
    date,
    nationality,
    gender,
    phone,
    phone2,
    id_requisicao_parceiro,
    convenio,
    city,
    country,
    codPais,
    numberCard,
    startTime,
    streetCep,
    state,
    objectModel,
    deviceData,
  } = user;

  convenio = convenio.split('|');

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      objectModel: objectModel,
      email: email,
      email2: email2,
      cabine: cabine,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      socialName: socialName,
      lastName: lastName,
      document: document,
      documentType: nationality === 'B' ? 'CPF' : 'PASSAPORTE',
      birthDate: date,
      phone: phone,
      phone2: phone2,
      gender: gender,
      partnerID: id_requisicao_parceiro,
      nationality: nationality,
      naturalness: null,
      city: city,
      state: state,
      deviceData: deviceData,
      country: country,
      codPais: codPais,
      startTime: startTime,
      numberCard: numberCard,
      streetCep: streetCep,
      idFila: idFila,
      products: products,
    }),
  };

  return fetch(
    `${URL_CONECTA}/servicoVonage/adm/acionamentos/create`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function registerVonage(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  var {
    email,
    email2,
    name,
    socialName,
    cabine,
    lastName,
    document,
    date,
    nationality,
    gender,
    phone,
    phone2,
    id_requisicao_parceiro,
    convenio,
    city,
    country,
    codPais,
    numberCard,
    startTime,
    streetCep,
    state,
    objectModel,
    deviceData,
  } = user;
  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      objectModel: objectModel,
      email: email,
      email2: email2,
      cabine: cabine,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      socialName: socialName,
      lastName: lastName,
      document: document,
      documentType: nationality === 'B' ? 'CPF' : 'PASSAPORTE',
      birthDate: date,
      phone: phone,
      phone2: phone2,
      gender: gender,
      partnerID: id_requisicao_parceiro,
      nationality: nationality,
      naturalness: null,
      city: city,
      state: state,
      deviceData: deviceData,
      country: country,
      codPais: codPais,
      startTime: startTime,
      numberCard: numberCard,
      streetCep: streetCep,
      descriptionRole: userData.user.id_perfil.descricao,
    }),
  };
  return fetch(
    `${URL_CONECTA}/servicoVonage/adm/acionamentos/create`,
    requestOptions
  ).then(handleResponse);
}

function putVonage(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  var {
    email,
    name,
    socialName,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    numberCard,
    streetCep,
  } = user;

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      email: email,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      socialName: socialName,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      numberCard: numberCard,
      streetCep: streetCep,
      city: city,
      state: regiao,
      country: pais,
    }),
  };
  return fetch(
    `${URL_CONECTA}/servicoVonage/adm/acionamentos/put/${user._id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function put(user) {
  let userData = window.localStorage.user;
  userData = JSON.parse(userData);
  var {
    email,
    name,
    socialName,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    numberCard,
    streetCep,
  } = user;

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      email: email,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      socialName: socialName,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      numberCard: numberCard,
      streetCep: streetCep,
      city: city,
      state: regiao,
      country: pais,
    }),
  };
  return fetch(
    `${URL_CONECTA}/servico/adm/acionamentos/put/${user._id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        history.push('/login');
        localStorage.removeItem('user');
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

async function filterResponse(response) {
  let newResponse = {};
  newResponse.statusResponse = response.status;
  let text = await response.text().then((text) => {
    const data = text && JSON.parse(text);
    return data;
  });
  const returnedTarget = Object.assign(newResponse, text);
  return returnedTarget;
}

function fetchWithTimeout(url, requestOptions, timeout) {
  return new Promise((resolve, reject) => {
    let timer = setTimeout(
      () => reject(new Error('Request timed out')),
      timeout
    );
    fetch(url, requestOptions)
      .then(
        (response) => resolve(response),

        (err) => reject(err)
      )
      .catch((err) => {
        console.log('err', err);
        reject(err);
      });
  });
}

async function setPersonalizedQueues(enabledQueues) {
  let userData = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.id,
    },
    body: JSON.stringify({
      id: userData.id,
      filas: enabledQueues,
      name: userData.name,
    }),
  };
  return fetch(
    `${URL_CONECTA}/servico/preferences/savePreferences/`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function getPersonalizedQueues() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/preferences/getPreferences?id=${user.user.id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function setPersonalizedStatus(status) {
  let userData = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userData.accessToken,
      _id: userData.user._id,
    },
    body: JSON.stringify({
      id: userData.user.id,
      status: status,
    }),
  };
  return fetch(
    `${URL_CONECTA}/servico/preferences/savePreferences/status`,
    requestOptions
  )
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function sentMfaCode(mfaMethod) {
  const urlMethod = mfaMethod === 'EMAIL' ? 'email' : 'sms';

  const url = `${URL_CONECTA}/servico/login/session/mfa/${urlMethod}`;

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
  };

  return fetchWithTimeout(url, requestOptions, REQUEST_TIMEOUT)
    .then(handleResponse)
    .then((message) => {
      return message;
    });
}

function verifyMfaCode(mfaCode) {
  const url = `${URL_CONECTA}/servico/login/session/mfa/validate/${mfaCode}`;

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
  };

  return fetchWithTimeout(url, requestOptions, REQUEST_TIMEOUT)
    .then(handleResponse)
    .then((user) => {
      const profileDescription = filterAccessByProfileDescription(
        user?.user?.access
      );
      if (user.token) {
        user.user._id = user.user._id;
        user.user.nome = user.user.name;
        user.user.login = user.user.username;
        user.user.id_perfil = {
          descricao: profileDescription,
        };
        user.user.telefone = user.user.phone;

        if (user.user.health_professional_data) {
          user.user.classificacao_cod_classe =
            user.user.health_professional_data.class_codes[0].description.toLowerCase();
          if (profileDescription === 'Profissional de Saúde') {
            user.user.id_perfil.descricao = 'Médico';
          }
        }
        if (profileDescription === 'Suporte Técnico') {
          user.user.id_perfil.descricao = 'Suporte';
        }
        if (profileDescription === 'Gestor da Unidade de Negócio') {
          user.user.id_perfil.descricao = 'Gestor';
        }
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function ResolveUserData(userData) {
  const profileDescription = filterAccessByProfileDescription(
    userData?.user?.access
  );

  if (userData.token) {
    userData.user._id = userData.user._id;
    userData.user.nome = userData.user.name;
    userData.user.login = userData.user.username;
    userData.user.id_perfil = {
      descricao: profileDescription,
    };
    userData.user.telefone = userData.user.phone;

    if (userData.user.health_professional_data) {
      userData.user.classificacao_cod_classe =
        userData.user.health_professional_data.class_codes[0].description.toLowerCase();
      if (profileDescription === 'Profissional de Saúde') {
        userData.user.id_perfil.descricao = 'Médico';
      }
    }

    if (profileDescription === 'Suporte Técnico') {
      userData.user.id_perfil.descricao = 'Suporte';
    }
    if (profileDescription === 'Gestor da Unidade de Negócio') {
      userData.user.id_perfil.descricao = 'Gestor';
    }
  }

  return userData;
}
