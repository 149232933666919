import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SelectCustom from './Forms/SelectCustom';
import { motivosSuporte } from '../commons/index';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '11px',
    color: 'red',
  },
  sucess: {
    backgroundColor: green[600],
  },
  boxdialog: {
    margin: '0 auto 0 auto',
    '@media only screen and (max-width: 900px)': {
      padding: '0 0 20px 0',
      margin: '0 auto 0 auto',
      '@media screen and (orientation: landscape)': {
        margin: '0 auto',
      },
    },
  },
  dialog_modal: {
    borderRadius: '50%',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    margin: '0 auto',
  },
  titleDialog: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'Poppins',
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
  },
  close: {
    position: 'absolute',
    width: '45px',
    top: '2px',
  },
  rootStyle: {
    borderRadius: 20,
  },
  dialogAlert: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
});

class ModalSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vertical: 'top',
      horizontal: 'center',
      currentTime: 10,
      copySuccess: '',
      maxWidth: 'xs',
      motivo: '',
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.zeraMotivo = this.zeraMotivo.bind(this);
  }

  zeraMotivo() {
    this.setState({
      motivo: '',
    });
  }
  handleChangeSelect(evt) {
    const { value } = evt.target;
    this.props.handlerChangeReason(evt);
    this.setState({
      motivo: value,
    });
  }

  render() {
    const { classes, open, close, message, onSubmit, emptyMotivo } = this.props;

    if (emptyMotivo && this.state.motivo.length > 1) {
      this.zeraMotivo();
    }

    return (
      <React.Fragment>
        <Dialog
          classes={{ paper: classes.rootStyle }}
          open={open}
          maxWidth={this.state.maxWidth}
          disableAutoFocus={true}
          title={message || ''}
          fullWidth={true}
        >
          <DialogActions>
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={close}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </DialogActions>

          <DialogTitle className={classes.titleDialog}>
            <span className={classes.titleDialog}>
              Encaminhamento para o suporte
            </span>
          </DialogTitle>

          <DialogContent>
            <SelectCustom
              value={this.state.motivo}
              onChange={this.handleChangeSelect}
              label={'Motivo'}
              placeholder={'Motivo'}
              dataSource={motivosSuporte}
            />
            <FormHelperText id='component-helper-text'>
              Escolha o motivo do encaminhamento
            </FormHelperText>
          </DialogContent>
          <DialogActions justify={'center'}>
            <Grid container justify={'center'}>
              <Fab
                onClick={close}
                variant='extended'
                aria-label='Support'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '40%',
                  height: '36px',
                  background: 'white',
                  color: 'red',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Cancelar
              </Fab>
              <Fab
                variant='extended'
                aria-label='Support'
                onClick={onSubmit}
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '40%',
                  height: '36px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Confirmar
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ModalSupport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModalSupport);
