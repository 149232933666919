export const agendamentoConstants = {
  AGENDAMENTO_REQUEST: 'AGENDAMENTO_REQUEST',
  AGENDAMENTO_SUCCESS: 'AGENDAMENTO_SUCCESS',
  AGENDAMENTO_FAILURE: 'AGENDAMENTO_FAILURE',
};

export const editAgendamentoConstants = {
  AGENDAMENTO_EDIT_REQUEST: 'AGENDAMENTO_EDIT_REQUEST',
  AGENDAMENTO_EDIT_SUCCESS: 'AGENDAMENTO_EDIT_SUCCESS',
  AGENDAMENTO_EDIT_FAILURE: 'AGENDAMENTO_EDIT_FAILURE',
};
