import { useState, useEffect } from 'react';

import { scalesService } from '../../../../services/core';

import { useNavigator } from '../../../../hooks';

import moment from 'moment';

const useEditScale = (id) => {
  const { push } = useNavigator();

  const [useError, setError] = useState(false);
  const [userName, setUserName] = useState('');
  const [entryTime, setEntryTime] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [snackBreakTime, setSnackBreakTime] = useState('');
  const [snackBreakDuration, setSnackBreakDuration] = useState(0);
  const [restBreakTime, setRestBreakTime] = useState('');
  const [restBreakDuration, setRestBreakDuration] = useState(0);
  const [dateScale, setDateScale] = useState('');
  const [unit, setUnit] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    async function fetchData() {
      const { err, data } = await scalesService.getByOne(id);

      if (!err) {
        const scale = data;

        setUserName(scale.userName);
        setEntryTime(moment(scale.entryTime).utc().format('HH:mm'));
        setDepartureTime(moment(scale.departureTime).utc().format('HH:mm'));
        setSnackBreakTime(scale.snackBreakTime);
        setSnackBreakDuration(scale.snackBreakDuration);
        setRestBreakTime(scale.restBreakTime);
        setRestBreakDuration(scale.restBreakDuration);
        setDateScale(scale.date);
        setUnit(scale.unit);
        setUserId(scale.userId);
      } else {
        setError(true);
      }
    }

    fetchData();
  }, [id]);

  const handlerSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      userName,
      entryTime: moment(entryTime, 'HH:mm').format('HH:mm'),
      departureTime: moment(departureTime, 'HH:mm').format('HH:mm'),
      snackBreakTime,
      snackBreakDuration,
      restBreakTime,
      restBreakDuration,
      date: moment(dateScale, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      unit,
      userId,
    };

    await scalesService.updateOne(id, data);

    push('/adm/scales');
  };

  return {
    handlerSubmit,
    userName,
    setUserName,
    entryTime,
    setEntryTime,
    departureTime,
    setDepartureTime,
    snackBreakTime,
    setSnackBreakTime,
    snackBreakDuration,
    setSnackBreakDuration,
    restBreakTime,
    setRestBreakTime,
    restBreakDuration,
    setRestBreakDuration,
    dateScale,
    setDateScale,
    unit,
    setUnit,
    userId,
    setUserId,
    useError,
  };
};

export default useEditScale;
