import { passwordConstants } from '../constants';
import { passwordService } from '../services';
import { alertActions } from './alert.actions';

export const passwordActions = {
  changePassword,
  changedPassword,
  openPassword,
  cancelChangePassword,
};

function changePassword() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: passwordConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success() {
    return { type: passwordConstants.CHANGE_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: passwordConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function changedPassword(password, user, handleLoader) {
  return (dispatch) => {
    request({ password });
    passwordService.changePassword(password, user).then(
      (msg) => {
        setTimeout(function () {
          dispatch(alertActions.success('Senha alterada com sucesso!'));
          dispatch(success());
          handleLoader();
        }, 5000);
      },
      (error) => {
        dispatch(alertActions.error(error.message));
        handleLoader();
        failure(error);
      }
    );
  };
  function request(password) {
    return { type: passwordConstants.PASSWORD_REQUEST, password };
  }
  function success() {
    return { type: passwordConstants.CHANGE_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: passwordConstants.PASSWORD_FAILURE, error };
  }
}

function openPassword() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { openPassword: true };
  }
}

function cancelChangePassword() {
  return (dispatch) => {
    dispatch(cancel());
  };
  function cancel() {
    return { type: passwordConstants.CHANGE_PASSWORD_CANCEL };
  }
}
