import React from 'react'

import { Popover } from '@material-ui/core'

import usePopover from './hooks/usePopover'

import { CustomIcon } from '..'

import { StyledPopoverContainer } from './styles'

import { aligns } from './constants'

const CustomPopover = ({
  children,
  align = "left"
}) => {
  const { handleClick, handleClose, useAnchor } = usePopover()

  const popoverAlign = aligns[align] || aligns.top

  return (
    <>
      <CustomIcon icon="MoreVert" color="default" onClick={handleClick} />

      <Popover
        disableScrollLock
        anchorEl={useAnchor}
        onClose={handleClose}
        open={!!useAnchor}
        anchorOrigin={popoverAlign.anchor}
        transformOrigin={popoverAlign.transform}
        onClick={() => handleClose()}
      >
        <StyledPopoverContainer>
          {children}
        </StyledPopoverContainer>
      </Popover>
    </>
  )
}

export default CustomPopover
