export const historicoConstants = {
  HISTORICO_REQUEST: 'HISTORICO_REQUEST',
  HISTORICO_SUCCESS: 'HISTORICO_SUCCESS',
  HISTORICO_FAILURE: 'HISTORICO_FAILURE',
};

export const editHistoricoConstants = {
  HISTORICO_EDIT_REQUEST: 'HISTORICO_EDIT_REQUEST',
  HISTORICO_EDIT_SUCCESS: 'HISTORICO_EDIT_SUCCESS',
  HISTORICO_EDIT_FAILURE: 'HISTORICO_EDIT_FAILURE',
};
