import React from 'react';

import { lockedMessage, sentRequestMessage, sendRequestUnlock, sendedRequestUnlock } from './constants';

import {
  CustomButton,
  CustomDivider,
  CustomText,
  CustomModal,
} from '../../../../../components/ui';

import { CustomInput } from '../../../../../components/ui/webform';

import useModalLock from './hooks/useModalLock';

import {
  StyledSubtitle,
  StyledTitle,
  StyledButtonWrapper,
  StyledWrapper,
} from './styles';

const ModalLock = ({ data }) => {
  const {
    buttonEnable,
    handleRequestBlocked,
    inputEnable,
    inputValue,
    setInputValue,
  } = useModalLock(data);



  const mountUnLockMessage = () => {
    const wasRequestedUnlock = !data.requestUnlock && !inputEnable
    return {
      message: wasRequestedUnlock ? lockedMessage : sentRequestMessage,
      unLockMessage: wasRequestedUnlock ? sendRequestUnlock : sendedRequestUnlock
    }
  }


  return (
    <CustomModal show={data.show}>
      <StyledWrapper>
        <StyledTitle>Estação de serviço bloqueada</StyledTitle>

        <CustomDivider />

        <StyledSubtitle>{mountUnLockMessage()?.message}</StyledSubtitle>

        <CustomDivider />

        <CustomInput
          value={data.motive}
          disabled={inputEnable}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          placeholder='Descreva o motivo'
          style='outlined'
          full
        />

        <CustomDivider space={1} />

        <CustomText>Este campo é obrigatório.</CustomText>

        <CustomDivider />

        <StyledButtonWrapper>
          <CustomButton
            disabled={buttonEnable}
            onClick={() => {
              handleRequestBlocked(inputValue);
            }}
            text={mountUnLockMessage()?.unLockMessage}
            size='medium'
          />
        </StyledButtonWrapper>
      </StyledWrapper>
    </CustomModal>
  );
};

export default ModalLock;
