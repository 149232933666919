import { createStore, compose, applyMiddleware } from 'redux'

import { createBrowserHistory } from 'history'

import createSagaMiddleware from 'redux-saga'

import { createLogger } from 'redux-logger'

import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import thunkMiddleware from 'redux-thunk'

import reducers from './combineReducers'

const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = []
const sagaMiddleware = createSagaMiddleware()
middlewares.push(thunkMiddleware)
middlewares.push(sagaMiddleware)

const persistConfig = {
	key: 'root',
	storage,
}

const loggerMiddleware = createLogger()
const persistedReducer = persistReducer(persistConfig, reducers(history))
const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(...middlewares, loggerMiddleware)),
)

const persistor = persistStore(store)

export { store, persistor }
