import {
  cancelamentoConstants,
  editCancelamentoConstants,
  userConstants,
  countryConstants,
  regionConstants,
  citiesConstants,
} from '../constants';
import { conveniosConstants } from '../constants';
import { cancelamentoService } from '../services';
import { conveniosService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';

export const cancelamentoActions = {
  getAllCancelamentos,
  deleteCancelamentos,
  getAllConvenios,
  confirmedCancelamentos,
  getAllCountryCancelamentos,
  getRegionsByCountry,
  getCityByRegions,
};

function confirmationCancel() {
  return (dispatch) => {};
}

function getAllCountryCancelamentos() {
  return (dispatch) => {
    dispatch(request());
    cancelamentoService
      .getCountryAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: countryConstants.COUNTRY_REQUEST };
  }

  function success(result) {
    return { type: countryConstants.COUNTRY_SUCCESS, result };
  }

  function failure(error) {
    return { type: countryConstants.COUNTRY_FAILURE, error };
  }
}

function getRegionsByCountry(region = 'nd') {
  return (dispatch) => {
    dispatch(request());
    cancelamentoService
      .getRegionsByCountry(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: regionConstants.REGION_REQUEST };
  }

  function success(result) {
    return { type: regionConstants.REGION_SUCCESS, result };
  }

  function failure(error) {
    return { type: regionConstants.REGION_FAILURE, error };
  }
}

function getCityByRegions(region = 'nda') {
  return (dispatch) => {
    dispatch(request());
    cancelamentoService
      .getCityByRegions(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: citiesConstants.CITIE_REQUEST };
  }

  function success(result) {
    return { type: citiesConstants.CITIE_SUCCESS, result };
  }

  function failure(error) {
    return { type: citiesConstants.CITIE_FAILURE, error };
  }
}

function getAllCancelamentos() {
  return (dispatch) => {
    dispatch(request());

    cancelamentoService
      .GetAllCancelamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cancelamentoConstants.CANCELAMENTO_REQUEST };
  }

  function success(cancelamentos) {
    return { type: cancelamentoConstants.CANCELAMENTO_SUCCESS, cancelamentos };
  }

  function failure(error) {
    return { type: cancelamentoConstants.CANCELAMENTO_FAILURE, error };
  }
}

function confirmedCancelamentos(cancelamento) {
  var tempData = cancelamento.birthDate.split('/');
  cancelamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());

    cancelamentoService
      .confirmedCancelamentos(cancelamento)
      .then(
        (body) => {
          history.push('/adm');
          dispatch(success(body));
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }

  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function deleteCancelamentos(id, reason) {
  return (dispatch) => {
    dispatch(request());

    cancelamentoService
      .deleteCancelamentos(id, reason)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cancelamentoConstants.CANCELAMENTO_REQUEST };
  }

  function success() {
    return { type: cancelamentoConstants.CANCELAMENTO_SUCCESS };
  }

  function failure(error) {
    return { type: cancelamentoConstants.CANCELAMENTO_FAILURE, error };
  }
}

// function editCancelamentos(cancelamento) {
//     return dispatch => {
//         dispatch(request(cancelamento));
//         if (cancelamento) {
//             dispatch(success(cancelamento));
//         } else {
//             dispatch(failure());
//         }
//         history.push('/adm/cancelamento');
//     };

//     function request() { return { type: editCancelamentoConstants.CANCELAMENTO_EDIT_REQUEST } }

//     function success(cancelamento) { return { type: editCancelamentoConstants.CANCELAMENTO_EDIT_SUCCESS, cancelamento } }

//     function failure() { return { type: editCancelamentoConstants.CANCELAMENTO_EDIT_FAILURE } }
// }

function getAllConvenios() {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }

  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }

  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}
