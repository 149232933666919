import { ReportQueueRecordsState } from '../../../../domain/state';
import { QueueRecordEntity } from '../../../../domain/entities';
import { dispatch, selector } from '../../../../application/store/root';
import {
  setQueueRecordReport,
  setLoading,
  setError,
  setErrorMessage,
  setModal,
  setDateFrom,
  setDateUntil,
} from './report-slice';

export class ReportQueueRecordsStore {
  dispatch = dispatch();

  reportQueueRecordsSelector: ReportQueueRecordsState = selector(
    (state) => state.reportQueueRecords
  );

  handleQueueRecordReport = (report: QueueRecordEntity[]) => {
    this.dispatch(setQueueRecordReport(report));
  };

  handleLoading = (loading: boolean) => {
    this.dispatch(setLoading(loading));
  };

  handleError = (error: boolean) => {
    this.dispatch(setError(error));
  };

  handleErrorMessage = (errorMessage: string) => {
    this.dispatch(setErrorMessage(errorMessage));
  };

  handleModal = (modal: boolean) => {
    this.dispatch(setModal(modal));
  };

  handleDateFrom = (dateFrom: Date | string) => {
    this.dispatch(setDateFrom(dateFrom));
  };

  handleDateUntil = (dateUntil: Date | string) => {
    this.dispatch(setDateUntil(dateUntil));
  };
}
