import { loadingHistoricoConstants } from '../constants';

export function historicoLoader(state = {}, action) {
  switch (action.type) {
    case loadingHistoricoConstants.HISTORICO_LOADING_SUCCESS:
      return {
        loading: false,
      };
    case loadingHistoricoConstants.HISTORICO_LOADING_FAILURE:
      return Object.assign({}, state, {
        loading: false,
      });
    case loadingHistoricoConstants.HISTORICO_LOADING_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    default:
      return Object.assign({}, state, {
        loading: false,
      });
      tate;
  }
}
