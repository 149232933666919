import React from 'react';

import {
  CustomText,
  CustomModal,
  CustomDivider,
  CustomButton,
} from '../../../../../components/ui';

import useCountdown from './hooks/useCountdown';

import { isStationLocked, hasTimeExpired, calculateFraction } from './utils';

import {
  StyledContainer,
  StyledCountDownLabel,
  StyledCountdown,
} from './styles';

const ModalPause = ({ data, onPauseFinish }) => {
  const { useCountDownValue, useModal, restartStation, useLoading } =
    useCountdown(data, onPauseFinish);

  return (
    <CustomModal show={useModal}>
      <StyledContainer>
        <CustomText color='dark' title={1} size='xlarge'>
          Estação em pausa
        </CustomText>
        <CustomDivider />

        <StyledCountdown>
          <svg
            className='base-timer__svg'
            viewBox='0 0 100 100'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g className='base-timer__circle'>
              <circle
                className='base-timer__path-elapsed'
                cx='50'
                cy='50'
                r='45'
              />
              <path
                id='base-timer-path-remaining'
                strokeDasharray={
                  hasTimeExpired(data?.interval, data?.timeRest)
                    ? '283 283'
                    : calculateFraction(data?.interval, data?.timeRest)
                }
                className={
                  hasTimeExpired(data?.interval, data?.timeRest)
                    ? 'base-timer__path-done'
                    : 'base-timer__path-remaining'
                }
                d='M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0'
              />
            </g>
          </svg>

          <StyledCountDownLabel>
            <p>{useCountDownValue}</p>
            <span>
              {typeof data?.timeRest === 'number'
                ? `Total: ${data?.timeRest} min`
                : 'Sem tempo limite'}
            </span>
          </StyledCountDownLabel>
        </StyledCountdown>

        <CustomDivider />
        <CustomButton
          text={useLoading ? 'Reativando estação' : 'Reativar estação'}
          size='medium'
          disabled={isStationLocked(data?.interval, data?.timeRest)}
          loading={useLoading}
          onClick={restartStation}
        />
      </StyledContainer>
    </CustomModal>
  );
};

export default ModalPause;
