import React, { forwardRef } from 'react';

import { StyledInput } from './style';

interface InputProps {
  name?: string;
  value?: string;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (evt: React.ClipboardEvent<HTMLInputElement>) => void;
  onNavigate?: (pressedKey: string, inputName: string) => void;
  error?: boolean;
}

export const Input = forwardRef(
  (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const handleBeforeInput = (evt: React.FormEvent<HTMLInputElement>) => {
      const value = (evt as any).data || (evt as any)?.key;

      if (!AllowOnlyNumbers(value)) {
        evt.stopPropagation();
        return evt.preventDefault();
      }
    };

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      const insertedValue = value.at(-1) || '';

      const isDifferentValue = insertedValue !== props?.value;
      const isNumberValue = insertedValue && AllowOnlyNumbers(insertedValue);
      const hasAllowedLength = insertedValue.length <= 1;

      if (
        isDifferentValue &&
        (isNumberValue || insertedValue === '') &&
        hasAllowedLength &&
        typeof props.onChange === 'function'
      ) {
        props.onChange({
          ...evt,
          target: {
            ...evt?.target,
            name: evt?.target?.name,
            value: insertedValue,
          },
        });
      }

      evt.stopPropagation();
      return evt.preventDefault();
    };

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
      const value = (evt as any).data || (evt as any)?.key;

      if (typeof props.onNavigate === 'function') {
        props.onNavigate(value, props?.name || '');
      }
    };

    return (
      <StyledInput
        {...props}
        ref={ref}
        onBeforeInput={handleBeforeInput}
        onKeyPress={handleBeforeInput}
        onKeyUp={handleKeyDown}
        onChange={handleChange}
        autoComplete='off'
        autoCorrect='off'
        placeholder=''
        type='text'
        autoFocus={false}
        error={props.error}
      />
    );
  }
);

const allowedNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const AllowOnlyNumbers = (value: string): boolean => {
  return allowedNumbers.includes(value);
};
