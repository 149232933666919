import { agendamentoTPsicolConstants } from '../constants';

export function agendamentoTPsicol(state = { agendamentos: {} }, action) {
  switch (action.type) {
    case agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_SUCCESS:
      return {
        type: 'success',
        agendamentos: action.agendamentos,
      };
    case agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        agendamentos: [],
      });
    case agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
        agendamentos: [],
      });

    case agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_CLEAR:
      return Object.assign({}, state, {
        type: 'request',
        agendamentos: [],
      });

    case agendamentoTPsicolConstants.AGENDAMENTOTPSICOL_FILTER:
      return Object.assign({}, state, {
        type: 'filter',
        agendamentos: action.agendamentos,
      });

    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
