import { useState } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../../../../../helpers';
import { triggerService } from '../../../../../../../../Spread/services/core';
import { sleep } from '../../../../../../../../Spread/utils/common';
import { snackMessages } from '../constants';

const usePatientData = (trigger) => {
  const [useModalNotAttend, setModalNotAttend] = useState(false);
  const [useModalConfirmStatus, setModalConfirmStatus] = useState(false);

  const editionStore = JSON.parse(localStorage.getItem('userEdition'));
  const acionamentoByHook = useSelector(
    (state) => state.editAcionamento.editAcionamento
  );
  const editAcionamento = acionamentoByHook.name
    ? acionamentoByHook
    : editionStore;
  const triggerStatus =
    editAcionamento?.currentEditionAttendanceInfo?.attendanceType;

  const setTriggerStatus = async (status) => {
    const { user } = JSON.parse(window.localStorage.user);

    const response = await triggerService.changeTriggerStatus({
      attendanceInfos: {
        userId: user._id,
        userName: user.nome,
        attendanceId: editAcionamento._id,
        _id: editAcionamento._id,
        attendanceType: status,
        waitingForDateSchedule: true,
        status: status === 'IN_VONAGE_ATTENDANCE_RECEPCIONIST' && 'input',
      },
    });

    setModalConfirmStatus(false);

    const triggerType = response.status ? 'success' : 'danger';

    trigger({
      message: snackMessages[status][triggerType],
      type: triggerType,
      duration: 3000,
    });

    await sleep(2000);

    status === 'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE' && history.push('/adm');
  };

  const chipColor = (amount) => {
    let color;
    let backgroundColor;
    if (amount < 3) {
      color = '#136630';
      backgroundColor = '#ebf5ef';
    }
    if (amount >= 3 && amount < 5) {
      color = '#8f1b11';
      backgroundColor = '#ffeceb';
    }
    if (amount >= 5) {
      color = '#fff';
      backgroundColor = '#d93a2b';
    }
    return { color, backgroundColor };
  };

  const formatPushCallTime = (time) => {
    const date = new Date(time);
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    return `${date.getHours()}h${minutes}`;
  };

  return {
    editAcionamento,
    triggerStatus,
    useModalNotAttend,
    setModalNotAttend,
    setTriggerStatus,
    useModalConfirmStatus,
    setModalConfirmStatus,
    chipColor,
    formatPushCallTime,
  };
};

export { usePatientData };
