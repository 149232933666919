import { useEffect, useState, useRef } from 'react';

import actions from '../../../../redux/scale/actions';

import moment from 'moment';

const pageLimit = 10;

const useListEmployeeScale = () => {
  const dateParse = new Date();
  const arrayDate = [
    new Date(formatDate(dateParse)),
    new Date(
      formatDate(new Date(dateParse.getFullYear(), dateParse.getMonth() + 1, 0))
    ),
  ];

  function formatDate(date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss')
      .local()
      .format('YYYY-MM-DD HH:mm:ss');
  }

  const initialState = { dates: arrayDate };

  const [{ dates }, setState] = useState(initialState);
  const [date, setDate] = useState({});
  const [dateForm, setDateForm] = useState([]);
  const [sendForm, setSendForm] = useState([]);
  const [scales, setScales] = useState([]);
  const [reset, setReset] = useState(false);
  const [useLoading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState();

  const offset = useRef(0);

  const searchMoreData = async () => {
    const updatedOffset = offset.current + pageLimit;

    const dateFormatSend = dates.map((dateSend) => formatDate(dateSend));

    const { data } = await actions.serviceGridUser(
      dateFormatSend,
      pageLimit,
      updatedOffset
    );

    const loadedScales = data?.scales || [];

    if (loadedScales.length) {
      offset.current = offset.current + pageLimit;

      const totalScales = [...scales, ...loadedScales];

      setScales(totalScales);
    }
  };

  const clearState = () => {
    setScales(null);
    setState({ ...initialState });
    setDateForm([]);
    setReset(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClear = (e) => {
    e?.preventDefault();
    clearState();
  };

  const updateGrid = async (datesGrid) => {
    if (datesGrid) {
      let items = null;

      const { data } = await actions.serviceGridUser(datesGrid);

      setRefreshData(data);

      if (data?.scales?.length) {
        items = data.scales;
      }

      setScales(items);
    } else {
      setScales(null);
    }
  };

  const resetOffset = () => {
    if (offset.current > 0) {
      offset.current = 0;
    }
  };

  const handleSubmit = async () => {
    resetOffset();

    const dateFormatSend = dates.map((dateSend) => formatDate(dateSend));

    if (dates.length === 2) {
      setLoading(true);

      await updateGrid(dateFormatSend);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (reset) {
      setReset(!reset);
    }

    const dateFormatSend = dates.map((dateSend) => formatDate(dateSend));

    if (dates.length === 2) {
      updateGrid(dateFormatSend);
    }
  }, [reset]);

  useEffect(() => {
    if (date.dateClicked !== undefined) {
      const dateClick = date.dateClicked?.format?.();
      let [dia, mes, ano] = dateClick.split(/[\/ :]/).map((v) => parseInt(v));
      const dataParse = new Date(Date.UTC(ano, mes - 1, dia))
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);
      dateForm.push(dataParse);
    }
  }, [date]);

  useEffect(() => {
    if (sendForm.length > 0) {
      const dateFilter = sendForm.filter(function (ele, pos) {
        return sendForm.indexOf(ele) == pos;
      });
      setSendForm([]);
      onChange({ target: { name: 'dates', value: dateFilter } });
    }
  }, [sendForm]);

  return {
    useLoading,
    setDateForm,
    searchMoreData,
    dateForm,
    setSendForm,
    dates,
    arrayDate,
    reset,
    handleSubmit,
    handleClear,
    scales,
    setDate,
  };
};

export default useListEmployeeScale;
