import React from 'react';
import * as S from './styles';

export const Loading = () => {
  return (
    <S.Loading>
      <svg width='100px' height='100px' viewBox='0 0 100 100'>
        <path
          fill='none'
          d='M24.3,30C11.4,30,5,43.3,5,50s6.4,20,19.3,20c19.3,0,32.1-40,51.4-40 C88.6,30,95,43.3,95,50s-6.4,20-19.3,20C56.4,70,43.6,30,24.3,30z'
          stroke='#fff'
          strokeWidth='6'
          strokeDasharray='205.271142578125 51.317785644531256'
        >
          <animate
            attributeName='stroke-dashoffset'
            calcMode='linear'
            values='0;256.58892822265625'
            keyTimes='0;1'
            dur='2'
            begin='0s'
            repeatCount='indefinite'
          ></animate>
        </path>
      </svg>
    </S.Loading>
  );
};
