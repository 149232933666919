import React from 'react';

import { AccordionSummary } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import {
  MaterialAccordion,
  StyledAccordionInfo,
  StyledAccordionTitleWrapper,
  StyledDivider,
} from './styles';

import { CustomText } from '../';

const CustomAccordion = ({ data = [] }) =>
  data.map((d, i) => (
    <MaterialAccordion elevation={0} key={i}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <StyledAccordionInfo>
          <StyledAccordionTitleWrapper>
            <CustomText>{d?.title || ''}</CustomText>
            <CustomText title={1} color='dark' size='large'>
              {d?.subtitle || ''}
            </CustomText>
          </StyledAccordionTitleWrapper>
        </StyledAccordionInfo>
      </AccordionSummary>
      <StyledDivider />
      {d?.body()}
    </MaterialAccordion>
  ));

export default CustomAccordion;
