import styled, { css } from 'styled-components';
export const fontFamily = "font-family: 'Inter', sans-serif";
export const Container = styled.div`
  /* margin-top: 6px; */
  display: flex;
  flex-direction: column;
`;
export const ContainerMenu = styled.div`
  width: 210px;
  display: flex;
  background: #fff;
  margin-top: 10px;
  ul {
    margin: 0;
    padding: 10px 0px;
  }

  ul li:first-child {
    ${(props) =>
      props.inEdit
        ? css`
            display: none;
          `
        : css`
            font-family: ${fontFamily};
            color: #2e384d;
          `}
  }

  ul li {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    min-width: 200px;
    height: 37px;
    margin-bottom: 2px;
    background: #fff;
    align-items: center;
    font-family: ${fontFamily};
    color: #6b7786;
    cursor: pointer;
  }

  ul li span {
    padding-left: 2px;
    padding-right: 2px;
  }

  span {
    display: flex;

    button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      img {
        margin-right: 8px;
      }
    }
  }

  .selected {
    background: #f1f4f6;
    font-family: ${fontFamily};
    color: #2e384d;
    //font-weight: bold;
  }

  .hover {
    transition: opacity 0.3s;
    opacity: 0.8;
    color: red;
  }
`;
export const Content = styled.section`
  background: aliceblue;
  margin-left: 30px;
`;

export const IconButton = styled.img`
  width: 12px;
  margin-right: 5px;
`;

export const Title = styled.div`
  font-weight: 500;
  height: 50px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  /* padding-bottom: 35px; */
  /* margin-left: 20px; */
  /* padding-bottom: 38px; */
  /* margin-top: 14px; */
`;

export const DivInformationCount = styled.div`
  display: flex;
`;

export const InformationCount = styled.div`
  margin-left: auto;
  span {
    background: #007acc;
    border-radius: 20%;
    color: white;
    margin-right: 6px;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    padding: 2px;
    justify-content: center;
  }

  ${(props) =>
    props.disabled &&
    css`
      div {
        background: #737373;
      }
    `}
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 98%;
`;

export const ContainerLoader = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
`;
