import { deleteDocs } from '../constants';

export function deletedDocs(state = { deletedDocs: {} }, action) {
  switch (action.type) {
    case deleteDocs.DELETE_DOCS_SUCCESS:
      return {
        type: 'success',
        deletedDocs: action.deleted,
      };
    case deleteDocs.DELETE_DOCS_ERROR:
      return Object.assign({}, state, {
        type: 'error',
        deletedDocs: {
          deleted: false,
        },
      });
    case deleteDocs.DELETE_DOCS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
