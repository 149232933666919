import request from '../request';

const execute = async ({ dates, userId }) => {
  const primaryQuery = `?dateBegin=${dates[0]}&dateEnd=${dates[1]}`;
  const optionalQuery = userId ? `&userId=${userId}` : '';

  const query = primaryQuery + optionalQuery;

  try {
    const response = await request.get(`/grid/scales/report${query}`);

    if (response.err) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
