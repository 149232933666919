import { cancelamentoListConstants } from '../constants';

export function cancelamentoList(state = { historicos: {} }, action) {
  switch (action.type) {
    case cancelamentoListConstants.CANCELAMENTO_LIST_SUCCESS:
      return {
        type: 'success',
        cancelamentos: action.cancelmentItems,
      };
    case cancelamentoListConstants.CANCELAMENTO_LIST_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        cancelamentos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    case cancelamentoListConstants.CANCELAMENTO_LIST_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
        cancelamentos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return Object.assign({}, state, {
        type: 'request',
        cancelamentos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
  }
}
