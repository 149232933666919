import { useState, useCallback, useMemo, useEffect } from 'react';

import { userService } from '../../../src/services/user.service.js';
import { LoginActions } from '../../actions/login.actions';
import { ELoginSteps, IUserLoginState } from '../../reducers/login.reducer';

import Loading from '../../components/Loading';

import { LoginAlerts } from '../LoginAlerts';

import { LoginError, LoginErrorProps } from '../LoginError';

import { PageProps } from '../types';

import { LoginLabelErrors } from './data';
import { LoginFormEvent } from './components/form-fields.js';
import { Form } from './components/form';

import * as S from '../style';

interface LoginFormProps {
  pageProps: PageProps;
}

export function LoginForm({ pageProps }: LoginFormProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const showLoader = useMemo<boolean>(() => {
    return isSubmitting && !pageProps.loading;
  }, [isSubmitting, pageProps.loading]);

  const [labelError, setLabelError] = useState<LoginErrorProps | null>(null);

  const requestLogin = useCallback(
    async (data: LoginFormEvent, reCaptchaToken: string) => {
      try {
        setIsSubmitting(true);

        const result = await userService.loginWithMfa(
          data.username,
          data.password,
          reCaptchaToken
        );

        setIsSubmitting(false);

        if (result) {
          const parsedData: IUserLoginState['mfaData'] = {
            email: result?.maskedEmail,
            phoneNumber: result?.maskedPhone,
          };

          pageProps.dispatch(LoginActions.CacheMfaData(parsedData));

          pageProps.dispatch(LoginActions.ChangeStep(ELoginSteps.MFA_SELECT));
        } else {
          setLabelError(LoginLabelErrors.INVALID_CREDENTIALS);
        }
      } catch {
        setIsSubmitting(false);
        setLabelError(LoginLabelErrors.INVALID_CREDENTIALS);
      }
    },
    []
  );

  useEffect(() => {
    localStorage.removeItem('user');

    pageProps.dispatch(LoginActions.Reset());
  }, []);

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LogoContainer>
          <S.Logo />
        </S.LogoContainer>
        <S.LoginWrapper>
          <LoginAlerts pageProps={pageProps} />
          {showLoader && <Loading />}
          <S.LoginHeader>
            <S.LoginTitle style={{ margin: '3px 0' }}>
              Seja bem-vindo!
            </S.LoginTitle>
            <S.Subtitle>Entre com seu usuário e senha.</S.Subtitle>
          </S.LoginHeader>

          <Form
            pageProps={pageProps}
            onSubmit={requestLogin}
            updateError={setLabelError}
          />
          {labelError && <LoginError {...labelError} />}
        </S.LoginWrapper>
      </S.LoginContainer>
      <S.CompanyImage />
    </S.Container>
  );
}
