import { editAgendamentoTPsicolConstants } from '../constants';

export function editAgendamentoTPsicol(
  state = { editAcionamentoTelepsicologia: {} },
  action
) {
  switch (action.type) {
    case editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_SUCCESS:
      return {
        type: 'success',
        editAcionamentoTelepsicologia: action.editAcionamentoTelepsicologia,
      };
    case editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        editAcionamentoTelepsicologia: {},
      });
    case editAgendamentoTPsicolConstants.AGENDAMENTOTPSICOL_EDIT_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
        editAcionamentoTelepsicologia: {},
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
