import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueueRecordEntity } from '../../../../domain/entities';
import { ShowQueueRecordsState } from '../../../../domain/state';

const initialState: ShowQueueRecordsState = {
  show: {
    _id: '',
    centralId: '',
    createIdCentral: '',
    name: '',
    lastName: '',
    document: '',
    documentType: 'CPF',
    convenioDescricao: '',
    createDate: '',
    sendCockPit: false,
    deviceData: {
      deviceType: '',
      accessChannel: '',
      OS: '',
    },
    gender: '',
    birthDate: '',
    phone: '',
    email: '',
    nationality: '',
    streetCep: '',
    country: '',
    city: '',
    state: '',
    socialName: '',
    email2: '',
    disruptiveBehavior: '',
  },
  loading: true,
  modal: false,
};

export const showQueueRecordsSlice = createSlice({
  name: 'showQueueRecord',
  initialState,
  reducers: {
    setShow: (state, action: PayloadAction<QueueRecordEntity>) => {
      state.show = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setModal: (state, action: PayloadAction<boolean>) => {
      state.modal = action.payload;
    },
  },
});

export const { setShow, setLoading, setModal } = showQueueRecordsSlice.actions;

export default showQueueRecordsSlice.reducer;
