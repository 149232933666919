import { cepConstants } from '../constants';

export function cepToAddress(state = { cepToAddress: {} }, action) {
  switch (action.type) {
    case cepConstants.CEP_SUCCESS:
      return {
        type: 'success',
        cepToAddress: action.result,
      };
    case cepConstants.CEP_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        cepToAddress: {},
      });
    case cepConstants.CEP_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
