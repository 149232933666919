import styled, { css } from 'styled-components';

import { StyledButtonProps } from './types';

import {
  variants,
  type ColorsVariant,
  type ImageSizesVariant,
  type PaddingVariant,
  type FontSizesVariant,
} from './variants';

export const Wrapper = styled.button<StyledButtonProps>`
  ${({
    $size,
    $typeButtom,
    $width,
    $focusColors,
    $margin,
    $border,
    $padding,
    $height,
    $opacity,
    $fontSize,
  }) => {
    const colorVariant = variants.colors[$typeButtom as ColorsVariant];
    const paddingVariant = variants.padding[$size as PaddingVariant];
    const imageVariant = variants.imageSizes[$size as ImageSizesVariant];
    const fontSizeVariant = variants.fontSizes[$size as FontSizesVariant];

    return css`
      width: ${$width};
      font-size: ${fontSizeVariant};
      height: 100%;
      padding: ${paddingVariant};
      cursor: pointer;
      background: ${colorVariant.background};
      border: ${colorVariant.border};
      border-radius: ${colorVariant.borderRadius};
      color: ${colorVariant.color};
      margin: ${$margin ? $margin : ' '};

      &:hover {
        background: ${colorVariant?.backgroundHover};
        color: ${colorVariant?.colorHover};
      }

      &:active {
        background: ${colorVariant.backgroundActive};
      }
      &:disabled {
        opacity: 0.4;
      }
      &:focus {
        outline: none;
        border-radius: ${colorVariant.borderRadius};
        font-weight: bold;
        ${$focusColors && `background: ${$focusColors};`}

        img {
          width: ${imageVariant};

          padding-right: 5px;
        }

        ${$padding && `padding: ${$padding};`}

        ${$height && `height: ${$height};`}
        opacity: ${$opacity};
        ${$fontSize && `font-size: ${$fontSize};`}

        ${$border && `border: ${$border};`}
      }
    `;
  }}
`;

type ContentWrapperProps = Pick<
  StyledButtonProps,
  '$color' | '$fontWeight' | '$noWrap'
>;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  ${({ $color, $fontWeight, $noWrap }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${$color};
    font-weight: ${$fontWeight ?? 'normal'};
    white-space: ${$noWrap && 'nowrap'};
  `}
`;
