import styled from 'styled-components';

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
