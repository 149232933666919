import styled from 'styled-components'

export const Header = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  background: #f3f5f9;
  z-index: 2;
`

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh + 40px);
  background: #f3f5f9;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 5%;
  width: 600px;
`

export const Span = styled.span`
  background:#F3F5F9; 
  padding:0 0; 
`
export const TitleCategory = styled.h3`
  font: normal normal 600 18px/32px;
  font-family:'Inter';
  color:#91959D;
  line-height: 0.1em;
  margin: 10px 0 20px;
  border-bottom: 1px solid #91959D; 
`

export const Country = styled.div`
  background: blue;
  width: 20px;
  height: 13px;
  margin: 0 7px;
`

export const CountryContainer = styled.div`
  overflow-y: scroll;
  max-height: 145px;
  margin: 2px 2px;
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: #909090; 
    border-radius: 4px;
  }
`

export const CountryOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 36px;
  font: normal normal normal 12px/15px Inter;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.5s;

  :hover {
    background: #f0f4f7;
  }

  > img {
    margin: 0 10px;
    width: 22px;
    height: 15px;
  }
`

type RawProps = {
  gap: string
}

export const Row = styled.div<RawProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  height: 62px;
  gap: ${props => props.gap};
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > svg {
    position: absolute;
    right: 16px;
    bottom: 20px;
    transform: translateY(50%);
  }
`

type SelectInputProps = {
  isFocused: boolean
  isThereError: boolean
  disabled: boolean
}

export const SelectInput = styled.div<SelectInputProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 38px;
  width: 152px;
  border: 1px solid #Dfe3e8;
  background: #fff;
  font: normal normal normal 14px/15px Inter;
  color: #6B7786;
  border-radius: 4px;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition: border 0.3s ease-in-out;

  ${props => props.disabled && `
    color: #6B7786;
    background-color:rgba(239, 239, 239, 0.3);
  `}

  ${props => !props.disabled && props.isFocused && `
    border-color: #2699FB;
    box-shadow: 0 0 6px #3381ee33;
  `}

  ${props => props.isThereError && `
    border: 1px solid #f00;
  `}
  
  &:focus {
    border-color: #2699FB;
    box-shadow: 0 0 6px #3381ee33;
  }
`

export const OptionsContainer = styled.div`
  position: absolute;
  background: #fff;
  width: 100%;
  top: 63px;
  border: 1px solid #Dfe3e8;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  font: normal normal normal 14px/15px Inter;
  height: 33px;
  padding-left: 15px;
  box-shadow: 0 0 0 0.5px #Dfe3e8;
  color: #6B7786;

  &:hover {
    font-weight: 600;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  user-select: none;

  > svg {
    position: relative;
    left: 5px;
    bottom: 1px;
    background: transparent;
    width: 24px;
  }
`

type InputProps = {
  isWithError: string
}
export const Input = styled.input<InputProps>`
  position: relative;
  border: none;
  height: 38px;
  padding: 0 15px;
  border: 1px solid #Dfe3e8;

  ${props => props.isWithError && `
  border: 1px solid #f00;
  `}
  
  font: normal normal normal 14px/15px Inter;
  color: #6B7786;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  
  :focus {
    border-color: #2699FB;
    box-shadow: 0 0 6px #3381ee33;
    cursor: text;
  }
`

export const CountrySelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 52px;
  height: 38px;
  left: 1px;
  bottom: 1px;
  background: #f3f5f9;
  border-radius: 2px 0 0 2px;
  cursor: pointer;

  > img {
    position: relative;
    left: 2px;
    min-width: 22px;
    height: 15px;
  }

  svg {
    position: relative;
    left: 2px;
  }
`

export const SearchCountryInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  padding: 0;
  height: 40px;
  padding-left: 40px;
  padding-right: 10px;
  color: #5b6571;
  font: normal normal normal 14px/15px Inter;

  ::placeholder {
    color: #6B7786;
  }
`

export const CountryList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  top: 64px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #DFE3E8;
  border-radius: 4px;
  font: normal normal normal 14px/15px Inter;
  overflow: hidden;

  > svg {
    position: absolute;
    top: 21px;
    left: 22px;
    transform: translate(-50%, -50%);
    height: 14px;
    width: 14px;
  }
`

export const CountryCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 48px;
  height: 38px;
  left: 54px;
  bottom: 1px;
  font: normal normal normal 14px/15px Inter;
`