import { cancelamentoConstants } from '../constants';

export function cancelamento(state = { cancelamentos: {} }, action) {
  switch (action.type) {
    case cancelamentoConstants.CANCELAMENTO_SUCCESS:
      return {
        type: 'success',
        cancelamentos: action.cancelamentos,
      };
    case cancelamentoConstants.CANCELAMENTO_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    case cancelamentoConstants.CANCELAMENTO_REQUEST:
      return Object.assign({}, state, {
        type: 'failure',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
