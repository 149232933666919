import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

export const CarouselDivStyled = styled.div`
width: 700px; 
height: 450px;
border: 1px solid #bcbcbc
border-radius: 15px;
margin-left:30px;
margin-right:30px;
display:flex;
flex-wrap:wrap;

`;
export const CarouselStyled = styled(Carousel)`
  width: 600px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .carousel-status {
    color: black !important;
    font-size: 12px !important;
    text-shadow: none !important;
    border: 1 px solid black !important;
  }
  .carousel .slide {
    width: 600px;
    height: 400px;
  }
`;
export const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 10px)',
  width: 30,
  height: 30,
  cursor: 'pointer',
  background: 'none',
  border: 'none',
};

export const indicatorStyles = {
  background: '#d3d3d3',
  width: 8,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  borderRadius: '10px',
};

export const CardContainer = styled.div`
  width: 500px;
  height: 300px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: left;

  .checkBox {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    position: absolute;
    bottom: 25px;
  }
`;
export const Span = styled.span`
  font-size: 12px;
`;
export const Span2 = styled.p`
  display: flex;
  width: 100%;
  min-heigth: 150px;
  font-size: 12px;
  word-wrap: break-word;
`;
export const H2 = styled.h2`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 30px;
  font-size: 14px;
  margin-bottom: 0px;
  margin-bottom: 5px;
  word-wrap: break-word;
`;
export const P = styled.a`
  margin-left: 5px;
`;
export const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  position: absolute;
  bottom: 100px;
`;
