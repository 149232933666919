import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
export const ContainerDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 20px;
  }
`;
export const ContainerError = styled.div`
  position: absolute;
  bottom: 24%;
  left: 27%;
`;
