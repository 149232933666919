import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Close from '../../icons/icon_x_old.svg';
import CustomButtom from '../CustomButtom';
import {
  Container,
  ContainerAction,
  ContainerButton,
  CustomTitle,
  ContainerDialog,
} from './styles';
const DialogSimpleModal = ({
  open,
  text,
  handleCancel,
  handleConfirm,
  children,
  footerpos,
  simple,
}) => {
  return (
    <Container>
      <ContainerDialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <CustomTitle onClick={handleCancel} id='alert-dialog-title'>
          <img src={Close} alt='close' />
        </CustomTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {text}
          </DialogContentText>
          {children}
        </DialogContent>
        {!simple && (
          <ContainerAction footerpos={footerpos}>
            <ContainerButton>
              <CustomButtom
                onClick={handleCancel}
                typeButtom='lightRounded'
                fontSize='10px'
                size='medium'
                width='167px'
                border='solid 1px #ccc'
              >
                Cancelar
              </CustomButtom>

              <CustomButtom
                onClick={handleConfirm}
                typeButtom='lightRounded'
                fontSize='10px'
                size='medium'
                width='167px'
                border='solid 1px #FF0000'
                color='#FF0000'
              >
                Desvincular
              </CustomButtom>
            </ContainerButton>
          </ContainerAction>
        )}
      </ContainerDialog>
    </Container>
  );
};

export default DialogSimpleModal;
