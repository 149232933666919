export const modalConfirmTitle = {
  IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE: 'Deseja mesmo agendar o acionamento?',
  IN_VONAGE_ATTENDANCE_RECEPCIONIST: 'Deseja mesmo continuar o atendimento?',
};

export const snackMessages = {
  IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE: {
    success: 'Acionamento agendado com sucesso!',
    danger: 'Houve um erro ao agendar o acionamento.',
  },
  IN_VONAGE_ATTENDANCE_RECEPCIONIST: {
    success: 'Acionamento agora está em atendimento!',
    danger: 'Houve um erro ao alterar o status.',
  },
};

export const confirmTriggerStatus = {
  continueService: 'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
  confirmAppointment: 'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE',
  unsuccessfulContact: 'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE',
};
