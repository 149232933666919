import green from '@material-ui/core/colors/green';

import styled, { css } from 'styled-components';

const SnackTypes = {
  success: green[600],
  error: '#d32f2f',
  warning: '#303f9f',
  info: 'blue',
};

interface IWrapperProps {
  $type: string;
}
export const Wrapper = styled.div<IWrapperProps>`
  ${({ $type }) => {
    const variant =
      SnackTypes[$type as keyof typeof SnackTypes] || SnackTypes.warning;

    return css`
      padding: 5px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      flex-wrap: nowrap;
      background-color: ${variant};
      border-radius: 4px;

      .icon,
      .close,
      span {
        color: #ffffff;
      }
    `;
  }}
`;
