import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormCancelUser from '../components/Forms/FormCancelUser';

import Alert from '../components/Alert';
import AlertLink from '../components/AlertLink';

import { alertActions } from '../actions';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     padding: '20px',
//     flexWrap: 'wrap',
//     input: {
//       width: '100%',
//       padding: '8px 0 8px 0',
//       fontSize: '15px',
//     },
//   },
// }));
class CancelamentosUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelamentoUserData: [],
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  componentDidMount() {}

  render() {
    //const classes = useStyles();

    const { cancelamentosUser, open, message, type } = this.props;

    return (
      //className={classes.root}
      <section>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              paddingBottom: '30px',
            }}
          >
            <h3>Solicitação de Cancelamento</h3>
          </div>

          <FormCancelUser data={cancelamentosUser} />
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { cancelamentosUser } = state.cancelamentoUser;
  const { message, type, open, loading } = state.alert;

  return {
    cancelamentosUser,
    message,
    type,
    open,
    loading,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(CancelamentosUser);
export default connectedRegisterPage;
