import { store } from '../../store';

import types from './types';

import services from '../../services';

import moment from 'moment';

const user = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.user.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.user.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.user.error,
      data,
    });
  },
};

const grid = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.grid.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.grid.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.grid.error,
      data,
    });
  },
};

const listGrid = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.listGrid.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.listGrid.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.listGrid.error,
      data,
    });
  },
};

const gridUser = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.gridUser.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.gridUser.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.gridUser.error,
      data,
    });
  },
};

const reportUser = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.reportUser.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.reportUser.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.reportUser.error,
      data,
    });
  },
};

const remove = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.remove.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.remove.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.remove.error,
      data,
    });
  },
};

const scale = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.scale.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.scale.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.scale.error,
      data,
    });
  },
};

const serviceScaleRead = async (data) => {
  try {
    await scale.execute(data);

    const response = await services.scale.scaleRead({ data });

    if (response.err) {
      return response;
    }

    return response;
  } catch (error) {
    return {
      err: true,
      data: {
        error,
      },
    };
  }
};

const serviceScaleEdit = async (data) => {
  await scale.execute(data);

  try {
    const response = await services.scale.scaleEdit({ data });
    if (response.err) {
      return response;
    }

    return response;
  } catch (error) {
    return {
      err: true,
      data: {
        error,
      },
    };
  }
};

const serviceUser = async (userName) => {
  await user.execute(userName);

  if (!userName) {
    await user.error({ msg: 'Parameters not found' });
    return {
      err: true,
      data: {
        msg: 'Parameters not found',
      },
    };
  }

  const limit = 1; //alterar para vim da pagina
  const page = 1; //alterar para vim da pagina

  const response = await services.scale.scaleUser({
    name: userName,
    page: page,
  });
  if (response.err) {
    await user.error(response.data);
    return response;
  }

  await user.success(response.data);
  return response;
};

const serviceGrid = async (data, limit = 10, offset = 0) => {
  await grid.execute(data);

  const userId = data?.value;

  if (!userId) {
    await grid.error({ msg: 'User not found' });
    return false;
  }

  const dateDay = moment().format('YYYY-MM-DD');
  const response = await services.scale.scaleGrid({
    dateDay,
    userId,
    limit,
    offset,
  });

  if (response.err) {
    await grid.error(response.data);
    return response;
  }

  await grid.success(response.data);
  return response;
};

const serviceListGrid = async (date, limit = 10, offset = 0, exactlyDate = false) => {
  await listGrid.execute(data);

  const response = await services.scale.scaleListGrid({
    date,
    limit,
    offset,
    exactlyDate
  });
  if (response.err) {
    await listGrid.error(response.data);
    return response;
  }

  await listGrid.success(response.data);
  return response;
};

const serviceGridUser = async (dates, limit = 10, offset = 0) => {
  await gridUser.execute(data);

  dates.sort();

  const response = await services.scale.scaleGridUser({
    dates,
    limit,
    offset,
  });
  if (response.err) {
    await gridUser.error(response.data);
    return response;
  }

  await gridUser.success(response.data);
  return response;
};

const serviceReportUser = async (data) => {
  await reportUser.execute(data);

  const response = await services.report.report({
    dates: data.dates.sort(),
    userId: data.professional.value,
  });
  if (response.err) {
    await reportUser.error(response.data);
    return response;
  }

  await reportUser.success(response.data);
  return response;
};

const serviceRemove = async (data) => {
  try {
    await remove.execute(data);

    const response = await services.scale.scaleRemove(data);
    if (response.err) {
      await remove.error(response.data);
      return response;
    }

    await remove.success(response.data);
    return response;
  } catch (error) {
    return {
      err: true,
      data: { error },
    };
  }
};

const serviceScale = async (data, employee) => {
  try {
    await scale.execute(data);

    const form = {
      userId: employee?.value.toString(),
      userName: employee?.label,
      entryTime: data.entryTime,
      departureTime: data.departureTime,
      snackBreakTime: data.snackBreakTime,
      snackBreakDuration: data.snackTime,
      restBreakTime: data.restBreakTime,
      restBreakDuration: data.restTime,
      unit: data.unit,
      dates: data.dates,
    };

    const response = await services.scale.scaleCreate(form);
    if (response.err) {
      await scale.error(response.data);
      return response;
    }

    await scale.success(response.data);
    return response;
  } catch (error) {
    return {
      err: true,
      data: { error },
    };
  }
};

const data = {
  serviceUser,
  serviceGrid,
  serviceRemove,
  serviceScale,
  serviceListGrid,
  serviceScaleRead,
  serviceScaleEdit,
  serviceGridUser,
  serviceReportUser,
};

export default data;
