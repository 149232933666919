import React, { forwardRef } from 'react';
//import { IconButton } from "@material-ui/core";
import MaterialTable from 'material-table';
import ModalDeleteAcionamento from './ModalDeleteAcionamento';
import AddBox from '@material-ui/icons/AddBox';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { acionamentoActions } from '../actions';
import { history } from '../helpers';
import { acionamentoService } from '../services';
import DialogModalCancelSchedule from './DialogModalCancelSchedule';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class FilaAgendamentoTpsicol extends React.Component {
  constructor(props) {
    //props.dispatch(acionamentoActions.getAllAcionamentos())
    super(props);
    this.state = {
      acionamentosData: [],
      modalDeleteAcionamento: false,
      dataDeleteAcionamento: {},
      inputValueReason: '',
      openCancelSchedule: false,
      user: {},
    };
    this.handlerModalDeleteAcionamento =
      this.handlerModalDeleteAcionamento.bind(this);

    this.handleConfirmModalCancelSchedule =
      this.handleConfirmModalCancelSchedule.bind(this);
    this.handleCancelModalCancelSchedule =
      this.handleCancelModalCancelSchedule.bind(this);

    this.handleChangeCancel = this.handleChangeCancel.bind(this);
  }
  componentDidMount() {
    //this.props.dispatch(acionamentoActions.getAllAcionamentos())
  }

  // handleDeleteAcionamento = (id, reason) => {
  //   this.props.dispatch(acionamentoActions.deleteAcionamentos(id, reason));
  // };

  handleEditAcionamento = (acionamento) => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    this.props.dispatch(
      acionamentoActions.editAcionamentos({ ...acionamento, editionTime })
    );
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  componentWillMount() {
    const { dispatch } = this.props;
    this.intervalID = setInterval(
      () => dispatch(acionamentoActions.getAllAcionamentos()),
      4000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.props.dispatch(acionamentoActions.editAcionamentosWithoutRedirect());
  }

  handlerModalDeleteAcionamento(oldData) {
    const { modalDeleteAcionamento } = this.state;
    this.setState({
      modalDeleteAcionamento: !modalDeleteAcionamento,
      dataDeleteAcionamento: oldData,
    });
  }

  openModalCancelSchedule(user) {
    this.setState({ openCancelSchedule: true, user: user });
  }

  handleCancelModalCancelSchedule() {
    this.setState({ openCancelSchedule: false });
  }

  handleConfirmModalCancelSchedule() {
    this.setState({ openCancelSchedule: false });
  }

  onEditClick() {
    history.push('/adm/acionamento');
  }

  handleChangeCancel(e) {}

  // deleteAcionamento(data, oldData, reason) {
  //   const index = data.indexOf(oldData);
  //   data.splice(index, 1);
  //   if (this.state.inputValueReason !== "") {
  //     this.setState(
  //       { data, acionamentosData: data, inputValueReason: "" },
  //       () => this.handleDeleteAcionamento(oldData._id, reason)
  //     );
  //     this.handlerModalDeleteAcionamento();
  //   }
  // }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }

  render() {
    const { acionamentos } = this.props;
    const {
      acionamentosData,
      modalDeleteAcionamento,
      dataDeleteAcionamento,
      inputValueReason,
    } = this.state;
    const columns = [
      { title: 'Passagem', field: 'passage' },
      { title: 'Nome', field: 'fullName' },
      { title: 'Documento', field: 'document' },
      { title: 'Contrato', field: 'contrato' },
      { title: 'Idade', field: 'idade' },
      { title: 'Entrada', field: 'entrada' },
    ];

    // Lógica para manter acionamentos, eu pego os acionamento que estão com props e passo para State quando
    // realizo alguma acão. E aqui eu verifico se existe algum acionamento nos props, se não existir eu pego
    // os acionamentos que estão no state. É preciso fazer isso por que as props são zeradas a cada ação disparada.
    const data = acionamentos
      ? acionamentos.length >= 1
        ? acionamentos
        : []
      : acionamentosData;

    return (
      <React.Fragment>
        <ModalDeleteAcionamento
          open={modalDeleteAcionamento}
          close={this.handlerModalDeleteAcionamento}
          dataDeleteAcionamento={dataDeleteAcionamento}
          onSubmit={() =>
            this.deleteAcionamento(
              data,
              dataDeleteAcionamento,
              inputValueReason
            )
          }
          inputValueReason={inputValueReason}
          dataAcionamento={data}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
        />
        <MaterialTable
          icons={tableIcons}
          title=''
          columns={columns}
          data={(query) =>
            new Promise(async (resolve) => {
              let result = await acionamentoService.paginateAllAcionamentos(
                query.pageSize,
                query.page + 1,
                query.search
              );
              resolve({
                data: result.docs,
                page: result.page - 1,
                totalCount: result.total,
              });
            })
          }
          // components={{
          //     Action:  props => {
          //         if (props.action.icon === 'edit') {
          //             return (
          //                 <IconButton size="medium" onClick={(event) => props.action.onClick(event, props.data)}>
          //                     <Edit fontSize="inherit" />
          //                 </IconButton>
          //             )
          //         }
          //         if (props.action.icon === 'AddBox') {
          //             return (
          //                 <IconButton size="medium" onClick={(event) => props.action.onClick(event, props.data)}>
          //                     <AddBox fontSize="inherit" />
          //                 </IconButton>
          //             )
          //         }
          //         return ('')
          //     }
          // }}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Ótimo, não há paciente esperando.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse acionamento?',
              },
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => ({
              icon: VideoCallIcon,
              tooltip: 'VideoCall',
              disabled: !rowData.urlCall,
              hidden: !rowData.urlCall,
              onClick: (event, rowData) => this.handleOpenLink(rowData.urlCall),
            }),
            (rowData) => ({
              icon: Edit,
              tooltip: 'Editar',
              //disabled: rowData.passage,
              onClick: (event, rowData) => {
                this.handleEditAcionamento(rowData);
              },
            }),
            {
              icon: Delete,
              tooltip: 'Deletar',
              //disabled: rowData.passage,
              onClick: (event, rowData) => {
                this.openModalCancelSchedule(rowData);
              },
            },
            {
              icon: AddBox,
              isFreeAction: true,
              tooltip: 'Adicionar Acionamento',
              // onClick: () => history.push('/adm/acionamento'),
              onClick: (event, rowData) => {
                this.onEditClick(null, rowData._id);
              },
            },
          ]}
        />
        <DialogModalCancelSchedule
          user={this.state.user}
          open={this.state.openCancelSchedule}
          title='Deseja Remover o agendamento?'
          handleCancel={() => this.handleCancelModalCancelSchedule}
          handleConfirm={() => this.handleCancelModalCancelSchedule}
          handleChange={(e) => this.handleChangeCancel(e)}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { acionamentos } = state.acionamento;
  return {
    acionamentos,
  };
}

FilaAgendamentoTpsicol.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(FilaAgendamentoTpsicol);
export { connectedLoginPage as FilaAgendamentoTpsicol };
