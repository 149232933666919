import { queueItemsConstants } from '../constants';

import { queueItemsService } from '../services/queueItems.service';

export const queueItems = {
  getFiltredQueueItems,
  getPaginate,
};

function getFiltredQueueItems(id, page, pageSize) {
  return (dispatch) => {
    dispatch(request());
    queueItemsService
      .getFiltredqueueItems(id, page, pageSize)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: queueItemsConstants.QUEUEITEMS_REQUEST };
  }
  function success(queueItems) {
    return { type: queueItemsConstants.QUEUEITEMS_SUCCESS, queueItems };
  }
  function failure(error) {
    return { type: queueItemsConstants.QUEUEITEMS_FAILURE, error };
  }
}

function getPaginate(
  id,
  page,
  pageSize,
  objectRequest,
  query,
  filterStatus,
  filterProfessionals,
  filterUnitsOfAttendance,
  codFila
) {
  return new Promise((resolve) => {
    queueItemsService
      .getFiltredqueueItems(
        id,
        page,
        pageSize,
        objectRequest,
        query,
        filterStatus,
        filterProfessionals,
        filterUnitsOfAttendance,
        codFila
      )
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        resolve({});
      });
  });
}
