import React from 'react';

import {
  StyledButton,
  StyledContainerContent,
  StyledButtonContainer,
} from './styles';

import { CustomIcon, CustomLoader } from '..';

const CustomButton = ({
  onClick = () => {},
  rounded = true,
  submit = false,
  disabled,
  loading,
  icon,
  text,
  width,
  iconDir = 'r',
  size = 'small',
  color = 'info',
  type = 'default',
  full,
}) => {
  const buttonProps = submit
    ? {
        type: 'submit',
      }
    : {
        type: 'button',
        onClick: (e) => {
          e.preventDefault();
          onClick(e);
        },
      };

  return (
    <StyledButtonContainer full={full}>
      <StyledButton
        $color={color}
        schema={type}
        rounded={rounded}
        size={size}
        width={width}
        disabled={disabled}
        aria-label={text}
        role='button'
        {...buttonProps}
      >
        <StyledContainerContent iconDir={iconDir}>
          {icon && <CustomIcon icon={icon} />}
          {text}
        </StyledContainerContent>
      </StyledButton>
      {loading && <CustomLoader color={color} />}
    </StyledButtonContainer>
  );
};

export default CustomButton;
