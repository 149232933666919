import React from 'react';
import { LoginEntity, HttpBodyLogin } from '../../../domain/entities';
import { HttpClient } from '../../../domain/http';

export class LoginData {
  httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async handle(body: HttpBodyLogin) {
    const response = await this.httpClient.handle<LoginEntity, HttpBodyLogin>(
      body
    );
    return response;
  }
}
