import styled from 'styled-components';

export * from '../style';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 32px;
`;

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 12px;
  justify-content: space-evenly;
  margin-top: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dce0e8;
  margin-top: 8px;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const TextButton = styled.button`
  width: 100%;
  border: 0;
  margin: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
  cursor: pointer;
  transition: all.5s;

  &:hover {
    text-decoration: underline;
  }
`;
