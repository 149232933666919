import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const responsavelFinanceiroService = {
  getContract,
  cancelContract,
  financeResponsible,
};

// async function financeResponsible(document) {
//   const { token } = JSON.parse(window.localStorage.user);
//   const requestOptions = {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//   };
// }

function financeResponsible(document) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify({
      document: document,
    }),
  };
  return fetch(
    `${URL_CONECTA}/servico/responsavelfinanceiro/getFinanceResponsibleByDocument`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  console.log(response);
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 511) {
        // auto logout if 401 response returned from api
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }

    return data;
  });
}

async function getContract(phoneNunber) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/vinculo/getContract?phone=${phoneNunber}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}

async function cancelContract(phoneNunber) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ number: phoneNunber }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/desvinculo/cancelContract`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}
