// @ts-nocheck
import React from 'react';
import * as S from './styles';

type Props = {
  children: React.ReactNode;
};

export const ButtonSubmit = ({ children }: Props) => {
  return <S.ButtonSubmit type='submit'>{children}</S.ButtonSubmit>;
};
