import { useEffect } from 'react';

const useBackdrop = () => {
  const addBlur = () => {
    const app = document.getElementById('root');

    if (app) {
      app.style.filter = 'blur(3px)';
    }
  };

  const removeBlur = () => {
    const app = document.getElementById('root');

    if (app) {
      app.style.filter = 'none';
    }
  };

  useEffect(() => {
    addBlur();

    return () => {
      removeBlur();
    };
  }, []);

  return {
    addBlur,
    removeBlur,
  };
};

export default useBackdrop;
