import schedule from './schedule'
import scale from './scale'
import report from './report'

const data = {
	schedule,
	scale,
	report,
}

export default data
