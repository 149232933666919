import { QueueRecordEntity } from '../../../domain/entities';
import {
  HttpClientAdapter,
  HttpRequest,
  HttpResponse,
} from '../../../infra/adapters';

type Response = {
  docs: QueueRecordEntity[];
  pages: number;
};

export class HttpQueueRecordReport {
  constructor(private readonly httpClient: HttpClientAdapter) {}

  async handle(httpRequest: HttpRequest): Promise<HttpResponse<Response>> {
    return await this.httpClient.request(httpRequest);
  }
}
