import { REQUEST_TIMEOUT } from '../../../constants';
import { userService } from '../../../services';
import { customEvent } from '../constants';

const baseUrl = process.env.REACT_APP_API_CONECTA;

const DEFAULT_UNAUTHORIZED_MESSAGE =
  'Autenticação falhou. O token pode ter expirado.';

const handleLogoutUser = (message = DEFAULT_UNAUTHORIZED_MESSAGE) => {
  userService.logout();
  localStorage.setItem('unauthorizedMessage', message);
};

const instance = (url, options, timeout = REQUEST_TIMEOUT) => {
  return new Promise(async (resolve, reject) => {
    const abortController = new AbortController();

    const requestTimeout = setTimeout(() => {
      abortController.abort();
      reject(new Error('Request timed out'));
    }, timeout);

    const user = JSON.parse(window.localStorage.user);

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.accessToken,
        _id: user.user._id,
      },
      signal: abortController.signal,
      ...options,
    };

    if (
      typeof requestOptions?.body === 'object' &&
      Object.entries(requestOptions.body).length > 0
    ) {
      requestOptions.body = JSON.stringify(requestOptions.body);
    }

    const response = await fetch(`${baseUrl}/${url}`, requestOptions);

    clearTimeout(requestTimeout);

    const body = await response.clone().json();

    if (response.status === 401) {
      handleLogoutUser(body.message);
      return;
    }

    if (response.status === 403 && body.isPaused) {
      const event = new Event(customEvent.lockUser);

      document.dispatchEvent(event);
    }

    resolve(response);
  });
};

const GET = async (url, options = {}) =>
  await instance(url, { method: 'GET', ...options });
const POST = async (url, options = {}) =>
  await instance(url, { method: 'POST', ...options });
const PUT = async (url, options = {}) =>
  await instance(url, { method: 'PUT', ...options });
const DELETE = async (url, options = {}) =>
  await instance(url, { method: 'DELETE', ...options });

export default {
  GET,
  POST,
  PUT,
  DELETE,
};
