import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import iconconvenio from '../icons/icon_convenio.svg';
import data from '../icons/icon_data.svg';
import pciente from '../icons/icon_paciente.svg';
import sexo from '../icons/icon_gen.svg';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import {
  alertActions,
  userActions,
  alertContingenciaActions,
  acionamentoActions,
} from '../actions';
import { validations } from '../helpers/validations';
import Fab from '@material-ui/core/Fab';
import './App.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '../components/Alert';
import ModalConvenio from '../components/ModalConvenio';
import classNames from 'classnames';
import AlertLink from '../components/AlertLink';
import { history } from '../helpers';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { floatToString } from '../utils/utils';

const styles = (theme) => ({
  main: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    width: '100%',
    fontFamily: 'Poppins',
    paddingTop: '0',
    margin: '0 auto 0 auto',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    flexBasis: 200,
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  img_inputs: {
    top: '10px !important',
    width: '20px',
    height: '20px',
  },
  img_inputs_sem: {
    width: '0 !important',
    height: '0 !important',
  },
  formulario: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  tituloPagina: {
    color: 'black',
    fontFamily: 'Arial',
    fontSize: '32px !important',
    fontWeight: 'bold',
  },
  erroValidation: {
    color: 'red',
  },
  alertBox: {
    zIndex: '10001 !important',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },
  alertTextTop: {
    marginTop: -15,
  },
  alertTextBottom: {
    marginTop: -8,
  },
  inputMobile: {
    width: '50%',
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  inputBirth: {
    width: '100%',
    padding: '8px 0 0 0',
    fontSize: '12px',
    '#::placeholder': {
      fontSize: '12px',
    },
  },
  input_phone: {
    fontSize: '12px',
    padding: '10px 0 2px 0',
    '#::placeholder': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
});

class Historico extends React.Component {
  constructor(props) {
    props.dispatch(acionamentoActions.getAllConvenios());
    super(props);

    this.state = {
      birthDate: '    -    ',
      numberformat: '1320',
      user: {
        cpfrequire: false,
        name: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: '',
        nacionalidade: '',
        id_requisicao_parceiro: '',
      },
      aliasConvenio: null,
      convenioValid: false,
      openModal: false,
      openModalConfirmacao: false,
      blurPhone: false,
      message: '',
      type: '',
      open: false,
      vertical: 'top',
      horizontal: 'center',
      valDate: 'text',
      submitted: false,
      registry: false,
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitConfirmed = this.handleSubmitConfirmed.bind(this);
    this.TextMaskCustom = this.TextMaskCustom.bind(this);
    this.TextMaskCustomCPF = this.TextMaskCustomCPF.bind(this);
    this.PhoneMask = this.PhoneMask.bind(this);
    this.PhoneMask2 = this.PhoneMask2.bind(this);
    this.PhoneMask3 = this.PhoneMask3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseAlertLink = this.handleCloseAlertLink.bind(this);
    this.handleClickOpenModalConvenio =
      this.handleClickOpenModalConvenio.bind(this);
    this.handleCloseModalConvenio = this.handleCloseModalConvenio.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValidFormulario = this.handleValidFormulario.bind(this);
    this.validationCadastro = this.validationCadastro.bind(this);
    this.handleClickOpenModalConfirmacao =
      this.handleClickOpenModalConfirmacao.bind(this);
    this.handleCloseModalConfirmacao =
      this.handleCloseModalConfirmacao.bind(this);
    this.handleShowNotification = this.handleShowNotification.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  async componentDidMount() {
    const { editAcionamento } = this.props;
    let user = editAcionamento;
    if (user.name) {
      this.setState({
        aliasConvenio: true,
        user: {
          ...editAcionamento,
          convenio: `${user.convenio}|${user.contrato}`,
          contrato: user.contrato,
          cpfrequire: false,
          name: user.name,
          lastName: user.lastName,
          cpf: user.document,
          email: user.email,
          birthDate: moment(user.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          password: '',
          cpassword: '',
          loadingsub: false,
          submitted: false,
          checkTerms: false,
          showNotifications: false,
          sex: user.gender,
          phone: floatToString(user.phone),
          phone2: floatToString(user.homePhone),
          phone3: floatToString(user.phone2),
          rg: user.rg,
          passport: user.document,
          nacionalidade: user.documentType
            ? user.documentType === 'CPF'
              ? 'B'
              : 'E'
            : '',
          numberCard: user.numberCard,
          userType: user.userType,
          id_requisicao_parceiro: user.partnerID,
        },
      });
    }
  }

  handleValidFormulario(event, edit) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, aliasConvenio } = this.state;
    var birthDate = user.birthDate;
    if (birthDate) {
      var arrayDate = birthDate.split('/');
      var day = Number(arrayDate[0]),
        month = Number(arrayDate[1]),
        year = Number(arrayDate[2]);

      var formBirth = moment([year, month, day]);
      var now = moment();
      var years = now.diff(formBirth, 'years');
      if (years >= 18 && !this.state.user.cpf) {
        this.setState({
          cpfrequire: true,
        });
      } else {
        this.setState({
          cpfrequire: false,
        });
      }
    }
    if (this.state.formValid && edit) {
      this.handleSubmitEdit();
    }
    if (this.state.formValid && !aliasConvenio) {
      this.handleClickOpenModalConvenio();
    } else if (this.state.formValid && aliasConvenio) {
      this.handleClickOpenModalConfirmacao();
    }
  }

  handleSubmit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    if (user.convenio !== '') {
      dispatch(userActions.register(user));
      this.handleCloseModalConvenio();
    } else {
      this.setState({
        convenioValid: true,
      });
    }
  };

  handleSubmitEdit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    dispatch(userActions.put(user));
    this.handleCloseModalConvenio();
    this.handleBack();
  };

  handleSubmitConfirmed = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    if (user.convenio !== '') {
      dispatch(acionamentoActions.confirmedAcionamentos(user));
      this.handleCloseModalConfirmacao();
    } else {
      this.setState({
        convenioValid: true,
      });
    }
  };

  handleShowNotification = () => {
    const { user } = this.state;

    if (this.state.user.showNotifications) {
      this.setState({
        user: {
          ...user,
          showNotifications: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          showNotifications: true,
        },
      });
    }
  };

  handleChangeCheckbox = () => {
    const { user } = this.state;
    if (user.checkTerms) {
      this.setState({
        user: {
          ...user,
          checkTerms: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          checkTerms: true,
        },
      });
    }
    this.validationCadastro('terms');
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowCPassword = () => {
    this.setState((state) => ({ showCPassword: !state.showCPassword }));
  };

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  handleClear() {
    this.setState({
      user: {
        name: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        nacionalidade: '',
        id_requisicao_parceiro: '',
      },
    });
  }

  validationCadastro(event) {
    const {
      name,
      lastName,
      nacionalidade,
      cpf,
      passport,
      email,
      birthDate,
      password,
      cpassword,
      checkTerms,
      sex,
      phone,
      rg,
      id_requisicao_parceiro,
    } = this.state.user;
    switch (event) {
      case 'name':
        if (name) {
          this.state.nameValid = validations.validationName(name);
        }
        break;
      case 'lastName':
        if (lastName) {
          this.state.lastNameValid = validations.validationLastName(lastName);
        }
        break;
      case 'cpf':
        if (cpf) {
          this.state.cpfValid = validations.validationCPF(cpf);
        }
        break;
      case 'email':
        if (email) {
          this.state.emailValid = validations.validationEmail(email);
        }
        break;
      case 'birthDate':
        if (birthDate) {
          this.state.birthDateValid =
            validations.validationbirthDate(birthDate);
        }
        break;
      case 'phone':
        if (phone) {
          this.state.phoneValid = validations.validationTelefone(phone);
        }
        break;
      case 'terms':
        this.state.termsValid = validations.validationTerms(checkTerms);
        break;
      case 'password':
        if (password) {
          this.state.passwordValid = validations.validationPassword(password);
        }
        break;
      case 'cpassword':
        if (cpassword) {
          this.state.cpasswordValid = validations.validationCPassword(
            password,
            cpassword
          );
        }
        break;
      case 'rg':
        if (rg) {
          this.state.rgValid = validations.validationRg(rg);
        }
        break;
      default:
        break;
    }
    const {
      nameValid,
      lastNameValid,
      cpfValid,
      emailValid,
      passwordValid,
      cpasswordValid,
      termsValid,
      birthDateValid,
    } = this.state;

    if (nacionalidade === 'B') {
      if (birthDate) {
        var arrayDate = birthDate.split('/');
        var day = Number(arrayDate[0]),
          month = Number(arrayDate[1]),
          year = Number(arrayDate[2]);

        var formBirth = moment([year, month, day]);
        var now = moment();
        var years = now.diff(formBirth, 'years');
        if (years >= 18 && !this.state.user.cpf) {
          this.state.formValid =
            cpf &&
            rg &&
            nacionalidade &&
            !nameValid &&
            !lastNameValid &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        } else {
          this.state.formValid =
            nacionalidade &&
            !nameValid &&
            !lastNameValid &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        }
      } else {
        this.state.formValid =
          nacionalidade &&
          !nameValid &&
          !lastNameValid &&
          !emailValid &&
          !passwordValid &&
          !cpasswordValid &&
          !termsValid &&
          !birthDateValid &&
          (sex === 'M' || sex === 'F');
      }
    } else {
      this.state.formValid =
        nacionalidade &&
        !nameValid &&
        !lastNameValid &&
        passport &&
        !emailValid &&
        !passwordValid &&
        !cpasswordValid &&
        !termsValid &&
        !birthDateValid &&
        (sex === 'M' || sex === 'F');
    }
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.birthDate}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='birthDate'
        id='formatted-text-mask-input'
        placeholder='Data de Nascimento'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  TextMaskCustomCPF(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.cpf}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='cpf'
        mask='999.999.999-99'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  handleBack() {
    history.push('/adm');
  }

  PhoneMask(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone'}
        value={this.state.user.phone}
        placeholder={'DDD + Telefone'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '99999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask2(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone2'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone2'}
        value={this.state.user.phone2}
        placeholder={'DDD + Telefone 2'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '99999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask3(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone3'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone3'}
        value={this.state.user.phone3}
        placeholder={'DDD + Telefone 3'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '99999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith('-') &&
      userInput !== '-' &&
      !this.state.value.endsWith('-')
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  handleCloseAlertLink() {
    const { dispatch } = this.props;
    dispatch(alertContingenciaActions.clear());
  }

  handleClickOpenModalConvenio() {
    this.setState({ openModal: true });
  }

  handleCloseModalConvenio() {
    this.setState({ openModal: false });
  }

  handleClickOpenModalConfirmacao() {
    this.setState({ openModalConfirmacao: true });
  }

  handleCloseModalConfirmacao() {
    this.setState({ openModalConfirmacao: false });
  }

  render() {
    const {
      registering,
      open,
      classes,
      message,
      type,
      convenios = [],
      user,
    } = this.props;
    const { submitted, openModal, openModalConfirmacao, aliasConvenio } =
      this.state;
    const width = window.innerWidth;

    const inputs_1 = [
      {
        float: 'left',
        imagem: pciente,
        pergunta: 'Nome',
        state: user.name,
        type: 'name',
        blur: this.validationCadastro('name'),
        nValid: this.state.nameValid,
        validacao: 'No mínimo 2 caracteres.',
      },
      {
        float: 'right',
        imagem: pciente,
        pergunta: 'Sobrenome',
        state: user.lastName,
        type: 'lastName',
        blur: this.validationCadastro('lastName'),
        nValid: this.state.lastNameValid,
        validacao: 'No mínimo 3 caracteres.',
      },
    ];
    const input_cpf = [
      {
        float: 'left',
        imagem: iconconvenio,
        pergunta: 'CPF',
        state: user.cpf,
        type: 'cpf',
        blur: this.validationCadastro('cpf'),
        nValid: this.state.cpfValid,
        require: this.state.cpfrequire,
        validacao: 'CPF inválido.',
      },
    ];

    const input_rg = [
      {
        float: 'right',
        imagem: iconconvenio,
        pergunta: 'Rg',
        state: user.rg,
        type: 'rg',
        blur: this.validationCadastro('rg'),
        nValid: this.state.rgValid,
        validacao: 'Digite o RG.',
      },
    ];

    const inputs_3 = [
      {
        imagem: pciente,
        pergunta: 'Email',
        state: user.email,
        type: 'email',
        blur: this.validationCadastro('email'),
        nValid: this.state.emailValid,
        validacao: 'Email inválido.',
      },
    ];

    const inputs_4 = [
      {
        imagem: iconconvenio,
        pergunta: 'Passaporte',
        state: user.passport,
        type: 'passport',
        blur: this.validationCadastro('passport'),
        nValid: this.state.passportValid,
        validacao: 'Passaporte inválido.',
      },
    ];

    const inputs_5 = [
      {
        imagem: iconconvenio,
        pergunta: 'Identificador parceiro',
        state: user.id_requisicao_parceiro,
        type: 'id_requisicao_parceiro',
        blur: this.validationCadastro('id_requisicao_parceiro'),
        nValid: this.state.id_requisicao_parceiroValid,
        validacao: 'Identificador parceiro inválido.',
      },
    ];

    const phones = [
      {
        inputRef: this.PhoneMask,
        float: 'left',
        text: 'Campo obrigatório.',
      },
      {
        inputRef: this.PhoneMask2,
        float: 'center',
      },
      {
        inputRef: this.PhoneMask3,
        float: 'right',
      },
    ];

    return (
      <div className={classes.main}>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <ModalConvenio
          open={openModal}
          close={() => this.handleCloseModalConvenio()}
          message='Escolha um Convênio'
          onSubmit={() => this.handleSubmit()}
          component={
            <React.Fragment>
              <TextField
                id='standard-select-currency'
                select
                label='Convênio'
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
                value={user.convenio}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='convenio'
                className={classes.input}
              >
                {convenios.map((convenio) => (
                  <MenuItem
                    key={convenio.id}
                    value={`${convenio.id}|${convenio.descricao}`}
                  >
                    {convenio.descricao}
                  </MenuItem>
                ))}
              </TextField>
              {this.state.convenioValid && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextTop
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
            </React.Fragment>
          }
        />

        <ModalConvenio
          open={openModalConfirmacao}
          close={() => this.handleCloseModalConfirmacao()}
          message='Confirmar acionamento?'
          onSubmit={() => this.handleSubmitConfirmed()}
        />

        <form
          id='Cadastro_Usuario'
          name='Cadastro_Usuario'
          className={classes.formulario}
          onSubmit={this.handleValidFormulario}
        >
          {aliasConvenio ? (
            <TextField
              id='standard-select-currency'
              select
              label='Convênio'
              onChange={this.handleChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              value={user.contrato}
              input={<Input id='select-multiple-placeholder' />}
              displayEmpty
              name='convenio'
              className={classes.input}
            >
              <MenuItem value={user.contrato}>{user.contrato}</MenuItem>
            </TextField>
          ) : (
            ''
          )}
          <Grid container spacing={width > 900 ? 8 : 0}>
            {inputs_1.map((questao) => (
              <Grid
                key={questao.type}
                item
                style={{ float: questao.float }}
                className={classNames(
                  classes.inputMobile +
                    (submitted && !questao.state ? ' has-error' : '')
                )}
              >
                <TextField
                  margin='normal'
                  placeholder={questao.validacao}
                  label={questao.pergunta}
                  name={questao.type}
                  value={questao.state}
                  className={classes.input}
                  key={questao.type}
                  onBlur={questao.blur}
                  type={questao.date}
                  onFocus={questao.focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {submitted && !questao.state && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {questao.nValid && !(submitted && !questao.state) && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    {questao.validacao}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>

          <Select
            value={user.nacionalidade}
            onChange={this.handleChange}
            input={<Input id='select-multiple-placeholder' />}
            displayEmpty
            name='nacionalidade'
            className={classes.input}
            startAdornment={
              <InputAdornment position='start'>
                <img className={classes.img_inputs} src={sexo} />
              </InputAdornment>
            }
          >
            <MenuItem value=''>
              <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                Nacionalidade
              </em>
            </MenuItem>
            <MenuItem value={'B'}>Brasileira</MenuItem>
            <MenuItem value={'E'}>Estrangeira</MenuItem>
          </Select>
          {submitted && !user.nacionalidade && (
            <div className={classes.alertText}>Campo obrigatório.</div>
          )}
          <Grid>
            {user.nacionalidade === 'B' ? (
              <Grid>
                <TextField
                  label='Cpf'
                  margin='normal'
                  placeholder='Digite o CPF.'
                  className={classes.inputBirth}
                  onBlur={this.validationCadastro('cpf')}
                  InputProps={{
                    inputComponent: this.TextMaskCustomCPF,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={classes.img_inputs}
                          src={iconconvenio}
                        />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {submitted && !user.cpf && this.state.cpfrequire && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {submitted &&
                  this.state.cpfValid &&
                  !(!user.cpf && this.state.cpfrequire) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {'CPF inválido.'}
                    </div>
                  )}

                {input_rg.map((questao) => (
                  <Grid>
                    <TextField
                      margin='normal'
                      placeholder={questao.validacao}
                      label={questao.pergunta}
                      name={questao.type}
                      value={questao.state}
                      className={classes.input}
                      onBlur={questao.blur}
                      type={questao.date}
                      onFocus={questao.focus}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <img
                              className={
                                questao.imagem
                                  ? classes.img_inputs
                                  : classes.img_inputs_sem
                              }
                              src={questao.imagem ? questao.imagem : null}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {submitted && !questao.state && this.state.cpfrequire && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextTop
                        )}
                      >
                        Campo obrigatório.
                      </div>
                    )}
                    {questao.nValid && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextTop
                        )}
                      >
                        {questao.validacao}
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          {user.nacionalidade === 'E' ? (
            <Grid container justify='center'>
              {inputs_4.map((questao) => (
                <Grid
                  key={questao.type}
                  item
                  style={{ width: '100%' }}
                  className={'form-group'}
                >
                  <TextField
                    label='Passaporte'
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {submitted && !questao.state && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      Campo obrigatório.
                    </div>
                  )}
                  {questao.nValid && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            ''
          )}

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                label='Data de Nascimento'
                margin='normal'
                className={classes.inputBirth}
                onBlur={this.validationCadastro('birthDate')}
                InputProps={{
                  inputComponent: this.TextMaskCustom,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={data} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {submitted && !user.birthDate && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
              {submitted && this.state.birthDateValid && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  {'Data de nascimento inválida.'}
                </div>
              )}
            </Grid>

            <Grid
              item
              style={{ float: 'right' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                id='standard-select-currency'
                select
                label='Sexo'
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
                value={user.sex}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='sex'
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={sexo} />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Feminino</MenuItem>
              </TextField>
              {submitted && !user.sex && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextTop
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            {phones.map((phone) => (
              <Grid key={phone.float} item xs={12} sm={4}>
                <TextField
                  label='Telefone'
                  margin='normal'
                  className={classes.input_phone}
                  InputProps={{
                    inputComponent: phone.inputRef,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img className={classes.img_inputs} src={pciente} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {submitted && !user.phone && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    {phone.text}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item xs={12} sm={7}>
              {inputs_3.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label='E-mail'
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {submitted && !questao.state && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextTop
                      )}
                    >
                      Campo obrigatório.
                    </div>
                  )}
                  {questao.nValid && !(submitted && !questao.state) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextTop
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={5}>
              {inputs_5.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label={questao.pergunta}
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    startAdornment={
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </form>
        {registering && (
          <div className={classes.overlay}>
            <div className='loader'>Loading...</div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  const { editAcionamento } = state.editAcionamento;
  const { user } = state.editHistoricos;
  const { convenios } = state.convenios;
  const { message, type, open, loading } = state.alert;

  return {
    registering,
    editAcionamento,
    message,
    type,
    open,
    loading,
    convenios,
    user,
  };
}

Historico.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(Historico);
export default withStyles(styles)(connectedRegisterPage);
