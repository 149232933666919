import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import InputMaskCustom from '../../components/Forms/InputMaskCustom';
import CustomButtom from '../../components/CustomButtom';
import IconDesvinculo from '../../icons/vinculo.svg';
import { responsavelFinanceiroService } from '../../services/responsavelFinanceiro.service';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { validations } from '../../helpers/validations';
import {
  Title,
  ContainerInput,
  ContainerButton,
  ContainerGeneric,
} from './styles';
import { CustomTable } from './CustomTable';
import { CustomAlert } from './CustomAlert';
import { Input } from '@material-ui/core';

class ResponsavelFinanceiro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hasContract: false,
      hasSearch: false,
      loading: false,
      responsavel: {},
      message: 'Nenhuma busca realizada',
      documento: '',
      typeModal: 'empty',
      alertMessage: '',
    };
  }

  handleClear(e) {
    this.setState({
      hasContract: false,
      hasSearch: false,
      loading: false,
      documento: '',
      responsavel: {},
    });
  }

  handleChange(e) {
    const { value } = e.target;
    if (validations.invalidateSpecialCharacters(value) || value === '') {
      this.setState({
        documento: value,
      });
    }
  }

  // componentDidMount() { }
  // async handlePerform(e) {
  //   this.setState({ open: true, typeModal: 'loading' });

  //   const cancelContract = await responsavelFinanceiroService.cancelContract(
  //     this.state.documento
  //   );

  //   console.log('cancelContract', cancelContract);

  //   if (
  //     (cancelContract !== undefined &&
  //       cancelContract.toString().includes('Failed to fetch')) ||
  //     (cancelContract !== undefined &&
  //       cancelContract.toString().includes('Request timed out')) ||
  //     cancelContract.error
  //   ) {
  //     this.setState({
  //       open: true,
  //       typeModal: 'error',
  //     });
  //   } else {
  //     if (cancelContract !== undefined && cancelContract.status == 0)
  //       this.setState({
  //         open: true,
  //         typeModal: 'warning',
  //       });

  //     if (
  //       cancelContract !== undefined &&
  //       cancelContract.message
  //         .toString()
  //         .includes('Documento não encontrado.')
  //     ) {
  //       this.setState({
  //         open: true,
  //         typeModal: 'notFound',
  //         alertMessage: 'Documento não encontrado.',
  //       });
  //     }

  //     if (
  //       (cancelContract !== undefined && cancelContract.status === 1) ||
  //       cancelContract.status === 0
  //     ) {
  //       this.setState({
  //         open: true,
  //         typeModal: 'success',
  //         alertMessage: cancelContract.message,
  //       });
  //     }
  //   }
  // }

  handleVerify(e) {
    if (this.state.documento.length === 11)
      this.setState({ open: true, typeModal: 'dialog' });
    else this.setState({ error: true });
  }

  async financeResponsible() {
    this.setState({
      loading: true,
    });

    if (this.state.documento) {
      const responsavel = await responsavelFinanceiroService.financeResponsible(
        this.state.documento.replace(/\D/g, '')
      );

      if (responsavel !== undefined && responsavel.length === 0) {
        this.setState({
          hasContract: false,
          documento: '',
          open: true,
          loading: false,
          typeModal: 'error',
        });
      }

      if (responsavel[0]) {
        this.setState({
          responsavel: responsavel[0],
        });
      } else {
        this.setState({
          responsavel: responsavel,
        });
      }

      if (
        responsavel !== undefined &&
        this.state.responsavel.hasOwnProperty('document')
      ) {
        this.setState({
          hasContract: true,
          loading: false,
        });
      }

      if (
        responsavel !== undefined &&
        responsavel.toString().includes('Failed to fetch')
      ) {
        this.setState({
          hasContract: false,
          documento: '',
          open: true,
          loading: false,
          typeModal: 'error',
        });
      }

      if (
        (responsavel !== undefined && responsavel.error) ||
        responsavel.responsavel
      ) {
        this.setState({
          hasContract: false,
          open: true,
          loading: false,
          typeModal: 'notFound',
        });
      }
    } else {
      return this.setState({
        loading: false,
        hasContract: false,
      });
    }
  }

  handleCancel(e) {
    this.setState({
      open: false,
    });
    this.handleClear();
  }
  render() {
    const { responsavel, hasContract, message, open, typeModal, loading } =
      this.state;
    let { alertMessage } = this.state;

    return (
      <>
        <section>
          <CustomAlert
            open={open}
            handleCancel={(e) => this.handleCancel(e)}
            handleConfirm={(e) => this.handlePerform(e)}
            typeModal={typeModal}
            message={alertMessage}
          />
          <Title>Responsável Financeiro</Title>
          <Grid justify='center' container spacing={3}>
            <ContainerInput style={{ display: 'flex' }}>
              <Input
                name='documento'
                value={this.state.documento}
                placeholder='RG, CPF ou Passaporte'
                onChange={(e) => this.handleChange(e)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ marginLeft: '10px' }}>
                <button
                  style={{
                    border: 0,
                    borderRadius: 15,
                    height: 30,
                    color: '#fff',
                    backgroundColor: '#409aa5',
                    fontWeight: 'bold',
                    width: 120,
                    cursor: 'pointer',
                    outline: '0',
                  }}
                  onClick={async () => {
                    await this.financeResponsible();
                  }}
                >
                  Buscar
                </button>
              </div>
            </ContainerInput>

            <ContainerGeneric>
              <CustomTable
                message={message}
                object={responsavel}
                hasContract={hasContract}
                loading={loading}
              />
            </ContainerGeneric>

            {hasContract && (
              <ContainerGeneric>
                <ContainerButton>
                  <CustomButtom
                    onClick={(e) => this.handleClear(e)}
                    typeButtom='lightRounded'
                    fontSize='10px'
                    width='167px'
                    size='medium'
                  >
                    Limpar
                  </CustomButtom>
                </ContainerButton>
              </ContainerGeneric>
            )}
          </Grid>
        </section>
      </>
    );
  }
}

export { ResponsavelFinanceiro };
