import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 530px;
  padding-left: 10px;
  max-width: 600px;
  text-align: center;
`;
