import React from 'react';

import { Navigate } from 'react-router-dom';

import { isAccessAuthorized, isRoleAuthorized } from '../../middlewares/access';

const PrivateRoute = ({ component: Component, roles = [] }) => {
  if (isAccessAuthorized() && isRoleAuthorized(roles)) {
    return <Component />;
  }

  return <Navigate to='/login' />;
};

export default PrivateRoute;
