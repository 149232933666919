import { saveAs } from 'file-saver'

export const exportToCsv = (fileName, data, format = 'csv') => {
  const formats = {
    csv: 'data:text/csv;charset=utf-8'
  }

  const blob = new Blob([data], { type: formats[format] })

  saveAs(blob, fileName)
}
