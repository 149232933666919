import React from 'react';
import { Container, ContainerResume, ItensContainer, Group } from './styles';

const Resume = (props) => {
  const { schedulingDate, schedulingTime } = props.user;
  const { telepsicoProgram } = props;

  return (
    <Container>
      <ContainerResume>
        <ItensContainer>
          <Group>
            <div className='title'>Programa</div>
            <div className='description'>{telepsicoProgram}</div>
          </Group>
          <Group>
            <div className='title'>Data</div>
            <div className='description'>{schedulingDate}</div>
          </Group>
          <Group>
            <div className='title'>Horário</div>
            <div className='description'>{schedulingTime}</div>
          </Group>
        </ItensContainer>
      </ContainerResume>
    </Container>
  );
};

export default Resume;
