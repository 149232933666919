import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const forwardingSupport = {
  encaminhamentoSupport,
  updateSupportToStart,
  updateSupportToWait,
};

async function encaminhamentoSupport(user) {
  let data = user;
  const accesUser = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'POST',
    // mode: 'cors',
    headers: {
      'x-access-token': accesUser.accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/support-front`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

async function updateSupportToStart(data) {
  const accesUser = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    // mode: 'cors',
    headers: {
      'x-access-token': accesUser.accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/support-start`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

async function updateSupportToWait(data) {
  const accesUser = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    // mode: 'cors',
    headers: {
      'x-access-token': accesUser.accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/support-wait`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
