export const snackPauseOptions = [
  { label: '20 minutos', value: "20" },
  { label: '30 minutos', value: "30" },
  { label: '40 minutos', value: "40" },
  { label: '50 minutos', value: "50" },
  { label: '60 minutos', value: "60" },
]

export const restPauseOptions = [
  { label: '10 minutos', value: "10" },
  { label: '15 minutos', value: "15" },
  { label: '20 minutos', value: "20" },
  { label: '25 minutos', value: "25" },
  { label: '30 minutos', value: "30" },
]

export const locationOptions = [
  { label: 'Morumbi', value: 'Morumbi' },
  { label: 'Paulista', value: 'Paulista' },
]
