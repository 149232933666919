import React from 'react';

export const menuItensDropdown = [
  {
    description: 'Nome do paciente',
    option: () => {
      setOptionValue('Nome do paciente');
    },
  },
  {
    description: 'Documento',
    option: () => {
      setOptionValue('Documento');
    },
  },
  {
    description: 'Contrato',
    option: () => {
      setOptionValue('Contrato');
    },
  },
  {
    description: 'Passagem',
    option: () => {
      setOptionValue('Passagem');
    },
  },
  {
    description: 'Nome do atendente',
    option: () => {
      setOptionValue('Nome do atendente');
    },
  },
];

export const messageForEmpty =
  'Não há paciente aguardando  nesta fila para ser atendido';

export const selectData = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
];
