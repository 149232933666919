import moment from 'moment';
import WorkerBuilder from '../../../../worker';
import Worker from '../worker';
import { useEffect, useState } from 'react';
import { formatDate, getDiff } from '../../../../utils/format';

export const formatStatusTime = (dateStart) => {
  if (dateStart) {
    const formattedStatusInit = formatDate(dateStart, 'YYYY-MM-DD HH:mm:ss');
    const currentDate = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss');

    const diffToInit = getDiff(currentDate, formattedStatusInit, 'seconds');

    const statusTime = moment.utc((diffToInit + 1) * 1000).format('HH:mm:ss');

    return statusTime;
  }

  return null;
};

const useTableCounter = (useCounter, users = [], date) => {
  const [usersCounter, setUsersCounter] = useState(users);
  const isCurrentDateToday = () => {
    const formattedStatusInit = formatDate(date, 'YYYY-MM-DD');
    const currentDate = formatDate(new Date(), 'YYYY-MM-DD');

    const isToday = getDiff(currentDate, formattedStatusInit, 'days') === 0;

    return isToday;
  };

  useEffect(() => {
    setUsersCounter(users)
  }, [users])

  useEffect(() => {
    const instance = new WorkerBuilder(Worker);

    if (useCounter && users?.length) {
      if (isCurrentDateToday()) {
        const mappedData = users.map((d) => ({
          ...d,
          statusDuration: d.status?.start
            ? formatStatusTime(d.status?.start)
            : '',
        }));
        setUsersCounter(mappedData);
        instance.postMessage(users);
      } else {
        setUsersCounter(users);
      }

      instance.onmessage = ({ data }) => {
        const mappedData = data.map((d) => ({
          ...d,
          statusDuration: d.status?.start
            ? formatStatusTime(d.status?.start)
            : '',
        }));
        setUsersCounter(mappedData);
      };
    }

    return () => {
      instance.terminate();
    };
  }, [useCounter, users]);



  return {
    usersCounter,
  };
};

export default useTableCounter;
