import { historySuporteConstants } from '../constants';

export function filaHistoricoSuporte(state = { historicos: {} }, action) {
  switch (action.type) {
    case historySuporteConstants.SUPORT_HISTORY_SUCCESS:
      return {
        type: 'success',
        historicos: action.filaSuporte,
      };
    case historySuporteConstants.SUPORT_HISTORY_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    case historySuporteConstants.SUPORT_HISTORY_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return Object.assign({}, state, {
        type: 'request',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
  }
}
