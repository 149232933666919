import React from 'react'

export const WarningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <defs>
        <clipPath id="clip-path">
          <rect id="Retângulo_140956" data-name="Retângulo 140956" width="22" height="22" transform="translate(782 723)" fill="#fff"/>
        </clipPath>
      </defs>
      <g id="icon_-_erro" data-name="icon - erro" transform="translate(-782 -723)" clip-path="url(#clip-path)">
        <g id="Elipse_946" data-name="Elipse 946" transform="translate(782 723)" fill="none" stroke="#fff" stroke-width="2">
          <circle cx="11" cy="11" r="11" stroke="none"/>
          <circle cx="11" cy="11" r="10" fill="none"/>
        </g>
        <text id="_" data-name="!" transform="translate(793 739.5)" fill="#fff" font-size="14" font-family="Inter" font-weight="600"><tspan x="-2" y="0">!</tspan></text>
      </g>
    </svg>
  )
}
