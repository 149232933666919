// import React from 'react'
// import { Route, Redirect } from 'react-router-dom'

// const handleRoute = (perfil) => {
//   if (!localStorage.getItem('user')) return false
//   const user = JSON.parse(localStorage.getItem('user'))
//   return Number(perfil.includes(user?.user?.id_perfil?.descricao))
// }

// export const PrivateRoute = ({ component: Component, array, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       handleRoute(array) ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{ pathname: '/login', state: { from: props.location } }}
//         />
//       )
//     }
//   />
// )
import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children, auth }) => {
  const handleRoute = (perfil) => {
    if (!localStorage.getItem('user')) {
      return false;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    return (
      Number(perfil.includes(user?.user?.id_perfil?.descricao)) ||
      Number(perfil.includes(user?.user?.access[0]?.profile?.description))
    );
  };

  return handleRoute(auth) ? children : <Navigate to='/login' replace />;
};
