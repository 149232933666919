import React from 'react';
import { Container, Label, Input } from './styles';

export const CheckBox = ({ type, name, textLabel, disabled, margin }) => {
  return (
    <Container margin={margin}>
      <Input type={type} name={name} id={name} disabled={disabled} />
      <Label htmlFor={name}>{textLabel}</Label>
    </Container>
  );
};
