// @ts-nocheck
import React from 'react';
import styled, { css } from 'styled-components';
import { styled as styledMui } from '@material-ui/core/styles';
import { Dialog, Grid } from '@material-ui/core';

export const Card = styled.div`
  background: #fff;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
  padding: 20px;
`;

export const GridBox = styledMui(Grid)({
  padding: '8px  0 16px 0',
});

export const ShowModal = styledMui(Dialog)({
  '& .MuiDialogTitle-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiDialog-paper': {
    borderRadius: 20,
    width: '600px',
  },
});

export const Paragraph = styled.div`
  padding: 5px 0;
`;

export const ReportCsv = styledMui(Dialog)({
  '& .MuiDialogTitle-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiDialog-paper': {
    borderRadius: 20,
    width: '400px',
  },
});

type ButtonProps = {
  color: string;
  borderColor: string;
};

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
`;

export const Button = styled.button<ButtonProps>`
  ${({ color, borderColor }) => css`
    color: ${color};
    cursor: pointer;
    background: #ffffff;
    border: 1px ${borderColor} solid;
    border-radius: 18px;
    box-shadow: none;
    font-size: 10px;
    height: 36px;
    width: 160px;
  `}
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FilterItem = styled.div`
  padding: 1px;
  display: flex;
`;
