import React from 'react';
import { ContainerTable, ContainerLoading } from './styles';
import { CircularProgress } from '@material-ui/core';
import { formatToCNPJ, formatToPhone } from 'brazilian-values';

export const CustomTable = ({
  message,
  width = '100%',
  hasContract,
  object,
  loading,
}) => {
  const { name, lastName, birthDate, document, userId } = object;

  return (
    <>
      {loading ? (
        <ContainerLoading>
          {' '}
          <CircularProgress color='primary' />
        </ContainerLoading>
      ) : (
        <ContainerTable hasContract={hasContract}>
          {hasContract === false ? (
            <div>{message}</div>
          ) : (
            <table
              width={width}
              border='1'
              cellSpacing='0'
              cellPadding='0'
              responsive
            >
              <tbody>
                <tr>
                  <td
                    width='885px'
                    align='center'
                    colSpan='3'
                    style={{
                      border: ' 1px solid #6c6c6c',
                      height: '60px',
                    }}
                  >
                    <span className='title-table'>Responsavel</span>
                  </td>
                </tr>
                <tr>
                  <td
                    width='295px'
                    align='center'
                    style={{
                      border: ' 1px solid #6c6c6c',
                      height: '60px',
                    }}
                  >
                    <span className='title-table'>Nome</span>
                  </td>
                  <td width='295' align='center'>
                    <span className='title-table'>Data de Nascimento</span>
                  </td>
                  <td width='295' align='center'>
                    <span className='title-table'>Documento</span>
                  </td>
                </tr>

                <tr>
                  <td
                    align='center'
                    className='text-table'
                    style={{ height: '60px' }}
                  >
                    {name ? name + ' ' + lastName : ''}
                  </td>
                  <td align='center' className='text-table'>
                    {birthDate ? birthDate.toString('dd-MM-yyyy') : ''}
                  </td>
                  <td align='center' className='text-table'>
                    {document ? document : ''}
                  </td>
                </tr>

                <tr>
                  <td
                    width='885px'
                    align='center'
                    colSpan='3'
                    style={{
                      border: ' 1px solid #6c6c6c',
                      height: '60px',
                    }}
                  >
                    <span className='title-table'>Dependente</span>
                  </td>
                </tr>
                <tr>
                  <td
                    width='295px'
                    align='center'
                    style={{
                      border: ' 1px solid #6c6c6c',
                      height: '60px',
                    }}
                  >
                    <span className='title-table'>Nome</span>
                  </td>
                  <td width='295' align='center'>
                    <span className='title-table'>Data de Nascimento</span>
                  </td>
                  <td width='295' align='center'>
                    <span className='title-table'>Documento</span>
                  </td>
                </tr>
                <tr>
                  <td
                    align='center'
                    className='text-table'
                    style={{ height: '60px' }}
                  >
                    {userId?.name ? userId.name + ' ' + userId?.lastName : ''}
                  </td>
                  <td align='center' className='text-table'>
                    {userId?.birthDate
                      ? userId.birthDate.toString('dd-MM-yyyy')
                      : ''}
                  </td>
                  <td align='center' className='text-table'>
                    {userId?.document ? userId.document : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </ContainerTable>
      )}
    </>
  );
};
