import request from '../request';

const execute = async ({ date, limit, offset, exactlyDate = false }) => {
  const query = `?date=${date}&limit=${limit}&offset=${offset}&exactlyDate=${exactlyDate}`;

  try {
    const response = await request.get(`/grid/scales${query}`);


    if (response.err) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
