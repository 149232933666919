import styled from 'styled-components';

export const Wrapper = styled.div`
  .MuiDialogTitle-root {
    padding: 24px 16px;

    .MuiTypography-root {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-right: 0px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogActions-root {
    padding: 0;
  }

  padding-bottom: 24px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 18px;
`;

interface ContentWrapperProps {
  $shouldRenderAsAForm?: boolean;
}
export const ContentWrapper = styled('div').attrs<ContentWrapperProps>(
  ({ $shouldRenderAsAForm }) => ({
    as: $shouldRenderAsAForm ? 'form' : 'div',
  })
)<ContentWrapperProps>`
  padding: 0px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const ActionsWrapper = styled.footer`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  column-gap: 16px;

  button {
    width: 100%;
  }
`;

export const LoaderWrapper = styled.div`
  height: 20px;
  overflow: hidden;

  & > div {
    margin-top: -20px;
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
  }
`;

export const ErrorMessage = styled.div`
  padding: 28px;
  font-size: 18px;
  font-weight: 500;
  color: #2e384d;
  text-align: center;
`;
