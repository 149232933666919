import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const ContentSearch = styled.div`
  width: 440px;
  height: 40px;
  border: solid 1px #a7abb24d;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  font-family: 'rubikregular', sans-serif;
  background: #fff;
`;

export const IconInput = styled.div`
  display: flex;
  align-items: center;
  transition: 5s ease;
  div:hover {
    transform: scale(1.2);
    transition: 1s ease;
  }
`;

export const Icon = styled.button`
  position: absolute;
  right: 0;
  width: 50px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed ' : 'pointer')};
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearSearchField = styled.button`
  position: absolute;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: transparent;
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const InputField = styled(InputMask)`
  outline: none;
  border: none;
  margin-left: 10px;
  width: 210px;
  display: flex;
  justify-content: flex-end;
`;
