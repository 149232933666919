import { useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import { PageProps } from '../types';

import { alertActions } from '../../actions';

import { SnackbarItem } from './snackbar-item';

interface LoginAlertsProps {
  pageProps: PageProps;
}

export function LoginAlerts({ pageProps }: LoginAlertsProps) {
  const handleCloseAlert = useCallback(() => {
    if (pageProps)
      pageProps.dispatch(
        alertActions.clear(pageProps, pageProps.type, pageProps.message)
      );
  }, [pageProps]);

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      open={pageProps.open}
      onClose={handleCloseAlert}
    >
      <SnackbarItem {...pageProps} onClose={handleCloseAlert} />
    </Snackbar>
  );
}
