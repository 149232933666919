import { store } from '../../store';

import types from './types';

import services from '../../services';

const pause = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.pause.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.pause.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.pause.error,
      data,
    });
  },
};

const status = {
  execute: async () => {
    await store.dispatch({
      type: types.states.status.execute,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.status.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.status.error,
      data,
    });
  },
};

const unlock = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.unlock.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.unlock.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.unlock.error,
      data,
    });
  },
};

const requestUnlock = {
  execute: async (data) => {
    await store.dispatch({
      type: types.states.requestUnlock.execute,
      data,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.requestUnlock.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.requestUnlock.error,
      data,
    });
  },
};

const nextPause = {
  execute: async () => {
    await store.dispatch({
      type: types.states.nextPause.execute,
    });
  },

  success: async (data) => {
    await store.dispatch({
      type: types.states.nextPause.success,
      data,
    });
  },

  error: async (data) => {
    await store.dispatch({
      type: types.states.nextPause.error,
      data,
    });
  },
};

const servicePause = async (data) => {
  await pause.execute(data);

  const { start, intervalType, reactivateStation } = data;
  const response = await services.schedule.schedulePause({
    start,
    intervalType,
    reactivateStation,
  });
  if (response.err) {
    await pause.error(response.data);
    return response;
  }

  await pause.success(response.data);
  return response;
};

const serviceStatus = async (userId) => {
  await status.execute(data);

  const response = await services.schedule.scheduleStatus({ userId });
  if (response.err) {
    await status.error(response.data);
    return response;
  }

  await status.success(response.data);
  return response;
};

const serviceUnlock = async (data) => {
  try {
    await unlock.execute(data);

    const response = await services.schedule.scheduleUnlock({
      userIdBlock: data,
    });

    if (response.err) {
      await unlock.error(response.data);
      return response;
    }

    await unlock.success(response.data);
    return response;
  } catch (error) {
    return {
      err: true,
      data: {
        error,
      },
    };
  }
};

const serviceRequestUnlock = async (message) => {
  try {
    await requestUnlock.execute(data);

    const response = await services.schedule.scheduleRequestUnlock({
      userIdBlock: userId,
      motive: message,
    });
    if (response.err) {
      await requestUnlock.error(response.data);
      return response;
    }

    await requestUnlock.success(response.data);
    return response;
  } catch (error) {
    return {
      err: true,
      data: {
        error,
      },
    };
  }
};

const serviceNextPause = async (data) => {
  await nextPause.execute(data);

  const response = await services.schedule.scheduleNextPause();
  if (response.err) {
    await nextPause.error(response.data);
    return response;
  }

  await nextPause.success(response.data);
  return response;
};

const data = {
  servicePause,
  serviceUnlock,
  serviceStatus,
  serviceRequestUnlock,
  serviceNextPause,
};

export default data;
