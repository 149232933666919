import { useState, useEffect } from 'react';

const useSelect = (value, placeholder = null, options) => {
  const initialState = {
    value: 0,
    label: placeholder || '',
  };

  const [useSelectData, setSelectData] = useState(null);

  const findInitialStateInOptions = () =>
    options.filter((option) => option.value === value);

  useEffect(() => {
    if (value === '') {
      setSelectData(initialState);
    } else {
      if (typeof options !== 'function' && options?.length) {
        const option = findInitialStateInOptions();

        setSelectData(option);
      }
    }
  }, [value]);

  return {
    setSelectData,
    useSelectData,
  };
};

export default useSelect;
