import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { withStyles } from '@material-ui/core/styles';
import { userActions, alertActions } from '../actions';
import { validations } from '../helpers/validations';

import Alert from '../components/Alert';
import Loading from '../components/Loading';

import EyeOpen from '../icons/icon_olho_aberto.svg';
import EyeClose from '../icons/icon_olho_fechado.svg';

import { ReWrapper, resetReCaptcha } from '../ReWrapper';

import {
  CompanyImage,
  Logo,
  Container,
  LoginContainer,
  LogoContainer,
  LoginWrapper,
  LoginTitle,
  LoginHeader,
  Subtitle,
  Label,
  InputText,
  ContainerInput,
  ButtonBlueContained,
  ButtonBlueOutlined,
  Eye,
  TogglePasswordVisibleBtn,
  ContainerPassword,
  InputTextPassword,
  LabelContainer,
  LabelBlue,
  LabelError,
} from './style';

const styles = (theme) => ({
  sucess: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  loginModal: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // width: '100%',
    // minWidth: '300px',
    // fontFamily:"Poppins",
    padding: '25% 5%',
    // backgroundColor:"white",
    // borderRadius: '10px',
  },
  buttons: {
    width: '100%',
    margin: '30px 0',
    color: 'F1F1F1',
    borderRadius: '25%',
  },
  img_inputs: {
    //top: '10px !important',
    width: '20px',
    height: '20px',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    flexBasis: 200,
  },
  input: {
    width: '100%',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  distanceTop: {
    //padding: '20px 0px 0px 0px',
    '@media only screen and (max-width: 900px)': {
      '@media screen and (orientation: landscape)': {
        padding: '10px',
      },
    },
  },
  logoConecta: {
    textAlign: 'center',
    marginTop: '40px',
    '@media only screen and (max-width: 900px)': {
      '@media screen and (orientation: landscape)': {
        marginTop: '0px',
      },
    },
  },
  alertBoxSucess: {
    zIndex: '10001 !important',
    backgroundColor: theme.palette.error.dark,
  },
  alertBoxError: {
    zIndex: '10001 !important',
    backgroundColor: green[600],
  },
  alertText: {
    fontSize: '11px',
    color: 'red',
    marginBottom: '10px',
  },
  displayNone: {
    display: 'none',
  },
  displayShow: {
    display: 'block',
  },
});

class LoginPageRobot extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.props.dispatch(alertActions.clear());
    this.state = {
      username: '',
      password: '',
      formValido: '',
      vertical: 'top',
      horizontal: 'center',
      loadingsub: false,
      submitted: false,
      controlModels: false,
      isVisiblePassword: false,
      invalidLogin: false,
      flag: 'LOG_IN',
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModels = this.handleModels.bind(this);
    this.handleSubmitRecoverPassword =
      this.handleSubmitRecoverPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validationLogin = this.validationLogin.bind(this);
    this.handleOpenEye = this.handleOpenEye.bind(this);
  }

  handleClose = (type, message) => {
    this.props.dispatch(alertActions.clear(this.props, type, message));
    this.setState({ open: false, loadingsub: false });
  };

  handleModels() {
    //this.setState({ controlModels: !this.state.controlModels });
    this.setState({ flag: 'RECOVER_LOGIN' });
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e, rota) {
    e.preventDefault();
    const { username, password } = this.state;
    const { dispatch } = this.props;

    if (username && password) {
      this.setState({ loadingsub: true });
      await dispatch(userActions.loginV2Robot(username, password, this));
    } else {
      this.setState({ submitted: true });
    }
    // resetReCaptcha();
  }

  async handleSubmitRecoverPassword(e) {
    e.preventDefault();
    const { email, formValido } = this.state;
    const { dispatch } = this.props;
    if (email && formValido) {
      //this.setState({ loadingsub: true });
      await dispatch(userActions.RecoverPassword(email, this));
      this.setState({ flag: 'RECOVER_SUCCESS', email: '' });
    } else {
      this.setState({ submitted: true });
    }
  }

  validationLogin(e) {
    const campo = e.target.name;
    const value = e.target.value;
    if (campo === 'username') {
      this.state.emailValid = validations.validationEmail(value);
    }
    if (campo === 'email') {
      this.state.emailValid = validations.validationEmail(value);
    }
    if (this.state.emailValid === false) {
      this.setState({ formValido: true });
    } else {
      this.setState({ formValido: false });
    }
  }

  handleOpenEye() {
    this.setState({ isVisiblePassword: !this.state.isVisiblePassword });
  }

  render() {
    const { open, classes, message, loading, type } = this.props;
    const { username, password, loadingsub, submitted, controlModels, email } =
      this.state;

    return (
      <Container>
        <LoginContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>

          {this.state.flag === 'LOG_IN' && (
            <LoginWrapper>
              {/* <Login> */}
              {loadingsub && !loading && <Loading></Loading>}
              <Alert
                open={open}
                type={type}
                message={message}
                onClose={() => this.handleClose(type, message)}
              />
              <LoginHeader>
                <LoginTitle style={{ margin: '3px 0' }}>
                  Seja bem-vindo!
                </LoginTitle>
                <Subtitle>Entre com seu usuário e senha.</Subtitle>
              </LoginHeader>

              <form onSubmit={this.handleSubmit} autocomplete='off'>
                <ContainerInput>
                  <Label style={{ margin: '5px 0' }}>Usuário</Label>
                  <InputText
                    name='username'
                    id='input-with-icon-adornment'
                    onBlur={this.validationLogin}
                    onChange={this.handleChange}
                    value={username}
                    placeholder='Seu usuário'
                    style={{
                      width: 'calc(100%)',
                      borderColor:
                        ((submitted && !username) || this.state.invalidLogin) &&
                        '#ff6347',
                      boxShadow: submitted && !username && '0 0 6px #3381ee33',
                    }}
                  />
                </ContainerInput>
                <Label>Senha</Label>
                <ContainerPassword
                  style={{
                    borderColor:
                      ((submitted && !password) || this.state.invalidLogin) &&
                      '#ff6347',
                  }}
                >
                  <InputTextPassword
                    name='password'
                    id='password'
                    onBlur={this.validationLogin}
                    onChange={this.handleChange}
                    value={password}
                    placeholder='Sua senha'
                    style={{
                      paddingRight: '45px',
                      width: 'calc(100%)',
                      border: 'none',
                      boxShadow: submitted && !password && '0 0 6px #3381ee33',
                    }}
                    type={this.state.isVisiblePassword ? 'text' : 'password'}
                  />
                  {this.state.isVisiblePassword ? (
                    <Eye src={EyeOpen} onClick={this.handleOpenEye} />
                  ) : (
                    <Eye src={EyeClose} onClick={this.handleOpenEye} />
                  )}
                </ContainerPassword>
                <LabelContainer>
                  <LabelBlue onClick={this.handleModels}>
                    Esqueceu sua senha?
                  </LabelBlue>
                </LabelContainer>
                <ContainerInput>
                  <ButtonBlueContained type='submit' fullWidth>
                    ENTRAR
                  </ButtonBlueContained>
                </ContainerInput>

                {submitted && (!username || !password) && (
                  <>
                    <LabelError>Campo obrigatório.</LabelError>
                    <LabelError>
                      Por favor, preencha e tente novamente.
                    </LabelError>
                  </>
                )}

                {this.state.invalidLogin && (
                  <>
                    <LabelError>Usuário e/ou senha inválidos.</LabelError>
                    <LabelError>
                      Por favor, verifique e tente novamente.
                    </LabelError>
                  </>
                )}
              </form>
              {/* </Login> */}
            </LoginWrapper>
          )}

          {this.state.flag === 'RECOVER_LOGIN' && (
            <LoginWrapper>
              <form onSubmit={this.handleSubmit}>
                <LoginHeader>
                  <LoginTitle>Recuperação de senha.</LoginTitle>
                  <Subtitle>
                    Informe abaixo o seu <strong>e-mail cadastrado</strong> e
                    receba instruções para alterar sua senha.
                  </Subtitle>
                </LoginHeader>
                <ContainerInput>
                  <Label
                    style={{
                      marginTop: '12px',
                      marginBottom: '6px',
                    }}
                  >
                    E-mail
                  </Label>
                  <InputText
                    name='email'
                    onBlur={this.validationLogin}
                    onChange={this.handleChange}
                    className={classNames(
                      //classes.input,
                      classes.distanceTop
                    )}
                    value={email}
                    placeholder='Seu e-mail'
                    style={{
                      width: 'calc(100%)',
                      borderColor:
                        ((submitted && !email) ||
                          (this.state.emailValid && email != '')) &&
                        '#ff6347',
                      boxShadow: submitted && '0 0 6px #3381ee33',
                    }}
                  />
                </ContainerInput>

                {submitted && !email && (
                  <div className={classes.alertText}>Campo obrigatório.</div>
                )}
                {this.state.emailValid && email != '' && (
                  <div className={classes.alertText}>E-mail inválido.</div>
                )}

                <ContainerInput>
                  <ButtonBlueContained
                    onClick={this.handleSubmitRecoverPassword}
                  >
                    ENVIAR
                  </ButtonBlueContained>

                  <ButtonBlueOutlined
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      this.setState({ flag: 'LOG_IN', email: '' });
                    }}
                  >
                    Voltar
                  </ButtonBlueOutlined>
                </ContainerInput>
              </form>
            </LoginWrapper>
          )}
          {this.state.flag === 'RECOVER_SUCCESS' && (
            <LoginWrapper>
              <form onSubmit={this.handleSubmit}>
                <LoginHeader>
                  <LoginTitle>
                    Verifique a caixa de entrada do seu e-mail
                  </LoginTitle>
                  <Subtitle>
                    Enviamos para o e-mail informado as instruções para alterar
                    sua senha.
                  </Subtitle>
                </LoginHeader>
                <div>
                  <ButtonBlueContained
                    style={{ marginTop: '22px' }}
                    onClick={() => {
                      this.setState({ flag: 'LOG_IN', username: '' });
                    }}
                  >
                    Voltar para a tela de login
                  </ButtonBlueContained>
                </div>
              </form>
            </LoginWrapper>
          )}
        </LoginContainer>
        <CompanyImage />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, submitted } = state.authentication;
  const { message, type, open, loading } = state.alert;
  return {
    loggingIn,
    submitted,
    message,
    type,
    open,
    loading,
  };
}

LoginPageRobot.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(styles)(
  connect(mapStateToProps)(LoginPageRobot)
);
export { connectedLoginPage as LoginPageRobot };
