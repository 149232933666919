import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 568px) {
    .rmdp-container {
      width: 100%;

      input {
        width: 100% !important;
        box-sizing: border-box;
      }
    }
  }
`;
