import React from 'react';

import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';

const white = '#fff';
const primary = '#2699fb';
const danger = '#FF2424';
const success = '#33AC2E';

const getColor = (color) => {
  const colors = {
    light: white,
    info: primary,
    danger: danger,
    success: success,
  };

  return colors[color] || colors.info;
};

export const MaterialCircularProgress = styled(
  ({ customColor, ...otherProps }) => (
    <CircularProgress {...otherProps} color='inherit' />
  )
)`
  color: ${({ customColor }) => getColor(customColor)};

  animation: fadeInAnimation 1s;
  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
