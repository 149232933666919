import styled from 'styled-components'

type WrapperProps = {
  severity: string
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 524px;
  height: 50px;
  padding: 0 15px;
  border-radius: 4px;

  ${props => props.severity === 'error' && `
    background-color: #D32f2f;
  `}

  ${props => props.severity === 'success' && `
    background-color: #2e7D32;
  `}
`

export const Message = styled.div`
  min-width: 85%;
  font: normal normal 600 14px/18px Inter;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 22px;
`