import { acionamentoSupportConstants } from '../constants';

export function supportAcionamento(state = { supportAcionamento: {} }, action) {
  switch (action.type) {
    case acionamentoSupportConstants.ACIONAMENTO_SUPPORT_SUCCESS:
      return {
        type: 'success',
        supportAcionamento: action.acionamento,
      };
    case acionamentoSupportConstants.ACIONAMENTO_SUPPORT_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        supportAcionamento: {},
      });
    case acionamentoSupportConstants.ACIONAMENTO_SUPPORT_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
