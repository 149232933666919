import moment from 'moment';

import {
  formatDate,
  getDiff,
  addDuration,
  isHourAfter,
} from '../../../../../../utils/format';

const formatSecondsToTime = (totalSeconds) => {
  const concatenetedNumber = () => {
    const concatNumberWithString = `0${totalSeconds % 60 < 0 ? 0 : totalSeconds % 60}`
    return concatNumberWithString
  }

  const hours =
    Math.trunc(totalSeconds / 3600) > 9
      ? Math.trunc(totalSeconds / 3600)
      : `0${Math.trunc(totalSeconds / 3600)}`;
  const totalSecondsInHours = totalSeconds % 3600;
  const minutes =
    Math.trunc(totalSecondsInHours / 60) > 9
      ? Math.trunc(totalSecondsInHours / 60)
      : `0${Math.trunc(totalSecondsInHours / 60)}`;
  const seconds =
    totalSeconds % 60 > 9 ? totalSeconds % 60 : concatenetedNumber();

  return `${hours}:${minutes}:${seconds}`;
};

export const formatStatusTime = (dateStart) => {
  if (dateStart) {
    const formattedStatusInit = formatDate(dateStart, 'YYYY-MM-DD HH:mm:ss');
    const currentDate = formatDate(moment(), 'YYYY-MM-DD HH:mm:ss');

    const diffToInit = getDiff(currentDate, formattedStatusInit, 'seconds');

    return formatSecondsToTime(diffToInit);
  }

  return null;
};

export const hasTimeExpired = (interval, pauseDuration) => {
  if (typeof pauseDuration !== 'number') {
    return false;
  }

  const intervalEnd = formatDate(
    addDuration(interval, pauseDuration, 'minutes'),
    'MM/DD/YYYY HH:mm:ss'
  );

  const currentDate = moment().format('MM/DD/YYYY HH:mm:ss');

  return isHourAfter(currentDate, intervalEnd);
};

export const isStationLocked = (interval, pauseDuration) => {
  if (typeof pauseDuration !== 'number') {
    return false;
  }

  const intervalEnd = formatDate(
    addDuration(interval, pauseDuration - 1, 'minutes'),
    'MM/DD/YYYY HH:mm:ss'
  );
  const currentDate = moment().format('MM/DD/YYYY HH:mm:ss');

  return isHourAfter(intervalEnd, currentDate);
};

export const calculateFraction = (dateStart, pauseDuration) => {
  if (typeof pauseDuration !== 'number') {
    return '283';
  }

  const currentDate = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss');
  const formattedStatusInit = formatDate(dateStart, 'YYYY-MM-DD HH:mm:ss');
  const formattedStatusEnd = formatDate(
    addDuration(formattedStatusInit, pauseDuration, 'minutes'),
    'YYYY-MM-DD HH:mm:ss'
  );

  const diffFromNow = getDiff(formattedStatusEnd, currentDate, 'seconds');
  const diffFromStart = getDiff(
    formattedStatusEnd,
    formattedStatusInit,
    'seconds'
  );

  const rawTimeFraction = diffFromNow / diffFromStart;

  const totalFraction =
    rawTimeFraction - (1 / diffFromStart) * (1 - rawTimeFraction);

  const currentFraction = `${(totalFraction * 283).toFixed(0)} 283`;

  return currentFraction;
};
