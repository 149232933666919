import request from '../request';

const execute = async ({ dates, limit, offset }) => {

  const query = `?dateBegin=${dates[0]}&dateEnd=${dates[1]}&limit=${limit}&offset=${offset}`;
  try {
    const response = await request.get(`/grid/scales/me${query}`);


    if (response.err) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
