import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledCountdown = styled.div`
  position: relative;
  width: 300px;
  height: 300px;

  svg {
    .base-timer__svg {
      transform: scaleX(-1);
    }

    .base-timer__circle {
      fill: none;
      stroke: none;
    }

    .base-timer__path-elapsed {
      stroke-width: 2px;
      stroke: #d7d7d7;
    }

    .base-timer__path-remaining {
      stroke-width: 7px;
      stroke-linecap: round;
      transform: rotate(270deg);
      transform-origin: center;
      transition: 1s linear all;
      fill-rule: nonzero;
      stroke: skyblue;
    }

    .base-timer__path-done {
      stroke-width: 7px;
      stroke-linecap: round;
      transform: rotate(270deg);
      transform-origin: center;
      transition: 1s linear all;
      fill-rule: nonzero;
      stroke: red;
    }
  }

  p {
    font-weight: 900;
    font-size: 32px;
  }
  span {
    font-size: 20px;
    color: #8798ad;
  }
`;

export const StyledCountDownLabel = styled.div`
  text-align: center;
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  p {
    font-weight: 900;
    font-size: 32px;
  }
  span {
    font-size: 20px;
    color: #8798ad;
  }
`;
