import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../../../../../../../components/Lib/ModalDialog/index';
import ModalAlert from '../../../../../../../components/Lib/ModalAlert';
import {
  CustomAccordion,
  CustomButton,
  CustomSnackbar,
} from '../../../../../../../Spread/components/ui';
import { FiPhone as FiPhoneIcon } from 'react-icons/fi';

import { PatientObservations, ModalNotAttend } from './components';

import {
  StyledWrapper,
  StyledQuickActionsWrapper,
  PushCallsCardTitle,
  PushCallsCardItem,
  PushCallsCardTimes,
} from './styles';

import { usePatientData } from './hooks/usePatientData';

import { useNavigator } from '../../../../../../../Spread/hooks';

import {
  ContainerSelectModal,
  SelectTitle,
  ValueCheck,
} from '../../../../../QueuePage/styles';

import GenericSelect from '../../../../../GenericSelect';

import {
  supportReason,
  objectForFoward,
  supportReason,
  removeReason,
} from '../../../../FormAdm/config';

import {
  actionForwarding,
  modalAlertActions,
  alertActions,
  acionamentoActionsVonage,
  acionamentoActions,
} from '../../../../../../../actions';
import { history } from '../../../../../../../helpers';
import { CancelScheduleModal } from '../../../../../../Modals/CancelScheduleModal';
import Alert from '../../../../../../Alert';

const PatientData = ({ data, onSave, encounter }) => {
  const { snack, trigger } = CustomSnackbar();
  const { push } = useNavigator();

  const memorizedData = useMemo(() => data, [JSON.stringify(data)]);
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [reasonSupport, setReasonSupport] = useState(0);
  const [suportError, setSuportError] = useState(false);
  const [itemFoward, setItemFoward] = useState({});
  const dispatch = useDispatch();
  const newModalAlert = useSelector((state) => state.modalAlert);
  const { messageModalAlert, openModalAlert, typeModalAlert } = newModalAlert;
  const [openScheduleCancel, setOpenScheduleCancel] = useState(false);
  const [reasonScheduleCancel, setReasonScheduleCancel] = useState(0);
  const [reasonScheduleOthers, setReasonScheduleOthers] = useState(false);
  const [reasonScheduleOthersInput, setReasonScheduleOthersInput] =
    useState('');
  const [reasonScheduleOthersError, setReasonScheduleOthersError] =
    useState(false);
  const [itemSchedule, setItemSchedule] = useState({});
  const [showContentScheduleError, setShowContentScheduleError] =
    useState(true);
  const [processingScheduleRequest, setProcessingScheduleRequest] =
    useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [reasonRemove, setReasonRemove] = useState(0);
  const [itemDelete, setItemDelete] = useState({});

  const alertProps = useSelector((state) => state.alert);

  const {
    editAcionamento,
    triggerStatus,
    useModalNotAttend,
    setModalNotAttend,
    setTriggerStatus,
    chipColor,
    formatPushCallTime,
  } = usePatientData(trigger);

  const handleCloseModalSupport = () => {
    setReasonSupport(0);
    setOpenModalSupport(false);
  };

  const handleConfirmSupport = () => {
    const { data } = itemFoward;

    if (reasonSupport === '' || reasonSupport === 0) {
      setSuportError(true);
    } else {
      setSuportError(false);
      fowardQueueItem(data, reasonSupport);
      setOpenModalSupport(false);
    }
  };

  const handleChangeReasonSupport = (e) => {
    setReasonSupport(e.target.value);
  };

  const fowardQueueItem = async (data, reason) => {
    const locallUser = JSON.parse(localStorage.getItem('user'));
    objectForFoward.origin.from = locallUser?.user?.id_perfil?.descricao;
    objectForFoward.originProfessional.name = locallUser.user.nome;
    objectForFoward.originProfessional.id = locallUser.user._id;
    data.reason = reason;
    data.form = true;
    const dataForFowarding = { ...data, ...objectForFoward };
    dispatch(actionForwarding.forwarding(dataForFowarding));
  };

  const handleReason = (e) => {
    setReasonRemove(e.target.value);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = () => {
    const { id, data } = itemDelete;
    if (reasonRemove === '' || reasonRemove === 0) {
      setDeleteError(true);
    } else {
      setDeleteError(false);
      deleteQueueItem(id, reasonRemove, data);
      setOpenDelete(false);
    }
  };

  async function deleteQueueItem(id, reason, pacientData) {
    try {
      dispatch(
        acionamentoActions.deleteAcionamentosNew(id, reason, pacientData)
      );
      dispatch(alertActions.success('Acionamento excluído com sucesso!'));
    } catch (erro) {
      console.log(erro);
    }
  }

  const handleCloseNewModal = () => {
    if (messageModalAlert?.includes('redirecionado')) {
      history.push('/adm');
      dispatch(modalAlertActions.clear());
    } else {
      dispatch(modalAlertActions.clear());
      history.push('/adm/acionamento/new');
    }
  };

  const handleCloseCancelScheduleModal = () => {
    setOpenScheduleCancel(false);
  };

  const handleCloseAlert = () => {
    dispatch(alertActions.clear());
  };

  const handleReasonSchedule = (e) => {
    setReasonScheduleOthers(false);
    setReasonScheduleOthersError(false);
    if (e.target.value == 'Outros') setReasonScheduleOthers(true);
    setReasonScheduleCancel(e.target.value);
  };

  const handleChangeScheduleOthersInput = (e) => {
    setReasonScheduleOthersInput(e.target.value);
  };

  const handleConfirmChangeScheduleOther = async () => {
    setProcessingScheduleRequest(true);
    const reasonSelected = reasonScheduleCancel;
    const reasonInputOther = reasonScheduleOthersInput;
    const selectedAttendance = itemSchedule;
    if (
      !reasonScheduleOthersInput &&
      reasonScheduleOthers &&
      reasonSelected == 'Outros'
    ) {
      setProcessingScheduleRequest(false);
      return setReasonScheduleOthersError(true);
    }

    console.log('reasonSelected', reasonSelected);
    if (!reasonSelected) {
      setProcessingScheduleRequest(false);
      return setReasonScheduleOthersError(true);
    }
    let showContentCondition =
      selectedAttendance && selectedAttendance.idSchedule ? true : false;
    if (!showContentCondition) {
      setProcessingScheduleRequest(false);
      return setShowContentScheduleError(showContentCondition);
    }
    const objectRequest = {
      reasonSelected,
      reasonInputOther,
      ...selectedAttendance,
    };
    await acionamentoActionsVonage.cancelScheduleAction(
      objectRequest,
      (text) => {
        dispatch(alertActions.success(text));
        setOpenScheduleCancel(false);
      },
      () => {
        setProcessingScheduleRequest(false);
        setShowContentScheduleError(false);
      }
    );
  };

  const accordionItems = [
    {
      title: 'Visualizar',
      subtitle: 'Observações do paciente',
      body: () => <PatientObservations data={memorizedData} onSave={onSave} />,
    },
    {
      title: 'Ações',
      subtitle: 'Rápidas',
      body: () => (
        <StyledQuickActionsWrapper>
          {!encounter.forkedFrom && (
            <CustomButton
              text='Encaminhar para suporte'
              icon='SendOutlined'
              iconDir='l'
              type='outlined'
              onClick={() => {
                setItemFoward({ data: editAcionamento });
                setOpenModalSupport(true);
              }}
              full
            />
          )}

          <CustomButton
            text='Remover'
            icon='Delete'
            iconDir='l'
            type='outlined'
            onClick={() => {
              setItemDelete({
                id: editAcionamento._id,
                data: editAcionamento,
              });
              setOpenDelete(true);
            }}
            full
          />

          {triggerStatus === 'IN_WAIT_FOR_DATE_SCHEDULE' && (
            <>
              <CustomButton
                text='Contato sem sucesso'
                icon='CallMissedOutgoing'
                iconDir='l'
                type='outlined'
                onClick={() => {
                  setTriggerStatus('IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE');
                }}
                full
              />
              <CustomButton
                text='Confirmar agendamento'
                icon='EventAvailable'
                iconDir='l'
                type='outlined'
                onClick={() => {
                  setTriggerStatus('IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE');
                }}
                full
              />
              <CustomButton
                text='Continuar Atendimento'
                icon='AssignmentInd'
                iconDir='l'
                type='outlined'
                onClick={() => {
                  setTriggerStatus('IN_VONAGE_ATTENDANCE_RECEPCIONIST');
                }}
                full
              />
            </>
          )}
        </StyledQuickActionsWrapper>
      ),
    },
  ];

  const pushCallAccordion = {
    title: 'Push Call',
    subtitle: (
      <PushCallsCardTitle
        color={chipColor(encounter.pushCalls?.length || 0).color}
        backgroundColor={
          chipColor(encounter.pushCalls?.length || 0).backgroundColor
        }
      >
        <div>Tentativas de Contato</div>
        <div className='chip'>
          <FiPhoneIcon className='icon' />
          <div>{encounter.pushCalls?.length || 0}</div>
        </div>
      </PushCallsCardTitle>
    ),
    body: () => (
      <>
        {encounter.pushCalls?.length > 0 ? (
          <PushCallsCardTimes>
            {encounter.pushCalls?.map((item) => (
              <PushCallsCardItem key={item.callUUID}>
                <div className='time'>{formatPushCallTime(item.createdAt)}</div>
                <div className='username'>{item.caller}</div>
              </PushCallsCardItem>
            ))}
          </PushCallsCardTimes>
        ) : (
          <PushCallsCardTimes>
            <PushCallsCardItem>
              <div className='username'>Não houve tentativas de chamada</div>
            </PushCallsCardItem>
          </PushCallsCardTimes>
        )}
      </>
    ),
  };

  const accordionData =
    process.env.REACT_APP_EXECUTE_PUSH_CALL === 'true'
      ? [...accordionItems, pushCallAccordion]
      : [...accordionItems];

  return (
    <StyledWrapper>
      <CustomAccordion data={accordionData} />

      <Alert
        open={alertProps?.open || false}
        type={alertProps?.type}
        message={alertProps?.message}
        onClose={handleCloseAlert}
      />

      <CancelScheduleModal
        open={openScheduleCancel}
        onClose={handleCloseCancelScheduleModal}
        selectedAttendance={itemSchedule || null}
      />

      <ModalNotAttend
        show={!!useModalNotAttend}
        data={useModalNotAttend}
        onClose={(shouldRedirect = false) => {
          setModalNotAttend(null);

          if (shouldRedirect) {
            push('/adm');
          }
        }}
        trigger={trigger}
      />

      <ModalDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
        title={'Deseja remover o acionamento ?'}
        withActions
        withClose
        labelCancel={'Cancelar'}
        labelOk={'Remover'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCancel={'danger'}
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              height={'medium'}
              value={reasonRemove}
              placeholder='Selecione'
              onChange={handleReason}
              data={removeReason}
            />
          </div>
          {deleteError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>

      <ModalDialog
        processing={processingScheduleRequest}
        showContentCondition={showContentScheduleError}
        showContentConditionString={
          'Não é possível cancelar agendamento, por favor entre em contato com o suporte'
        }
        open={openScheduleCancel}
        handleClose={() => {
          setReasonScheduleCancel(null);
          setOpenScheduleCancel(false);
        }}
        handleConfirm={async () => {
          await handleConfirmChangeScheduleOther();
        }}
        title={'Deseja cancelar o agendamento?'}
        withActions
        withClose
        labelCancel={'Voltar'}
        labelOk={'Sim, cancelar'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCancel={'danger'}
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              height={'medium'}
              value={reasonScheduleCancel}
              placeholder='Selecione'
              onChange={handleReasonSchedule}
              data={supportReason}
            />
          </div>
          {reasonScheduleOthers && (
            <div>
              <FormInput
                labelTitle={''}
                placeholder={'Digite o motivo do cancelamento'}
                type={'text'}
                id={'motivo'}
                name={'motivo'}
                height={'32px'}
                value={reasonScheduleOthersInput}
                handleChange={handleChangeScheduleOthersInput}
                color={'1px solid  #ff2424'}
              />
            </div>
          )}
          {reasonScheduleOthersError && (
            <ValueCheck marginTop={'0px'}>Este campo é obrigatório</ValueCheck>
          )}
        </ContainerSelectModal>
      </ModalDialog>

      <ModalDialog
        open={openModalSupport}
        handleClose={handleCloseModalSupport}
        handleConfirm={handleConfirmSupport}
        title={'Encaminhamento para o suporte'}
        typeButtomConfirm={'dangerHighlight'}
        typeButtomCancel={'primaryHighlight'}
        labelCancel={'Cancelar'}
        labelOk={'Confimar'}
        withActions
        withClose
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              placeholder='Selecione'
              height={'medium'}
              value={reasonSupport}
              onChange={handleChangeReasonSupport}
              data={supportReason}
            />
          </div>

          {suportError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>
      {openModalAlert && (
        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          close={() => {
            handleCloseNewModal();
          }}
        />
      )}

      {snack}
    </StyledWrapper>
  );
};

export default PatientData;
