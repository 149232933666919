import request from '../request';

const execute = async ({ data }) => {
  try {
    const requestData = data;

    const response = await request.put(
      `/scales?id=${requestData.id}`,
      requestData,
      {
        headers: {
          'x-access-token': headers.userToken,
          _id: headers._id,
        },
      }
    );

    if (response.err) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }
    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
