import React, { Component } from 'react';
import CheckboxCustom from '../../../Forms/CheckboxCustom';
import SelectCustom from '../../../Forms/SelectCustom';
import { cloneDeep } from 'lodash';
import { duration } from '../../../../commons/index';
//import Plus from "../../../../icons/plus-tele.svg";
//import Minus from "../../../../icons/minus-tele.svg";
import { addDaysToDate } from '../../../../utils/utils';
import { codUFNumberToString } from '../../../../commons/index';
//import { avaliableMonth } from '../helpers/getEnableOrDisableDates';
import { avaliableMonth } from '../../../../helpers/getEnableOrDisableDatesbyDoctor';
//446390CRPSP
import {
  Container,
  CustomContainer,
  ContainerButton,
  ContainerDate,
  ContainerWeek,
} from './styles';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { objectKeysToString } from '../../../../utils/utils';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Errors } from '../../../Forms/Errors';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { IconButton, InputAdornment } from '@material-ui/core';
import { agendamentoTPsicol } from '../../../../actions/agendamentoTPsicol.action';
import CustomButtom from '../../../CustomButtom';
import {
  compareIsAfter,
  debounce,
  objectIsEmpty,
} from '../../../../utils/utils';

class ProvideSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: {
        periodDateStart: '',
        periodDateFinish: '',
        periodDuration: '30',
        // horarios: {},
        maxSessions: '',
        specialtyId: '',
        nameWeek: {},
        scheduleType: '',
        slotStateId: 2,
      },
      daysOfWeek: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    // this.handleAlert = this.handleAlert.bind(this);
  }

  disableDates(date) {
    if (
      this.state.disabledDates !== undefined &&
      this.state.disabledDates.length > 0
    )
      return this.state.disabledDates.includes(
        moment(date).format('YYYY-MM-DD')
      );
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { schedule } = this.state;

    this.setState({
      schedule: {
        ...schedule,
        [name]: value,
      },
    });
  }

  handleChangePeriodStart(value, name) {
    const { schedule } = this.state;
    this.setState({
      ...this.state,
      schedule: {
        ...schedule,
        [name]: value,
      },
    });
  }

  handleChangePeriodFinish(value, name) {
    let { schedule, daysOfWeek } = this.state;
    let dayStart = moment(schedule.periodDateStart).day();

    let dayFinish = moment(value).day();
    let days = 0;
    daysOfWeek = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      0: false,
    };

    let startPer = schedule.periodDateStart
      ? cloneDeep(schedule.periodDateStart)
      : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let endPer = cloneDeep(value);
    let daysMonthDifference = endPer
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .diff(
        startPer.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        'days'
      );

    if (schedule.periodDateStart && value) {
      for (
        let i = startPer
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .day();
        i <=
        endPer.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).day();
        i++
      ) {
        daysOfWeek[i] = true;
      }

      if (daysMonthDifference >= 6) {
        daysOfWeek = {
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          0: true,
        };
      }
    }

    this.setState({
      ...this.state,
      daysOfWeek,
      schedule: {
        ...schedule,
        [name]: value,
      },
    });
  }

  handleChangeCheck(e) {
    const { name, checked } = e.target;
    const { schedule } = this.state;
    const { nameWeek } = this.state.schedule;

    if (checked) {
      this.setState({
        schedule: {
          ...schedule,
          nameWeek: {
            ...nameWeek,
            [name]: '',
          },
        },
      });
    } else {
      delete nameWeek[name];
      this.setState({
        schedule: {
          ...schedule,
          nameWeek: {
            ...nameWeek,
          },
        },
      });
    }
  }

  handleChangeHorarios(e, index) {
    const { name, value } = e.target;
    const { schedule } = this.state;
    const { horarios } = this.state.schedule;

    this.setState({
      schedule: {
        ...schedule,
        horarios: {
          ...horarios,
          [name]: value,
        },
      },
    });
  }

  handleConfirm = async (e) => {
    const { nameWeek } = this.state.schedule;
    let periodDateStartError = false;
    let periodDateFinishError = false;
    let periodDurationError = false;
    let nameWeekError = false;

    if (this.state.schedule.periodDuration === '') {
      this.setState({ periodDurationError: true });
      periodDurationError = true;
    } else {
      this.setState({ periodDurationError: false });
      periodDurationError = false;
    }

    // if (!actualAfterOneDate(this.state.schedule.periodDateStart)) {
    //   this.setState({ periodDateStartError: true });
    // } else {
    //   this.setState({ periodDateStartError: false });
    // }

    // if (
    //   !compareIsAfter(
    //     moment().format('YYYY-MM-DD'),
    //     this.state.schedule.periodDateStart
    //   )
    // ) {
    //   this.setState({ periodDateStartError: true });
    //   periodDateStartError = true;
    // } else {
    //   this.setState({ periodDateStartError: false });
    //   periodDateStartError = false;
    // }

    // if (
    //   !compareIsAfter(
    //     this.state.schedule.periodDateStart,
    //     this.state.schedule.periodDateFinish
    //   )
    // ) {
    //   this.setState({ periodDateFinishError: true });
    //   periodDateFinishError = true;
    // } else {
    //   this.setState({ periodDateFinishError: false });
    //   periodDateFinishError = false;
    // }

    if (objectIsEmpty(nameWeek)) {
      this.setState({ nameWeekError: true });
      nameWeekError = true;
    } else {
      this.setState({ nameWeekError: false });
      nameWeekError = false;
    }

    if (
      //  !periodDateStartError &&
      // !periodDateFinishError &&
      !periodDurationError &&
      !nameWeekError
    ) {
      const objectClone = cloneDeep(this.state);
      objectClone.schedule.periodDateStart = moment(
        this.state.schedule.periodDateStart
      ).format('YYYY-MM-DD HH:mm:ss');
      objectClone.schedule.periodDuration = '30';
      objectClone.schedule.periodDateFinish = moment(
        this.state.schedule.periodDateFinish
      ).format('YYYY-MM-DD HH:mm:ss');

      objectClone.schedule.nameWeek = objectKeysToString(
        this.state.schedule.nameWeek
      );

      if (!this.state.schedule.periodDateStart) {
        objectClone.schedule.periodDateStart = moment().format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }

      if (!this.state.schedule.periodDateFinish) {
        objectClone.schedule.periodDateFinish = moment()
          .add('2', `hour`)
          .format('YYYY-MM-DD HH:mm:ss');
      }

      delete objectClone.periodDateStartError;
      delete objectClone.periodDateFinishError;
      delete objectClone.periodDurationError;

      this.props.dispatch(agendamentoTPsicol.CreateSlot(objectClone));
      debounce(this.props.handleGetMessage, 5000);
    }
  };

  async componentDidMount() {
    let user = JSON.parse(window.localStorage.user);
    let docRefId =
      user.user.codigo_classe +
      user.user.classificacao_cod_classe +
      codUFNumberToString[user.user.uf_cod_classe];
    this.state = {
      disabledDates: await avaliableMonth(1, true, docRefId, true),
      schedule: {
        periodDateStart: moment(),
        periodDateFinish: moment(),
        // horarios: {},
        periodDuration: '30',
        slotStateId: 2,
        specialtyId: 3,
        nameWeek: {},
        scheduleType: 'TELEPSICOLOGIA', //TELEPSICOLOGIA || TELEMEDICINA
        doctorRefId: docRefId,
        doctorRefName: this.props.userLogin.user.nome,
      },
      daysOfWeek: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        0: false,
      },
      periodDateStartError: false,
      periodDateFinishError: false,
      periodDurationError: false,
    };
  }
  componentWillUnmount() {}

  render() {
    return (
      <Formik
        initialValues={{ ...this.state }}
        render={({ values, errors, touched }) => (
          <Form>
            <Container>
              <div className='half-size'>
                <CustomContainer>
                  <div className='titles'>Selecione uma data ou um período</div>

                  <div>
                    <div>
                      <Errors
                        errorText='Data e Horário deve ser superior a data e horário atual'
                        isError={this.state.periodDateStartError}
                        isRequired={this.state.periodDateStartError}
                      >
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          locale={moment.locale('pt-br')}
                        >
                          <ContainerDate
                            fullWidth
                            disablePast={true}
                            hideTabs
                            ampm={false}
                            cancelLabel='Cancelar'
                            value={this.state.schedule.periodDateStart}
                            //   ||
                            //   addDaysToDate(moment(), 1).format('YYYY-MM-DD')
                            // }
                            shouldDisableDate={(date) =>
                              this.disableDates(date)
                            }
                            onChange={(e) =>
                              this.handleChangePeriodStart(e, 'periodDateStart')
                            }
                            allowKeyboardControl={false}
                            helperText=''
                            format='DD/MM/YYYY HH:mm:ss'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton>
                                    <CalendarTodayIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Errors>
                    </div>
                    {/* back later of tests */}
                    {/* {compareIsAfter(
                      moment().format('YYYY-MM-DD'),
                      this.state.schedule.periodDateStart
                    ) && ( */}
                    <div>
                      <Errors
                        errorText='Data e Horário deve ser superior a data inicial'
                        isError={this.state.periodDateFinishError}
                        isRequired={this.state.periodDateFinishError}
                      >
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          locale={moment.locale('pt-br')}
                        >
                          <ContainerDate
                            fullWidth
                            disablePast={true}
                            hideTabs
                            ampm={false}
                            minDate={addDaysToDate(
                              this.state.schedule.periodDateStart,
                              0 //back later for 1 or 2
                            ).format('YYYY-MM-DD')}
                            value={
                              this.state.schedule.periodDateFinish ||
                              addDaysToDate(
                                this.state.schedule.periodDateStart,
                                1
                              ).format('YYYY-MM-DD')
                            }
                            value={this.state.schedule.periodDateFinish}
                            // ||
                            // addDaysToDate(
                            //   this.state.schedule.periodDateStart,
                            //   1
                            // ).format('YYYY-MM-DD')
                            //}
                            onChange={(e) =>
                              this.handleChangePeriodFinish(
                                e,
                                'periodDateFinish'
                              )
                            }
                            shouldDisableDate={(date) =>
                              this.disableDates(date)
                            }
                            allowKeyboardControl={false}
                            helperText=''
                            format='DD/MM/YYYY HH:mm:ss'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton>
                                    <CalendarTodayIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Errors>
                    </div>
                  </div>
                </CustomContainer>
                <CustomContainer>
                  <div className='titles'>Duração da consulta</div>
                  <Errors
                    errorText='Campo não pode ser vazio!'
                    isError={this.state.periodDurationError}
                    isRequired={this.state.periodDurationError}
                  >
                    <SelectCustom
                      name='periodDuration'
                      label='Selecione'
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.schedule.periodDuration}
                      dataSource={duration}
                      placeholder='Selecione'
                    />
                  </Errors>
                </CustomContainer>
              </div>

              <div className='half-size'>
                <CustomContainer>
                  <div className='titles'>Dia da Semana</div>

                  <Errors
                    errorText='Selecione ao  menos um dia da semana.'
                    isError={this.state.nameWeekError}
                    isRequired={this.state.nameWeekError}
                  >
                    <ContainerWeek>
                      {this.state.daysOfWeek['1'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.segunda}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='1'
                            label='Segunda'
                          />
                        </div>
                      )}

                      {this.state.daysOfWeek['2'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.terca}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='2'
                            label='Terça'
                          />
                        </div>
                      )}

                      {this.state.daysOfWeek['3'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.quarta}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='3'
                            label='Quarta'
                          />
                        </div>
                      )}

                      {this.state.daysOfWeek['4'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.quinta}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='4'
                            label='Quinta'
                          />
                        </div>
                      )}

                      {this.state.daysOfWeek['5'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.sexta}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='5'
                            label='Sexta'
                          />
                        </div>
                      )}
                      {this.state.daysOfWeek['6'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.sabado}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='6'
                            label='Sábado'
                          />
                        </div>
                      )}
                      {this.state.daysOfWeek['0'] && (
                        <div>
                          <CheckboxCustom
                            value={this.state.domingo}
                            onChange={(e) => this.handleChangeCheck(e)}
                            name='0'
                            label='Domingo'
                          />
                        </div>
                      )}
                    </ContainerWeek>
                  </Errors>
                </CustomContainer>

                {/* <CustomContainer>
                  <div>Horário</div>

                  <FieldArray
                    name="horarios"
                    render={(arrayHelpers) => (
                      <div>
                        {values.horarios && values.horarios.length > 0 ? (
                          values.horarios.map((ele, index) => (
                            <div
                              key={index}
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <TextFieldCustom
                                variant="outlined"
                                onChange={(e) => this.handleChangeHorarios(e)}
                                name={`horarios[${index}]`}
                                value={this.state.schedule.horarios[index]}
                              />

                              <ArrayButtons>
                                <img
                                  src={Minus}
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                />
                              </ArrayButtons>

                              <ArrayButtons>
                                <img
                                  src={Plus}
                                  onClick={() => arrayHelpers.insert(index, "")}
                                />
                              </ArrayButtons>
                            </div>
                          ))
                        ) : (
                          <ArrayButtons>
                            <img
                              src={Plus}
                              onClick={() => arrayHelpers.push("")}
                            />
                          </ArrayButtons>
                        )}
                      </div>
                    )}
                  />
                </CustomContainer> */}
              </div>
            </Container>
            <ContainerButton>
              <CustomButtom
                onClick={this.props.handleCancel()}
                typeButtom='lightRounded'
                fontSize='10px'
                size='medium'
                border='solid 1px #ccc'
              >
                Cancelar
              </CustomButtom>

              <CustomButtom
                onClick={(e) => this.handleConfirm(e)}
                typeButtom='lightRounded'
                fontSize='10px'
                size='medium'
                border='solid 1px #000AFF'
              >
                Confirmar
              </CustomButtom>
            </ContainerButton>
          </Form>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  const { schedule } = state;
  const userLogin = state.authentication.user;
  const doctorRefId = state.doctor.doctorRefId;
  const { alert } = state;
  const { message, type, open } = state.alert;
  return { schedule, userLogin, alert, doctorRefId, message, type, open };
}

const connectedRegisterPage = connect(mapStateToProps)(ProvideSchedule);
export default connectedRegisterPage;
