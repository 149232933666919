import React, { useMemo } from 'react';

import { Avatar } from '@material-ui/core';

import { cutString } from './utils/cutString';

const CustomAvatar = ({ name }) => {
  const colors = [
    '#aa0000',
    '#004b51',
    '#32105c',
    '#86cdde ',
    '#b2b2b2',
    '#007acc',
    '#ff2424',
    '#ff8721',
    '#ffb324',
    '#b600a0',
  ];

  const memoizedAvatar = useMemo(
    () => (
      <Avatar
        style={{
          background : colors[Math.floor(Math.random() * colors.length)]
        }}
      >
        {cutString(name)}
      </Avatar>
    ),
  [name]
  );

  return <>{memoizedAvatar}</>;
};

export default CustomAvatar;
