import IconButton from '@material-ui/core/IconButton';

import { PageProps } from '../types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

import * as S from './styles';

const IconVariants = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

interface SnackbarItemProps
  extends Pick<PageProps, 'message' | 'type' | 'open'> {
  onClose: () => void;
  classes?: Record<string, any>;
  className?: string;
}
export function SnackbarItem({
  onClose,
  type,
  message,
  classes,
  open,
}: SnackbarItemProps) {
  const IconComponent = IconVariants[type as keyof typeof IconVariants];

  if (!open) return <div />;

  return (
    <S.Wrapper $type={type || ''}>
      <div className='icon'>
        <IconComponent />
      </div>
      <span>{message}</span>
      <div className='close'>
        <IconButton
          className={classes?.close}
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </S.Wrapper>
  );
}
