require('dotenv').config();
import axios from 'axios';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const pushCallService = {
  createPushCall,
  getById,
  updateIsCalling,
};

async function createPushCall(input) {
  const storage = window.localStorage.user;
  const user = JSON.parse(storage);
  const requestOptions = {
    method: 'POST',
    url: `${URL_CONECTA}/push-calls`,
    data: input,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.token,
      _id: user.user._id,
    },
  };
  const result = await axios.request(requestOptions);
  return result;
}

async function getById(id) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    url: `${URL_CONECTA}/push-calls/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.token,
      _id: user.user._id,
    },
  };
  return await axios.request(requestOptions);
}

async function updateIsCalling(id, input) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'PUT',
    url: `${URL_CONECTA}/push-calls/${id}/is-calling`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.token,
      _id: user.user._id,
    },
    data: input,
  };
  await axios.request(requestOptions);
}
