import { Text } from '../../../components/Text';

import { StyledSubmitButton } from './style';

interface SubmitButtonProps {
  disabled?: boolean;
}
export function SubmitButton({ disabled }: SubmitButtonProps) {
  return (
    <StyledSubmitButton disabled={disabled} type='submit'>
      <Text
        $size='14px'
        $weight='700'
        $color='#ffffff'
        $textTransform='uppercase'
      >
        Validar
      </Text>
    </StyledSubmitButton>
  );
}
