import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const TextButton = styled.button`
  width: 100%;
  border: 0;
  margin: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
  cursor: pointer;
  transition: all.5s;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
`;
