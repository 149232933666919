import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const desvinculoService = {
  getContract,
  cancelContract,
};

async function getContract(phoneNunber) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/vinculo/getContract?phone=${phoneNunber}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}

async function cancelContract(phoneNunber) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ number: phoneNunber }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/desvinculo/cancelContract`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}
