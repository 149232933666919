import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #76818f;

  @media (max-width: 568px) {
    width: 100%;

    .customSelect {
      width: 100%;

      div:nth-of-type(1) {
        width: 100%;
      }
    }
  }
`;
