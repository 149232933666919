export const estados_brasileiros = [
  { region: 'Acre', country: 'br' },
  { region: 'Alagoas', country: 'br' },
  { region: 'Amapa', country: 'br' },
  { region: 'Amazonas', country: 'br' },
  { region: 'Bahia', country: 'br' },
  { region: 'Ceara', country: 'br' },
  { region: 'Espirito Santo', country: 'br' },
  { region: 'Federal District', country: 'br' },
  { region: 'Goias', country: 'br' },
  { region: 'Maranhao', country: 'br' },
  { region: 'Mato Grosso', country: 'br' },
  { region: 'Mato Grosso do Sul', country: 'br' },
  { region: 'Minas Gerais', country: 'br' },
  { region: 'Para', country: 'br' },
  { region: 'Paraiba', country: 'br' },
  { region: 'Parana', country: 'br' },
  { region: 'Pernambuco', country: 'br' },
  { region: 'Piaui', country: 'br' },
  { region: 'Rio de Janeiro', country: 'br' },
  { region: 'Rio Grande do Norte', country: 'br' },
  { region: 'Rio Grande do Sul', country: 'br' },
  { region: 'Rondonia', country: 'br' },
  { region: 'Roraima', country: 'br' },
  { region: 'Santa Catarina', country: 'br' },
  { region: 'Sao Paulo', country: 'br' },
  { region: 'Sergipe', country: 'br' },
  { region: 'Tocantins', country: 'br' },
];

export const estados = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espirito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MS: 'Mato Grosso do Sul',
  MT: 'Mato Grosso',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const estados_united = [
  { region: 'Alabama', country: 'us' },
  { region: 'Alaska', country: 'us' },
  { region: 'Arizona', country: 'us' },
  { region: 'Arkansas', country: 'us' },
  { region: 'California', country: 'us' },
  { region: 'Colorado', country: 'us' },
  { region: 'Connecticut', country: 'us' },
  { region: 'Delaware', country: 'us' },
  { region: 'District of Columbia', country: 'us' },
  { region: 'Florida', country: 'us' },
  { region: 'Georgia', country: 'us' },
  { region: 'Hawaii', country: 'us' },
  { region: 'Idaho', country: 'us' },
  { region: 'Illinois', country: 'us' },
  { region: 'Indiana', country: 'us' },
  { region: 'Iowa', country: 'us' },
  { region: 'Kansas', country: 'us' },
  { region: 'Kentucky', country: 'us' },
  { region: 'Louisiana', country: 'us' },
  { region: 'Maine', country: 'us' },
  { region: 'Maryland', country: 'us' },
  { region: 'Massachusetts', country: 'us' },
  { region: 'Michigan', country: 'us' },
  { region: 'Minnesota', country: 'us' },
  { region: 'Mississippi', country: 'us' },
  { region: 'Missouri', country: 'us' },
  { region: 'Montana', country: 'us' },
  { region: 'Nebraska', country: 'us' },
  { region: 'Nevada', country: 'us' },
  { region: 'New Hampshire', country: 'us' },
  { region: 'New Jersey', country: 'us' },
  { region: 'New Mexico', country: 'us' },
  { region: 'New York', country: 'us' },
  { region: 'North Carolina', country: 'us' },
  { region: 'North Dakota', country: 'us' },
  { region: 'Ohio', country: 'us' },
  { region: 'Oklahoma', country: 'us' },
  { region: 'Oregon', country: 'us' },
  { region: 'Pennsylvania', country: 'us' },
  { region: 'Rhode Island', country: 'us' },
  { region: 'South Carolina', country: 'us' },
  { region: 'South Dakota', country: 'us' },
  { region: 'Tennessee', country: 'us' },
  { region: 'Texas', country: 'us' },
  { region: 'Utah', country: 'us' },
  { region: 'Vermont', country: 'us' },
  { region: 'Virginia', country: 'us' },
  { region: 'Washington', country: 'us' },
  { region: 'West Virginia', country: 'us' },
  { region: 'Wisconsin', country: 'us' },
  { region: 'Wyoming', country: 'us' },
];

export const statusType = [
  {
    value: 'OPEN',
    label: 'Em aberto',
  },
  {
    value: 'CONTACT_UNSUCCESSFUL',
    label: 'Contato sem sucesso',
  },
  {
    value: 'FUTURE_CONTACT',
    label: 'Contato futuro',
  },
  {
    value: 'RETAINED_COSTUMER',
    label: 'Cliente retido',
  },
  {
    value: 'CANCELED_INVOLUNTARY',
    label: 'Cancelamento Involuntario',
  },
  {
    value: 'CANCELED_CHARGEBACK',
    label: 'Cancelamento com estorno',
  },
  {
    value: 'CANCELED_WITH_PENALITY',
    label: 'Cancelamento com multa',
  },
  {
    value: 'CANCELED_WITHOUT_PENALITY',
    label: 'Cancelamento sem multa',
  },
  {
    value: 'ERROR_BROKER',
    label: 'Erro no Broker',
  },
];

export const statusTypeForm = [
  {
    value: 'OPEN',
    label: 'Em aberto',
  },
  {
    value: 'CONTACT_UNSUCCESSFUL',
    label: 'Contato sem sucesso',
  },
  {
    value: 'FUTURE_CONTACT',
    label: 'Contato futuro',
  },
  {
    value: 'RETAINED_COSTUMER',
    label: 'Cliente retido',
  },
  {
    value: 'CANCELED_INVOLUNTARY',
    label: 'Cancelamento Involuntario',
  },
  {
    value: 'CANCELED_CHARGEBACK',
    label: 'Cancelamento com estorno',
  },
  {
    value: 'CANCELED_WITH_PENALITY',
    label: 'Cancelamento com multa',
  },
  {
    value: 'CANCELED_WITHOUT_PENALITY',
    label: 'Cancelamento sem multa',
  },
];

export const statusTypeTranslation = {
  OPEN: 'Aberto',
  CONTACT_UNSUCCESSFUL: 'Contato Sem sucesso',
  FUTURE_CONTACT: 'Contato Futuro',
  RETAINED_COSTUMER: 'Cliente Retido',
  CANCELED_CHARGEBACK: 'Cancelamento Com Estorno',
  CANCELED_WITH_PENALITY: 'Cancelamento com multa',
  CANCELED_WITHOUT_PENALITY: 'Cancelamento sem multa',
  CANCELED_INVOLUNTARY: 'Cancelamento Involuntario',
  ERROR_BROKER: 'Erro no Broker',
};

export const translationSupport = {
  PATIENT_DOESNT_SEE_ME: 'Paciente não me vê',
  PATIENT_DONT_HEAR_ME: 'Paciente não me ouve',
  PATIENT_DOES_NOT_SEE_ME_AND_DONT_HEAR_ME: 'Paciente não me vê e não me ouve',
  DO_NOT_HEAR_THE_PATIENT: 'Não ouço o paciente',
  DO_NOT_HEAR_AND_DO_NOT_SEE_THE_PATIENT: 'Não ouço e não vejo o paciente',
  NO_COMINICATION: 'Nenhuma comunicação',
  UNSTABLE_CONNECTION: 'Conexão instável',
};

export const reasonCancellationTranslation = {
  DOES_NOT_ATTEND: 'Não Atende',
  WRONG_NUMBER: ' Número errado',
  VOICE_MAIL: 'Caixa Postal',
  IMCOMPLETE_NUMBER: 'Número incompleto',
  ARGUMENTATION: 'Argumentação',
  OFFER: 'Oferta',
  PRODUCT_WITHDRAWAL: 'Desistência do produto',
  UNDERSTANDING_PRODUCT: 'Entendimento do produto',
  COST_REDUCTION: 'Redução de custos',
  DISSATISFACTION: 'Insatisfação',
};

export const unsuccessfulContact = [
  {
    value: 'DOES_NOT_ATTEND',
    label: 'Não atende',
  },
  {
    value: 'WRONG_NUMBER',
    label: ' Número errado',
  },
  {
    value: 'VOICE_MAIL',
    label: 'Caixa Postal',
  },
  {
    value: 'IMCOMPLETE_NUMBER',
    label: 'Número incompleto',
  },
];

export const reasonCancellation = [
  {
    value: 'PRODUCT_WITHDRAWAL',
    label: 'Desistência do produto',
  },
  {
    value: 'UNDERSTANDING_PRODUCT',
    label: 'Entendimento do produto',
  },
  {
    value: 'COST_REDUCTION',
    label: 'Redução de custos',
  },
  {
    value: 'DISSATISFACTION',
    label: 'Insatisfação',
  },
  {
    value: 'LATEPAYMENT',
    label: 'Atraso de pagamento',
  },
];

export const retained = [
  {
    value: 'ARGUMENTATION',
    label: 'Argumentação',
  },
  {
    value: 'OFFER',
    label: 'Oferta',
  },
];

export const motivosSuporte = [
  {
    value: {
      value: 'PATIENT_DOESNT_SEE_ME',
      desc: 'paciente nao me ve',
    },
    label: 'Paciente não me vê',
  },
  {
    value: {
      value: 'PATIENT_DONT_HEAR_ME',
      desc: 'paciente nao me ouve',
    },
    label: 'Paciente não me ouve',
  },
  {
    value: {
      value: 'PATIENT_DOES_NOT_SEE_ME_AND_DONT_HEAR_ME',
      desc: 'paciente nao me ve e nao me ouve',
    },
    label: 'Paciente não me vê e não me ouve',
  },
  {
    value: {
      value: 'DO_NOT_HEAR_THE_PATIENT',
      desc: 'nao ouco o paciente',
    },
    label: 'Não ouço o paciente',
  },
  {
    value: {
      value: 'DO_NOT_HEAR_AND_DO_NOT_SEE_THE_PATIENT',
      desc: 'nao ouco e nao vejo o paciente',
    },
    label: 'Não ouço e não vejo o paciente',
  },
  {
    value: {
      value: 'NO_COMINICATION',
      desc: 'nenhuma comunicacao',
    },
    label: 'Nenhuma comunicação',
  },
];

export const selectDoc = [
  {
    label: 'CPF',
    value: 'CPF',
  },
  {
    value: 'PASSAPORT',
    label: 'Passaporte',
  },
];

export const initiaValues = {
  title: '',
  content: '',
  links: [{ link: '' }, { link: '' }, { link: '' }],
  startDate: new Date(),
  reception: false,
  support: false,
  administration: false,
  manager: false,
};

export const codUFNumberToString = {
  11: 'RO',
  12: 'AC',
  13: 'AM',
  14: 'RR',
  15: 'PA',
  16: 'AP',
  17: 'TO',
  21: 'MA',
  22: 'PI',
  23: 'CE',
  24: 'RN',
  25: 'PB',
  26: 'PE',
  27: 'AL',
  28: 'SE',
  29: 'BA',
  31: 'MG',
  32: 'ES',
  33: 'RJ',
  35: 'SP',
  41: 'PR',
  42: 'SC',
  43: 'RS',
  50: 'MS',
  51: 'MT',
  52: 'GO',
  53: 'DF',
};

export const duration = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '15',
    label: '15',
  },

  {
    value: '20',
    label: '20',
  },

  {
    value: '25',
    label: '25',
  },

  {
    value: '30',
    label: '30',
  },
];
