import { alertContingenciaConstants } from '../constants';

export const alertContingenciaActions = {
  success,
  clear,
};

function success(message, url) {
  return { type: alertContingenciaConstants.SUCCESS, message, open: true, url };
}

function clear() {
  return { type: alertContingenciaConstants.CLEAR };
}
