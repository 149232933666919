import { useState } from 'react'
import {
    StopIcon,
    PlayIcon,
    PauseIcon,
    MoreVertical,
    InfoStopContainer,
    InfoStartContainer,
    InfoPauseContainer,
    InfoOptionsContainer,
    StyledProfileTimerText,
    StyledProfileTimerStop,
    StyledButtonsContainer,
    StyledProfileTimerStart,
    StyledProfileTimerPause,
    StyledProfileTimerPause,
    StyledProfileTimerSelected,
    StyledSchedullingContainer,
    StyledProfileTimerTextTitle,
    StyledProfileTimerTextSubTitle,
    StyledProfileTimerSelectedContent,
    StyledProfileTimerSelectedTextLink,
    StyledProfileTimerSelectedTextSwith,
    StyledProfileTimerSelectedTextTitle,
    StyledProfileTimerSelectedContentLink,
    StyledProfileTimerStopSelectedExpanded,
    StyledProfileTimerSelectedContentSwith,
    StyledProfileTimerPauseSelectedExpanded,
} from './styles'

import { CustomSwitch } from '../../../../components/ui/webform';

import {
    CustomSnackbar
} from '../../../../components/ui'

export function WorkShiftControl({
    tasks,
    useAlertPause,
    nextPause,
    selected,
    setSelected,
    blockStake,
    scheduledPause,
    setScheduledPause,
    schedulingPause,
    pauseIndex,
    setPauseIndex,
    pauseTypes,
    changeAlertPause,
    startWorkShift,
    handleStopWorkShift,
    useWorkShiftStart,
}) {
    const { trigger } = CustomSnackbar();
    const [selectedOptionPause, setSelectedOptionPause] = useState(false)

    return (
        <StyledSchedullingContainer>

            <StyledButtonsContainer>
                <StyledProfileTimerStart
                    onClick={() => startWorkShift()}
                    isBlocked={useWorkShiftStart}
                    disabled={useWorkShiftStart}
                >
                    <PlayIcon isBlocked={useWorkShiftStart} />

                    <InfoStartContainer className='start-info-container'>
                        <span>Iniciar jornada</span>
                    </InfoStartContainer>
                </StyledProfileTimerStart>

                <StyledProfileTimerPause
                    onClick={() => setSelected(!selected)}
                    isBlocked={useWorkShiftStart}
                    selected={selected}
                    disabled={!useWorkShiftStart}
                >
                    <PauseIcon />

                    <InfoPauseContainer className='pause-info-container'>
                        <span>Pausar jornada</span>
                    </InfoPauseContainer>
                </StyledProfileTimerPause>

                <StyledProfileTimerStop
                    onClick={() => handleStopWorkShift()}
                    isBlocked={useWorkShiftStart}
                    disabled={!useWorkShiftStart}
                >
                    <StopIcon />
                    <InfoStopContainer className='stop-info-container'>
                        <span>Finalizar jornada</span>
                    </InfoStopContainer>
                </StyledProfileTimerStop>

                <div style={{ height: 30, borderLeft: '1px solid #DFE3E8', margin: '0 8px' }} />

            </StyledButtonsContainer>

            <StyledProfileTimerText>
                <StyledProfileTimerTextTitle>
                    Próxima
                </StyledProfileTimerTextTitle>
                <StyledProfileTimerTextSubTitle>
                    {nextPause.pause}
                </StyledProfileTimerTextSubTitle>
            </StyledProfileTimerText>


            <StyledProfileTimerText>
                <StyledProfileTimerTextTitle>
                    Previsão
                </StyledProfileTimerTextTitle>
                <StyledProfileTimerTextSubTitle>
                    {nextPause.pauseHour}
                </StyledProfileTimerTextSubTitle>
            </StyledProfileTimerText>

            <StyledProfileTimerSelected
                onClick={() => setSelectedOptionPause(!selectedOptionPause)}
            >
                <MoreVertical />
                <InfoOptionsContainer className='options-info-container'>
                    <span>Opções</span>
                </InfoOptionsContainer>
            </StyledProfileTimerSelected>

            {selectedOptionPause && (
                <StyledProfileTimerStopSelectedExpanded selected={selectedOptionPause}>
                    <StyledProfileTimerSelectedContent>
                        <StyledProfileTimerSelectedTextTitle>
                            Opções de pausa
                        </StyledProfileTimerSelectedTextTitle>
                        <StyledProfileTimerSelectedContentSwith>
                            <StyledProfileTimerSelectedTextSwith>
                                Alertar sobre a pausa
                            </StyledProfileTimerSelectedTextSwith>
                            <CustomSwitch
                                checked={useAlertPause}
                                onChange={changeAlertPause}
                            />
                        </StyledProfileTimerSelectedContentSwith>
                        <StyledProfileTimerSelectedContentSwith>
                            <StyledProfileTimerSelectedTextSwith>
                                Pausar após este atendimento
                            </StyledProfileTimerSelectedTextSwith>
                            <CustomSwitch
                                checked={scheduledPause.marked}
                                onChange={() =>
                                    setScheduledPause({
                                        marked: !scheduledPause.marked,
                                        typeSelected: false,
                                    })
                                }
                                disabled={scheduledPause.typeSelected}
                            />
                        </StyledProfileTimerSelectedContentSwith>
                    </StyledProfileTimerSelectedContent>
                </StyledProfileTimerStopSelectedExpanded>
            )}

            {selected && (
                <StyledProfileTimerPauseSelectedExpanded selected={selected}>
                    <StyledProfileTimerSelectedContent pauseIndex={pauseIndex}>
                        <StyledProfileTimerSelectedTextTitle>
                            Selecione o tipo de pausa
                        </StyledProfileTimerSelectedTextTitle>

                        {tasks.map((task) => (
                            <StyledProfileTimerSelectedContentLink
                                className='selected-pause-container'
                                key={task.nome}
                            >
                                <StyledProfileTimerSelectedTextLink
                                    onClick={async () => {
                                        if (scheduledPause.marked) {
                                            const response = await schedulingPause({
                                                intervalType: task.nome.toLowerCase(),
                                            });

                                            if (!response.status) {
                                                trigger({
                                                    message: response.error?.message,
                                                    type: 'danger',
                                                    duration: 4000,
                                                });

                                                setScheduledPause({
                                                    marked: false,
                                                    typeSelected: false,
                                                });
                                                return;
                                            }

                                            const pauseSelected = pauseTypes.find(
                                                (pause) =>
                                                    pause.name === task.nome.toLowerCase()
                                            );

                                            setPauseIndex(pauseSelected.selectedIndex);

                                            trigger({
                                                message: `Pausa ${pauseSelected.selectedIndex !== 5
                                                    ? 'para'
                                                    : ''
                                                    } ${task.nome.toLowerCase()} agendada!`,
                                                type: 'success',
                                                duration: 3000,
                                            });

                                            setScheduledPause({
                                                marked: true,
                                                typeSelected: true,
                                            });

                                            return;
                                        }

                                        switch (task.nome) {
                                            case 'Descanso':
                                                blockStake('descanso');
                                                break;

                                            case 'Lanche':
                                                blockStake('lanche');
                                                break;

                                            case 'Feedback':
                                                blockStake('feedback');
                                                break;

                                            default:
                                                blockStake('pessoal');
                                                break;
                                        }
                                    }}
                                >
                                    {task.nome}
                                </StyledProfileTimerSelectedTextLink>

                            </StyledProfileTimerSelectedContentLink>
                        ))}
                    </StyledProfileTimerSelectedContent>
                </StyledProfileTimerPauseSelectedExpanded>
            )}

        </StyledSchedullingContainer>
    )
}