import { forwardingConstants } from '../constants';

export function forwardingSupport(state = { forwardingSuporte: {} }, action) {
  switch (action.type) {
    case forwardingConstants.FORWARDING_SUCCESS:
      return {
        type: 'success',
        forwadingSuporte: action.forwarding,
      };
    case forwardingConstants.FORWARDING_ERROR:
      return Object.assign({}, state, {
        type: 'error',
        forwading: {},
      });
    case forwardingConstants.FORWARDING_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
