import React from 'react';
import { CssBaseline } from '@material-ui/core';
import MenuPainel from '../components/MenuPainelNew';
import FormContingency from '../components/Lib/Forms/FormContingency';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import GlobalStyle from '../styles/globalStyle';

class Contingencia extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        <MenuPainel stepper='Gerador de Links'>
          <FormContingency />
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = Contingencia;
export { connectedAssinar as Contingencia };
