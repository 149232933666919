import styled from 'styled-components';

export const AccordionBody = styled.div`
  width: 320px;
  height: 60px;
  background: white;
  cursor: pointer;
  margin: 3px 0;
`;

export const Container = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 16px;
`;

export const Subtittle = styled.h3`
  font-size: 10px;
`;

export const BodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AccordionOpen = styled.img`
  transform: rotate(180deg);
`;
