export const filaTelepsicologiaConstants = {
  FILATPISICOL_REQUEST: 'FILATPISICOL_REQUEST',
  FILATPISICOL_SUCCESS: 'FILATPISICOL_SUCCESS',
  FILATPISICOL_FAILURE: 'FILATPISICOL_FAILURE',
};

export const editFilaTelepsicologiaConstants = {
  FILATELEPSICOLOGIA_EDIT_REQUEST: 'FILATELEPSICOLOGIA_EDIT_REQUEST',
  FILATELEPSICOLOGIA_EDIT_SUCCESS: 'FILATELEPSICOLOGIA_EDIT_SUCCESS',
  FILATELEPSICOLOGIA_EDIT_FAILURE: 'FILATELEPSICOLOGIA_EDIT_FAILURE',
};
