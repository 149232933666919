import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueueRecordEntity } from '../../../../domain/entities';
import {
  FindAllQueueRecordsFilter,
  FindAllQueueRecordsState,
} from '../../../../domain/state';

const initialState: FindAllQueueRecordsState = {
  error: false,
  errorMessage: '',
  loading: true,
  list: [],
  filter: {
    centralId: '',
    name: '',
    lastName: '',
    document: '',
    convenioDescricao: '',
    entrada: '',
  },
  pages: 0,
  totalPages: 0,
  sendCockpitError: false,
  sendCockpitErrorMessage: '',
  sendCockpitLoading: false,
};

export const findAllQueueRecordsSlice = createSlice({
  name: 'findAllQueueRecord',
  initialState,
  reducers: {
    setQueueRecordList: (state, action: PayloadAction<QueueRecordEntity[]>) => {
      state.list = action.payload;
    },
    setPages: (state, action: PayloadAction<number>) => {
      state.pages = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action: PayloadAction<FindAllQueueRecordsFilter>) => {
      state.filter = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setSendCockpitLoading: (state, action: PayloadAction<boolean>) => {
      state.sendCockpitLoading = action.payload;
    },
    setSendCockpitError: (state, action: PayloadAction<boolean>) => {
      state.sendCockpitError = action.payload;
    },
    setSendCockpitErrorMessage: (state, action: PayloadAction<string>) => {
      state.sendCockpitErrorMessage = action.payload;
    },
  },
});

export const {
  setQueueRecordList,
  setPages,
  setTotalPages,
  setLoading,
  setFilter,
  setError,
  setErrorMessage,
  setSendCockpitLoading,
  setSendCockpitError,
  setSendCockpitErrorMessage,
} = findAllQueueRecordsSlice.actions;

export default findAllQueueRecordsSlice.reducer;
