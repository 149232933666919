import { userConstants } from '../constants';
import { LoginConstants } from '../constants/login.constants';
import { IUserLoginState } from '../reducers/login.reducer';

const CacheMfaData = (mfaUserData: IUserLoginState['mfaData']) => {
  return { type: LoginConstants.CACHE_MFA_DATA, payload: mfaUserData };
};

const SelectMethod = (method: 'EMAIL' | 'PHONE') => {
  return { type: LoginConstants.SELECT_METHOD, payload: method };
};

const RegisterCodeSentAt = () => {
  return { type: LoginConstants.REGISTER_CODE_SENT_AT };
};

const ChangeStep = (step: IUserLoginState['step']) => {
  return { type: LoginConstants.CHANGE_STEP, payload: step };
};

const Reset = () => {
  return { type: LoginConstants.RESET };
};

export function RegisterUserInScope(user: Record<string, any>) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}

export const LoginActions = {
  CacheMfaData,
  SelectMethod,
  RegisterCodeSentAt,
  ChangeStep,
  Reset,
};
