import types from './types'

const reduceScale = (state = types.states.scale.initial, action) => {
	switch (action.type) {
		case types.states.scale.execute: {
			return {
				...state,
				request: action.data,
				loading: true,
				loaded: false,
				error: false,
			}
		}

		case types.states.scale.success: {
			return {
				...state,
				response: action.data,
				loading: false,
				loaded: true,
				error: false,
			}
		}

		case types.states.scale.error: {
			return {
				...state,
				response: action.data,
				loading: false,
				loaded: true,
				error: true,
			}
		}

		default:
			return state
	}
}

const reduceRemove = (state = types.states.remove.initial, action) => {
	switch (action.type) {
		case types.states.remove.execute: {
			return {
				...state,
				data: { ...action.data },
				loading: true,
				loaded: false,
				error: false,
			}
		}

		case types.states.remove.success: {
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
			}
		}

		case types.states.remove.error: {
			return {
				...state,
				data: { ...state.data, ...action.data },
				loading: false,
				loaded: true,
				error: true,
			}
		}

		default:
			return state
	}
}

const data = {
	[types.states.scale.execute]: reduceScale,
	[types.states.remove.execute]: reduceRemove,
}

export default data
