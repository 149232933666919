// @ts-nocheck
import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Close as CloseIcon, GetApp as DownloadIcon } from '@material-ui/icons';
import { Alert } from '../../../application/shared/ui';
import { ReportQueueRecordsData } from '../../../application/data';
import { ReportQueueRecordsStore } from '../../../application/store';
import { downloadFile } from './report-csv-config';
import { QueueRecordEntity } from '../../../domain/entities';
import * as S from './styles';
import dayjs from 'dayjs';

type Props = {
  reportQueueRecordsStore: ReportQueueRecordsStore;
  reportQueueRecordsData: ReportQueueRecordsData;
};

const RenderSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton height={40} animation='wave' />
        </Grid>
        <Grid item xs={6}>
          <Skeleton height={40} animation='wave' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton height={40} animation='wave' />
        </Grid>
        <Grid item xs={6}>
          <Skeleton height={40} animation='wave' />
        </Grid>
      </Grid>
    </>
  );
};

export function ReportCsv({
  reportQueueRecordsStore,
  reportQueueRecordsData,
}: Props) {
  const {
    reportQueueRecordsSelector,
    handleQueueRecordReport,
    handleLoading,
    handleError,
    handleErrorMessage,
    handleModal,
    handleDateFrom,
    handleDateUntil,
  } = reportQueueRecordsStore;
  const selector = reportQueueRecordsSelector;

  const fetchCsv = async () => {
    if (
      selector.dateFrom === '' ||
      selector.dateUntil === '' ||
      new Date(selector.dateFrom) > new Date(selector.dateUntil)
    ) {
      handleError(true);
      handleErrorMessage('Datas inválidas!');
      return;
    }
    handleLoading(false);
    handleError(false);
    handleErrorMessage('');
    handleLoading(true);
    const response = await reportQueueRecordsData.handle(
      new Date(selector.dateFrom),
      new Date(selector.dateUntil),
      1
    );
    const pages = response.data.pages;
    let page = 1;
    const arrayPromises = [];
    do {
      arrayPromises.push(
        reportQueueRecordsData.handle(
          new Date(selector.dateFrom),
          new Date(selector.dateUntil),
          page
        )
      );
      page++;
    } while (page <= pages);

    const data: QueueRecordEntity[] = [];
    try {
      await Promise.all(arrayPromises).then((values) => {
        for (const value of values) {
          const docs = value.data.docs || [];
          for (const doc of docs) {
            data.push(doc);
          }
        }
      });
    } catch (error) {
      handleError(true);
      handleErrorMessage('Erro ao gerar relatório, tente novamante!');
    }
    const values: QueueRecordEntity[] = [];
    data.map((item) =>
      values.push({ ...item, cabine: item.cabine ? 'sim' : 'não' })
    );
    handleQueueRecordReport(values);
    handleLoading(false);
    downloadFile(values);
    handleDateFrom('');
    handleDateUntil('');
  };

  const handleCloseError = () => {
    handleError(false);
    handleErrorMessage('');
    handleDateFrom('');
    handleDateUntil('');
  };

  const handleCloseModal = () => {
    handleModal(false);
    handleError(false);
    handleErrorMessage('');
    handleDateFrom('');
    handleDateUntil('');
  };

  const handleOpenModal = () => {
    handleModal(true);
    handleError(false);
    handleErrorMessage('');
    handleDateFrom('');
    handleDateUntil('');
  };

  return (
    <>
      <Alert
        handleClose={handleCloseError}
        open={selector.error}
        message={selector.errorMessage}
        severity='error'
      />
      <IconButton color='default' onClick={handleOpenModal} size='small'>
        <DownloadIcon />
      </IconButton>
      <S.ReportCsv open={selector.modal} onClose={handleCloseModal}>
        <DialogActions>
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogTitle>
          <span>Selecione o periodo de extração</span>
        </DialogTitle>
        <DialogContent>
          {selector.loading ? (
            <RenderSkeleton />
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel shrink>De</InputLabel>
                    <Input
                      type='date'
                      fullWidth
                      autoFocus
                      value={selector.dateFrom}
                      onChange={(e) => handleDateFrom(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel shrink>Até</InputLabel>
                    <Input
                      type='date'
                      fullWidth
                      value={selector.dateUntil}
                      onChange={(e) => handleDateUntil(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <S.Buttons>
                  <Grid item xs={6}>
                    <S.Button
                      color='red'
                      borderColor='red'
                      onClick={() => handleModal(false)}
                    >
                      Cancelar
                    </S.Button>
                  </Grid>
                  <Grid item xs={6}>
                    <S.Button
                      color='blue'
                      borderColor='blue'
                      onClick={async () => await fetchCsv()}
                    >
                      Confirmar
                    </S.Button>
                  </Grid>
                </S.Buttons>
              </Grid>
            </>
          )}
        </DialogContent>
      </S.ReportCsv>
    </>
  );
}
