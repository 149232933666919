import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArrowBack from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForward from '@material-ui/icons/ArrowForwardIosOutlined';
import PropTypes from 'prop-types';
import Card from './Card';
import {
  CarouselDivStyled,
  CarouselStyled,
  arrowStyles,
  indicatorStyles,
} from './styled/styles';

const CarouselComp = (props) => {
  const handleHock = (check, id) => {
    if (check) {
      props.ids.push(id);
      props.idAviso(props.ids);
    }
  };
  const formatStatus = (currentItem, total) => {
    return `${currentItem} / ${total}`;
  };
  const arrowBack = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type='button'
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, left: 5 }}
      >
        <ArrowBack />
      </button>
    );
  const arrowForward = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type='button'
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, right: 5 }}
      >
        <ArrowForward />
      </button>
    );
  const indicator = (onClickHandler, isSelected, index) => {
    if (isSelected) {
      return <li style={{ ...indicatorStyles, background: '#000' }} />;
    }

    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role='button'
        tabIndex={0}
      />
    );
  };
  let arr = props.data ? props.data : [];

  return (
    <>
      <CarouselDivStyled>
        <CarouselStyled
          showThumbs={false}
          showIndicators={true}
          statusFormatter={formatStatus}
          renderArrowPrev={arrowBack}
          renderArrowNext={arrowForward}
          renderIndicator={indicator}
        >
          {arr.docs?.map((data, index) => {
            return (
              <>
                <Card
                  title={data.title}
                  text={data.content}
                  startDate={data.startDate}
                  links={data.links}
                  handleHock={(e) => handleHock(e, data._id)}
                />
              </>
            );
          })}
        </CarouselStyled>
      </CarouselDivStyled>
    </>
  );
};
CarouselStyled.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default CarouselComp;
