import React from 'react';
import MenuPainel from '../components/MenuPainel';
import { AgendaTelepsicologia } from '../components/AgendaTelepsicologia';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import Alert from '../components/Alert';
import { avaliableMonth } from '../helpers/getEnableOrDisableDates';

class AdmAgendaTelepsicologia extends React.Component {
  render() {
    const { message, type, open } = this.props;
    const disabledDates = avaliableMonth(1, true, true);
    return (
      <React.Fragment>
        <ChangePassword />
        <MenuPainel>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
          <PaperForm
            title={''}
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<AgendaTelepsicologia disabledDates={disabledDates} />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = AdmAgendaTelepsicologia;
export { connectedAssinar as AdmAgendaTelepsicologia };
