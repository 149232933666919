// libs
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';

import ErrorIcon from '../../Lib/assets/icons/error-icon.svg';

import CloseIcon from '@material-ui/icons/Close';

import * as S from './styles';
import { CustomButton, type ButtonProps } from '../../Lib/CustomButton';

export interface ModalTemplateProps {
  open?: boolean;
  onClose: () => void;
}

interface ButtonActionProps {
  label: string;
  onClick?: () => void;
  variant: ButtonProps['typeButtom'];
  type?: 'button' | 'submit' | 'reset';
}

interface ModalProps extends ModalTemplateProps {
  children: React.ReactNode;
  title: string;
  onSubmit?: React.FormEventHandler<HTMLElement>;
  onReset?: React.FormEventHandler<HTMLElement>;
  isSubmitLoading?: boolean;
  primaryAction?: ButtonActionProps;
  secondaryAction?: ButtonActionProps;
  renderAsError?: boolean;
  errorMessage?: string;
  allowCloseWhileLoading?: boolean;
}

export function Modal({
  children,
  onClose,
  title,
  open,
  primaryAction,
  secondaryAction,
  onSubmit,
  onReset,
  isSubmitLoading,
  renderAsError,
  errorMessage,
}: ModalProps) {
  const handleClose = (evt: React.MouseEvent<HTMLElement>, reason?: string) => {
    if (reason === 'backdropClick') {
      evt.preventDefault();
      return evt.stopPropagation();
    }

    if (isSubmitLoading) return;

    onClose();
  };

  const handleSubmit = (evt: React.FormEvent<HTMLElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (onSubmit) onSubmit(evt);
  };

  return (
    <Dialog
      open={open || false}
      onClose={handleClose}
      maxWidth='sm'
      PaperProps={{ style: { borderRadius: '8px' } }}
      fullWidth
    >
      <S.Wrapper>
        <DialogTitle>
          <S.TitleWrapper>
            <Typography variant='h5' style={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          </S.TitleWrapper>
          <S.CloseButtonWrapper>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              disabled={isSubmitLoading}
            >
              <CloseIcon />
            </IconButton>
          </S.CloseButtonWrapper>
        </DialogTitle>
        <DialogContent>
          {renderAsError ? (
            <S.ErrorWrapper>
              <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
              <img src={ErrorIcon} alt='Símbolo de erro' />
              <CustomButton size='large' typeButtom='gray' onClick={onClose}>
                Voltar
              </CustomButton>
            </S.ErrorWrapper>
          ) : (
            <S.ContentWrapper
              $shouldRenderAsAForm={Boolean(onSubmit)}
              onSubmit={handleSubmit}
              onReset={onReset}
            >
              {children}
              <S.ActionsWrapper>
                {secondaryAction && (
                  <CustomButton
                    size='medium'
                    typeButtom={secondaryAction.variant}
                    onClick={secondaryAction.onClick}
                    type={secondaryAction.type}
                  >
                    {secondaryAction?.label}
                  </CustomButton>
                )}
                {primaryAction && (
                  <CustomButton
                    size='medium'
                    typeButtom={primaryAction.variant}
                    onClick={primaryAction.onClick}
                    type={primaryAction.type}
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? (
                      <S.LoaderWrapper>
                        <Loader
                          type='ThreeDots'
                          color='#ccc'
                          ariaLabel='three-dots-loading'
                          radius='9'
                          height={60}
                          width={60}
                        />
                      </S.LoaderWrapper>
                    ) : (
                      primaryAction?.label
                    )}
                  </CustomButton>
                )}
              </S.ActionsWrapper>
            </S.ContentWrapper>
          )}
        </DialogContent>
      </S.Wrapper>
    </Dialog>
  );
}
