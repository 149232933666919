import { isCEP } from 'brazilian-values';
export const setErrorCEP = (value) => {
  let cep = value?.replace(/[^0-9]/g, '');
  if (cep.length < 3) {
    return 'Campo obrigatório.';
  } else {
    return isCEP(value) ? ' ' : 'CEP inválido';
  }
};
export const setErrorCity = (formikValues, touch) => {
  if (formikValues.country == 'Brasil' && formikValues.city == '') {
    return { err: true, message: 'Campo obrigatório.' };
  }
  if (
    formikValues.country == 'United States of America' &&
    formikValues.city == ''
  ) {
    return { err: false, message: '' };
  }

  if (formikValues.country === 'Brasil' && touch) {
    return formikValues.state?.length < 2
      ? { err: true, message: 'Campo obrigatório.' }
      : { err: false, message: '' };
  }
  if (
    formikValues.country != 'Brasil ' ||
    formikValues.country != 'United States of America'
  ) {
    return { err: false, message: '' };
  }
  //return value.length < 2 ? 'Campo obrigatório. ' : '';
};
export const setErrorState = (formikValues, touch) => {
  if (formikValues.country === 'United States of America' && touch) {
    return formikValues.state?.length < 2
      ? { err: true, message: 'Campo obrigatório.' }
      : { err: false, message: '' };
  }

  if (formikValues.country === 'Brasil' && touch) {
    return formikValues.state?.length < 2
      ? { err: true, message: 'Campo obrigatório.' }
      : { err: false, message: '' };
  }

  if (formikValues.country === 'United States of America' && touch) {
    return formikValues.state?.length < 2
      ? { err: true, message: 'Campo obrigatório.' }
      : { err: false, message: '' };
  }

  if (formikValues.country === 'Brasil' && formikValues.state == undefined) {
    return { err: true, message: 'Campo obrigatório.' };
  }

  if (
    formikValues.country === 'United States of America' &&
    formikValues.state == undefined
  ) {
    return { err: true, message: 'Campo obrigatório.' };
  }
  // if (
  //   formikValues.country === 'Brasil' &&
  //   (formikValues.nationality === 'B' ||
  //     formikValues.nationality === 'Brasileira') &&
  //   touch
  // ) {
  //   return formikValues.state?.length < 2
  //     ? { err: true, message: 'Campo obrigatório.' }
  //     : { err: false, message: '' };
  // }
  if (
    formikValues.country != 'Brasil ' ||
    formikValues.country != 'United States of America'
  ) {
    return { err: false, message: '' };
  }
};

export const setErrorCountry = (country, err) => {
  if (country && err) {
    return { err: true, message: 'Campo obrigatório' };
  } else {
    return { err: false, message: '' };
  }
};
