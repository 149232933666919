import { isCPF } from 'brazilian-values';
import { userService } from './user.service';
import { REQUEST_TIMEOUT } from '../constants';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const deleteService = {
  deleteDocs,
};

async function deleteDocs({ user, isDependent }) {
  let docDelete = JSON.stringify(user.document);
  if (isCPF(user.document)) {
    docDelete = docDelete.match(/\d/g).join('');
  } else {
    docDelete = user.document;
  }
  function Data(data) {
    return data.substr(0, 10).split('/').reverse().join('-');
  }
  let newData = Data(user.birthDate);

  const requestOptions = {
    method: 'POST',
    // mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      _id: user?._id,
      isVonage: user.isVonage,
      document: docDelete,
      email: user.email,
      token: user.userLogin.token,
      isDependent: isDependent,
      userId: user._id,
      dependent: {
        name: user.fullName,
        birthDate: newData,
      },
      docRemovedBy: user.docRemovedBy,
      userLogin: {
        id: user.userLogin.user.id_perfil._id,
        email: user.userLogin.user.email,
      },
    }),
  };
  return await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/deleteDocs`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
