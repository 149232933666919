import moment from 'moment';

import { formatDate, getDiff } from '../../../../utils/format';

const STATUS = Object.freeze({
  BLOQUED: 'bloqueado',
  REQUESTED_UNBLOCK: 'solicitou desbloqueio',
  FREE: 'livre',
  CONNECTED: 'conectado',
  IN_ATTENDANCE: 'em atendimento',
  PAUSE_SNACK: 'pausa lanche',
  PAUSE_REST: 'pausa descanso',
  PAUSE_FEEDBACK: 'pausa feedback',
  PAUSE_PERSONAL: 'pausa pessoal',
  OTHER: 'other',

})

export const getPauseScaleStatusColor = (scaleStatus) => {
  const status = {
    livre: 'default',
    conectado: 'default',
    bloqueado: 'danger',
    'solicitou desbloqueio': 'danger',
    'em atendimento': 'success',
    'pausa descanso': 'warning',
    'pausa lanche': 'warning',
    'pausa feedback': 'warning',
    'pausa pessoal': 'warning',
  };

  return status[scaleStatus?.toLowerCase()] || status.livre;
};

export const mountPauseTime = ({ intervalStart, intervalEnd }) => {
  const start = formatDate(intervalStart, ' HH:mm');
  const end = formatDate(intervalEnd, ' HH:mm');

  return end ? `${start} - ${end}` : start;
};

export const parseScalesToTimeLine = (scales) =>
  scales.map(({ userName, departureTime, entryTime }) => ({
    name: userName,
    start: entryTime,
    end: departureTime,
  }));

export const orderByScale = (array) =>
  array.sort((a, b) => moment(a.entryTime) - moment(b.entryTime));

export const orderByStatus = (array) => {
  const pausesStatus = [
    'pausa lanche',
    'pausa descanso',
    'pausa feedback',
    'pausa pessoal',
  ];

  const priorities = [
    'bloqueado',
    'solicitou desbloqueio',
    'livre',
    'conectado',
    'em atendimento',
    'pausa lanche',
    'pausa descanso',
    'pausa feedback',
    'pausa pessoal',
  ];
  
  const toLowerCaseHelper = (propertie) => propertie.toLowerCase() 

  const isDifferentStatus = (element) =>
      ![STATUS.BLOQUED, STATUS.FREE, STATUS.IN_ATTENDANCE].includes(toLowerCaseHelper(element.status?.description)) &&
      !pausesStatus.includes(toLowerCaseHelper(element?.status?.description));

  const sortDiff = (arr) =>
    arr.sort((a, b) => new Date(a?.status?.start) - new Date(b?.status?.start));

  const sortByPauseStatusOrder = (unOrdered) => 
    unOrdered.sort((a, b) => 
      pausesStatus.indexOf(toLowerCaseHelper(a.status.description)) - 
      pausesStatus.indexOf(toLowerCaseHelper(b.status.description)
      ))

  const orderedPriority = array.sort(
    (a, b) => 
      priorities.indexOf(toLowerCaseHelper(a.status.description)) - 
      priorities.indexOf(toLowerCaseHelper(b.status.description))
  );

  const blocked = sortDiff(
    orderedPriority.filter(
      (a) => a.timeLogin && [STATUS.REQUESTED_UNBLOCK, STATUS.BLOQUED].includes(toLowerCaseHelper(a?.status?.description))
    )
  );

  const freeAtt = sortDiff(
    orderedPriority.filter(
      (a) => a.timeLogin && [STATUS.FREE].includes(toLowerCaseHelper(a?.status?.description))
    )
  );
  const inAtt = sortDiff(
    orderedPriority.filter(
      (a) => a.timeLogin && [STATUS.IN_ATTENDANCE].includes(toLowerCaseHelper(a?.status?.description))
    )
  );
  const inPause = sortByPauseStatusOrder(
    sortDiff(
    orderedPriority.filter((a) =>
      a.timeLogin &&
      pausesStatus.includes(toLowerCaseHelper(a?.status?.description))
    )
  ));

  const restStatus = orderedPriority.filter((a) => !a.timeLogin || isDifferentStatus(a));
  
  const orderedArray = [
    ...blocked,
    ...freeAtt,
    ...inAtt,
    ...inPause,
    ...restStatus,
 ];

  return [...new Set(orderedArray)];
};

export const isSameDate = (date1, date2) => {
  const formatedDate1 = moment(date2).utc().format('YYYY-MM-DD');
  const formatedDate2 = moment(date1).utc().format('YYYY-MM-DD');

  return formatedDate1 === formatedDate2;
};

const hasAddZero = (v = 0) => (v >= 10 ? v : '0' + v);

const calcBreakTimeFinish = (field, duration, user) => {
  const time =
    user[field].length === 5
      ? user[field].split(':').map((e) => parseInt(e))
      : moment(user[field])
          .utc()
          .format('HH:mm')
          .split(':')
          .map((e) => parseInt(e));

  const fullMinutes = time[0] * 60 + time[1] + duration;

  const newHours = hasAddZero(Math.trunc(fullMinutes / 60));
  const newMinutes = hasAddZero(fullMinutes % 60);

  const newTime = `${newHours}:${newMinutes}`;

  return newTime;
};

const timeToDateISO = (date, timer) => {
  if (timer?.length !== 5) {
    return timer;
  }

  const [hour, minutes] = timer.split(':');

  return moment(date).utc().set({ hour, minutes }).toISOString();
};

const parseUserObject = {
  _id: (value) => ({ _id: value }),
  userId: (value) => ({ userId: value }),
  userName: (value) => ({ userName: value }),
  entryTime: (value) => ({ entryTime: value }),
  departureTime: (value) => ({ departureTime: value }),
  restBreakTimeFinish: (value) => ({ restBreakPause: value }),
  statusStart: (value) => ({ start: value }),
  statusDescription: (value) => ({ description: value }),
  scaleSocketId: (value) => ({ scaleSocketId: value }),
  snackBreakTimeFinish: (value) => ({ snackBreakPause: value }),
  snackBreakTimeBegin: (value, user) => {
    const newTime = calcBreakTimeFinish(
      'snackBreakTime',
      user.snackBreakDuration,
      { snackBreakTime: value }
    );

    return {
      snackBreakTime:
        value.length === 5 ? value : moment(value).utc().format('HH:mm'),
      snackBreakPause: newTime,
    };
  },
  restBreakTimeBegin: (value, user) => {
    const newTime = calcBreakTimeFinish(
      'restBreakTime',
      user.restBreakDuration,
      { restBreakTime: value }
    );

    return {
      restBreakTime:
        value.length === 5 ? value : moment(value).utc().format('HH:mm'),
      restBreakPause: newTime,
    };
  },
  restBreakTimeDuration: (value, user) => {
    const newTime = calcBreakTimeFinish('restBreakTime', value, user);

    return {
      restBreakDuration: value,
      restBreakPause: newTime,
    };
  },
  snackBreakTimeDuration: (value, user) => {
    const newTime = calcBreakTimeFinish('snackBreakTime', value, user);

    return {
      snackBreakDuration: value,
      snackBreakPause: newTime,
    };
  },
  timeLogin: (value, user, searchDate) => ({
    timeLogin: user?.timeLogin || timeToDateISO(searchDate, value),
  }),
  timeLogout: (value, user, searchDate) => ({
    timeLogout: timeToDateISO(searchDate, value) || null,
  }),
};

export const parseUserToNewObject = (
  userObj = {},
  user = {},
  searchScaleInDate
) => {
  let newUserObj = {};

  Object.keys(userObj).forEach((key) => {
    const parsedField = parseUserObject[key]?.(
      userObj[key],
      { ...user, ...newUserObj },
      searchScaleInDate
    );

    if (['statusDescription', 'statusStart'].includes(key)) {
      if (Object.keys(user)?.length) {
        const { status: userStatus } = user;

        const newStatus = newUserObj?.status
          ? { ...userStatus, ...newUserObj.status, ...parsedField }
          : { ...userStatus, ...parsedField };

        newUserObj = { ...newUserObj, status: newStatus };
      } else {
        const status = newUserObj?.status
          ? { ...newUserObj.status, ...parsedField }
          : { ...parsedField };

        newUserObj = { ...newUserObj, status };
      }
    } else {
      parsedField && (newUserObj = { ...newUserObj, ...parsedField });
    }
  });

  return newUserObj;
};

export const isStatusCounting = (dateStart = null) => {
  if (!dateStart) return false;

  const formattedStatusInit = formatDate(dateStart, 'YYYY-MM-DD');
  const currentDate = formatDate(new Date(), 'YYYY-MM-DD');

  const isToday = getDiff(currentDate, formattedStatusInit, 'days') === 0;

  return isToday;
};
