import request from '../request';

const execute = async ({ data }) => {
  try {
    const { id } = data;
    const response = await request.get(`/scale?id:${id}`, {
      headers: {
        'x-access-token': headers.userToken,
        _id: headers._id,
      },
    });

    if (response.err) {
      return {
        err: true,
        data: {
          ...response.data,
        },
      };
    }

    return { err: false, data: response?.data };
  } catch (error) {
    return {
      err: true,
      data: {
        errorMessage: 'Erro interno',
        error,
      },
    };
  }
};

export default execute;
