export const agendamentoTPsicolConstants = {
  AGENDAMENTOTPSICOL_REQUEST: 'AGENDAMENTOTPSICOL_REQUEST',
  AGENDAMENTOTPSICOL_SUCCESS: 'AGENDAMENTOTPSICOL_SUCCESS',
  AGENDAMENTOTPSICOL_FAILURE: 'AGENDAMENTOTPSICOL_FAILURE',
  AGENDAMENTOTPSICOL_FILTER: 'AGENDAMENTOTPSICOL_FILTER',
  AGENDAMENTOTPSICOL_CLEAR: 'AGENDAMENTOTPSICOL_CLEAR',
};

export const editAgendamentoTPsicolConstants = {
  AGENDAMENTOTPSICOL_EDIT_REQUEST: 'AGENDAMENTOTPSICOL_EDIT_REQUEST',
  AGENDAMENTOTPSICOL_EDIT_SUCCESS: 'AGENDAMENTOTPSICOL_EDIT_SUCCESS',
  AGENDAMENTOTPSICOL_EDIT_FAILURE: 'AGENDAMENTOTPSICOL_EDIT_FAILURE',
};
