import React from 'react';

import {
  CustomButton,
  CustomDivider,
  CustomModal,
  CustomText,
} from '../../../../../../../../../Spread/components/ui';

import {
  ContainerAlignCenter,
  ContainerForm,
  ContainerButtons,
  LeftAreaButton,
  RightAreaButton,
  SliderContainer,
  RadioGroupContainer,
  ContainerBottomButtons,
  ContainerTextarea,
} from './styles';

import { useModalNotAttend } from './hooks/useModalNotAttend';

import {
  CustomInput,
  CustomRadioGroup,
} from '../../../../../../../../../Spread/components/ui/webform';

import { typeFaults } from './constants';

const ModalNotAttend = ({ show, onClose, data = {}, trigger }) => {
  const {
    useDataType,
    useTypeFault,
    setDataType,
    useObervationInput,
    handleObervationInputChange,
    handleChangeTypeFault,
    saveFault,
    isInWaitForDateSchedule,
  } = useModalNotAttend(show, onClose, data, trigger);

  return (
    <>
      {show && (
        <CustomModal show={show} onClose={onClose}>
          <>
            <ContainerAlignCenter>
              <CustomText size='xlarge' color='dark'>
                Contato com sucesso?
              </CustomText>
            </ContainerAlignCenter>

            <ContainerAlignCenter>
              <CustomText size='large'>
                ({data.socialName || `${data.name} ${data.lastName}`} -{' '}
                {data.document})
              </CustomText>
            </ContainerAlignCenter>

            <CustomDivider />

            <ContainerForm>
              <ContainerButtons>
                <LeftAreaButton
                  selected={useDataType === false}
                  disabled={isInWaitForDateSchedule}
                  onClick={() => !isInWaitForDateSchedule && setDataType(false)}
                >
                  <CustomText color='dark' size='xlarge'>
                    Não
                  </CustomText>
                </LeftAreaButton>
                <RightAreaButton
                  selected={useDataType === true}
                  onClick={() => setDataType(true)}
                >
                  <CustomText color='dark' size='xlarge'>
                    Sim
                  </CustomText>
                </RightAreaButton>
              </ContainerButtons>

              <SliderContainer show={useDataType !== null}>
                <RadioGroupContainer>
                  <CustomRadioGroup
                    data={
                      useDataType === false
                        ? typeFaults.failed.map((e) => ({ value: e, label: e }))
                        : typeFaults.success.map((e) => ({
                            value: e,
                            label: e,
                          }))
                    }
                    value={useTypeFault}
                    onChange={handleChangeTypeFault}
                  />
                </RadioGroupContainer>

                <ContainerTextarea>
                  <CustomInput
                    value={useObervationInput}
                    onChange={handleObervationInputChange}
                    type='textarea'
                    full
                    placeholder='Observações...'
                  />
                </ContainerTextarea>

                <ContainerBottomButtons>
                  <CustomButton
                    size='large'
                    type='outlined'
                    text='Cancelar'
                    onClick={onClose}
                  />
                  <CustomButton
                    size='large'
                    text='Salvar'
                    onClick={saveFault}
                  />
                </ContainerBottomButtons>
              </SliderContainer>
            </ContainerForm>
          </>
        </CustomModal>
      )}
    </>
  );
};

export default ModalNotAttend;
