import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import findAllQueueRecordsReducer from '../store/queue-records/find-all/find-all-slice';
import showQueueRecordsReducer from '../store/queue-records/show/find-all-slice';
import reportQueueRecordsReducer from '../store/queue-records/report/report-slice';

export const store = configureStore({
  reducer: {
    findAllQueueRecords: findAllQueueRecordsReducer,
    reportQueueRecords: reportQueueRecordsReducer,
    showQueueRecords: showQueueRecordsReducer,
  },
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const dispatch = useAppDispatch;
export const selector = useAppSelector;

export type UseAppDispatch = typeof useAppDispatch;
export type UseAppSelector = typeof useAppSelector;
