// libs
import { useState, useCallback, FormEvent } from 'react';

import { validations } from '../../helpers/validations';

import { useRecaptcha, ReCaptchaActions } from '../../reCaptcha';

import { userActions } from '../../actions';

import * as S from '../style';
import { StyledError } from './style';

import { PageProps } from '../types';

interface RecoverPasswordFormProps {
  pageProps: PageProps;
  onGoBackToLogin?: () => void;
  onSuccess?: () => void;
}
export function RecoverPasswordForm({
  pageProps,
  onGoBackToLogin,
  onSuccess,
}: RecoverPasswordFormProps) {
  const [emailValue, setEmailValue] = useState<string>('');

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  const { reCaptchaToken, handleRequestAuthorization } = useRecaptcha(
    ReCaptchaActions.RECOVER_PASSWORD_SUBMIT
  );

  const handleChangeEmail = useCallback((evt: FormEvent<HTMLInputElement>) => {
    setEmailValue(evt.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      evt.stopPropagation();

      const hasErrors = validations.validationEmail(emailValue);

      if (emailValue && hasErrors === false && reCaptchaToken) {
        await pageProps.dispatch(userActions.RecoverPassword(emailValue, reCaptchaToken));

        if (onSuccess) onSuccess();
      }

      setIsSubmitted(true);

      handleRequestAuthorization();
    },
    [emailValue, onSuccess, reCaptchaToken]
  );

  const handleBlur = useCallback(() => {
    setIsEmailValid(validations.validationEmail(emailValue));
  }, [emailValue]);

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LogoContainer>
          <S.Logo />
        </S.LogoContainer>
        <S.LoginWrapper>
          <form onSubmit={handleSubmit}>
            <S.LoginHeader>
              <S.LoginTitle>Recuperação de senha.</S.LoginTitle>
              <S.Subtitle>
                Informe abaixo o seu <strong>e-mail cadastrado</strong> e receba
                instruções para alterar sua senha.
              </S.Subtitle>
            </S.LoginHeader>
            <S.ContainerInput>
              <S.Label
                style={{
                  marginTop: '12px',
                  marginBottom: '6px',
                }}
              >
                E-mail
              </S.Label>
              <S.InputText
                name='email'
                onChange={handleChangeEmail}
                onBlur={handleBlur}
                value={emailValue}
                placeholder='Seu e-mail'
                $showBorder={
                  (isSubmitted && !emailValue) ||
                  (isEmailValid && emailValue != '')
                }
                $hasBoxShadow={isSubmitted}
              />
            </S.ContainerInput>

            {isSubmitted && !emailValue && (
              <StyledError>Campo obrigatório.</StyledError>
            )}
            {isEmailValid && emailValue !== '' && (
              <StyledError>E-mail inválido.</StyledError>
            )}

            <S.ContainerInput>
              <S.ButtonBlueContained type='submit'>
                ENVIAR
              </S.ButtonBlueContained>

              <S.ButtonBlueOutlined
                style={{ marginTop: '10px' }}
                onClick={onGoBackToLogin}
              >
                Voltar
              </S.ButtonBlueOutlined>
            </S.ContainerInput>
          </form>
        </S.LoginWrapper>
      </S.LoginContainer>
      <S.CompanyImage />
    </S.Container>
  );
}
