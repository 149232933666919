import React from 'react'

import {
  StyledRow,
  StyledTitle,
  StyledHourContainer,
  StyledProfessionalSchedule,
  StyledTimeLine,
  StyledProfessionalHour,
  StyledHourSchedule,
  StyledCenteredContent
} from './styles'

import { hours } from './constants'

import { CustomText, CustomToolTip, CustomLoader, CustomDivider } from '../'

import { calculatePeriod, hasPeriod, displayHour } from './utils'

const TimeLine = ({ data }) => (
  <>
    <StyledTimeLine>
      <StyledRow>
        <StyledHourContainer>
          {hours.map((h, idx) => (
            <StyledProfessionalHour key={idx} fixed>
              <StyledHourSchedule>
                {displayHour(h)}
              </StyledHourSchedule>
            </StyledProfessionalHour>
          ))}
        </StyledHourContainer>
      </StyledRow>
    </StyledTimeLine>

    {data === null && (
      <StyledCenteredContent>
        <CustomDivider />
        <CustomText>Não há dados disponíveis.</CustomText>
        <CustomDivider />
      </StyledCenteredContent>
    )}

    {data && data.length === 0 && (
      <StyledCenteredContent>
        <CustomDivider />
        <CustomLoader />
      </StyledCenteredContent>
    )}

    <StyledTimeLine>
      {data && data.length > 0 && data.map((u, i) => (
        <StyledRow key={i}>
          <StyledTitle>
            {u?.name?.length < 14 ?
              (
                <CustomText style={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100px'
                }}
                >
                  {u?.name}
                </CustomText>
              )
              : (
                <CustomToolTip text={u?.name} align='top'>
                  <CustomText style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100px'
                  }}
                  >
                    {u?.name}
                  </CustomText>
                </CustomToolTip>
              )}

          </StyledTitle>
          <StyledHourContainer>
            {hours.map((h, idx) => (
              <StyledProfessionalHour key={idx}>
                {hasPeriod(u?.start, u?.end, h) && (
                  <StyledProfessionalSchedule
                    filled={true}
                    style={{
                      ...calculatePeriod(u?.start, u?.end, h),
                    }}
                  />
                )}
              </StyledProfessionalHour>
            ))}
          </StyledHourContainer>
        </StyledRow>
      ))
      }
    </StyledTimeLine>
  </>
)

export default TimeLine
