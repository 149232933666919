import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../Loader';

const styles = (theme) => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
});

class LoadingNew extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.overlay}>
          <div className='loader_conecta'>
            <Loader background={'#007ACC'} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const connectedRegisterPage = LoadingNew;
export default withStyles(styles)(connectedRegisterPage);
