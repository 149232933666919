export const comunicadosConstants = {
  COMUNICATION_REQUEST: 'COMUNICATION_REQUEST',
  COMUNICATION_SUCCESS: 'COMUNICATION_SUCCESS',
  COMUNICATION_FAILURE: 'COMUNICATION_FAILURE',
};

// export const editSuporteConstants = {
//   COMUNICATION_EDIT_REQUEST: 'COMUNICATION_EDIT_REQUEST',
//   COMUNICATION_EDIT_SUCCESS: 'COMUNICATION_EDIT_SUCCESS',
//   COMUNICATION_EDIT_FAILURE: 'COMUNICATION_EDIT_FAILURE',
// }
