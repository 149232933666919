import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { triggerService, notificationService } from '../../../services/core';

import { acionamentoActions } from '../../../../actions';

import {
  notificationTitle,
  notificationPush,
  socketSignature,
} from '../contants';

import { isNotificationValid, hasNotification } from '../utils';

import WorkerBuilder from '../../../worker';

import Worker from '../worker';

import { usePushNotification } from '../../../hooks';

const io = require('socket.io-client');

const defaultNotificationTitle = document.title;

const useNotifications = () => {
  const dispatch = useDispatch();

  const { callPermision, spawnNotification } = usePushNotification();

  const [useNotification, setNotification] = useState(false);

  callPermision();

  useEffect(() => {
    // const socket = io.connect(process.env.REACT_APP_API_SOCKET_IO_CR, { "transports": ['websocket'] });

    // const instance = new WorkerBuilder(Worker);

    // if (hasNotification() && !useNotification) {
    //   const notification = hasNotification();

    //   getTriggerData(notification.acionamentoId);
    // }

    // socket.on(socketSignature, (payload) => {
    //   const { fullDocument, updateDescription, documentKey } = payload;

    //   let data = fullDocument || updateDescription?.updatedFields || null;

    //   data.documentKey = documentKey._id;

    //   if (isNotificationValid(data)) {
    //     getTriggerData(data.acionamentoId, data.documentKey);
    //   }
    // });

    // instance.onmessage = () => {
    //   resetUseNotification();
    // };

    // if (useNotification) {
    //   instance.postMessage(true);
    //   document.title = notificationTitle;
    //   spawnNotification(notificationPush);
    // }

    // return () => {
    //   document.title = defaultNotificationTitle;
    //   instance.terminate();
    //   socket.disconnect();
    // };
  }, [useNotification]);

  const userLocalStorageData = () => {
    const userLocalStorage = localStorage.getItem('user');
    const userData = JSON.parse(userLocalStorage);

    return {
      userId: userData.user._id,
      userName: userData.user.nome,
    };
  };

  const useNotificationDTO = (trigger, triggerId, documentKey) => ({
    name: trigger?.name || '',
    lastName: trigger?.lastName || '',
    userName: trigger?.name || '',
    socialName: trigger?.socialName || '',
    queue: trigger?.queue || '',
    convenio: trigger?.convenio || '',
    convenioDescricao: trigger?.convenioDescricao || '',
    document: trigger?.document || '',
    documentType: trigger?.documentType || '',
    email: trigger?.email || '',
    appointment: trigger?.appointment || '',
    birthDate: trigger?.birthDate || '',
    telefone: trigger?.telefone || '',
    gender: trigger?.gender || '',
    patientId: trigger?.patientId || '',
    acionamentoId: triggerId || '',
    phone: trigger?.phone || '',
    documentKey: documentKey || '',
    fullObject: {
      ...trigger,
    },
    ...userLocalStorageData(),
  });

  const getTriggerData = async (triggerId, documentKey) => {
    const { status, data } = await triggerService.getOne(triggerId);

    if (status && data.acionamento) {
      const notificationData = useNotificationDTO(
        data.acionamento,
        triggerId,
        documentKey
      );

      setNotification(notificationData);
    }
  };

  const handleTriggerStart = async () => {
    const tzoffset = new Date().getTimezoneOffset() * 60000;
    const editionTime = new Date(Date.now() - tzoffset).toISOString();

    await notificationService.updateStatus(
      useNotification.documentKey,
      'aceito'
    );

    await triggerService.changeTriggerStatus({
      attendanceInfos: {
        userId: useNotification.userId,
        userName: useNotification.userName,
        attendanceId: useNotification.acionamentoId,
        _id: useNotification.acionamentoId,
        attendanceType: 'recepcionist',
      },
    });

    dispatch(
      acionamentoActions.editAcionamentos({
        ...useNotification?.fullObject,
        editionTime,
        edit: true,
        new: true,
      })
    );

    resetUseNotification();
  };

  const resetUseNotification = () => {
    if (localStorage.getItem('notification')) {
      localStorage.removeItem('notification');
    }

    document.title = defaultNotificationTitle;

    setNotification(null);
  };

  return {
    handleTriggerStart,
    useNotification,
    setNotification,
  };
};

export default useNotifications;
