import { modalAlertConstants } from '../constants';

export const modalAlertActions = {
  success,
  error,
  clear,
};

function success(messageModalAlert) {
  return {
    type: modalAlertConstants.SUCCESS,
    messageModalAlert,
    openModalAlert: true,
    loading: true,
  };
}

function error(messageModalAlert) {
  return {
    type: modalAlertConstants.ERROR,
    messageModalAlert,
    openModalAlert: true,
    loading: true,
  };
}

function clear(props, type, messageModalAlert) {
  return {
    type: modalAlertConstants.CLEAR,
    varient: type,
    messageModalAlert: messageModalAlert,
    props,
  };
}
