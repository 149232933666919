interface GetExclusiveFieldsOrderProps {
  isUpaDigital?: boolean;
  convenio: string;
}

export const getExclusiveFieldsOrder = ({
  convenio,
  isUpaDigital,
}: GetExclusiveFieldsOrderProps) => {
  if (isUpaDigital) return true;

  if (!convenio) return false;

  const contractId = process.env.REACT_APP_UPA_DIGITAL_CONTRACT;

  return String(convenio) === String(contractId);
};
