// libs
import { useState, useCallback } from 'react';

import { useRecaptcha, ReCaptchaActions } from '../../../reCaptcha';

import { LoginErrorProps } from '../../LoginError';

import { PageProps } from '../../types';

import { LoginLabelErrors } from '../data';
import { FormFields, LoginFormEvent } from './form-fields';

interface FormProps {
  updateError: (error: LoginErrorProps | null) => void;
  onSubmit: (userCredentials: LoginFormEvent, reCaptchaToken: string) => void;
  pageProps: PageProps;
}

export function Form({ updateError, onSubmit, pageProps }: FormProps) {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { reCaptchaToken, handleRequestAuthorization } = useRecaptcha(
    ReCaptchaActions.LOGIN_SUBMIT
  );

  const [isLoginInvalid, setIsLoginInvalid] = useState<boolean>(false);

  const [state, updater] = useState<LoginFormEvent>({
    password: '',
    username: '',
  });

  const handleSubmit = useCallback(
    async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      updateError(null);

      if (!reCaptchaToken || typeof reCaptchaToken !== 'string')
        return updateError(LoginLabelErrors.RECAPTCHA_ERROR);

      if (!state.username || !state.password) {
        updateError(LoginLabelErrors.REQUIRED_FIELD);
      }

      setIsSubmitted(true);

      if (state.username && state.password) {
        onSubmit(state, reCaptchaToken as string);
      }

      handleRequestAuthorization();
    },
    [state, reCaptchaToken]
  );

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <FormFields
        pageProps={pageProps}
        disableButton={!reCaptchaToken}
        isLoginInvalid={isLoginInvalid}
        isSubmitted={isSubmitted}
        updater={updater}
        state={state}
      />
    </form>
  );
}
