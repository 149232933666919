import React from 'react';

import { createPortal } from 'react-dom';

import {
  StyledLayer,
  StyledRow,
  StyledProgress,
  StyledIconCloseWrapper,
} from './styles';

import { Slide } from '../../context';

import { CustomText, CustomIcon, CustomDivider } from '../../..';

import useLayer from './hooks/useLayer';

const Layer = ({ id, align, onTimeEnd, type, duration, message = '' }) => {
  const iconsType = {
    warning: 'Warning',
    info: 'Info',
    danger: 'Error',
    success: 'CheckCircle',
  };

  const { layerAlign, abortSnack, useFadeOut } = useLayer(
    id,
    align,
    duration,
    onTimeEnd
  );

  const currentIcon = iconsType[type] || 'Info';

  const slideAlign = align.search('Left') < 0 ? 'right' : 'left';

  return createPortal(
    <Slide from={slideAlign}>
      <StyledLayer
        fadeOut={useFadeOut}
        customColor={type}
        style={{
          ...layerAlign,
          zIndex: 10,
          position: 'fixed',
        }}
      >
        <StyledIconCloseWrapper>
          <CustomIcon
            icon='Close'
            color='light'
            size={14}
            onClick={() => {
              abortSnack();
            }}
          />
        </StyledIconCloseWrapper>

        <CustomDivider space={0} height={2} />

        <StyledRow>
          <CustomIcon icon={currentIcon} color='light' />
          <CustomText color='light' title={1}>
            {message}
          </CustomText>
        </StyledRow>

        <CustomDivider space={0.1} />

        <StyledProgress duration={duration} />
      </StyledLayer>
    </Slide>,
    document.body
  );
};

export default Layer;
