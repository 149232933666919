import styled from 'styled-components';
import Select from 'react-select';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const ContainerLabel1 = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
`;
const Container1 = styled.div`
  width: 100%;
`;
const Space = styled.div`
  display: block;
  margin-bottom: 30px;
  margin-top: 20px;
`;
const Margin = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;
const ContainerLabel2 = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
`;
const Container2 = styled.div`
  width: 100%;
`;
const SelectStyled = styled(Select)`
  .Select__control {
    height: 42px;
    background: #f1f4f6;
    border: ${(props) => (props.border ? '1px solid red' : '')};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '')};
    :hover,
    :focus {
      border: ${(props) =>
        props.border ? '1px solid red' : '1px solid #2699fb'};
      //border: 1px solid #2699fb;
      background-color: white;
    }
  }
  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    width: 100%;
  }
`;

export {
  Container,
  ContainerLabel1,
  Container1,
  ContainerLabel2,
  Container2,
  SelectStyled,
  Space,
  Margin,
};
