import styled from 'styled-components';
import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
export const Container = styled.div`
  width: 624px;
  box-sizing: border-box;
  display: flex;

  .half-size {
    width: 350px;
    margin-right: 30px;
  }

  .titles {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #424242;
    margin-left: 6px;
  }
`;

export const CustomContainer = styled.div`
  min-height: 98px;
`;

export const ArrayButtons = styled.div`
  display: flex;
  padding: 0 5px;
  cursor: pointer;
`;
export const ContainerDate = styled(DateTimePicker)`
  .MuiInput-underline.Mui-error:after {
    transform: scaleX(0);
    border-bottom-color: #e2e2e2;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  height: 35px;
`;

export const ContainerWeek = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 260px;
  > div {
    width: 90px;
  }
`;
