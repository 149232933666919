// @ts-nocheck
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, TablePagination, TableRow, IconButton } from '@material-ui/core';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from '@material-ui/icons';
import { PaginationProps } from '../../../application/shared/hooks';
import { FindAllQueueRecordsStore } from '../../../application/store';
import { usePagination } from '../../../application/shared/hooks';

type Props = {
  pagination: PaginationProps;
  findAllQueueRecordsStore: FindAllQueueRecordsStore;
};

type ActionsProps = {
  total: number;
  page: number;
  offset?: number;
  totalPages?: number;
  setPage: (page: string) => void;
};

function TablePaginationActions({
  total,
  page,
  offset,
  setPage,
}: ActionsProps) {
  const theme = useTheme();

  const handleFirstPageButtonClick = () => {
    setPage('1');
  };

  const handleBackButtonClick = () => {
    setPage((Number(page) - 1).toString());
  };

  const handleNextButtonClick = () => {
    setPage((Number(page) + 1).toString());
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(total / Number(offset))).toString());
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={Number(page) === 1}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={Number(page) === 1}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={Number(page) >= Math.ceil(total / Number(offset))}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={Number(page) >= Math.ceil(total / Number(offset))}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export function Pagination({ pagination, findAllQueueRecordsStore }: Props) {
  const { setPage, setOffset, page, offset } = pagination;
  const { totalPages, pages } =
    findAllQueueRecordsStore.findAllQueueRecordsSelector;

  const pagesString = pages.toString();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage.toString());
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOffset(event.target.value);
    setPage('1');
  };

  function defaultLabelDisplayedRows(from: string, count: string) {
    return `página: ${from} de ${count}`;
  }

  return (
    <TableRow>
      {totalPages && pages && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={9}
          count={totalPages}
          rowsPerPage={Number(offset)}
          page={Number(page) - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={() =>
            TablePaginationActions({
              page: Number(page),
              offset: Number(offset),
              total: totalPages,
              setPage,
            })
          }
          labelRowsPerPage='Linhas'
          labelDisplayedRows={() =>
            defaultLabelDisplayedRows(page, pagesString)
          }
        />
      )}
    </TableRow>
  );
}
