import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
0% {opacity: 0;}
100% {opacity: 1;}
`;
const slideInLeft = keyframes` {
  0% {
   transform: translateX(-100%);
   visibility: visible;
  }
  100% {
  transform: translateX(0);
  }
  }
  `;

// animation-name: ${slideInLeft};
// animation-duration: 1s;
// animation-fill-mode: both;

export const ModalOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-fill-mode: both;
`;

export const Modal = styled.div`
  background: #fff;
  width: 474px;
  max-width: 100%;
  height: 300px;
  /* max-height: 100%; */
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0px 27px 41px #00000026;
  border-radius: 5px;
  opacity: 1;
`;

export const CloseDiv = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  height: 40px;
  align-items: center;
  margin-right: 10px;
`;

export const Close = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 15px;
`;

export const InnerLayer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70%;
`;

export const TitleModal = styled.h1`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

export const TextAreaModal = styled.textarea`
  width: 400px;
  height: 100px;
`;

export const CustomButtomModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
