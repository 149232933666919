import schedulePause from './schedulePause';
import scheduleUnlock from './scheduleUnlock';
import scheduleStatus from './scheduleStatus';
import scheduleRequestUnlock from './scheduleRequestUnlock';
import scheduleNextPause from './scheduleNextPause';

const data = {
  schedulePause,
  scheduleUnlock,
  scheduleStatus,
  scheduleRequestUnlock,
  scheduleNextPause,
};

export default data;
