import React from 'react';

import { StyledWrapper } from './styles';

import { CustomText } from '../../../../../../../Spread/components/ui';

const BoxInfo = ({ text, value }) => (
  <StyledWrapper>
    <CustomText size='medium'>{text}</CustomText>
    <CustomText size='medium' color='dark' style={{ marginLeft: 8 }}>
      {value}
    </CustomText>
  </StyledWrapper>
);

export default BoxInfo;
