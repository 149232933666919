interface ISelectOption {
  value: string;
  label: string;
}

interface IHealthOperatorControl {
  label: string;
  isNumberCardRequired?: boolean;
  isPlanRequired?: boolean;
}

export const healthOperatorsControl: Array<IHealthOperatorControl> = [
  { label: 'Allianz',isNumberCardRequired: true },
  { label: 'Amil',isNumberCardRequired: true },
  { label: 'BNDES',isNumberCardRequired: true },
  { label: 'Bradesco',isNumberCardRequired: true },
  { label: 'Câmara dos Deputados',isNumberCardRequired: true },
  { label: 'Care Plus',isNumberCardRequired: true },
  { label: 'Claro', isNumberCardRequired: true, isPlanRequired: false },
  {
    label: 'CNU - Central Nacional Unimed',isNumberCardRequired: true,
  },
  { label: 'Eletronorte',isNumberCardRequired: true },
  { label: 'Gama Saúde',isNumberCardRequired: true },
  { label: 'ItauSeg',isNumberCardRequired: true },
  { label: 'Life',isNumberCardRequired: true },
  { label: 'Mediservice',isNumberCardRequired: true },
  { label: 'NotreDame',isNumberCardRequired: true },
  { label: 'Omint',isNumberCardRequired: true },
  { label: 'Plan-Assiste',isNumberCardRequired: true },
  {
    label: 'Porto Seguro/Fundação Saúde Itaú',isNumberCardRequired: true,
  },
  { label: 'Senado Federal',isNumberCardRequired: true },
  { label: 'SulAmérica',isNumberCardRequired: true },
  { label: 'Unimed Seguros',isNumberCardRequired: true },
  { label: 'Vale',isNumberCardRequired: true },
  { label: 'Sem cobertura' },
  { label: 'Outros' },
].sort((itemA, itemB) => {
  if (itemA.label < itemB.label) return -1;
  if (itemA.label > itemB.label) return 1;
  return 0;
});

export const healthOperatorOptions: Array<ISelectOption> =
  healthOperatorsControl.map(({ label }) => ({ label, value: label }));

export const isPatientEligibleOptions: Array<ISelectOption> = [
  { label: 'Sim', value: 'sim' },
  { label: 'Não', value: 'não' },
];
