import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Container1,
  Container2,
  ContainerLabel2,
  ContainerLabel1,
  SelectStyled,
  Space,
  Margin,
} from './styles';
import { ErrorInput, LabelInput } from '../FormAdm/styles';
import InputMask from '../FormAdm/InputMask';
import { acionamentoActions } from '../../../../actions';
import { setErrorCity, setErrorState, setErrorCountry } from './validations';
import { estados } from '../../../../commons/index';
import { objectIsEmpty } from '../../../../utils/utils';

export default function SelectGroup({
  group,
  formikValues,
  onBlur,
  setFieldValue,
  errors,
  touched,
  isEdit = false,
}) {
  //-----------------Declaração de hooks---------------------------
  const dispatch = useDispatch();
  const { cepToAddress } = useSelector((state) => state.cepToAddress);
  //-----------------Declaração de states -------------------------
  const [country, setCountry] = useState({
    countryName: '',
    codPais: '',
  });
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [streetCep, setStreetCep] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  //-----------------Declaração de Funções--------------------------
  const getStateByRegion = (state) =>
    dispatch(acionamentoActions.getRegionsByCountry(state));
  const getCitiesByRegion = (region) =>
    dispatch(acionamentoActions.getCityByRegions(region));
  const getAddressFromCep = (cep) =>
    dispatch(acionamentoActions.getAddressFromCep(cep));

  const setMask = (mask) => {
    if (mask === 'cep') {
      return '99999-999';
    }
  };

  function newObjCountry() {
    const { countries } = useSelector((state) => state.country);
    const mod = countries.map((element) => {
      let e = {};
      e.value = element.code;
      e.label = element.name;
      return { ...e };
    });
    return mod;
  }
  const countries = newObjCountry();
  function newObjRegion() {
    let regions = useSelector((state) => state.region.regions);
    let mod;
    if (regions.length >= 1) {
      let filter = regions[0].states;
      if (filter?.length === 27) {
        mod = filter.map((element) => {
          let e = {};
          e.value = element.region;
          e.label = element.region;
          return { ...e };
        });
      }
      if (filter?.length === 51) {
        mod = filter.map((element) => {
          let e = {};
          e.value = element.region;
          e.label = element.region;
          return { ...e };
        });
      }
    }

    return mod;
  }
  const states = newObjRegion();
  function newObjCities() {
    let cities = useSelector((state) => state.citie.cities);
    let mod;
    if (cities) {
      mod = cities.map((element) => {
        let e = {};
        e.value = element.Nome;
        e.label = element.Nome;
        return { ...e };
      });
    }
    return mod;
  }
  const cities = newObjCities();

  const onDisable = (value) => {
    let cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length > 3) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };
  const searchCEP = (value) => {
    if (!objectIsEmpty(value)) {
      setCountry({
        country: {
          countryName: 'Brasil',
          codPais: 'br',
        },
      }),
        setFieldValue('country', 'Brasil');
      setFieldValue('codPais', 'br');
      setCity(value.localidade), setFieldValue('city', value.localidade);
      setState(value.uf), setFieldValue('state', estados[value.uf]);
    }
  };

  const handleReset = () => {
    setCountry({
      country: {
        countryName: '',
        codPais: '',
      },
    }),
      setFieldValue('country', '');
    setFieldValue('codPais', '');
    setCity(''), setFieldValue('city', '');
    setState(''), setFieldValue('state', '');
  };

  //---------------------- Uso de Hooks -------------------

  useEffect(() => {
    dispatch(acionamentoActions.getAllCountryAcionamentos());
    getStateByRegion(country.country ? country.country.codPais : '');
  }, [country]);

  useEffect(() => {
    getCitiesByRegion(state);
  }, [state]);

  useEffect(() => {
    let cep = streetCep?.replace(/[^0-9]/g, '');
    if (cep?.length === 8) {
      getAddressFromCep(cep);
    }

    let streetCepTrim = formikValues?.streetCep?.trim();

    if (streetCepTrim?.length === 9) {
      getAddressFromCep(formikValues.streetCep);
    }

    if (formikValues.streetCep === '') {
      handleReset();
    }
  }, [streetCep]);

  useEffect(() => {
    if (formikValues.streetCep != '' && formikValues.streetCep?.length === 9) {
      searchCEP(cepToAddress);
    }
    //searchCEP(cepToAddress);
  }, [cepToAddress]);

  return (
    <Container>
      {group == '1' && (
        <Container1>
          <ContainerLabel1>
            <Space>
              <Margin>
                <LabelInput>CEP</LabelInput>
                <InputMask
                  value={formikValues.streetCep}
                  onChange={(e) => {
                    setStreetCep(e.target.value),
                      setFieldValue('streetCep', e.target.value);
                  }}
                  name='streetCep'
                  id='streetCep'
                  inputId={'streetCep'}
                  placeholder='CEP'
                  onBlur={onBlur}
                  mask={setMask('cep')}
                  maskChar=' '
                  fullWidth
                  borderRadius={'5px'}
                  color={'1px solid #ced4da'}
                  validation={{
                    errors: formikValues.streetCep < 8,
                    touched: touched.streetCep,
                  }}
                />
              </Margin>
              <Margin>
                <LabelInput>País</LabelInput>
                <SelectStyled
                  classNamePrefix='Select'
                  name='country'
                  inputId={'country'}
                  id='country'
                  value={{
                    value: formikValues.country,
                    label: formikValues.country,
                  }}
                  defaultValue={country}
                  onChange={(e) => {
                    setCountry({
                      country: {
                        countryName: e.label,
                        codPais: e.value,
                      },
                    }),
                      setFieldValue('state', '');
                    setFieldValue('city', '');
                    setFieldValue('country', e.label);
                    setFieldValue('codPais', e.value);
                  }}
                  options={countries.length > 0 ? countries : []}
                  placeholder='Selecione'
                  onBlur={onBlur}
                  borderRadius={'5px'}
                  border={touched?.country && errors?.country}
                  isDisabled={
                    formikValues.streetCep?.length > 7 &&
                    Object.keys(cepToAddress).length > 0
                  }
                />

                <ErrorInput>
                  {errors.country && isEdit && (
                    <ErrorInput>{errors.country}</ErrorInput>
                  )}
                </ErrorInput>

                {touched?.country ? (
                  <ErrorInput>
                    {setErrorCountry(touched?.country, errors?.country).message}
                  </ErrorInput>
                ) : (
                  formikValues.country == undefined && (
                    <ErrorInput>
                      {
                        setErrorCountry(touched?.country, errors?.country)
                          .message
                      }
                    </ErrorInput>
                  )
                )}
              </Margin>
            </Space>
          </ContainerLabel1>
        </Container1>
      )}
      {group == '2' && (
        <Container2>
          <ContainerLabel2>
            <Space>
              <Margin>
                <LabelInput>Estado</LabelInput>
                <SelectStyled
                  classNamePrefix='Select'
                  inputId={'state'}
                  name='state'
                  id='state'
                  type='select'
                  options={states ? states : []}
                  placeholder={'Selecione'}
                  value={{
                    value: formikValues.state,
                    label: formikValues.state,
                  }}
                  defaultValue={state}
                  onChange={(e) => {
                    setState(e.value), setFieldValue('state', e.value);
                  }}
                  onBlur={onBlur}
                  borderRadius={'5px'}
                  border={setErrorState(formikValues, touched).err}
                  isDisabled={
                    formikValues.streetCep?.length > 7 &&
                    Object.keys(cepToAddress).length > 0
                  }
                />
                {touched.state ? (
                  <ErrorInput>
                    {setErrorState(formikValues, touched).message}
                  </ErrorInput>
                ) : (
                  (formikValues.state == undefined ||
                    formikValues.state == '') &&
                  (formikValues.country == 'Brasil' ||
                    formikValues.country == 'United States of America') && (
                    <ErrorInput>
                      {setErrorState(formikValues, touched).message}
                    </ErrorInput>
                  )
                )}
              </Margin>
              <Margin>
                <LabelInput>Cidade</LabelInput>
                <SelectStyled
                  classNamePrefix='Select'
                  name='city'
                  inputId={'city'}
                  type='select'
                  id='city'
                  defaultValue={city}
                  value={{ value: formikValues.city, label: formikValues.city }}
                  onChange={(e) => {
                    setCity(e.value), setFieldValue('city', e.value);
                  }}
                  options={cities ? cities : []}
                  placeholder='Selecione'
                  onBlur={onBlur}
                  borderRadius={'5px'}
                  border={setErrorCity(formikValues, touched).err}
                  isDisabled={
                    formikValues.streetCep?.length > 7 &&
                    Object.keys(cepToAddress).length > 0
                  }
                />
                {touched.city ? (
                  <ErrorInput>
                    {setErrorCity(formikValues, touched).message}
                  </ErrorInput>
                ) : (
                  (formikValues.city == undefined || formikValues.city == '') &&
                  formikValues.country == 'Brasil' && (
                    <ErrorInput>
                      {setErrorCity(formikValues, touched).message}
                    </ErrorInput>
                  )
                )}
              </Margin>
            </Space>
          </ContainerLabel2>
        </Container2>
      )}
    </Container>
  );
}
