import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { atividadesActions, cancelamentoUserActions } from '../actions';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import { history } from '../helpers';
import { Divider } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { alertActions } from '../actions';
import Alert from '../components/Alert';
import { statusType, statusTypeTranslation } from '../commons';
import SelectCustom from '../components/Forms/SelectCustom';
import { withStyles } from '@material-ui/core/styles';
import GetApp from '@material-ui/icons/GetApp';
import moment from 'moment';
import ModalConvenio from '../components/ModalConvenio';
import Loader from 'react-loader-spinner';
import { validations } from '../helpers/validations';
import * as paparse from 'papaparse';
import { json2csv } from 'json-2-csv';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStylesFilterComponent = (theme) => ({
  textField: {
    width: '15%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
    padding: '10px 5px 0',
  },
});

class FilaCancelamento extends React.Component {
  constructor(props) {
    //props.dispatch(cancelamentoActions.getAllCancelamentos())
    super(props);
    this.state = {
      pageList: 1,
      itemsByPage: 5,
      cancelamentosData: [],
      modalDeleteAcionamento: false,
      dataDeleteAcionamento: {},
      inputValueReason: '',
      showLoader: false,
      showModalDownloadCsv: false,
      dataInicioCsv: '',
      dataFimCsv: '',
      filter: {
        name: '',
        document: '',
        'statusCancel.description': '',
        'statusCancel.updatedTimeInit': '',
        'statusCancel.updatedTimeEnd': '',
        'statusCancel.futureDate': '',
      },
    };
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleSearchFilter = this.handleSearchFilter.bind(this);
    this.handleChangeFilterCPF = this.handleChangeFilterCPF.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.changeValueCsvInicio = this.changeValueCsvInicio.bind(this);
    this.changeValueCsvFim = this.changeValueCsvFim.bind(this);
    this.downloadCsvModal = this.downloadCsvModal.bind(this);
    this.closeCsvModal = this.closeCsvModal.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
    this.handlePerfil = this.handlePerfil.bind(this);
  }

  handleEditManualCancel() {
    history.push('/cancelamento-manual');
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', marginTop: '1px' }}
        id='formatted-text-mask-input'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  TextMaskDownloadCustomNextContact(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', marginTop: '1px' }}
        id='formatted-text-mask-input'
        placeholder='Data de próximo contato'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  componentWillMount() {
    //this.props.dispatch(cancelamentoActions.getAllCancelamentos())
    this.props.dispatch(
      cancelamentoUserActions.paginateCancelamentosWithReducer()
    );
  }

  handleEditCancelamento = (cancelamento) => {
    this.props.dispatch(
      cancelamentoUserActions.getCancelamentosByUSer(cancelamento)
    );
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleChangeFilter(event) {
    const { name, value } = event.target;
    const { filter } = this.state;
    this.setState({
      state: this.state,
      filter: {
        ...filter,
        [name]: value,
      },
    });
  }

  handleChangeFilterCPF(event) {
    let { name, value } = event.target;
    const { filter } = this.state;
    value = value.replace(/\./g, '').replace(/-/g, '');
    this.setState({
      filter: {
        ...filter,
        [name]: value,
      },
    });
  }

  handleChangeStatus(e) {
    const { user } = this.state;
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        'statusCancel.description': e.target.value,
      },
    });
  }

  handleChangeFilterSelect(filter) {
    let { limit, page } = this.state;
    this.props.dispatch(
      cancelamentoUserActions.paginateCancelamentosWithReducer(
        limit,
        page,
        filter
      )
    );
  }

  handleSearchFilter() {
    let { filter, limit, page } = this.state;
    let status = filter['statusCancel.description'];
    filter['statusCancel.description'] = statusTypeTranslation[status];
    this.props.dispatch(
      cancelamentoUserActions.paginateCancelamentosWithReducer(
        limit,
        page,
        filter
      )
    );
    const filterToState = this.state.filter;
    this.setState({
      filter: {
        ...filterToState,
        'statusCancel.description': status,
      },
    });
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
    this.props.dispatch(
      cancelamentoUserActions.paginateCancelamentosWithReducer()
    );
  }

  async cleanFields() {
    await this.setState({
      filter: {
        document: '',
        name: '',
        'statusCancel.updatedTimeInit': '',
        'statusCancel.description': '',
        'statusCancel.updatedTimeEnd': '',
        'statusCancel.futureDate': '',
      },
    });
    this.handleSearchFilter();
  }

  handleOpenCsv = () => {
    let { showModalDownloadCsv } = this.state;
    if (showModalDownloadCsv) {
      return this.setState({ showModalDownloadCsv: false });
    }
    return this.setState({ showModalDownloadCsv: true });
  };

  async submitCsvModal() {
    let { dataInicioCsv, dataFimCsv } = this.state;
    let periodoInvalido = validations.validationCsvRangeDate(
      dataInicioCsv,
      dataFimCsv
    );

    if (periodoInvalido) {
      this.setState({ periodoCsvValido: true });
    } else {
      this.setState({ errorCsv: false, showLoader: true });
      let items = [];
      let hasError = false;
      let firstPage = await this.resolvePageDownload(
        dataInicioCsv,
        dataFimCsv,
        1,
        1
      ).catch((err) => {
        this.setState({ loaderPercent: 0, showLoader: false, errorCsv: true });
      });
      let { page = 0, pages = 0, limit = 0, docs = [] } = firstPage;
      for (let doc of docs) {
        items.push(doc);
      }
      let arrayPromises = [];
      do {
        page++;
        arrayPromises.push(
          this.resolvePageDownloadWithoutExecutePromise(
            dataInicioCsv,
            dataFimCsv,
            page,
            pages
          )
        );
      } while (page <= pages);
      await Promise.all(arrayPromises)
        .then((values) => {
          for (let value of values) {
            let { docs } = value;
            for (let doc of docs) {
              items.push(doc);
            }
          }
        })
        .catch((err) => {
          hasError = true;
          this.setState({
            periodoCsvValido: false,
            loaderPercent: 0,
            showLoader: false,
            dataInicioCsv: '',
            dataFimCsv: '',
            errorCsv: true,
          });
        });

      if (!hasError) {
        this.convertCsv(items);
        this.setState({
          periodoCsvValido: false,
          loaderPercent: 0,
          showLoader: false,
          dataInicioCsv: '',
          dataFimCsv: '',
          errorCsv: false,
        });
      }
    }
  }

  convertCsv(array) {
    let perseCSV = paparse;
    let response = perseCSV.unparse({
      fields: [
        'name',
        'lastName',
        'document',
        'phone',
        'email',
        'reason',
        'PurchaseId',
        'customerId',
        'birthDate',
        'documentType',
        'productDescription',
        'purchaseDate',
        'createdAt',
        'updatedAt',
        'statusLogDescription',
        'statusLogObservation',
        'statusLogStatus',
        'statusLogUpdatedTime',
        'statusCancelName',
        'statusCancelDescription',
        'statusCancelobservation',
        'statusCancelupdatedTime',
        'statusCancelReason',
        'statusCancelFutureDate',
      ],
      data: array,
    });

    response = response.replace(/undefined/g, '');
    response = response.replace(/null/g, '');
    var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'historicocsv.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  resolvePageDownload(dataInicioCsv, dataFimCsv, page) {
    return new Promise((resolve, reject) => {
      cancelamentoUserActions
        .downloadJson(dataInicioCsv, dataFimCsv, page)
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resolvePageDownloadWithoutExecutePromise(dataInicioCsv, dataFimCsv, page) {
    return cancelamentoUserActions.downloadJson(
      dataInicioCsv,
      dataFimCsv,
      page
    );
  }

  changeValueCsvInicio(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataInicioCsvValida: validations.validationCsvDate(value),
    });
  }

  changeValueCsvFim(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataFimCsvValida: validations.validationCsvDate(value),
    });
  }

  downloadCsvModal() {
    this.setState({ modalCsvIsOpen: true });
  }

  closeCsvModal() {
    this.setState({ modalCsvIsOpen: false });
  }

  handlePerfil() {
    let check = false;
    const perfil = this.props.userLogin.user.id_perfil.descricao;
    if (perfil === 'Gestor' || perfil === 'Suporte') {
      check = true;
    }
    return check;
  }

  render() {
    const { cancelamentos } = this.props;
    let { pageList, itemsByPage, filter, showModalDownloadCsv, showLoader } =
      this.state;
    let { docs, page, total, limit } = cancelamentos;
    const { message, type, open } = this.props;
    let { classes } = this.props;

    const columns = [
      { title: 'Nome Completo', field: 'fullName' },
      { title: 'Documento', field: 'document' },
      { title: 'Status', field: 'statusCancel.description' },
      { title: 'Data solicitação', field: 'createdAt' },
      { title: 'Próximo Contato', field: 'statusCancel.futureDate' },
    ];

    const data = cancelamentos.docs;
    let col = data;

    return (
      <React.Fragment>
        <ModalConvenio
          open={showModalDownloadCsv}
          close={() => this.handleOpenCsv()}
          component={
            <React.Fragment>
              {showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Loader type='Oval' color='#ccc' height={60} width={60} />
                </div>
              )}
              {!showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    label='De'
                    margin='normal'
                    name='dataInicioCsv'
                    value={this.state.dataInicioCsv}
                    onChange={this.changeValueCsvInicio}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                  <TextField
                    label='Até'
                    margin='normal'
                    name='dataFimCsv'
                    value={this.state.dataFimCsv}
                    onChange={this.changeValueCsvFim}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                </div>
              )}
              {this.state.periodoCsvValido && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  O periodo entre as datas não pode ultrapassar 3 meses
                </div>
              )}
              {this.state.errorCsv && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  Erro ao baixar csv, tente novamente mais tarde
                </div>
              )}
            </React.Fragment>
          }
          message='Selecione o periodo de extração'
          onSubmit={async () => {
            await this.submitCsvModal();
          }}
        />
        <div>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
          <div
            style={{
              padding: '10px',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
            }}
          >
            <TextField
              className={classes.textField}
              label='Nome'
              name='name'
              value={this.state.filter.name}
              onChange={this.handleChangeFilter}
            />
            <TextField
              className={classes.textField}
              value={filter.document}
              label='Documento'
              name='document'
              value={this.state.filter.document}
              onChange={this.handleChangeFilterCPF}
            />
            <SelectCustom
              className={classes.textField}
              showLabel={false}
              width='11%'
              name='statusCancel.description'
              label='Status'
              onChange={(e) => this.handleChangeStatus(e)}
              value={this.state.filter['statusCancel.description']}
              dataSource={statusType}
              placeholder='Status'
            />
            <TextField
              className={classes.textField}
              label='Data início'
              name='statusCancel.updatedTimeInit'
              onChange={this.handleChangeFilter}
              value={this.state.filter['statusCancel.updatedTimeInit']}
              placeholder='Data início'
              InputProps={{
                inputComponent: this.TextMaskDownloadCustom,
              }}
            />
            <TextField
              className={classes.textField}
              label='Data fim'
              name='statusCancel.updatedTimeEnd'
              onChange={this.handleChangeFilter}
              placeholder='Data fim'
              value={this.state.filter['statusCancel.updatedTimeEnd']}
              InputProps={{
                inputComponent: this.TextMaskDownloadCustom,
              }}
            />
            <TextField
              className={classes.textField}
              label='Próximo contato'
              name='statusCancel.futureDate'
              onChange={this.handleChangeFilter}
              value={this.state.filter['statusCancel.futureDate']}
              InputProps={{
                inputComponent: this.TextMaskDownloadCustomNextContact,
              }}
            />

            <a
              href='#'
              onClick={this.handleSearchFilter}
              className={classes.searchClick}
            >
              <Search onClick={this.handleSearchFilter} />
            </a>

            {/*this.handlePerfil() && 
            <a onClick={()=>this.handleEditManualCancel()} className={classes.searchClick} >
              <AddBox cursor = 'pointer' color = "action" onClick={()=>this.handleEditManualCancel()}/>
            </a>
            */}

            <a
              href='#'
              onClick={() => {
                this.cleanFields();
              }}
              className={classes.searchClick}
            >
              <CloseIcon
                onClick={() => {
                  this.cleanFields();
                }}
              />
            </a>

            <a
              href='#'
              onClick={() => {
                this.handleOpenCsv();
              }}
              className={classes.searchClick}
            >
              <GetApp
                onClick={() => {
                  this.cleanFields();
                }}
              />
            </a>
          </div>
        </div>
        <MaterialTable
          icons={tableIcons}
          options={{ pageSize: itemsByPage }}
          title=''
          columns={columns}
          data={col}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não existem cancelamentos.',
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => ({
              icon: Edit,
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                this.handleEditCancelamento(rowData);
              },
            }),
          ]}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPage={limit}
                count={total}
                page={page - 1}
                onChangePage={(e, page) => {
                  page = page + 1;
                  let { filter } = this.state;
                  let status = filter['statusCancel.description'];
                  filter['statusCancel.description'] =
                    statusTypeTranslation[status];
                  this.props.dispatch(
                    cancelamentoUserActions.paginateCancelamentosWithReducer(
                      limit,
                      page,
                      filter
                    )
                  );
                  const filterToState = this.state.filter;
                  this.setState({
                    filter: {
                      ...filterToState,
                      'statusCancel.description': status,
                    },
                  });
                }}
                onChangeRowsPerPage={(event) => {
                  this.props.dispatch(
                    cancelamentoUserActions.paginateCancelamentosWithReducer(
                      event.target.value,
                      page
                    )
                  );
                  this.setState({ itemsByPage: event.target.value });
                }}
              />
            ),
            Toolbar: (props) => <div></div>,
          }}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { cancelamentos } = state.cancelamentoList;
  const { message, type, open } = state.alert;
  const userLogin = state.authentication.user;
  return {
    message,
    type,
    open,
    cancelamentos,
    userLogin,
  };
}

FilaCancelamento.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(FilaCancelamento)
);
export { connectedLoginPage as FilaCancelamento };
