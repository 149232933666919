import React from 'react';
import {
  HttpBodyResendCockpit,
  HttpQueueRecordResendCockpit,
} from '../../../domain/http';
import { HttpRequest } from '../../../infra/adapters';

export class ResendCockpitData {
  constructor(
    private readonly httpQueueRecordResendCockpit: HttpQueueRecordResendCockpit
  ) {}

  async handle(body: HttpBodyResendCockpit) {
    const user = JSON.parse(localStorage.getItem('user') || '');

    const httpRequest: HttpRequest = {
      url: `${process.env.REACT_APP_API_CONECTA}/servico/historico/send`,
      method: 'post',
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.accessToken,
        _id: user.user._id,
      },
      data: body,
    };

    const response = await this.httpQueueRecordResendCockpit.handle(
      httpRequest
    );
    return response;
  }
}
