import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import Refresh from '@material-ui/icons/Refresh';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { passagemAcionamentoActions } from '../actions';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MTableFilterRow from 'material-table';
import LastPageIcon from '@material-ui/icons/LastPage';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ActionPassage from './ActionPassage';
import { history } from '../helpers';

const useStylesFilterComponent = (theme) => ({
  root: {
    padding: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  textField: {
    width: '9%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  dateField: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-30px',
    fontWeight: 500,
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
    marignTop: '20%',
  },
});

const useStylesPaginationComponent = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class PaAcionamentos extends React.Component {
  constructor(props) {
    props.dispatch(passagemAcionamentoActions.getAllPaAcionamentos());
    super(props);
    this.state = {
      historicosData: [],
      rowsPerPage: [5, 10, 15],
      rows: [],
      numberOfRows: 5,
      page: 1,
      total: undefined,
      itemByPage: 1,
      form: {
        email: '',
        country: '',
        state: '',
        city: '',
        name: '',
        lastName: '',
        createPassageStart: '',
        createPassageEnd: '',
      },
      filterData: false,
      sendfilter: [],
    };
    this.itemByPage = 5;
    this.docs = [];
    this.page = 1;
    this.total = 0;
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(passagemAcionamentoActions.getAllPaAcionamentos());
  }

  filterDate(date) {
    if (moment(date, 'DD/MM/YYYY HH:mm').isValid()) {
      return moment(date, 'DD/MM/YYYY HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    } else {
      return moment(date, 'YYYY-MM-DD HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    }
  }

  formataCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, '');

    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  TablePaginationActions(props) {
    const classes = useStylesPaginationComponent();
    let { count, page, rowsPerPage, onChangePage } = props;
    return (
      <div className={classes.root}>
        <IconButton
          onClick={(event) => {
            onChangePage(event, 1);
          }}
          disabled={false}
          aria-label='first page'
        >
          <FirstPageIcon />
        </IconButton>

        <IconButton
          onClick={(event) => {
            if (page > 1) {
              onChangePage(event, page - 1);
            }
          }}
          aria-label='previous page'
        >
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          onClick={(event) => {
            if (page < count) {
              onChangePage(event, page + 1);
            }
          }}
          disabled={false}
          aria-label='next page'
        >
          <KeyboardArrowRight />
        </IconButton>

        <IconButton
          onClick={(event) => {
            onChangePage(event, count);
          }}
          disabled={false}
          aria-label='last page'
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }

  handleEditPaAcionamento = (historico) => {
    this.props.dispatch(
      passagemAcionamentoActions.editPaAcionamento(historico)
    );
  };

  updateRows(page, qtd) {
    this.setState({ filterData: false });
    this.props.dispatch(
      passagemAcionamentoActions.getAllPaAcionamentos(page, qtd)
    );
  }

  filterRows(page, qtd) {
    this.setState({ filterData: false });
    this.props.dispatch(
      passagemAcionamentoActions.filterAllPaFisico(this.state.form, page, qtd)
    );
  }

  changeValue(event) {
    let { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  styleComp() {
    let style = useStylesPaginationComponent();
    return style;
  }

  onEditClick() {
    history.push('/edit/teletriagem');
  }

  componentWillUnmount() {
    this.props.dispatch(
      passagemAcionamentoActions.editPAcionamentosWithoutRedirect()
    );
    this.props.dispatch(
      passagemAcionamentoActions.findAcionamentoByPassageOnUnmount()
    );
  }

  render() {
    let { historicos, paacionamentolist } = this.props;
    let { classes } = this.props;
    const { historicosData } = this.state;
    const { docs } = paacionamentolist;
    let { pages } = paacionamentolist;
    let { page } = paacionamentolist;
    let { total } = paacionamentolist;
    const columns = [
      { title: 'Nome', field: 'name' },
      { title: 'Sobrenome', field: 'lastName' },
      { title: 'Email', field: 'email' },
      { title: 'Data Criação', field: 'createdAt' },
      { title: 'Pais', field: 'country' },
      { title: 'Estado', field: 'state' },
      { title: 'Cidade', field: 'city' },
    ];
    this.docs = docs;
    this.page = page;
    this.total = total;
    const data = docs ? (docs.length >= 1 ? docs : []) : historicosData;
    let col = data.map((ret) => {
      let { createdAt } = ret;
      let { documentType } = ret;
      let { createDate } = ret;
      if (createdAt) {
        ret.createdAt = this.filterDate(ret.createdAt);
      }
      if (createDate) {
        ret.createDate = this.filterDate(ret.createDate);
      }
      if (documentType == 'CPF') {
        ret.document = this.formataCPF(ret.document);
      }
      ret.createPassage = ret.createPassage;
      return { ...ret };
    });

    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify='center' spacing={1}>
                <Grid item>
                  <TextField
                    id='standard-select-currency'
                    label='Nome'
                    name='name'
                    value={this.state.form.name}
                    onChange={this.changeValue}
                  ></TextField>
                </Grid>
                <Grid item>
                  <TextField
                    id='standard-select-currency'
                    label='Sobrenome'
                    name='lastName'
                    value={this.state.form.lastName}
                    onChange={this.changeValue}
                  ></TextField>
                </Grid>
                <Grid item>
                  <TextField
                    id='standard-select-currency'
                    label='Email'
                    name='email'
                    value={this.state.form.email}
                    onChange={this.changeValue}
                  ></TextField>
                </Grid>
                <Grid item>
                  <div>
                    <InputLabel htmlFor='formatted-text-mask-input'>
                      Data inicio criação
                    </InputLabel>
                    <Input
                      id='standard-select-currency'
                      label='Data e hora passagem'
                      name='createPassageStart'
                      value={this.state.form.createPassageStart}
                      onChange={this.changeValue}
                      inputComponent={this.TextMaskCustom}
                      margin='normal'
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <InputLabel htmlFor='formatted-text-mask-input'>
                      Data fim criação
                    </InputLabel>
                    <Input
                      id='standard-select-currency'
                      label='Data e hora passagem'
                      name='createPassageEnd'
                      value={this.state.form.createPassageEnd}
                      onChange={this.changeValue}
                      inputComponent={this.TextMaskCustom}
                      margin='normal'
                    />
                  </div>
                </Grid>
                <Grid item>
                  <TextField
                    id='standard-select-currency'
                    label='Pais'
                    name='country'
                    value={this.state.form.country}
                    onChange={this.changeValue}
                  ></TextField>
                </Grid>
                <Grid item>
                  <TextField
                    id='standard-select-currency'
                    label='Estado'
                    name='state'
                    value={this.state.form.state}
                    onChange={this.changeValue}
                  ></TextField>
                </Grid>
                <Grid item>
                  <TextField
                    id='standard-select-currency'
                    label='Cidade'
                    name='city'
                    value={this.state.form.city}
                    onChange={this.changeValue}
                  ></TextField>
                </Grid>
                <Grid item>
                  <a
                    href='#'
                    onClick={this.onEditClick}
                    className={classes.searchClick}
                  >
                    <AddBox />
                  </a>
                </Grid>
                <Grid item>
                  <a href='#' className={classes.searchClick}>
                    <Search
                      onClick={() => {
                        this.filterRows();
                      }}
                      className={classes.searchClick}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href='#'
                    onClick={() => {
                      this.filterRows();
                    }}
                    className={classes.searchClick}
                  >
                    Pesquisar
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='center' spacing={1}></Grid>
            </Grid>
          </Grid>
        </div>
        <MaterialTable
          icons={tableIcons}
          title='Histórico'
          columns={columns}
          options={{
            pageSize: this.itemByPage,
            filtering: false,
            toolbar: true,
          }}
          data={col}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Ótimo, não há paciente esperando.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse historico?',
              },
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
          }}
          components={{
            Pagination: (props) => (
              <TablePagination
                labelRowsPerPage='Linhas'
                rowsPerPageOptions={this.state.rowsPerPage}
                count={pages}
                page={this.page}
                onChangePage={(e, page) =>
                  this.filterRows(page++, this.itemByPage)
                }
                onChangeRowsPerPage={(event) => {
                  this.itemByPage = parseInt(event.target.value);
                  this.filterRows(1, parseInt(event.target.value));
                }}
                labelDisplayedRows={() => {
                  return `pagina: ${this.page} de ${pages}`;
                }}
                ActionsComponent={(props) => {
                  return this.TablePaginationActions(props);
                }}
              />
            ),
            Toolbar: (props) => <div></div>,
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { historicos } = state.historico;
  const { paacionamentolist } = state.paacionamentolist;
  return {
    historicos,
    paacionamentolist,
  };
}

PaAcionamentos.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(PaAcionamentos)
);
export { connectedLoginPage as PaAcionamentos };
