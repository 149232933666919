import styled from 'styled-components';

export const ContainerPage = styled.div`
  background-color: #f1f4f6;
  width: 100%;
`;

export const GridLayout = styled.div`
  /* height: 10vh;
  display: grid;
  grid-template-areas:
    'header header header'
    'asideLeft main asideRight';
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
  @media screen and (min-device-width: 800px) and (max-device-width: 1900px) {
    transform: scale(0.8);
  } */
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1440px;
`;

export const Header = styled.div`
  /* grid-area: header;
  width: 100%;
  height: 200px;
  padding: 10px 10px;
  background-color: #f1f4f6; */
  background-color: #f1f4f6;
  display: flex;
  align-items: center;
  padding: 20px 0;
  width: 100%;
`;

export const AsideLeft = styled.aside`
  grid-area: asideLeft;
  background: #f1f4f6;
  height: 100vh;
`;

export const AsideRight = styled.aside`
  grid-area: asideRight;
  background: #f1f4f6;
  padding: 10px 10px;
  height: 100vh;
`;

export const Main = styled.main`
  /* grid-area: main;
  background: #f1f4f6; */
`;

export const ContainerSelect = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerQueue = styled.div`
  padding-left: 30px;
  width: 100%;

  /* @media (max-width: 1366px) {
    width: 1150px;
  } */

  /* @media (min-width: 1367px) {
    width: 1352px;
  } */

  /* overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #a8a6a8;
    border: 1.3px solid #e6e6e6;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #878587;
  }
  ::-webkit-scrollbar-track {
    background: #f1f4f6;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  } */
`;

export const IconButton = styled.img`
  width: 12px;
  margin-right: 5px;
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  color: #a7abb2;
  height: 35px;
`;

export const ContainerButton = styled.div`
  display: flex;
`;

export const ContainerSearch = styled.div`
  /* @media (min-width: 1369px) {
    margin-left: 130px;
  }

  @media (max-width: 1369px) {
    margin-left: -50px;
  } */
`;

export const SelectTitle = styled.span`
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const ValueCheck = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 8px;
`;

export const ContainerSelectModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextAreaModal = styled.textarea`
  resize: none;
  width: 100%;
  height: 17vh;
`;

export const ContainerButtonOfUnits = styled.div`
  width: 180px;
  margin-left: 50px;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const ContainerFilterUnits = styled.div`
  position: absolute;
  right: 765px;
  top: -115px;
`;
export const ContainerSpanInfos = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
`;
export const ContainerFilterProfessionals = styled.div`
  position: absolute;
  right: 785px;
  top: -115px;
`;
export const ContainerButtonOfProfessionals = styled.div`
  width: 220px;
  margin-left: 50px;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: center;
`;
export const ContainerUnitsAndProfessionalsFilters = styled.div`
  display: flex;
`;
