import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { alertActions, passwordActions } from '../../actions';
import { passwordService } from '../../services';

import { useRecaptcha, ReCaptchaActions } from '../../reCaptcha';

import senha from '../../icons/icon_senha.svg';

import {
  Grid,
  IconButton,
  Input,
  Fab,
  InputAdornment,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { useFormik } from 'formik';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import ModalDialog from '../ModalDialog';

import { validationSchema } from './schema';

const formikInitialValues = {
  oldPassword: '',
  newPassword: '',
  confirmedNewPassword: '',
};

export function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [passwordState, setPasswordState] = useState({
    oldPassword: {
      isVisible: false,
      placeholder: 'Senha',
    },
    newPassword: {
      isVisible: false,
      placeholder: 'Nova senha',
    },
    confirmedNewPassword: {
      isVisible: false,
      placeholder: 'Repita nova senha',
    },
  });

  const dispatch = useDispatch();

  const { handleRequestAuthorization, reCaptchaToken } = useRecaptcha(
    ReCaptchaActions.CHANGE_PASSWORD_SUBMIT
  );

  const { openPassword } = useSelector((state) => state.password);

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: (formValues) => handleChangePassword(formValues),
  });

  function resetForm() {
    formik.resetForm();

    setPasswordState(() => ({
      oldPassword: {
        isVisible: false,
        placeholder: 'Senha',
      },
      newPassword: {
        isVisible: false,
        placeholder: 'Nova senha',
      },
      confirmedNewPassword: {
        isVisible: false,
        placeholder: 'Repita nova senha',
      },
    }));
  }
  function handleHome() {
    resetForm();
    dispatch(passwordActions.cancelChangePassword());
  }

  const handleChangePassword = async (formValues) => {
    setLoading(true);
    if (formik.isValid) {
      try {
        const user = localStorage.getItem('user')
          ? JSON.parse(localStorage.getItem('user'))
          : {};

        if (!reCaptchaToken) {
          return dispatch(alertActions.error('Erro ao processar seus dados'));
        }

        await passwordService.changePassword(formValues, user, reCaptchaToken);

        setLoading(false);

        dispatch(alertActions.success('Senha alterada com sucesso!'));
        dispatch(passwordActions.cancelChangePassword());
        resetForm();
      } catch (error) {
        dispatch(
          alertActions.error(error.message ?? 'Por favor, tente novamente')
        );

        setLoading(false);
        dispatch(passwordActions.cancelChangePassword());
        resetForm();
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    handleRequestAuthorization();
  };

  function mountInputAdornment(formFildName) {
    return (
      <InputAdornment position='end'>
        <IconButton
          onClick={() =>
            setPasswordState((prevState) => ({
              ...prevState,
              [formFildName]: {
                isVisible: !passwordState[formFildName].isVisible,
                placeholder: passwordState[formFildName].placeholder,
              },
            }))
          }
        >
          {passwordState[formFildName].isVisible ? (
            <Visibility />
          ) : (
            <VisibilityOff />
          )}
        </IconButton>
      </InputAdornment>
    );
  }

  function mountInputPasswordComponent(formFieldName) {
    const inputValue = formik.values[formFieldName];
    const inputPlaceholder = passwordState[formFieldName].placeholder;
    const inputType = !passwordState[formFieldName].isVisible
      ? 'password'
      : 'text';

    const showInputError =
      formik.touched[formFieldName] && formik.errors[formFieldName];

    return (
      <div>
        <Input
          style={{
            paddingTop: 15,
            width: '100%',
          }}
          type={inputType}
          name={formFieldName}
          error={showInputError}
          value={inputValue}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={inputPlaceholder}
          endAdornment={mountInputAdornment(formFieldName)}
          startAdornment={
            <InputAdornment position='start'>
              <img
                src={senha}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </InputAdornment>
          }
        />
        {showInputError && (
          <div
            id='error-feedback'
            style={{
              fontSize: 12,
              color: 'red',
            }}
          >
            {formik.errors[formFieldName]}
          </div>
        )}
      </div>
    );
  }

  return (
    <ModalDialog
      title={'Troca de senha'}
      open={openPassword}
      close={handleHome}
      disableAutoFocus={true}
      componente={
        <div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                paddingTop: 60,
                justifyContent: 'center',
              }}
            >
              <Loader type='Oval' color='green' height={120} width={120} />
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {mountInputPasswordComponent('oldPassword')}

                {mountInputPasswordComponent('newPassword')}

                {mountInputPasswordComponent('confirmedNewPassword')}
              </div>

              <Grid
                container
                justifyContent='center'
                style={{ paddingTop: 40 }}
              >
                <Fab
                  variant='extended'
                  aria-label='Delete'
                  color='primary'
                  type={'submit'}
                  style={{
                    fontSize: '10px',
                    width: '40%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginRight: '5px',
                  }}
                  disabled={loading}
                >
                  Enviar
                </Fab>
                <Fab
                  variant='extended'
                  aria-label='Delete'
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '40%',
                    height: '36px',
                    background: 'white',
                    color: 'blue',
                    boxShadow: 'none',
                    border: '1px solid',
                    marginLeft: '5px',
                  }}
                  onClick={handleHome}
                >
                  Cancelar
                </Fab>
              </Grid>
            </form>
          )}
        </div>
      }
    />
  );
}
