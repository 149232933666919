import { LoginErrorProps } from '../LoginError/component';

export interface LoginAlertProps {
  type: 'success' | 'warning' | 'error' | 'info' | 'default';
  message: string;
}

export const LoginAlerts = {};

export const LoginLabelErrors = {
  REQUIRED_FIELD: {
    title: 'Campo obrigatório.',
    description: 'Por favor, preencha e tente novamente.',
  } as LoginErrorProps,
  INVALID_CREDENTIALS: {
    title: 'Usuário e/ou senha inválidos.',
    description: 'Por favor, verifique e tente novamente.',
  } as LoginErrorProps,
  RECAPTCHA_ERROR: {
    title:
      'Houve um problema ao carregar os dados, por favor recarregue a página.',
  },
};
