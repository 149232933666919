import {
  acionamentoConstants,
  editAcionamentoConstants,
  userConstants,
  countryConstants,
  regionConstants,
  citiesConstants,
} from '../constants';
import {
  ActionPassageConstants,
  ActionPassageListConstants,
  ActionPassageCreateConstants,
  ActionPassageEditConstants,
  ActionPassageUpdateConstants,
} from '../constants';
import { passagemAcionamentoService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const passagemAcionamentoActions = {
  findAcionamentoByPassage,
  createPassagemAcionamento,
  getAllPaAcionamentos,
  redirectPaAcionamento,
  editPaAcionamento,
  updatePassagemAcionamento,
  editPAcionamentosWithoutRedirect,
  filterAllPaFisico,
  findAcionamentoByPassageOnUnmount,
};

function getAllPaAcionamentos(page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request);
    passagemAcionamentoService
      .getAllPaAcionamentos(page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: ActionPassageListConstants.PAACIONAMENTO_LIST_REQUEST };
  }
  function success(result) {
    return {
      type: ActionPassageListConstants.PAACIONAMENTO_LIST_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return {
      type: ActionPassageListConstants.PAACIONAMENTO_LIST_FAILURE,
      error,
    };
  }
}

function filterAllPaFisico(fields, page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request);
    passagemAcionamentoService
      .filterAllPaACionamentos(fields, page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: ActionPassageListConstants.PAACIONAMENTO_LIST_REQUEST };
  }
  function success(result) {
    return {
      type: ActionPassageListConstants.PAACIONAMENTO_LIST_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return {
      type: ActionPassageListConstants.PAACIONAMENTO_LIST_FAILURE,
      error,
    };
  }
}

function findAcionamentoByPassage(prontuario, passagem) {
  return (dispatch) => {
    dispatch(request());
    passagemAcionamentoService
      .findAcionamentoByPassage(prontuario, passagem)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
    function request() {
      return { type: ActionPassageConstants.ACIONAMENTO_PASSAGEM_REQUEST };
    }
    function success(result) {
      return {
        type: ActionPassageConstants.ACIONAMENTO_PASSAGEM_SUCCESS,
        result,
      };
    }
    function failure(error) {
      return {
        type: ActionPassageConstants.ACIONAMENTO_PASSAGEM_FAILURE,
        error,
      };
    }
  };
}

function findAcionamentoByPassageOnUnmount(prontuario) {
  return (dispatch) => {
    dispatch(request(prontuario));
    if (prontuario) {
      dispatch(success(prontuario));
    } else {
      dispatch(failure());
    }
  };
  function request() {
    return { type: ActionPassageConstants.ACIONAMENTO_PASSAGEM_REQUEST };
  }
  function success(result) {
    return {
      type: ActionPassageConstants.ACIONAMENTO_PASSAGEM_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: ActionPassageConstants.ACIONAMENTO_PASSAGEM_FAILURE, error };
  }
}

function redirectPaAcionamento() {
  history.push('edit/teletriagem');
}

function editPAcionamentosWithoutRedirect(editData) {
  return (dispatch) => {
    dispatch(request(editData));
    if (editData) {
      dispatch(success(editData));
    } else {
      dispatch(failure());
    }
  };
  function request() {
    return { type: ActionPassageEditConstants.PAACIONAMENTO_EDIT_REQUEST };
  }
  function success(editData) {
    return {
      type: ActionPassageEditConstants.PAACIONAMENTO_EDIT_SUCCESS,
      editData,
    };
  }
  function failure() {
    return { type: ActionPassageEditConstants.PAACIONAMENTO_EDIT_FAILURE };
  }
}

function createPassagemAcionamento(pacionamento) {
  return (dispatch) => {
    dispatch(request());
    passagemAcionamentoService
      .newPassagemAcionamento(pacionamento)
      .then(
        (body) => {
          dispatch(success(body));
          window.open(body.urlCall.visitUrl, '_blank');
          history.push('/teletriagem');
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
    function request() {
      return {
        type: ActionPassageCreateConstants.PAACIONAMENTO_CREATE_REQUEST,
      };
    }
    function success(result) {
      return {
        type: ActionPassageCreateConstants.PAACIONAMENTO_CREATE_SUCCESS,
        result,
      };
    }
    function failure(error) {
      return {
        type: ActionPassageCreateConstants.PAACIONAMENTO_CREATE_FAILURE,
        error,
      };
    }
  };
}

function updatePassagemAcionamento(pacionamento) {
  return (dispatch) => {
    dispatch(request());
    passagemAcionamentoService
      .editPassagemAcionamento(pacionamento)
      .then(
        (body) => {
          history.push('/teletriagem');
          dispatch(success(body));
          dispatch(alertActions.success('PaFisico atualizado.'));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
    function request() {
      return {
        type: ActionPassageUpdateConstants.PAACIONAMENTO_UPDATE_REQUEST,
      };
    }
    function success(result) {
      return {
        type: ActionPassageUpdateConstants.PAACIONAMENTO_UPDATE_SUCCESS,
        result,
      };
    }
    function failure(error) {
      return {
        type: ActionPassageUpdateConstants.PAACIONAMENTO_UPDATE_FAILURE,
        error,
      };
    }
  };
}

function editPaAcionamento(editData) {
  return (dispatch) => {
    dispatch(request());
    if (editData) {
      dispatch(success(editData));
    } else {
      dispatch(failure());
    }
    history.push('/edit/teletriagem');
  };

  function request() {
    return { type: ActionPassageEditConstants.PAACIONAMENTO_EDIT_REQUEST };
  }
  function success(editData) {
    return {
      type: ActionPassageEditConstants.PAACIONAMENTO_EDIT_SUCCESS,
      editData,
    };
  }
  function failure() {
    return { type: ActionPassageEditConstants.PAACIONAMENTO_EDIT_FAILURE };
  }
}
