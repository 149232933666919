import styled from 'styled-components';
import { Field } from 'formik';

export const Container = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '10px 0 10px 0')};
`;
export const Label = styled.label`
  margin-left: 10px;
`;
export const Input = styled(Field)`
  margin: 0 0 5px 0;
  padding-bottom: 5px;
`;
