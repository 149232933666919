// importação de pacotes
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment, { utc } from 'moment';
import { history } from '../../../../helpers';
import { validations } from '../../../../helpers/validations';

// importação de estilização
import {
  ContainerForm,
  ContainerPage,
  Container,
  FormAdmContainer,
  ContainerInputs,
  ContainerLabelInput,
  LabelInput,
  SelectDiv,
  ErrorInput,
  ButtonsForm,
  ContainerButton,
  Line,
  Header,
} from './styles';

import { Main } from '../../QueuePage/styles';

import GlobalStyle from '../../styles/globalStyles';

//importação de componentes
import { CheckBox } from '../FormCheckBox/index';
import InputMask from './InputMask';
import FormInput from '../FormInput';
import CustomTitle from '../../CustomTitle';
import Loading from '../../../Loading';
import ModalAlert from '../../ModalAlert';
import CustomButton from '../../CustomButton';
import ModalDialog from '../../ModalDialog';
//importação de actions
import {
  acionamentoActionsVonage,
  alertContingenciaActions,
  modalAlertActions,
  alertActions,
} from '../../../../actions';

// importação de service
import { acionamentoService } from '../../../../services/acionamento.service';

//importação de utils do form
import { Nacionality, Sexo, initialValuesFormAdm } from './config';

export default function FormAdm({ typeForm, fatherState }) {
  const dispatch = useDispatch();
  //-----------------------------Declaração de states e hooks---------------------------------------------------------------------------
  const validation = validations;
  const [values, setValues] = useState(initialValuesFormAdm);
  const { message, type, open } = useSelector((state) => state.alert);
  const [loading, setLoading] = useState(false);
  const { supportAcionamento } = useSelector(
    (state) => state.supportAcionamento
  );

  const { socketInfosTime } = useSelector((state) => state.socketInfosTime);
  const newModalAlert = useSelector((state) => state.modalAlert);
  let { messageModalAlert, openModalAlert, typeModalAlert } = newModalAlert;
  //----------------------------- Declaração de Funções ----------------------------------------------
  const ModalDeleteDocs = ModalDialog;

  const setMask = (mask) => {
    if (mask === 'cpf') {
      return '999.999.999-99';
    }
    if (mask === 'phone') {
      return '(99) 999999999';
    }

    if (mask === 'nascimento') {
      return '99/99/9999';
    } else {
      return '************************************************************';
    }
  };
  // const formValues = (supportAcionamento, values) => {
  //   if (editAcionamento.edit) {
  //     editAcionamento.nationality = defineNationality(
  //       editAcionamento.document ? editAcionamento.document : ''
  //     ).value;
  //     return editAcionamento;
  //   } else {
  //     return values;
  //   }
  // };

  const updatedTime = () => {
    let replacedDate = socketInfosTime.replace('-03:00', '');
    //replacedDate = moment(replacedDate).toISOString();
    return replacedDate;
  };

  let getDate = (str) => moment(str, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const valueCheck = (value) => {
    if (value == undefined) return false;
    return value != '';
  };

  // const handleSubmit = (values, actions, type, resetForm) => {
  //   let { name, lastName, socialName, email, email2 } = values;
  //   setSendMailValues({
  //     name: name,
  //     lastName: lastName,
  //     socialName: socialName,
  //     email: email,
  //     emai2: email2,
  //   });

  //   if (values.edit) {
  //     if (checkCountryAndNationality(values)) {
  //       let confirmedTime = updatedTime();
  //       let age = getDate(values.birthDate);
  //       if (onNumberCard(values)) {
  //         dispatch(
  //           alertActions.error(
  //             'Dados inválidos, Verifique o campo da Carteirinha. '
  //           )
  //         );
  //       } else {
  //         if (!isMinor(age) && values.document?.length < 1) {
  //           dispatch(
  //             alertActions.error(
  //               'Dados inválidos, Verifique o campo da data de nascimento. '
  //             )
  //           );
  //         } else {
  //           values.nationality =
  //             values.document && !validation.validationCPF(values.document)
  //               ? 'B'
  //               : '';
  //           dispatch(
  //             acionamentoActions.confirmedAcionamentosNovo(
  //               { ...values, confirmedTime },
  //               setLoading
  //             )
  //           );
  //           dispatch(acionamentoActions.clearAcionamentos());
  //           setLoading(true);
  //           this.handleCloseModalConfirmacao();
  //         }
  //       }
  //     }
  //   } else {
  //     if (checkCountryAndNationality(values)) {
  //       //values.truclinic == true || values.vonage == true
  //       if (true) {
  //         if (isMinor(values.birthDate) === true) {
  //           //values.truclinic
  //           if (true) {
  //             dispatch(userActions.registerNovo({ ...values }, setLoading));
  //             setLoading(true);
  //             resetForm();
  //           } else {
  //             dispatch(userActions.registerVonage({ ...values }, setLoading));
  //             setLoading(true);
  //             resetForm();
  //           }
  //         } else if (
  //           isMinor(values.birthDate) === false &&
  //           values.document.length > 5
  //         ) {
  //           //values.truclinic
  //           if (true) {
  //             dispatch(userActions.registerNovo({ ...values }, setLoading));
  //             setLoading(true);
  //             resetForm();
  //           } else {
  //             dispatch(userActions.registerVonage({ ...values }, setLoading));
  //             setLoading(true);
  //             resetForm();
  //           }
  //         } else {
  //           dispatch(
  //             alertActions.error(
  //               'Dados inválidos, Verifique o campo de documento. '
  //             )
  //           );
  //         }
  //       } else {
  //         dispatch(alertActions.error('Verifique o tipo de plataforma. '));
  //       }
  //     }
  //   }
  // };

  const handleCloseAlertLink = () => {
    dispatch(alertContingenciaActions.clear());
    history.push('/adm');
  };

  const handleCloseOldModal = () => {
    dispatch(alertActions.clear());
    //essas actions são no velho
  };
  const handleCloseNewModal = () => {
    dispatch(modalAlertActions.clear());
    //essas actions são do novo
  };

  async function handleBack(edit = { isVonage: false }, resetForm) {
    switch (edit.isVonage) {
      case true: {
        edit.status = 'output';
        await acionamentoActionsVonage
          // Seta as infos de atendente do acionamento Vonage
          .updateStatusAttendance({ ...edit, clear: true })
          .then(() => {
            dispatch(acionamentoActionsVonage.clearAcionamentos());
            history.push('/adm');
            window.scroll(0, 0);
          })
          .catch((err) => {
            console.log('erro ao editar', err);
          });
        break;
      }
      case false: {
        edit.status = 'output';
        await acionamentoActionsVonage
          // Seta as infos de atendente do acionamento normal
          .updateStatusAttendance2({ ...edit, clear: true })
          .then(() => {
            dispatch(acionamentoActionsVonage.clearAcionamentos());
            history.push('/adm');
          })
          .catch((err) => {
            console.log('erro ao editar', err);
          });
        break;
      }
      default: {
        dispatch(acionamentoActionsVonage.clearAcionamentos());
        history.push('/adm');
        break;
      }
    }
  }
  const handleFormatDate = (date) => {
    var convert = new Date(date);
    return moment(convert).isValid()
      ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
  };

  const maskDocument = (value) => {
    if (value.length > 2) {
      let res = value && !validations.validationCPF(value) ? 'cpf' : '';
      return res;
    }
    return '';
  };

  //----------------------------- Uso de hooks ----------------------------------------------
  // useEffect(() => {
  //   dispatch(acionamentoActions.getAllConvenios());
  //   // editAcionamento?.signUpDocuments?.map((el) => {
  //   //   handleGetUrlDocs(el).then(
  //   //     (result) => (el.urls = new Image().src = result)
  //   //   );
  //   // });

  //   return async () => {
  //     let action = fatherState.props.history.action;
  //     let pathname = location.pathname;
  //     if (action == 'POP' && pathname == '/adm/acionamento/new') {
  //       editAcionamento.status = 'output';
  //       if (editAcionamento?.isVonage) {
  //         await acionamentoActionsVonage.updateStatusAttendance({
  //           ...editAcionamento,
  //           clear: true,
  //         });
  //       } else {
  //         await acionamentoActionsVonage.updateStatusAttendance2({
  //           ...editAcionamento,
  //           clear: true,
  //         });
  //       }
  //     }
  //   };
  // }, []);

  const defineNationality = (cpf) => {
    if (cpf?.length > 2) {
      return !validation.validationCPF(cpf)
        ? 'Brasileiro(a)'
        : 'Enstrangeiro(a)';
    }
  };
  const defineGender = (gender) => {
    switch (gender) {
      case 'F': {
        return 'Feminino';
      }
      case 'M': {
        return 'Masculino';
      }
    }
  };
  const initial = () => {
    return Object.keys(supportAcionamento).length > 1
      ? supportAcionamento
      : values;
  };

  return (
    <>
      {loading && <Loading />}
      <ModalAlert
        open={open}
        type={type}
        message={message}
        close={() => {
          handleCloseOldModal();
        }}
      />

      {openModalAlert && (
        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          close={() => {
            handleCloseNewModal();
          }}
        />
      )}
      <GlobalStyle />
      {/* <GridLayout> */}
      <Container>
        <ContainerPage>
          <ContainerForm>
            <Header>
              <CustomTitle
                width={'99%'}
                height={'100px'}
                className={'rubik'}
                title={'Acionamento Suporte'}
                pos={'flex-start'}
                level={1}
              ></CustomTitle>
            </Header>
            <Main>
              <Formik
                initialValues={initial()}
                onSubmit={(values, actions, typeForm, resetForm) => {
                  alert('Submit');
                  console.log(values);
                  //handleSubmit(values, actions, typeForm, resetForm);
                }}
              >
                {({ values, handleChange }) => (
                  <Form
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                  >
                    <FormAdmContainer>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <LabelInput>Convênio</LabelInput>
                          <>
                            <SelectDiv>
                              <FormInput
                                height={'large'}
                                placeholder={'Convênio'}
                                id='convenio'
                                value={values.convenioDescricao}
                                name='convenio'
                                disabled={true}
                              />
                            </SelectDiv>
                          </>
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'Carteirinha'}
                            placeholder={'Carteirinha'}
                            disabled={true}
                            mask
                            type={'text'}
                            name={'numberCard'}
                            id={'numberCard'}
                            value={values.numberCard}
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'Nome'}
                            placeholder={'Nome'}
                            type={'text'}
                            id={'name'}
                            name={'name'}
                            value={values.name}
                            disabled={true}
                            mask=''
                          />
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            disabled={true}
                            type={'text'}
                            id={'lastName'}
                            name={'lastName'}
                            value={values.lastName}
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <ContainerInputs>
                        {values.socialName && (
                          <ContainerLabelInput
                            width={'98%'}
                            marginBottom={'-20px'}
                            marginTop={'-10px'}
                          >
                            <FormInput
                              disabled
                              labelTitle={'Nome Social'}
                              name={'socialName'}
                              id={'socialName'}
                              type={'text'}
                              value={values.socialName}
                            />
                          </ContainerLabelInput>
                        )}
                      </ContainerInputs>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <LabelInput>Nacionalidade</LabelInput>

                          <>
                            <ContainerLabelInput marginTop='-20px'>
                              <FormInput
                                placeholder={'Nacionalidade'}
                                mask
                                disabled
                                type={'text'}
                                name={'nacionality'}
                                id={'nacionality'}
                                value={defineNationality(values.document)}
                              />
                            </ContainerLabelInput>
                          </>
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <LabelInput>Documento</LabelInput>
                          <InputMask
                            placeholder={'Documento'}
                            name={'document'}
                            id={'document'}
                            type={'text'}
                            value={values.document}
                            disabled={true}
                            mask={setMask(maskDocument(values.document))}
                            maskChar=''
                            fullWidth
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <LabelInput>Sexo</LabelInput>
                          <SelectDiv>
                            <FormInput
                              placeholder={'Sexo'}
                              mask
                              disabled
                              type={'text'}
                              name={'gender'}
                              id={'gender'}
                              value={defineGender(values.gender)}
                            />
                          </SelectDiv>
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <LabelInput>Data de nascimento</LabelInput>
                          <InputMask
                            label={'Data de nascimento'}
                            placeholder={'DD/MM/AAAA'}
                            disabled={true}
                            type={'text'}
                            name={'birthDate'}
                            id={'birthDate'}
                            value={handleFormatDate(values.birthDate)}
                            mask={setMask('nascimento')}
                            maskChar=''
                            fullWidth
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>

                      <ContainerInputs>
                        <ContainerLabelInput marginTop='20px'>
                          <LabelInput>Telefone</LabelInput>
                          <InputMask
                            labelTitle={'Telefone'}
                            placeholder='(DDD) + Telefone'
                            type={'text'}
                            name={'phone'}
                            id={'phone'}
                            disabled={true}
                            value={values.phone}
                            mask={setMask('phone')}
                            maskChar=' '
                            fullWidth
                          />
                        </ContainerLabelInput>
                        <ContainerLabelInput marginTop='20px'>
                          <LabelInput>Telefone</LabelInput>
                          <InputMask
                            labelTitle={'Telefone'}
                            placeholder={'(DDD) + Telefone'}
                            type={'text'}
                            name={'phone2'}
                            disabled={true}
                            id={'phone2'}
                            value={values.phone2}
                            mask={setMask('phone')}
                            maskChar=' '
                            fullWidth
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'E-mail'}
                            placeholder={'E-mail'}
                            type={'email'}
                            name={'email'}
                            id={'email'}
                            disabled
                            value={values.email}
                          />
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'E-mail secundário'}
                            placeholder={'E-mail'}
                            type={'email2'}
                            id={'email2'}
                            value={values.email2}
                            disabled
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'Canal de acesso'}
                            placeholder={'Canal de acesso'}
                            type={'deviceData'}
                            id={'deciceData'}
                            value={
                              values.deviceData
                                ? values.deviceData.deviceType
                                : 'Não recebido'
                            }
                            disabled
                          />
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <FormInput
                            labelTitle={'Identificador Parceiro'}
                            placeholder={'Identificador parceiro'}
                            type={'partnerID'}
                            id={'partnerID'}
                            value={values.partnerID}
                            disabled
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <LabelInput>Status do suporte *</LabelInput>
                      <ContainerInputs>
                        <ContainerLabelInput>
                          <CheckBox
                            disabled={values.notcompleted}
                            textLabel={'Concluído'}
                            type={'checkbox'}
                            name={'supportCompleted'}
                          />
                        </ContainerLabelInput>
                        <ContainerLabelInput>
                          <CheckBox
                            disabled={values.completed}
                            textLabel={'Não concluído'}
                            type={'checkbox'}
                            name={'supportNotCompleted'}
                          />
                        </ContainerLabelInput>
                      </ContainerInputs>
                      <ContainerLabelInput
                        width={'98%'}
                        marginBottom={'-20px'}
                        marginTop={'-10px'}
                      >
                        <FormInput
                          labelTitle={'Observações gerais'}
                          name={'generalObservations'}
                          id={'generalObservations'}
                          type={'text'}
                          handleChange={handleChange}
                          value={values.generalObservations}
                        />
                      </ContainerLabelInput>

                      <Line />
                      <ButtonsForm>
                        <ContainerButton>
                          <CustomButton
                            onClick={() => alert('Back')}
                            type={'button'}
                            size={'medium'}
                            typeButtom={'dangerHighlight'}
                            border='solid 1px #ccc'
                          >
                            Cancelar
                          </CustomButton>
                        </ContainerButton>

                        <ContainerButton>
                          <CustomButton
                            size={'medium'}
                            typeButtom={'primary'}
                            border='solid 1px #ccc'
                            type={'submit'}
                          >
                            Confimar
                          </CustomButton>
                        </ContainerButton>
                      </ButtonsForm>
                    </FormAdmContainer>
                  </Form>
                )}
              </Formik>
            </Main>
          </ContainerForm>
        </ContainerPage>
      </Container>
    </>
  );
}
