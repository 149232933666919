import styled from 'styled-components';

export const StyledCenteredContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledTimeLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledHourContainer = styled.div`
  display: flex;
  background: white;
  width: 100%;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledProfessionalSchedule = styled.span`
  display: flex;
  position: absolute;
  filter: brightness(98%);
  background: #008fff;
  z-index: 1;
  height: 100%;
  top: 0px;
  border: 1px solid #008fff;
  ${({ filled }) =>
    !filled && {
      'border-radius': '2px',
      border: '1px solid #f1f4f6',
    }}
`;

export const StyledHourSchedule = styled(StyledProfessionalSchedule)`
  background: unset;
  filter: unset;
  border: unset;
  font-size: 12px;
  align-items: center;
`;

export const StyledHourHeader = styled.div`
  width: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #e6e6e6;
  height: 30px;

  &:not(:last-of-type) {
    border-right: 0;
  }
`;

export const StyledProfessionalHour = styled(StyledHourHeader)`
  width: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #e6e6e6;
  position: relative;
  height: ${({ fixed }) => (fixed ? '30px' : '20px')};

  &:not(:last-of-type) {
    border-right: 0;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
