import React from 'react';

import {
    CustomDivider,
    CustomButton,
    CustomModal,
    CustomDivider,
} from '../../../../components/ui';

import {
    StyledButtonGroup,
    StyledModalContent,
    StyledModalInfoContent
} from './styles';


const ModalConfirm = ({ show, onCancel, onConfirm }) => {
    return (
        <CustomModal show={show} width='470'>
            <StyledModalContent>
                <StyledModalInfoContent>
                    <h1>Verifique sua escala</h1>
                    <p>
                        Você está finalizando sua jornada <span>fora do horário previsto</span><br />
                        em sua escala. Deseja prosseguir?
                    </p>
                </StyledModalInfoContent>

                <CustomDivider />

                <StyledButtonGroup>
                    <CustomButton
                        size='large'
                        text='Cancelar'
                        color='danger'
                        type='outlined'
                        onClick={() => onCancel(false)}
                    />
                    <CustomButton
                        size='large'
                        text='Confirmar'
                        color='primary'
                        onClick={() => onConfirm()}
                    />
                </StyledButtonGroup>
                <CustomDivider space={0.5} />
            </StyledModalContent>
        </CustomModal >
    );
};


export default ModalConfirm;