import { acionamentoSupportConstants } from '../constants';

import { acionamentoSuporteService } from '../services';
import { alertActions, modalAlertActions } from './';
import { history } from '../helpers';

export const acionamentoSuporteActions = {
  editAcionamentosSupport,
  editScreenAcionamentosSupport,
};

function editAcionamentosSupport(
  id,
  generalObservations = '',
  supportCompleted,
  refresh
) {
  return (dispatch) => {
    dispatch(request());
    acionamentoSuporteService
      .editAcionamentosSuporte(id, supportCompleted, generalObservations)
      .then(
        (body) => {
          dispatch(modalAlertActions.success(body.message));
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(modalAlertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoSupportConstants.ACIONAMENTO_SUPPORT_REQUEST };
  }
  function success() {
    return { type: acionamentoSupportConstants.ACIONAMENTO_SUPPORT_SUCCESS };
  }
  function failure(error) {
    return {
      type: acionamentoSupportConstants.ACIONAMENTO_SUPPORT_FAILURE,
      error,
    };
  }
}

function editScreenAcionamentosSupport(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
    history.push('/suporte/edit');
  };
  function request() {
    return { type: acionamentoSupportConstants.ACIONAMENTO_SUPPORT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: acionamentoSupportConstants.ACIONAMENTO_SUPPORT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: acionamentoSupportConstants.ACIONAMENTO_SUPPORT_FAILURE };
  }
}
