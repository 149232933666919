export const ActionPassageConstants = {
  ACIONAMENTO_PASSAGEM_REQUEST: 'ACIONAMENTO_PASSAGEM_REQUEST',
  ACIONAMENTO_PASSAGEM_SUCCESS: 'ACIONAMENTO_PASSAGEM_SUCCESS',
  ACIONAMENTO_PASSAGEM_FAILURE: 'ACIONAMENTO_PASSAGEM_FAILURE',
};

export const ActionPassageCreateConstants = {
  PAACIONAMENTO_CREATE_REQUEST: 'PAACIONAMENTO_CREATE_REQUEST',
  PAACIONAMENTO_CREATE_SUCCESS: 'PAACIONAMENTO_CREATE_SUCCESS',
  PAACIONAMENTO_CREATE_FAILURE: 'PAACIONAMENTO_CREATE_FAILURE',
};

export const ActionPassageListConstants = {
  PAACIONAMENTO_LIST_REQUEST: 'PAACIONAMENTO_PASSAGEM_LIST_REQUEST',
  PAACIONAMENTO_LIST_SUCCESS: 'PAACIONAMENTO_PASSAGEM_LIST_SUCCESS',
  PAACIONAMENTO_LIST_FAILURE: 'PAACIONAMENTO_PASSAGEM_LIST_FAILURE',
};

export const ActionPassageEditConstants = {
  PAACIONAMENTO_EDIT_REQUEST: 'PAACIONAMENTO_PASSAGEM_EDIT_REQUEST',
  PAACIONAMENTO_EDIT_SUCCESS: 'PAACIONAMENTO_PASSAGEM_EDIT_SUCCESS',
  PAACIONAMENTO_EDIT_FAILURE: 'PAACIONAMENTO_PASSAGEM_EDIT_FAILURE',
};

export const ActionPassageUpdateConstants = {
  PAACIONAMENTO_UPDATE_REQUEST: 'PAACIONAMENTO_PASSAGEM_UPDATE_REQUEST',
  PAACIONAMENTO_UPDATE_SUCCESS: 'PAACIONAMENTO_PASSAGEM_UPDATE_SUCCESS',
  PAACIONAMENTO_UPDATE_FAILURE: 'PAACIONAMENTO_PASSAGEM_UPDATE_FAILURE',
};
