import React, { useState } from 'react';

import chevron from '../assets/icons/chevron-up.svg';

import {
  AccordionBody,
  Container,
  Title,
  Subtittle,
  BodyTitle,
  AccordionOpen,
} from './style';

export default function Accordion({
  accordionTitle,
  accordionSubtitle,
  children,
  load,
  loadFunction,
}) {
  const [open, setOpen] = useState(false);

  function handleOpen() {
    if (loadFunction) {
      load();
    }
    setOpen(!open);
  }

  return (
    <>
      <AccordionBody>
        <Container onClick={handleOpen}>
          <BodyTitle>
            <Subtittle>{accordionSubtitle}</Subtittle>
            <Title>{accordionTitle}</Title>
          </BodyTitle>
          {open ? <AccordionOpen src={chevron} /> : <img src={chevron} />}
        </Container>
      </AccordionBody>
      {open ? children : <></>}
    </>
  );
}
