import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const Container = styled.div`
  width: 458px;
  box-sizing: border-box;
`;
export const ContainerAction = styled.div`
  display: flex;
  justify-content: ${(props) => props.footerpos};
  height: 35px;
  margin: 20px 0 70px 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 361px;
`;

export const CustomTitle = styled(DialogTitle)`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ContainerDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 20px;
  }
`;
