import styled from 'styled-components'

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const StyledModalInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #2E384D;
    margin-bottom: 14px;
  }

  p {
    font-size: 14px; 
    color: #6B7786;

    span {
      font-weight: bold;
      color: #2E384D;
    }
  }
`;