import React from 'react';

import { Accordion } from '@material-ui/core';

import styled, { css } from 'styled-components';

export const StyledAccordionInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const StyledAccordionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const sharedDividerStyle = css`
  background-color: #fafafa !important;
  height: 10px !important;
`;

export const StyledDivider = styled.div`
  ${sharedDividerStyle}
`;

export const MaterialAccordion = styled(({ ...props }) => (
  <Accordion {...props} />
))`
  &:before {
    ${sharedDividerStyle}
  }
`;
