import * as S from './styles'
import lessIcon from '../../../assets/icons/less.svg'
import plusIcon from '../../../assets/icons/plus.svg'

export function InputNumber({ ...props }) {
  return <S.divAlign>
    <S.LabelImg onClick={props.lessAction} src={lessIcon}></S.LabelImg>
    <S.InputNumber type='text'  step="1" {...props} isWithError={props.isWithError} />
    <S.LabelImgPlus onClick={props.plusAction} src={plusIcon}></S.LabelImgPlus>
    </S.divAlign>
}
