import React from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@material-ui/core/styles';
import { Input as InputMui } from '@material-ui/core';

export const Input = styledMui(InputMui)({
  width: '100%',
  padding: '10px',
});

export const InputMessage = styled.div`
  font-size: 11px;
  color: red;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  color: #bbb;
  font-size: 18px;
`;
