const usePushNotification = () => {
  const callPermision = () => {
    Notification.requestPermission();
  };

  const spawnNotification = (options) => {
    const n = new Notification(options.title, options.opt);

    n.addEventListener('click', () => {
      n.close();
      window.focus();

      if (options.link) {
        window.location.href = options.link;
      }
    });
  };

  return {
    callPermision,
    spawnNotification,
  };
};

export default usePushNotification;
