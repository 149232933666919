import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cFinalizadosActions } from '../actions';
import { cancelamentoUserActions } from '../actions';
import Send from '@material-ui/icons/Send';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Send: forwardRef((props, ref) => <Send {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class FilaCancelamentoFinalizados extends React.Component {
  constructor(props) {
    props.dispatch(cFinalizadosActions.getCancelamentosFinalizados());
    super(props);
    this.state = {
      cFinalizadosData: [],
      modalDeleteAcionamento: false,
      dataDeleteAcionamento: {},
      inputValueReason: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(cFinalizadosActions.getCancelamentosFinalizados());
  }

  handleEditCancelamento = (cancelamento) => {
    this.props.dispatch(
      cancelamentoUserActions.getCancelamentosByUSer(cancelamento)
    );
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { cancelamentosFinalizados } = this.props;
    const { docs } = cancelamentosFinalizados;
    const columns = [
      { title: 'Nome', field: 'name' },
      { title: 'Descrição', field: 'statusCancel.description' },
      // { title: 'data Inicio', field: 'dataInicio' },
      { title: 'data Fim', field: 'statusCancel.updatedTime' },
    ];
    // Lógica para manter acionamentos, eu pego os acionamento que estão com props e passo para State quando
    // realizo alguma acão. E aqui eu verifico se existe algum acionamento nos props, se não existir eu pego
    // os acionamentos que estão no state. É preciso fazer isso por que as props são zeradas a cada ação disparada.

    return (
      <React.Fragment>
        <MaterialTable
          icons={tableIcons}
          title=''
          columns={columns}
          data={docs}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não existem Cancelamentos Finalizados.',
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => {
              return rowData.status === 'ERROR_BROKER'
                ? {
                    icon: Edit,
                    tooltip: 'Detalhes',
                    onClick: (event, rowData) => {
                      this.handleEditCancelamento(rowData);
                    },
                  }
                : {
                    icon: '',
                    tooltip: '',
                    hidden: rowData,
                  };
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cFinalizados } = state;
  const { cancelamentosFinalizados } = cFinalizados;
  return {
    cancelamentosFinalizados,
  };
}

FilaCancelamentoFinalizados.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(
  FilaCancelamentoFinalizados
);
export { connectedLoginPage as FilaCancelamentoFinalizados };
