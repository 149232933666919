import React from 'react';
import MenuPainel from '../components/MenuPainel';
import AcionamentoTelepsicologia from '../components/AcionamentoTelepsicologia';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';
class AdmAcionamentoTelepsicologia extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        <MenuPainel>
          <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={<AcionamentoTelepsicologia />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = AdmAcionamentoTelepsicologia;
export { connectedAssinar as AdmAcionamentoTelepsicologia };
