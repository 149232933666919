import React from 'react';
import MenuPainel from '../components/MenuPainel';
import AdmAgendamentoTelepsicologia from '../components/AgendamentoTelepsicologia';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';

class AgendamentoTelepsicologia extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ChangePassword />
        <MenuPainel>
          <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={<AdmAgendamentoTelepsicologia />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = AgendamentoTelepsicologia;
export { connectedAssinar as AgendamentoTelepsicologia };
