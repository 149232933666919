import styled from 'styled-components';

export const ProfissionalCol = styled.td`
  width: 250px;
  height: 81px;
  vertical-align: middle;
  overflow: hidden;
`;

export const ProfessionalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 4px;
  padding: 8px;
  padding-right: 30px;
  position: relative;
`;

export const ProfessionalInfo = styled.p`
  max-width: calc(250px - 30px - 8px);
  min-width: calc(250px - 30px - 8px);
  font-size: 12px;
  font-weight: 500;
  color: #5a6a7e;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
