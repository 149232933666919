import React from 'react'

const Divider = ({ space = 10, border = false, height = 10 }) =>
  <div style={{
    width: '100%',
    margin: `${space}px 0px`,
    height,
    backgroundColor: border ? '#A7ABB2' : 'unset'
    }}
  />

export default Divider
