export const suporteConstants = {
  SUPORT_REQUEST: 'SUPORT_REQUEST',
  SUPORT_SUCCESS: 'SUPORT_SUCCESS',
  SUPORT_FAILURE: 'SUPORT_FAILURE',
};

export const editSuporteConstants = {
  SUPORT_EDIT_REQUEST: 'SUPORT_EDIT_REQUEST',
  SUPORT_EDIT_SUCCESS: 'SUPORT_EDIT_SUCCESS',
  SUPORT_EDIT_FAILURE: 'SUPORT_EDIT_FAILURE',
};

export const historySuporteConstants = {
  SUPORT_HISTORY_REQUEST: 'SUPORT_HISTORY_REQUEST',
  SUPORT_HISTORY_SUCCESS: 'SUPORT_HISTORY_SUCCESS',
  SUPORT_HISTORY_FAILURE: 'SUPORT_HISTORY_FAILURE',
};
