export const LoginMessages = {
  INPUT: {
    INVALID_CODE: 'O código inserido é inválido',
    EXPIRED_CODE: 'O código inserido está expirado',
    EXPIRED_TOKEN: 'Este código expirou, gere um novo código!',
    CANNOT_VALIDATE_CODE:
      'Não conseguimos validar seu código, entre novamente!',
    CANNOT_CREATE_SESSION: 'Não conseguimos criar sua sessão, tente novamente!',
  },
  SELECT: {
    EXPIRED_TOKEN: 'Tempo limite ultrapassado, tente novamente!',
    ALREADY_SENT_CODE:
      'Você enviou um código recentemente, aguarde para enviar outro!',
    CANNOT_SEND_CODE:
      'Não conseguimos enviar o código de verificação, tente novamente!',
  },
  FOOTER: {
    EXPIRED_TOKEN: 'Tempo limite atingido, insira os dados novamente!',
    WAIT_FOR_RESEND:
      'Você enviou um código recentemente, aguarde para enviar outro!',
    UNEXPECTED: 'Ocorreu um erro. Insira os dados novamente!',
  },
};
