import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 20px 0px;
  background-color: #f5f5f5;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledSchedullingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  color: #6b7786;
  background: #fff;
  position: relative;
  gap: 20px;

  padding: 5px 10px;
  margin-right: 10px;

  /*   @media screen and (max-width: 992px) {
    flex-direction: column;
  } */
`;
export const StyledProfileTimerPause = styled.div`
  background: #f1f4f6;
  padding: 10px 20px 6px 20px;
  border-radius: 4px;

  color: #6b7786;
  transition: hover ease 0.5s;
`;
export const StyledProfileTimerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
`;
export const StyledProfileTimerTextTitle = styled.div`
  font-size: 12px;
  color: #6b7786;
`;
export const StyledProfileTimerTextSubTitle = styled.div`
  font-weight: bold;
  color: #2e384d;
`;
export const StyledProfileTimerSelected = styled.div`
  cursor: pointer;
`;
export const StyledProfileTimerSelectedExpanded = styled.div`
  z-index: 1;
  position: absolute;
  top: 58px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  opacity: ${(props) => (props.selected ? '1' : '0')};
  transition: opacity 0.1s ease 0.02s;
`;

export const StyledProfileTimerSelectedContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px;

  .selected-pause-container:nth-child(${(props) => props.pauseIndex ?? 0}) {
    div {
      color: ${(props) => (props.pauseIndex > 0 ? '#2196f3' : '#000')};
    }
  }
`;

export const StyledProfileTimerSelectedTextTitle = styled.div`
  padding: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
`;

export const StyledProfileTimerSelectedContentSwith = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const StyledProfileTimerSelectedContentLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`;

export const StyledProfileTimerSelectedTextSwith = styled.div`
  color: #000;
  padding-right: 8px;
`;

export const StyledProfileTimerSelectedTextLink = styled.div``;


export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
