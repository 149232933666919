import React from 'react';
import DialogSimpleModal from '../DialogSimpleModal';
import SucessIcon from '../../icons/success.svg';
import ErrorIcon from '../../icons/error-icon.svg';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';

import { ContainerModal } from './styles';
const ModalAlert = ({
  open,
  handleCancel,
  handleProceed,
  type,
  message,
  userCogEmail = '',
}) => {
  const [emailVerify, setemailVerify] = useState('');

  let Icon =
    type === 'error' ? (
      <img src={ErrorIcon} alt='Erro' />
    ) : (
      <img src={SucessIcon} alt='Sucesso' />
    );

  if (type == 'message')
    Icon = (
      <div style={{ width: '100%' }}>
        <a
          onClick={handleCancel}
          style={{
            color: '#fff',
            backgroundColor: '#DC004E',
            padding: '10px',
            marginRight: '10px',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Cancelar
        </a>
        {userCogEmail == emailVerify && (
          <a
            onClick={() => {
              setemailVerify('');
              handleProceed();
            }}
            style={{
              color: '#fff',
              backgroundColor: '#3F51B5',
              padding: '10px',
              marginRight: '10px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Prosseguir
          </a>
        )}
        {userCogEmail != emailVerify && (
          <a
            style={{
              color: '#fff',
              backgroundColor: '#3F51B5',
              padding: '10px',
              marginRight: '10px',
              borderRadius: '5px',
              cursor: 'not-allowed',
            }}
          >
            Prosseguir
          </a>
        )}
      </div>
    );
  return (
    <>
      <DialogSimpleModal
        message={message}
        open={open}
        handleCancel={handleCancel}
        text=''
        simple
      >
        <ContainerModal>
          {' '}
          <div>
            <div>{message}</div>
            {userCogEmail && (
              <div>
                <p>
                  E-mail: <b>{userCogEmail}</b>
                </p>
                <TextField
                  label='Confirme o e-mail do usuário'
                  margin='normal'
                  value={emailVerify}
                  onChange={(e) => {
                    setemailVerify(e.target.value);
                  }}
                  style={{
                    width: '100%',
                  }}
                ></TextField>
              </div>
            )}
          </div>
          <div>{Icon}</div>
        </ContainerModal>
      </DialogSimpleModal>
    </>
  );
};

export default ModalAlert;
