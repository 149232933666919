import React from 'react';

import { CustomButton, CustomModal } from '../../../../../components/ui';

import { StyledSubtitle, StyledTitle, StyledButtonsWrapper } from './styles';

import useModalUnlock from './hooks/useModalUnlock';

const ModalUnlock = ({ show, dataUser, setReloadGrid }) => {
  const { handleCancel, handleUnlock, motive, showUnlock, useLoading } =
    useModalUnlock(dataUser, setReloadGrid);

  return (
    <CustomModal show={show ? showUnlock : show}>
      <>
        <StyledTitle>Solicitação de desbloqueio de estação</StyledTitle>

        <StyledSubtitle>
          O funcionário {dataUser.userName}, solicitou o desbloqueio da estação
          {motive}.
          <br />
          Deseja desbloquear?
        </StyledSubtitle>

        <StyledButtonsWrapper>
          <CustomButton
            text='Confirmar'
            size='medium'
            loading={useLoading}
            onClick={() => handleUnlock(dataUser.userId)}
          />

          <CustomButton
            text='Cancelar'
            size='medium'
            color='danger'
            onClick={() => handleCancel(showUnlock)}
          />
        </StyledButtonsWrapper>
      </>
    </CustomModal>
  );
};

export default ModalUnlock;
