export const Nacionality = [
  { label: 'Brasileira', value: 'B' },
  { label: 'Estrangeira', value: 'E' },
];
export const ArrayConvenios = [
  'Albert Einstein',
  'Albert Einstein II',
  'Albert Einstein III',
  'Albert Einstein IV 2112',
  'Albert Einstein V',
  'Albert Einstein VI',
];

export const selectDoc = [
  {
    label: 'CPF',
    value: 'CPF',
  },
  {
    value: 'PASSPORT',
    label: 'Passaporte',
  },
];

export const Sexo = [
  {
    label: 'Masculino',
    value: 'M',
  },
  {
    label: 'Feminino',
    value: 'F',
  },
];

export const initialValuesFormAdm = {
  email: '',
  email2: '',
  cabine: false,
  supportCompleted: false,
  supportNotCompleted: false,
  checkDisruptiveBehavior: false,
  checkUrgencyAttendance: false,
  checkSocialName: false,
  convenio: '',
  convenioDescricao: '',
  name: '',
  socialName: '',
  numberCard: '',
  lastName: '',
  partnerID: '',
  document: '',
  documentType: '',
  birthDate: '',
  phone: '',
  phone2: '',
  gender: '',
  partnerID: '',
  nationality: '',
  naturalness: '',
  city: '',
  state: '',
  country: '',
  codPais: '',
  streetCep: '',
  flgNewPassage: '',
  produto: '',
  disruptiveBehavior: '',
  urgencyAttendance: '',
  confirmationUser: {
    _id: '',
    email: '',
    nome: '',
    login: '',
    telefone: '',
  },
  deviceData: {
    deviceType: '',
    OS: '',
    navigator: '',
    route: '/',
    accessChannel: '',
    appVersion: '',
    deviceBrand: '',
  },
  urlAttendanceVonage: '',
  idAttendanceVonage: '',
  docsReferences: [],
  signUpDocuments: [],
  docRemovedBy: {},
  processing: true,
  generalObservations: '',
};
