import { countryConstants } from '../constants';

export function country(state = { countries: [] }, action) {
  switch (action.type) {
    case countryConstants.COUNTRY_SUCCESS:
      return {
        type: 'success',
        countries: action.result,
      };
    case countryConstants.COUNTRY_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        countries: [],
      });
    case countryConstants.COUNTRY_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
