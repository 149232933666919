import React from 'react';

import { StyledSwitch } from './styles';

const CustomSwitch = ({ checked, onChange, disabled = false }) => (
  <StyledSwitch disabled={disabled}>
    <label className='switch'>
      <input
        role='switch'
        type='checkbox'
        checked={checked}
        onChange={() => onChange?.()}
        disabled={disabled}
      />
      <span className='slider round'></span>
    </label>
  </StyledSwitch>
);

export default CustomSwitch;
