import { cFinalizadosConstants } from '../constants';

export function cFinalizados(state = { cancelamentosFinalizados: {} }, action) {
  switch (action.type) {
    case cFinalizadosConstants.CANCELAMENTO_FINALIZADOS_SUCCESS:
      return {
        type: 'success',
        cancelamentosFinalizados: action.cFinalizados,
      };
    case cFinalizadosConstants.CANCELAMENTO_FINALIZADOS_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        cancelamentosFinalizados: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    case cFinalizadosConstants.CANCELAMENTO_FINALIZADOS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
        cancelamentosFinalizados: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    default:
      return Object.assign({}, state, {
        type: 'request',
        cancelamentosFinalizados: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
  }
}
