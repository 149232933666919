import {
  FindAllQueueRecordsFilter,
  FindAllQueueRecordsState,
} from '../../../../domain/state';
import { dispatch, selector } from '../../../../application/store/root';
import {
  setQueueRecordList,
  setPages,
  setTotalPages,
  setLoading,
  setFilter,
  setError,
  setErrorMessage,
  setSendCockpitLoading,
  setSendCockpitError,
  setSendCockpitErrorMessage,
} from './find-all-slice';
import { QueueRecordEntity } from '../../../../domain/entities';

export class FindAllQueueRecordsStore {
  dispatch = dispatch();

  findAllQueueRecordsSelector: FindAllQueueRecordsState = selector(
    (state) => state.findAllQueueRecords
  );

  handleQueueRecordList = (list: QueueRecordEntity[]) => {
    this.dispatch(setQueueRecordList(list));
  };

  handlePages = (pages: number) => {
    this.dispatch(setPages(pages));
  };

  handleTotalPages = (totalPages: number) => {
    this.dispatch(setTotalPages(totalPages));
  };

  handleLoading = (loading: boolean) => {
    this.dispatch(setLoading(loading));
  };

  handleFilter = (filter: FindAllQueueRecordsFilter) => {
    this.dispatch(setFilter(filter));
  };

  handleError = (error: boolean) => {
    this.dispatch(setError(error));
  };

  handleErrorMessage = (errorMessage: string) => {
    this.dispatch(setErrorMessage(errorMessage));
  };

  handleSendCockpitLoading = (loading: boolean) => {
    this.dispatch(setSendCockpitLoading(loading));
  };

  handleSendCockpitError = (error: boolean) => {
    this.dispatch(setSendCockpitError(error));
  };

  handleSendCockpitErrorMessage = (errorMessage: string) => {
    this.dispatch(setSendCockpitErrorMessage(errorMessage));
  };
}
