import { passwordConstants } from '../constants';

export function password(state = { openPassword: false }, action) {
  switch (action.type) {
    case passwordConstants.CHANGE_PASSWORD_REQUEST:
      return { openPassword: true };
    case passwordConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        openPassword: false,
      };
    case passwordConstants.CHANGE_PASSWORD_CANCEL:
      return {
        openPassword: false,
      };
    default:
      return state;
  }
}
