import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const filaSuporteService = {
  getQueueSuport,
  getQueueSuportHistory,
  deleteAcionamentoSuporte,
  DownloadJSON,
  getCountSuport,
  // paginateAllQueue,
  // DownloadJSON,
  // confirmedTpisicoAcionamento,
};

function getCountSuport() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/suport/count`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function DownloadJSON(dataInicioCsv, dataFimCsv, page) {
  let user = JSON.parse(window.localStorage.user);
  dataInicioCsv = dataInicioCsv.replace(/\//g, '-');
  dataFimCsv = dataFimCsv.replace(/\//g, '-');

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/suport/downloadjson/${dataInicioCsv}/${dataFimCsv}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

async function getQueueSuport(pageSize = 5, page = 1, queryString = '') {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
    }),
  };

  const getService = userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/suport/${page}/${pageSize}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
  //.catch((error) => error);

  return getService;
}

async function getQueueSuportHistory(pageSize = 5, page = 1, queryString = '') {
  const user = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
    }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/suporthistory/${page}/${pageSize}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
  //.catch((error) => error);

  return getService;
}

function deleteAcionamentoSuporte(
  id,
  reason,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  let user = JSON.parse(window.localStorage.user);
  let { _id, email, nome, login, telefone } = user.user;
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      reason,
      cancelUser: { _id, email, nome, login, telefone },
      urgencyAttendance,
      urgencyAttendenceReason,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/suporte/delete/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
