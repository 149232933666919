export const countryConstants = {
  COUNTRY_REQUEST: 'COUNTRY_REQUEST',
  COUNTRY_SUCCESS: 'COUNTRY_SUCCESS',
  COUNTRY_FAILURE: 'COUNTRY_FAILURE',
};

export const regionConstants = {
  REGION_REQUEST: 'REGION_REQUEST',
  REGION_SUCCESS: 'REGION_SUCCESS',
  REGION_FAILURE: 'REGION_FAILURE',
};

export const citiesConstants = {
  CITIE_REQUEST: 'CITIE_REQUEST',
  CITIE_SUCCESS: 'CITIE_SUCCESS',
  CITIE_FAILURE: 'CITIE_FAILURE',
};
