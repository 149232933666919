import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 530px;
  padding-left: 10px;
  max-width: 600px;
  text-align: center;
  min-height: 100px;
`;
// todo make grid and test
export const ContainerButtom = styled.div`
  width: 125px;
  float: left;
  margin-top: 20px;
  height: 35px;
`;

export const ContainerTag = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Failback = styled.div`

::before {
  content: "Carregando horários.";
} 
#loader {
  margin-top:30px;
  margin  0 auto
  display: block;
}

  ${(props) =>
    props.eventTimeout &&
    css`
      ::before {
        content: '';
      }

      ::after {
        content: 'Erro ao carregar horários ou agenda inexistente.';
      }

      #loader {
        display: none;
      }
    `};
`;
