import React from 'react';

import { StyledFadeIn } from './styles';

const FadeIn = ({ children, visible }) => (
    <StyledFadeIn visible={visible}>
      {children}
    </StyledFadeIn>
)

export default FadeIn;
