import { alertConstants } from '../constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
        open: true,
        loading: true,
      };
    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.message,
        open: true,
        loading: true,
      };
    case alertConstants.CLEAR:
      return {
        ...action.props,
        type: 'success',
        message: '',
        open: false,
        loading: false,
      };
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
