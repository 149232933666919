import httpClient from '../../../utils/httpClient';

const prefixResource = 'grid';

const singularResource = 'scale';
const resource = 'scales';

const create = async (data) => {
  const response = await httpClient.POST(`${resource}`, { body: data });

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const remove = async (id) => {
  const response = await httpClient.DELETE(`${singularResource}?id=${id}`);

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
        };
  } catch (error) {
    return {
      status: false,
    };
  }
};

const getByUser = async (dateBegin, dateEnd) => {
  const response = await httpClient.GET(
    `grid/${resource}/me?dateBegin=${dateBegin}&dateEnd=${dateEnd}`
  );

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const getByQuery = async () => {
  const query = `?userId=${userId}&date=${dateDay}&limit=${limit}&offset=${offset}`;

  const response = await httpClient.GET(`${resource}${query}`);

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const getByDay = async (data) => {
  const { day, exactlyDate = false } = data;
  const response = await httpClient.GET(
    `${prefixResource}/${resource}?date=${day}&exactlyDate=${exactlyDate}`
  );

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const getByOne = async (id) => {
  const response = await httpClient.GET(`scale?id=${id}`);

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const updateOne = async (id, body) => {
  const response = await httpClient.PUT(`${resource}?id=${id}`, { body });

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const getUserIdByScale = async (id) => {
  const response = await httpClient.GET(`scale-sockets/${id}`);

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const removeScales = async (userId, scales) => {
  let url = `${resource}/${userId}`
  
  if(scales) {
    url += `?scales=${JSON.stringify(scales)}`
  }

  const response = await httpClient.DELETE(url);

   try {
    const data = await response.json();

    return {
      status: response.ok,
      message: data?.message,
    };
  } catch (e) {
    return {
      status: false,
    };
  }
};

const unlockUserScale = async (motive, userIdBlock) => {
  const response = await httpClient.POST(`request-unlock`, {
    body: {
      motive,
      userIdBlock,
    },
  });

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

export default {
  getByUser,
  unlockUserScale,
  removeScales,
  getByQuery,
  remove,
  create,
  getByDay,
  getByOne,
  updateOne,
  getUserIdByScale,
};
