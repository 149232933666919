import { useEffect, useState, useCallback } from 'react';

import { Timer } from '../utils';

const verticalPosition = 24;
const horizontalPosition = 24;
const layerHeight = 70;

const calculateMargins = (id) => verticalPosition * id;

const calculateDistance = (id) => layerHeight * id;

const calculateCurrentSnackPosition = (id) =>
  id === 1
    ? verticalPosition
    : calculateMargins(id) + calculateDistance(id - 1);

const useLayer = (id, align, duration = 6000, onTimeEnd) => {
  const [useFadeOut, setFadeOut] = useState(false);

  const directions = {
    BottomLeft: {
      left: `${horizontalPosition}px`,
      bottom: `${calculateCurrentSnackPosition(id)}px`,
    },
    BottomRight: {
      right: `${horizontalPosition}px`,
      bottom: `${calculateCurrentSnackPosition(id)}px`,
    },
    TopRight: {
      top: `${calculateCurrentSnackPosition(id)}px`,
      right: `${verticalPosition}px`,
    },
    TopLeft: {
      top: `${calculateCurrentSnackPosition(id)}px`,
      left: `${verticalPosition}px`,
    },
  };

  const layerAlign = directions[align] || directions.BottomRight;

  const timeLapse = new Timer(() => {
    abortSnack();
  }, duration);

  const abortSnack = () => {
    setFadeOut(true);
  };

  const startTimeLapse = useCallback(() => {
    timeLapse.resume();

    if (useFadeOut) {
      setTimeout(() => {
        timeLapse.stop();
        onTimeEnd();
      }, 500);
    }
  }, [timeLapse, useFadeOut]);

  useEffect(() => {
    startTimeLapse();
  }, [useFadeOut]);

  return {
    layerAlign,
    abortSnack,
    useFadeOut,
  };
};

export default useLayer;
