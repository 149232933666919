import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user, loginstatus: {}, submitted: false }
  : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loginstatus: state.loginstatus,
        loggedIn: false,
        submitted: false,
      };
    case userConstants.LOGOUT:
      return {
        user: {},
      };
    default:
      return state;
  }
}
