import React from 'react';
import MenuPainel from '../components/MenuPainel';
import AdmCancelamento from '../components/CancelamentosUser';
import PaperForm from '../components/PaperForm';
import GlobalStyle from '../styles/globalStyle';
import Alert from '../components/Alert';
import { alertActions } from '../actions';
import { CssBaseline } from '@material-ui/core';

class Cancelamento extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }
  render() {
    const { message, type, open } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <MenuPainel stepper='Cancelamentos - B2C'>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
          <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={<AdmCancelamento />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = Cancelamento;
export { connectedAssinar as Cancelamento };
