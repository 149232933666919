import { atividadesConstants } from '../constants';

export function atividades(
  state = {
    atividades: {
      docs: [],
      limit: 5,
      page: 1,
      pages: 1,
      total: 0,
    },
  },
  action
) {
  switch (action.type) {
    case atividadesConstants.ACTIVITIES_REQUEST:
      return {
        atividades: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      };
    case atividadesConstants.ACTIVITIES_ERROR:
      return action;
    case atividadesConstants.ACTIVITIES_SUCCESS:
      return {
        atividades: {
          docs: action.result.docs,
          limit: action.result.limit,
          page: action.result.page,
          pages: action.result.pages,
          total: action.result.total,
        },
      };
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
