export const lockedMessage =
  'O tempo pausa foi excedido, bloqueando essa estação de trabalho. Para efetuar o desbloqueio entre em contato com seu supervisor.';

export const sentRequestMessage =
  'Solicitação de desbloqueio enviada, aguardando desbloqueio.';

export const sendRequestUnlock =
'Solicitar desbloqueio ao Supervisor';

export const sendedRequestUnlock =
'Desbloqueio Solicitado';