import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

export function ScrollToError() {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const keysErros = Object.keys(formik?.errors);

  useEffect(() => {
    const el = document.querySelector(`#${keysErros[0]}`);
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [submitting]);

  return null;
}
