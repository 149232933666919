import { forwardingConstants } from '../constants/forwardingSupport.constants';
import { forwardingSupport } from '../services/encaminhamentoSuport.service';
import { modalAlertActions } from './modalAlert.action';

export const actionForwarding = {
  forwarding,
  startSupport,
  noActionSupport,
};

function forwarding(user) {
  return (dispatch) => {
    dispatch(request());
    forwardingSupport
      .encaminhamentoSupport(user)
      .then((body) => {
        dispatch(success(body));
        dispatch(modalAlertActions.success(body.message));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(modalAlertActions.error(error.message));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: forwardingConstants.FORWARDING_REQUEST };
  }

  function success(forwarding) {
    return { type: forwardingConstants.FORWARDING_SUCCESS, forwarding };
  }

  function failure(error) {
    return { type: forwardingConstants.FORWARDING_ERROR, error };
  }
}

function startSupport(user) {
  return (dispatch) => {
    dispatch(request());
    forwardingSupport
      .updateSupportToStart(user)
      .then((body) => {
        dispatch(success(body));
        dispatch(modalAlertActions.success(body.message));
        dispatch(modalAlertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(modalAlertActions.error(error.message));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: forwardingConstants.FORWARDING_REQUEST };
  }

  function success(dataAttendance) {
    return { type: forwardingConstants.FORWARDING_SUCCESS, dataAttendance };
  }

  function failure(error) {
    return { type: forwardingConstants.FORWARDING_ERROR, error };
  }
}

function noActionSupport(user) {
  return (dispatch) => {
    dispatch(request());
    forwardingSupport
      .updateSupportToWait(user)
      .then((body) => {
        dispatch(success(body));
        dispatch(modalAlertActions.success(body.message));
        dispatch(modalAlertActions.clear());
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(modalAlertActions.error(error.message));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: forwardingConstants.FORWARDING_REQUEST };
  }

  function success(dataAttendance) {
    return { type: forwardingConstants.FORWARDING_SUCCESS, dataAttendance };
  }

  function failure(error) {
    return { type: forwardingConstants.FORWARDING_ERROR, error };
  }
}
