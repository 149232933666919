import { regionConstants } from '../constants';

export function region(state = { regions: [] }, action) {
  switch (action.type) {
    case regionConstants.REGION_SUCCESS:
      return {
        type: 'success',
        regions: action.result,
      };
    case regionConstants.REGION_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        regions: [{}],
      });
    case regionConstants.REGION_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
