import React from 'react';
import { QueueRecordList } from '../../../application/pages';
import {
  MakeFindAllQueueRecordsStore,
  MakeReportQueueRecordsStore,
  MakeShowQueueRecordsStore,
} from '../../../main/factories/store';
import {
  MakeFindAllQueueRecords,
  MakeReportQueueRecords,
  MakeResendCockpit,
} from '../../../main/factories/usecases';

export const MakeQueueRecordList = () => {
  return (
    <QueueRecordList
      findAllQueueRecordsStore={MakeFindAllQueueRecordsStore()}
      findAllQueueRecordsData={MakeFindAllQueueRecords()}
      showQueueRecordsStore={MakeShowQueueRecordsStore()}
      reportQueueRecordsStore={MakeReportQueueRecordsStore()}
      reportQueueRecordsData={MakeReportQueueRecords()}
      resendCockpitData={MakeResendCockpit()}
    />
  );
};
