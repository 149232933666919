import styled from 'styled-components';

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const StyledFlexDisplay = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const StyledChipContainer = styled(StyledFlexDisplay)`
  flex-direction: column;
  gap: 10px;
`;

export const StyledAvatarContainer = styled(StyledFlexDisplay)``;

export const StyledPopoverContent = styled(StyledFlexDisplay)`
  cursor: pointer;
`;

export const StyledSelectWrapper = styled.div`
  width: max-content;

  @media (max-width: 568px) {
    width: 100%;
  }
`;
