import { TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Fieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.label`
  text-transform: uppercase;
  margin: 10px 0px 10px 0px;
  color: #2e384d;
  font-size: 12px;
`;

interface StyledSelectProps {
  $hasNoValue?: boolean;
}
export const StyledSelect = styled(TextField)<StyledSelectProps>`
  ${({ $hasNoValue }) => css`
    .MuiInputBase-root {
      ${$hasNoValue && `color: grey !important;`}
    }

    .MuiInput-underline {
      &::after,
      &::before {
        border-color: transparent !important;
      }
    }

    .MuiInputBase-root {
      min-height: 38px;
      border: 1px solid rgb(206, 212, 218);
      border-radius: 5px;

      & textarea,
      & .MuiSelect-root {
        padding-left: 6px;
      }
    }
  `}
`;
