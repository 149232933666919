import { editHistoricoConstants } from '../constants';

export function editHistoricos(state = { user: {} }, action) {
  switch (action.type) {
    case editHistoricoConstants.HISTORICO_EDIT_SUCCESS:
      return {
        type: 'success',
        user: action.editHistoricos,
      };
    case editHistoricoConstants.HISTORICO_EDIT_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        user: {},
      });
    case editHistoricoConstants.HISTORICO_EDIT_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
