import styled from 'styled-components'

export const ButtonDarkerBlueOutlined = styled.button`
  width: 98px;
  height: 40px;
  outline: none;
  border: 1px solid rgb(44,120,245);
  border-radius: 4px;
  background: #F3F5F9;
  color: rgb(44,120,245);
  font: normal normal 600 14px/15px;
  font-family:'Inter';
  cursor: pointer;
  
  :hover {
    background: rgb(44,120,245);
    color: #F3F5F9;
  }
`