import { userConstants } from '../constants';
import { userService } from '../services';
import {
  alertActions,
  modalAlertActions,
  alertContingenciaActions,
  actionNotification,
} from './';
import { history } from '../helpers';

export const userActions = {
  // login,
  // loginV2,
  loginV2Robot,
  logout,
  register,
  registerNovo,
  registerEmpAdm,
  put,
  putVonage,
  registerVonage,
  getAll,
  RecoverPassword,
  setEnabledQueues,
  getEnabledQueues,
};

// function login(username, password) {
//   return (dispatch) => {
//     dispatch(request({ username }));

//     userService
//       .login(username, password)
//       .then(
//         async (user) => {
//           dispatch(success(user));
//           dispatch(actionNotification.Notifications(user));
//           dispatch(actionNotification.getIdNotifications(user));

//           if (
//             user?.user?.id_perfil?.descricao === 'Gestor' ||
//             user?.user?.id_perfil?.descricao === 'Administrativo' ||
//             user?.user?.id_perfil?.descricao === 'Suporte'
//           ) {
//             history.push('/adm');
//           } else if (
//             user?.user?.id_perfil?.descricao === 'Empresa - Administrativo'
//           ) {
//             history.push('/painel');
//           } else if (user?.user?.id_perfil?.descricao === 'Assistencial') {
//             history.push('/adm');
//           } else if (
//             user?.user?.classificacao_cod_classe != 'crm' &&
//             user?.user?.id_perfil?.descricao === 'Assistencial'
//           ) {
//             history.push('/teletriagem');
//           } else if (
//             user?.user?.id_perfil?.descricao === 'Médico' ||
//             (user?.user?.classificacao_cod_classe == 'crp' &&
//               user?.user?.id_perfil?.descricao === 'Assistencial')
//           ) {
//             history.push('/adm/agenda/telepsicologia');
//           } else {
//             dispatch(failure('fail'));
//             dispatch(alertActions.error('Esse perfil não tem permissão.'));
//           }
//         },
//         (error) => {
//           dispatch(failure(error));
//           dispatch(alertActions.error(error.error_description));
//         }
//       )
//       .catch((error) => {
//         dispatch(failure(error.toString()));
//       });
//   };

//   function request(user) {
//     return { type: userConstants.LOGIN_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.LOGIN_SUCCESS, user };
//   }
//   function failure(error) {
//     console.log(error);
//     return { type: userConstants.LOGIN_FAILURE, error };
//   }
// }

// function loginV2(username, password, recaptcha, onError) {
//   const profilesTypeContingencia = ['Gerador de Link', 'Contingência'];
//   return (dispatch) => {
//     // dispatch(request({ username }));

//     userService
//       .loginV2(username, password, recaptcha)
//       .then(
//         async (user) => {
//           console.log(user);
//           dispatch(success(user));
//           // if (
//           //   profilesTypeContingencia.includes(user?.user?.id_perfil?.descricao)
//           // ) {
//           //   return history.push('/contingencia');
//           // }
//           // if (
//           //   user?.user?.id_perfil?.descricao === 'Gestor' ||
//           //   user?.user?.id_perfil?.descricao ===
//           //     'Gestor da Unidade de Negócio' ||
//           //   user?.user?.id_perfil?.descricao === 'Administrativo' ||
//           //   user?.user?.id_perfil?.descricao === 'Suporte'
//           // ) {
//           //   history.push('/adm');
//           // } else if (
//           //   user?.user?.id_perfil?.descricao === 'Empresa - Administrativo'
//           // ) {
//           //   history.push('/painel');
//           // } else if (user?.user?.id_perfil?.descricao === 'Assistencial') {
//           //   history.push('/adm');
//           // } else if (
//           //   user?.user?.classificacao_cod_classe != 'crm' &&
//           //   user?.user?.id_perfil.descricao === 'Assistencial'
//           // ) {
//           //   history.push('/teletriagem');
//           // } else if (
//           //   user?.user?.id_perfil?.descricao === 'Médico' ||
//           //   (user?.user?.classificacao_cod_classe == 'crp' &&
//           //     user?.user?.id_perfil?.descricao === 'Assistencial')
//           // ) {
//           //   history.push('/adm/agenda/telepsicologia');
//           // } else {
//           //   dispatch(failure('fail'));
//           //   dispatch(alertActions.error('Esse perfil não tem permissão.'));
//           // }
//         },
//         (error) => {
//           dispatch(failure(error));
//           if (onError) onError();
//           //dispatch(alertActions.error(error.error_description));
//         }
//       )
//       .catch((error) => {
//         console.log('error', error);
//         dispatch(failure(error.toString()));
//       });
//   };

//   function request(user) {
//     return { type: userConstants.LOGIN_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.LOGIN_SUCCESS, user };
//   }
//   function failure(error) {
//     return { type: userConstants.LOGIN_FAILURE, error };
//   }
// }

function loginV2Robot(username, password, scope) {
  const profilesTypeContingencia = ['Gerador de Link', 'Contingência'];
  return (dispatch) => {
    dispatch(request({ username }));

    userService
      .loginV2Robot(username, password)
      .then(
        async (user) => {
          dispatch(success(user));
          if (
            profilesTypeContingencia.includes(user?.user?.id_perfil?.descricao)
          ) {
            return history.push('/contingencia');
          }
          if (
            user?.user?.id_perfil?.descricao === 'Gestor' ||
            user?.user?.id_perfil?.descricao ===
              'Gestor da Unidade de Negócio' ||
            user?.user?.id_perfil?.descricao === 'Administrativo' ||
            user?.user?.id_perfil?.descricao === 'Suporte'
          ) {
            history.push('/adm');
          } else if (
            user?.user?.id_perfil?.descricao === 'Empresa - Administrativo'
          ) {
            history.push('/painel');
          } else if (user?.user?.id_perfil?.descricao === 'Assistencial') {
            history.push('/adm');
          } else if (
            user?.user?.classificacao_cod_classe != 'crm' &&
            user?.user?.id_perfil?.descricao === 'Assistencial'
          ) {
            history.push('/teletriagem');
          } else if (
            user?.user?.id_perfil?.descricao === 'Médico' ||
            (user?.user?.classificacao_cod_classe == 'crp' &&
              user?.user?.id_perfil?.descricao === 'Assistencial')
          ) {
            history.push('/adm/agenda/telepsicologia');
          } else {
            dispatch(failure('fail'));
            dispatch(alertActions.error('Esse perfil não tem permissão.'));
          }
        },
        (error) => {
          dispatch(failure(error));
          scope.setState({ invalidLogin: true });
          scope.setState({ loadingsub: false });
          //dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        console.log('error', error);
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function RecoverPassword(username, reCaptchaToken) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService
      .RecoverPassword(username, reCaptchaToken)
      .then(
        (user) => {
          if (user.error) {
            dispatch(alertActions.error(user.error));
            history.push('/');
          } else {
            dispatch(alertActions.success(user.message));
            history.push('/');
          }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.RECOVER_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RECOVER_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RECOVER_PASSWORD_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function registerEmpAdm(user, onNext) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    userService
      .registerEmpAdm(user)
      .then(
        (dados) => {
          dispatch(success());
          dispatch(alertContingenciaActions.success('Link de Acesso', dados));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function register(user, onNext) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    userService
      .register(user)
      .then(
        (dados) => {
          dispatch(success());
          dispatch(alertContingenciaActions.success('Link de Acesso', dados));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function registerVonage(user, setLoading) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    userService
      .registerVonage(user)
      .then(
        (dados) => {
          dispatch(success());
          dispatch(
            alertContingenciaActions.success('Link de Acesso', dados.url)
          );
          setLoading(false);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          setLoading(false);
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
        setLoading(false);
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function put(user) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    userService
      .put(user)
      .then(
        (dados) => {
          dispatch(success());
          dispatch(alertActions.success('Acionamento atualizado.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function putVonage(user) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    userService
      .putVonage(user)
      .then(
        (dados) => {
          dispatch(success());
          dispatch(alertActions.success('Acionamento atualizado.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());
    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

//Register do formulario novo
function registerNovo(user, setLoading) {
  var tempData = user.birthDate.split('/');
  user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request(user));
    userService
      .registerNovo(user)
      .then(
        (dados) => {
          dispatch(success());
          setLoading(false);
          dispatch(alertContingenciaActions.success('Link de Acesso', dados));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(modalAlertActions.error(error.message));
          setLoading(false);
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
        setLoading(false);
      });
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function setEnabledQueues(ids) {
  return (dispatch) => {
    dispatch(request(ids));
    userService
      .setPersonalizedQueues(ids)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request(ids) {
    return { type: userConstants.SET_ENABLED_QUEUES_REQUEST, ids };
  }
  function success(ids) {
    return { type: userConstants.SET_ENABLED_QUEUES_SUCCESS, ids };
  }
  function failure(error) {
    return { type: userConstants.SET_ENABLED_QUEUES_FAILURE, error };
  }
}

function getEnabledQueues(setLoading) {
  return (dispatch) => {
    dispatch(request());
    userService
      .getPersonalizedQueues()
      .then(
        (body) => {
          dispatch(success({ filas: body.result, status: body.status }));
          if (setLoading) setLoading(false);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          if (setLoading) setLoading(false);
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: userConstants.GET_ENABLED_QUEUES_REQUEST };
  }
  function success(enabledQueues) {
    return { type: userConstants.GET_ENABLED_QUEUES_SUCCESS, enabledQueues };
  }
  function failure(error) {
    return { type: userConstants.GET_ENABLED_QUEUES_FAILURE, error };
  }
}
