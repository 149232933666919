// @ts-nocheck
import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import { Input, FormControl, InputLabel } from '@material-ui/core';
import { FindAllQueueRecordsFilter } from '../../../domain/state';
import { documentMask } from '../../../application/shared/utils';
import { formatDate } from '../../../application/shared/adapters';
import { FindAllQueueRecordsStore } from '../../../application/store';
import { PaginationProps } from '../../../application/shared/hooks';
import * as S from './styles';
import { useFormik } from 'formik';
import { FindAllQueueRecordsData } from '../../data';

type Props = {
  findAllQueueRecordsStore: FindAllQueueRecordsStore;
  findAllQueueRecordsData: FindAllQueueRecordsData;
  pagination: PaginationProps;
  reportCsv: JSX.Element;
};

export const Filter = ({
  findAllQueueRecordsStore,
  findAllQueueRecordsData,
  pagination,
  reportCsv,
}: Props) => {
  const {
    handleFilter,
    handleLoading,
    handleError,
    handleErrorMessage,
    handleQueueRecordList,
    handlePages,
    handleTotalPages,
  } = findAllQueueRecordsStore;

  const { setPage, page, offset } = pagination;

  const initialValues: FindAllQueueRecordsFilter = {
    centralId: '',
    name: '',
    lastName: '',
    document: '',
    convenioDescricao: '',
    entrada: '',
  };

  const handleClearFilter = async () => {
    handleFilter(initialValues);
    handleLoading(true);
    handleError(false);
    handleErrorMessage('');
    try {
      const response = await findAllQueueRecordsData.handle(
        initialValues,
        page,
        offset
      );
      handleQueueRecordList(response.data.docs || []);
      handlePages(response.data.pages);
      handleTotalPages(response.data.total);
      handleLoading(false);
      setPage('1');
    } catch (error: any) {
      handleError(true);
      handleErrorMessage(error.response.data.message);
    }
  };

  const handleSubmit = async (values: FindAllQueueRecordsFilter) => {
    handleLoading(true);
    handleError(false);
    handleErrorMessage('');
    try {
      const response = await findAllQueueRecordsData.handle(
        values,
        page,
        offset
      );
      handleQueueRecordList(response.data.docs || []);
      handlePages(response.data.pages);
      handleTotalPages(response.data.total);
      handleLoading(false);
      handleFilter({
        centralId: values.centralId,
        name: values.name,
        lastName: values.lastName,
        document: values.document,
        convenioDescricao: values.convenioDescricao,
        entrada: values.entrada,
      });
      setPage('1');
    } catch (error: any) {
      handleError(true);
      handleErrorMessage(error.response.data.message);
    }
  };

  const form = useFormik({
    initialValues,
    onSubmit: (values: FindAllQueueRecordsFilter) => handleSubmit(values),
  });

  return (
    <S.Form onSubmit={form.handleSubmit}>
      <S.FilterItem>
        <FormControl>
          <InputLabel shrink>ID Central</InputLabel>
          <Input name='centralId' onChange={form.handleChange} />
        </FormControl>
      </S.FilterItem>
      <S.FilterItem>
        <FormControl>
          <InputLabel shrink>Nome</InputLabel>
          <Input name='name' onChange={form.handleChange} />
        </FormControl>
      </S.FilterItem>
      <S.FilterItem>
        <FormControl>
          <InputLabel shrink>Sobrenome</InputLabel>
          <Input name='lastName' onChange={form.handleChange} />
        </FormControl>
      </S.FilterItem>
      <S.FilterItem>
        <FormControl>
          <InputLabel shrink>Documento</InputLabel>
          <Input
            name='document'
            value={documentMask(form.values.document || '')}
            onChange={(e) =>
              form.setFieldValue('document', documentMask(e.target.value))
            }
          />
        </FormControl>
      </S.FilterItem>
      <S.FilterItem>
        <FormControl>
          <InputLabel shrink>Contrato</InputLabel>
          <Input name='convenioDescricao' onChange={form.handleChange} />
        </FormControl>
      </S.FilterItem>
      <S.FilterItem>
        <FormControl>
          <InputLabel shrink>Entrada</InputLabel>
          <Input
            type='date'
            name='entrada'
            onChange={(e) =>
              form.setFieldValue(
                'entrada',
                formatDate(new Date(e.target.value), 'DD/MM/YYYY')
              )
            }
          />
        </FormControl>
      </S.FilterItem>
      <S.FilterItem>
        <IconButton type='submit' color='default' size='small'>
          <SearchIcon />
        </IconButton>
        <IconButton
          size='small'
          type='reset'
          color='default'
          onClick={() => {
            handleClearFilter();
            setPage('1');
            form.resetForm();
          }}
        >
          <CloseIcon />
        </IconButton>
        {/*reportCsv*/}
      </S.FilterItem>
    </S.Form>
  );
};
