import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputText } from './input-text';
import { Label } from './label';
import * as S from './default/styles';
import { ButtonBlueContained } from './button-blue-contained';
import { ButtonDarkerBlueOutlined } from './button-darker-blue-outlined';
import { InputNumber } from './input-number';
import { removeNonNumericCharacters } from './formatters';
import { contingenciaService } from './service';
import { Loading } from './loading';
import { Alert } from './alert';
import { validateEmail } from './validation';
import GenericSelect from '../../GenericSelect';
import {
  phoneRegexFactory,
  phoneInputPatternFactory,
  phonePatternFactory,
} from './factories';
import { acionamentoActions } from '../../../../actions';

export default function FormContingency() {
  const [fieldsValidation, setFieldsValidation] = useState({
    validade: false,
    link_paciente: false,
    email_paciente: false,
    telefone_paciente: false,
    link_medico: false,
    email_medico: false,
    telefone_medico: false,
    queue: false,
  });

  const formatter = (value, mask) => {
    return value
      ? value.replace(phoneRegexFactory(mask), phonePatternFactory(mask))
      : '';
  };

  const [countryPlaceholder, setCountryPlaceholder] = useState('00 00000 0000');
  const [isLoading, setIsLoading] = useState(false);
  const { queues } = useSelector((state) => state.queues);
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    validade: '50 min',
    link_paciente: '',
    email_paciente: '',
    telefone_paciente: '',
    link_medico: '',
    email_medico: '',
    telefone_medico: '',
    idAtendimento: '',
    queue: '',
  });

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name == 'telefone_paciente' || name == 'telefone_medico') {
      const valueWithoutLetters = removeNonNumericCharacters(value);
      value = valueWithoutLetters;
      if (value.length > 11) return;
    }

    if (name == 'validade') {
      value = value.replace(/\D/g, '');
      value += ' min';
    }
    if (name == 'queue' && value != '') {
      setFieldsValidation({ ...fieldsValidation, queue: false });
    }

    setFields({ ...fields, [name]: value });
  };
  const objQueues = (value) => {
    if (value) {
      let activeQueue = [];
      value.map((element) => {
        element.label = element.fila;
        element.value = `${element.idFila}`;
        if (
          ![1, 2, 3, 4, 5, 6, 7, 8, 11, 13, 50, 29, 30].includes(element.idFila)
        )
          activeQueue.push({ ...element });
      });
      return activeQueue;
    }
  };
  const [isError, setError] = useState(false);

  const [isSuccess, setSuccess] = useState(false);

  const [successMessage, setSuccessMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handleChangePlus = (value) => {
    let { validade } = fields;
    validade = validade.replace(/\D/g, '');
    validade = Number(validade);
    validade += 10;
    setFields({ ...fields, validade: `${validade} min` });
  };

  const handleChangeLess = (value) => {
    let { validade } = fields;
    validade = validade.replace(/\D/g, '');
    validade = Number(validade);
    validade -= 10;
    if (validade < 1) validade = '';
    setFields({ ...fields, validade: `${validade} min` });
  };

  const handleBuscarLinks = async () => {
    let { validade, queue } = fields;
    validade = validade.replace(/\D/g, '');
    validade = Number(validade);
    if (!queue)
      return setFieldsValidation({ ...fieldsValidation, queue: true });
    if (!validade)
      return setFieldsValidation({ ...fieldsValidation, validade: true });
    setIsLoading(true);
    const response = await contingenciaService.getURLs(validade, queue);

    if (response?.data) {
      setFields({
        ...fields,
        link_paciente: response.data.pacientLink,
        link_medico: response.data.doctorLink,
        idAtendimento: response.data._id,
      });
      setError(false);
    } else {
      setErrorMessage('Não foi possível gerar links de atendimento');
      setError(true);
    }
    setIsLoading(false);
    setFieldsValidation({ ...fieldsValidation, validade: false, queue: false });
  };

  const sendEmail = async (origem) => {
    let {
      idAtendimento,
      email_paciente,
      email_medico,
      link_medico,
      link_paciente,
      validade,
    } = fields;
    validade = validade.replace(/\D/g, '');
    validade = Number(validade);

    if (origem == 'paciente' && (!link_paciente || link_paciente.length < 11))
      return setFieldsValidation({
        ...fieldsValidation,
        link_paciente: true,
      });
    if (origem == 'medico' && (!link_medico || link_medico.length < 11))
      return setFieldsValidation({
        ...fieldsValidation,
        link_medico: true,
      });

    if (origem == 'paciente' && !email_paciente) {
      return setFieldsValidation({ ...fieldsValidation, email_paciente: true });
    }
    if (origem == 'medico' && !email_medico) {
      return setFieldsValidation({ ...fieldsValidation, email_medico: true });
    }

    if (origem == 'paciente' && validateEmail(email_paciente)) {
      return setFieldsValidation({ ...fieldsValidation, email_paciente: true });
    }

    if (origem == 'medico' && validateEmail(email_medico)) {
      return setFieldsValidation({ ...fieldsValidation, email_medico: true });
    }

    setIsLoading(true);
    const response = await contingenciaService.sendEmail({
      idAtendimento,
      email_paciente,
      email_medico,
      origem,
      link_medico,
      link_paciente,
      validade,
    });

    if (response.data) {
      setSuccessMessage('E-mail enviado com sucesso.');
      setSuccess(true);
      setError(false);
    } else {
      setErrorMessage('Não foi possível enviar o e-mail');
      setError(true);
      setSuccess(false);
    }

    setIsLoading(false);
    if (origem == 'paciente')
      setFieldsValidation({ ...fieldsValidation, email_paciente: false });
    if (origem == 'medico')
      setFieldsValidation({ ...fieldsValidation, email_medico: false });
  };

  const sendSMS = async (origem) => {
    let {
      idAtendimento,
      telefone_paciente,
      telefone_medico,
      link_medico,
      link_paciente,
    } = fields;

    if (origem == 'paciente' && (!link_paciente || link_paciente.length < 11))
      return setFieldsValidation({
        ...fieldsValidation,
        link_paciente: true,
      });
    if (origem == 'medico' && (!link_medico || link_medico.length < 11))
      return setFieldsValidation({
        ...fieldsValidation,
        link_medico: true,
      });

    if (
      origem == 'paciente' &&
      (!telefone_paciente || telefone_paciente.length < 11)
    )
      return setFieldsValidation({
        ...fieldsValidation,
        telefone_paciente: true,
      });
    if (origem == 'medico' && (!telefone_medico || telefone_medico.length < 11))
      return setFieldsValidation({
        ...fieldsValidation,
        telefone_medico: true,
      });
    setIsLoading(true);
    const response = await contingenciaService.sendSMS({
      idAtendimento,
      telefone_paciente,
      telefone_medico,
      origem,
      link_medico,
      link_paciente,
    });

    if (response.data) {
      setSuccessMessage('SMS enviado com sucesso.');
      setSuccess(true);
      setError(false);
    } else {
      setErrorMessage('Não foi possível enviar o SMS');
      setError(true);
      setSuccess(false);
    }

    setIsLoading(false);
    if (origem == 'paciente')
      setFieldsValidation({ ...fieldsValidation, telefone_paciente: false });
    if (origem == 'medico')
      setFieldsValidation({ ...fieldsValidation, telefone_medico: false });
  };

  const clear = () => {
    setFields({
      ...fields,
      validade: '50 min',
      link_paciente: '',
      link_medico: '',
      idAtendimento: '',
      email_medico: '',
      email_paciente: '',
      telefone_medico: '',
      telefone_paciente: '',
      queue: '',
    });
    setFieldsValidation({ ...fieldsValidation, queue: false });
  };

  useEffect(() => {
    dispatch(acionamentoActions.getAllQueuesRequisition());
  }, []);
  return (
    <S.Main>
      {isLoading && <Loading />}
      <S.Form>
        <S.Row gap='15px'>
          <S.Col style={{ width: '25%' }}>
            <Label>Validade</Label>
            <InputNumber
              isWithError={fieldsValidation['validade']}
              plusAction={handleChangePlus}
              lessAction={handleChangeLess}
              name='validade'
              onChange={handleChange}
              value={fields.validade}
              min='1'
              type='text'
              placeholder='min'
            />
          </S.Col>
          <S.Col style={{ width: '63%' }}>
            <Label>Fila de atendimento</Label>
            <GenericSelect
              placeholder='Selecione'
              data={objQueues(queues)}
              name='queue'
              value={fields.queue}
              validation={{
                errors: fieldsValidation['queue'],
                touched: fieldsValidation['queue'],
              }}
              onChange={handleChange}
              color={'1px solid #f00'}
              bgColor='#f1f4f6'
              height={'large'}
              onHover
              opacity={1}
              textColor={'#6b7786'}
            />
          </S.Col>
        </S.Row>
        <S.Row>
          <S.Col
            style={{
              width: '90%',
            }}
          >
            <S.TitleCategory>
              <S.Span>Dados do paciente</S.Span>
            </S.TitleCategory>
          </S.Col>
        </S.Row>
        <S.Row gap='15px' style={{ marginTop: '0px' }}>
          <S.Col
            style={{
              width: '72%',
            }}
          >
            <Label>Link da consulta</Label>
            <InputText
              isWithError={fieldsValidation['link_paciente']}
              disabled={true}
              type='text'
              name='link_paciente'
              onChange={handleChange}
              value={fields.link_paciente}
            />
          </S.Col>

          <S.Col
            style={{
              width: '28%',
            }}
          >
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={() => {
                navigator.clipboard.writeText(fields.link_paciente);
              }}
            >
              Copiar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>

        <S.Row gap='15px'>
          <S.Col
            style={{
              width: '72%',
            }}
          >
            <Label>E-mail</Label>
            <InputText
              isWithError={fieldsValidation['email_paciente']}
              type='text'
              name='email_paciente'
              onChange={handleChange}
              value={fields.email_paciente}
            />
          </S.Col>

          <S.Col
            style={{
              width: '28%',
            }}
          >
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={() => {
                sendEmail('paciente');
              }}
            >
              Enviar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>

        <S.Row gap='15px'>
          <S.Col
            style={{
              width: '72%',
            }}
          >
            <Label>Celular</Label>
            <InputText
              isWithError={fieldsValidation['telefone_paciente']}
              type='tel'
              name='telefone_paciente'
              pattern={phoneInputPatternFactory(countryPlaceholder)}
              onChange={handleChange}
              value={formatter(fields.telefone_paciente, countryPlaceholder)}
            />
          </S.Col>

          <S.Col
            style={{
              width: '28%',
            }}
          >
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={() => {
                sendSMS('paciente');
              }}
            >
              Enviar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>

        <S.Row>
          <S.Col
            style={{
              width: '90%',
            }}
          >
            <S.TitleCategory>
              <S.Span>Dados do profissional</S.Span>
            </S.TitleCategory>
          </S.Col>
        </S.Row>

        <S.Row gap='15px' style={{ marginTop: '0px' }}>
          <S.Col
            style={{
              width: '72%',
            }}
          >
            <Label>Link da consulta</Label>
            <InputText
              isWithError={fieldsValidation['link_medico']}
              disabled={true}
              type='text'
              name='link_medico'
              onChange={handleChange}
              value={fields.link_medico}
            />
          </S.Col>

          <S.Col
            style={{
              width: '28%',
            }}
          >
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={() => {
                navigator.clipboard.writeText(fields.link_medico);
              }}
            >
              Copiar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>

        <S.Row gap='15px'>
          <S.Col
            style={{
              width: '72%',
            }}
          >
            <Label>E-mail</Label>
            <InputText
              isWithError={fieldsValidation['email_medico']}
              type='text'
              name='email_medico'
              onChange={handleChange}
              value={fields.email_medico}
            />
          </S.Col>

          <S.Col
            style={{
              width: '28%',
            }}
          >
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={() => {
                sendEmail('medico');
              }}
            >
              Enviar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>

        <S.Row gap='15px'>
          <S.Col
            style={{
              width: '72%',
            }}
          >
            <Label>Celular</Label>
            <InputText
              isWithError={fieldsValidation['telefone_medico']}
              type='tel'
              name='telefone_medico'
              pattern={phoneInputPatternFactory(countryPlaceholder)}
              onChange={handleChange}
              value={formatter(fields.telefone_medico, countryPlaceholder)}
            />
          </S.Col>

          <S.Col
            style={{
              width: '28%',
            }}
          >
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={() => {
                sendSMS('medico');
              }}
            >
              Enviar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>
        <S.Row>
          <S.Col style={{ width: '90%' }}>
            <ButtonBlueContained
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
              }}
              onClick={handleBuscarLinks}
            >
              Gerar Link
            </ButtonBlueContained>
          </S.Col>
        </S.Row>
        <S.Row>
          <S.Col style={{ width: '90%' }}>
            <ButtonDarkerBlueOutlined
              id='enter-button'
              type='button'
              style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
              }}
              onClick={clear}
            >
              Limpar
            </ButtonDarkerBlueOutlined>
          </S.Col>
        </S.Row>
      </S.Form>

      {isError && (
        <Alert
          message={errorMessage}
          severity='error'
          onClose={() => {
            setError(null);
          }}
        />
      )}

      {isSuccess && (
        <Alert
          message={successMessage}
          severity='success'
          onClose={() => {
            setSuccess(null);
          }}
        />
      )}
    </S.Main>
  );
}
