import React from 'react';
import MenuPainel from '../components/MenuPainel';
import ManualCancelUser from '../components/ManualCancelUser/ManualCancelUser';
import PaperForm from '../components/PaperForm';

import Alert from '../components/Alert';
import { alertActions } from '../actions';

class ManualCancel extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }
  render() {
    const { message, type, open } = this.props;
    return (
      <React.Fragment>
        <MenuPainel stepper='Cancelamentos - B2C'>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
          <PaperForm
            title={''}
            margin={'15px 0'}
            elevation={24}
            componente={<ManualCancelUser />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = ManualCancel;
export { connectedAssinar as ManualCancel };
