import { useState, useEffect } from 'react';

import WorkerBuilder from '../../../../../../worker';

import Worker from '../worker';

import actions from '../../../../../../redux/actions';

import { formatStatusTime } from '../utils';

const useCountdown = (data, onPauseFinish) => {
  const [useLoading, setLoading] = useState(false);
  const [useModal, setModal] = useState(false);
  const [useCountDownValue, setCountdown] = useState(null);

  useEffect(() => {
    const instance = new WorkerBuilder(Worker);

    if (data) {
      setModal(true);
      setCountdown(formatStatusTime(data?.interval));

      instance.postMessage(true);

      instance.onmessage = () => {
        setCountdown(formatStatusTime(data?.interval));
      };
    } else {
      setModal(false);
      setCountdown(null);
      instance.terminate();
    }

    return () => {
      instance.terminate();
      setModal(false);
      setCountdown(null);
    };
  }, [data]);

  const restartStation = async () => {
    setLoading(true);

    try {
      await actions.schedule.servicePause({
        start: false,
        intervalType: data.typeRest,
        reactivateStation: true,
      });
    } catch (error) {
      console.log(error);
    }

    onPauseFinish();
    setCountdown(null);
    setLoading(false);
    setModal(false);
  };

  return {
    useLoading,
    useCountDownValue,
    useModal,
    restartStation,
  };
};

export default useCountdown;
