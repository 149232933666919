import styled, { keyframes, css } from 'styled-components';

import { addAlpha } from '../../../../../../utils/styles';

const getColor = (color) => {
  const colors = {
    default: '#2699fb',
    danger: '#ff2424',
    success: '#33ac2e',
    warning: '#ffb324',
  };

  return colors[color] || colors.default;
};

const fadeOutKeyFrame = keyframes`
  0% { opacity: 1; }
  99% { opacity: 0.01; }
  100% { opacity: 0;; }
`;

export const StyledLayer = styled.div`
  max-width: 300px;
  border-radius: 4px;
  padding: 10px 20px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  background-color: ${({ customColor }) => getColor(customColor)};

  ${({ fadeOut }) =>
    fadeOut &&
    css`
      animation-name: ${fadeOutKeyFrame};
      animation-duration: 0.5s;
      animation-timing-function: forwards;
    `}
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const slideDown = keyframes`
  0% {width: 100%;}  
  100% {width: 0%;}
`;

export const StyledProgress = styled.div`
  height: 5px;
  background: ${() => addAlpha('#ffffff', 0.9)};
  border-radius: 2px;
  box-shadow: 0px 0px 3px white;
  animation-name: ${slideDown};
  animation-duration: ${({ duration }) => `${duration / 1000}s`};
  animation-timing-function: linear;
`;

export const StyledIconCloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -3px;
  margin-left: 10px;
`;
