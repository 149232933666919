import types from './types'

const reducePause = (state = types.states.pause.initial, action) => {
	switch (action.type) {
		case types.states.pause.execute: {
			return {
				...state,
				request: action.data,
				loading: true,
				loaded: false,
				error: false,
			}
		}

		case types.states.pause.success: {
			return {
				...state,
				response: action.data,
				loading: false,
				loaded: true,
				error: false,
			}
		}

		case types.states.pause.error: {
			return {
				...state,
				response: action.data,
				loading: false,
				loaded: true,
				error: true,
			}
		}

		default:
			return state
	}
}

const reduceUnlock = (state = types.states.unlock.initial, action) => {
	switch (action.type) {
		case types.states.unlock.execute: {
			return {
				...state,
				data: { ...action.data },
				loading: true,
				loaded: false,
				error: false,
			}
		}

		case types.states.unlock.success: {
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
			}
		}

		case types.states.unlock.error: {
			return {
				...state,
				data: { ...state.data, ...action.data },
				loading: false,
				loaded: true,
				error: true,
			}
		}

		default:
			return state
	}
}

const reduceRequestUnlock = (state = types.states.requestUnlock.initial, action) => {
	switch (action.type) {
		case types.states.requestUnlock.execute: {
			return {
				...state,
				data: { ...action.data },
				loading: true,
				loaded: false,
				error: false,
			}
		}

		case types.states.requestUnlock.success: {
			return {
				...state,
				loading: false,
				loaded: true,
				error: false,
			}
		}

		case types.states.requestUnlock.error: {
			return {
				...state,
				data: { ...state.data, ...action.data },
				loading: false,
				loaded: true,
				error: true,
			}
		}

		default:
			return state
	}
}

const data = {
	[types.states.pause.execute]: reducePause,
	[types.states.unlock.execute]: reduceUnlock,
	[types.states.requestUnlock.execute]: reduceRequestUnlock,
}

export default data
