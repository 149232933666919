import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import CustomButtom from './CustomButtom';
import Close from '../icons/icon_x_old.svg';
import PropTypes from 'prop-types';
import { LoaderThreePoints } from '../components/Lib/LoaderThreePoints';

const Container = styled.div`
  border-radius: 20px;
  max-width: 850px;
  max-height: 800px;
`;
const ContainerAction = styled.div`
  display: flex;
  justify-content: ${(props) => props.footerpos};
  height: 30px;
  margin: 20px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 220px;
`;

const CustomTitle = styled(DialogTitle)`
  display: flex;
  justify-content: ${(props) => props.titlepos};

  .MuiTypography-h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

const ContainerDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 20px;
  }

  .MuiDialog-paperWidthSm {
    max-width: 850px;
    max-height: 100vh;
  }
`;
const Divider = styled.div`
  border: 1px solid #e1e1e1;
  margin: 5px auto;
  width: 85%;
`;
const CustomClose = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 15px;
`;

const DialogModal = ({
  open,
  title,
  text,
  handleCancel,
  handleConfirm,
  children,
  titlepos,
  footerpos,
  labelCancel,
  labelOk,
  withDivider,
  withClose,
  withActions,
  typeButtomConfirm,
  typeButtomCancel,
  buttomSize,
  fontButtonSize,
  typeModal,
  loading,
  loader,
  disable1,
}) => {
  return (
    <Container>
      <ContainerDialog
        open={open}
        onEnter={() => handleConfirm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {withClose && (
          <CustomClose onClick={() => handleCancel()} id='alert-dialog-title'>
            <img src={Close} alt='close' />
          </CustomClose>
        )}

        <CustomTitle titlepos={titlepos} id='alert-dialog-title'>
          {title}
        </CustomTitle>
        {withDivider && <Divider />}
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {text}
          </DialogContentText>
          {children}
        </DialogContent>
        <ContainerAction footerpos={footerpos}>
          {withActions && (
            <ContainerButton>
              <CustomButtom
                id={'cancel'}
                toBeDisabled={disable1}
                onClick={() => handleCancel()}
                typeButtom={typeButtomCancel}
                fontSize={fontButtonSize}
                size={buttomSize}
              >
                {labelCancel}
              </CustomButtom>

              <CustomButtom
                onClick={() => handleConfirm()}
                typeButtom={typeButtomConfirm}
                fontSize={fontButtonSize}
                size={buttomSize}
              >
                {loading ? (
                  <LoaderThreePoints>{loader}</LoaderThreePoints>
                ) : (
                  labelOk
                )}
              </CustomButtom>
            </ContainerButton>
          )}
        </ContainerAction>
      </ContainerDialog>
    </Container>
  );
};

DialogModal.propTypes = {
  children: PropTypes.node,
  errorText: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  titlepos: PropTypes.string,
  footerpos: PropTypes.string,
  labelCancel: PropTypes.string,
  labelOk: PropTypes.string,
  withDivider: PropTypes.bool,
  withClose: PropTypes.bool,
  withActions: PropTypes.bool,
  typeButtomConfirm: PropTypes.string,
  typeButtomCancel: PropTypes.string,
  buttomSize: PropTypes.string,
  fontButtonSize: PropTypes.string,
};

DialogModal.defaultProps = {
  typeButtom: 'primary',
  buttomSize: 'medium',
  titlepos: 'center',
  footerpos: 'center',
  labelOk: 'Confirmar',
  labelCancel: 'Cancelar',
  fontButtonSize: '10px',
};

export default DialogModal;
