import { useSelector } from 'react-redux';

import { ReCaptchaProvider } from '../../reCaptcha';

import { ChangePassword } from './modal';

interface PasswordStateType {
  password: { openPassword?: boolean };
}

export function ChangePasswordRecaptcha() {
  const isOpen = useSelector(
    (state: PasswordStateType) => state.password?.openPassword
  );

  if (!isOpen) return null;

  return (
    <ReCaptchaProvider>
      <ChangePassword />
    </ReCaptchaProvider>
  );
}
