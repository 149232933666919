// @ts-nocheck
import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import { HttpBodyResendCockpit } from '../../../domain/http';
import { QueueRecordEntity } from '../../../domain/entities';
import { ResendCockpitData } from '../../../application/data';
import { FindAllQueueRecordsStore } from '../../../application/store';
import { Alert } from '../../../application/shared/ui';

type Props = {
  queueRecord: QueueRecordEntity;
  resendCockpitData: ResendCockpitData;
  findAllQueueRecordsStore: FindAllQueueRecordsStore;
};

export const ResendCockpit = ({
  queueRecord,
  resendCockpitData,
  findAllQueueRecordsStore,
}: Props) => {
  const {
    findAllQueueRecordsSelector,
    handleSendCockpitLoading,
    handleSendCockpitError,
    handleSendCockpitErrorMessage,
  } = findAllQueueRecordsStore;

  const selector = findAllQueueRecordsSelector;

  const handleCloseError = () => {
    handleSendCockpitLoading(false);
    handleSendCockpitError(false);
    handleSendCockpitErrorMessage('');
  };

  const handleResendCockpit = async (body: HttpBodyResendCockpit) => {
    try {
      handleSendCockpitLoading(true);
      handleSendCockpitError(false);
      handleSendCockpitErrorMessage('');
      const response = await resendCockpitData.handle(body);
      handleSendCockpitLoading(false);
      return response;
    } catch (error: any) {
      handleSendCockpitLoading(false);
      handleSendCockpitError(true);
      handleSendCockpitErrorMessage(
        'Erro ao fazer o reenvio, tente novamente!'
      );
    }
  };

  return (
    <>
      <Tooltip title='Reenviar acionamento'>
        <IconButton
          color='inherit'
          onClick={async () => {
            return await handleResendCockpit(queueRecord);
          }}
        >
          <SendIcon />
        </IconButton>
      </Tooltip>
      <Alert
        handleClose={handleCloseError}
        open={selector.sendCockpitError}
        message={selector.sendCockpitErrorMessage}
        severity='error'
      />
    </>
  );
};
