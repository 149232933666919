import {
  acionamentoConstants,
  editAcionamentoConstants,
  userConstants,
  countryConstants,
  regionConstants,
  citiesConstants,
  cepConstants,
  conveniosConstantsPortal,
} from '../constants';

import { conveniosConstants } from '../constants';
import { acionamentoVonageService } from '../services';
import { conveniosService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const acionamentoActionsVonage = {
  getAllAcionamentos,
  deleteAcionamentos,
  editAcionamentos,
  editAcionamentosWithoutRedirect,
  getAllConvenios,
  confirmedAcionamentos,
  confirmedAcionamentosNovo,
  getAllCountryAcionamentos,
  getRegionsByCountry,
  getCityByRegions,
  getAddressFromCep,
  paginateAcionamentos,
  generateImageUrl,
  getChannelAccess,
  attendanceAcionamentos,
  getAllConveniosPortal,
  attendanceAcionamentosSuport,
  updateStatusAttendance,
  updateStatusAttendance2,
  inAttendance,
  getCount,
  updateStatusConecta,
  clearAcionamentos,
  updateAcionamentos,
  cancelScheduleAction,
};

async function cancelScheduleAction(
  canceledSchedule,
  successCallback,
  errorCallback
) {
  try {
    await acionamentoVonageService.cancelScheduleRequest(canceledSchedule);

    if (typeof successCallback === 'function') successCallback();
  } catch (err) {
    if (typeof errorCallback === 'function') errorCallback(err?.message);
  }
}

function getCount() {
  return acionamentoVonageService.getCountAcionamentos();
}

function updateStatusAttendance(attInfos) {
  return acionamentoVonageService.updateStatusAttendance(attInfos);
}

// Seta as infos de atendente do acionamento normal
function updateStatusAttendance2(attInfos) {
  return acionamentoVonageService.updateStatusAttendance2(attInfos);
}

function inAttendance(attInfos) {
  return acionamentoVonageService.inAttendance(attInfos);
}

function generateImageUrl(bucketObject = {}) {
  return new Promise((resolve, reject) => {
    acionamentoVonageService
      .getUrlImages(bucketObject)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve({ signedURL: '' });
      });
  });
}

function getAllCountryAcionamentos() {
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .getCountryAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: countryConstants.COUNTRY_REQUEST };
  }
  function success(result) {
    return { type: countryConstants.COUNTRY_SUCCESS, result };
  }
  function failure(error) {
    return { type: countryConstants.COUNTRY_FAILURE, error };
  }
}

function getAddressFromCep(cep) {
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .GetAddressFromCep(cep)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cepConstants.CEP_REQUEST };
  }
  function success(result) {
    return { type: cepConstants.CEP_SUCCESS, result };
  }
  function failure(error) {
    return { type: cepConstants.CEP_FAILURE, error };
  }
}

function getRegionsByCountry(region = 'nd') {
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .getRegionsByCountry(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: regionConstants.REGION_REQUEST };
  }
  function success(result) {
    return { type: regionConstants.REGION_SUCCESS, result };
  }
  function failure(error) {
    return { type: regionConstants.REGION_FAILURE, error };
  }
}

function getCityByRegions(region = 'nda') {
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .getCityByRegions(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: citiesConstants.CITIE_REQUEST };
  }
  function success(result) {
    return { type: citiesConstants.CITIE_SUCCESS, result };
  }
  function failure(error) {
    return { type: citiesConstants.CITIE_FAILURE, error };
  }
}

function editAcionamentosWithoutRedirect(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
  };
  function request() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE };
  }
}

function paginateAcionamentos(pageSize = 5, page = 1, queryString = '') {
  return new Promise((resolve) => {
    acionamentoVonageService
      .paginateAllAcionamentos(pageSize, page, queryString)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        return resolve({
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        });
      });
  });
}

function getAllAcionamentos() {
  return (dispatch) => {
    dispatch(request());

    acionamentoVonageService
      .GetAllAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success(acionamentos) {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS, acionamentos };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function confirmedAcionamentos(acionamento) {
  var tempData = acionamento.birthDate.split('/');
  acionamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .confirmedAcionamentos(acionamento)
      .then(
        (body) => {
          dispatch(success(body));
          history.push('/adm');
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function confirmedAcionamentosNovo(acionamento, setLoading) {
  var tempData = acionamento.birthDate.split('/');
  acionamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .confirmedAcionamentosNovo(acionamento)
      .then(
        (body) => {
          dispatch(success(body));
          setLoading(false);
          acionamento?.new ? history.push('/adm') : history.push('/adm');
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          setLoading(false);
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function attendanceAcionamentos(
  acionamento,
  state,
  setInAttendance,
  setLoading
) {
  return (dispatch) => {
    //dispatch(request());

    acionamentoVonageService
      .attendanceAcionamentos(acionamento)
      .then(
        (body) => {
          window.open(body.attendanceUrl, '_blank');
          if (state) state.setState({ showAttendanceVonage: false });
          setInAttendance(false);
          setLoading && setLoading(false);
          //dispatch(success());
        },
        (error) => {
          //dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(alertActions.error(error.message));
        //dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function attendanceAcionamentosSuport(acionamento, state) {
  return (dispatch) => {
    //dispatch(request());

    acionamentoVonageService
      .attendanceAcionamentos(acionamento)
      .then(
        (body) => {
          window.open(body.attendanceUrl, '_blank');
          //dispatch(success());
        },
        (error) => {
          //dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(alertActions.error(error.message));
        //dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAllConveniosPortal(isEdition) {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConveniosPortal(isEdition)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstantsPortal.CONVENIOS_REQUEST_PORTAL };
  }
  function success(convenios) {
    return {
      type: conveniosConstantsPortal.CONVENIOS_SUCCESS_PORTAL,
      convenios,
    };
  }
  function failure(error) {
    return { type: conveniosConstantsPortal.CONVENIOS_FAILURE_PORTAL, error };
  }
}

function deleteAcionamentos(
  id,
  idAttendanceVonage,
  reason,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  return (dispatch) => {
    dispatch(request());
    acionamentoVonageService
      .deleteAcionamentos(
        id,
        idAttendanceVonage,
        reason,
        urgencyAttendance,
        urgencyAttendenceReason
      )
      .then(
        (body) => {
          dispatch(success(body));
          history.push('/adm');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success() {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function editAcionamentos(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
    acionamento.new
      ? history.push('/adm')
      : history.push('/adm/vonage-acionamento');
  };
  function request() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE };
  }
}

function getAllConvenios() {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }
  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }
  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}

function getChannelAccess() {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }
  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }
  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}

function updateStatusConecta(
  userData,
  status,
  token,
  userAttendance,
  supportNotCompleted = false
) {
  return new Promise((resolve, reject) => {
    acionamentoVonageService
      .updateStatusConecta(
        userData.idAttendanceVonage,
        status,
        token,
        userAttendance,
        supportNotCompleted,
        userData
      )
      .then((body) => {
        resolve(body);
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

function clearAcionamentos() {
  return (dispatch) => {
    dispatch(clear());
  };

  function clear() {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_CLEAR,
      editAcionamento: {},
    };
  }
}

function updateAcionamentos(id, acionamento) {
  return new Promise((resolve, reject) => {
    acionamentoVonageService
      .updateAcionamentos(id, acionamento)
      .then((body) => {
        resolve(body);
      })
      .catch((error) => {
        console.log(error);
      });
  });
}
