import { searchUserConstants } from '../constants';

export function searchUser(state = { searchUser: [] }, action) {
  switch (action.type) {
    case searchUserConstants.SEARCH_USER_SUCCESS:
      return {
        type: 'success',
        searchUser: action.result,
      };
    case searchUserConstants.SEARCH_USER_ERROR:
      return Object.assign({}, state, {
        type: 'error',
        searchUser: [],
      });
    case searchUserConstants.SEARCH_USER_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
