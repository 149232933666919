import { userService } from './user.service';
import { REQUEST_TIMEOUT } from '../constants';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const acionamentoService = {
  GetAllAcionamentos,
  deleteAcionamentos,
  confirmedAcionamentos,
  confirmedAcionamentosNovo,
  getCountryAcionamentos,
  getQueueRequisition,
  getProductsRequisition,
  getRegionsByCountry,
  getCityByRegions,
  GetAddressFromCep,
  paginateAllAcionamentos,
  getUrlImages,
  paginateAllUsers,
  confirmUserRecord,
  changePassword,
  resetPassword,
  verifyEmail,
  sendEmail,
  deleteAcionamentosNew,
  reSendDocuments,
  confirmUserV2,
  paginateUsersV2,
  verifyEmailV2,
  recoverAccountV2,
  changeEmailUserConectaV2,
  getUnitsAndProfessionalsAttendance,
};

function getCountryAcionamentos() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/country`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getQueueRequisition() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/filas`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}

function getProductsRequisition() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/contratos`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}

function getRegionsByCountry(region) {
  let user = window.localStorage.user;
  let regiao = region ? (region.length <= 0 ? 'nd' : region) : 'nd';

  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/states/${regiao}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getCityByRegions(city) {
  let user = window.localStorage.user;
  let cidade = city ? (city.length <= 0 ? 'nda' : city) : 'nda';

  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/cities/${cidade}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetAddressFromCep(cep) {
  const user = window.localStorage.user
    ? JSON.parse(window.localStorage.user)
    : {};

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cep/getAddress?cep=${cep}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function paginateAllAcionamentos(
  pageSize = 5,
  page = 1,
  queryString = '',
  projection,
  pediatria
) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
      projection: projection,
      pediatria: pediatria,
    }),
  };

  return userService

    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/paginate/${pageSize}/${page}&$projection=${projection}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function paginateAllUsers(
  pageSize = 5,
  paginationToken = '',
  queryString = ''
) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: queryString,
      limit: pageSize,
      paginationToken,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/listUsers`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function paginateUsersV2(queryString = '', search) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
      search,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/getUser`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function confirmUserRecord(username = '') {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      username,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/confirmSignUp`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function confirmUserV2(userData) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      userData,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/activateAccount`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function changePassword(username = '') {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      username,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/changePassword`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function verifyEmail(username = '') {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      username,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/verifyEmail`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function verifyEmailV2(id = '') {
  let user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      id,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/confirmEmail`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function changeEmailUserConectaV2(id = '', email) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      id,
      email,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/changeEmailUser`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function resetPassword(username = '') {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      username,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/generatePassword`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function recoverAccountV2(id = '') {
  let user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      id,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cognito/recoverAccount`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getUrlImages(bucketObject = {}) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      bucketInfo: bucketObject,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/documents/url`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetAllAcionamentos() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/getall`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function deleteAcionamentos(
  id,
  reason,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  let { _id, email, nome, login, telefone } = user.user;
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      reason,
      cancelUser: { _id, email, nome, login, telefone },
      urgencyAttendance,
      urgencyAttendenceReason,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/delete/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function deleteAcionamentosNew(
  id,
  reason,
  pacientData,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  let { _id, email, nome, login, telefone } = user.user;
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      reason,
      cancelUser: { _id, email, nome, login, telefone },
      urgencyAttendance,
      idAttendanceVonage: pacientData.idAttendanceVonage
        ? pacientData.idAttendanceVonage
        : '',
      urgencyAttendenceReason,
      pacientData,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/delete/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function confirmedAcionamentos(acionamento) {
  let user = window.localStorage.user;
  user = JSON.parse(user);

  var {
    email,
    name,
    socialName,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    regiao,
    pais,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    disruptiveBehavior,
    cabine,
    docRemovedBy,
    emailSecondary,
    document,
    isVonage,
  } = acionamento;
  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      disruptiveBehavior: disruptiveBehavior,
      createDate: createDate,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      incompany: incompany,
      name: name,
      socialName: socialName,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: document,
      documentType:
        nacionalidade === 'B' || 'Brasileira' || 'BRASILEIRA'
          ? 'CPF'
          : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
      },
      deviceData: deviceData,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      docRemovedBy,
      emailSecondary: emailSecondary,
      cabine: cabine,
      isVonage: isVonage,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/confirmed/${acionamento._id}`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}

// --- Confirmed pegando atributos do novo acionamento por enquanto fica aqui comentado --- //
function confirmedAcionamentosNovo(acionamento) {
  const user = JSON.parse(window.localStorage.user);

  let { waitingForDateSchedule } = acionamento.currentEditionAttendanceInfo;

  const {
    isVonage,
    idCockpit,
    idAttendanceVonage,
    email,
    email2,
    name,
    socialName,
    lastName,
    document,
    birthDate,
    nationality,
    gender,
    phone,
    phone2,
    rg,
    partnerID,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    state,
    country,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    disruptiveBehavior,
    cabine,
    docRemovedBy,
    emailSecondary,
    convenioDescricao,
    descricaoProduto,
    _id,
    streetCep,
    idFila,
    all,
    sendCockPit,
    processing,
    userProducts,
    centralId,
    currentEditionAttendanceInfo,
    encounterHealthOperator,
    encounterPlan,
    isPatientEligible,
    purchaseCode,
  } = acionamento;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      email2: email2,
      disruptiveBehavior: disruptiveBehavior,
      createDate: createDate,
      convenio: convenio,
      descricaoProduto: descricaoProduto,
      convenioDescricao: convenioDescricao,
      incompany: incompany,
      name: name,
      socialName: socialName,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: document,
      documentType:
        nationality == 'B' ||
        nationality == 'Brasileira' ||
        nationality == 'BRASILEIRA'
          ? 'CPF'
          : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      userProducts: userProducts,
      phone: phone,
      phone2: phone2,
      gender: gender,
      partnerID: partnerID,
      nationality: nationality,
      naturalness: null,
      city: city,
      state: state,
      country: country,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      streetCep: streetCep,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
      },
      deviceData: deviceData,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      docRemovedBy,
      emailSecondary: email2,
      cabine: cabine,
      _id: _id,
      isVonage: isVonage,
      idAttendanceVonage: idAttendanceVonage,
      idCockpit: idCockpit,
      waitingForDateSchedule: waitingForDateSchedule,
      idFila: idFila,
      all: all,
      sendCockPit: sendCockPit,
      processing: processing,
      centralId: centralId,
      currentEditionAttendanceInfo: currentEditionAttendanceInfo,
      encounterHealthOperator,
      encounterPlan,
      isPatientEligible: isPatientEligible === 'sim',
      purchaseCode,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/confirmed/${acionamento._id}`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}

function sendEmail(name, lastName, socialName, email, email2, url) {
  let user = window.localStorage.user;
  user = JSON.parse(user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email,
      email2,
      name,
      lastName,
      socialName,
      url,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/sendLink`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((err) => {
      console.log('Email não enviado.', err);
    });
}

function reSendDocuments(acionamento) {
  const user = JSON.parse(window.localStorage.user);

  var {
    isVonage,
    idCockpit,
    idAttendanceVonage,
    email,
    email2,
    name,
    socialName,
    lastName,
    document,
    birthDate,
    nationality,
    gender,
    phone,
    phone2,
    rg,
    partnerID,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    state,
    country,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments,
    deviceData,
    disruptiveBehavior,
    cabine,
    docRemovedBy,
    emailSecondary,
    convenioDescricao,
    descricaoProduto,
    _id,
    streetCep,
  } = acionamento;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      email: email,
      email2: email2,
      disruptiveBehavior: disruptiveBehavior,
      createDate: createDate,
      convenio: convenio,
      descricaoProduto: descricaoProduto,
      convenioDescricao: convenioDescricao,
      incompany: incompany,
      name: name,
      socialName: socialName,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: document,
      documentType:
        nationality == 'B' ||
        nationality == 'Brasileira' ||
        nationality == 'BRASILEIRA'
          ? 'CPF'
          : 'PASSAPORTE',
      rg: rg,
      birthDate: birthDate,
      phone: phone,
      phone2: phone2,
      gender: gender,
      partnerID: partnerID,
      nationality: nationality,
      naturalness: null,
      city: city,
      state: state,
      country: country,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      streetCep: streetCep,
      confirmationUser: {
        _id: user.user._id,
        email: user.user.email,
        nome: user.user.nome,
        login: user.user.login,
        telefone: user.user.telefone,
      },
      deviceData: deviceData,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments,
      docRemovedBy,
      emailSecondary: email2,
      cabine: cabine,
      _id: _id,
      isVonage: isVonage,
      idAttendanceVonage: idAttendanceVonage,
      idCockpit: idCockpit,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/resend`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}

function getUnitsAndProfessionalsAttendance() {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/unitsOfAttendance`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}
