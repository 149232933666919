import moment from 'moment';
import { queues } from './config';

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format('DD-MM-YYYY HH:MM');
  return buildDate;
};

/**
 * Function returning the actual date like "2021-02-04"
 */
export const getFormatedDate = () => {
  let objDate = new Date();
  let day = objDate.getDate().toString().padStart(2, '0');
  let month = (objDate.getMonth() + 1).toString().padStart(2, '0'); //+1 pois no getMonth Janeiro começa com zero.
  let year = objDate.getFullYear();
  return year + '-' + month + '-' + day;
};

/**
 * Function to scan array of objects looking for duplicates
  @param arr array of objects
  @param prop key of the object that will serve as a reference for comparison
  @return object without repeats and with counter with number of repeats
  
   */

export const countAndSingle = (arr, prop) => {
  const accumulated =
    arr &&
    arr.reduce(
      (acum, o) => {
        const key = JSON.stringify(o[prop]);
        acum.ctrl[key] = acum.ctrl[key] || { ...o, count: 0 };
        if (acum.ctrl[key].count++ === 0) acum.list.push(acum.ctrl[key]);
        return acum;
      },
      { ctrl: {}, list: [] }
    ).list;

  return accumulated;
};

/**
 * Function to transform object do array
  @param obj minor date
  @return array
   */
export const objectToarray = (obj) => Object.values(obj);

export const actualAfterOneDate = (date) => {
  const actualDate = moment();
  const selectedDate = moment(date).format('DD/MM/YYYY HH:mm');
  if (!selectedDate.includes('Data inválida')) {
    let days = moment(date).diff(actualDate, 'days');
    return days > 0;
  }
  return false;
};

/**
 * Function to compare dates of days after another
  @param before minor date
  @param after bigger date
  @return bolean
   */

export const compareIsAfter = (lowest, biggest) => {
  let actualDate = moment(lowest).format('YYYY-MM-DD');
  let selectedDate = moment(biggest).format('YYYY-MM-DD');
  if (
    !selectedDate.includes('Data inválida') &&
    !actualDate.includes('Data inválida')
  )
    return moment(selectedDate).isAfter(actualDate, 'day');
};

/**
 * Function to compare dates of days after another and same day 
  @param before minor date
  @param after bigger date
  @return bolean
   */

export const compareIsSameOrAfter = (lowest, biggest) => {
  let actualDate = moment(lowest).format('YYYY-MM-DD');
  let selectedDate = moment(biggest).format('YYYY-MM-DD');
  if (
    !selectedDate.includes('Data inválida') &&
    !actualDate.includes('Data inválida')
  )
    return moment(selectedDate).isSameOrAfter(actualDate, 'day');
};

/**
 * Function to execute some function pass in callback after time passed and removes istself from memory
 * useful also with autocompletes consuming api 
  @param callback function !
  @param wait time in miliseconds to wait 
  @return bolean
   */

//todo pass more args
export const debounce = (callback, wait) => {
  const timer = setTimeout(() => {
    callback();
  }, wait);
  //removes timer from memory
  return () => clearTimeout(timer);
};

export const isoToDatePTBR = (str) => moment(str).utc().format('DD/MM/YYYY');
export const format = (str) =>
  moment(str).utc().local().format('HH:mm - DD/MM/YYYY');
export const formatSemHora = (str) =>
  moment(str).utc().local().format('DD/MM/YYYY');
export const formatDateHour = (str) =>
  moment(str).utc().local().format('DD/MM/YYYY - HH:mm');
export const addDaysToDate = (date, days) => moment(date).add(days, 'day');
export const addDaysToActualDate = (days) => moment().add(days, 'day');
export const objectKeysToString = (obj) => Object.keys(obj).toString();
export const objectIsEmpty = (obj) => Object.keys(obj).length === 0;
export const stringSplit = (str, char, index) => str.split(char)[index];

export const isoToDate = (str) => moment(str).utc().format('YYYY-MM-DD H:mm ');

//stackoverflow.com/questions/13146418/find-all-the-days-in-a-month-with-date-object

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */

export const getDaysInMonth = (month, year) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const formateDatesPerMonth = (dates) =>
  dates &&
  dates.map((element) => {
    return moment(element).format('YYYY-MM-DD');
  });

export const objDate = () => {
  let objDate = new Date();
  objDate.date = objDate.getDate();
  objDate.month = objDate.getMonth();
  objDate.year = objDate.getFullYear();
  return objDate;
};
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

//Função para inverter a formatação de data para integrar no componente de busca da queuePage

export const invertDateToMaskSearch = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

/**
 * @param {obj} Object to detect if is dotNotation
 * @return {boolean} returns true if is dotNotation reference
 */

export const isDotNotation = (obj) => {
  for (const objectPath in obj) {
    const parts = objectPath.split('.');
    return parts.length > 1;
  }
};

/**
 * @param {obj} Object to transform dotNotation in Nested Object
 * @return {object} returns true if is dotNotation reference
 */

export const dotToNestedObj = (obj) => {
  const result = {};
  for (const objectPath in obj) {
    const parts = objectPath.split('.');

    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    target[parts[0]] = obj[objectPath];
  }

  return result;
};

export const dotToNestedArr = (obj) => {
  const result = {};
  for (const objectPath of obj) {
    const parts = objectPath.split('.');

    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    target[parts[0]] = obj[objectPath];
  }

  return result;
};

/**
 * @param {value} value to evaluate is object or not
 * @return {boolean} returns true if is object reference
 * verify se is null or undefined and verify typeof object and if is array too because typeof array result in object
 */

export const isOnlyObject = (value) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value));
};

export const isObjectOrArray = (value) => {
  return !!((value && typeof value === 'object') || Array.isArray(value));
};

/**
 * Function to update original object with part of other object
 * @param {originalObject} Object to be updated
 * @param {objectB} Object to be updated
 * @return {object} Object with updates
 * Function searches all keys of object and compares if objectB contains keys if true update value with value of object updated an call again "recursive"
 *  delete keys found until objectB is empty finally function
    todo  test verify with removed field socket

*/

export const findNestedObjectAndUpdate = (
  originalObject = {},
  objectB = {}
) => {
  if (isOnlyObject(originalObject)) {
    for (const prop in originalObject) {
      for (const propB in objectB) {
        if (propB === prop) {
          if (isObjectOrArray(objectB[propB])) {
            originalObject[prop] = {
              ...originalObject[prop],
              ...objectB[propB],
            };

            delete objectB[propB];
          } else {
            originalObject[prop] = objectB[propB];
            delete objectB[propB];
          }
          findNestedObjectAndUpdate(originalObject, objectB);
        }

        if (objectIsEmpty(objectB)) {
          return originalObject;
        }
      }
    }
  }
  return originalObject;
};

/**
 * Function to update original object with part of other object
 * @param {originalObject} Object to be updated
 * @param {objectB} Object to be updated
 * @return {object} Object with updates
 * Function searches all keys of object and compares if objectB contains keys if true delete and keep object empty value with value of object updated an call again "recursive"
 *  delete keys found until objectB is empty finally function
    todo  test verify with removed field socket

*/

export const findNestedObjectAndRemove = (
  originalObject = {},
  objectB = {}
) => {
  if (isOnlyObject(originalObject)) {
    for (const prop in originalObject) {
      for (const propB in objectB) {
        if (propB === prop && isOnlyObject(prop)) {
          findNestedObjectAndRemove(prop, objectB);
        } else if (propB === prop) {
          delete originalObject[prop];
          originalObject[prop] = {};
          delete objectB[propB];
        }

        if (objectIsEmpty(objectB)) return originalObject;
      }
    }
  }
  return originalObject;
};

/**
 * @param {obj} Object to contains props to return inside
 * @param {patch} Object to contains path to return value inside
 * @return {string} returns string value
 */

export const valueNestedProp = (object, path = '') => {
  if (
    !isOnlyObject(object) ||
    objectIsEmpty(object) ||
    stringIsNullOrUndefined(path)
  )
    return '';
  const parts = path.split('.');
  return parts.reduce((object, key) => object?.[key], object);
};

/**
 * @param {param} string  to  evaluate
 * @return {boolean} returns bollean value
 */

export const stringIsNullOrUndefined = (param) =>
  param === undefined || param === null || param === '';

/**
 * Function verify if exists array inside another array
 *  @param {param} array to  evaluate
 * @param {param} array  to  evaluate
 * @return {boolean} returns bollean value
 */

export const arrayInAnotherArray = (a1, a2) => a1.some((el) => a2.includes(el));

export const getNavigatorInfos = (window) => {
  let nAgt = window.navigator.userAgent;
  let browserName = window.navigator.appName;
  let fullVersion = '' + parseFloat(window.navigator.appVersion);
  let majorVersion = parseInt(window.navigator.appVersion, 10);
  let nameOffset, verOffset, ix;
  let OSName = 'Unknown OS';

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = window.navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) != -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(window.navigator.appVersion);
    majorVersion = parseInt(window.navigator.appVersion, 10);
  }

  if (window.navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
  if (window.navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
  if (window.navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
  if (window.navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';

  return {
    browserName: browserName,
    fullVersion: fullVersion,
    majorVersion: majorVersion,
    appName: window.navigator.appName,
    userAgent: window.navigator.userAgent,
    so: OSName,
  };
};

export const sortElementByCreateDate = (array) => {
  return array.sort((acionamentoA, acionamentoB) => {
    return (
      new Date(acionamentoA.createDate).getTime() -
      new Date(acionamentoB.createDate).getTime()
    );
  });
};

export const pushQueue = (attendanceType) => {
  return queues[attendanceType];
};

export const createObjectForAttendanceUpdate = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const timeAttendance = new Date(
    moment.tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
  );
  const currentEditionAttendanceInfo = {
    userName: user.user.name,
    attendances: [{ userId: user.user.id, userName: user.user.name }],
    dateStarted: timeAttendance,
    attendanceType: 'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
  };
  return currentEditionAttendanceInfo;
};
