import httpClient from '../../../utils/httpClient';

const resource = 'acionamentos';

const getOne = async (id) => {
  const response = await httpClient.GET(`${resource}/${id}`);

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const sendMail = async (body) => {
  const response = await httpClient.POST(`email`, { body });

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const updateOne = async (id, body) => {
 const user = JSON.parse(window?.localStorage?.user);
 const userEdition = JSON.parse(window?.localStorage?.userEdition)
 
 body.confirmationUser = {
        _id: user?.user?._id,
        email: user?.user?.email,
        nome: user?.user?.nome,
        login: user?.user?.login,
        telefone: user?.user?.telefone,
        editionTime:userEdition?.editionTime,
  }

  const response = await httpClient.PUT(`${resource}/${id}`, { body });

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const changeTriggerStatus = async (body) => {
  const response = await httpClient.POST(
    `servicoVonage/adm/${resource}/updatestatus`,
    { body }
  );

  try {
    return response.ok
      ? {
          status: true,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

export default {
  changeTriggerStatus,
  getOne,
  updateOne,
  sendMail,
};
