import { useState, useCallback, useEffect } from 'react';

type BlankFunction<T extends any> = (...args: T[]) => any;

export interface IUseDebounce<T extends any> {
  debounceTime?: number;
  action: BlankFunction<T>;
  onCancelDebounce?: BlankFunction<T>;
}

export const useDebounce = <T extends any>({
  debounceTime = 300,
  action,
  onCancelDebounce,
}: IUseDebounce<T>) => {
  type Args = Parameters<BlankFunction<T>>;

  const [timeoutValue, setTimeoutValue] = useState<NodeJS.Timeout | null>(null);
  const [isCalling, setIsCalling] = useState<boolean>(false);

  // create timeout logic
  const debounceCallback = useCallback(
    async (...args: Args) => {
      setIsCalling(true);
      await action(...args);

      const timeout = setTimeout(() => {
        setIsCalling(false);
      }, debounceTime);

      setTimeoutValue(timeout);
    },
    [action, debounceTime]
  );

  // clear timeout when component unmount
  useEffect(() => {
    return () => {
      if (timeoutValue) clearTimeout(timeoutValue);
    };
  }, [timeoutValue]);

  // execute action by debounce
  const handleDebounce = useCallback(
    (...args: Args) => {
      if (isCalling) {
        if (onCancelDebounce) onCancelDebounce(...args);

        return;
      }

      debounceCallback(...args);
    },
    [debounceCallback, isCalling]
  );

  return {
    handleDebounce,
    isCalling,
  };
};
