import styled from 'styled-components';
import { styled as styledMui } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

import banner_cad from '../../images/img/Banner.png';

export const Content = styled.div`
  background: url(${banner_cad});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 99vh;
  width: 100%;
  @media only screen and (max-width: 900px) {
    background-position: 'center';
    background-repeat: 'no-repeat';
    background-size: 'cover';
    width: '100%';
  } ;
`;
export const Logo = styled.div`
  cursor: pointer;
  margin-top: 10px;
  & img {
    height: 42px;
  }
`;
export const PaperPage = styledMui(Paper)({
  borderRadius: '20px',
  maxWidth: '650px',
  margin: '0 auto',
  padding: '0 0 20px 0',
});

export const PaperForm = styledMui(Paper)({
  borderRadius: '20px',
  height: '380px',
  maxWidth: '300px',
  margin: 'auto',
  marginTop: '80px',
  padding: '10px',
});

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 800;
  padding: 20px;
  text-align: center;
`;
