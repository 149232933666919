import { useEffect, useState } from 'react';

const usePatientObservations = (data, onSave, trigger) => {
  const [useObsInEdition, setObsInEdition] = useState(null);
  const [useObsData, setObsData] = useState([...data]);
  const [useInputNewObs, setInputNewObs] = useState('');

  useEffect(() => {
    setObsData([...data]);
  }, [data]);

  const handleChange = (text, i) => {
    const newObsData = [...useObsData];

    newObsData[i] = text;

    setObsData(newObsData);
  };

  const handleEdit = (i) => {
    setObsInEdition(i);
    setObsData(data);
  };

  const handleDelete = (i) => {
    const newObsData = [...useObsData];

    newObsData.splice(i, 1);

    onSave([...newObsData], 'delete');
  };

  const saveChanges = () => {
    onSave([...useObsData], 'update');
    setObsInEdition(null);
  };

  const handleInputNewObsChange = (e) => {
    setInputNewObs(e.target.value);
  };

  const handleAdd = () => {
    if (!useInputNewObs.length) {
      trigger({
        message: 'Preencha o campo de observações corretamente.',
        type: 'danger',
        duration: 3000,
      });

      return;
    }

    const newObsData = [...useObsData];

    newObsData.push(useInputNewObs);

    onSave([...newObsData], 'insert');
    setInputNewObs('');
  };

  return {
    saveChanges,
    useObsInEdition,
    useObsData,
    setObsData,
    handleEdit,
    handleChange,
    handleDelete,
    useInputNewObs,
    handleInputNewObsChange,
    handleAdd,
  };
};

export default usePatientObservations;
