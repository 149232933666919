import React from 'react';

import InputMask from 'react-input-mask';

import { FadeIn } from '../../../../context';

import { CustomDivider, CustomText, CustomLoader } from '../../..';

import { cepMaxLength, styles, customBorderStyles, types } from './constants';

import useInput from './hooks/useInput';

import {
  StyledContainer,
  StyledInputValue,
  StyledInputDataContainer,
  StyledTextareaValue,
} from './styles';

const Input = ({
  title = '',
  name = '',
  type = 'text',
  style = 'solo',
  disabled,
  value,
  onChange,
  onRequestCallback,
  placeholder,
  full,
  mask,
  hasError = false,
  error,
}) => {
  const customInputStyle = hasError
    ? customBorderStyles.error
    : customBorderStyles[style] || customBorderStyles.solo;

  const inputType = types[type] || 'text';

  const { handleChange, useIsLoading } = useInput(
    onChange,
    onRequestCallback,
    type
  );

  const componentType = {
    textarea: (
      <StyledTextareaValue
        error={hasError}
        name={name}
        full={full}
        disabled={disabled}
        border={customInputStyle}
        type={inputType}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...(type === 'CEP' && {
          maxLength: cepMaxLength,
        })}
        rows={3}
      ></StyledTextareaValue>
    ),
    time: (
      <InputMask mask='99:99' onChange={handleChange} value={value}>
        {() => (
          <StyledInputValue
            error={hasError}
            name={name}
            full={full}
            disabled={disabled}
            border={customInputStyle}
            style={{ ...styles }}
          />
        )}
      </InputMask>
    ),
    default: mask ? (
      <InputMask value={value} mask={mask} onChange={handleChange} maskChar=' '>
        {() => (
          <StyledInputValue
            error={hasError}
            name={name}
            full={full}
            disabled={disabled}
            border={customInputStyle}
            type={inputType}
            placeholder={placeholder}
          />
        )}
      </InputMask>
    ) : (
      <StyledInputValue
        error={hasError}
        name={name}
        full={full}
        disabled={disabled}
        border={customInputStyle}
        type={inputType}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...(type === 'CEP' && {
          maxLength: cepMaxLength,
        })}
      ></StyledInputValue>
    ),
  };

  const getCurrentComponent = () =>
    componentType[inputType] || componentType.default;

  return (
    <StyledContainer full={full}>
      {title !== '' && (
        <>
          <StyledInputDataContainer>
            <CustomText>{title}</CustomText>
            {useIsLoading && <CustomLoader />}
          </StyledInputDataContainer>
          <CustomDivider space={0} />
        </>
      )}

      {getCurrentComponent()}

      {hasError && error && (
        <FadeIn visible={hasError && error}>
          <CustomText style={{ height: 20 }} color='danger'>
            {error}
          </CustomText>
        </FadeIn>
      )}
    </StyledContainer>
  );
};

export default Input;
