import styled from 'styled-components';

export const Container = styled.div`
  width: 624px;
  box-sizing: border-box;
  display: flex;

  .titles {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #424242;
  }
`;

export const ContainerResume = styled.div`
  width: 313px;
  height: 274px;
  margin: 30px auto;

  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const ItensContainer = styled.div`
  margin: 10px 60px;
  display: flex;
  flex-direction: column;
`;

export const Group = styled.div`
  min-height: 100px;
  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #424242;
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #424242;
  }
`;
