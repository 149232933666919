import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const agendamentoService = {
  getSlots,
};

async function getSlots(date, specialty, state) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/schedule/slots?searchDate=${date}&specialty=${specialty}&state=${state}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}
