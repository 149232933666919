export const isAccessAuthorized = () => {
  const allowedRoles = ['Gestor', 'Suporte', 'Administrativo', 'Assistencial'];

  const userLocalStorage = localStorage.getItem('user');

  if (!userLocalStorage) {
    return false;
  }

  const userData = JSON.parse(userLocalStorage);

  return allowedRoles.includes(userData.user.id_perfil.descricao);
};

export const isRoleAuthorized = (roles) => {
  const userLocalStorage = localStorage.getItem('user');

  if (!userLocalStorage) {
    return false;
  }

  const userData = JSON.parse(userLocalStorage);

  return roles.includes(userData.user.id_perfil.descricao);
};
