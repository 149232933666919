import styled, { css } from 'styled-components';

interface TextProps {
  $as?:
    | 'p'
    | 'legend'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'span'
    | 'label'
    | 'div';
  $color?: string;
  $size?: string;
  $weight?: string;
  $align?: string;
  $lineHeight?: string;
  $textTransform?: string;
}
export const Text = styled('div').attrs<TextProps>(({ $as }) => ({
  as: $as || 'p',
}))<TextProps>`
  ${({ $color, $size, $weight, $align, $lineHeight, $textTransform }) => css`
    color: ${$color || '#000'};
    font-size: ${$size || '16px'};
    font-weight: ${$weight || '400'};
    text-align: ${$align || 'left'};
    line-height: ${$lineHeight || '16px'};
    text-transform: ${$textTransform || 'none'};
  `}
`;