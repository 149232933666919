import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const atividadesService = {
  GetListActivities,
  JSONDownloadActivities,
};

function JSONDownloadActivities(dataInicioCsv, dataFimCsv, page) {
  let user = JSON.parse(window.localStorage.user);
  dataInicioCsv = dataInicioCsv.replace(/\//g, '-');
  dataFimCsv = dataFimCsv.replace(/\//g, '-');

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/atividades/json/${dataInicioCsv}/${dataFimCsv}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetListActivities(page, qtd, search) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({ search }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/atividades/fila/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
