import {
  notificationsConstants,
  notificationsSaveConstants,
  getIdNotificationsConstants,
  changeActiveConstants,
} from '../constants/index';
import {
  notificationsService,
  getNotificationsService,
  filaComunicadosService,
} from '../services/index';

export const actionNotification = {
  Notifications,
  NotificationsSave,
  getIdNotifications,
  changeActive,
  NotificationsWithoutRedux,
  getIdNotificationsWithoutRedux,
};

function NotificationsWithoutRedux(user) {
  return getNotificationsService.getNotifications(user);
}

function getIdNotificationsWithoutRedux(data) {
  return notificationsService.getIdNotifications(data);
}

function Notifications(user) {
  return (dispatch) => {
    dispatch(request());
    getNotificationsService
      .getNotifications(user)
      .then((body) => {
        dispatch(success(body));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: notificationsConstants.NOTIFICATIONS_REQUEST };
  }

  function success(notifications) {
    return {
      type: notificationsConstants.NOTIFICATIONS_SUCCESS,
      notifications,
    };
  }

  function failure(error) {
    return { type: notificationsConstants.NOTIFICATIONS_FAILURE, error };
  }
}

function NotificationsSave(data, propRef) {
  return (dispatch) => {
    dispatch(request());
    notificationsService
      .Notifications(data)
      .then((body) => {
        propRef.notificationsUpdates(propRef.userLogin, propRef.refThisOrigin);
        dispatch(success(body));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: notificationsSaveConstants.NOTIFICATIONS_SAVE_REQUEST };
  }

  function success(save) {
    return {
      type: notificationsSaveConstants.NOTIFICATIONS_SAVE_SUCCESS,
      save,
    };
  }

  function failure(error) {
    return {
      type: notificationsSaveConstants.NOTIFICATIONS_SAVE_FAILURE,
      error,
    };
  }
}

function getIdNotifications(data) {
  return (dispatch) => {
    dispatch(request());
    notificationsService
      .getIdNotifications(data)
      .then((body) => {
        dispatch(success(body));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: getIdNotificationsConstants.GET_ID_NOTIFICATIONS_REQUEST };
  }

  function success(notificationsId) {
    return {
      type: getIdNotificationsConstants.GET_ID_NOTIFICATIONS_SUCCESS,
      notificationsId,
    };
  }

  function failure(error) {
    return {
      type: getIdNotificationsConstants.GET_ID_NOTIFICATIONS_FAILURE,
      error,
    };
  }
}

function changeActive(data, value, stateRef) {
  return filaComunicadosService
    .changeActiveNotifications(data, value)
    .then((body) => {
      stateRef.tableRef.current.onQueryChange();
    })
    .catch((err) => {
      console.log(err);
    });
}
