import React from 'react';

import styled from 'styled-components';

import { addAlpha } from '../../../utils/styles';

import { rootStyle, types, colors } from './constants';

const getColor = (color) => {
  return types[color] || types.info;
};

const buttonStyles = {
  default: (color) => ({
    ...rootStyle,
    background: color,
    color: colors.white,
    border: `1px solid ${color}`,
  }),
  outlined: (color) => ({
    ...rootStyle,
    background: 'unset',
    border: `1px solid ${color}`,
    color,
  }),
  solo: (color) => ({
    ...rootStyle,
    background: 'unset',
    color,
  }),
};

const mountButton = (color, schema) => {
  const defaultButtonStyle = 'default';

  const buttonColor = getColor(color);

  const buttonStyle = buttonStyles[schema];

  return buttonStyle
    ? buttonStyles[schema](buttonColor)
    : buttonStyles[defaultButtonStyle](buttonColor);
};

const getSizes = (size) => {
  const sizes = {
    small: {
      'font-size': '12px',
      padding: '5px 15px',
    },
    medium: {
      'font-size': '14px',
      padding: '8px 18px',
    },
    large: {
      'font-size': '16px',
      padding: '10px 30px',
    },
  };

  return sizes[size] || { ...medium };
};

export const StyledButton = styled.button`
  border-radius: ${({ rounded }) => `${rounded ? 4 : 0}px;`};
  ${({ size }) => getSizes(size)};
  ${({ $color, schema }) => mountButton($color, schema)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease-out;

  &:hover {
    ${({ schema, $color, disabled }) =>
      disabled
        ? ''
        : schema === 'default'
        ? {
            background: addAlpha(getColor($color), 0.6),
          }
        : {
            background: addAlpha(getColor($color), 0.15),
          }}
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: ${({ full }) => (full ? '100%' : 'max-content')};
`;

export const StyledContainerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: width 0.5s ease-out;
  flex-direction: ${({ iconDir }) => (iconDir === 'r' ? 'row-reverse' : 'row')};
`;
