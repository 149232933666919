// @ts-nocheck
import React, { useEffect } from 'react';
import {
  FindAllQueueRecordsStore,
  ReportQueueRecordsStore,
  ShowQueueRecordsStore,
} from '../../../application/store';
import {
  FindAllQueueRecordsData,
  ReportQueueRecordsData,
  ResendCockpitData,
} from '../../../application/data';
import { AlertComponent } from './alert-component';
import { QueueRecordTable } from './table';
import { ShowModal } from './show-modal';
import { Pagination } from './pagination';
import { Filter } from './filter';
import { ReportCsv } from './report-csv';
import { usePagination } from '../../../application/shared/hooks';
import { Container, CssBaseline } from '@material-ui/core';
import * as S from './styles';
import { TemplatePage } from '../../shared/ui';
import MenuPainel from '../../../../components/MenuPainelHistorico';
import { FindAllQueueRecordsFilter } from '../../../domain/state';

type Props = {
  findAllQueueRecordsStore: FindAllQueueRecordsStore;
  findAllQueueRecordsData: FindAllQueueRecordsData;
  showQueueRecordsStore: ShowQueueRecordsStore;
  reportQueueRecordsStore: ReportQueueRecordsStore;
  reportQueueRecordsData: ReportQueueRecordsData;
  resendCockpitData: ResendCockpitData;
};

export const QueueRecordList = ({
  findAllQueueRecordsStore,
  findAllQueueRecordsData,
  showQueueRecordsStore,
  reportQueueRecordsStore,
  reportQueueRecordsData,
  resendCockpitData,
}: Props) => {
  const pagination = usePagination();

  const { handleFilter } = findAllQueueRecordsStore;

  const initialValues: FindAllQueueRecordsFilter = {
    centralId: '',
    name: '',
    lastName: '',
    document: '',
    convenioDescricao: '',
    entrada: '',
  };

  useEffect(() => {
    return () => handleFilter(initialValues);
  }, []);

  return (
    <>
      <CssBaseline />
      <AlertComponent findAllQueueRecordsStore={findAllQueueRecordsStore} />
      <TemplatePage>
        <MenuPainel>
          <Container maxWidth='xl'>
            <S.Card>
              <Filter
                findAllQueueRecordsStore={findAllQueueRecordsStore}
                findAllQueueRecordsData={findAllQueueRecordsData}
                pagination={pagination}
                reportCsv={
                  <ReportCsv
                    reportQueueRecordsStore={reportQueueRecordsStore}
                    reportQueueRecordsData={reportQueueRecordsData}
                  />
                }
              />
              <QueueRecordTable
                findAllQueueRecordsStore={findAllQueueRecordsStore}
                findAllQueueRecordsData={findAllQueueRecordsData}
                showQueueRecordsStore={showQueueRecordsStore}
                resendCockpitData={resendCockpitData}
                pagination={pagination}
                paginationComponent={
                  <Pagination
                    pagination={pagination}
                    findAllQueueRecordsStore={findAllQueueRecordsStore}
                  />
                }
              />
              <ShowModal showQueueRecordsStore={showQueueRecordsStore} />
            </S.Card>
          </Container>
        </MenuPainel>
      </TemplatePage>
    </>
  );
};
