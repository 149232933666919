import { ActionPassageEditConstants } from '../constants';

export function editPAcionamento(state = { editPAcionamento: {} }, action) {
  switch (action.type) {
    case ActionPassageEditConstants.PAACIONAMENTO_EDIT_SUCCESS:
      return {
        type: 'success',
        editPAcionamento: action.editData,
      };
    case ActionPassageEditConstants.PAACIONAMENTO_EDIT_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        editPAcionamento: {},
      });
    case ActionPassageEditConstants.PAACIONAMENTO_EDIT_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
