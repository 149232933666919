import styled from 'styled-components'


type InputProps = {
  isWithError: string
}

export const divAlign = styled.div`
display:inline
`

export const InputNumber = styled.input<InputProps>`
  height: 38px;
  padding: 0 15px;
  border: none;
  outline: none;
  border: 1px solid #Dfe3e8;
  width:110px;
  ${props => props.isWithError && `
  border: 1px solid #f00;
  `}

  border-radius: 4px;
  color: #6B7786;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  font: normal normal normal 14px/15px Inter;

  :focus {
    border-color: #2699FB;
    box-shadow: 0 0 6px #3381ee33;
    cursor: text;
  }
  padding: 10px 30px;
  display: inline-flex;
  * {
  box-sizing: border-box;
  }
`

export const LabelImg = styled.img`
cursor: pointer;
width:15px;
height:15px;
color:#378FF7;
background-color:#DFE3E8;
position: absolute;
margin-top: 13px;
margin-left:10px;
border-radius:10px
`

export const LabelImgPlus = styled.img`
cursor: pointer;
width:15px;
height:15px;
color:#378FF7;
background-color:#DFE3E8;
position: absolute;
border-radius:10px;
margin-top: 13px;
margin-left:-30px;
`