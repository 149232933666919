import {
  getDiff,
  addDuration,
  isHourBetween,
  isHourAfter,
  isHourBefore,
  formatDate,
} from '../../../../utils/format';

import moment from 'moment';

const generateDateBasedOnHour = (start, hour) => {
  const dayInit = formatDate(start, 'DD');

  const hourPeriod = moment();

  const [hours, min] = hour.split(/:/);

  hourPeriod.set('date', dayInit);
  hourPeriod
    .hours(parseInt(hours))
    .minutes(parseInt(min))
    .seconds(0)
    .milliseconds(0);

  return hourPeriod;
};

export const calculatePeriod = (start, end, hour) => {
  const defaultPeriod = 60;

  const fullPeriod = {
    width: '100%',
    left: '0%',
  };

  const professionalInit = formatDate(start, 'YYYY-MM-DD HH:mm:ss');
  const professionalFinish = formatDate(end, 'YYYY-MM-DD HH:mm:ss');

  const periodInit = formatDate(
    generateDateBasedOnHour(start, hour),
    'YYYY-MM-DD HH:mm:ss'
  );
  const periodFinish = formatDate(
    addDuration(periodInit, 1),
    'YYYY-MM-DD HH:mm:ss'
  );

  const startDateIsOnPeriod = isHourBetween(
    professionalInit,
    periodInit,
    periodFinish,
    '[]'
  );
  const endDateIsOnPeriod = isHourBetween(
    professionalFinish,
    periodInit,
    periodFinish,
    '[)'
  );

  if (startDateIsOnPeriod) {
    if (endDateIsOnPeriod) {
      const periodDistanceEnd = getDiff(periodFinish, end);
      const periodDistanceStart = getDiff(periodFinish, start);

      const periodWidth = (periodDistanceEnd / defaultPeriod) * 100;
      const periodLeft = 100 - (periodDistanceStart * 100) / defaultPeriod;

      return {
        width: `${periodWidth.toFixed(2)}%`,
        left: `${periodLeft.toFixed(2)}%`,
      };
    }

    const period = getDiff(periodFinish, start);

    const periodWidth = (period / defaultPeriod) * 100;
    const periodLeft = 100 - (period * 100) / defaultPeriod;

    return {
      width: `${periodWidth.toFixed(2)}%`,
      left: `${periodLeft.toFixed(2)}%`,
    };
  }

  if (endDateIsOnPeriod) {
    const periodDistanceEnd = getDiff(periodFinish, end);
    const periodWidth = 100 - (periodDistanceEnd / defaultPeriod) * 100;

    return {
      width: `${periodWidth.toFixed(2)}%`,
      left: `${0}%`,
    };
  }

  return fullPeriod;
};

export const hasPeriod = (start, end, hour) => {
  const professionalInit = formatDate(start, 'YYYY-MM-DD HH:mm:ss');
  const professionalFinish = formatDate(end, 'YYYY-MM-DD HH:mm:ss');

  const periodInit = formatDate(
    generateDateBasedOnHour(start, hour),
    'YYYY-MM-DD HH:mm:ss'
  );

  const periodFinish = formatDate(
    addDuration(periodInit, 1),
    'YYYY-MM-DD HH:mm:ss'
  );

  const startDateIsOnPeriod = isHourBetween(
    professionalInit,
    periodInit,
    periodFinish,
    '[)'
  );
  const finishDateIsOnPeriod = isHourBetween(
    professionalFinish,
    periodInit,
    periodFinish,
    '(]'
  );

  const professionalStartIsBeforePeriod = isHourBefore(
    professionalInit,
    periodInit
  );
  const professionalStartIsAfterPeriod = isHourAfter(
    professionalFinish,
    periodFinish
  );

  const isOnPeriod =
    professionalStartIsAfterPeriod && professionalStartIsBeforePeriod;

  return startDateIsOnPeriod || finishDateIsOnPeriod || isOnPeriod;
};

export const displayHour = (h) => `${moment(h, 'HH').format('HH')}h`;
