import { loaderConstants } from '../constants';
export function loaderEditReducer(state = { loaderEdit: {} }, action) {
  switch (action.type) {
    case loaderConstants.LOADER_START:
      return {
        type: 'start',
        loaderEdit: action.edit,
      };
    case loaderConstants.LOADER_STOP:
      return {
        type: 'stop',
        loaderEdit: action.edit,
      };
    default:
      console.debug('notification reducer :: hit default', action.type);
      return {
        type: 'stop',
        loaderEdit: false,
      };
  }
}
