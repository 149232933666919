import { historicoConstants, cockpitConstants } from '../constants';
import { alertActions, historicoLoader } from './';
import { historicoService } from '../services';
import { history } from '../helpers';

export const historicoActions = {
  getAllHistoricos,
  filterAllHistoricos,
  sendToCockpit,
  downloadCsv,
  downloadJson,
};

function sendToCockpit(rowData, list, historicScope) {
  return (dispatch) => {
    dispatch(request);
    dispatch(historicoLoader.request());
    historicoService
      .SendToCockpit(rowData)
      .then(
        (body) => {
          dispatch(alertActions.success('Registro Reenviado ao Cockpit'));
          historicScope.filterRows(
            historicScope.page,
            historicScope.itemByPage
          );
          dispatch(historicoLoader.success());
        },
        (error) => {
          dispatch(alertActions.error(error.message));
          if (list) dispatch(failure(list));
          dispatch(historicoLoader.error());
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(historicoLoader.error());
      });
  };
  function request() {
    return { type: cockpitConstants.COCKPIT_REQUEST };
  }
  function success(historicos) {
    return { type: cockpitConstants.HISTORICO_SUCCESS, historicos };
  }
  function failure(historicos) {
    return { type: historicoConstants.HISTORICO_SUCCESS, historicos };
  }
}

function downloadCsv(dataInicioCsv, dataFimCsv) {
  return (dispatch) => {
    dataInicioCsv = dataInicioCsv.replace(/\//g, '-');
    dataFimCsv = dataFimCsv.replace(/\//g, '-');
    var a = document.createElement('a');
    a.style = 'display: none';
    a.href = `${process.env.REACT_APP_API_CONECTA}/servico/historico/downloadcsv/${dataInicioCsv}/${dataFimCsv}`;
    a.download = 'historico.csv';
    a.click();
  };
}

function downloadJson(dataInicioCsv, dataFimCsv, page) {
  return historicoService.DownloadJSON(dataInicioCsv, dataFimCsv, page);
}

function getAllHistoricos(page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request);
    dispatch(historicoLoader.request());
    historicoService
      .GetAllHistoricos(page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
          dispatch(historicoLoader.success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          dispatch(historicoLoader.error());
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: historicoConstants.HISTORICO_REQUEST };
  }
  function success(historicos) {
    return { type: historicoConstants.HISTORICO_SUCCESS, historicos };
  }
  function failure(error) {
    return { type: historicoConstants.HISTORICO_FAILURE, error };
  }
}

function filterAllHistoricos(fields, page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(historicoLoader.request());

    let fieldsJson = Object.keys(fields);
    let needClear = false;
    fieldsJson.map((res) => {
      fields[res].trim();
      if (fields[res] != '') needClear = true;
      return res;
    });

    if (needClear)
      dispatch(
        success({
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 1,
        })
      );

    dispatch(request);
    historicoService
      .GetFilterHistoricos(fields, page, qtd)
      .then(
        (body) => {
          dispatch(success(body));
          dispatch(historicoLoader.success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          dispatch(historicoLoader.error());
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: historicoConstants.HISTORICO_REQUEST };
  }
  function success(historicos) {
    return { type: historicoConstants.HISTORICO_SUCCESS, historicos };
  }
  function failure(error) {
    return { type: historicoConstants.HISTORICO_FAILURE, error };
  }
}
