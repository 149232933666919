import styled from 'styled-components';
import { ErrorMessage } from 'formik';

export const ContentForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  margin: auto;
  width: 50%;
`;

export const ContainerTable = styled.div`
  margin: auto;
  width: 85%;
`;

export const TitleForm = styled.h1`
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
`;

export const Error = styled.h5`
  font-size: 10px;
  color: red;
`;
export const ErrorMessageRed = styled(ErrorMessage)`
  color: red;
`;
