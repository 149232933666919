import styled from 'styled-components';

export const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 30px 45px;
  text-align: center;
`;

export const ContainerInput = styled.div`
  display: flex;
  min-width: 400px;
  margin: 80px 0 30px;
  .MuiInputAdornment-root {
    margin-right: 20px;
  }

  .MuiInputBase-input {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }
`;
export const ContainerButton = styled.div`
  margin: 10px;
  height: 35px;
  justify-content: space-between;
  img {
    width: 15px;
    margin: 0 10px 0 0;
  }
`;

export const ContainerGeneric = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ContainerModal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
  width: 458px;

  > div {
    text-align: center;
    font-size: 17px;
    padding: 30px;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: 'center';
  height: 200px;
`;

export const SearchResult = styled.div`
width:100%
display:flex;
flex-direction:row;
flex-wrap: wrap;

`;
export const AccordionContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;
export const Dependentes = styled.div`
width:100%
display:flex;
flex-wrap:wrap;


`;
export const Convenios = styled.div`
width:100%
display:flex;
flex-wrap: wrap;

`;
export const Historico = styled.div`
  width: 100%;
`;
