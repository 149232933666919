import { historicoConstants } from '../constants';

export function historico(
  state = { historicos: { docs: [], limit: 5, page: 1, pages: 1, total: 0 } },
  action
) {
  switch (action.type) {
    case historicoConstants.HISTORICO_SUCCESS:
      return {
        type: 'success',
        historicos: action.historicos,
      };
    case historicoConstants.HISTORICO_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    case historicoConstants.HISTORICO_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
        historicos: {
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        },
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return Object.assign({}, state, {
        type: 'request',
        historicos: {
          ...state.historicos,
        },
      });
  }
}
