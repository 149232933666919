import React from 'react';
import MenuPainel from '../components/MenuPainel';
import { FilaSuporte } from '../components/FilaSuporte';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import PropTypes from 'prop-types';
import { alertActions } from '../actions';
import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';

class Suporte extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }
  render() {
    const { message, type, open } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ChangePassword />
        <MenuPainel stepper='Fila Atendimento Suporte'>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<FilaSuporte />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { message, type, open } = state.alert;
  return {
    message,
    type,
    open,
  };
}

Suporte.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(Suporte);
export { connectedRegisterPage as Suporte };
