import React from 'react';
import { Container } from './styles';

const RowCustomDashed = ({
  customHeight,
  borderColor,
  children,
  align,
  justify,
}) => {
  return (
    <Container
      customHeight={customHeight}
      borderColor={borderColor}
      align={align}
      justify={justify}
    >
      {children}{' '}
    </Container>
  );
};

export default RowCustomDashed;
