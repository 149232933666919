import React from 'react';
import {
  Route,
  unstable_HistoryRouter as Router,
  Routes,
} from 'react-router-dom';
import { history } from './helpers';
import withClearCache from './ClearCache';
import { PrivateRoute } from './components';
import {
  AdmNew,
  Acionamento,
  AcionamentoEditarSuporte,
  ActivitiesList,
  Cancelamentos,
  Comunicados,
  Suporte,
  AcionamentoVonage,
  AcionamentoNew,
  FilaPortalConectaAdm,
  AcionamentoSupport,
  Contingencia
} from './Adm';
import { AdmAgendamento } from './AdmAgendamento';
import { Historicoedit } from './Historicoedit';
import { Actionpassage } from './Actionpassage';
import { Painel } from './Painel';
import { PaAcionamentosList } from './Paacionamentoslist';
import { Cancelamento } from './Adm/Cancelamento';
import { HistoricoSuport } from './HistoricoSuporte';
import { AdmDesvinculo } from './AdmDesvinculo';
import { SearchCpf } from './SearchCpf';
import { AdmResponsavelFinanceiro } from './AdmResponsavelFinanceiro';
import {
  AdmAcionamentoTelepsicologia,
  AdmFilaTelepsicologia,
} from './AdmAgendamentoTelePsiciologia';

import {
  EditScale,
  EmployeeScales,
  ManagerScales,
  RegisterScale,
  LockStation,
} from './Spread/pages/Administrator';

import { PrivateRouter } from './Spread/router';

import { Notifications } from './Spread/modules';

import { isAccessAuthorized } from './Spread/middlewares/access';

import { Login } from './Login';
import { LoginRobot } from './LoginRobot';
import ReactGA from 'react-ga4';

import { MakeQueueRecordList } from '../src/clean-arch/main/factories/pages';
import { Historicolist } from '../src/Historicolist';
import { EisteinClinicAndCareForm } from './components/Lib/Forms/FormAdm/views';

const keyGA = process.env.REACT_APP_API_GA_KEY
  ? process.env.REACT_APP_API_GA_KEY
  : '';

const ClearContingency = withClearCache(Contingencia);
const ClearAdmNew = withClearCache(AdmNew);
const ClearPainel = withClearCache(Painel);
const ClearAcionamento = withClearCache(Acionamento);
const ClearAcionamentoEditarSuporte = withClearCache(AcionamentoEditarSuporte);
const ClearAcionamentoSupport = withClearCache(AcionamentoSupport);
const ClearActivitiesList = withClearCache(ActivitiesList);
const ClearCancelamentos = withClearCache(Cancelamentos);
const ClearComunicados = withClearCache(Comunicados);
const ClearSuporte = withClearCache(Suporte);
const ClearAcionamentoVonage = withClearCache(AcionamentoVonage);
const ClearAcionamentoNew = withClearCache(AcionamentoNew);
const ClearFilaPortalConectaAdm = withClearCache(FilaPortalConectaAdm);
const ClearAdmAgendamento = withClearCache(AdmAgendamento);
const ClearHistoricoedit = withClearCache(Historicoedit);
const ClearActionpassage = withClearCache(Actionpassage);
const ClearPaAcionamentosList = withClearCache(PaAcionamentosList);
const ClearCancelamento = withClearCache(Cancelamento);
const ClearAdmDesvinculo = withClearCache(AdmDesvinculo);
const ClearSearchCpf = withClearCache(SearchCpf);
const ClearAdmResponsavelFinanceiro = withClearCache(AdmResponsavelFinanceiro);
const ClearAdmAcionamentoTelepsicologia = withClearCache(
  AdmAcionamentoTelepsicologia
);
const ClearAdmFilaTelepsicologia = withClearCache(AdmFilaTelepsicologia);
const ClearLogin = withClearCache(Login);
const ClearRobot = withClearCache(LoginRobot);
const ClearMakeQueueRecordList = withClearCache(MakeQueueRecordList);
const ClearHistoricoSuport = withClearCache(HistoricoSuport);
const ClearHistoricolist = withClearCache(Historicolist);

const authCollection = {
  adm: [
    'Gestor',
    'Gestor da Unidade de Negócio',
    'Suporte',
    'Suporte Técnico',
    'Administrativo',
    'Assistencial',
  ],
  suporte: ['Gestor', 'Gestor da Unidade de Negócio', 'Suporte'],
  cancelment: ['Gestor', 'Gestor da Unidade de Negócio', 'Suporte'],
  assistencial: ['Assistencial'],
  convenios: ['Empresa - Administrativo'],
  medico: ['Médico'],
  contingency:[
  'Contingência',
  'Gerador de Link', 
  //'Gestor', 
  //'Gestor da Unidade de Negócio',
  //'Suporte'
  ]
};

localStorage.setItem('totalAttendance', '0');
localStorage.setItem('playalert', 'true');
localStorage.setItem('totalAttendancesupport', '0');
localStorage.setItem('playalertsupport', 'false');
if (keyGA) ReactGA.initialize(keyGA);
console.log('keyGA', keyGA);

export const RoutesRoot = () => {
  const hist: any = history;

  return (
    <Router history={hist}>
      {isAccessAuthorized() && <Notifications />}

      <Routes>
       
        <Route
          path='/adm/lock-station'
          element={
            <PrivateRouter component={LockStation} roles={['Administrativo']} />
          }
        />

        <Route
          path='/adm/scales'
          element={
            <PrivateRouter
              component={ManagerScales}
              roles={['Suporte', 'Gestor', 'Gestor da Unidade de Negócio']}
            />
          }
        />

        <Route
          path='/adm/scales/me'
          element={
            <PrivateRouter
              component={EmployeeScales}
              roles={['Administrativo']}
            />
          }
        />
        <Route
          path='/adm/scales/manager'
          element={
            <PrivateRouter
              component={RegisterScale}
              roles={['Suporte', 'Gestor', 'Gestor da Unidade de Negócio']}
            />
          }
        />
        <Route
          path='/adm/scales/:_id/edit'
          element={
            <PrivateRouter
              component={EditScale}
              roles={['Suporte', 'Gestor', 'Gestor da Unidade de Negócio']}
            />
          }
        />

        <Route path='/' element={<ClearLogin />} />
        {process.env.REACT_APP_ENVIROMENT_CAPTCHA && (
          <Route path='/robot' element={<ClearRobot />} />
        )}
        <Route path='/login' element={<ClearLogin />} />
        <Route
          path='/adm'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAdmNew />
            </PrivateRoute>
          }
        />
        <Route
          path='/contingencia'
          element={
            <PrivateRoute auth={authCollection.contingency}>
              <ClearContingency />
            </PrivateRoute>
          }
        />

        <Route
          path='/historico'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearMakeQueueRecordList />
            </PrivateRoute>
          }
        />
        <Route
          path='/historico/antigo'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearHistoricolist />
            </PrivateRoute>
          }
        />
        <Route
          path='/Painel'
          element={
            <PrivateRoute auth={authCollection.convenios}>
              <ClearPainel />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/search'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearSearchCpf />
            </PrivateRoute>
          }
        />
        <Route
          path='/agendamento'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAdmAgendamento />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/acionamento'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAcionamento />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/acionamento/new'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAcionamentoNew history={history} />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/acionamento/new/edit'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAcionamentoNew />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/desvinculo'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAdmDesvinculo />
            </PrivateRoute>
          }
        />
        <Route
          path='/edit/historico'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearHistoricoedit />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/atividades'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearActivitiesList />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/responsavelfinanceiro'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearAdmResponsavelFinanceiro />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/comunicados'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearComunicados />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/comunicados/history'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearComunicados />
            </PrivateRoute>
          }
        />
        <Route
          path='/suporte'
          element={
            <PrivateRoute auth={authCollection.suporte}>
              <ClearSuporte />
            </PrivateRoute>
          }
        />
        <Route
          path='/suporte/edit'
          element={
            <PrivateRoute auth={authCollection.suporte}>
              <ClearAcionamentoEditarSuporte />
            </PrivateRoute>
          }
        />
        <Route
          path='/suporte/edit/new'
          element={
            <PrivateRoute auth={authCollection.suporte}>
              <ClearAcionamentoSupport />
            </PrivateRoute>
          }
        />
        <Route
          path='/historico/suporte'
          element={
            <PrivateRoute auth={authCollection.suporte}>
              <ClearHistoricoSuport />
            </PrivateRoute>
          }
        />
        <Route
          path='/portalConecta'
          element={
            <PrivateRoute auth={authCollection.suporte}>
              <ClearFilaPortalConectaAdm />
            </PrivateRoute>
          }
        />
        <Route
          path='/canc/ativos'
          element={
            <PrivateRoute auth={authCollection.cancelment}>
              <ClearCancelamentos />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/fila/telepsicologia'
          element={
            <PrivateRoute auth={authCollection.adm}>
              <ClearCancelamentos />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/agenda/telepsicologia'
          element={
            <PrivateRoute auth={authCollection.medico}>
              <ClearAdmFilaTelepsicologia />
            </PrivateRoute>
          }
        />
        <Route
          path='/adm/fila/acionamento-telepsicologia'
          element={
            <PrivateRoute auth={authCollection.medico}>
              <ClearAdmAcionamentoTelepsicologia />
            </PrivateRoute>
          }
        />
        <Route
          path='/cancelamentos'
          element={
            <PrivateRoute auth={authCollection.cancelment}>
              <ClearCancelamento />
            </PrivateRoute>
          }
        />
        <Route
          path='/teletriagem'
          element={
            <PrivateRoute auth={authCollection.assistencial}>
              <ClearPaAcionamentosList />
            </PrivateRoute>
          }
        />
        <Route
          path='/edit/teletriagem'
          element={
            <PrivateRoute auth={authCollection.assistencial}>
              <ClearActionpassage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};
