import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonLink = styled(Link)`
  text-decoration: none;
  font-size: 10px;
  width: 95%;
  height: 36px;
  color: #212121;
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
`;
