import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  margin-top: 0px;
  margin-bottom: 20px;
  border: 1px dashed ${({ borderColor }) => borderColor};
  height: ${({ customHeight }) => ` ${customHeight}px`};
`;
