import React, { useState } from 'react';
import {
  CardContainer,
  Span,
  Span2,
  H2,
  P,
  ContainerLinks,
} from './styled/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { formatSemHora } from '../../utils/utils';

const Card = (props) => {
  let [check, setCheck] = useState(false);

  function setChecked() {
    setCheck((check = check ? false : true));
    props.handleHock(check);
  }

  function openLink(url) {
    url = url.match(/^https?:/) || url.match(/^http?:/) ? url : '//' + url;
    window.open(url, '_blank');
  }
  return (
    <>
      <CardContainer>
        <Span>Enviado - {formatSemHora(props.startDate)}</Span>
        <H2>{props.title}</H2>
        <Span2>{props.text}</Span2>
        {props.links.length > 1 && (
          <ContainerLinks>
            {props.links[0].link.length > 1 && (
              <Span>Para mais informações :</Span>
            )}
            {props.links.map((data, index) => {
              if (data.link.length > 1) {
                return (
                  <P>
                    <a
                      href='#'
                      onClick={() => {
                        openLink(data.link);
                      }}
                    >
                      {data.link.substring(0, 45)}...
                    </a>
                  </P>
                );
              }
            })}
          </ContainerLinks>
        )}
        <FormControlLabel
          className='checkBox'
          control={
            <Checkbox
              disabled={check}
              onChange={() => setChecked()}
              color='default'
              inputProps={{
                'aria-label': 'checkbox with default color',
              }}
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
            />
          }
          label='Marcar como lido'
        />
      </CardContainer>
    </>
  );
};
Card.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default Card;
