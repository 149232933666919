import React from 'react';
import { ContainerTable, ContainerLoading } from './styles';
import { CircularProgress } from '@material-ui/core';
import { formatToCNPJ, formatToPhone } from 'brazilian-values';

export const CustomTable = ({
  message,
  width = '100%',
  hasContract,
  object,
  loading,
}) => {
  const { id_oferta, contrato, telefone } = object;

  return (
    <>
      {loading ? (
        <ContainerLoading>
          {' '}
          <CircularProgress color='primary' />
        </ContainerLoading>
      ) : (
        <ContainerTable hasContract={hasContract}>
          {hasContract === false ? (
            <div>{message}</div>
          ) : (
            <table width={width} border='1' cellSpacing='0' cellPadding='0'>
              <tbody>
                <tr>
                  <td
                    width='295px'
                    align='center'
                    style={{
                      border: ' 1px solid #6c6c6c',
                      height: '60px',
                    }}
                  >
                    <span className='title-table'>CNPJ</span>
                  </td>
                  <td width='295' align='center'>
                    <span className='title-table'>ID Contrato</span>
                  </td>
                  <td width='295' align='center'>
                    <span className='title-table'>Telefone</span>
                  </td>
                </tr>

                <tr>
                  <td
                    align='center'
                    className='text-table'
                    style={{ height: '60px' }}
                  >
                    {contrato ? formatToCNPJ(contrato) : ''}
                  </td>
                  <td align='center' className='text-table'>
                    {id_oferta ? id_oferta : ''}
                  </td>
                  <td align='center' className='text-table'>
                    {telefone ? telefone : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </ContainerTable>
      )}
    </>
  );
};
