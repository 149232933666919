//Todo Create palete
const primary = '#2699fb'; //também fonte color atendimento 2699fb
const white = '#fff';
const danger = '#FF2424';
const hardOnHold = '#FF8720';
const onHold = '#FFB324';
const pending = '#ff5a5a';
//const light = '#F1F4F6';
const info = '#e3ebf2';
const green = '#33AC2E';
const greenLight = '#7ADC30';
const blue = '#0000ff';
const purple = '#6C6C94';
const fontColorPrymaryLight = '#6B7786';
const pendingLight = '#ffeded';

const defaultBorderRadius = '4px';
const roundedBorderRadius = '50px';

const padding = {
  small: '10px 14px',
  medium: '10px 30px',
  large: '10px 30px',
};

const imageSizes = {
  small: '14px',
  medium: '16px',
  large: '18px',
};

const fontSizes = {
  small: '12px',
  medium: '14px',
  large: '16px',
};

interface ColorVariant {
  background: string;
  border: string;
  borderRadius: string;
  color: string;
  backgroundHover: string;
  colorHover: string;
  backgroundActive: string;
}

const colors = {
  primary: {
    background: primary,
    borderRadius: defaultBorderRadius,
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    font: 'normal normal medium 0.875em/1.375em Inter',
    backgroundActive: '',
  },

  primaryPurple: {
    background: purple,
    borderRadius: defaultBorderRadius,
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    font: 'normal normal medium 0.875em/1.375em Inter',
    backgroundActive: '',
  },

  primaryHighlight: {
    background: '#FFF',
    borderRadius: defaultBorderRadius,
    color: primary,
    border: `solid 1px ${primary}`,
    opacity: '0.8',
    font: 'normal normal medium 0.875em/1.375em Inter',
    backgroundActive: '',
    backgroundHover: primary,
    colorHover: '#FFF',
  },

  lightDashed: {
    background: white,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: 'gray',
    border: `dashed 2px ${fontColorPrymaryLight}`,
    borderRadius: defaultBorderRadius,
    opacity: '1',
    backgroundActive: info,
    backgroundHover: primary,
    colorHover: '#FFF',
  },

  dangerHighlight: {
    background: '#FFF',
    borderRadius: defaultBorderRadius,
    color: danger,
    border: `solid 1px ${danger}`,
    opacity: '0.8',
    font: 'normal normal medium 0.875em/1.375em Inter',
    backgroundActive: '',
    backgroundHover: danger,
    colorHover: '#FFF',
  },

  danger: {
    background: danger,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    borderRadius: defaultBorderRadius,
    backgroundActive: '',
  },

  dangerRounded: {
    background: danger,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: white,
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  onHold: {
    background: onHold,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: white,
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  hardOnHold: {
    background: hardOnHold,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: white,
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  greyRounded: {
    background: '#F1F4F6',
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#6B7786',
    border: 'solid 1px #CCC',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  blueRounded: {
    background: '#E3E6F6',
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#485CC1',
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  lightBlueRounded: {
    background: '#DFF0FE',
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#319EFB',
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  //Todo button ghost
  lightRounded: {
    background: white,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: fontColorPrymaryLight,
    border: 'solid 1px #CCC',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  lightBlue: {
    background: white,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: blue,
    border: 'solid 1px #0000ff',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  Blue: {
    background: blue,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: white,
    border: 'solid 1px #0000ff',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  dangerLightRounded: {
    background: '#FFF',
    borderRadius: roundedBorderRadius,
    color: danger,
    border: `solid 1px ${danger}`,
    opacity: '0.8',
    font: 'normal normal medium 0.875em/1.375em Inter',
    backgroundActive: '',
  },

  light: {
    background: white,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: fontColorPrymaryLight,
    border: `solid 1px ${fontColorPrymaryLight}`,
    borderRadius: defaultBorderRadius,
    opacity: '1',
    backgroundActive: info,
  },

  greenRounded: {
    background: green,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  greenLightRounded: {
    background: greenLight,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  purpleLightRounded: {
    background: purple,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: roundedBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },

  green: {
    background: green,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: defaultBorderRadius,
    opacity: '1',
    backgroundActive: '',
  },
  gray: {
    background: white,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#8a9098',
    border: '1px  solid #8a9098',
    borderRadius: defaultBorderRadius,
    backgroundActive: '',
  },
  gray2: {
    background: white,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#8a9098',
    border: 'none',
    borderRadius: defaultBorderRadius,
    backgroundActive: '',
  },
  info: {
    background: info,
    font: 'normal normal medium 0.875em/1.375em Inter',
    color: '#FFFFFF',
    border: 'none',
    opacity: '1',
    borderRadius: defaultBorderRadius,
    backgroundActive: '',
  },

  pendingLightRounded: {
    background: pendingLight,
    borderRadius: roundedBorderRadius,
    color: pending,
    border: `solid 1px ${pendingLight}`,
    opacity: '0.8',
    font: 'normal normal medium 14px/22px Rubik',
    fontWeight: 'bold',
    backgroundActive: '',
  },
} as unknown as Record<string, ColorVariant>;

export type ColorsVariant = keyof typeof colors & string;
export type PaddingVariant = keyof typeof padding & string;
export type ImageSizesVariant = keyof typeof imageSizes & string;
export type FontSizesVariant = keyof typeof fontSizes & string;

export const variants = {
  padding,
  colors,
  imageSizes,
  fontSizes,
};
