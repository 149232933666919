import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Icon = styled.div`
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

export const ContainerDashed = styled.div`
  width: '100%';
  margin-top: 0px;
`;

export const ContainerField = styled.div`
  display: flex;
  justify-content: center;
  min-width: 105px;
  margin-left: 7px;
  margin-right: 7px;
`;
export const ContainerLoader = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CustomizedTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 15px;
  position: relative;
  white-space: nowrap;
  tbody tr th:first-child span {
    margin-left: -115px;
  }

  tr td span {
    padding-left: 5px;
    padding-right: 5px;
    color: #5a6a7e;
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

interface TableColSpacingProps {
  $vAlign: string;
  $borderTypeColor?: string;
  $isId?: boolean;
}
export const TableColSpacing = styled.td<TableColSpacingProps>`
  ${({ $borderTypeColor, $isId, $vAlign }) => css`
    padding: 0 0.25em;
    vertical-align: ${$vAlign};
    border-left: ${$borderTypeColor};
    ${$isId && `border-left: ${$borderTypeColor};`}

    & > span {
      display: block;
    }
  `}
`;

interface TrCustomProps {
  $isHover: boolean;
  $opacity: number;
}
export const TrCustom = styled.tr<TrCustomProps>`
  ${({ $isHover, $opacity }) => css`
    transition: all 0.4s ease;
    text-align: center;
    height: 75px;
    background-color: white;
    opacity: 0.2;

    ${(props) =>
      !$isHover &&
      css`
        div {
          cursor: default;
        }
        button {
          cursor: default;
        }
      `};

    ${$isHover &&
    css`
      transition: all 0.8s ease;
      &&:hover {
        font-weight: 500;
        cursor: pointer;

        td span {
          color: #000;
        }
      }
    `};

    opacity: ${$opacity};
  `}
`;

interface ThCustomProps {
  $height: string;
  $padding: string;
  $customWidth: string;
}
export const ThCustom = styled.th<ThCustomProps>`
  ${({ $height, $padding, $customWidth }) => css`
    height: ${$height};
    ${$height};
    padding: ${$padding};
    transition: all 0.4s ease;
    width: ${$customWidth};
  `}
`;

interface ChipProps {
  $backgroundColor?: string;
  $color?: string;
}
export const Chip = styled.div<ChipProps>`
  ${({ $backgroundColor, $color }) => css`
    background-color: ${$backgroundColor};
    color: ${$color};
    height: 32px;
    width: 58px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 0 12px;
    font-size: 12px;
  `}
`;

export const ChipCol = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81px;
`;
