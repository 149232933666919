import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  boxdialog: {
    width: 1000,
    margin: '0 auto 0 auto',
    '@media only screen and (max-width: 900px)': {
      padding: '0 0 20px 0',
      margin: '0 auto 0 auto',
      '@media screen and (orientation: landscape)': {
        margin: '0 auto',
      },
    },
  },
  dialog_modal: {
    borderRadius: '50%',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin: '0 auto',
  },
  titleDialog: {
    textAlign: 'center',
    fontWeight: '800',
    fontSize: '24px',
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
  },
  icon: {
    fontSize: 20,
  },
  close: {
    position: 'absolute',
    width: '45px',
    top: '2px',
  },
  rootStyle: {
    width: '550px',
    height: '250px',
    padding: '-90px 0 -10px 0',
    borderRadius: 20,
    '@media only screen and (max-width: 900px)': {
      width: '100%',
      height: '100%',
    },
  },
  inputEmail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80%',
  },

  inputTitle: {
    fontSize: '18px',
    marginRight: '4px',
    marginTop: '10px',
  },
  inputText: {
    width: '350px',
    height: '30px',
    padding: '0 15px',
    border: 'none',
    outline: 'none',
    border: '1px solid #dfe3e8',
    borderRadius: '4px',
    //color: #6b7786,
    transition: 'border 0.3s ease-in-out',
    cursor: 'pointer',
    font: 'normal normal normal 14px/15px Inter',
    background: '#fff !important',
    marginTop: '10px',

    // :focus {
    //   borderColor: '#2699fb',
    //   boxShadow: '0 0 6px #3381ee33',
    //   cursor: 'text',
    // }
  },
  labelError: {
    font: 'normal normal normal 14px/18px Inter',
    color: '#fe1d14',
    opacity: '1',
    display: 'block',
    marginTop: '4px',
  },
});

class ModalChangeEmail extends React.Component {
  render() {
    const {
      classes,
      title,
      open,
      close,
      componente,
      btone,
      bttwo,
      handleChange,
      save,
      submitted,
    } = this.props;
    return (
      <React.Fragment>
        <Dialog
          classes={{ paper: classes.rootStyle }}
          fullScreen={true}
          open={open}
          onClose={close}
        >
          <DialogActions>
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={close}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </DialogActions>
          <DialogTitle className={classes.titleDialog}>
            <span className={classes.titleDialog}>{title}</span>
          </DialogTitle>
          <div
            className={classes.inputEmail}
            style={{
              alignItems: submitted && 'flex-start',
            }}
          >
            <span className={classes.inputTitle}>Novo E-mail:</span>
            <div
              style={{
                display: submitted && 'flex',
                flexDirection: submitted && 'column',
                alignItems: submitted && 'flex-start',
              }}
            >
              <input
                id='email'
                name='email'
                onChange={handleChange}
                className={classes.inputText}
                style={{
                  borderColor: submitted && '#ff6347',
                  boxShadow: submitted && '0 0 6px #3381ee33',
                }}
              />
              {submitted && (
                <>
                  <label className={classes.labelError}>E-mail inválido</label>
                </>
              )}
            </div>
          </div>
          <DialogActions>
            {btone && (
              <Button color='primary' onClick={save}>
                {btone}
              </Button>
            )}
            {bttwo && (
              <Button color='primary' onClick={close}>
                {bttwo}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ModalChangeEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = ModalChangeEmail;
export default withStyles(styles)(connectedRegisterPage);
