export const queues = {
  IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST: 'waitingReception',
  IN_VONAGE_ATTENDANCE_RECEPCIONIST: 'inAttendanceReception',
  IN_WAIT_ATTENDANCE_DOCTOR: 'waitingDoctor',
  IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST: 'truClinicWaiting',
  IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST: 'truClinicInAttendance',
  VONAGE_IN_WAIT_ATTENDANCE_SUPPORT: 'vonageWaitingSupport',
  TRUCLINIC_IN_WAIT_ATTENDANCE_SUPPORT: 'truclinicWaitingSupport',
  VONAGE_IN_ATTENDANCE_SUPPORT: 'vonageInAttendanceSupport',
  TRUCLINIC_IN_ATTENDANCE_SUPPORT: 'truclinicInAttendanceSupport',
  NO_SHOW: 'noShow',
  IN_ATTENDANCE_DOCTOR: 'inAttendanceDoctor',
  IN_WAIT_FOR_DATE_SCHEDULE: 'newQueue',
  IN_VONAGE_PENDING_CONTACT: 'newQueue',
  IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE: 'newQueue',
};

export const ALL_QUEUES_IDS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
];
