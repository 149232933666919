import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Refresh from '@material-ui/icons/Refresh';
import { validations } from '../helpers/validations';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import AlertLink from '../components/AlertLink';
import Loader from 'react-loader-spinner';
import ModalConvenio from '../components/ModalConvenio';
import { alertActions } from '../actions';
import Alert from '../components/Alert';
import { withStyles } from '@material-ui/core/styles';
import { agendamentoTPsicol } from '../actions';
import CustomButtom from './CustomButtom';
import ProvideSchedule from '../components/Modals/Telepisicologia/ProvideSchedule';
import DialogModal from '../components/DialogModal';
import { addDaysToActualDate, debounce } from '../utils/utils';
import moment from 'moment';
import { json2csvAsync } from 'json-2-csv';
import { CircularProgress } from '@material-ui/core';
import { isCPF, formatToCPF } from 'brazilian-values';
import styled from 'styled-components';
import { history } from '../helpers/history';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStylesFilterComponent = (theme) => ({
  internalPadding: {
    padding: '0 40px',
  },

  root: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },

  containerFilters: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  textField: {
    padding: '10px',
    width: '22%',
    marginLeft: '-20px',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },

  dateField: {
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // fontWeight: 500,
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
  },

  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
});

const CustomTextField = styled(TextField)`
  .MuiInputLabel-formControl {
    left: 10px;
  }
`;
class AgendaTelepsicologia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: 1,
      itemsByPage: 5,
      agendaTelepsicologia: [],
      form: {
        name: '',
        document: '',
        professional: '',
        date: '',
        status: '',
      },
      page: 1,
      total: undefined,
      itemByPage: 1,
      modalCsvIsOpen: false,
      dataInicioCsv: '',
      dataInicioCsvValida: false,
      dataFimCsvValida: false,
      periodoCsvValido: false,
      showLoader: false,
      openDispose: false,
      loader: true,
      timeout: 2000,
      loaderTable: true,
    };
    this.itemByPage = 5;
    this.docs = [];
    this.page = 1;
    this.total = 0;
    this.changeValue = this.changeValue.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
    this.changeValueCsvInicio = this.changeValueCsvInicio.bind(this);
    this.changeValueCsvFim = this.changeValueCsvFim.bind(this);
    this.downloadCsvModal = this.downloadCsvModal.bind(this);
    this.closeCsvModal = this.closeCsvModal.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
    this.handleCancelDispose = this.handleCancelDispose.bind(this);
    this.openModalDispose = this.openModalDispose.bind(this);
    this.handleChangeFilterCPF = this.handleChangeFilterCPF.bind(this);
    this.handleGetMessage = this.handleGetMessage.bind(this);
  }

  handleClose() {
    //this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '' });
  }

  changeValue(event) {
    let { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  }

  handleChangeFilterCPF(event) {
    let { name, value } = event.target;
    const { form } = this.state;
    value = value.replace(/\./g, '').replace(/-/g, '');
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  }

  downloadCsvModal() {
    this.setState({ modalCsvIsOpen: true });
  }

  async submitCsvModal() {
    let { dataInicioCsv, dataFimCsv } = this.state;
    let periodoInvalido = validations.validationCsvRangeDate(
      dataInicioCsv,
      dataFimCsv
    );

    if (periodoInvalido) {
      this.setState({ periodoCsvValido: true });
    } else {
      this.setState({ errorCsv: false, showLoader: true });
      let items = [];
      let hasError = false;
      let firstPage = await this.resolvePageDownload(
        dataInicioCsv,
        dataFimCsv,
        1,
        1
      ).catch((err) => {
        this.setState({ loaderPercent: 0, showLoader: false, errorCsv: true });
      });
      let { page = 0, pages = 0, limit = 0, docs = [] } = firstPage;
      items = items.concat(docs);
      for (let doc of docs) {
        items.push(doc);
      }
      let arrayPromises = [];
      do {
        page++;
        arrayPromises.push(
          this.resolvePageDownloadWithoutExecutePromise(
            dataInicioCsv,
            dataFimCsv,
            page,
            pages
          )
        );
      } while (page <= pages);
      await Promise.all(arrayPromises)
        .then((values) => {
          for (let value of values) {
            let { docs } = value;
            for (let doc of docs) {
              items.push(doc);
            }
          }
        })
        .catch((err) => {
          hasError = true;
          this.setState({
            periodoCsvValido: false,
            loaderPercent: 0,
            showLoader: false,
            dataInicioCsv: '',
            dataFimCsv: '',
            errorCsv: true,
          });
        });

      if (!hasError) {
        this.convertCsv(items);
        this.setState({
          periodoCsvValido: false,
          loaderPercent: 0,
          showLoader: false,
          dataInicioCsv: '',
          dataFimCsv: '',
          errorCsv: false,
        });
      }
    }
  }

  changeValueCsvInicio(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataInicioCsvValida: validations.validationCsvDate(value),
    });
  }

  changeValueCsvFim(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataFimCsvValida: validations.validationCsvDate(value),
    });
  }

  async handleGetMessage() {
    const alertReturn = await this.getMessage();
    if (alertReturn.open === true) {
      this.handleCancelDispose();
      this.handleClose(alertReturn.type, alertReturn.message);
    }
  }

  async getMessage() {
    return await this.props.alertCallback;
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        id='formatted-text-mask-input'
        placeholder='Data de criação'
        mask='99/99/9999'
        maskChar={null}
      />
    );
  }

  TextMaskData(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        id='formatted-text-mask-input'
        placeholder='Data'
        mask='99/99/9999'
        maskChar={null}
      />
    );
  }

  closeCsvModal() {
    this.setState({ modalCsvIsOpen: false });
  }

  filterRows(page, qtd) {
    var filters = ['name', 'professional', 'document', 'date', 'status'];
    var objectProps = [
      'Patient',
      'doctorRefName',
      'Patient',
      'startAt',
      'status',
    ];
    const getIndex = () => {
      let count = null;
      filters.forEach((element, index) => {
        if (this.state.form[element] !== '') count = index;
      });

      return count;
    };
    const getCount = getIndex();
    if (getCount === null) return;

    this.setState({ loaderTable: true });

    let filteredArray = {};
    filteredArray.data = [];

    filteredArray.data =
      this.state.newdata &&
      this.state.newdata.filter((item) => {
        let search = null;

        if (filters[getIndex()].includes('name')) {
          search = item[objectProps[getIndex()]].name.toLowerCase();
        }

        if (filters[getIndex()].includes('document')) {
          if (isCPF(item[objectProps[getIndex()]].document))
            search = item[objectProps[getIndex()]].document
              .replace(/\./g, '')
              .replace('-', '');
          else search = item[objectProps[getIndex()]].document;
        }

        if (filters[getIndex()].includes('date')) {
          search = moment(item[objectProps[getIndex()]]).format('DD/MM/YYYY');
        }

        if (search === null) return;

        return search.includes(
          this.state.form[filters[getIndex()]].toLowerCase()
        );
      });
    debounce(() => this.setState({ loaderTable: false }), 1000);
    this.props.dispatch(agendamentoTPsicol.filterAgenda(filteredArray));
  }

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  async componentWillMount() {
    this.setState({ disabledDates: await this.props.disabledDates });
    this.getScheduleds();
  }

  getScheduleds() {
    let startAt = moment().format('YYYY-MM-DD');
    let finishAt = addDaysToActualDate(7).format('YYYY-MM-DD');
    const payload = { startAt, finishAt, specialtyId: 3, status: 3 };
    this.props.dispatch(agendamentoTPsicol.getAllAgendamentosTPsicol(payload));
    debounce(() => this.setState({ loader: false }), this.state.timeout);
  }

  async componentDidMount() {
    if (
      this.props.agendamentos.data === undefined ||
      this.props.agendamentos.data.length === 0
    )
      this.state.loaderTable = false;
  }
  componentWillUnmount() {
    this.props.dispatch(alertActions.clear());
  }
  openModalDispose() {
    this.setState({
      openDispose: true,
    });
  }

  handleCancelDispose() {
    this.setState({
      openDispose: false,
    });
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
  }

  render() {
    let { open, message, type, showLoader } = this.props;
    const { agendamentos } = this.props;
    const { modalCsvIsOpen } = this.state;
    let { classes } = this.props;

    // let { page, pages, total, limit } = agendamentos;
    let { pageList, itemsByPage } = this.state;
    let data = agendamentos.data;

    //const { data } = this.state;
    const formatDate = (str) => {
      let arr = str.split('-');
      arr.reverse();
      return arr[0] + '/' + arr[1] + '/' + arr[2];
    };

    const revomeSeconds = (str) => str.slice(0, 5);

    data =
      data &&
      data.map((element) => {
        if (isCPF(element.Patient.document))
          element.Patient.document = formatToCPF(element.Patient.document);
        return {
          ...element,
          date: formatDate(element.startAt.split(' ')[0]),
          hour: revomeSeconds(element.startAt.split(' ')[1]),
          especialidade: element.Specialty.name,
        };
      });

    this.state.newdata = data;

    const columns = [
      { title: 'Nome ', field: 'Patient.name' },
      { title: 'Documento', field: 'Patient.document' },
      { title: 'Contrato', field: 'contractId' },
      { title: 'E-mail', field: 'Patient.email' },
      { title: 'Profissional', field: 'doctorRefName' },
      { title: 'Programa', field: 'telepsicoProgram' },
      { title: 'Especialidade', field: 'Specialty.name' },
      { title: 'Data', field: 'date' },
      { title: 'Horário', field: 'hour' },
      { title: 'Status', field: 'status' },
    ];

    return (
      <div>
        <Alert
          open={this.state.open}
          type={this.state.type}
          message={this.state.message}
          onClose={() => this.handleClose(this.state.type, this.state.message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />

        <DialogModal
          withClose
          withDivider
          title='Disponibilizar agenda'
          titlepos='center'
          footerpos='center'
          labelOk='Confirmar'
          open={this.state.openDispose}
          handleCancel={() => this.handleCancelDispose}
        >
          <ProvideSchedule
            handleGetMessage={() => this.handleGetMessage()}
            handleCancel={() => this.handleCancelDispose}
            disabledDates={this.state.disabledDates}
          />
        </DialogModal>

        <ModalConvenio
          open={modalCsvIsOpen}
          close={() => this.closeCsvModal()}
          message='Selecione o periodo de extração'
          onSubmit={async () => {
            await this.submitCsvModal();
          }}
          component={
            <React.Fragment>
              {showLoader && (
                <div className={classes.loaderAlign}>
                  <Loader type='Oval' color='#ccc' height={60} width={60} />
                </div>
              )}
              {!showLoader && (
                <div className={classes.formAlign}>
                  <TextField
                    label='De'
                    margin='normal'
                    name='dataInicioCsv'
                    value={this.state.dataInicioCsv}
                    onChange={this.changeValueCsvInicio}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>

                  <TextField
                    label='Até'
                    margin='normal'
                    name='dataFimCsv'
                    value={this.state.dataFimCsv}
                    onChange={this.changeValueCsvFim}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                </div>
              )}
              {this.state.periodoCsvValido && (
                <div className={classNames(classes.alertText)}>
                  O periodo entre as datas não pode ultrapassar 3 meses
                </div>
              )}
              {this.state.errorCsv && (
                <div className={classNames(classes.alertText)}>
                  Não foi possível efetuar o download. Por favor, tente
                  novamente.
                </div>
              )}
            </React.Fragment>
          }
        />
        <div className={classes.internalPadding}>
          <div className={classes.root}>
            <h2>Agenda Telepsicologia</h2>

            {this.state.loader ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '80px',
                }}
              >
                <CircularProgress color='primary' />
              </div>
            ) : (
              <div style={{ margin: '10px auto', height: '36px' }}>
                <CustomButtom
                  onClick={() => {
                    this.openModalDispose();
                  }}
                  size='medium'
                  typeButtom='tele'
                >
                  Disponibilizar agenda
                </CustomButtom>
              </div>
            )}
            <div className={classes.containerFilters}>
              <CustomTextField
                className={classes.textField}
                id='standard-select-currency'
                label='Nome'
                name='name'
                value={this.state.form.name}
                onChange={this.changeValue}
                margin='none'
                //margin
              />

              <CustomTextField
                className={classes.textField}
                id='standard-select-currency'
                label='Documento'
                name='document'
                value={this.state.form.document}
                onChange={this.handleChangeFilterCPF}
                margin='normal'
              />

              <CustomTextField
                className={classes.textField}
                id='standard-select-currency'
                label='Profissional'
                name='professional'
                value={this.state.form.professional}
                onChange={this.changeValue}
                margin='normal'
              />

              <Input
                className={classes.textField}
                id='standard-select-currency'
                label='Data'
                name='date'
                value={this.state.form.date}
                onChange={this.changeValue}
                inputComponent={this.TextMaskData}
                margin='normal'
              />

              {/* <CustomTextField
                className={classes.textField}
                id="standard-select-currency"
                label="Status"
                name="status"
                value={this.state.form.status}
                onChange={this.changeValue}
                margin="normal"
              /> */}
              <Search
                onClick={() => {
                  this.filterRows();
                }}
              />
              <div style={{ marginRight: '10px' }}>
                <a
                  href='#'
                  onClick={() => {
                    this.filterRows();
                  }}
                  className={classes.searchClick}
                >
                  Pesquisar
                </a>
              </div>
              <a style={{ width: '14px' }} className={classes.refreshClick}>
                <Refresh
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({
                      ...this.state,

                      form: {
                        name: '',
                        document: '',
                        professional: '',
                        date: '',
                        status: '',
                      },
                    });
                    this.getScheduleds();
                  }}
                />
              </a>
            </div>
          </div>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />

          {this.state.loaderTable ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '300px',
              }}
            >
              <CircularProgress color='primary' />
            </div>
          ) : (
            <MaterialTable
              icons={tableIcons}
              title=''
              columns={columns}
              data={data}
              options={{
                search: false,
              }}
              localization={{
                header: {
                  actions: 'Acões',
                },
                body: {
                  emptyDataSourceMessage: 'Nada agendado',
                  editRow: {
                    deleteText: '',
                  },
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                },

                pagination: {
                  labelRowsSelect: 'Linhas',
                  labelDisplayedRows: '{from} - {to} de {count}',
                  firstTooltip: 'Voltar para a primeira página',
                  previousTooltip: 'Voltar',
                  nextTooltip: 'Proxima',
                  lastTooltip: 'Ir para ultima página',
                },
              }}
              actions={[]}
            />
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { agendamentos } = state.agendamentoTPsicol;
  const { message, type, open } = state.alert;
  const alertCallback = state.alert;
  const userLogin = state.authentication.user;

  return {
    message,
    type,
    open,
    agendamentos,
    userLogin,
    alertCallback,
  };
}

AgendaTelepsicologia.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(AgendaTelepsicologia)
);
export { connectedLoginPage as AgendaTelepsicologia };
