import styled, { css } from 'styled-components';
import Menu from '@material-ui/core/Menu';

export const DivTableAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 25px;
    margin-top: 25px;
    color: #898989;
  }

  td {
    width: 0%;
  }

  .resendLink {
    color: #ffffff;
  }

  cursor: pointer !important;
`;

export const ContainerSelectModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectTitle = styled.span`
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const TextAreaModal = styled.textarea`
  width: 400px;
  height: 100px;
  resize: none;
`;

export const TableColStyled = styled.td`
  width: 5% !important;
`;

export const DivIconDropdown = styled.div`
  svg:first-child {
    color: #787878;
  }

  padding: 0px;
  font-size: 15px;
`;

export const MenuDropdown = styled(Menu)`
  margin-left: 25px;

  @media screen and (max-width: 1366px) {
    margin-left: 10px;
  }
`;

export const ModalDialogContent = styled.div`
  font-size: 16px;
`;
