import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  Modal,
  ModalOverlay,
  CloseDiv,
  Close,
  InnerLayer,
  TitleModal,
  TextAreaModal,
  CustomButtomModal,
} from './styles';
import CloseIcon from './Icon/close.svg';
import CustomButton from '../CustomButton';

export default function ModalAlertLink({
  close,
  title,
  sendEmail,
  copy,
  textAreaRef,
}) {
  const { message, type, open, url } = useSelector(
    (state) => state.alertContingencia
  );

  return (
    <>
      {open && (
        <ModalOverlay>
          <Modal>
            <CloseDiv>
              <Close src={CloseIcon} onClick={close} />
            </CloseDiv>
            <InnerLayer>
              <TitleModal>{title}</TitleModal>
              <TextAreaModal readOnly ref={textAreaRef}>
                {url}
              </TextAreaModal>
              <CustomButtomModal>
                {document.queryCommandSupported('copy') && (
                  <>
                    <CustomButton
                      type={'button'}
                      onClick={(e) => {
                        copy(e);
                      }}
                      size={'large'}
                      typeButtom={'primary'}
                      height={'40px'}
                      text={'Copiar link'}
                      margin={'0 30px 0 0'}
                    />
                    {true && (
                      <CustomButton
                        type={'button'}
                        text={'Enviar Link'}
                        size={'large'}
                        typeButtom={'primary'}
                        onClick={() => sendEmail()}
                      />
                    )}
                  </>
                )}
              </CustomButtomModal>
            </InnerLayer>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
}
