import { FindAllQueueRecordsFilter } from '../../../domain/state';
import { QueueRecordEntity } from '../../../domain/entities';
import {
  HttpClientAdapter,
  HttpRequest,
  HttpResponse,
} from '../../../infra/adapters';

export type HttpBodyQueueRecordFindAll = FindAllQueueRecordsFilter;

export type Response = {
  docs: QueueRecordEntity[];
  pages: number;
  total: number;
};

export class HttpQueueRecordFindAll {
  constructor(private readonly httpClient: HttpClientAdapter) {}

  async handle(httpRequest: HttpRequest): Promise<HttpResponse<Response>> {
    return await this.httpClient.request(httpRequest);
  }
}
