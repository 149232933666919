import React from 'react';

import { FormControlLabel, RadioGroup } from '@material-ui/core';

import CustomRadio from '../radio';

const CustomRadioGroup = ({ value, onChange, data, ...props }) => (
  <RadioGroup {...props} value={value} onChange={onChange} >
    {data.map((e) => (
      <FormControlLabel
        control={<CustomRadio />}
        key={e.value}
        value={e.value}
        label={e.label}
      />
    ))}
  </RadioGroup>
);

export default CustomRadioGroup;
