import { searchUserConstants } from '../constants/searchUser.constants';
import { SearchService } from '../services/searchUserCentraRelacionamento.service';

export const actionSearchUser = {
  SearchUSer,
};

function SearchUSer(doc, user, functionUser, requestTimeOut) {
  return (dispatch) => {
    dispatch(request());
    SearchService.SearchUser(doc, user)
      .then((body) => {
        dispatch(success(body));
        functionUser();
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(failure(error));
        requestTimeOut();
      });
  };

  function request() {
    return { type: searchUserConstants.SEARCH_USER_REQUEST };
  }

  function success(result) {
    return { type: searchUserConstants.SEARCH_USER_SUCCESS, result };
  }

  function failure(error) {
    return { type: searchUserConstants.SEARCH_USER_ERROR, error };
  }
}
