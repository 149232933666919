import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  background: white;
  border-radius: 0.5rem;
  width: 240px;
  height: 345px;
  text-align: start;
  box-shadow: 3px 1px 15px 5px #ebebeb;
  z-index: 9;
  position: absolute;
  right: 5px;
  top: 45px;
  font-weight: 400;
  color: black;
  text-transform: none;
`;
export const Title = styled.h2`
  padding-top: 5px;
  margin: 0 0 5px 10px;
`;
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
export const Check = styled.div`
  margin: 0 0 5px 0;
  padding-bottom: 5px;
`;

export const ContainerCheck = styled.div`
  display: block;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : '10px 0 10px 10px')};
`;
export const Label = styled.label`
  margin-left: 10px;
`;
export const Input = styled.input``;
