import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user, loginstatus: {}, submitted: false }
  : {};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.PLAN_SUCCESS: {
      return Object.assign({}, state, {
        user: {
          ...action.user,
          plan: action.plan,
        },
      });
    }
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, deleting: true } : user
        ),
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter((user) => user.id !== action.id),
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        }),
      };
    default:
      return state;
  }
}

export function enabledQueuesSet(state = { enabledQueues: [] }, action) {
  switch (action.type) {
    case userConstants.SET_ENABLED_QUEUES_SUCCESS:
      return {
        type: 'success',
        enabledQueuesSet: action.enabledQueues,
      };
    case userConstants.SET_ENABLED_QUEUES_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        enabledQueuesSet: [],
      });
    case userConstants.SET_ENABLED_QUEUES_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}

export function enabledQueuesGet(state = { enabledQueues: [] }, action) {
  switch (action.type) {
    case userConstants.GET_ENABLED_QUEUES_SUCCESS:
      return {
        type: 'success',
        enabledQueuesGet: action.enabledQueues,
      };
    case userConstants.GET_ENABLED_QUEUES_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        enabledQueuesGet: [],
      });
    case userConstants.GET_ENABLED_QUEUES_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
