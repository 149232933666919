import styled from 'styled-components'

type InputProps = {
  isWithError: string
}

export const InputText = styled.input<InputProps>`
  height: 38px;
  padding: 0 15px;
  border: none;
  outline: none;
  border: 1px solid #Dfe3e8;

  ${props => props.isWithError && `
  border: 1px solid #f00;
  `}

  border-radius: 4px;
  color: #6B7786;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  font: normal normal normal 14px/15px Inter;

  :focus {
    border-color: #2699FB;
    box-shadow: 0 0 6px #3381ee33;
    cursor: text;
  }
`