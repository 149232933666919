// @ts-nocheck
import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const TableRenderSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={9}>
          <Skeleton height={48} animation='wave' />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9}>
          <Skeleton height={48} animation='wave' />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9}>
          <Skeleton height={48} animation='wave' />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9}>
          <Skeleton height={48} animation='wave' />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9}>
          <Skeleton height={48} animation='wave' />
        </TableCell>
      </TableRow>
    </>
  );
};
