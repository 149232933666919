import React from 'react';
import { HttpQueueRecordReport } from '../../../domain/http';
import { HttpRequest } from '../../../infra/adapters';
import { formatDate } from '../../shared/adapters';

export class ReportQueueRecordsData {
  constructor(private readonly httpQueueRecordReport: HttpQueueRecordReport) {}

  async handle(dateFrom: Date, dateUntil: Date, reportPage: number) {
    const user = JSON.parse(localStorage.getItem('user') || '');
    const from = formatDate(dateFrom, 'DD-MM-YYYY');
    const until = formatDate(dateUntil, 'DD-MM-YYYY');

    const httpRequest: HttpRequest = {
      url: `${process.env.REACT_APP_API_CONECTA}/servico/historico/downloadjson/${from}/${until}/${reportPage}`,
      method: 'get',
      headers: {
        'x-access-token': user.accessToken,
        _id: user.user._id,
      },
    };

    const response = await this.httpQueueRecordReport.handle(httpRequest);
    return {
      status: response.status,
      data: {
        docs: response.data?.docs,
        pages: Number(response.data?.pages),
      },
    };
  }
}
