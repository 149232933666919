import { useState, useEffect, useMemo } from 'react';

import ModalUnlock from './components/ModalUnlock';

import MenuPainel from '../../../../components/MenuPainelNew';

import { Container } from '../../../components/context';

import {
  CustomTable,
  CustomAvatar,
  CustomChip,
  CustomPopover,
  CustomIcon,
  CustomDivider,
  CustomButton,
  CustomText,
  CustomTabs,
  CustomTimeLine,
  CustomSegment,
} from '../../../components/ui';

import {
  CustomDatePicker,
  CustomInput,
  CustomSelect,
} from '../../../components/ui/webform';

import {
  displayValue,
  formatLocalHour,
  formatDate,
} from '../../../utils/format';

import {
  StyledAvatarContainer,
  StyledPopoverContent,
  StyledChipContainer,
  StyledButtonContainer,
  StyledSelectWrapper,
} from './styles';

import { getPauseScaleStatusColor, mountPauseTime } from './utils';

import { useNavigator } from '../../../hooks';

import { ModalUnlock } from './components';

import { statusToUnlock } from './constants';

import useListPauseScale from './hooks/useListPauseScale';
import useTableCounter from './hooks/useTableCounter';
import usePauses from '../../../modules/pauses/hooks/usePauses';

const ListPauseScale = () => {
  const {
    useTimeLine,
    tableRef,
    arrayDate,
    dates,
    getUsers,
    handleProfessional,
    handleSubmit,
    professional,
    reset,
    searchScaleInDate,
    setDate,
    setDateForm,
    setScaleInDate,
    setSendForm,
    dateForm,
    clearState,
    openUnlockModal,
    useModalData,
    useUnlockModal,
    loadUsers,
    useLoading,
    useUsers,
    onOrderChange,
    handleTab,
    setCounter,
    useCounter,
    isSearchingMoreData,
    updateSocket
  } = useListPauseScale();

  const { usersCounter } = useTableCounter(
    useCounter,
    useUsers,
    searchScaleInDate
  );

  const [tabState, setTabState] = useState(null);
  const memorizedTabe = useMemo(() => tabState, [tabState]);

  useEffect(() => {
    if (!usersCounter.length) {
      loadUsers();
    }
  }, []);

  useEffect(() => {
    if (typeof memorizedTabe === 'number') {
      onOrderChange(memorizedTabe);
    }
  }, [memorizedTabe, useUsers?.length, updateSocket]);

  const { push } = useNavigator();

  const columns = [
    {
      title: 'funcionário',
      value: ({ userName }) => (
        <StyledAvatarContainer>
          <CustomAvatar name={userName} />
          <CustomText title={1} color='dark'>
            {displayValue(userName)}
          </CustomText>
        </StyledAvatarContainer>
      ),
    },
    {
      title: 'entrada',
      value: ({ timeLogin = null }) => (
        <>
          <CustomText color='dark'>
            {displayValue(formatDate(timeLogin, 'DD/MM/YYYY'), '-')}
          </CustomText>

          <br />

          <CustomText>
            {displayValue(formatDate(timeLogin, 'HH:mm'), '-')}
          </CustomText>
        </>
      ),
    },
    {
      title: 'saída',
      value: ({ timeLogout = null }) => (
        <>
          <CustomText color='dark'>
            {displayValue(formatDate(timeLogout, 'DD/MM/YYYY'), '-')}
          </CustomText>

          <br />

          <CustomText>
            {displayValue(formatDate(timeLogout, 'HH:mm'), '-')}
          </CustomText>
        </>
      ),
    },
    {
      title: 'escala',
      value: ({ entryTime, departureTime }) => (
        <>
          <CustomText color='dark'>
            {displayValue(formatLocalHour(entryTime))} -{' '}
            {displayValue(formatLocalHour(departureTime))}
          </CustomText>

          <br />

          <CustomText>
            <br />
          </CustomText>
        </>
      ),
    },
    {
      title: 'pausa lanche',
      value: ({ snackBreakTime, pauses, snackBreakPause }) => (
        <>
          <CustomText color='dark'>
            {displayValue(snackBreakTime)} - {displayValue(snackBreakPause)}
          </CustomText>

          <br />

          <CustomText>
            {pauses?.snackBreak ? mountPauseTime(pauses.snackBreak) : '-'}
          </CustomText>
        </>
      ),
    },
    {
      title: 'pausa descanso',
      value: ({ restBreakTime, pauses, restBreakPause }) => (
        <>
          <CustomText color='dark'>
            {displayValue(restBreakTime)} - {displayValue(restBreakPause)}
          </CustomText>

          <br />

          <CustomText>
            {pauses?.restBreak ? mountPauseTime(pauses.restBreak) : '-'}
          </CustomText>
        </>
      ),
    },
    {
      title: 'status',
      value: ({ status, statusDuration, timeLogin, timeLogout }) => (
        <StyledChipContainer>
          {statusDuration !== undefined ? (
            <>
              {!timeLogin || timeLogout ? (
                <CustomChip
                  color='grey'
                  label='Desconectado'
                  bold={1}
                  applyBaseColor={true}
                />
              ) : (
                <>
                  <CustomChip
                    icon='QueryBuilder'
                    width='62'
                    bold={1}
                    applyBaseColor={1}
                    label={displayValue(statusDuration, '-')}
                    color={getPauseScaleStatusColor(status?.description)}
                  />

                  <CustomText color='dark'>
                    {displayValue(status?.description)}
                  </CustomText>
                </>
              )}
            </>
          ) : (
            <CustomText color='dark'>{displayValue(null, '-')}</CustomText>
          )}
        </StyledChipContainer>
      ),
    },
    {
      title: '',
      value: ({ _id, userId, userName, status }) => (
        <CustomPopover align='left'>
          <StyledPopoverContent
            onClick={() => {
              push(`/adm/scales/${_id}/edit`);
            }}
          >
            <CustomIcon icon='AddCircleOutline' />
            <CustomText>Ver mais</CustomText>
          </StyledPopoverContent>

          {statusToUnlock.includes(status?.description) && (
            <>
              <CustomDivider space={5} />

              <StyledPopoverContent
                onClick={() => openUnlockModal(userId, userName)}
              >
                <CustomIcon icon='LockOpen' />
                <CustomText>Desbloquear</CustomText>
              </StyledPopoverContent>
            </>
          )}
        </CustomPopover>
      ),
    },
  ];

  return (
    <>
      <MenuPainel stepper='Gestão de escalas' />

      <Container>
        <CustomDivider />

        <CustomButton
          rounded={false}
          onClick={() => push('/adm/scales/manager')}
          icon='Add'
          size='small'
          iconDir='l'
          text='Cadastro de escalas'
        />

        <CustomDivider />

        <StyledSelectWrapper>
          <CustomSelect
            value={professional}
            onChange={(e) => handleProfessional(e)}
            options={(e) => getUsers(e)}
            title='Profissional'
            placeholder='Selecione um profissional'
            emptyData='Funcionário não encontrado.'
          />
        </StyledSelectWrapper>

        <CustomDivider space={5} />

        <CustomDatePicker
          type='range'
          title='Selecione uma data ou período'
          name='datas'
          onFocusedDateChange={(dateFocused, dateClicked) => {
            if (dateForm.length === 2) {
              setDateForm([]);
            }

            setDate({ dateFocused, dateClicked });
          }}
          onClose={() => setSendForm(dateForm)}
          value={dates}
          dateInitial={arrayDate}
          reset={reset}
        />

        <CustomDivider space={5} />

        <StyledButtonContainer>
          <CustomButton
            onClick={handleSubmit}
            loading={useLoading}
            rounded={false}
            size='large'
            text='Exportar'
          />

          <CustomButton
            onClick={clearState}
            rounded={false}
            size='large'
            text='Limpar filtros'
            type='outlined'
          />
        </StyledButtonContainer>

        <CustomDivider space={5} />

        <CustomInput
          value={searchScaleInDate}
          onChange={(e) => setScaleInDate(e.target.value)}
          title='Data'
          type='date'
          placeholder='dd/mm/aaaa'
        />

        <CustomDivider space={5} />

        <CustomTabs
          onChange={(newValue) => {
            setCounter(newValue === 1);
            handleTab(newValue);
          }}
          data={[
            {
              title: 'Dashboard',
              element: () => <CustomTimeLine data={useTimeLine} />,
            },
            {
              title: 'Lista de escalas',
              element: () => (
                <>
                  <CustomSegment
                    title='Ordenar por:'
                    elements={['Escala', 'Status']}
                    onChange={(index) => setTabState(index)}
                  />
                  <CustomDivider space={5} />
                  <CustomTable
                    columns={columns}
                    data={usersCounter}
                    loadMore={isSearchingMoreData}
                  />
                </>
              ),
            },
          ]}
        />

        <ModalUnlock
          show={useUnlockModal}
          dataUser={useModalData}
          setReloadGrid={(reloadGrid) => {
            if (reloadGrid && !usersCounter.length) {
              loadUsers();
            }
          }}
        />
        <div ref={tableRef} />
      </Container>
    </>
  );
};

export default ListPauseScale;
