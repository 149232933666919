import { atividadesConstants } from '../constants';
import { alertActions } from './';
import { atividadesService } from '../services';

export const atividadesActions = {
  getListActivities,
  getListActivitiesComponent,
  JSONDownloadActivities,
  errorCsv,
};

function getListActivities(page = 1, qtd = 5) {
  return (dispatch) => {
    dispatch(request());
    atividadesService
      .GetListActivities((page = 1), (qtd = 5))
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  function success(result) {
    return { type: atividadesConstants.ACTIVITIES_SUCCESS, result };
  }
  function request() {
    return { type: atividadesConstants.ACTIVITIES_REQUEST };
  }
}

function JSONDownloadActivities(dataInicioCsv, dataFimCsv, page) {
  return atividadesService.JSONDownloadActivities(
    dataInicioCsv,
    dataFimCsv,
    page
  );
}

function errorCsv() {
  return (dispatch) => {
    dispatch(
      alertActions.error('Erro no download do csv por favor tente novamente.')
    );
  };
}

function getListActivitiesComponent(page = 1, qtd = 5, search) {
  return new Promise((resolve, reject) => {
    atividadesService
      .GetListActivities(page, qtd, search)
      .then(
        (body) => {
          resolve(body);
        },
        (error) => {
          resolve({
            atividades: {
              docs: [],
              limit: 5,
              page: 0,
              pages: 1,
              total: 0,
            },
          });
        }
      )
      .catch((error) => {
        resolve({
          atividades: {
            docs: [],
            limit: 5,
            page: 0,
            pages: 1,
            total: 0,
          },
        });
      });
  });
}
