// libs
import React, { useCallback, useState, useRef, useEffect } from 'react';

import { Text } from '../../../components/Text';

import { PageProps } from '../../types';

import { useDebounce } from '../../utils/debounce.utils';

import { Input } from './input';
import { HelperText } from './helperText';
import { SubmitButton } from './submitButton';

import * as S from './style';

const inputValuesInitialState = {
  input1: '',
  input2: '',
  input3: '',
  input4: '',
  input5: '',
  input6: '',
};

type IInputValuesState = typeof inputValuesInitialState;

const INPUT_AMOUNT = Object.keys(inputValuesInitialState).length;

interface FormProps {
  pageProps: PageProps;
  onSubmit: (code: string) => void;
  isLoading?: boolean;
  hasQueryFailed?: boolean;
}

export function Form({ onSubmit, isLoading, hasQueryFailed }: FormProps) {
  const refList = useRef<(HTMLInputElement | null)[]>([]);

  const [inputValues, setInputValues] = useState<IInputValuesState>(
    inputValuesInitialState
  );
  const [lastChangedIndex, setLastChangedIndex] = useState<number | null>(null);

  const { handleDebounce, isCalling } = useDebounce({
    action: (codeData: string) => onSubmit(codeData),
  });

  const [isFormDirty, setIsFormDirty] = useState(true);
  const [hasFormError, setHasFormError] = useState(true);

  const manualSubmit = (
    dataToSubmit: typeof inputValues,
    hasError: boolean
  ) => {
    if (isFormDirty) setIsFormDirty(false);

    if (isLoading) return;

    if (!hasError) {
      const code = Object.values(dataToSubmit).join('');

      if (!isCalling) handleDebounce(code);
    }
  };

  const getInputIndexFromName = (inputName: string) => {
    const state = refList?.current;

    if (Array.isArray(state)) {
      const currentIndex = state.findIndex((ref) => ref?.name === inputName);

      if (currentIndex > -1) return currentIndex;
    }

    return null;
  };

  const getInputFromIndex = (inputIndex: number) => {
    const state = refList?.current;

    if (Array.isArray(state)) {
      const found = state[inputIndex];

      if (found) return found;
    }

    return null;
  };

  const handleNavigateRight = (activeIndex: number) => {
    const nextIndex = activeIndex + 1;

    if (nextIndex >= INPUT_AMOUNT) return;

    const found = getInputFromIndex(nextIndex);

    if (found) {
      found.focus();
    }
  };

  const handleNavigateLeft = (activeIndex: number) => {
    const prevIndex = activeIndex - 1;

    if (prevIndex < 0) return;

    const found = getInputFromIndex(prevIndex);

    if (found) {
      found.focus();
    }
  };

  const handleNavigate = (pressedKey: string, inputName: string) => {
    if (!pressedKey) return;

    const inputIndex = getInputIndexFromName(inputName);

    if (inputIndex === null) return;

    if (NavigateToRightKeys.includes(pressedKey))
      handleNavigateRight(inputIndex);

    if (NavigateToLeftKeys.includes(pressedKey)) handleNavigateLeft(inputIndex);

    if (SubmitKeys.includes(pressedKey)) {
      manualSubmit(inputValues, hasFormError);
    }
  };

  const validateState = (dataToValidate: typeof inputValues) => {
    const values = Object.values(dataToValidate);

    const isWrongLength = values.length !== INPUT_AMOUNT;
    const hasEmpty = values.some((item) => !item);

    return isWrongLength || hasEmpty;
  };

  useEffect(() => {
    if (lastChangedIndex !== null) {
      const inputValue = getInputFromIndex(lastChangedIndex);

      if (inputValue) {
        if (lastChangedIndex === INPUT_AMOUNT - 1) {
          manualSubmit(inputValues, validateState(inputValues));
        } else {
          handleNavigateRight(lastChangedIndex);
        }
      }
    }
  }, [lastChangedIndex, inputValues]);

  useEffect(() => {
    const hasError = validateState(inputValues);

    setHasFormError(hasError);
  }, [inputValues]);

  const handleUpdateField = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const insertedValue = evt.target.value;
      const inputName = evt.target.name;

      const currentState = Object.assign({}, inputValues);

      const currentValue = currentState[inputName as keyof IInputValuesState];

      if (insertedValue !== currentValue) {
        setInputValues({
          ...inputValues,
          [inputName]: insertedValue,
        });
      }

      setLastChangedIndex(getInputIndexFromName(inputName));
    },
    [inputValues]
  );

  const handleDynamicPaste = useCallback(
    (index: number) => (evt: React.ClipboardEvent<HTMLInputElement>) => {
      if (index !== 0) {
        evt.stopPropagation();
        return evt.preventDefault();
      }

      const data = evt.clipboardData.getData('text');

      if (data && typeof data === 'string' && data.length === INPUT_AMOUNT) {
        const charArray = data.split('');

        const objectState = {
          input1: charArray[0],
          input2: charArray[1],
          input3: charArray[2],
          input4: charArray[3],
          input5: charArray[4],
          input6: charArray[5],
        };

        setInputValues(objectState);

        const hasError = validateState(objectState);

        setHasFormError(hasError);

        manualSubmit(objectState, hasError);
      }
    },
    [isLoading]
  );

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    manualSubmit(inputValues, hasFormError);

    return false;
  };

  const handleReset = useCallback(() => {
    setInputValues(inputValuesInitialState);
    setHasFormError(true);
    setIsFormDirty(true);
  }, []);

  return (
    <S.FormWrapper onSubmit={handleSubmit} onReset={handleReset}>
      <S.Fieldset>
        <S.InputWrapper>
          {Object.keys(inputValues).map((key, index) => (
            <Input
              key={key}
              name={key}
              value={inputValues[key as keyof IInputValuesState]}
              onChange={handleUpdateField}
              ref={(refElement) => (refList.current[index] = refElement)}
              onPaste={handleDynamicPaste(index)}
              onNavigate={handleNavigate}
              error={hasQueryFailed}
            />
          ))}
        </S.InputWrapper>
        <HelperText
          hasFieldErrors={hasFormError}
          hasQueryFailed={hasQueryFailed}
          isFormDirty={isFormDirty}
        />
      </S.Fieldset>
      <S.FormFooter>
        <SubmitButton disabled={isLoading || hasFormError} />
      </S.FormFooter>
    </S.FormWrapper>
  );
}

const NavigateToRightKeys = ['ArrowRight', 'ArrowDown'];

const NavigateToLeftKeys = ['ArrowLeft', 'ArrowUp', 'Backspace'];

const SubmitKeys = ['Enter', ' '];
