import React, { forwardRef } from 'react';
import { IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';
import ModalDeleteAcionamento from './ModalDeleteAcionamento';
import AddBox from '@material-ui/icons/AddBox';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import BuildIcon from '@material-ui/icons/Build';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { acionamentoActions, actionForwarding } from '../actions';
import { history } from '../helpers';
import { acionamentoService } from '../services';
import allAcionamentosLocal from '../helpers/acionamentosComArquivosexclusao.json';
import ModalSupport from './ModalSupport';
import ModalAlert from './ModalAlert';
import { modalAlertActions } from '../actions';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, pref) => <Clear {...props} ref={pref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Build: forwardRef((props, ref) => <BuildIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class Fila extends React.Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      acionamentosData: [],
      modalDeleteAcionamento: false,
      modalSupport: false,
      dataDeleteAcionamento: {},
      dataSupport: {},
      inputValueReason: '',
      zeraDisplay: false,
      openModalAlert: false,
      typeModalAlert: '',
      messageModalAlert: '',
    };
    this.handlerModalDeleteAcionamento =
      this.handlerModalDeleteAcionamento.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.handleConfirmForwarding = this.handleConfirmForwarding.bind(this);
    this.handlerModalSupport = this.handlerModalSupport.bind(this);
  }

  componentDidMount() {}

  handleDeleteAcionamento = (id, reason) => {
    this.props.dispatch(acionamentoActions.deleteAcionamentos(id, reason));
  };

  handleEditAcionamento = (acionamento) => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    this.props.dispatch(
      acionamentoActions.editAcionamentos({ ...acionamento, editionTime })
    );
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  componentWillMount() {
    this.intervalID = setInterval(
      () => this.tableRef.current.onQueryChange(),
      4000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.props.dispatch(modalAlertActions.clear());
    this.props.dispatch(acionamentoActions.editAcionamentosWithoutRedirect());
  }

  handlerModalDeleteAcionamento(oldData) {
    const { modalDeleteAcionamento } = this.state;
    this.setState({
      modalDeleteAcionamento: !modalDeleteAcionamento,
      dataDeleteAcionamento: oldData,
    });
  }

  handlerModalSupport(oldData) {
    const { modalSupport } = this.state;
    this.setState({
      modalSupport: !modalSupport,
      dataSupport: oldData,
      zeraDisplay: true,
    });
  }

  handleCloseNewModal() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({
      openModalAlert: false,
      messageModalAlert: '',
      loadingsub: false,
    });
    this.refreshData();
  }

  handleOpenModaSupport(data) {
    this.setState({
      modalSupport: true,
      dataSupport: data,
      zeraDisplay: false,
    });
  }

  onEditClick() {
    history.push('/adm/acionamento');
  }

  deleteAcionamento(data, oldData, reason) {
    const index = data.indexOf(oldData);
    data.splice(index, 1);
    if (this.state.inputValueReason !== '') {
      this.setState(
        { data, acionamentosData: data, inputValueReason: '' },
        () => this.handleDeleteAcionamento(oldData._id, reason)
      );
      this.handlerModalDeleteAcionamento();
    }
  }

  handleConfirmForwarding(inputValue) {
    const { dataSupport } = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const Professional = {
      name: user.user.nome,
      id: user.user._id,
    };
    dataSupport.token = user.accessToken;
    dataSupport.reason = inputValue.value;
    dataSupport.originProfessional = Professional;
    dataSupport.reasonDesc = inputValue.desc;
    dataSupport.origin = {
      channel: 'acionamentos',
      received: 'central',
      from: user?.user?.id_perfil?.descricao,
    };
    dataSupport.objectModel = {
      collectionName: 'acionamentosVonage',
      schemaName: 'acionamentosVonage',
    };

    if (inputValue.value.length > 1) {
      this.props.dispatch(actionForwarding.forwarding(dataSupport));
      this.setState({
        modalSupport: false,
        zeraDisplay: true,
      });
    }
  }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }

  refreshData() {
    window.location.reload(false);
  }

  render() {
    const {
      open,
      message,
      type,
      acionamentos,
      openModalAlert,
      typeModalAlert,
      messageModalAlert,
    } = this.props;
    let { regions } = this.props;
    const {
      acionamentosData,
      modalDeleteAcionamento,
      modalSupport,
      dataDeleteAcionamento,
      inputValueReason,
      zeraDisplay,
    } = this.state;
    const columns = [
      { title: 'ID Central', field: 'idCentral' },
      { title: 'Nome Social', field: 'socialName' },
      { title: 'Nome', field: 'fullName' },
      { title: 'Documento', field: 'document' },
      { title: 'Contrato', field: 'contrato' },
      { title: 'Idade', field: 'idade' },
      { title: 'Entrada', field: 'entrada' },
    ];

    // Lógica para manter acionamentos, eu pego os acionamento que estão com props e passo para State quando
    // realizo alguma acão. E aqui eu verifico se existe algum acionamento nos props, se não existir eu pego
    // os acionamentos que estão no state. É preciso fazer isso por que as props são zeradas a cada ação disparada.
    //
    const data = acionamentos
      ? acionamentos.length >= 1
        ? acionamentos
        : []
      : allAcionamentosLocal;

    return (
      <React.Fragment>
        <ModalDeleteAcionamento
          open={modalDeleteAcionamento}
          close={this.handlerModalDeleteAcionamento}
          dataDeleteAcionamento={dataDeleteAcionamento}
          onSubmit={(stateDelete) => {
            this.deleteAcionamento(
              data,
              dataDeleteAcionamento,
              inputValueReason
            );
            stateDelete.setState({ motivo: '' });
            this.handleChangeReason({ target: { value: '' } });
          }}
          inputValueReason={inputValueReason}
          dataAcionamento={data}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
        />

        <ModalSupport
          open={modalSupport}
          close={this.handlerModalSupport}
          onSubmit={() => this.handleConfirmForwarding(inputValueReason)}
          inputValueReason={inputValueReason}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
          emptyMotivo={zeraDisplay}
        />

        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          handleCancel={(typeModalAlert, messageModalAlert) =>
            this.handleCloseNewModal(typeModalAlert, messageModalAlert)
          }
        />

        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          options={{
            loadingType: 'linear',
          }}
          title=''
          columns={columns}
          data={(query) =>
            new Promise(async (resolve) => {
              let result = await acionamentoService.paginateAllAcionamentos(
                query.pageSize,
                query.page + 1,
                query.search
              );
              resolve({
                data: result.docs, // <--allAcionamentosLocal
                page: result.page - 1,
                totalCount: result.total,
              });
            })
          }
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Ótimo, não há paciente esperando.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse acionamento?',
              },
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => ({
              icon: VideoCallIcon,
              tooltip: 'VideoCall',
              disabled: !rowData.urlCall,
              hidden: !rowData.urlCall,
              onClick: (event, rowData) => this.handleOpenLink(rowData.urlCall),
            }),
            (rowData) => ({
              icon: Edit,
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                this.handleEditAcionamento(rowData);
              },
            }),
            {
              icon: BuildIcon,
              tooltip: 'Suporte',

              onClick: (event, rowData) => {
                this.handleOpenModaSupport(rowData);
              },
            },
            {
              icon: Delete,
              tooltip: 'Deletar',
              //disabled: rowData.passage,
              onClick: (event, rowData) => {
                this.handlerModalDeleteAcionamento(rowData);
              },
            },
            {
              icon: AddBox,
              isFreeAction: true,
              tooltip: 'Adicionar Acionamento',
              // onClick: () => history.push('/adm/acionamento')
              onClick: (event, rowData) => {
                this.onEditClick(null, rowData._id);
              },
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { acionamentos } = state.acionamento;
  const { forwardingSuporte } = state.forwardingSupport;
  const { message, type, open, loading } = state.alert;
  const { openModalAlert, typeModalAlert, messageModalAlert } =
    state.modalAlert;
  const { users } = state.users;
  return {
    acionamentos,
    forwardingSuporte,
    message,
    type,
    open,
    loading,
    users,
    openModalAlert,
    typeModalAlert,
    messageModalAlert,
  };
}

Fila.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(Fila);
export { connectedLoginPage as Fila };
