import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const cFinalizadosService = {
  GetCancelamentosFinalizados,
};

function GetCancelamentosFinalizados() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/finalization/1/5`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
