import React from 'react';
import MenuPainel from '../components/MenuPainel';
import PaperForm from '../components/PaperForm';
import { Historico } from '../components/Historico';
import { ChangePassword } from '../components/ChangePassword';

class Historicolist extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ChangePassword />
        <MenuPainel stepper='Histórico'>
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<Historico />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = Historicolist;
export { connectedAssinar as Historicolist };
