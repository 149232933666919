import React, { createRef, useState } from 'react';
import OlhoAberto from '../../../icons/icon_olho_aberto.svg';
import OlhoFechado from '../../../icons/icon_olho_fechado.svg';
import { userService } from '../../../services/user.service';
import { useDispatch } from 'react-redux';

//Actions
import { userActions } from '../../../actions';

import {
  Container,
  ContainerMenu,
  Title,
  InformationCount,
  DivInformationCount,
  ContainerButton,
  ContainerLoader,
} from './styles';

import Loader from '../Loader';
import { useEffect } from 'react';
import { ALL_QUEUES_IDS } from '../utils/config';
import CustomButton from '../CustomButton';
const containerUl = createRef();

const CustomMenu = ({
  data,
  title,
  parentCallBack,
  filter,
  setFilterPage,
  setPageNumber,
  receivedEnabledQueues,
  controlOVerlay,
  setCodFila,
}) => {
  const [index, setIndex] = useState(0);

  function handleSort(data) {
    return data.sort(function (a, b) {
      return a.order - b.order;
    });
  }

  const [editQueues, setEditQueues] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [enableQueues, setEnabledQueues] = useState([]);
  const [loaderOn, setLoader] = useState(false);
  const dispatch = useDispatch();

  const confirmSelect = async () => {
    setLoader(true);
    await userService.setPersonalizedQueues(enableQueues);

    setFilterPage(1);
    setPageNumber(1);

    dispatch(userActions.getEnabledQueues(setLoader));
  };

  const confirmCancel = async () => {
    setFilterPage(1);
    setPageNumber(1);
  };

  useEffect(() => {
    const filasExistentes =
      receivedEnabledQueues?.enabledQueuesGet?.filas?.length > 0
        ? receivedEnabledQueues?.enabledQueuesGet?.filas
        : ALL_QUEUES_IDS;
    setEnabledQueues(filasExistentes);
  }, [receivedEnabledQueues]);

  let dataLoading = Object.keys(data).length > 0 ? true : false;

  return (
    <>
      <Container>
        <Title className={'rubik-upper'}>{title}</Title>
        <ContainerMenu
          inEdit={false}
          style={{
            minHeight: enableQueues?.length <= 5 ? '100px' : '400px',
          }}
        >
          {dataLoading && !loaderOn ? (
            <nav>
              <ul ref={containerUl}>
                {data &&
                  handleSort(data).map((item, i) => {
                    return (
                      <li
                        style={{
                          padding: '10px 0 40px 0',
                          cursor: enableQueues?.includes(item.idFila)
                            ? 'pointer'
                            : 'default',
                          display:
                            !enableQueues?.includes(item.idFila) && !editQueues
                              ? 'none'
                              : 'block',
                        }}
                        className={`rubik-regular ${
                          item.idFila === filter ? 'selected' : ''
                        }`}
                        key={item._id}
                        id={`ele${i}`}
                        //onMouseOver={(e) => handleOver(e, i)}
                        onClick={() => {
                          if (
                            !editQueues &&
                            enableQueues?.includes(item.idFila)
                          ) {
                            setIndex(i);
                            parentCallBack(item.idFila, item.descricaoFila);
                            setCodFila(item.descricaoFila);
                            controlOVerlay();
                          }
                        }}
                      >
                        <DivInformationCount>
                          <div>
                            <span
                              style={{
                                fontWeight: enableQueues?.includes(item.idFila)
                                  ? 'bold'
                                  : 'normal',
                              }}
                            >
                              {editQueues && (
                                <button
                                  onClick={(e) => {
                                    if (enableQueues?.includes(item.idFila)) {
                                      setEnabledQueues((prevState) =>
                                        prevState.filter(
                                          (id) => id !== item.idFila
                                        )
                                      );
                                    } else {
                                      setEnabledQueues((prevState) => [
                                        ...prevState,
                                        item.idFila,
                                      ]);
                                    }
                                    e.stopPropagation();
                                  }}
                                >
                                  {/* ID 1 = Fila "Todas", retira o olhinho do fila "Todas" */}
                                  {item.idFila !== 1 ? (
                                    <>
                                      {!enableQueues?.includes(item.idFila) ? (
                                        <img
                                          src={OlhoFechado}
                                          width='18'
                                          height='18'
                                          alt='Desabilitado'
                                        />
                                      ) : (
                                        <img
                                          src={OlhoAberto}
                                          width='18'
                                          height='18'
                                          alt='Habilitado'
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </button>
                              )}
                              {item.fila}
                            </span>
                          </div>
                          {item?.qtd > 0 && (
                            <InformationCount
                              disabled={!enableQueues?.includes(item.idFila)}
                            >
                              <span>{item.qtd}</span>
                            </InformationCount>
                          )}
                        </DivInformationCount>
                      </li>
                    );
                  })}
              </ul>
              {enableQueues?.length >= 1 && !editQueues && !confirmEdit && (
                <ContainerButton>
                  <CustomButton
                    width='100%'
                    onClick={(e) => {
                      setEditQueues(true);
                    }}
                    size={'small'}
                    typeButtom={'primaryHighlight'}
                  >
                    Personalizar
                  </CustomButton>
                </ContainerButton>
              )}

              {editQueues && (
                <>
                  <ContainerButton>
                    <CustomButton
                      width='100%'
                      onClick={(e) => {
                        confirmSelect();
                        setConfirmEdit(false);
                        setEditQueues(false);
                      }}
                      size={'small'}
                      typeButtom={'primaryHighlight'}
                    >
                      Confirmar
                    </CustomButton>
                  </ContainerButton>

                  <ContainerButton>
                    <CustomButton
                      onClick={(e) => {
                        confirmCancel();
                        setEditQueues(false);
                        dispatch(userActions.getEnabledQueues(setLoader));
                      }}
                      width='100%'
                      size={'small'}
                      typeButtom={'dangerHighlight'}
                    >
                      Cancelar
                    </CustomButton>
                  </ContainerButton>
                </>
              )}
            </nav>
          ) : (
            <ContainerLoader>
              <Loader background={'#007ACC'} />
            </ContainerLoader>
          )}
        </ContainerMenu>
      </Container>
    </>
  );
};

export default CustomMenu;
