// libs
import { useMemo } from 'react';

import { QueueCopyAction } from '../QueueCopyAction';

import * as S from './styles';

interface QueueProfessionalInfoProps {
  itemData?: Record<string, any>;
  isClinicQueue?: boolean;
  isScheduled?: boolean;
  children: React.ReactNode;
}

export function QueueProfessionalInfo({
  itemData,
  children,
  isClinicQueue,
  isScheduled,
}: QueueProfessionalInfoProps) {
  const unityData: Record<string, string> = itemData?.unitOfAttendance;

  const isValidProfessionalName =
    unityData?.professionalName &&
    typeof unityData?.professionalName === 'string';
  const isValidProfessionalSpecialty =
    unityData?.speciality && typeof unityData?.speciality === 'string';
  const isValidProfessionalUnit =
    unityData?.unit && typeof unityData?.unit === 'string';

  const formattedDataToCopy = useMemo<string | null>(() => {
    let result = '';

    if (isValidProfessionalName)
      result += `Nome do profissional: ${unityData.professionalName}\n`;

    if (isValidProfessionalSpecialty)
      result += `Especialidade: ${unityData.speciality}\n`;

    if (isValidProfessionalUnit) result += `Unidade: ${unityData.unit}`;

    if (!result) return null;

    return result;
  }, [unityData]);

  return (
    <>
      {process.env.REACT_APP_SGH_SEARCH_ON === 'true' &&
      isClinicQueue &&
      isScheduled ? (
        <S.ProfissionalCol className='ProfissionalInfo'>
          <S.ProfessionalWrapper>
            {isValidProfessionalName && (
              <S.ProfessionalInfo>
                {unityData.professionalName}
              </S.ProfessionalInfo>
            )}
            {isValidProfessionalSpecialty && (
              <S.ProfessionalInfo>{unityData?.speciality}</S.ProfessionalInfo>
            )}
            {isValidProfessionalUnit && (
              <S.ProfessionalInfo>{unityData?.unit}</S.ProfessionalInfo>
            )}

            {!isValidProfessionalName &&
              !isValidProfessionalSpecialty &&
              !isValidProfessionalUnit && (
                <S.ProfessionalInfo>
                  Nenhuma informação disponível.
                </S.ProfessionalInfo>
              )}

            <QueueCopyAction dataToCopy={formattedDataToCopy} />
          </S.ProfessionalWrapper>
        </S.ProfissionalCol>
      ) : (
        children
      )}
    </>
  );
}
