import React from 'react';

import DatePicker from 'react-multi-date-picker';

import DatePanel from 'react-multi-date-picker/plugins/date_panel';

import useDatePicker from './hooks/useDatePicker';

import { gregorian_en_lowercase } from './constants';

import { CustomDivider, CustomText } from '../../..';

import { StyledContainer } from './styles';

const stylesDataPicker = {
  height: 45,
  width: 343,
  borderRadius: 4,
  borderColor: '#dfe3e8',
  borderWidth: 1,
  paddingLeft: 16,
  borderStyle: 'solid',
  color: '#6b7786',
  marginTop: 7,
};

const CustomDatePicker = ({
  title,
  onFocusedDateChange,
  onClose,
  value,
  reset,
  type = 'date',
}) => {
  const { setValueDate, valueDate } = useDatePicker(value, reset);

  const CustomMultiple = ({ openCalendar, value }) => (
    <input onFocus={openCalendar} value={value} style={stylesDataPicker} />
  );

  return (
    <StyledContainer>
      {title !== '' && <CustomText>{title}</CustomText>}

      <CustomDivider space={0} />

      {type === 'range' ? (
        <DatePicker
          onFocusedDateChange={onFocusedDateChange}
          value={valueDate}
          onClose={onClose}
          onChange={setValueDate}
          locale={gregorian_en_lowercase}
          render={<CustomMultiple value={value} />}
          range
          required
          className='customDatePicker'
          format='DD/MM/YYYY'
          placeholder='Selecione as datas'
        />
      ) : (
        <DatePicker
          onFocusedDateChange={onFocusedDateChange}
          onClose={onClose}
          value={valueDate}
          onChange={setValueDate}
          locale={gregorian_en_lowercase}
          minDate={new Date()}
          render={<CustomMultiple value={value} />}
          plugins={[<DatePanel header='Datas' markFocused />]}
          multiple
          required
          className='customDatePicker'
          placeholder='Selecione as datas'
          format='DD/MM/YYYY'
        />
      )}
    </StyledContainer>
  );
};

export default CustomDatePicker;
