import styled from 'styled-components';
import banner_cad from '../../images/img/Banner.png';

export const TemplatePage = styled.div`
  background: url(${banner_cad});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 99vh;
  @media only screen and (max-width: 900px) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
`;
