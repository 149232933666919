import React from 'react';
import { HttpQueueRecordResendCockpit } from '../../../../domain/http';
import { HttpClientAdapter } from '../../../../infra/adapters';
import { ResendCockpitData } from '../../../../application/data';

export const MakeResendCockpit = () => {
  const httpClientAdapter = new HttpClientAdapter();
  const httpClient = new HttpQueueRecordResendCockpit(httpClientAdapter);
  return new ResendCockpitData(httpClient);
};
