const axios = require('axios');

export async function getTime() {
  try {
    const response = await axios.get(
      'https://worldtimeapi.org/api/timezone/America/Sao_Paulo'
    );
    const { datetime } = response.data;

    return datetime;
  } catch (error) {
    console.error('Erro ao obter o timestamp:', error.message);
  }
}
