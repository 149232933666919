import React, { forwardRef } from 'react';
import { IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';
import ModalDeleteAcionamento from './ModalDeleteAcionamento';
import AddBox from '@material-ui/icons/AddBox';
import Duo from '@material-ui/icons/Duo';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  acionamentoActions,
  acionamentoActionsVonage,
  actionForwarding,
} from '../actions';
import BuildIcon from '@material-ui/icons/Build';
import { history } from '../helpers';
import { acionamentoVonageService } from '../services';
import allAcionamentosLocal from '../helpers/acionamentosComArquivosexclusao.json';
import ModalSupport from './ModalSupport';
import ModalAlert from './ModalAlert';
import { modalAlertActions } from '../actions';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class FilaVonage extends React.Component {
  tableRef = React.createRef();
  constructor(props) {
    //props.dispatch(acionamentoActionsVonage.getAllAcionamentos())
    super(props);
    this.state = {
      acionamentosData: [],
      modalDeleteAcionamento: false,
      modalSupport: false,
      dataDeleteAcionamento: {},
      inputValueReason: '',
      zeraDisplay: false,
      openModalAlert: false,
      typeModalAlert: '',
      messageModalAlert: '',
    };
    this.handlerModalDeleteAcionamento =
      this.handlerModalDeleteAcionamento.bind(this);
    this.handleConfirmForwarding = this.handleConfirmForwarding.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.handlerModalSupport = this.handlerModalSupport.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.handleOpenModalNewAttendance =
      this.handleOpenModalNewAttendance.bind(this);
    this.handleCloseModalNewAttendance =
      this.handleCloseModalNewAttendance.bind(this);
    this.handleConfirmNewAttendance =
      this.handleConfirmNewAttendance.bind(this);
  }
  componentDidMount() {
    //this.props.dispatch(acionamentoActionsVonage.getAllAcionamentos())
  }

  handleCloseNewModal() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({
      openModalAlert: false,
      messageModalAlert: '',
      loadingsub: false,
    });
    //this.refreshData();
  }

  handleDeleteAcionamento = (id, idAttendanceVonage, reason) => {
    this.props.dispatch(
      acionamentoActionsVonage.deleteAcionamentos(
        id,
        idAttendanceVonage,
        reason
      )
    );
  };

  handleEditAcionamento = (acionamento) => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    this.props.dispatch(
      acionamentoActionsVonage.editAcionamentos({
        ...acionamento,
        editionTime,
      })
    );
  };

  handleEditAcionamentoComum = (acionamento) => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    this.props.dispatch(
      acionamentoActions.editAcionamentos({ ...acionamento, editionTime })
    );
  };

  handleOpenLink = (userData) => {
    userData.date = userData.birthDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let confirmedTime = new Date(Date.now() - tzoffset).toISOString();
    if (userData.convenio !== '' && userData.name) {
      this.props.dispatch(
        acionamentoActionsVonage.attendanceAcionamentos({
          ...userData,
          confirmedTime,
        })
      );
    }
  };

  componentWillMount() {
    this.intervalID = setInterval(
      () => this.tableRef.current.onQueryChange(),
      2000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.props.dispatch(
      acionamentoActionsVonage.editAcionamentosWithoutRedirect()
    );
  }

  handlerModalDeleteAcionamento(oldData) {
    const { modalDeleteAcionamento } = this.state;
    this.setState({
      modalDeleteAcionamento: !modalDeleteAcionamento,
      dataDeleteAcionamento: oldData,
    });
  }
  handlerModalSupport(oldData) {
    const { modalSupport } = this.state;
    this.setState({
      modalSupport: !modalSupport,
      dataSupport: oldData,
      zeraDisplay: true,
    });
  }

  handleOpenModaSupport(data) {
    this.setState({
      modalSupport: true,
      dataSupport: data,
      zeraDisplay: false,
    });
  }

  handleConfirmForwarding(inputValue) {
    const { dataSupport } = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const Professional = {
      name: user.user.nome,
      id: user.user._id,
    };

    dataSupport.token = user.accessToken;
    dataSupport.reason = inputValue.value;
    dataSupport.reasonDesc = inputValue.desc;
    dataSupport.originProfessional = Professional;
    dataSupport.origin = {
      channel: 'acionamentos-vonage',
      received: 'central',
      from: user?.user?.id_perfil?.descricao,
    };
    dataSupport.objectModel = {
      collectionName: 'acionamentosVonage',
      schemaName: 'AcionamentosVonage',
    };

    if (inputValue.value.length > 1) {
      this.props.dispatch(actionForwarding.forwarding(dataSupport));

      this.setState({
        modalSupport: false,
        zeraDisplay: true,
      });
      this.refreshData();
    }
  }

  onEditClick() {
    history.push('/adm/vonage-acionamento');
  }

  deleteAcionamento(data, oldData, reason) {
    const index = data.indexOf(oldData);
    data.splice(index, 1);
    if (this.state.inputValueReason !== '') {
      this.setState(
        { data, acionamentosData: data, inputValueReason: '' },
        () =>
          this.handleDeleteAcionamento(
            oldData._id,
            oldData.idAttendanceVonage,
            reason
          )
      );
      this.handlerModalDeleteAcionamento();
    }
  }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }

  refreshData() {
    window.location.reload(false);
  }
  handleOpenModalNewAttendance(rowData) {
    this.setState({ newAttendance: true, rowData: rowData });
  }
  handleCloseModalNewAttendance() {
    this.setState({ newAttendance: false, rowData: {} });
  }
  async handleConfirmNewAttendance() {
    let rowData = this.state.rowData;
    this.handleEditAcionamento(rowData);
  }
  render() {
    const { acionamentos, openModalAlert, typeModalAlert, messageModalAlert } =
      this.props;

    let sessionInfos = localStorage.getItem('user');
    sessionInfos = JSON.parse(sessionInfos);
    let token = sessionInfos.token;
    let userData = {
      name: sessionInfos.user.nome,
      id: sessionInfos.user._id,
      email: sessionInfos.user.email,
    };

    const {
      acionamentosData,
      modalDeleteAcionamento,
      dataDeleteAcionamento,
      inputValueReason,
      modalSupport,
      zeraDisplay,
    } = this.state;
    const columns = [
      {
        title: 'Status',
        field: 'currentEditionAttendanceInfo',
        render: (rowData) => {
          if (
            rowData.currentEditionAttendanceInfo &&
            rowData.currentEditionAttendanceInfo.userName
          ) {
            return (
              <a>
                Em atendimento com{' '}
                {rowData.currentEditionAttendanceInfo.userName}
              </a>
            );
          }
        },
      },
      { title: 'ID Central', field: 'passage' },
      { title: 'Nome Social', field: 'socialName' },
      { title: 'Nome', field: 'fullName' },
      { title: 'Documento', field: 'document' },
      { title: 'Contrato', field: 'contrato' },
      { title: 'Idade', field: 'idade' },
      { title: 'Entrada', field: 'entrada' },
    ];
    // Lógica para manter acionamentos, eu pego os acionamento que estão com props e passo para State quando
    // realizo alguma acão. E aqui eu verifico se existe algum acionamento nos props, se não existir eu pego
    // os acionamentos que estão no state. É preciso fazer isso por que as props são zeradas a cada ação disparada.
    //
    const data = acionamentos
      ? acionamentos.length >= 1
        ? acionamentos
        : []
      : allAcionamentosLocal;

    return (
      <React.Fragment>
        <ModalDeleteAcionamento
          open={modalDeleteAcionamento}
          close={this.handlerModalDeleteAcionamento}
          dataDeleteAcionamento={dataDeleteAcionamento}
          onSubmit={(stateDelete) => {
            this.deleteAcionamento(
              data,
              dataDeleteAcionamento,
              inputValueReason
            );
            stateDelete.setState({ motivo: '' });
            this.handleChangeReason({ target: { value: '' } });
          }}
          inputValueReason={inputValueReason}
          dataAcionamento={data}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
        />

        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          handleCancel={(typeModalAlert, messageModalAlert) =>
            this.handleCloseNewModal(typeModalAlert, messageModalAlert)
          }
        />

        <ModalSupport
          open={modalSupport}
          close={this.handlerModalSupport}
          onSubmit={() => this.handleConfirmForwarding(inputValueReason)}
          inputValueReason={inputValueReason}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
          emptyMotivo={zeraDisplay}
        />
        {/* <Modal
          open={newAttendance}
          withActions
          text={
            'Chamada já está em atendimento, Tem certeza que deseja entrar?'
          }
          withClose
          handleCancel={() => this.handleCloseModalNewAttendance}
          handleConfirm={() => this.handleConfirmNewAttendance}
          labelOk={'Sim'}
          labelCancel={'Não'}
        /> */}

        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          options={{
            loadingType: 'linear',
          }}
          title=''
          columns={columns}
          data={(query) =>
            new Promise(async (resolve) => {
              let result =
                await acionamentoVonageService.paginateAllAcionamentos(
                  query.pageSize,
                  query.page + 1,
                  query.search
                );
              resolve({
                data: result.docs, // <--allAcionamentosLocal
                page: result.page - 1,
                totalCount: result.total,
              });
            })
          }
          // components={{
          //     Action:  props => {
          //         if (props.action.icon === 'edit') {
          //             return (
          //                 <IconButton size="medium" onClick={(event) => props.action.onClick(event, props.data)}>
          //                     <Edit fontSize="inherit" />
          //                 </IconButton>
          //             )
          //         }
          //         if (props.action.icon === 'AddBox') {
          //             return (
          //                 <IconButton size="medium" onClick={(event) => props.action.onClick(event, props.data)}>
          //                     <AddBox fontSize="inherit" />
          //                 </IconButton>
          //             )
          //         }
          //         return ('')
          //     }
          // }}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Ótimo, não há paciente esperando.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse acionamento?',
              },
            },
            toolbar: {
              searchTooltip: 'Pesquisar',
              searchPlaceholder: 'Pesquisar',
            },
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from} - {to} de {count}',
              firstTooltip: 'Voltar para a primeira página',
              previousTooltip: 'Voltar',
              nextTooltip: 'Proxima',
              lastTooltip: 'Ir para ultima página',
            },
          }}
          actions={[
            (rowData) => ({
              icon: KeyboardReturnIcon,
              tooltip: 'Retornar ao atendimento',
              hidden:
                rowData.currentEditionAttendanceInfo &&
                rowData.currentEditionAttendanceInfo.userId !=
                  sessionInfos.user._id,
              onClick: async (event, rowData) => {
                await acionamentoActionsVonage.updateStatusConecta(
                  rowData,
                  'recepcao',
                  token,
                  userData
                );
                await acionamentoActionsVonage
                  .updateStatusAttendance(rowData)
                  .then(() => {
                    this.handleEditAcionamento(rowData);
                  })
                  .catch((err) => {
                    console.log('erro ao editar');
                  });
              },
            }),
            (rowData) => ({
              icon: Edit,
              tooltip: 'Editar',
              disabled:
                rowData.currentEditionAttendanceInfo.waitingForDateSchedule,
              hidden:
                rowData.currentEditionAttendanceInfo &&
                rowData.currentEditionAttendanceInfo.userName,
              onClick: async (event, rowData) => {
                if (
                  rowData &&
                  rowData.idAttendanceVonage &&
                  rowData.idAttendanceVonage != 'Não informado'
                ) {
                  await acionamentoActionsVonage.updateStatusConecta(
                    rowData,
                    'recepcao',
                    token,
                    userData
                  );
                  await acionamentoActionsVonage
                    .updateStatusAttendance(rowData)
                    .then(() => {
                      this.handleEditAcionamento(rowData);
                    })
                    .catch((err) => {
                      console.log('erro ao editar');
                    });
                } else {
                  await acionamentoActionsVonage
                    .updateStatusAttendance(rowData)
                    .then(() => {
                      this.handleEditAcionamentoComum(rowData);
                    })
                    .catch((err) => {
                      console.log('erro ao editar');
                    });
                }
              },
            }),
            (rowData) => ({
              icon: Duo,
              tooltip: `Em atendimento com ${rowData.currentEditionAttendanceInfo.userName}`,
              hidden: !(
                rowData.currentEditionAttendanceInfo &&
                rowData.currentEditionAttendanceInfo.userName
              ),
            }),
            (rowData) => ({
              icon: Delete,
              tooltip: 'Deletar',
              hidden:
                rowData.currentEditionAttendanceInfo &&
                rowData.currentEditionAttendanceInfo.userName,
              //disabled: rowData.passage,
              onClick: (event, rowData) => {
                this.handlerModalDeleteAcionamento(rowData);
              },
            }),
            {
              icon: AddBox,
              isFreeAction: true,
              tooltip: 'Adicionar Acionamento',
              // onClick: () => history.push('/adm/acionamento')
              onClick: (event, rowData) => {
                //this.onEditClick(null, rowData._id);
                history.push('/adm/acionamento');
              },
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { acionamentos } = state.acionamento;
  const { openModalAlert, typeModalAlert, messageModalAlert } =
    state.modalAlert;

  return {
    acionamentos,
    openModalAlert,
    typeModalAlert,
    messageModalAlert,
  };
}

FilaVonage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(FilaVonage);
export { connectedLoginPage as FilaVonage };
