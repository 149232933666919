import { ButtonProps } from './types';

import * as S from './styles';

export function CustomButton({
  typeButtom = 'primary',
  onClick,
  icon,
  size = 'small',
  text,
  margin,
  children,
  focusColor,
  fontSize,
  border,
  disable,
  padding,
  color,
  fontWeight,
  type,
  width,
  height,
  id,
  clicked,
  toBeDisabled,
  noWrap,
  disabled,
}: ButtonProps) {
  if (id === clicked) disable = false;
  if (
    id == toBeDisabled &&
    toBeDisabled !== null &&
    toBeDisabled !== undefined
  ) {
    disable = true;
  }

  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    if (typeof onClick === 'function') onClick(evt);
  };

  return (
    <S.Wrapper
      disabled={disabled || disable}
      type={type}
      id={id}
      onClick={handleClick}
      $typeButtom={typeButtom}
      $focusColor={focusColor}
      $border={border}
      $size={size}
      $width={width}
      $height={height}
      $fontSize={fontSize}
      $padding={padding}
      $margin={margin}
      $color={color}
    >
      <S.ContentWrapper
        $color={color}
        $fontWeight={fontWeight}
        $noWrap={noWrap}
      >
        {icon && icon} {text && <span> {text} </span>}
        {children && children}
      </S.ContentWrapper>
    </S.Wrapper>
  );
}
