import React from 'react';
import MenuPainel from '../components/MenuPainel';
import PaperForm from '../components/PaperForm';
import { HistoricoSupport } from '../components/HistoricoSupport';
import { ChangePassword } from '../components/ChangePassword';
import { CssBaseline } from '@material-ui/core';

class HistoricoSuport extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <ChangePassword />
        <MenuPainel stepper='Histórico de Suporte'>
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<HistoricoSupport />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

const connectedAssinar = HistoricoSuport;
export { connectedAssinar as HistoricoSuport };
