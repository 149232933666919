import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const acionamentoSuporteService = {
  editAcionamentosSuporte,
};

function editAcionamentosSuporte(
  id,
  supportCompleted,
  generalObservations = ''
) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  let { _id, email, nome, login, telefone } = user.user;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      _id: user.user._id,
    },
    body: JSON.stringify({
      supportUser: { _id, email, nome, login, telefone },
      supportCompleted,
      generalObservations,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/editSupport/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
