import React, { forwardRef } from 'react';
import { IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';
import ModalDeleteAcionamento from './ModalDeleteAcionamento';
import AddBox from '@material-ui/icons/AddBox';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import BuildIcon from '@material-ui/icons/Build';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import {
  acionamentoActions,
  actionForwarding,
  modalAlertActions,
  alertActions,
} from '../actions';
import { history } from '../helpers';
import { acionamentoService } from '../services';
import allAcionamentosLocal from '../helpers/acionamentosComArquivosexclusao.json';
import ModalSupport from './ModalSupport';
import ModalAlert from './ModalAlert';
import moment from 'moment';
import ModalChangeEmail from './ModalChangeEmail';
import { validations } from '../helpers/validations';

class FilaPortalConecta extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      acionamentosData: [],
      modalDeleteAcionamento: false,
      modalSupport: false,
      dataDeleteAcionamento: {},
      dataSupport: {},
      inputValueReason: '',
      inputEmailChange: '',
      submitted: false,
      zeraDisplay: false,
      tokenPaginate: '',
      openModalAlert: false,
      openModalChangeEmail: false,
      idUserEmail: '',
      typeModalAlert: 'message',
      messageModalAlert:
        'Certifique-se de que o cliente está recebendo os e-mails e de que o e-mail a ser verificado está igual ao selecionado antes de seguir com essa operação, deseja mesmo prosseguir?',
      emailVerification: '',
      idUser: '',
    };
    this.handlerModalDeleteAcionamento =
      this.handlerModalDeleteAcionamento.bind(this);
    // this.refreshData = this.refreshData.bind(this);
    this.handleConfirmForwarding = this.handleConfirmForwarding.bind(this);
    this.handlerModalSupport = this.handlerModalSupport.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.confirmarV2 = this.confirmarV2.bind(this);
    this.onCloseChangeEmail = this.onCloseChangeEmail.bind(this);
    this.onHandleEmailChange = this.onHandleEmailChange.bind(this);
    this.onHandleConfirm = this.onHandleConfirm.bind(this);
  }

  componentDidMount() {}

  handleDeleteAcionamento = (id, reason) => {
    this.props.dispatch(acionamentoActions.deleteAcionamentos(id, reason));
  };

  handleEditAcionamento = (acionamento) => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let editionTime = new Date(Date.now() - tzoffset).toISOString();
    this.props.dispatch(
      acionamentoActions.editAcionamentos({ ...acionamento, editionTime })
    );
  };

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  componentWillMount() {
    const { dispatch } = this.props;
    this.props.dispatch(modalAlertActions.clear());

    //this.intervalID = setInterval(() => dispatch(acionamentoActions.getAllAcionamentos()), 4000)
  }

  handleCloseNewModal() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({
      openModalAlert: false,
    });
  }

  handleClose() {
    //this.props.dispatch(alertActions.clear());
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.props.dispatch(modalAlertActions.clear());
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(acionamentoActions.editAcionamentosWithoutRedirect());
  }

  handleClose() {
    this.props.dispatch(alertActions.clear(this.props));
  }

  handlerModalDeleteAcionamento(oldData) {
    const { modalDeleteAcionamento } = this.state;
    this.setState({
      modalDeleteAcionamento: !modalDeleteAcionamento,
      dataDeleteAcionamento: oldData,
    });
  }

  handlerModalSupport(oldData) {
    const { modalSupport } = this.state;
    this.setState({
      modalSupport: !modalSupport,
      dataSupport: oldData,
      zeraDisplay: true,
    });
  }

  handleOpenModaSupport(data) {
    this.setState({
      modalSupport: true,
      dataSupport: data,
      zeraDisplay: false,
    });
  }

  onEditClick() {
    history.push('/adm/acionamento');
  }

  deleteAcionamento(data, oldData, reason) {
    const index = data.indexOf(oldData);
    data.splice(index, 1);
    if (this.state.inputValueReason !== '') {
      this.setState(
        { data, acionamentosData: data, inputValueReason: '' },
        () => this.handleDeleteAcionamento(oldData._id, reason)
      );
      this.handlerModalDeleteAcionamento();
    }
  }
  handleConfirmForwarding(inputValue) {
    const { dataSupport } = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    let data = { dataSupport };
    data.token = user.accessToken;
    data.name = user.user.nome;
    data._id = user.user._id;
    data.motivo = inputValue;

    if (!data.deviceData) {
      data.deviceData = {
        deviceType: 'Não Recebido',
        OS: '',
        navigator: '',
        route: '/',
        accessChannel: '',
        appVersion: '',
        deviceBrand: '',
      };
    }

    if (inputValue.length > 1) {
      this.props.dispatch(actionForwarding.forwarding(data));
      this.setState({
        modalSupport: false,
        zeraDisplay: true,
      });
    }
  }

  handleChangeReason(e) {
    const { value } = e.target;
    this.setState({ inputValueReason: value });
  }
  refreshData() {
    window.location.reload(false);
  }

  confirmar(rowData) {
    if (rowData && rowData.UserStatus != 'Não Confirmado') return true;
    this.props.dispatch(
      acionamentoActions.confirmUserCognito(rowData.Username, this)
    );
  }

  confirmarV2(rowData) {
    if (rowData && rowData.UserStatus != 'Não Confirmado') return true;
    this.props.dispatch(acionamentoActions.confirmUserCognitoV2(rowData, this));
  }

  alterarSenha(rowData) {
    this.props.dispatch(
      acionamentoActions.changePassword(rowData.Username, this)
    );
  }

  recoverAccountV2(rowData) {
    this.props.dispatch(acionamentoActions.recoverAccountV2(rowData.id, this));
  }

  verifyEmail() {
    let { idUser } = this.state;

    this.props.dispatch(acionamentoActions.verifyEmailV2(idUser, this));
    this.setState({ openModalAlert: false, idUser: '' });
  }

  openVerifyEmail(rowData) {
    this.setState({
      openModalAlert: true,
      idUser: rowData.id,
    });
  }

  openChangeEmail(rowData) {
    this.setState({
      openModalChangeEmail: true,
      idUserEmail: rowData.id,
    });
  }

  onCloseChangeEmail() {
    this.setState({
      openModalChangeEmail: false,
      idUserEmail: '',
      submitted: false,
    });
  }

  onHandleConfirm() {
    const { inputEmailChange, idUserEmail } = this.state;
    const validate = this.validationLogin(inputEmailChange);
    if (validate) {
      this.props.dispatch(
        acionamentoActions.changeEmailUserConectaV2(
          idUserEmail,
          inputEmailChange,
          this
        )
      );
      this.setState({
        openModalChangeEmail: false,
        idUserEmail: '',
        submitted: false,
      });
    } else {
      this.setState({ submitted: true });
    }
  }

  validationLogin(value) {
    let validateEmail;
    if (value !== '') {
      validateEmail = validations.validationEmail(value);
    }
    if (validateEmail === false) {
      return true;
    } else {
      return false;
    }
  }

  onHandleEmailChange(e) {
    const { value } = e.target;
    this.setState({ inputEmailChange: value });
  }

  resetarSenha(rowData) {
    this.props.dispatch(
      acionamentoActions.resetPassword(rowData.Username, this)
    );
  }

  async fetchUsers(query) {
    let resultV2 = await acionamentoService.paginateUsersV2('0');

    if (query.search) {
      resultV2 = await acionamentoService.paginateUsersV2(query.search);
    }

    if (resultV2.data) {
      const availableDicionary = {
        true: `✔️`,
        false: `❌`,
      };

      const statusDicionary = {
        CONFIRMED: 'Confirmado',
        UNCONFIRMED: 'Não Confirmado',
        RESET_REQUIRED: 'Precisa de Reset',
      };

      const statusEmail = {
        Confirmado: true,
        'Não Confirmado': false,
        'Precisa de Reset': false,
      };

      resultV2.data = resultV2.data.map((user) => {
        user.emailVerified = ``;
        if (user.cognito) {
          user.emailVerified =
            availableDicionary[
              statusEmail[statusDicionary[user.cognito.status]]
            ];
          user.Enabled = availableDicionary[user.cognito.enabled];
          if (user.cognito.createdAt)
            user.UserCreateDate = moment(
              user.cognito.createdAt,
              'YYYY-MM-DDTHH:mm:ss'
            )
              .add(-3, 'hours')
              .format('DD/MM/YYYY HH:mm:ss')
              .toString();
          if (user.cognito.updatedAt)
            user.UserLastModifiedDate = moment(
              user.cognito.updatedAt,
              'YYYY-MM-DDTHH:mm:ss'
            )
              .add(-3, 'hours')
              .format('DD/MM/YYYY HH:mm:ss')
              .toString();
        }

        return { ...user };
      });
    }

    return {
      data: resultV2.data,
      page: query.page,
      totalCount: resultV2.data.length,
    };
  }

  render() {
    const { open, message, type, acionamentos } = this.props;

    const {
      modalDeleteAcionamento,
      modalSupport,
      dataDeleteAcionamento,
      inputValueReason,
      zeraDisplay,
      typeModalAlert,
      messageModalAlert,
      openModalAlert,
      emailVerification,
      openModalChangeEmail,
    } = this.state;

    const columns = [
      {
        field: 'Ações',
        title: 'Ações',
        render: (rowData) => (
          <div style={{ width: '460px' }}>
            <a
              onClick={() => this.confirmarV2(rowData)}
              style={
                rowData && rowData.UserStatus != 'Não Confirmado'
                  ? {
                      opacity: '0.5',
                      color: '#fff',
                      backgroundColor: '#3F51B5',
                      padding: '3px',
                      marginRight: '10px',
                      borderRadius: '5px',
                      cursor: 'default',
                    }
                  : {
                      color: '#fff',
                      backgroundColor: '#3F51B5',
                      padding: '3px',
                      marginRight: '10px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }
              }
            >
              Confirmar
            </a>
            <a
              onClick={() => this.recoverAccountV2(rowData)}
              style={{
                color: '#fff',
                backgroundColor: '#3F51B5',
                padding: '3px',
                marginRight: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Alterar senha
            </a>
            {/* <a
              onClick={() => this.resetarSenha(rowData)}
              style={{
                color: '#fff',
                backgroundColor: '#3F51B5',
                padding: '3px',
                marginRight: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Alterar Senha
            </a> */}
            <a
              onClick={() => this.openVerifyEmail(rowData)}
              style={{
                color: '#fff',
                backgroundColor: '#3F51B5',
                padding: '3px',
                marginRight: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Confirmar E-mail
            </a>
            <a
              onClick={() => this.openChangeEmail(rowData)}
              style={{
                color: '#fff',
                backgroundColor: '#3F51B5',
                padding: '3px',
                marginRight: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Trocar E-mail
            </a>
          </div>
        ),
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'E-mail',
        field: 'email',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'E-mail Verificado',
        field: 'emailVerified',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'Nome',
        field: 'firstName',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'left' },
      },
      {
        title: 'Sobrenome',
        field: 'lastName',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'left' },
      },
      {
        title: 'Documento',
        field: 'document',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'left' },
      },
      {
        title: 'Status',
        field: 'UserStatus',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'Habilitado',
        field: 'Enabled',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'Data Cadastro',
        field: 'UserCreateDate',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },

      {
        title: 'Data atualização',
        field: 'UserLastModifiedDate',
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
      },
    ];

    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, pref) => <Clear {...props} ref={pref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      Delete: forwardRef((props, ref) => <BuildIcon {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowUpward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
    };

    // Lógica para manter acionamentos, eu pego os acionamento que estão com props e passo para State quando
    // realizo alguma acão. E aqui eu verifico se existe algum acionamento nos props, se não existir eu pego
    // os acionamentos que estão no state. É preciso fazer isso por que as props são zeradas a cada ação disparada.

    const data = acionamentos
      ? acionamentos.length >= 1
        ? acionamentos
        : []
      : allAcionamentosLocal;

    return (
      <React.Fragment>
        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          userCogEmail={emailVerification}
          handleProceed={this.verifyEmail}
          handleCancel={(typeModalAlert, messageModalAlert) =>
            this.handleCloseNewModal(typeModalAlert, messageModalAlert)
          }
        />

        <ModalChangeEmail
          open={openModalChangeEmail}
          close={this.onCloseChangeEmail}
          title={'Trocar E-mail'}
          btone={'salvar'}
          bttwo={'sair'}
          handleChange={this.onHandleEmailChange}
          save={this.onHandleConfirm}
          submitted={this.state.submitted}
        />

        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />

        <ModalDeleteAcionamento
          open={modalDeleteAcionamento}
          close={this.handlerModalDeleteAcionamento}
          dataDeleteAcionamento={dataDeleteAcionamento}
          onSubmit={() =>
            this.deleteAcionamento(
              data,
              dataDeleteAcionamento,
              inputValueReason
            )
          }
          inputValueReason={inputValueReason}
          dataAcionamento={data}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
        />
        <ModalSupport
          open={modalSupport}
          close={this.handlerModalSupport}
          onSubmit={() => this.handleConfirmForwarding(inputValueReason)}
          inputValueReason={inputValueReason}
          handlerChangeReason={(event) => this.handleChangeReason(event)}
          emptyMotivo={zeraDisplay}
        />
        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          title=''
          columns={columns}
          options={{ paging: false, debounceInterval: 2000 }}
          data={this.fetchUsers}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Usuário não encontrado.',
              editRow: {
                deleteText: 'Gostaria de deletar e esse acionamento?',
              },
            },
            toolbar: {
              searchTooltip: 'E-mail',
              searchPlaceholder: 'E-mail',
            },
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { acionamentos } = state.acionamento;
  const { forwardingSuporte } = state.forwardingSupport;
  const { message, type, open } = state.alert;
  const { users } = state.users;

  return {
    acionamentos,
    forwardingSuporte,
    users,
    message,
    type,
    open,
  };
}

FilaPortalConecta.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapStateToProps)(FilaPortalConecta);
export { connectedLoginPage as FilaPortalConecta };
