import React, { useSearchParams } from 'react-router-dom';

export type PaginationProps = {
  page: string;
  offset: string;
  setPage: (page: string) => void;
  setOffset: (offset: string) => void;
};

export const usePagination = (): PaginationProps => {
  const [paginationParams, setPaginationParams] = useSearchParams();

  const page =
    (paginationParams.get('page') !== null
      ? paginationParams.get('page')
      : '1') || '';

  const offset =
    (paginationParams.get('offset') === null ||
    Number(paginationParams.get('offset')) > 25
      ? '5'
      : paginationParams.get('offset')) || '';

  const setPage = (page: string): void => {
    paginationParams.set('page', page);
    setPaginationParams(paginationParams);
  };

  const setOffset = (offset: string) => {
    paginationParams.set('offset', offset);
    setPaginationParams(paginationParams);
  };

  return {
    page,
    offset,
    setPage,
    setOffset,
  };
};
