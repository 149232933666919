export const mappedObjLogActivities = (array = []) => {
  const objMapped = array.map((element) => {
    return {
      Nome: element?.userLogged?.nome ?? 'Não informado',
      Email: element?.userLogged?.email ?? 'Não informado',
      Perfil: element?.userLogged?.access
        ? element?.userLogged?.access[0]?.profile?.description
        : element?.userLogged.id_perfil.descricao ?? 'Não informado',
      Login: element?.userLogged?.username ?? 'Não informado',
      'Hora login': element?.timeLogin ?? 'Não informado',
      'Hora logout': element?.timeLogout ?? 'Não informado',
    };
  });
  return objMapped;
};
