export const status = [
  {
    text: 'Em espera recepção',
    checked: false,
    value: [
      'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST',
      'IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST',
    ],
  },
  {
    text: 'Em espera suporte',
    checked: false,
    value: [
      'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT',
      'TRUCLINIC_IN_WAIT_ATTENDANCE_SUPPORT',
    ],
  },
  {
    text: 'Em espera médica',
    checked: false,
    value: ['IN_WAIT_ATTENDANCE_DOCTOR'],
  },
  {
    text: 'Em atendimento recepção',
    checked: false,
    value: [
      'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
      'IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST',
    ],
  },
  {
    text: 'Em atendimento suporte',
    checked: false,
    value: ['VONAGE_IN_ATTENDANCE_SUPPORT', 'TRUCLINIC_IN_ATTENDANCE_SUPPORT'],
  },
  {
    text: 'Em atendimento médico',
    checked: false,
    value: ['IN_ATTENDANCE_DOCTOR'],
  },
  { text: 'Agendado', checked: false, value: ['IN_WAIT_FOR_DATE_SCHEDULE'] },
  { text: 'Faltou', checked: false, value: 'NO_SHOW' },
];
