import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useDebounce } from './debounce';

export enum ReCaptchaActions {
  LOGIN_SUBMIT = 'LOGIN_SUBMIT',
  RECOVER_PASSWORD_SUBMIT = 'RECOVER_PASSWORD_SUBMIT',
  CHANGE_PASSWORD_SUBMIT = 'CHANGE_PASSWORD_SUBMIT',
}

/**
 * @description Hook to handle reCaptcha v3 authorization (auto-fetched)
 * @param action ReCaptchaAction - the key to identify the action
 */
export const useRecaptcha = (action: ReCaptchaActions) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);

  const handleRequestAuthorization = useCallback(async () => {
    if (executeRecaptcha) {
      const result = await executeRecaptcha(action);

      if (result && typeof result === 'string') {
        setReCaptchaToken(result);
      }
    }
  }, [executeRecaptcha, action]);

  const { handleDebounce, isCalling } = useDebounce({
    action: handleRequestAuthorization,
  });

  const executeAction = useCallback(() => {
    if (!isCalling) handleDebounce();
  }, [isCalling, handleDebounce]);

  useEffect(() => {
    if (executeRecaptcha) executeAction();
  }, [executeRecaptcha]);

  return {
    reCaptchaToken,
    handleRequestAuthorization: executeAction,
  };
};
