import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AiOutlineHome } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Grid } from '@material-ui/core';
import { HttpBodyLogin } from '../../../domain/entities';
import {
  Alert,
  ButtonSubmit,
  Card,
  Form,
  Input,
  Loading,
  TemplateLogin,
} from '../../../application/shared/ui';
import { LoginData } from '../../../application/data';
import * as S from './styles';

type Props = {
  loginData: LoginData;
};

export const LoginPage = ({ loginData }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = (): void => {
    setError(false);
  };

  const handleLogin = async (body: HttpBodyLogin) => {
    setLoading(true);
    setError(false);
    setErrorMessage('');
    try {
      const response = await loginData.handle(body);
      setLoading(false);
      localStorage.setItem('user', JSON.stringify(response.data));
      navigate('/adm');
    } catch (error: any) {
      setError(true);
      setLoading(false);
      setErrorMessage(error.response.data.error_description);
    }
  };

  const initialValues: HttpBodyLogin = { username: '', password: '' };
  const validationSchema: Yup.SchemaOf<HttpBodyLogin> = Yup.object().shape({
    username: Yup.string().required('Campo obrigatório'),
    password: Yup.string()
      .min(6, 'Deve conter no minimo 6 caracteres')
      .required('Campo obrigatório'),
  });

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: HttpBodyLogin) => handleLogin(values),
  });

  return (
    <TemplateLogin title='Entre com a sua conta'>
      <Card>
        <Grid item xs={12}>
          <Form onSubmit={form.handleSubmit}>
            <Grid item>
              <Input
                name='username'
                placeholder='Usuário'
                onChange={form.handleChange}
                errors={form.errors}
                touched={form.touched}
                icon={<AiOutlineHome />}
              />
            </Grid>
            <Grid item>
              <Input
                type='password'
                name='password'
                placeholder='Senha'
                onChange={form.handleChange}
                errors={form.errors}
                touched={form.touched}
                icon={<RiLockPasswordLine />}
              />
            </Grid>
            <Grid item>
              <S.LinkRecoverPassword to='/esqueci-senha'>
                &rsaquo; &nbsp; Esqueceu sua senha?
              </S.LinkRecoverPassword>
            </Grid>
            <Grid container>
              <ButtonSubmit>ENTRAR</ButtonSubmit>
            </Grid>
          </Form>
        </Grid>
      </Card>

      {loading && <Loading />}
      <Alert
        severity='error'
        handleClose={handleClose}
        open={error}
        message={errorMessage}
      />
    </TemplateLogin>
  );
};
