import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  min-width: 55px;
  flex-direction: row;
  height: 30px;
  text-align: center;
  justify-content: center;

  padding-top: 5px;
  background-color: ${(props) =>
    props.receivedStatus || props.active ? '#9BA8BA' : ''};
  border-radius: 4px;
  &&:hover {
    background-color: ${(props) => (!props.active ? '#0000001A' : '')};
  }

  cursor: pointer;
  color: ${(props) => (props.active ? '#8691A1' : '#A6ACBA')};

  svg {
    g {
      stroke: ${(props) =>
        props.active || props.receivedStatus ? '#fff' : '#A6ACBA'};
    }
    width: 20px;
    margin-left: 5px;
    margin-top: 3px;
  }
  div {
    span {
      display: flex;
      flex-wrap: nowrap;
      text-align: center;
      color: ${(props) =>
        props.receivedStatus || props.active ? 'white' : ''};
    }
  }
`;
