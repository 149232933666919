import React from 'react';

import useIcon from './hooks/useIcon';

const Icon = ({ size = 24, icon = '', onClick, color }) => {
  const { UseIcon } = useIcon(icon);

  const handleIconClick = (event) => {
    onClick?.(event);
  };

  const colors = {
    primary: '#2699fb',
    default: '#76818F',
    light: '#fff',
    dark: '#000',
  };

  return (
    UseIcon && (
      <div
        style={{
          color: colors[color] || 'unset',
          cursor: onClick ? 'pointer' : 'unset',
          height: 'max-content',
          display: 'flex',
        }}
        onClick={handleIconClick}
        role='img'
      >
        <UseIcon style={{ fontSize: size }} />
      </div>
    )
  );
};

export default Icon;
