import { useCallback, FormEvent } from 'react';

import * as S from '../style';

interface PasswordSuccessFormProps {
  onConfirm: () => void;
}
export function PasswordSuccessForm({ onConfirm }: PasswordSuccessFormProps) {
  const handleSubmit = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      evt.stopPropagation();

      onConfirm();
    },
    [onConfirm]
  );

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LogoContainer>
          <S.Logo />
        </S.LogoContainer>
        <S.LoginWrapper>
          <form onSubmit={handleSubmit}>
            <S.LoginHeader>
              <S.LoginTitle>
                Verifique a caixa de entrada do seu e-mail
              </S.LoginTitle>
              <S.Subtitle>
                Enviamos para o e-mail informado as instruções para alterar sua
                senha.
              </S.Subtitle>
            </S.LoginHeader>
            <div>
              <S.ButtonBlueContained
                style={{ marginTop: '22px' }}
                type='submit'
              >
                Voltar para a tela de login
              </S.ButtonBlueContained>
            </div>
          </form>
        </S.LoginWrapper>
      </S.LoginContainer>
      <S.CompanyImage />
    </S.Container>
  );
}
