import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 470px;

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const StyledQuickActionsWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 10px;

  button {
    width: 60%;

    div {
      justify-content: flex-start;
    }
  }
`;

export const PushCallsCardTimes = styled.div`
  padding: 16px;
`;

export const PushCallsCardTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .chip {
    height: 32px;
    width: 58px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    margin-left: 16px;
    font-size: 14px;
  }
  .icon {
    margin-right: 8px;
  }
  h3 {
    font-size: 16px;
    text-align: start;
    padding-left: 16px;
  }
`;

export const PushCallsCardItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  height: 28px;
  width: 100%;
  font-size: 16px;
  padding: 16px 0;
  .time {
    font-weight: 600;
    width: 58px;
    text-align: center;
  }
  .username {
    padding: 0 16px;
  }
`;
