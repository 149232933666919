// @ts-nocheck
import React from 'react';
import { InputAdornment } from '@material-ui/core';

import * as S from './styles';

type Props = {
  type?: string;
  name: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon: JSX.Element;
  errors: any;
  touched: any;
  // errors: object
  // touched: object
};

export const Input = ({
  type = 'text',
  name,
  placeholder,
  onChange,
  icon,
  errors,
  touched,
}: Props) => {
  return (
    <>
      <S.Input
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        startAdornment={
          <InputAdornment position='start'>
            <S.Icon>{icon}</S.Icon>
          </InputAdornment>
        }
      />
      <S.InputMessage>
        {errors[name] && touched[name] && errors[name]}
      </S.InputMessage>
    </>
  );
};
