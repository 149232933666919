import React from 'react';
import InputMask from 'react-input-mask';
import { CustomAlert } from './CustomAlert';
import { Formik, Form } from 'formik';
import { history } from '../../helpers';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import InputMaskCustom from '../Forms/InputMaskCustom';
import { Button, Fab, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { cancelamentoUserActions } from '../../actions/cancelamenstoUser.action';
import moment from 'moment';

import DialogModal from '../DialogModal';
import Input from '@material-ui/core/Input';
import {
  statusTypeForm,
  retained,
  reasonCancellation,
  unsuccessfulContact,
  statusTypeTranslation,
  reasonCancellationTranslation,
} from '../../commons';
import { isoToDatePTBR } from '../../utils/utils';
import { cancelamentoUserService } from '../../services';
import { isCPF, formatToCPF } from 'brazilian-values';
import Loading from '../Loading';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { IconButton, InputAdornment } from '@material-ui/core';
import SelectCustom from '../Forms/SelectCustom';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Errors } from '../Forms/Errors';
import 'moment/locale/pt-br';
import { HistoryCancelments } from '../Forms/HistoryCancelments';
import { check } from 'prettier';

import {
  Title,
  ContainerInput,
  ContainerButton,
  ContainerGeneric,
} from './styles';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { validations } from '../../helpers/validations';
import {
  AlternateEmailSharp,
  ContactsOutlined,
  Label,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
const useStylesFilterComponent = (theme) => ({
  root: {
    padding: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  formAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  textField: {
    width: '9%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  dateField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-100px',
    fontWeight: 500,
  },
  dateFieldEntry: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-30px',
    fontWeight: 500,
  },
  dateFieldHistory: {
    width: '30%',
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
  loaderAlign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
    marignTop: '20%',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  height: {
    height: '60px',
  },
});

const ContainerDate = styled(DateTimePicker)`
  .MuiInput-underline.Mui-error:after {
    transform: scaleX(0);
    border-bottom-color: #e2e2e2;
  }
`;

class FormManualCancelUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objManualCancel: {},
      opens: '',
      reasons: { reason: '' },
      dadosCancelamento: {},
      valida: false,
      messages: 'teste de aplicação',
      typeModal: 'empty',
      alertMessage: '',
      hasSearch: false,
      open: false,
      objFindCustomers: {},
      objFindPurshases: {},
      loading: false,
      purchaseId: 0,
      customerId: {},
      disabledCancelFlag: true,
      document: { DocumentNumber: '' },
      user: {
        statusCancel: {
          message: '',
          reason: '',
          status: '',
          futureDate: {},
          justification: '',
          operator: '',
          operatorId: '',
        },
        _id: '',
      },
      tempLog: [],
      observationValid: false,
      reasonValid: false,
      statusValid: false,
      futureDateValid: true,
      justificationValid: true,
      commentValid: true,
      futureDate: {},
      isCancelSelected: false,
      isOpen: false,
      isRequired: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.removeEmote = this.removeEmote.bind(this);
    this.handleChangeDocument = this.handleChangeDocument.bind(this);
    this.cancelaCliente = this.cancelaCliente.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.handleFindCustomers = this.handleFindCustomers.bind(this);
    this.handleFindPurshases = this.handleFindPurshases.bind(this);
    this.handleManualCancelUser = this.handleManualCancelUser.bind(this);
    this.handleDocument = this.handleDocument.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeReasonManual = this.handleChangeReasonManual.bind(this);
  }

  removeEmote(string) {
    var regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
  }

  handleChangeReasonManual(e) {
    const { reasons } = this.state;
    this.removeEmote(e.target.value);
    if (e.target.value.length === 0 || !e.target.value.trim()) {
      return this.setState({
        valida: true,
        reasons: { reason: '' },
      });
    } else {
      this.setState({
        reasons: {
          reason: e.target.value,
        },
      });
    }
  }

  async handleDocument(e) {
    this.handleChangeDocument(e);
    if (
      (await this.state.document.DocumentNumber) === '' ||
      this.state.document.DocumentNumber.length < 5 ||
      this.state.document.DocumentNumber.length > 11
    ) {
      this.setState({
        document: '',
        open: true,
        loading: false,
        typeModal: 'emptyTrue',
      });
    } else {
      await this.handleManualCancelUser();
    }
  }

  async handleChangeDocument(e) {
    try {
      e.preventDefault();

      const { value } = e.target;

      if (validations.invalidateSpecialCharacters(value) || value === '')
        this.setState({
          document: { DocumentNumber: value.toString().replace(/\.|\-/g, '') },
        });
    } catch {
      this.setState({
        open: true,
        typeModal: 'error',
      });
    }
  }
  async cancelaCliente() {
    if (!this.state.reasons.reason) {
      return this.setState({
        valida: true,
        loading: false,
      });
    }
    if ((await this.state.document.DocumentNumber) === '') {
      return this.setState({
        loading: false,
        open: true,
        typeModal: 'emptyTrue',
      });
    } else {
      this.setState({
        valida: false,
      });
      this.props.dispatch(
        cancelamentoUserActions.SendCancellation(
          this.state.objManualCancel,
          this.state.reasons.reason
        )
      );
    }
  }

  async handleManualCancelUser() {
    this.setState({
      loading: true,
    });

    if (this.state.document.DocumentNumber === undefined) {
      this.setState({
        open: true,
        typeModal: 'error',
        loading: false,
      });
      return this.refreshPage();
    }

    const _data = { DocumentNumber: this.state.document.DocumentNumber };

    if (
      _data === undefined ||
      this.state.document.DocumentNumber === undefined
    ) {
      this.setState({
        open: true,
        typeModal: 'error',
      });
    }

    const fc = await this.handleFindCustomers(_data);
    const _datas = this.state.objFindCustomers.CustomerId;
    const geral = { CustomerId: _datas };

    if (_datas === undefined || (await _datas.length) === 0) {
      this.setState({
        DocumentNumber: '',
        open: true,
        loading: false,
        typeModal: 'notFound',
      });

      return;
    }

    if (_datas !== undefined && _data.toString().includes('Failed to fetch')) {
      this.setState({
        DocumentNumber: '',
        open: true,
        loading: false,
        typeModal: 'error',
      });
    }

    if ((_datas !== undefined && _data.error) || _datas._datas) {
      this.setState({
        open: true,
        loading: false,
        typeModal: 'notFound',
      });
    }

    await this.handleFindPurshases(geral);
  }

  async handleFindCustomers(_data) {
    try {
      const dadosColetados =
        await cancelamentoUserService.cancellatiionManualUser(_data);
      dadosColetados
        ? this.setState({ loading: false })
        : this.setState({ loading: true });

      const {
        Customers: [
          {
            CustomerAddress: { Address, AddressType, CustomerId },
            CustomerDocument: { DocumentNumber },
          },
        ],
      } = dadosColetados;

      this.setState({
        objFindCustomers: {
          Address,
          AddressType,
          DocumentNumber,
          CustomerId,
        },
      });
    } catch {
      this.setState({
        open: true,
        typeModal: 'error',
      });
    }
  }

  async handleFindPurshases(geral) {
    try {
      const dadosColetados2 = await cancelamentoUserService.getUserCustomerId(
        geral
      );
      dadosColetados2
        ? this.setState({ loading: false })
        : this.setState({ loading: true });

      const {
        CreatedDate,
        Customer: {
          CellPhone,
          CustomerDocument: { DocumentTypeId, CustomerId },
          Email,
          Name,
        },
        Product: { Description, Id },
      } = dadosColetados2;

      const document = dadosColetados2.Customer.CustomerDocument.DocumentNumber;
      const PurchaseId = Id;
      const CustomerName = Name;
      const email = Email;
      const _name = dadosColetados2.Customer.Name.split(/ (.+)/)[0];
      const lastName = dadosColetados2.Customer.Name.split(/ (.+)/)[1];
      const _purchaseDate = isoToDatePTBR(CreatedDate);

      this.setState({
        objManualCancel: {
          CustomerId,
          CellPhone,
          DocumentTypeId,
          email,
          document,
          Description,
          CustomerName,
          CreatedDate,
          PurchaseId,
          DocumentTypeId,
          _purchaseDate,
          _name,
          lastName,
        },
      });
    } catch {
      this.setState({
        open: true,
        typeModal: 'error',
      });
    }
  }

  handleChange(e) {
    const { user } = this.state;
    const operator = JSON.parse(localStorage.getItem('user'));

    if (e.target.name === 'message') {
      const comment = this.removeEmote(e.target.value);

      e.target.value = comment;
      this.setState({
        ...this.state,

        user: {
          ...user,
          statusCancel: {
            ...user.statusCancel,
            [e.target.name]: e.target.value,
            operator: operator.user.nome,
            operatorId: operator.user.id_perfil._id,
          },
        },
      });
    } else {
      this.setState({
        ...this.state,

        user: {
          ...user,
          statusCancel: {
            ...user.statusCancel,
            [e.target.name]: e.target.value,
            operator: operator.user.nome,
            operatorId: operator.user.id_perfil._id,
          },
        },
      });
    }
  }

  handleChangeJustification(e) {
    const { user } = this.state;
    this.setState({
      ...this.state,
      isErrorJustification: false,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          [e.target.name]: e.target.value,
        },
      },
    });
  }

  validDate() {
    const actualDate = moment();
    const selectedDate = moment(this.state.user.statusCancel.futureDate).format(
      'DD/MM/YYYY HH:mm'
    );
    if (!selectedDate.includes('Data inválida')) {
      let days = moment(this.state.user.statusCancel.futureDate).diff(
        actualDate,
        'days'
      );
      let hours = moment(this.state.user.statusCancel.futureDate).diff(
        actualDate,
        'hours'
      );
      if (days == 0) return hours > 0;
      return days > 0;
    }
    return false;
  }

  handleChangedate(value) {
    const { user } = this.state;
    this.setState({
      ...this.state,
      isErrorFutureDate: false,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          futureDate: value,
        },
      },
    });
  }
  handleClear(e) {
    this.setState({
      hasSearch: false,
      loading: false,
      document: '',
    });
  }

  handleCancel(e) {
    this.setState({
      open: false,
    });
    this.handleClear();
    this.refreshPage();
  }

  refreshPage() {
    this.setState({
      open: false,
      objManualCancel: {
        CellPhone: '',
        DocumentTypeId: '',
        email: '',
        document: '',
        Description: '',
        CustomerName: '',
        CreatedDate: '',
        PurchaseId: '',
        DocumentTypeId: '',
        _purchaseDate: '',
        _name: '',
        lastName: '',
      },

      objFindCustomers: {
        Id: '',
        CustomerId: '',
        DocumentNumber: '',
      },

      reasons: { reason: '' },
      document: { DocumentNumber: '' },
    });
  }

  handleChangeStatus(e) {
    const { user } = this.state;
    const operator = JSON.parse(localStorage.getItem('user'));
    this.setState({
      ...this.state,
      isErrorStatus: false,
      isCancelSelected: this.checkCancellationType(e.target.value),
      isRequired: e.target.value === 'OPEN' ? false : true,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          status: e.target.value,
          reason: '',
          observation: '',
          description: statusTypeTranslation[e.target.value],
          operator: operator.user.nome,
          operatorId: operator.user.id_perfil._id,
        },
      },
    });
  }

  handleChangeReason(e) {
    const { user } = this.state;
    this.setState({
      ...this.state,
      isErrorReason: false,
      user: {
        ...user,
        statusCancel: {
          ...user.statusCancel,
          reason: e.target.value,
          observation: reasonCancellationTranslation[e.target.value],
        },
      },
    });
  }

  handleConfirm() {
    this.setState({ opens: false });
    if (this.state.user.statusCancel.futureDate)
      this.state.user.statusCancel.futureDate = moment(
        this.state.user.statusCancel.futureDate
      )
        .subtract('3', 'hours')
        .toISOString();
    // this.props.dispatch(
    this.props.dispatch(
      cancelamentoUserActions.SendCancellation(
        this.state.objManualCancel,
        this.state.reasons.reason,
        this.state.user
      )

      //cancelamentoUserActions.ConfirmationCancelamentoUser(this.state.user) //alterar
    ); //)
  }

  checkCancellationType(status) {
    return (
      status === 'CANCELED_CHARGEBACK' ||
      status === 'CANCELED_WITH_PENALITY' ||
      status === 'CANCELED_WITHOUT_PENALITY' ||
      status === 'CANCELED_INVOLUNTARY'
    );
  }

  checkStatus(status) {
    return status === 'FUTURE_CONTACT' || status === 'CONTACT_UNSUCCESSFUL';
  }

  isoToDate(str) {
    let date = moment(str).format('DD/MM/YYYY HH:mm').toString();
    return date;
  }

  componentDidMount() {
    let { log = [] } = this.props.data;
    if (this.props.data.statusCancel && this.props.data.statusCancel.futureDate)
      this.props.data.statusCancel.futureDate = moment(
        this.props.data.statusCancel.futureDate,
        'DD/MM/YYYY HH:mm:ss'
      ).toISOString();
    let arrayBlockedStatus = [
      'RETAINED_COSTUMER',
      'CANCELED_CHARGEBACK',
      'CANCELED_WITH_PENALITY',
      'CANCELED_WITHOUT_PENALITY',
    ];
    let disabledCancelFlag =
      this.props.data.statusCancel && this.props.data.statusCancel.status
        ? arrayBlockedStatus.includes(this.props.data.statusCancel.status)
        : true;

    this.setState({
      ...this.state,
      user: {
        statusCancel: {
          message: '',
          reason: '',
          status: '',
          futureDate: '',
          justification: '',
        },
        ...this.props.data,
      },
      tempLog: log.reverse(),
      opens: false,
      isErrorStatus: false,
      isRequired: this.state.user.statusCancel.status === 'OPEN' ? false : true,
      disabledCancelFlag: disabledCancelFlag,
    });
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      user: {},
    });
  }

  validForm() {
    let justificationValid = true;
    let futureDateValid = true;

    if (
      this.checkCancellationType(this.state.user.statusCancel.status) &&
      this.state.user.statusCancel.justification.trim() === ''
    ) {
      justificationValid = false;
      this.setState({
        isErrorJustification: true,
      });
    }

    if (
      this.checkCancellationType(this.state.user.statusCancel.status) &&
      this.state.user.statusCancel.justification === undefined
    ) {
      justificationValid = false;
      this.setState({
        isErrorJustification: true,
      });
    }
    if (
      this.checkStatus(this.state.user.statusCancel.status) &&
      !this.validDate()
    ) {
      this.setState({ isErrorFutureDate: true });
      futureDateValid = false;
    }

    if (this.state.user.statusCancel.status === '')
      this.setState({ statusValid: false, isErrorStatus: true });

    if (this.state.user.statusCancel.reason === '')
      this.setState({ reasonValid: false, isErrorReason: true });

    if (this.state.user.statusCancel.observation === '') {
      this.setState({ observationValid: false });
    }

    if (
      this.state.user.statusCancel.status != '' &&
      this.state.user.statusCancel.reason != '' &&
      this.state.user.statusCancel.observation != '' &&
      justificationValid &&
      futureDateValid
    ) {
      return true;
    }

    return false;
  }

  opemModal() {
    let { user } = this.state;

    if (this.state.user.statusCancel.status === 'OPEN') {
      this.setState({ ...this.state, opens: true });
    } else {
      if (this.validForm()) this.setState({ ...this.state, open: true });
    }
  }

  maskForCPF(data) {
    if (isCPF(data)) {
      return formatToCPF(data);
    } else {
      return data;
    }
  }

  render() {
    const { objManualCancel, message, open, typeModal, loading } = this.state;

    let { alertMessage } = this.state;

    const classes = useStylesFilterComponent();
    return (
      <>
        {this.state.loading && <Loading />}

        <section>
          <CustomAlert
            open={open}
            handleCancel={(e) => this.handleCancel(e)}
            //handleConfirm={(e) => this.handlePerform(e)}
            typeModal={typeModal}
            message={alertMessage}
          />

          <Fab
            variant='extended'
            aria-label='Delete'
            //className={classes.fab}
            color='primary'
            onChange={this.state.document.DocumentNumber}
            onClick={async (e) => await this.handleDocument(e)}
            style={{
              fontSize: '10px',
              width: '13%',
              height: '36px',
              background:
                'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
              boxShadow: 'none',
              border: '1px white solid',
              position: 'relative',
              top: -1,
              left: 410,
            }}
          >
            Buscar
          </Fab>

          {/*      <Paper
          component="form"
          style={{
            padding: 'px 4px',
            display: 'flex',
            marginBottom: 50,
            marginLeft: 140,
            width: 250,
            height: 60,
          }}
        > */}

          <Grid>
            <Input
              style={{
                display: 'flex',
                paddingLeft: -1,
                fontSize: 14,
                width: 250,
                left: 160,
                top: -30,
              }}
              name='document'
              value={this.state.document.DocumentNumber}
              placeholder='CPF OU PASSAPORTE'
              onChange={(e) => this.handleChangeDocument(e)}
            />
          </Grid>

          <Formik enableReinitialize initialValues={{ ...this.state.user }}>
            {/*  {() => ( */}

            <Form>
              <Grid justify='space-between' container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    name='customerId'
                    autoFocus={false}
                    defaultValue=' '
                    label='ID Cliente'
                    value={this.state.objFindCustomers.CustomerId}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    defaultValue=' '
                    name='productDescription'
                    label='Produto'
                    value={this.state.objManualCancel.Description}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    defaultValue=' '
                    name='name'
                    label='Nome'
                    value={this.state.objManualCancel._name}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    defaultValue=' '
                    name='lastName'
                    label='Sobrenome'
                    value={this.state.objManualCancel.lastName}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputMaskCustom
                    disabled={true}
                    defaultValue=' '
                    name='cpf'
                    label='Documento'
                    value={this.maskForCPF(
                      this.state.objFindCustomers.DocumentNumber
                    )}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      defaultValue=' '
                      label='DDD + Telefone'
                      disabled={true}
                    ></TextField>
                    <InputMaskCustom
                      value={this.state.objManualCancel.CellPhone}
                      mask='(99) 99999-9999'
                      defaultValue=' '
                      name='phone'
                      disabled={true}
                      style={{ position: 'relative', top: -32 }}
                      fullWidth
                    ></InputMaskCustom>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    defaultValue=' '
                    name='email'
                    label='E-mail '
                    value={this.state.objManualCancel.email}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={3}
                    type='text-area'
                    name='reason'
                    label='Motivo do cancelamento'
                    value={this.removeEmote(this.state.reasons.reason)}
                    placeholder='Motivo do cancelamento'
                    onChange={this.handleChangeReasonManual}
                    //onChange={(e) => this.setState({ reasons:{reason: e.target.value} })}
                    fullWidth
                    error={!this.state.reasons.reason && this.state.valida}
                    id='standard-error-helper-text'
                    helperText='Campo Obrigatório*'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    name='purchaseDate'
                    label='Data de Compra'
                    type='Mensagem'
                    className={classes.textField}
                    value={this.state.objManualCancel._purchaseDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}
              >
                {/*   <Fab
                  variant="extended"
                  aria-label="Delete"
                  //className={classes.fab}
                  color="primary"
                  onClick={(e) => history.push('/canc/ativos')}
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    
                  }}
                >
                  Cancelar
                </Fab> */}

                <Fab
                  onClick={(e) => history.push('/canc/ativos')}
                  variant='extended'
                  aria-label='Delete'
                  className={classes.fab}
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background: 'white',
                    color: 'red',
                    boxShadow: 'none',
                    border: '1px solid',
                    margin: '1%',
                    position: 'relative',
                    left: 13,
                  }}
                >
                  Cancelar
                </Fab>

                {/*     <Fab
                  variant="extended"
                  aria-label="Delete"
                  //className={classes.fab}
                  color="primary"
                  onClick={ this.refreshPage }
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    margin:'auto',
                    
                  }}
                >
                  Limpar
                </Fab> */}

                <Fab
                  onClick={this.refreshPage}
                  variant='extended'
                  aria-label='Delete'
                  className={classes.fab}
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background: 'white',
                    color: 'blue',
                    boxShadow: 'none',
                    border: '1px solid',
                    margin: '1%',
                    position: 'relative',
                    left: 13,
                  }}
                >
                  Limpar
                </Fab>

                {/*    <Fab
                  variant="extended"
                  aria-label="Delete"
                  //className={classes.fab}
                  color="primary"
                  onChange={this.state.reasons}
                  onClick={this.cancelaCliente}

                  style={{
                    fontSize: '10px',
                    width: '40%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    
                    
                  }}
                >
                  Salvar Cancelamento
                </Fab> */}
                <Fab
                  onChange={this.state.reasons}
                  onClick={this.cancelaCliente}
                  variant='extended'
                  aria-label='Delete'
                  className={classes.fab}
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginRight: '5px',
                    margin: '1%',
                    position: 'relative',
                    left: 13,
                  }}
                >
                  Salvar Dados
                </Fab>
              </div>
            </Form>
          </Formik>
        </section>

        {/*  ///////////////////////////////////// colocar parte debaixo */}

        <Formik /* enableReinitialize initialValues={{ ...this.state.user }} */>
          {/*  {() => (
          <Form>
            <Grid justify="space-between" container spacing={3}>
              <Grid item xs={6}>
            
              </Grid>
              

              <DialogModal
                title="Inclusão de status"
                open={this.state.opens}
                handleCancel={() => this.handleCancel}
                handleConfirm={() => this.handleConfirm}
                withActions
                labelOk={'Confirmar'}
                labelCancel={'Cancelar'}
                typeButtomCancel={'lightRounded'}
                typeButtomConfirm={'dangerLightRounded'}
                text={
                  this.state.user.statusCancel === 'CANCELED_WITH_PENALITY'
                    ? 'Atenção! Não se esqueça de gerar link com valor da multa de cancelamento e envia-lo ao paciente'
                    : 'Confirme abaixo a mudança de status. Essa ação não poderá ser desfeita.'
                }
              ></DialogModal>
    
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  name="purchaseDate"
                  label="Data de Compra"
                  placeholder="Data de Compra"
                  type="Mensagem"
                  className={classes.textField}
                  value={this.state.user.purchaseDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '30px',
              }}
            >
              <h3>Tratativa da Solicitação </h3>
            </div>

            <Grid justify="space-between" container spacing={3}>
              <Grid item xs={12}>
                <Errors
                  errorText="Campo invalido!"
                  isError={this.state.isErrorStatus}
                  isRequired={
                    this.state.user.statusCancel.status === 'OPEN'
                      ? false
                      : true
                  }
                >
                  <SelectCustom
                    disable={false}
                    name="status"
                    label="Status"
                    onChange={(e) => this.handleChangeStatus(e)}
                    value={this.state.user.statusCancel.status}
                    dataSource={statusTypeForm}
                    placeholder="Status"
                  />
                </Errors>
              </Grid>
              <Grid item xs={12}>
                {this.state.user.statusCancel &&
                  this.checkStatus(this.state.user.statusCancel.status) && (
                    <Errors
                      errorText="Campo invalido!"
                      isError={this.state.isErrorReason}
                      isRequired={this.state.isRequired}
                    >
                      <SelectCustom
                        disable={false}
                        type="reason"
                        reson="reason"
                        label="Motivo"
                        onChange={(e) => this.handleChangeReason(e)}
                        value={this.state.user.statusCancel.reason}
                        dataSource={unsuccessfulContact}
                        placeholder="Motivo"
                      />
                    </Errors>
                  )}

                {this.state.user.statusCancel.status !== 'RETAINED_COSTUMER' &&
                  !this.checkStatus(this.state.user.statusCancel.status) && (
                    <Errors
                      errorText="Campo invalido!"
                      isError={this.state.isErrorReason}
                      isRequired={this.checkCancellationType(
                        this.state.user.statusCancel.status
                      )}
                    >
                      <SelectCustom
                        disable={false}
                        name="reason"
                        label="Motivo"
                        onChange={(e) => this.handleChangeReason(e)}
                        value={this.state.user.statusCancel.reason}
                        dataSource={reasonCancellation}
                        placeholder="Motivo"
                      />
                    </Errors>
                  )}

                {this.state.user.statusCancel.status ===
                  'RETAINED_COSTUMER' && (
                    <Errors
                      errorText="Campo invalido!"
                      isError={this.state.isErrorReason}
                      isRequired={this.state.isRequired}
                    >
                      <SelectCustom
                        disable={false}
                        name="reason"
                        label="Motivo"
                        onChange={(e) => this.handleChangeReason(e)}
                        value={this.state.user.statusCancel.reason}
                        dataSource={retained}
                        placeholder="Motivo"
                      />
                    </Errors>
                  )}
              </Grid>

              {this.state.user.statusCancel &&
                this.checkStatus(this.state.user.statusCancel.status) && (
                  <Grid item xs={12}>
                    <Errors
                      errorText="Data e Horário do próximo contato deve ser superior a data e horário atual"
                      isError={this.state.isErrorFutureDate}
                      isRequired={this.state.isRequired}
                    >
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        locale={moment.locale('pt-br')}
                      >
                        <ContainerDate
                          disabled={false}
                          label="Data e horário do próximo contato"
                          fullWidth
                          disablePast={true}
                          hideTabs
                          ampm={false}
                          value={this.state.user.statusCancel.futureDate}
                          onChange={(e) => this.handleChangedate(e)}
                          allowKeyboardControl={false}
                          helperText=""
                          format="DD/MM/YYYY HH:mm"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <CalendarTodayIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Errors>
                  </Grid>
                )}
              <Grid item xs={12}>
                {!this.checkCancellationType(
                  this.state.user.statusCancel.status
                ) ? (
                  <div style={{ paddingLeft: '11px' }}>
                    <TextField
                      disabled={false}
                      name="message"
                      label="Comentários"
                      placeholder="Comentários"
                      type="Comentários"
                      value={this.state.user.statusCancel.message}
                      onChange={(e) => this.handleChange(e)}
                      fullWidth
                      id="standard-multiline-flexible"
                      multiline
                      rowsMax={4}
                    />
                  </div>
                ) : (
                  <Errors
                    errorText="Campo inválido!"
                    isError={this.state.isErrorJustification}
                    isRequired={this.state.isRequired}
                  >
                    <TextField
                      disabled={false}
                      name="justification"
                      label="Justificativa"
                      placeholder="Justificativa"
                      type="Justificativa"
                      value={this.state.user.statusCancel.justification}
                      onChange={(e) => this.handleChangeJustification(e)}
                      fullWidth
                      multiline
                      rowsMax={4}
                    />
                  </Errors>
                )}
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: '30px', marginBottom: '30px' }}
              justify="center"
              container
              spacing={6}
            >
              {this.state.objManualCancel && <Grid item xs={6}>
                <Fab
                  variant="extended"
                  aria-label="Delete"
                  //className={classes.fab}
                  color="primary"
                  onClick={(e) => this.opemModal(e)}
                  style={{
                    fontSize: '10px',
                    width: '100%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginRight: '5px',
                    margin: '1%',
                  }}
                >
                  Salvar Cancelamento
                </Fab>
              </Grid>}
            </Grid>

            <Grid
              justify="center"
              container
            >
              <Grid item xs={7}>
                <Fab
                  variant="extended"
                  aria-label="Delete"
                  //className={classes.fab}
                  color="primary"
                  onClick={(e) => history.push('/canc/ativos')}
                  style={{
                    fontSize: '10px',
                    width: '40%',
                    height: '36px',
                    background:
                      'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                    boxShadow: 'none',
                    border: '1px white solid',
                    marginLeft: '107px'
                  }}
                >
                  Cancelar
                </Fab>
              </Grid>
            </Grid>
         
          </Form>
        )} */}
        </Formik>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.alert;

  return {
    ...user,
  };
}

const connenctCancelPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(FormManualCancelUser)
);
export default connenctCancelPage;
