import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LoginAlerts } from '../LoginAlerts';

import { LoginActions } from '../../actions/login.actions';
import { alertActions } from '../../actions';
import { IUserLoginState, ELoginSteps } from '../../reducers/login.reducer';
import { userService } from '../../services';

import { useExpireTimer } from '../utils/timer.utils';

import { PageProps } from '../types';
import { LoginMessages } from '../messages';

import { Text } from '../../components/Text';

import * as S from './styles';

interface ILoginReducerProps {
  loginReducer: IUserLoginState;
}

interface MfaFooterProps {
  pageProps: PageProps;
}

export function MfaFooter({ pageProps }: MfaFooterProps) {
  const data = useSelector((state: ILoginReducerProps) => state.loginReducer);

  const { timeLeft, willExpireAt } = useExpireTimer();

  const handleSelectOtherMethod = useCallback(() => {
    pageProps.dispatch(LoginActions.ChangeStep(ELoginSteps.MFA_SELECT));
  }, []);

  const handleRestartProcess = useCallback(() => {
    pageProps.dispatch(LoginActions.Reset());
  }, []);

  const handleResendCode = useCallback(async () => {
    try {
      await userService.sentMfaCode(data.method);

      pageProps.dispatch(LoginActions.RegisterCodeSentAt());
    } catch (err: any) {
      let message = LoginMessages.FOOTER.UNEXPECTED;

      if (err?.message?.includes('token')) {
        message = LoginMessages.FOOTER.EXPIRED_TOKEN;
      } else if (err?.message?.includes('já foi enviado')) {
        message = LoginMessages.FOOTER.WAIT_FOR_RESEND;
      }

      pageProps.dispatch(alertActions.error(message));

      handleRestartProcess();
    }
  }, [data.method]);

  const showResetMessage = useMemo<boolean>(() => {
    return [ELoginSteps.MFA_SELECT].includes(data.step);
  }, []);

  const showSelectOtherMessage = useMemo<boolean>(() => {
    return [ELoginSteps.MFA_ENTER_CODE].includes(data.step);
  }, []);

  const showTimeMessage = useMemo<boolean>(() => {
    if (willExpireAt !== null) {
      return [ELoginSteps.MFA_ENTER_CODE].includes(data.step);
    }

    return false;
  }, [timeLeft, willExpireAt]);

  const isResendDisabled = useMemo<boolean>(() => {
    return typeof timeLeft === 'number' && timeLeft > 0;
  }, [timeLeft]);

  return (
    <S.Wrapper>
      <LoginAlerts pageProps={pageProps} />
      {showTimeMessage && (
        <S.TextButton onClick={handleResendCode} disabled={isResendDisabled}>
          <Text
            $size='12px'
            $color={isResendDisabled ? '#B3B7C0' : '#363940'}
            $weight='500'
            $align='center'
          >
            Enviar código novamente
            {timeLeft && timeLeft > 0 ? ` (aguarde ${timeLeft} segundos)` : ''}
          </Text>
        </S.TextButton>
      )}
      {showSelectOtherMessage && (
        <S.TextButton onClick={handleSelectOtherMethod}>
          <Text $size='12px' $color='#363940' $weight='500' $align='center'>
            Selecionar outro método para receber o código
          </Text>
        </S.TextButton>
      )}
      {showResetMessage && (
        <S.TextButton onClick={handleRestartProcess}>
          <Text $size='12px' $color='#363940' $weight='500' $align='center'>
            Faça login em uma conta diferente
          </Text>
        </S.TextButton>
      )}
    </S.Wrapper>
  );
}
