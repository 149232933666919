import React, { useState } from 'react'

import { Tabs, Tab } from '@material-ui/core'

import { StyledTabHeader, StyledTabs, StyledTabContent } from './styles'

const CustomTabs = ({ data, currentElement = 0, onChange }) => {
  const [value, setValue] = useState(currentElement)

  const handleChange = (_, newValue) => {
    setValue(newValue)
    onChange?.(newValue)
  }

  return (
    <StyledTabs>
      <StyledTabHeader>
        <Tabs value={value} onChange={handleChange} aria-label="Tabs">
          {data.map((d, i) => (
            <Tab key={i} label={d?.title || ''} aria-controls={`arial-controls-${i}`} />
          ))}
        </Tabs>
      </StyledTabHeader>

      {data.map((d, i) => (
        <StyledTabContent
          key={i}
          role="tabpanel"
          hidden={value !== i}
          aria-labelledby={`tab-${i}`}
        >
          {d?.element()}
        </StyledTabContent>
      ))}
    </StyledTabs>
  )
}

export default CustomTabs
