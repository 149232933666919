import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const filaComunicadosService = {
  getQueueComunication,
  insertComunication,
  getAllComunication,
  changeActiveNotifications,
};

async function getQueueComunication(
  userId,
  pageSize = 5,
  page = 1,
  queryString = ''
) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
      userId: userId.user._id,
    }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/comunicados/${page}/${pageSize}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);

  return getService;
}
async function getAllComunication(
  userId,
  pageSize = 5,
  page = 1,
  queryString = ''
) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryString,
      userId: userId.user._id,
    }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/all/comunicados/${page}/${pageSize}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);

  return getService;
}

async function insertComunication(values) {
  const user = JSON.parse(window.localStorage.user);
  const name = user.user.nome;
  const {
    title,
    content,
    startDate,
    links,
    reception,
    administration,
    support,
    manager,
  } = values;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      title: title,
      content: content,
      startDate: startDate,
      links: links,
      name: name,
      reception: reception,
      administration: administration,
      support: support,
      manager: manager,
      active: true,
    }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/comunicados/new`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);

  return getService;
}

async function changeActiveNotifications(data, value) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      id: data._id,
      value: value,
    }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/fila/comunicados/update`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);

  return getService;
}
