import React, { Component } from 'react';
import DateModal from './DateModal/';
import Resume from './Resume';
import DialogModal from '../../DialogModal';
import { getFormatedDate, stringSplit } from '../../../utils/utils';
import Slots from './Slots';
import { Errors } from '../../Forms/Errors';
import { ContainerError } from './styles';
import moment from 'moment';

class ModalSteps extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      step: 0,
      schedulingDate: '',
      schedulingTime: '',
      timeout: 2000,
    };
  }

  handleChange = (e) => {
    const { user } = this.state;
    const { name, value } = e.target;

    this.setState({
      step: 0,
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  componentWillMount() {}

  componentDidMount() {
    this.setState({
      step: 0,
      schedulingDateError: false,

      user: {
        schedulingDate: '',
        schedulingTime: '',
        doctorRefId: '',
        doctorRefName: '',
        telepsicoProgram: '',
        telepsicoProgramId: '',
        idSlot: '',
      },
    });
  }
  componentWillUnmount() {}

  render() {
    const actualDate = getFormatedDate();
    const { step, user } = this.state;
    const isFirst = () => this.state.step === 0;
    const isSecond = () => this.state.step === 1;
    const isNext = () => this.state.step + 1 < modalChildrens.length;
    const isLast = () => this.state.step + 1 === modalChildrens.length;

    const handleConfirm = () => {
      let isError = false;
      if (isFirst() && this.state.user.schedulingDate === '') {
        // this.setState({ schedulingDateError: true });
        // isError = true;
        this.setState({
          user: { schedulingDate: moment().format('DD/MM/YYYY') },
        });
      }

      // else {
      //   this.setState({ schedulingDateError: false });
      //   isError = false;
      // }

      if (
        isSecond() &&
        (this.state.user.schedulingTime === '' ||
          this.state.user.schedulingTime === undefined)
      ) {
        this.setState({ schedulingtimeError: true });
        isError = true;
      }

      if (isNext() && !isLast() && !isError)
        this.setState({ step: this.state.step + 1 });

      if (isLast()) {
        this.setState({ step: 0 });
        this.props.handleCancel();
      }
      this.props.handleHOCSetState(this.state.user);
    };

    const handleCancel = () => {
      if (!isFirst()) this.setState({ step: this.state.step - 1 });
      else this.props.handleCancel();
    };

    const handleClose = () => {
      this.props.handleCancel();
    };

    const handleSetState = (state, sender) => {
      const { user } = this.state;

      //  const stringSplit = (str, 1) => str.split(' ')[1];

      if (sender === 'datemodal')
        this.setState({
          user: {
            ...user,
            schedulingDate: state.schedulingDate,
          },
        });
      else
        this.setState({
          user: {
            ...user,
            schedulingSlotId: state.Id,
            schedulingTime: state.startAt
              ? stringSplit(state.startAt, ' ', 1).slice(0, 5)
              : '',
            doctorRefId: state.doctorRefId || '',
            doctorRefName: state.doctorRefName || '',
            telepsicoProgram: state.telepsicoProgram || '',
            telepsicoProgramId: state.telepsicoProgramId || '',
            idSlot: state.id || '',
          },
        });
    };

    const modalChildrens = [
      {
        title: 'Selecione uma data',
        elementChildren: (
          <>
            <DateModal
              doctorRefId={this.props.doctorRefId}
              handleSetState={(e, name) => handleSetState(e, name)}
            />

            <ContainerError>
              {/* <Errors
                errorText="A data deve ser posterior a data atual!"
                isError={this.state.schedulingDateError}
                isRequired={this.state.schedulingDateError}
              /> */}
            </ContainerError>
          </>
        ),
      },

      {
        title: 'Selecione um horário',
        elementChildren: (
          <Errors
            errorText='Selecione um horário'
            isError={this.state.schedulingtimeError}
            isRequired={this.state.schedulingtimeError}
          >
            <Slots
              user={user}
              searchDate={actualDate}
              handleSetState={(e) => handleSetState(e)}
              specialty={3}
              state={2}
              scheduleType='TELEPSICOLOGIA'
            />
          </Errors>
        ),
      },

      {
        title: 'Agendamento Telepsicologia',
        elementChildren: (
          <Resume telepsicoProgram={this.props.telepsicoProgram} user={user} />
        ),
      },
    ];

    return (
      <>
        <DialogModal
          withClose
          withDivider
          withActions
          title={modalChildrens[step].title}
          titlepos='center'
          footerpos='center'
          labelOk={isLast() ? 'Confirmar' : 'Continuar'}
          labelCancel={!isLast() && !isFirst() ? 'Voltar' : 'Cancelar'}
          open={this.props.open}
          handleCancel={() => handleCancel}
          onClose={() => handleClose}
          handleConfirm={() => handleConfirm}
        >
          {modalChildrens[step].elementChildren}
        </DialogModal>
      </>
    );
  }
}

export default ModalSteps;
