import httpClient from '../../../utils/httpClient';

const resource = 'work-shift';

const toggleWorkshift = async (data) => {
  const response = await httpClient.POST(`${resource}`, { body: data });

  try {
    const data = await response.clone().json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          data,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

const delayReason = async (data) => {
  const response = await httpClient.PUT(`${resource}/reason`, { body: data });
  try {
    const data = await response.clone().json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
          data,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

export default {
  delayReason,
  toggleWorkshift,
};
