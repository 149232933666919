import React from 'react';
import * as S from './styles';
import propTypes from 'prop-types';
import { string } from 'yup/lib/locale';

const ButtonFilter = ({
  text,
  icon,
  placementIcon = 'right',
  children,
  customFunction,
  active = false,
  receivedStatus,
}) => {
  return (
    <S.ContainerButton
      active={active}
      receivedStatus={receivedStatus?.length > 0}
      onClick={() => {
        if (typeof customFunction === 'function') customFunction();
      }}
    >
      {text && (
        <div>
          {placementIcon && placementIcon === 'left' ? (
            <span>
              {icon}
              {text}
            </span>
          ) : (
            <>
              <span>
                {text}
                {icon}
              </span>
            </>
          )}
        </div>
      )}
      {children && children}
    </S.ContainerButton>
  );
};

ButtonFilter.propTypes = {
  text: propTypes.string,
  icon: propTypes.element,
  children: propTypes.element,
  placementIcon: string,
};

export default ButtonFilter;
