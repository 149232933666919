import React from 'react';
import PropTypes from 'prop-types';

import { LoginPageRobot } from '../LoginPageRobot';
import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';
class LoginRobot extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        {/* <MenuAssinar>
          <PaperForm
            title={'Entre com a sua conta'}
            margin={'15px 0'}
            elevation={24}
            componente={
              <PaperForm
                margin={'15px 0'}
                elevation={24}
                width={300}
                componente={<LoginPage assinar={true} />}
              ></PaperForm>
              <LoginPage assinar={true} />
            }
          ></PaperForm>
          
        </MenuAssinar> */}
        <LoginPageRobot assinar={true} />
      </React.Fragment>
    );
  }
}

LoginRobot.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedAssinar = LoginRobot;
export { connectedAssinar as LoginRobot };
