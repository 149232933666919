import { LoginConstants } from '../constants/login.constants';

export enum ELoginSteps {
  LOGIN = 'LOGIN',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS',
  MFA_SELECT = 'MFA_SELECT',
  MFA_ENTER_CODE = 'MFA_ENTER_CODE',
}
export interface IUserLoginState {
  mfaData: {
    email: string;
    phoneNumber: string;
  };
  method: 'EMAIL' | 'PHONE';
  lastCodeSentAt?: Date | null;
  step: ELoginSteps;
}

interface ActionType {
  type: string;
  payload: unknown;
}

const loginReducerInitialState: IUserLoginState = {
  mfaData: {
    email: '',
    phoneNumber: '',
    token: '',
  },
  step: ELoginSteps.LOGIN,
} as any;

export function loginReducer(
  state = {} as IUserLoginState,
  action: ActionType
): IUserLoginState {
  switch (action.type) {
    case LoginConstants.CACHE_MFA_DATA: {
      const mfaData = Object.assign(
        {},
        state.mfaData,
        action.payload as IUserLoginState['mfaData']
      );

      return {
        ...state,
        mfaData,
      };
    }

    case LoginConstants.SELECT_METHOD: {
      return {
        ...state,
        method: action.payload as IUserLoginState['method'],
      };
    }

    case LoginConstants.REGISTER_CODE_SENT_AT: {
      return {
        ...state,
        lastCodeSentAt: new Date(),
      };
    }

    case LoginConstants.CHANGE_STEP: {
      return {
        ...state,
        step: action.payload as ELoginSteps,
      };
    }

    case LoginConstants.RESET: {
      return loginReducerInitialState;
    }

    default:
      return state;
  }
}
