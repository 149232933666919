import React, { useMemo } from 'react';

import SnackLayer from './components/Layer';

import useSnackbar from './hooks/useSnackbar';

const CustomSnackbar = () => {
  const { add, useQueue, remove } = useSnackbar();

  return {
    trigger: (toast) => add(toast),
    snack: useMemo(() => (
      <>
        {useQueue.map((n, i) => (
          <SnackLayer
            key={n.id}
            id={i + 1}
            align={n.align}
            message={n.message}
            type={n.type}
            duration={n.duration}
            onTimeEnd={() => remove(n.id)}
          />
        ))}
      </>
    )),
  };
};

export default CustomSnackbar;
