const VIACEP_URL = 'https://viacep.com.br';

export const getAddressByCep = async (cep) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await (
      await fetch(`${VIACEP_URL}/ws/${cep}/json`, requestOptions)
    ).json();

    return response?.erro ? null : response;
  } catch {
    return null;
  }
};

export default {
  getAddressByCep,
};
