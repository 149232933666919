const name = `SCALE`

const states = {
	user: {
		execute: `${name}_USER`,
		success: `${name}_USER_SUCCESS`,
		error: `${name}_USER_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	search: {
		execute: `${name}_SEARCH`,
		success: `${name}_SEARCH_SUCCESS`,
		error: `${name}_SEARCH_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},
	
	grid: {
		execute: `${name}_GRID`,
		success: `${name}_GRID_SUCCESS`,
		error: `${name}_GRID_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	remove: {
		execute: `${name}_REMOVE`,
		success: `${name}_REMOVE_SUCCESS`,
		error: `${name}_REMOVE_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	scale: {
		execute: `${name}_SCALE`,
		success: `${name}_SCALE_SUCCESS`,
		error: `${name}_SCALE_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	listGrid: {
		execute: `${name}_LISTGRID`,
		success: `${name}_LISTGRID_SUCCESS`,
		error: `${name}_LISTGRID_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},
	
	gridUser: {
		execute: `${name}_GRID_USER`,
		success: `${name}_GRID_USER_SUCCESS`,
		error: `${name}_GRID_USER_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},

	reportUser: {
		execute: `${name}_REPORT_USER`,
		success: `${name}_REPORT_USER_SUCCESS`,
		error: `${name}_REPORT_USER_ERROR`,
		initial: {
			loading: false,
			loaded: true,
			error: false,
			request: false,
			response: false,
		},
	},
}

const types = { states }
export default types
