import React from 'react';

import {
  StyledModalContainer,
  StyledCloseDiv,
  StyledInnerLayer,
} from './styles';

import { CustomIcon, CustomBackdrop } from '../../';

import useModal from './hooks/useModal';

import t from 'prop-types';

const Modal = ({ show, children, onClose, width = '500' }) => {
  useModal(show);

  return (
    show && (
      <CustomBackdrop>
        <StyledModalContainer width={width}>
          {onClose && (
            <StyledCloseDiv>
              <CustomIcon icon='Close' color='dark' onClick={onClose} />
            </StyledCloseDiv>
          )}
          <StyledInnerLayer>{children}</StyledInnerLayer>
        </StyledModalContainer>
      </CustomBackdrop>
    )
  );
};

export default Modal;

Modal.propTypes = {
  show: t.bool.isRequired,
  children: t.element.isRequired,
  onClose: t.func,
};
