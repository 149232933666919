import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import { HistoricoDetails } from './CustomTable';
import Select from '../../components/Forms/SelectCustom';
import { selectDoc } from '../../commons';
import Loading from '../Loading';
import { isoToDatePTBR, objectIsEmpty } from '../../utils/utils';
import { validations } from '../../helpers/validations';
import InputMaskCustom from '../Forms/InputMaskCustom';
import {
  Title,
  ContainerInput,
  ContainerGeneric,
  AccordionContainer,
  SearchResult,
  Dependentes,
  Historico,
  Convenios,
} from './styles';
import { actionSearchUser } from '../../actions';
import Alert from '../../components/Alert';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: '',
      portal: [],
      open: false,
      type: '',
      message: '',
      openResult: false,
      historicoSuporte: [],
      historicoAcionamento: [],
      relacionamentoConecta: [],
      documentsConvs: '',
      docType: '',
      loading: false,
      user: [],
      dependentes: {
        name: '',
        convenio: '',
        cpf: '',
        birthDate: '',
      },
      qtdConvenio: '',
      convenio: [],
      convenios: {
        name: '',
        dataIni: '',
        dataExp: '',
      },
    };
    this.openResult = this.openResult.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.requestTimeOut = this.requestTimeOut.bind(this);
    this.setMask = this.setMask.bind(this);
  }

  componentDidMount() {
    this.handleClose();
  }
  handleChange(e) {
    const { value } = e.target;
    this.setState({
      doc: value,
    });
  }
  handleSelect(e) {
    const { value } = e.target;
    this.setState({
      docType: value,
    });
  }
  sendCpf() {
    if (this.state.doc.length <= 4) {
      this.setState({
        open: true,
        type: 'error',
        message: 'Documento inválido ou Não possui registros!',
        loading: false,
      });
    }
    if (this.state.doc.length >= 5) {
      let user = this.props.user;
      const search = {
        documentType: this.state.docType,
        document: this.state.doc.trim(),
      };
      if (this.state.docType === '') {
        this.setState({
          open: true,
          type: 'error',
          message: 'Selecione o tipo de documento!',
          loading: false,
        });
      } else {
        this.props.dispatch(
          actionSearchUser.SearchUSer(
            search,
            user,
            this.openResult,
            this.requestTimeOut
          )
        );
        this.setState({
          loading: true,
        });
      }
    }
  }

  handleClose() {
    this.setState({
      open: false,
      message: '',
      type: '',
    });
  }

  formataCPF(cpf = '') {
    //retira os caracteres indesejados...
    if (validations.validarCPF(cpf)) {
      cpf = cpf ? cpf.replace(/[^\d]/g, '') : '';
      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return cpf;
    }
  }
  requestTimeOut() {
    this.setState({
      openResult: false,
      open: true,
      type: 'error',
      message: 'Tempo de resposta excedido!',
      loading: false,
    });
  }

  setPortalUser(portal, document = '') {
    let cpf = document.replace(/[^\d]/g, '');
    let arr = [];

    portal.map((element) => {
      if (element.documento.replace(/[^\d]/g, '') == cpf) {
        element.documento = this.formataCPF(element.documento);
        element.email = element.email.toLowerCase();
        arr.push(element);
      }
    });

    return !arr ? portal : arr;
  }

  formataData(str) {
    let { searchUser } = this.props.searchUser;
    let data = searchUser;
    let relacionamentoConecta = data.relacionamentoConecta;
    function date() {
      if (str) {
        return str;
      } else {
        return relacionamentoConecta.birthDate;
      }
    }
    return isoToDatePTBR(date());
  }
  existsResult() {
    let { searchUser } = this.props.searchUser;
    let data = searchUser;

    let resp = true;
    if (!data.relacionamentoConecta.message) {
      resp = false;
    }
    if (data.historicoAcionamento.length > 0) {
      resp = false;
    }
    if (data.historicoSuporte.length > 0) {
      resp = false;
    }

    if (data.relacionamentoPortal.length > 0) {
      resp = false;
    }

    return resp;
  }

  async openResult() {
    let { searchUser } = this.props.searchUser;
    let data = searchUser;
    let relacionamentoConecta = data.relacionamentoConecta;
    let result = this.existsResult();

    if (result) {
      this.setState({
        openResult: false,
        open: true,
        type: 'error',
        message: 'Documento inválido ou Não possui registros!',
        loading: false,
      });
    } else {
      this.handleClose();
      let newArr = [];
      let newArrDep = [];

      data.relacionamentoPortal.map((r) => {
        r.origem = 'Portal';
        r.name = r.nome;

        if (r.document_type == 'CPF') {
          r.documento = this.formataCPF(r.documento);
        }

        if (r.titularidade == 'TITU') newArr.push(r);
        if (r.titularidade == 'DEPE') newArrDep.push(r);

        return { ...r };
      });

      data.relacionamentoConecta.dependents = newArrDep;
      let conv = newArr.length > 0 ? newArr : data.relacionamentoPortal;

      data.relacionamentoConecta.docs = data.relacionamentoConecta.docs.map(
        (relacionamentoConecta) => {
          return {
            name: relacionamentoConecta.name
              ? relacionamentoConecta.name +
                ' ' +
                relacionamentoConecta.lastName
              : '',
            email: relacionamentoConecta.email
              ? relacionamentoConecta.email
              : '',
            cpf: relacionamentoConecta.document
              ? this.formataCPF(relacionamentoConecta.document)
              : '',
            tel1: relacionamentoConecta.phone
              ? relacionamentoConecta.phone
                  .replace(/\-/g, '')
                  .replace(/\(/g, '')
                  .replace(/\)/g, '')
                  .replace(' ', '')
              : '',
            birthDate: relacionamentoConecta.birthDate
              ? this.formataData(relacionamentoConecta.birthDate)
              : '',
          };
        }
      );

      this.setState({
        loading: false,
        openResult: true,
        qtdConvenio: conv ? conv.length : '',
        historicoSuporte: data.historicoSuporte,
        historicoAcionamento: data.historicoAcionamento,
        convenio: conv,
        portal: this.setPortalUser(conv, this.state.doc),
        relacionamentoConecta: data.relacionamentoConecta,
        doc: '',
        docType: '',
        user: data.relacionamentoConecta.docs,
      });
    }
  }

  setMask(mask) {
    if (mask === 'CPF') {
      return '999.999.999-99';
    } else {
      return '*********************';
    }
  }
  render() {
    return (
      <>
        <section>
          <Alert
            open={this.state.open}
            type={this.state.type}
            message={this.state.message}
            onClose={() => this.handleClose()}
          />
          <Title></Title>
          <Grid justify='center' container spacing={3}>
            <ContainerInput>
              <Select
                width='30%'
                label='Documento'
                marginBottom='20px'
                value={this.state.docType}
                dataSource={selectDoc}
                onChange={this.handleSelect}
              />
              <Grid
                item
                xs={5}
                style={{ marginLeft: 15, marginBottom: 20, paddingTop: 20 }}
              >
                <InputMaskCustom
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.doc}
                  placeholder='Digite o documento'
                  mask={this.setMask(this.state.docType)}
                  maskChar=' '
                  fullWidth
                />
              </Grid>

              <div style={{ marginLeft: '10px' }}>
                <button
                  style={{
                    marginTop: 15,
                    border: 0,
                    borderRadius: 15,
                    height: 30,
                    color: '#fff',
                    backgroundColor: '#409aa5',
                    fontWeight: 'bold',
                    width: 100,
                    cursor: 'pointer',
                    outline: '0',
                  }}
                  onClick={() => this.sendCpf()}
                >
                  Buscar
                </button>
              </div>
            </ContainerInput>
            {this.state.loading && <Loading />}
            {this.state.openResult && (
              <ContainerGeneric>
                {this.state.user.length > 0 && (
                  <Title>Informações do Conecta </Title>
                )}
                {this.state.user.map((r) => {
                  return (
                    <div style={{ marginTop: '30px' }}>
                      <SearchResult>
                        <TextField
                          id='name'
                          value={r.name}
                          disabled={true}
                          style={{ margin: 8, width: '35%' }}
                          margin='normal'
                          label='Nome'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          id='email'
                          value={r.email}
                          disabled={true}
                          style={{ margin: 8, width: '35%' }}
                          margin='normal'
                          label='E-mail'
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                        <TextField
                          id='documento'
                          value={r.cpf}
                          disabled={true}
                          style={{ margin: 8, width: '25%' }}
                          margin='normal'
                          label='Documento'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {r.birthDate && (
                          <TextField
                            id='birthDate'
                            value={r.birthDate}
                            disabled={true}
                            style={{ margin: 8, width: '22%' }}
                            margin='normal'
                            label='Data de Nascimento'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                        <TextField
                          id='telefone1'
                          value={r.tel1}
                          disabled={true}
                          style={{ margin: 8, width: '22%' }}
                          margin='normal'
                          label='Telefone'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </SearchResult>
                    </div>
                  );
                })}
                {this.state.convenio && (
                  <>
                    <Title>Informações do Portal Empresas </Title>
                    <div>
                      {this.state.portal.message ||
                        (objectIsEmpty(this.state.portal) && (
                          <Title> Não Elegível </Title>
                        ))}
                    </div>
                    <div>
                      {this.state.portal.length > 0 && (
                        <SearchResult>
                          <TextField
                            id='name'
                            value={this.state.portal[0].nome}
                            disabled={true}
                            style={{ margin: 8, width: '35%' }}
                            margin='normal'
                            label='Nome'
                            InputProps={{
                              readOnly: true,
                            }}
                          />

                          <TextField
                            id='documento'
                            value={this.state.portal[0].documento}
                            disabled={true}
                            style={{ margin: 8, width: '25%' }}
                            margin='normal'
                            label='Documento'
                            InputProps={{
                              readOnly: true,
                            }}
                          />

                          <TextField
                            id='birthDate'
                            value={this.state.portal[0].data_nascimento}
                            disabled={true}
                            style={{ margin: 8, width: '22%' }}
                            margin='normal'
                            label='Data de Nascimento'
                            InputProps={{
                              readOnly: true,
                            }}
                          />

                          <TextField
                            id='telefone'
                            value={this.state.portal[0].telefone}
                            disabled={true}
                            style={{ margin: 8, width: '22%' }}
                            margin='normal'
                            label='Telefone'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <TextField
                            id='email'
                            value={this.state.portal[0].email}
                            disabled={true}
                            style={{ margin: 8, width: '30%' }}
                            margin='normal'
                            label='E-mail'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </SearchResult>
                      )}
                    </div>
                  </>
                )}
                <AccordionContainer>
                  {this.state.convenio.length > 0 && (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <span className='span-title'>
                          <h4>Convênios ( {this.state.qtdConvenio} )</h4>
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Convenios>
                          <Historico>
                            <HistoricoDetails type={'convenio'} />
                          </Historico>
                        </Convenios>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {!this.state.relacionamentoConecta.message &&
                    this.state.relacionamentoConecta.dependents.length > 0 && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <span className='span-title'>
                            <h4>
                              Vínculos ({' '}
                              {
                                this.state.relacionamentoConecta.dependents
                                  .length
                              }{' '}
                              )
                            </h4>
                          </span>
                        </AccordionSummary>

                        <Dependentes>
                          {this.state.relacionamentoConecta.dependents.map(
                            (r) => {
                              r.origem = 'Conecta';
                              r.documentNumber = this.formataCPF(
                                r.documentNumber
                              );
                              r.birthDate = this.formataData(r.birthDate);
                              return (
                                <>
                                  <TextField
                                    id='name'
                                    value={r.nome}
                                    disabled={true}
                                    style={{ margin: 8, width: '30%' }}
                                    margin='normal'
                                    label='Nome'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                  <TextField
                                    id='documento'
                                    value={r.documento}
                                    disabled={true}
                                    style={{ margin: 8, width: '20%' }}
                                    margin='normal'
                                    label='Documento'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                  <TextField
                                    id='birthDate'
                                    value={r.data_nascimento}
                                    disabled={true}
                                    style={{ margin: 8, width: '20%' }}
                                    margin='normal'
                                    label='Data de Nascimento'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                  <TextField
                                    id='Contrato'
                                    value={r.descricao}
                                    disabled={true}
                                    style={{ margin: 8, width: '10%' }}
                                    margin='normal'
                                    label='Contrato'
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </>
                              );
                            }
                          )}
                        </Dependentes>
                      </Accordion>
                    )}
                  {!this.state.historicoAcionamento.message &&
                    this.state.historicoAcionamento.length > 0 && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel2a-content'
                          id='panel2a-header'
                        >
                          <span className='span-title'>
                            <h4>
                              Histórico de Acionamentos ({' '}
                              {this.state.historicoAcionamento.length} )
                            </h4>
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Historico>
                            <HistoricoDetails type={'acionamento'} />
                          </Historico>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  {!this.state.historicoSuporte.message &&
                    this.state.historicoSuporte.length > 0 && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel2a-content'
                          id='panel2a-header'
                        >
                          <span className='span-title'>
                            <h4>
                              Histórico do Suporte ({' '}
                              {this.state.historicoSuporte.length} )
                            </h4>
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Historico>
                            <HistoricoDetails type={'suporte'} />
                          </Historico>
                        </AccordionDetails>
                      </Accordion>
                    )}
                </AccordionContainer>
              </ContainerGeneric>
            )}
          </Grid>
        </section>
      </>
    );
  }
}
function mapStateToProps(state) {
  let user = state.users.user;
  let searchUser = state.searchUser;

  return {
    user,
    searchUser,
  };
}

const connectedLoginPage = connect(mapStateToProps)(Search);
export { connectedLoginPage as Search };
