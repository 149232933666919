import { combineReducers } from 'redux'

import reducers from '../../reducers'

import reduxReducers from '../redux/reducers'

const rootReducer = (history) =>
	combineReducers({
		router: history,
		...reducers,
		...reduxReducers,
	})

export default rootReducer
