import styled, { css } from 'styled-components';
import { styled as styledMui } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

export const Header = styledMui(Grid)({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
});

export const Logo = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  & img {
    height: 32px;
  }
`;

type TitleProps = {
  justifyContent: 'flex-start' | 'center' | 'flex-end';
};

export const Title = styled.div<TitleProps>`
  ${({ justifyContent }) => css`
    align-items: 'center';
    display: flex;
    justify-content: ${justifyContent};
    & h1 {
      font-weight: 500;
      font-size: 24px;
    }
  `}
`;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const User = styled.div`
  align-items: center;
  color: #bbb;
  cursor: pointer;
  display: flex;
  font-size: 40px;
  font-weight: 400;
  justify-content: flex-end;
  & p {
    color: #fff;
    font-size: 16px;
    padding: 0 12px 0 0;
  }
`;
