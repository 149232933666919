import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '11px',
    color: 'red',
  },
  sucess: {
    backgroundColor: green[600],
  },
  boxdialog: {
    margin: '0 auto 0 auto',
    '@media only screen and (max-width: 900px)': {
      padding: '0 0 20px 0',
      margin: '0 auto 0 auto',
      '@media screen and (orientation: landscape)': {
        margin: '0 auto',
      },
    },
  },
  dialog_modal: {
    borderRadius: '50%',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    margin: '0 auto',
  },
  titleDialog: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'Poppins',
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
  },
  close: {
    position: 'absolute',
    width: '45px',
    top: '2px',
  },
  rootStyle: {
    borderRadius: 20,
  },
  dialogAlert: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
});

class ModalImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vertical: 'top',
      horizontal: 'center',
      currentTime: 10,
      copySuccess: '',
      maxWidth: 'xs',
    };
  }

  render() {
    const { component, classes, open, close, message, onSubmit } = this.props;
    return (
      <React.Fragment>
        <Dialog
          classes={{ paper: classes.rootStyle }}
          open={open}
          maxWidth={this.state.maxWidth}
          disableAutoFocus={true}
          title={message || ''}
          fullWidth={true}
        >
          <DialogActions>
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={close}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </DialogActions>
          <DialogTitle className={classes.titleDialog}>
            <span className={classes.titleDialog}>{message}</span>
          </DialogTitle>
          <DialogContent>{component}</DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

ModalImage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModalImage);
