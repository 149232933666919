import { queueItemsConstants } from '../constants';

export function queueItems(state = { queueItems: {} }, action) {
  switch (action.type) {
    case queueItemsConstants.QUEUEITEMS_SUCCESS:
      return {
        type: 'sucess',
        queueItems: action.queueItems,
      };
    case queueItemsConstants.QUEUEITEMS_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        queueItems: {},
      });
    case queueItemsConstants.QUEUEITEMS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
