import { cockpitConstants } from '../constants';

export function cockpit(state = {}, action) {
  switch (action.type) {
    case cockpitConstants.COCKPIT_SUCCESS:
      return {
        type: 'success',
        message: action.message,
        open: true,
        loading: true,
      };
    case cockpitConstants.COCKPIT_REQUEST:
      return Object.assign({}, state, {
        type: 'error',
        message: action.message,
        open: true,
        loading: true,
      });
    case cockpitConstants.COCKPIT_FAILURE:
      return Object.assign({}, state, {
        type: action.varient ? action.varient : 'error',
        message: action.message,
        open: false,
        loading: null,
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
