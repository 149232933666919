// @ts-nocheck
import React, { useCallback, useEffect } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import { QueueRecordEntity } from '../../../domain/entities';
import { cpfMask } from '../../../application/shared/utils';
import { formatDate } from '../../../application/shared/adapters';
import {
  FindAllQueueRecordsStore,
  ShowQueueRecordsStore,
} from '../../../application/store';
import {
  FindAllQueueRecordsData,
  ResendCockpitData,
} from '../../../application/data';
import { PaginationProps } from '../../../application/shared/hooks';
import { TableRenderSkeleton } from './table-skeleton';
import { ResendCockpit } from './resend-cockpit';

type Props = {
  findAllQueueRecordsStore: FindAllQueueRecordsStore;
  findAllQueueRecordsData: FindAllQueueRecordsData;
  showQueueRecordsStore: ShowQueueRecordsStore;
  resendCockpitData: ResendCockpitData;
  pagination: PaginationProps;
  paginationComponent: JSX.Element;
};

export const QueueRecordTable = ({
  findAllQueueRecordsStore,
  findAllQueueRecordsData,
  showQueueRecordsStore,
  resendCockpitData,
  pagination,
  paginationComponent,
}: Props) => {
  const { page, offset } = pagination;
  const {
    findAllQueueRecordsSelector,
    handleQueueRecordList,
    handlePages,
    handleTotalPages,
    handleLoading,
    handleError,
    handleErrorMessage,
  } = findAllQueueRecordsStore;
  const selector = findAllQueueRecordsSelector;

  const { handleModal, handleShow } = showQueueRecordsStore;

  const fetch = useCallback(async () => {
    handleLoading(true);
    handleError(false);
    handleErrorMessage('');
    try {
      const response = await findAllQueueRecordsData.handle(
        selector.filter,
        page,
        offset
      );
      handleQueueRecordList(response.data.docs || []);
      handlePages(response.data.pages);
      handleTotalPages(response.data.total);
      handleLoading(false);
    } catch (error: any) {
      handleError(true);
      handleErrorMessage(error.response.data.message);
    }
  }, [pagination.page, pagination.offset]);

  useEffect(() => {
    fetch();
  }, [pagination.page, pagination.offset]);

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Ações</TableCell>
          <TableCell>ID Central</TableCell>
          <TableCell>Data e Hora da ID Central</TableCell>
          <TableCell>Nome</TableCell>
          <TableCell>Sobrenome</TableCell>
          <TableCell>Documento</TableCell>
          <TableCell>Contrato</TableCell>
          <TableCell>Entrada</TableCell>
          <TableCell>Canal de Acesso</TableCell>
        </TableRow>
      </TableHead>
      {selector.loading || selector.sendCockpitLoading ? (
        <TableRenderSkeleton />
      ) : (
        <TableBody>
          {selector.list.map((queueRecord: QueueRecordEntity) => (
            <TableRow key={queueRecord._id}>
              <TableCell>
                <div style={{ display: 'flex' }}>
                  <Tooltip title='Expandir'>
                    <IconButton
                      color='inherit'
                      onClick={() => {
                        handleModal(true);
                        handleShow(queueRecord);
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  {!(
                    queueRecord.sendCockPit === true ||
                    queueRecord.sendCockPit === undefined
                  ) && (
                    <ResendCockpit
                      queueRecord={queueRecord}
                      resendCockpitData={resendCockpitData}
                      findAllQueueRecordsStore={findAllQueueRecordsStore}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell>{queueRecord.centralId}</TableCell>
              <TableCell>
                {queueRecord.createIdCentral &&
                  formatDate(
                    new Date(queueRecord.createIdCentral),
                    'DD/MM/YYYY HH:mm'
                  )}
              </TableCell>
              <TableCell>{queueRecord.name}</TableCell>
              <TableCell>{queueRecord.lastName}</TableCell>
              <TableCell>
                {queueRecord.documentType === 'CPF' && queueRecord.document
                  ? cpfMask(queueRecord.document)
                  : queueRecord.document}
              </TableCell>
              <TableCell>{queueRecord.convenioDescricao}</TableCell>
              <TableCell>
                {formatDate(queueRecord.createDate, 'DD/MM/YYYY HH:mm')}
              </TableCell>
              <TableCell>
                {queueRecord.deviceData &&
                  `${
                    queueRecord.deviceData.deviceType
                      ? queueRecord.deviceData.deviceType
                      : ''
                  }
                ${
                  queueRecord.deviceData.accessChannel
                    ? queueRecord.deviceData.accessChannel
                    : ''
                }
                ${queueRecord.deviceData.OS ? queueRecord.deviceData.OS : ''}
              `}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
      <TableFooter>{paginationComponent}</TableFooter>
    </Table>
  );
};
