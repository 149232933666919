import { citiesConstants } from '../constants';

export function citie(state = { cities: [] }, action) {
  switch (action.type) {
    case citiesConstants.CITIE_SUCCESS:
      return {
        type: 'success',
        cities: action.result.cities,
      };
    case citiesConstants.CITIE_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        cities: [],
      });
    case citiesConstants.CITIE_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
