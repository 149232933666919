import { queuesConstants } from '../constants';

export function queues(state = { queues: [] }, action) {
  switch (action.type) {
    case queuesConstants.QUEUE_SUCCESS:
      return {
        type: 'success',
        queues: action.result,
      };
    case queuesConstants.QUEUE_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        queues: [],
      });
    case queuesConstants.QUEUE_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
