import React from 'react';

import { CustomDivider, CustomButton, CustomModal, CustomText } from '../../';

import { StyledButtonGroup } from './styles';

import t from 'prop-types';

const Confirmation = ({ show, title, onClose, onConfirm }) => {
  return (
    show && (
      <CustomModal show={show}>
        <>
          <CustomText title={1} color='dark' size='xlarge'>
            {title}
          </CustomText>
          <CustomDivider />
          <StyledButtonGroup>
            <CustomButton
              size='large'
              text='Cancelar'
              color='danger'
              type='outlined'
              onClick={onClose}
            />
            <CustomButton
              size='large'
              text='Confirmar'
              color='primary'
              onClick={onConfirm}
            />
          </StyledButtonGroup>
          <CustomDivider space={0.5} />
        </>
      </CustomModal>
    )
  );
};

export default Confirmation;

Confirmation.propTypes = {
  show: t.bool.isRequired,
  title: t.string.isRequired,
  onClose: t.func.isRequired,
  onConfirm: t.func.isRequired,
};
