import {
  cancelamentoUserConstants,
  cancelamentoListConstants,
} from '../constants';
import { cancelamentoUserService } from '../services';
import { alertActions } from './';
import { cancelamentoService } from '../services';
// import { alertActions } from '.'
import { history } from '../helpers';
import moment from 'moment';
import { validations } from '../helpers/validations';

export const cancelamentoUserActions = {
  getCancelamentosByUSer,
  ConfirmationCancelamentoUser,
  paginateCancelamentos,
  paginateCancelamentosWithReducer,
  downloadJson,
  SendCancellation,
};

function downloadJson(dataInicioCsv, dataFimCsv, page) {
  return cancelamentoService.DownloadJSON(dataInicioCsv, dataFimCsv, page);
}

function getCancelamentosByUSer(user) {
  return (dispatch) => {
    dispatch(request());
    dispatch(success(user));
    history.push('/cancelamentos');
  };

  function request() {
    return { type: cancelamentoUserConstants.CANCELAMENTO_USER_REQUEST };
  }

  function success(cancelamentosUser) {
    return {
      type: cancelamentoUserConstants.CANCELAMENTO_USER_SUCCESS,
      cancelamentosUser,
    };
  }

  function failure(error) {
    return { type: cancelamentoUserConstants.CANCELAMENTO_USER_FAILURE, error };
  }
}
function paginateCancelamentosWithReducer(
  pageSize = 5,
  page = 1,
  queryFilter = {}
) {
  return (dispatch) => {
    dispatch(request);
    cancelamentoService
      .paginateCancelamentos(pageSize, page, queryFilter)
      .then((result) => {
        dispatch(success(result));
      })
      .catch((err) => {
        dispatch(failure);
      });
    function success(cancelmentItems) {
      cancelmentItems.docs = cancelmentItems.docs.map((element) => {
        element.phone = element.phone
          .replace(' ', '')
          .replace('(', '')
          .replace(')', '')
          .replace('-', '');

        if (element.phone) {
          if (element.phone.length > 11) {
            element.phone = `${element.phone}`;
          } else {
            switch (element.phone.length) {
              case 10:
                element.phone =
                  `(${element.phone.slice(0, 2)})` +
                  ' ' +
                  `${element.phone.slice(2, 6)}-${element.phone.slice(6, 10)}`;
                break;
              default:
                element.phone =
                  `(${element.phone.slice(0, 2)})` +
                  ' ' +
                  `${element.phone.slice(2, 7)}-${element.phone.slice(7, 11)}`;
                break;
            }
          }
        }

        let valCpf = validations.validationCPF(element.document);
        if (!valCpf) {
          element.document = element.document.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            function (regex, argumento1, argumento2, argumento3, argumento4) {
              return (
                argumento1 +
                '.' +
                argumento2 +
                '.' +
                argumento3 +
                '-' +
                argumento4
              );
            }
          );
        }

        element.validCpf = !valCpf;
        element.statusCancel.updatedTime = moment(element.updatedAt).format(
          'DD/MM/YYYY HH:mm:ss'
        );
        element.purchaseDate = moment(element.purchaseDate).format(
          'DD/MM/YYYY HH:mm:ss'
        );
        element.createdAt = element.log[0].updatedTime
          ? moment(element.log[0].updatedTime, 'YYYY-MM-DDTHH:mm:ss').format(
              'DD/MM/YYYY HH:mm:ss'
            )
          : '';
        if (element.statusCancel.futureDate)
          element.statusCancel.futureDate = moment(
            element.statusCancel.futureDate,
            'YYYY-MM-DDTHH:mm:ss'
          ).format('DD/MM/YYYY HH:mm:ss');
        element.fullName =
          (element.name ? element.name : '') +
          ' ' +
          (element.lastName ? element.lastName : '');
        return { ...element };
      });
      return {
        type: cancelamentoListConstants.CANCELAMENTO_LIST_SUCCESS,
        cancelmentItems,
      };
    }
    function failure(error) {
      return {
        type: cancelamentoListConstants.CANCELAMENTO_LIST_FAILURE,
        error,
      };
    }
    function request() {
      return {
        type: cancelamentoListConstants.CANCELAMENTO_LIST_REQUEST,
      };
    }
  };
}
function paginateCancelamentos(pageSize = 5, page = 1, queryString = '') {
  return new Promise((resolve) => {
    cancelamentoService
      .paginateCancelamentos(pageSize, page, queryString)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        return resolve({
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        });
      });
  });
}

function ConfirmationCancelamentoUser(cancelamento) {
  return (dispatch) => {
    cancelamentoService
      .confirmationCancel(cancelamento)
      .then(
        (body) => {
          dispatch(alertActions.success('Cancelamento Atualizado'));
          history.push('/canc/ativos');
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(alertActions.error(error.message));
      });
  };
}

function SendCancellation(user, motivo, mais) {
  return (dispatch) => {
    cancelamentoUserService
      .getUserNewCancel(user, motivo)

      .then(
        (body) => {
          dispatch(alertActions.success('Registro de Cancelamento Realizado'));
          history.push('/canc/ativos');
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(alertActions.error(error.message));
      });
  };
}
