import React from 'react';

import { LoaderContent, Object } from './styles';

export default function Loader(props) {
  return (
    <LoaderContent background={props.background}>
      <Object></Object>
      <Object></Object>
      <Object></Object>
      <Object></Object>
      <Object></Object>
      <Object></Object>
      <Object></Object>
      <Object></Object>
    </LoaderContent>
  );
}
