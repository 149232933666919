import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 20px 0px;
  background-color: #f5f5f5;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledSchedullingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  color: #6b7786;
  background: #fff;
  position: relative;
  gap: 20px;

  padding: 5px 10px;
  margin-right: 10px;

  /*   @media screen and (max-width: 992px) {
    flex-direction: column;
  } */
`;
export const StyledProfileTimerPause = styled.div`
  background: #f1f4f6;
  padding: 10px 20px 6px 20px;
  border-radius: 4px;

  color: #6b7786;
  transition: hover ease 0.5s;
`;
export const StyledProfileTimerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
`;
export const StyledProfileTimerTextTitle = styled.div`
  font-size: 12px;
  color: #6b7786;
`;
export const StyledProfileTimerTextSubTitle = styled.div`
  font-weight: bold;
  color: #2e384d;
`;
export const StyledProfileTimerSelected = styled.div`
  cursor: pointer;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin: auto;
  padding: 0 30px;
  width: 100%;
  min-width: 1270px;
  @media (min-width: 1440px) {
    max-width: 1440px;
  }
`;

export const Logo = styled.div`
  & img {
    width: 120px;
  }
`;

export const Title = styled.div`
  & h1 {
    font-size: 23px;
    text-align: center;
  }
`;

export const Profile = styled.div`
  display: flex;
`;
