import { conveniosConstants } from '../constants';

export function convenios(state = { convenios: [] }, action) {
  switch (action.type) {
    case conveniosConstants.CONVENIOS_SUCCESS:
      return {
        type: 'success',
        convenios: action.convenios,
      };
    case conveniosConstants.CONVENIOS_FAILURE:
      return Object.assign({}, state, {
        type: 'failure',
        convenios: [],
      });
    case conveniosConstants.CONVENIOS_REQUEST:
      return Object.assign({}, state, {
        type: 'request',
      });
    default:
      console.debug('notification reducer :: hit default', action.type);
      return state;
  }
}
