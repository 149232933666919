import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: 'RubikRegular';
  }
  body {
    background: #f0f4f7;
    -webkit-font-smoothing: antialiased;
  }
`;

export default GlobalStyle;
