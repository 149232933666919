import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

export const differenceTime = (dateStarted, timeDifference) => {
  const realTime = useSelector(
    (state) => state.socketInfosTime.socketInfosTime
  );

  const differenceDateStarted = moment(realTime, 'YYYY-MM-DDTHH:mm:ss').diff(
    moment(dateStarted, 'YYYY-MM-DDTHH:mm:ss'),
    'milliseconds'
  );

  return differenceDateStarted >= timeDifference;
};
