import { formatMask } from '../../../../../../Spread/utils/format';

import {
  regexPhoneNumber,
  regexHasNumber,
} from '../../../../../../Spread/components/ui/webform/utils/regex';

export const validateFormNumberSchema = (maskedPhone) => {
  if (maskedPhone) {
    const hasNumber = regexHasNumber.test(maskedPhone);
    const isComplete = regexPhoneNumber.test(maskedPhone);

    if (!hasNumber) return true;

    return hasNumber && isComplete ? true : false;
  }

  return true;
};

export const parsePhoneNumber = (phoneNumber) => {
  const parsedPhoneNumber = phoneNumber ? `${phoneNumber}` : null;

  const nineDigitsMask = '(##) #########';
  const eightDigitsMask = '(##) ########';

  if (!parsedPhoneNumber) return '';

  const phoneNumberWithoutAreaCode = parsedPhoneNumber.slice(2);

  return formatMask(
    parsedPhoneNumber,
    phoneNumberWithoutAreaCode < 11 ? eightDigitsMask : nineDigitsMask
  );
};

export const phoneMask = (value) =>
  value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
