import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import { validations } from '../helpers/validations';
import ModalConvenio from './ModalConvenio';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { alertActions } from '../actions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import Alert from './Alert';
import AlertLink from './AlertLink';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import InputMask from 'react-input-mask';
import * as paparse from 'papaparse';
import { filaSuporte } from '../actions';
import { translationSupport } from '../commons';
import { filaSuporteService } from '../services';
import _ from 'lodash';

const useStylesFilterComponent = (theme) => ({
  root: {
    padding: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  formAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  textField: {
    width: '9%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  dateField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-100px',
    fontWeight: 500,
  },
  dateFieldEntry: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-30px',
    fontWeight: 500,
  },
  dateFieldHistory: {
    width: '30%',
  },
  searchClick: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: 'inherit',
  },
  loaderAlign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  refreshClick: {
    textDecoration: 'none',
    color: 'inherit',
    marignTop: '20%',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '15px',
    color: 'red',
  },
});

const useStylesPaginationComponent = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class HistoricoSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      historicosData: [],
      errorCsv: false,
      loaderPercent: 0,
      rowsPerPage: [5, 10, 15],
      dataInicioCsv: '',
      dataFimCsv: '',
      rows: [],
      dataInicioCsvValida: false,
      dataFimCsvValida: false,
      periodoCsvValido: false,
      numberOfRows: 5,
      modalCsvIsOpen: false,
      page: 1,
      total: undefined,
      itemByPage: 1,
      form: {
        prontuario: '',
        name: '',
        lastName: '',
        document: '',
        convenioDescricao: '',
        entrada: '',
      },
      filterData: false,
      sendfilter: [],
      showModalDownloadCsv: false,
    };
    this.itemByPage = 5;
    this.handleClose = this.handleClose.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeValueCsvInicio = this.changeValueCsvInicio.bind(this);
    this.changeValueCsvFim = this.changeValueCsvFim.bind(this);
    // this.downloadCsvModal = this.downloadCsvModal.bind(this);
    // this.closeCsvModal = this.closeCsvModal.bind(this);
    this.submitCsvModal = this.submitCsvModal.bind(this);
  }

  filterDate(date) {
    if (moment(date, 'DD/MM/YYYY HH:mm').isValid()) {
      return moment(date, 'DD/MM/YYYY HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    } else {
      return moment(date, 'YYYY-MM-DD HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString();
    }
  }

  formataCPF(cpf = '') {
    //retira os caracteres indesejados...
    cpf = cpf ? cpf.replace(/[^\d]/g, '') : '';
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  resolvePageDownload(dataInicioCsv, dataFimCsv, page) {
    return new Promise((resolve, reject) => {
      filaSuporte
        .downloadJson(dataInicioCsv, dataFimCsv, page)
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resolvePageDownloadWithoutExecutePromise(dataInicioCsv, dataFimCsv, page) {
    return filaSuporte.downloadJson(dataInicioCsv, dataFimCsv, page);
  }

  convertCsv(array) {
    array = array.map((arr) => {
      if (arr.createdAt)
        arr.createdAt = moment(arr.createdAt, 'YYYY-MM-DDTHH:mm:ss')
          .add(-3, 'hours')
          .format('YYYY-MM-DDTHH:mm:ss')
          .toString();
      if (arr.updatedAt)
        arr.updatedAt = moment(arr.updatedAt, 'YYYY-MM-DDTHH:mm:ss')
          .add(-3, 'hours')
          .format('YYYY-MM-DDTHH:mm:ss')
          .toString();

      if (arr['supportUser']) {
        arr.supportUserEmail = arr['supportUser']['email'];
        arr.supportUserLogin = arr['supportUser']['login'];
        arr.supportUserNome = arr['supportUser']['nome'];
        arr.supportUserTelefone = arr['supportUser']['telefone'];
      }

      if (arr['origin']) {
        arr.originChannel = arr['origin']['channel'];
        arr.originFrom = arr['origin']['from'];
        arr.originReceived = arr['origin']['received'];
      }

      if (arr['deviceData']) {
        arr.deviceDataDeviceType = arr['deviceData']['deviceType'];
        arr.deviceDataOS = arr['deviceData']['OS'];
        arr.deviceDataNavigator = arr['deviceData']['navigator'];
        arr.deviceDataRoute = arr['deviceData']['route'];
        arr.deviceDataDeviceBrand = arr['deviceData']['deviceBrand'];
        arr.deviceDataAppVersion = arr['deviceData']['appVersion'];
        arr.deviceDataAccessChannel = arr['deviceData']['accessChannel'];
      }
      return arr;
    });

    let perseCSV = paparse;
    let response = perseCSV.unparse({
      fields: [
        'name',
        'document',
        'convenioDescricao',
        'email',
        'convenio',
        'createdAt',
        'deviceDataDeviceType',
        'deviceDataOS',
        'deviceDataNavigator',
        'deviceDataRoute',
        'deviceDataDeviceBrand',
        'deviceDataAppVersion',
        'deviceDataAccessChannel',
        'originChannel',
        'originFrom',
        'originReceived',
        'reasonDesc',
        'statusDoSuporte',
        'Entrada',
        'documentType',
        'birthDate',
        'phone',
        'homePhone',
        'gender',
        'partnerID',
        'nationality',
        'createDate',
        'reason',
        'updatedAt',
        'supportUserEmail',
        'supportUserLogin',
        'supportUserNome',
        'supportUserTelefone',
        'supportCompleted',
        'generalObservations',
      ],
      data: array,
    });

    response = response.replace(/undefined/g, '');
    response = response.replace(/null/g, '');
    var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'historicocsv.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async submitCsvModal() {
    let { dataInicioCsv, dataFimCsv } = this.state;
    let periodoInvalido = validations.validationCsvRangeDate(
      dataInicioCsv,
      dataFimCsv
    );

    if (dataInicioCsv == '' || dataFimCsv == '' || periodoInvalido) {
      this.setState({ periodoCsvValido: true });
    } else {
      this.setState({ errorCsv: false, showLoader: true });
      let items = [];
      let hasError = false;
      let firstPage = await this.resolvePageDownload(
        dataInicioCsv,
        dataFimCsv,
        1,
        1
      ).catch((err) => {
        this.setState({ loaderPercent: 0, showLoader: false, errorCsv: true });
      });

      let { page = 0, pages = 0, limit = 0, docs = [] } = firstPage;
      for (let doc of docs) {
        items.push(doc);
      }
      let arrayPromises = [];
      do {
        page++;
        arrayPromises.push(
          this.resolvePageDownloadWithoutExecutePromise(
            dataInicioCsv,
            dataFimCsv,
            page,
            pages
          )
        );
      } while (page <= pages);
      await Promise.all(arrayPromises)
        .then((values) => {
          for (let value of values) {
            let { docs } = value;
            for (let doc of docs) {
              items.push(doc);
            }
          }
        })
        .catch((err) => {
          hasError = true;
          this.setState({
            periodoCsvValido: false,
            loaderPercent: 0,
            showLoader: false,
            dataInicioCsv: '',
            dataFimCsv: '',
            errorCsv: true,
          });
        });

      if (!hasError) {
        this.convertCsv(items);
        this.setState({
          periodoCsvValido: false,
          loaderPercent: 0,
          showLoader: false,
          dataInicioCsv: '',
          dataFimCsv: '',
          errorCsv: false,
        });
      }
    }
  }

  closeCsvModal() {
    this.setState({ modalCsvIsOpen: false });
  }

  changeValue(event) {
    let { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  }

  changeValueCsvInicio(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataInicioCsvValida: validations.validationCsvDate(value),
    });
  }

  changeValueCsvFim(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dataFimCsvValida: validations.validationCsvDate(value),
    });
  }

  TextMaskDownloadCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        id='formatted-text-mask-input'
        placeholder='Data de criação'
        mask='99/99/9999'
        maskChar={null}
      />
    );
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  styleComp() {
    let style = useStylesPaginationComponent();
    return style;
  }

  handleOpenCsv = () => {
    let { showModalDownloadCsv } = this.state;
    if (showModalDownloadCsv) {
      return this.setState({ showModalDownloadCsv: false });
    }
    return this.setState({ showModalDownloadCsv: true });
  };

  async fetchSupportHistory({ pageSize, page, search }) {
    const result = await filaSuporteService.getQueueSuportHistory(
      pageSize,
      page + 1,
      (search = search.replace(/([^\w]+|\s+)/g, ''))
    );

    let filtred;
    if (result.docs) {
      filtred = result.docs.map((element) => {
        element.motivo = translationSupport[element.reason];
        element.origem = element.origin.from;
        element.name = element.name + ' ' + element.lastName;

        if (element.supportCompleted) {
          element.statusDoSuporte = 'Concluído';
        } else {
          element.statusDoSuporte = 'Não concluído';
        }
        if (element.createDate) {
          element.createDate = this.filterDate(element.createDate);
        }
        if (element.documentType == 'CPF') {
          element.document = this.formataCPF(element.document);
        }

        if (!element.deviceData)
          element.deviceData = {
            deviceType: '',
            OS: '',
            navigator: '',
            route: '',
            accessChannel: '',
            appVersion: '',
            deviceBrand: '',
          };

        if (!element.deviceData.deviceType) element.deviceData.deviceType = '';
        if (!element.deviceData.accessChannel)
          element.deviceData.accessChannel = '';

        element.canalAcesso =
          element.deviceData.deviceType +
          ' ' +
          element.deviceData.accessChannel +
          ' ' +
          element.deviceData.OS;
        return { ...element };
      });

      return {
        data: filtred,
        page: result.page - 1,
        totalCount: result.total,
      };
    }
  }

  render() {
    let { open, message, type } = this.props;
    const { showLoader, showModalDownloadCsv } = this.state;

    const columns = [
      { title: 'Nome', field: 'name' },
      { title: 'Documento', field: 'document' },
      { title: 'Contrato', field: 'convenioDescricao' },
      { title: 'Entrada', field: 'createDate' },
      { title: 'Canal de acesso', field: 'canalAcesso' },
      { title: 'Origem', field: 'origem' },
      { title: 'Motivo', field: 'motivo' },
      { title: 'Status do Suporte', field: 'statusDoSuporte' },
    ];

    return (
      <div>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <ModalConvenio
          open={showModalDownloadCsv}
          close={() => this.handleOpenCsv()}
          component={
            <React.Fragment>
              {showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Loader type='Oval' color='#ccc' height={60} width={60} />
                </div>
              )}
              {!showLoader && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    label='De'
                    margin='normal'
                    name='dataInicioCsv'
                    value={this.state.dataInicioCsv}
                    onChange={this.changeValueCsvInicio}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                  <TextField
                    label='Até'
                    margin='normal'
                    name='dataFimCsv'
                    value={this.state.dataFimCsv}
                    onChange={this.changeValueCsvFim}
                    InputProps={{
                      inputComponent: this.TextMaskDownloadCustom,
                    }}
                  ></TextField>
                </div>
              )}
              {this.state.periodoCsvValido && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  O periodo entre as datas não pode ultrapassar 3 meses
                </div>
              )}
              {this.state.errorCsv && (
                <div
                  style={{ margin: '3px 0 0 0', fontSize: '9px', color: 'red' }}
                >
                  Erro ao baixar csv, tente novamente mais tarde
                </div>
              )}
            </React.Fragment>
          }
          message='Selecione o periodo de extração'
          onSubmit={async () => {
            await this.submitCsvModal();
          }}
        />

        <MaterialTable
          title=''
          options={{ sorting: false, debounceInterval: 1000 }}
          icons={tableIcons}
          columns={columns}
          data={(query) => this.fetchSupportHistory(query)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message, type, open, loading } = state.alert;
  return {
    message,
    type,
    open,
    loading,
  };
}

HistoricoSupport.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedLoginPage = withStyles(useStylesFilterComponent)(
  connect(mapStateToProps)(HistoricoSupport)
);
export { connectedLoginPage as HistoricoSupport };
