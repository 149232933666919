export const Timer = function (callback, delay) {
  let timerId,
    start,
    remaining = delay;

  this.stop = () => {
    window.clearTimeout(timerId);
  };

  this.resume = () => {
    start = Date.now();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(callback, remaining);
  };
};
