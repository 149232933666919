import React from 'react';

import MenuPainel from '../../../../components/MenuPainelNew';

import { Container } from '../../../components/context';

import { CustomDatePicker } from '../../../components/ui/webform';

import { formatDate, displayValue } from '../../../utils/format';

import {
  CustomTable,
  CustomDivider,
  CustomButton,
  CustomText,
} from '../../../components/ui';

import { mountRestTime } from './utils/format';

import { StyledButtonContainer } from './styles';

import useListEmployeeScale from './hooks/useListEmployeeScale';

const columns = [
  {
    title: 'data',
    value: ({ date }) => (
      <CustomText title={1} color='dark'>
        {displayValue(formatDate(date))}
      </CustomText>
    ),
  },
  {
    title: 'entrada',
    value: ({ entryTime, actvities }) => (
      <>
        <CustomText color='dark'>
          {displayValue(formatDate(entryTime, 'HH:mm'))}
        </CustomText>

        <br />

        <CustomText>
          {displayValue(
            formatDate(
              actvities?.length && actvities[0]?.timeLogin !== ''
              ? actvities[0]?.workShiftStart
              : null,
            'HH:mm'
            ),
            '-'
          )}
        </CustomText>
      </>
    ),
  },
  {
    title: 'pausa lanche',
    value: ({ snackBreakTime, snackBreakPause, pauses }) => (
      <>
        <CustomText color='dark'>
          {displayValue(snackBreakTime)} - {displayValue(snackBreakPause)}
        </CustomText>

        <br />

        <CustomText>
          {pauses?.snackBreak ? mountRestTime(pauses.snackBreak) : '-'}
        </CustomText>
      </>
    ),
  },
  {
    title: 'pausa descanso',
    value: ({ restBreakTime, restBreakPause, pauses }) => (
      <>
        <CustomText color='dark'>
          {displayValue(restBreakTime)} - {displayValue(restBreakPause)}
        </CustomText>

        <br />

        <CustomText>
          {pauses?.restBreak ? mountRestTime(pauses.restBreak) : '-'}
        </CustomText>
      </>
    ),
  },
  {
    title: 'saída',
    value: ({ departureTime, actvities }) => (
      <>
        <CustomText color='dark'>
          {displayValue(formatDate(departureTime, 'HH:mm'))}
        </CustomText>

        <br />

        <CustomText>
          {displayValue(
            formatDate(
              actvities?.length && actvities[0]?.timeLogout !== ''
                ? actvities[0]?.workShiftEnd
                : null,
              'HH:mm'
            ),
            '-'
          )}
        </CustomText>
      </>
    ),
  },
];

const ListEmployeeScale = () => {
  const {
    arrayDate,
    dateForm,
    dates,
    handleClear,
    handleSubmit,
    reset,
    scales,
    setSendForm,
    searchMoreData,
    setDate,
    setDateForm,
    useLoading,
  } = useListEmployeeScale();



  return (
    <>
      <MenuPainel stepper='Gestão de escalas' />

      <Container>
        <CustomDivider />

        <CustomDatePicker
          type='range'
          title='SELECIONE UMA DATA OU PERÍODO'
          name='datas'
          onFocusedDateChange={(dateFocused, dateClicked) => {
            if (dateForm.length === 2) {
              setDateForm([]);
            }
            setDate({ dateFocused, dateClicked });
          }}
          onClose={() => setSendForm(dateForm)}
          value={dates}
          dateInitial={arrayDate}
          reset={reset}
        />

        <CustomDivider space={5} />

        <StyledButtonContainer>
          <CustomButton
            onClick={handleSubmit}
            loading={useLoading}
            rounded={false}
            size='large'
            text='Filtrar'
          />

          <CustomButton
            onClick={handleClear}
            rounded={false}
            size='large'
            text='Limpar filtros'
            type='outlined'
          />
        </StyledButtonContainer>

        <CustomDivider space={5} />

        <CustomTable
          columns={columns}
          data={scales}
          onScroll={searchMoreData}
        />
      </Container>
    </>
  );
};
export default ListEmployeeScale;
