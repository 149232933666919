import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const cancelamentoUserService = {
  GetCancelamentosByUSer,
  cancellatiionManualUser,
  getUserCustomerId,
  getUserNewCancel,
};

function GetCancelamentosByUSer(id) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamentos/ativos/${id}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function cancellatiionManualUser(_data) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(_data),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/getUserCancelManual`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getUserCustomerId(geral) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(geral),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/getUserCustomerId`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getUserNewCancel(users, reason) {
  const formInfos = {
    reason: reason,
    idConecta: '0000000000',
    CustomerName: users.CustomerName,
    PurchaseId: users.PurchaseId,
    lastName: users.lastName,
    CustomerId: users.CustomerId,
    email: users.email,
    document: users.document,
    CellPhone: users.CellPhone,
  };
  let user = window.localStorage.user;
  user = JSON.parse(user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      formInfos,
    }),
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/insertCancellationManual`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
