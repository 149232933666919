import styled from 'styled-components';

export const SelectLabel = styled.label`
  width: 100%;
  display: flex;
`;

export const SelectStyled = styled.select`
  border: ${(props) => (props.border ? props.border : '')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '')};
  width: 100%;
  padding-left: 10px;
  height: ${(props) =>
    props.height === 'small'
      ? '24px'
      : props.height === 'large'
      ? '40px'
      : '32px'};
  appearance: ${(props) => (props.iconDisable ? 'none' : '')};
  opacity: ${(props) => (props.opacity ? props.opacity : 0.3)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '')};
  color: ${(props) => (props.textColor ? props.textColor : '')};
  :focus,
  :hover {
    ${(props) =>
      props.onHover
        ? `
    border: 1px solid #2699FB;
    background-color: white;
    `
        : ''}
  }
`;

export const SVG = styled.div`
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
`;
