import { useState, useCallback, useEffect } from 'react';

type BlankFunction = (...args: any[]) => any;

interface IUseDebounce<T extends BlankFunction> {
  debounceTime?: number;
  action: T;
}

export const useDebounce = <T extends BlankFunction>({
  debounceTime = 300,
  action,
}: IUseDebounce<T>) => {
  type Args = Parameters<T>;

  const [timeoutValue, setTimeoutValue] = useState<NodeJS.Timeout | null>(null);
  const [isCalling, setIsCalling] = useState<boolean>(false);

  // create timeout logic
  const debounceCallback = useCallback(
    (...args: Args) => {
      setIsCalling(true);
      const timeout = setTimeout(() => {
        action(...args);
        setIsCalling(false);
      }, debounceTime);

      setTimeoutValue(timeout);
    },
    [action, debounceTime]
  );

  // clear timeout when component unmount
  useEffect(() => {
    return () => {
      if (timeoutValue) clearTimeout(timeoutValue);
    };
  }, [timeoutValue]);

  // execute action by debounce
  const handleDebounce = useCallback(
    (...args: Args) => {
      debounceCallback(...args);
    },
    [debounceCallback]
  );

  return {
    handleDebounce,
    isCalling,
  };
};
