import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const cancelamentoService = {
  GetAllCancelamentos,
  getCountryAcionamentos,
  getRegionsByCountry,
  getCityByRegions,
  confirmationCancel,
  paginateCancelamentos,
  DownloadJSON,
};

function DownloadJSON(dataInicioCsv, dataFimCsv, page) {
  let user = JSON.parse(window.localStorage.user);
  dataInicioCsv = dataInicioCsv.replace(/\//g, '-');
  dataFimCsv = dataFimCsv.replace(/\//g, '-');

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/downloadjson/${dataInicioCsv}/${dataFimCsv}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function confirmationCancel(formInfos) {
  const { idConecta = '0000000000' } = formInfos;
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      ...formInfos,
      idConecta: idConecta,
    }),
  };
  console.log('meu formInfo', formInfos);
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/edit/cancellation`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getCountryAcionamentos() {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/country`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getRegionsByCountry(region) {
  let user = window.localStorage.user;
  let regiao = region ? (region.length <= 0 ? 'nd' : region) : 'nd';

  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/states/${regiao}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getCityByRegions(city) {
  let user = window.localStorage.user;
  let cidade = city ? (city.length <= 0 ? 'nda' : city) : 'nda';

  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/cities/${cidade}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function GetAllCancelamentos(page = 1, qtd = 5) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
  };

  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/list/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function paginateCancelamentos(pageSize = 5, page = 1, queryObject = {}) {
  let user = window.localStorage.user;
  user = JSON.parse(user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryObject,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/cancelamento/list/${pageSize}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
