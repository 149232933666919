// libs
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../components/Loading';

import { userService } from '../../services';

import { LoginActions } from '../../actions/login.actions';
import { alertActions } from '../../actions/alert.actions';

import { Text } from '../../components/Text';

import { PageProps } from '../types';
import { useExpireTimer } from '../utils/timer.utils';

import { ELoginSteps, IUserLoginState } from '../../reducers/login.reducer';

import { MfaFooter } from '../MfaFooter';
import { LoginMessages } from '../messages';

import * as S from './style';

import EmailIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/TextsmsOutlined';
import ArrowIcon from '@material-ui/icons/ChevronRightOutlined';

interface ILoginReducerProps {
  loginReducer: IUserLoginState;
}

interface MfaSelectWayProps {
  pageProps: PageProps;
}

export function MfaSelectWay({ pageProps }: MfaSelectWayProps) {
  const data = useSelector((state: ILoginReducerProps) => state.loginReducer);

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { timeLeft } = useExpireTimer();

  const handleSelectOption = useCallback(
    (method: IUserLoginState['method']) => async () => {
      setShowLoader(true);

      try {
        await userService.sentMfaCode(method);

        pageProps.dispatch(LoginActions.SelectMethod(method));

        pageProps.dispatch(LoginActions.RegisterCodeSentAt());

        setShowLoader(false);

        pageProps.dispatch(LoginActions.ChangeStep(ELoginSteps.MFA_ENTER_CODE));
      } catch (err: any) {
        setShowLoader(false);

        let message = LoginMessages.SELECT.CANNOT_SEND_CODE;

        if (err?.message?.includes('token')) {
          message = LoginMessages.SELECT.EXPIRED_TOKEN;
        }

        if (err?.message?.includes('já foi enviado')) {
          message = LoginMessages.SELECT.ALREADY_SENT_CODE;

          return pageProps.dispatch(alertActions.error(message));
        }

        pageProps.dispatch(alertActions.error(message));

        pageProps.dispatch(LoginActions.Reset());
      }
    },
    []
  );

  const showTimeMessage = useMemo<boolean>(() => {
    return typeof timeLeft === 'number' && timeLeft > 0;
  }, [timeLeft]);

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LogoContainer>
          <S.Logo />
        </S.LogoContainer>
        <S.LoginWrapper>
          {showLoader && <Loading />}
          <S.LoginHeader>
            <S.LoginTitle style={{ margin: '3px 0' }}>
              Verificação de login
            </S.LoginTitle>
            <S.Subtitle>
              Selecione um método para receber o seu código de verificação:
            </S.Subtitle>
          </S.LoginHeader>
          <S.Wrapper>
            {showTimeMessage && (
              <Text $size='12px' $weight='500' $align='center'>
                Aguarde {timeLeft || 0} segundos
              </Text>
            )}
            <S.MethodWrapper
              disabled={showTimeMessage}
              onClick={handleSelectOption('EMAIL')}
            >
              <EmailIcon fontSize='medium' className='Icon' />
              <S.MethodTexts>
                <Text $size='14px' $weight='500' $color='#061D38'>
                  Enviar o código para o e-mail
                </Text>
                <Text $size='14px' $weight='600'>
                  {data?.mfaData?.email}
                </Text>
              </S.MethodTexts>
              <ArrowIcon fontSize='medium' className='Arrow' />
            </S.MethodWrapper>
            <S.Divider />
            <S.MethodWrapper
              disabled={showTimeMessage}
              onClick={handleSelectOption('PHONE')}
            >
              <PhoneIcon fontSize='medium' className='Icon' />
              <S.MethodTexts>
                <Text $size='14px' $weight='500' $color='#061D38'>
                  Enviar o código para o telefone
                </Text>
                <Text $size='14px' $weight='600'>
                  {data?.mfaData?.phoneNumber}
                </Text>
              </S.MethodTexts>
              <ArrowIcon fontSize='medium' className='Arrow' />
            </S.MethodWrapper>

            <S.Divider />
            <MfaFooter pageProps={pageProps} />
          </S.Wrapper>
        </S.LoginWrapper>
      </S.LoginContainer>
      <S.CompanyImage />
    </S.Container>
  );
}
