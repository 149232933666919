import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { Grid } from '@material-ui/core';
import { HttpBodyRecoverPassword } from '../../../domain/entities';
import {
  Alert,
  ButtonSubmit,
  Card,
  Form,
  Input,
  Loading,
  TemplateLogin,
} from '../../../application/shared/ui';
import { RecoverPasswordData } from '../../../application/data';
import * as S from './styles';

type Props = {
  recoverPasswordData: RecoverPasswordData;
};

export const RecoverPasswordPage = ({ recoverPasswordData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const handleCloseSuccess = (): void => {
    setSuccess(false);
  };

  const handleCloseError = (): void => {
    setError(false);
  };

  const handleRecoverPassword = async (body: HttpBodyRecoverPassword) => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const response = await recoverPasswordData.handle(body);
      console.log(response);
      setSuccessMessage('E-mail enviado!');
      setSuccess(true);
      setLoading(false);
    } catch (error: any) {
      setErrorMessage(error.response.data.error_description);
      setError(true);
      setLoading(false);
    }
  };

  const initialValues: HttpBodyRecoverPassword = { email: '' };
  const validationSchema: Yup.SchemaOf<HttpBodyRecoverPassword> =
    Yup.object().shape({
      email: Yup.string()
        .email('Informe um email válido')
        .required('Campo obrigatório'),
    });

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: HttpBodyRecoverPassword) =>
      handleRecoverPassword(values),
  });

  return (
    <TemplateLogin title='Esqueci a senha'>
      <Card>
        <Grid item xs={12}>
          <Form onSubmit={form.handleSubmit}>
            <Grid item>
              <Input
                type='email'
                name='email'
                placeholder='Email'
                onChange={form.handleChange}
                errors={form.errors}
                touched={form.touched}
                icon={<MdOutlineAlternateEmail />}
              />
            </Grid>

            <Grid container>
              <ButtonSubmit>ENVIAR</ButtonSubmit>
            </Grid>
            <Grid container>
              <S.ButtonLink to='/login'>VOLTAR</S.ButtonLink>
            </Grid>
          </Form>
        </Grid>
      </Card>

      {loading && <Loading />}
      <Alert
        severity='success'
        handleClose={handleCloseSuccess}
        open={success}
        message={successMessage}
      />
      <Alert
        severity='error'
        handleClose={handleCloseError}
        open={error}
        message={errorMessage}
      />
    </TemplateLogin>
  );
};
