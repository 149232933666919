// @ts-nocheck
import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { history } from '../../../../../helpers/history';
import logo from '../../images/img/cr_white.png';
import * as S from './styles';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const TemplateLogin = ({ title, children }: Props) => {
  return (
    <S.Content>
      <Container>
        <Grid container>
          <Grid item xs={2}>
            <S.Logo onClick={() => history.push('/adm')}>
              <img src={logo} alt='hiae-mda' />
            </S.Logo>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth='md'>
        <Grid>
          <S.PaperForm elevation={24}>
            <S.Title>{title}</S.Title>
            {children}
          </S.PaperForm>
        </Grid>
      </Container>
    </S.Content>
  );
};
