import React from 'react';
import { connect } from 'react-redux';

import { LoginPage } from './component';

import { PageProps } from './types';

class ComponentHelper extends React.Component<PageProps> {
  constructor(props: PageProps) {
    super(props);
  }

  render() {
    return <LoginPage {...this.props} />;
  }
}

function mapStateToProps(
  state: Record<string, any>
): Omit<PageProps, 'dispatch'> {
  const { loggingIn, submitted } = state.authentication;
  const { message, type, open, loading } = state.alert;
  return {
    loggingIn,
    submitted,
    message,
    type,
    open,
    loading,
  };
}

export const ConnectedLoginPage = connect(mapStateToProps)(ComponentHelper);
