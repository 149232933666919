import React from 'react';
import { HttpQueueRecordFindAll } from '../../../domain/http';
import { FindAllQueueRecordsFilter } from '../../../domain/state';
import { HttpRequest } from '../../../infra/adapters';

export class FindAllQueueRecordsData {
  constructor(
    private readonly httpQueueRecordFindAll: HttpQueueRecordFindAll
  ) {}

  async handle(body: FindAllQueueRecordsFilter, page: string, offset: string) {
    const user = JSON.parse(localStorage.getItem('user') || '');

    const httpRequest: HttpRequest = {
      url: `${process.env.REACT_APP_API_CONECTA}/servico/historico/filtro/${page}/${offset}`,
      method: 'post',
      data: body,
      headers: {
        'x-access-token': user.accessToken,
        _id: user.user._id,
      },
    };

    const response = await this.httpQueueRecordFindAll.handle(httpRequest);
    return {
      status: response.status,
      data: {
        docs: response.data?.docs,
        pages: Number(response.data?.pages),
        total: Number(response.data?.total),
      },
    };
  }
}
