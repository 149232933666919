import httpClient from '../../../utils/httpClient';

const resource = 'notifications';

const updateStatus = async (id, status) => {
  const response = await httpClient.PUT(`${resource}/${id}`, {
    body: { status },
  });

  try {
    const data = await response.json();

    return response.ok
      ? {
          status: true,
          data,
        }
      : {
          status: false,
          response,
        };
  } catch (error) {
    return {
      status: false,
      response,
      error,
    };
  }
};

export default {
  updateStatus,
};
