import React from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';

import {
  StyledContainer,
  StyledForm,
  StyledContainerContent,
  StyledPageTitle,
  StyledRow,
  StyledButtonsWrapper,
  StyledPatientDataDesktop,
  StyledPatientDataMobile,
  CustomDiv,
} from './styles';

import { formSchema } from './utils';

import { Container } from '../../../../../../Spread/components/context';

import { sleep } from '../../../../../../Spread/utils/common';

import {
  displayValue,
  formatDate,
  formatDocumentMask,
} from '../../../../../../Spread/utils/format';

import {
  CustomText,
  CustomIcon,
  CustomDivider,
  CustomButton,
  CustomModalConfirmation,
  CustomSnackbar,
  CustomClipboard,
} from '../../../../../../Spread/components/ui';

import Modal from '../../../../../DialogModal';
import Alert from '../../../../../../components/Alert';
import Loader from '../../../../Loader';

import VideoConferenciaButton from '../../../../VideoChamadaButton';

import { CustomInput } from '../../../../../../Spread/components/ui/webform';

import { useNavigate } from 'react-router-dom';

import { parsePhoneNumber, phoneMask } from '../utils';

import { BoxInfo, PatientData } from '../components';

import InputMask from '../../InputMask';

import {
  productQueue,
  maskedDocumentTypes,
  snackBarMessageTypes,
} from '../../constants';

import useEisteinClinicAndCareForm from './hooks/useEisteinClinicAndCareForm';

const EisteinClinicAndCareForm = () => {
  const setMask = (mask) => {
    if (mask === 'phone') {
      return '(99) 999999999';
    } else {
      return '************************************************************';
    }
  };
  const { snack, trigger } = CustomSnackbar();
  const navigate = useNavigate();

  const clickedInfoAttendance = useSelector((state) => state.editAcionamento);
  const formValues = (clickedInfoAttendance) => {
    const { editAcionamento } = clickedInfoAttendance;
    let editionStore = JSON.parse(localStorage.getItem('userEdition'));
    let editionForm = {};

    if (editionStore) {
      editionForm = {
        ...editionStore,
        ...editAcionamento,
        edit: true,
        isVonage: true,
      };
      return editionForm;
    }

    return editAcionamento;
  };
  const editAcionamento = formValues(clickedInfoAttendance);

  const {
    handleModal,
    useModal,
    searchAddressByCep,
    setFormValues,
    sendMailTrigger,
    updateTrigger,
    formValuesRef,
    useLoading,
    usePatient,
    updatePatient,
    inAttendance,
    setInAttendance,
    handleBack,
    setLoading,
    setSessionIdState,
    setDocumentState,
    pushCall,
    setPushCallLoading,
    setPushCallTitle,
    setPushCallMessage,
    setPushCallModal,
    pushCallModal,
    pushCallLoading,
    pushCallTitle,
    pushCallMessage,
    handleCreatePushCall,
    pushCallError,
    setPushCallError,
    encounter,
  } = useEisteinClinicAndCareForm(editAcionamento);

  return (
    <>
      <Container>
        <CustomDivider space={10} />
        <StyledContainer>
          <CustomIcon
            size={32}
            onClick={() => handleBack(editAcionamento)}
            icon='ArrowBack'
            color='default'
          />

          <StyledContainerContent>
            <div>
              <StyledPageTitle>
                <CustomText color='dark' size='xlarge'>
                  Detalhes do acionamento
                </CustomText>

                <VideoConferenciaButton
                  data={editAcionamento}
                  setInAttendance={setInAttendance}
                  setLoading={setLoading}
                  setSessionIdState={setSessionIdState}
                  setDocumentState={setDocumentState}
                  pushCall={pushCall}
                  setPushCallLoading={setPushCallLoading}
                  setPushCallTitle={setPushCallTitle}
                  setPushCallMessage={setPushCallMessage}
                  setPushCallModal={setPushCallModal}
                />
              </StyledPageTitle>

              <CustomDivider space={10} />

              <BoxInfo
                value={displayValue(productQueue[editAcionamento?.queue], '')}
                text='Produto:'
              />

              <CustomDivider space={10} />

              <StyledPatientDataMobile>
                <PatientData
                  encounter={encounter}
                  data={(usePatient?.observations || []).map((e) => e.text)}
                  onSave={async (observations = [], messageType) => {
                    const dataToUpdate = observations.map((text) => ({ text }));

                    const updateResponseStatus = await updatePatient({
                      observations: dataToUpdate,
                    });

                    const triggerType = updateResponseStatus
                      ? 'success'
                      : 'danger';

                    trigger({
                      message: snackBarMessageTypes[messageType][triggerType],
                      type: triggerType,
                      duration: 3000,
                    });
                  }}
                />
              </StyledPatientDataMobile>

              <CustomDivider space={10} />

              <Formik
                initialValues={{
                  cep: editAcionamento?.streetCep
                    ? displayValue(editAcionamento?.streetCep.replace('-', ''))
                    : '',
                  phone: editAcionamento?.phone || '',
                  state: displayValue(editAcionamento?.state, ''),
                  city: displayValue(editAcionamento?.city, ''),
                  country: displayValue(editAcionamento?.country, ''),
                  socialName: displayValue(editAcionamento?.socialName, ''),
                  produto: displayValue(editAcionamento?.produto, ''),
                  especialidade: displayValue(
                    editAcionamento?.especialidade,
                    ''
                  ),
                  unidade: displayValue(editAcionamento?.unidade, ''),
                }}
                validationSchema={formSchema}
                onSubmit={() => {
                  handleModal(true);
                }}
                innerRef={formValuesRef}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  dirty,
                }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <StyledRow>
                      <CustomInput
                        value={displayValue(
                          editAcionamento?.unitOfAttendance?.professionalName
                        )}
                        style='outlined'
                        title='Profissional'
                        disabled
                        full
                      />

                      <CustomInput
                        value={displayValue(
                          editAcionamento?.unitOfAttendance?.speciality
                        )}
                        style='outlined'
                        title='Especialidade'
                        disabled
                        full
                      />
                    </StyledRow>
                    <StyledRow>
                      <CustomInput
                        value={displayValue(
                          editAcionamento?.unitOfAttendance?.unit
                        )}
                        style='outlined'
                        title='Unidade'
                        disabled
                        full
                      />

                      <CustomInput
                        value={
                          editAcionamento?.isoScheduleTime &&
                          moment(editAcionamento?.isoScheduleTime).format(
                            'DD/MM/YYYY - HH:mm'
                          )
                        }
                        style='outlined'
                        title='Data de agendamento'
                        disabled
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        value={displayValue(
                          productQueue[editAcionamento?.queue],
                          editAcionamento?.queue
                        )}
                        style='outlined'
                        title='Serviço'
                        disabled
                        full
                      />

                      <CustomInput
                        value={displayValue(editAcionamento?.medical_record)}
                        style='outlined'
                        title='Prontuário'
                        disabled
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        value={displayValue(editAcionamento?.convenioDescricao)}
                        style='outlined'
                        title='Convênio'
                        disabled
                        full
                      />

                      <CustomInput
                        value={
                          maskedDocumentTypes.includes(
                            editAcionamento?.documentType
                          )
                            ? formatDocumentMask(
                                editAcionamento?.documentType,
                                editAcionamento?.document
                              )
                            : displayValue(editAcionamento?.document)
                        }
                        style='outlined'
                        title={`Documento (${editAcionamento?.documentType})`}
                        disabled
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        value={displayValue(editAcionamento?.name)}
                        style='outlined'
                        title='Nome'
                        disabled
                        full
                      />

                      <CustomInput
                        value={displayValue(editAcionamento?.lastName)}
                        style='outlined'
                        title='Sobrenome'
                        disabled
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        onChange={handleChange}
                        value={values.socialName}
                        hasError={errors.socialName && touched.socialName}
                        error={
                          touched?.socialName &&
                          errors?.socialName?.replace('"', '')
                        }
                        name='socialName'
                        style='outlined'
                        title='Nome Social'
                        full
                      />

                      <CustomInput
                        value={displayValue(editAcionamento?.gender)}
                        style='outlined'
                        title='Sexo'
                        disabled
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        value={displayValue(
                          formatDate(editAcionamento?.birthDate)
                        )}
                        style='outlined'
                        title='Data de aniversário'
                        disabled
                        full
                      />

                      <div>
                        <CustomDiv>
                          <CustomText>Telefone</CustomText>
                        </CustomDiv>
                        <InputMask
                          labelTitle={'Telefone'}
                          placeholder={'(DDD) + Telefone'}
                          type={'text'}
                          name={'phone'}
                          id={'phone'}
                          value={values.phone}
                          onChange={handleChange}
                          mask={setMask('phone')}
                          maskChar=' '
                          fullWidth
                        />
                      </div>
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        value={displayValue(editAcionamento?.email)}
                        style='outlined'
                        title='E-mail'
                        disabled
                        full
                      />

                      <CustomInput
                        disabled
                        onRequestCallback={async (cep) => {
                          const cepData = await searchAddressByCep(cep);

                          setFormValues(cepData, setFieldValue);
                        }}
                        onChange={handleChange}
                        value={values.cep}
                        name='cep'
                        style='outlined'
                        title='CEP'
                        type='CEP'
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        disabled
                        onChange={handleChange}
                        value={values.city}
                        name='city'
                        style='outlined'
                        title='Cidade'
                        full
                      />

                      <CustomInput
                        disabled
                        onChange={handleChange}
                        value={values.state}
                        name='state'
                        style='outlined'
                        title='Estado'
                        full
                      />
                    </StyledRow>

                    <StyledRow>
                      <CustomInput
                        disabled
                        onChange={handleChange}
                        value={values.country}
                        name='country'
                        style='outlined'
                        title='País'
                        full
                      />

                      <CustomDivider />
                    </StyledRow>

                    <StyledRow align='start' bottom={1}>
                      <CustomInput
                        disabled
                        title='Link da videoconferência'
                        style='outlined'
                        value={editAcionamento?.urlAttendanceVonage || ''}
                        full
                      />

                      <StyledRow align='start'>
                        <StyledButtonsWrapper>
                          <CustomClipboard
                            text='Copiar link'
                            value={editAcionamento?.urlAttendanceVonage || ''}
                          />
                          <CustomButton
                            onClick={async () => {
                              (await sendMailTrigger())
                                ? trigger({
                                    message: 'Email enviado com sucesso',
                                    type: 'success',
                                    duration: 3000,
                                  })
                                : trigger({
                                    message: 'Falha ao enviar email',
                                    type: 'danger',
                                    duration: 3000,
                                  });
                            }}
                            type='outlined'
                            text={
                              useLoading
                                ? 'Enviando e-mail'
                                : 'Reenvio por e-mail'
                            }
                            icon='EmailOutlined'
                            iconDir='l'
                          />
                        </StyledButtonsWrapper>
                      </StyledRow>
                    </StyledRow>

                    <CustomDivider space={30} height={2} border />

                    <StyledRow align='end'>
                      <CustomButton
                        onClick={() => {
                          handleBack(editAcionamento);
                        }}
                        size='medium'
                        color='danger'
                        type='outlined'
                        text='Cancelar'
                      />

                      <CustomButton
                        disabled={inAttendance}
                        size='medium'
                        text='Confirmar'
                        submit
                      />
                    </StyledRow>
                  </StyledForm>
                )}
              </Formik>
            </div>

            <StyledPatientDataDesktop>
              <PatientData
                encounter={encounter}
                data={(usePatient?.observations || []).map((e) => e.text)}
                onSave={async (observations = [], messageType) => {
                  const dataToUpdate = observations.map((text) => ({ text }));

                  const updateResponseStatus = await updatePatient({
                    observations: dataToUpdate,
                  });

                  const triggerType = updateResponseStatus
                    ? 'success'
                    : 'danger';

                  trigger({
                    message: snackBarMessageTypes[messageType][triggerType],
                    type: triggerType,
                    duration: 3000,
                  });
                }}
              />
            </StyledPatientDataDesktop>
          </StyledContainerContent>
        </StyledContainer>

        <CustomDivider />

        {snack}
        <CustomModalConfirmation
          onConfirm={async () => {
            handleModal(false);
            const hasTriggeredSuccess = await updateTrigger(
              editAcionamento._id
            );

            if (hasTriggeredSuccess) {
              trigger({
                message: 'Atendimento confirmado',
                type: 'success',
                duration: 1500,
              });

              navigate('/adm');
            } else {
              trigger({
                message: 'Falha ao confirmar o atendimento',
                type: 'danger',
                duration: 3000,
              });
            }
          }}
          onClose={() => handleModal(false)}
          show={useModal}
          title='Deseja confirmar o atendimento?'
        />
      </Container>

      <Modal
        open={pushCallModal}
        withActions={!pushCallLoading}
        title={pushCallTitle}
        text={pushCallMessage}
        withClose
        loading={useLoading}
        loader={<Loader background={'#007ACC'} />}
        handleCancel={() => setPushCallModal(false)}
        handleConfirm={handleCreatePushCall}
        labelOk={'Sim'}
        labelCancel={'Não'}
        buttomSize='large'
      />

      <Alert
        open={pushCallError}
        type='error'
        message='Não foi possível carregar as Tentativas de Contato'
        onClose={() => setPushCallError(false)}
      />
    </>
  );
};

export default EisteinClinicAndCareForm;
