// @ts-nocheck
import React from 'react';
import { FindAllQueueRecordsStore } from '../../../application/store';
import { Alert } from '../../../application/shared/ui';

type Props = {
  findAllQueueRecordsStore: FindAllQueueRecordsStore;
};

export const AlertComponent = ({ findAllQueueRecordsStore }: Props) => {
  const { findAllQueueRecordsSelector, handleError, handleErrorMessage } =
    findAllQueueRecordsStore;
  const selector = findAllQueueRecordsSelector;

  const handleCloseError = () => {
    handleError(false);
    handleErrorMessage('');
  };

  return (
    <Alert
      handleClose={handleCloseError}
      open={selector.error}
      message={selector.errorMessage}
      severity='error'
    />
  );
};
