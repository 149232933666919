import styled from 'styled-components';

export const StyledTitle = styled.h2`
  color: #2e384d;
  text-align: center;
  font-size: 28px;
  font-weight: 900;
  line-height: 30px;
`;

export const StyledSubtitle = styled.p`
  line-height: 22px;
  font-size: 15px;
  color: rgba(121, 124, 129);
  text-align: center;
`;

export const StyledWrapper = styled.div`
  margin: 0px 40px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
