import {
  acionamentoConstants,
  editAcionamentoConstants,
  userConstants,
  countryConstants,
  regionConstants,
  citiesConstants,
  cepConstants,
  conveniosConstantsPortal,
  productsConstants,
} from '../constants';

import { conveniosConstants } from '../constants';
import { queuesConstants } from '../constants';
import { acionamentoService, acionamentoVonageService } from '../services';
import { conveniosService } from '../services';
import { alertActions, modalAlertActions } from './';
import { history } from '../helpers';

export const acionamentoActions = {
  getAllAcionamentos,
  getAllAcionamentosPaginados,
  deleteAcionamentos,
  editAcionamentos,
  editAcionamentosWithoutRedirect,
  getAllConvenios,
  confirmedAcionamentos,
  confirmedAcionamentosNovo,
  getAllCountryAcionamentos,
  getAllQueuesRequisition,
  getAllProductsRequisition,
  getRegionsByCountry,
  getCityByRegions,
  getAddressFromCep,
  paginateAcionamentos,
  generateImageUrl,
  getChannelAccess,
  confirmUserCognito,
  changePassword,
  resetPassword,
  verifyEmail,
  getAllConveniosPortal,
  clearAcionamentos,
  deleteAcionamentosNew,
  confirmUserCognitoV2,
  recoverAccountV2,
  verifyEmailV2,
  changeEmailUserConectaV2,
};

function generateImageUrl(bucketObject = {}) {
  return new Promise((resolve, reject) => {
    acionamentoService
      .getUrlImages(bucketObject)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve({ signedURL: '' });
      });
  });
}

function resetPassword(username = '', state) {
  return (dispatch) => {
    acionamentoService
      .resetPassword(username)
      .then((body) => {
        let { password } = body;
        dispatch(
          alertActions.success(`Resetar de senha concluído, Senha: ${password}`)
        );
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        console.log(err);
        dispatch(alertActions.error('Erro ao resetar senha.'));
      });
  };
}

function recoverAccountV2(id = '', state) {
  return (dispatch) => {
    acionamentoService
      .recoverAccountV2(id)
      .then((body) => {
        dispatch(alertActions.success(body.message));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('E-mail não verificado'));
      });
  };
}

function confirmUserCognito(username = '', state) {
  return (dispatch) => {
    acionamentoService
      .confirmUserRecord(username)
      .then((body) => {
        dispatch(alertActions.success('Usuário Confirmado'));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('Erro ao confirmar'));
      });
  };
}

function confirmUserCognitoV2(username, state) {
  return (dispatch) => {
    acionamentoService
      .confirmUserV2(username)
      .then((body) => {
        dispatch(alertActions.success(body.message));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('Erro ao confirmar'));
      });
  };
}

function changePassword(username = '', state) {
  return (dispatch) => {
    acionamentoService
      .changePassword(username)
      .then((body) => {
        dispatch(alertActions.success('E-mail enviado'));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('E-mail não verificado'));
      });
  };
}

function verifyEmail(username = '', state) {
  return (dispatch) => {
    acionamentoService
      .verifyEmail(username)
      .then((body) => {
        dispatch(alertActions.success('E-mail verificado'));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('Não foi possível verificar o e-mail'));
      });
  };
}

function verifyEmailV2(id = '', state) {
  return (dispatch) => {
    acionamentoService
      .verifyEmailV2(id)
      .then((body) => {
        dispatch(alertActions.success(body.message));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('Não foi possível verificar o e-mail'));
      });
  };
}

function changeEmailUserConectaV2(id = '', email, state) {
  return (dispatch) => {
    acionamentoService
      .changeEmailUserConectaV2(id, email)
      .then((body) => {
        dispatch(alertActions.success(body.message));
        state.tableRef.current.onQueryChange();
      })
      .catch((err) => {
        dispatch(alertActions.error('Não foi possível trocar o email'));
      });
  };
}

function getAllCountryAcionamentos() {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getCountryAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: countryConstants.COUNTRY_REQUEST };
  }
  function success(result) {
    return { type: countryConstants.COUNTRY_SUCCESS, result };
  }
  function failure(error) {
    return { type: countryConstants.COUNTRY_FAILURE, error };
  }
}

function getAllQueuesRequisition() {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getQueueRequisition()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: queuesConstants.QUEUE_REQUEST };
  }
  function success(result) {
    return { type: queuesConstants.QUEUE_SUCCESS, result };
  }
  function failure(error) {
    return { type: queuesConstants.QUEUE_FAILURE, error };
  }
}

function getAllProductsRequisition() {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getProductsRequisition()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: productsConstants.PRODUCTS_REQUEST };
  }
  function success(result) {
    return { type: productsConstants.PRODUCTS_SUCCESS, result };
  }
  function failure(error) {
    return { type: productsConstants.PRODUCTS_FAILURE, error };
  }
}

function getAddressFromCep(cep) {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .GetAddressFromCep(cep)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cepConstants.CEP_REQUEST };
  }
  function success(result) {
    return { type: cepConstants.CEP_SUCCESS, result };
  }
  function failure(error) {
    return { type: cepConstants.CEP_FAILURE, error };
  }
}

function getRegionsByCountry(region = 'nd') {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getRegionsByCountry(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: regionConstants.REGION_REQUEST };
  }
  function success(result) {
    return { type: regionConstants.REGION_SUCCESS, result };
  }
  function failure(error) {
    return { type: regionConstants.REGION_FAILURE, error };
  }
}

function getCityByRegions(region = 'nda') {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .getCityByRegions(region)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };
  function request() {
    return { type: citiesConstants.CITIE_REQUEST };
  }
  function success(result) {
    return { type: citiesConstants.CITIE_SUCCESS, result };
  }
  function failure(error) {
    return { type: citiesConstants.CITIE_FAILURE, error };
  }
}

function editAcionamentosWithoutRedirect(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
  };
  function request() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE };
  }
}

function paginateAcionamentos(pageSize = 5, page = 1, queryString = '') {
  return new Promise((resolve) => {
    acionamentoService
      .paginateAllAcionamentos(pageSize, page, queryString)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        return resolve({
          docs: [],
          limit: 5,
          page: 1,
          pages: 1,
          total: 0,
        });
      });
  });
}
function getAllAcionamentosPaginados(
  itensPerPage,
  page,
  queryString = '',
  projection,
  pediatria
) {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .paginateAllAcionamentos(
        itensPerPage,
        page,
        queryString,
        projection,
        pediatria
      )
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success(acionamentos) {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS, acionamentos };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function getAllAcionamentos() {
  return (dispatch) => {
    dispatch(request());

    acionamentoService
      .GetAllAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success(acionamentos) {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS, acionamentos };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function confirmedAcionamentos(acionamento, setLoading) {
  var tempData = acionamento.birthDate.split('/');
  acionamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .confirmedAcionamentos(acionamento)
      .then(
        (body) => {
          dispatch(success(body));
          dispatch(alertActions.success(body.message));
          history.push('/adm');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function confirmedAcionamentosNovo(acionamento, setLoading) {
  var tempData = acionamento.birthDate.split('/');
  acionamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .confirmedAcionamentosNovo(acionamento)
      .then(
        (body) => {
          dispatch(success(body));
          setLoading(false);
          dispatch(alertActions.success(body.message));
          history.push('/adm');
        },
        (error) => {
          dispatch(failure(error));
          setLoading(false);
          dispatch(modalAlertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function deleteAcionamentosNew(
  id,
  reason,
  pacientData,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .deleteAcionamentosNew(
        id,
        reason,
        pacientData,
        urgencyAttendance,
        urgencyAttendenceReason
      )
      .then(
        (body) => {
          dispatch(success(body));
          //dispatch(modalAlertActions.success('Acionamento excluido com sucesso!'));

          history.push('/adm');
        },
        (error) => {
          dispatch(failure(error));
          // dispatch(alertActions.error(error.message));
          dispatch(modalAlertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success() {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function deleteAcionamentos(
  id,
  reason,
  urgencyAttendance = false,
  urgencyAttendenceReason = ''
) {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .deleteAcionamentos(
        id,
        reason,
        urgencyAttendance,
        urgencyAttendenceReason
      )
      .then(
        (body) => {
          dispatch(success(body));
          //dispatch(modalAlertActions.success('Acionamento excluido com sucesso!'));

          history.push('/adm');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
          //dispatch(modalAlertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success() {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function editAcionamentos(acionamento, setLoadItem, setLoadingButton) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      setLoadItem(false);
      setLoadingButton(false);
      dispatch(success(acionamento));
    } else {
      setLoadItem(false);
      setLoadingButton(false);
      dispatch(failure());
    }
    history.push('/adm/acionamento/new');
  };
  function request() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE };
  }
}

function clearAcionamentos() {
  return (dispatch) => {
    dispatch(clear());
  };

  function clear() {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_CLEAR,
      editAcionamento: {},
    };
  }
}

function getAllConvenios(isEdition) {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios(isEdition)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }
  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }
  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}
//.
function getAllConveniosPortal(isEdition) {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConveniosPortal(isEdition)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstantsPortal.CONVENIOS_REQUEST_PORTAL };
  }
  function success(convenios) {
    return {
      type: conveniosConstantsPortal.CONVENIOS_SUCCESS_PORTAL,
      convenios,
    };
  }
  function failure(error) {
    return { type: conveniosConstantsPortal.CONVENIOS_FAILURE_PORTAL, error };
  }
}

function getChannelAccess() {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }
  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }
  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}
