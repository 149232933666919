import React from 'react';
import { TableColStyled } from './styles';
const TableCol = ({ vAlign, align, children, borderTypeColor, maxWidth }) => {
  return (
    <TableColStyled
      borderTypeColor={borderTypeColor}
      vAlign={vAlign}
      align={align}
      maxWidth={maxWidth}
    >
      {children}
    </TableColStyled>
  );
};

export default TableCol;
