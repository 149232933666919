import React from 'react';

import {
  ContentSearch,
  IconInput,
  Icon,
  ClearSearchField,
  InputField,
} from './styled';
import SearchIcon from './icons/search.svg';
import ClearIcon from './icons/clear.svg';
import DropwdownMenu from '../DropdownMenu';

export default function SearchInput({
  menuItensDropdown,
  optionValue,
  handleChangeSearch,
  handleClearSearch,
  value,
  inputPlaceHolder,
  defaultValue,
  onBlur,
  onClick,
  mask,
  maskChar,
}) {
  return (
    <form onSubmit={(e) => onClick(e)}>
      <ContentSearch>
        <DropwdownMenu
          menuItensDropdown={menuItensDropdown}
          defaultValue={defaultValue}
          optionValue={optionValue}
        />
        <IconInput>
          <InputField
            mask={mask}
            maskChar={maskChar}
            placeholder={inputPlaceHolder}
            onChange={handleChangeSearch}
            onBlur={onBlur}
            value={value}
          />
        </IconInput>
        <Icon disabled={!value?.trim()?.length > 0} type='submit'>
          <img src={SearchIcon} />
        </Icon>
        {value !== '' && (
          <ClearSearchField onClick={handleClearSearch} type='submit'>
            <img src={ClearIcon} />
          </ClearSearchField>
        )}
      </ContentSearch>
    </form>
  );
}
