import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const color = '#f44336';
const ContainerErrors = styled.small`
  color: ${color};
  display: ${(props) => (props.isError ? 'block' : 'none')};
  margin: 5px 0px;
`;
const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`;
const Asterisk = styled.small`
  color: ${color};
  width: 1%;
  margin-right: 5px;
`;

export const Errors = ({ children, errorText, isError, isRequired }) => {
  return (
    <>
      <Container>
        {isRequired && <Asterisk>*</Asterisk>}
        {!isRequired && <Asterisk></Asterisk>}
        {children}
      </Container>
      {isRequired && (
        <ContainerErrors isError={isError}>{`*${errorText}`}</ContainerErrors>
      )}
    </>
  );
};

Errors.propTypes = {
  children: PropTypes.node,
  errorText: PropTypes.string,
  isError: PropTypes.bool,
};

Errors.defaultProps = {
  isError: false,
  errorText: '',
  children: <div></div>,
};
