import { useState, useEffect } from 'react';

import actions from '../../../../../../redux/actions';

const useModalUnlock = (dataUser, setReloadGrid) => {
  const [useLoading, setLoading] = useState(false);
  const [showUnlock, setShowUnlock] = useState(true);
  const [motive, setMotive] = useState('');

  const handleUnlock = async (value) => {
    setLoading(true);
    const res = await actions.schedule.serviceUnlock(value);
    if (res.data) {
      setShowUnlock(false);
      setReloadGrid(true);
    }
    setLoading(false);
  };

  const handleCancel = (showUnlock) => {
    setShowUnlock(!showUnlock);
    setReloadGrid(true);
  };

  useEffect(() => {
    if (dataUser) {
      (async () => {
        const { data: pause } = await verifyPauseStatus();
        if (pause.data.motive) {
          setMotive(' pelo motivo: ' + pause?.data?.motive);
        }
      })();
    }
    setShowUnlock(true);
  }, [dataUser]);

  const verifyPauseStatus = async () => {
    return await actions.schedule.serviceStatus(dataUser.userId);
  };

  return {
    useLoading,
    handleCancel,
    handleUnlock,
    showUnlock,
    motive,
  };
};

export default useModalUnlock;
