// Modules
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { ExpandMore } from '@material-ui/icons';
// Styles
import {
  GridLayout,
  Header,
  Main,
  Container,
  ContainerQueue,
  IconButton,
  ContainerButton,
  ContainerSearch,
  ContainerPage,
  ContainerUnitsAndProfessionalsFilters,
  ContainerButtonOfUnits,
  ContainerButtonOfProfessionals,
  ContainerFilter,
  ContainerFilterUnits,
  ContainerFilterProfessionals,
  ContainerSpanInfos,
} from './styles';

//Components
import CustomMenu from '../CustomMenu';
import Alert from '../../../components/Alert';
import Queue from '../Queue';
import CustomTitle from '../CustomTitle';
import CustomButton from '../CustomButton';
import Plus from '../../../icons/plus.svg';
import SearchInput from '../SearchInput';
import { history } from '../../../helpers';
import Loader from '../Loader';
import { validations } from '../../../helpers/validations';
import FilterGeneric from '../FilterGeneric/index';
import { ContainerLoader } from '../Queue/styles';
import Modal from '../../DialogModal';
import ButtonFilter from '../ButtonFilter';
import { ReactComponent as IconFilter } from '../../../icons/icon-filtro-new.svg';
import QueueTable from '../Queue/QueueTable/index';
import { ContainerLoader } from '../Queue/QueueTable/styles.js';
// Config
import { messageForEmpty } from './config';

//Actions
import {
  acionamentoActions,
  acionamentoActionsVonage,
  alertActions,
  queueItems,
  userActions,
  subMenuActions,
  acionamentoSuporteActions,
  actionForwarding,
  getTime,
} from '../../../actions';

import { debounce, createObjectForAttendanceUpdate } from '../utils/utils';
import { ALL_QUEUES_IDS } from '../utils/config';
import {
  acionamentoService,
  acionamentoVonageService,
} from '../../../services';

import { CustomLoader } from '../../../Spread/components/ui';
import FilterStatus from '../FilterStatus';
import { checkCreateEncounterPermission } from '../../../utils/profiles';
import dayjs from 'dayjs';
import { CancelScheduleModal } from '../../Modals/CancelScheduleModal';
export const productsTriggerQueue = ['clinica', 'checkup', 'predicta', 'reab'];

const QueuePage = ({ fatherState, socket }) => {
  let editAcionamento = useSelector(
    (state) => state.editAcionamento.editAcionamento
  );
  let { message, open, type } = useSelector((state) => state.alert);
  const socketInfos = useSelector((state) => state.socketInfos.socketInfos);
  const subMenu = useSelector((state) => state.subMenuItems.subMenuItems);
  const subMenuItemsBySocket = useSelector(
    (state) => state.subMenuItemsBySocket.subMenuItemsBySocket
  );

  let timeInterval = null;
  let cronometer = null;

  let receivedEnabledQueues = useSelector((state) => state.enabledQueuesGet);

  const dispatch = useDispatch();
  let [objectQueryString, setObjectQueryString] = useState('');
  const sizePage = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [openStatus, setOpenStatus] = useState(false);
  const [buttonFilterActive, setButtonFilterActive] = useState(false);
  const [objectRequest, setObjectRequest] = useState({
    queryString: '',
    projection: 'name',
    refresh: false,
    timeout: 2000,
  });

  const [mask, setMask] = useState('');
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [openModalSupport, setModalOpenSupport] = useState(false);
  const [textModal, setTextModal] = useState('em atendimento');
  const [dataAcionamento, setData] = useState({});
  const [dataSupport, setDataSupport] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [reqPage, setReqPage] = useState(0);
  const [loadRow, setLoadRow] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [useLoadItem, setLoadItem] = useState(false);
  const [useCronometer, setCronometer] = useState(null);
  const [openScheduleCancel, setOpenScheduleCancel] = useState(false);
  const [itemSchedule, setItemSchedule] = useState({});

  // filtro das filas
  const [filter, setFilterPage] = useState(1);
  const [codfila, setCodFila] = useState('TODOS');
  const saveUserQueue = fatherState?.props?.enabledQueuesGet?.filas;
  const [filterNew, setFilterNew] = useState(saveUserQueue);
  const [filterStatus, setFilterStatus] = useState([]);
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [filterProfessionals, setFilterProfessionals] = useState([]);
  const [filterUnitsOfAttendance, setFilterUnitsOfAttendance] = useState([]);
  const [unitsOfAttendance, setUnitsOfAttendance] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [openUnits, setOpenUnits] = useState(false);
  const [counterProfessionals, setCounterProfessionals] = useState(0);
  const [counterUnits, setCounterUnits] = useState(false);
  const [openProfessionals, setOpenProfessionals] = useState(false);

  const [inAttendance, setIinAttendance] = useState([]);
  const isObjectEmpty = (obj) => {
    if (obj === undefined || obj === null) return true;
    return Object.keys(obj).length === 0;
  };

  const QUEUES_ATTENDENCES_TYPES = [
    'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST',
    'IN_VONAGE_ATTENDANCE_RECEPCIONIST',
    'IN_WAIT_ATTENDANCE_DOCTOR',
    'IN_ATTENDANCE_DOCTOR',
    'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT',
    'VONAGE_IN_ATTENDANCE_SUPPORT',
    'IN_VONAGE_PENDING_CONTACT',
    'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE',
    'IN_WAIT_FOR_DATE_SCHEDULE',
    'NO_SHOW',
    'IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST',
    'IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST',
    'TRUCLINIC_IN_WAIT_ATTENDANCE_SUPPORT',
    'TRUCLINIC_IN_ATTENDANCE_SUPPORT',
  ];

  useEffect(() => {
    const queues = receivedEnabledQueues?.enabledQueuesGet?.filas;
    const status = receivedEnabledQueues?.enabledQueuesGet?.status;

    if (
      receivedEnabledQueues?.enabledQueuesGet?.filas !== undefined ||
      receivedEnabledQueues?.enabledQueuesGet?.status !== undefined
    ) {
      setFilterNew(queues);
      setFilterStatus(status);
      dispatch(subMenuActions.getAllSubMenuItemsOnload(queues, codfila));
    }
  }, [receivedEnabledQueues]);

  useEffect(() => {
    const { id } = JSON.parse(localStorage.getItem('user'));
    const countProfFilters = JSON.parse(
      localStorage.getItem(`${id}||Profissionais`)
    );

    setCounterProfessionals(countProfFilters?.length);
    const countUnitsFilters = JSON.parse(
      localStorage.getItem(`${id}||Unidades de Atendimento`)
    );
    setCounterUnits(countUnitsFilters?.length);
  }, [counterProfessionals, counterUnits]);

  const controlOVerlay = () => {
    setLoadItem(true);
    debounce(() => {
      setLoadItem(false);
    }, 2000);
  };

  const customFunction = () => {
    setButtonFilterActive(!buttonFilterActive);
    setOpenStatus(!openStatus);
  };

  const columns = [
    { title: 'Paciente', field: '' },
    { title: 'Documento', field: 'document' },
    { title: 'Produto', field: 'descricaoProduto' },
    { title: 'Contrato', field: 'convenioDescricao' },
    { title: 'Idade', field: 'idade' },
    {
      title: 'Atendimento com',
      field: '',
    },
    {
      title: (
        <ButtonFilter
          text={'Status'}
          openStatus={openStatus}
          icon={<IconFilter />}
          active={buttonFilterActive && filterStatus.length > 0}
          receivedStatus={filterStatus}
          placementIcon={'right'}
          customFunction={customFunction}
        />
      ),
      field: '',
    },
    {
      title: 'Ações',
      field: '',
    },
  ];

  const menuItensDropdown = [
    {
      description: 'Nome do paciente',
      option: () => {
        setObjectRequest({
          ...objectRequest,
          queryString: '',
          projection: 'name',
        });
        setMask('');
        setObjectQueryString(objectQueryString);
      },
    },
    {
      description: 'Documento',
      option: () => {
        setObjectRequest({
          ...objectRequest,
          queryString: '',
          projection: 'document',
        });
        setMask('');
        setObjectQueryString(objectQueryString);
      },
    },
    {
      description: 'Contrato',
      option: () => {
        setObjectRequest({
          ...objectRequest,
          queryString: '',
          projection: 'convenioDescricao',
        });
        setMask('');
        setObjectQueryString(objectQueryString);
      },
    },
    {
      description: 'Data de nascimento',
      option: () => {
        setObjectRequest({
          ...objectRequest,
          queryString: '',
          projection: 'birthDate',
        });
        setMask('99/99/9999');
        setObjectQueryString('');
      },
    },
  ];

  let { projection } = objectRequest;

  //TODO: Remover esse bloco após validar atualização do status
  // const updateStatusAttendanceNormalTrigger = async (data, verified) => {
  //   setIsLoadingOverlay(true);
  //   data.status = 'input';
  //   await acionamentoActionsVonage
  //     // Seta as infos de atendente do acionamento normal
  //     .updateStatusAttendance2(data)
  //     .then(() => {
  //       setIsLoadingOverlay(false);
  //       editQueueItem(data);
  //       setObjectRequest({ ...objectRequest, refresh: true });
  //     })
  //     .catch((err) => {
  //       console.log('erro ao editar', err);
  //     });
  // };

  const updateStatusAttendanceVonageTrigger = async (data) => {
    data.status = 'input';
    if (socket.connected) {
      socket.emit('updateAttendance', {
        operationType: 'updateAttendance',
        _id: data._id,
        updateAttendance: createObjectForAttendanceUpdate(),
      });
    }

    await acionamentoActionsVonage
      // Seta as infos de atendente do acionamento Vonage
      .updateStatusAttendance(data)
      .then(() => {
        editQueueItem(data);
        setObjectRequest({ ...objectRequest, refresh: true });
      })
      .catch((err) => {
        console.log('erro ao editar', err);
      });
  };

  const defineEdit = async (_, data) => {
    setLoadItem(true);
    debounce(async () => {
      let result = await acionamentoVonageService.verifyAttendance(data._id);
      if (result?.err) {
        dispatch(
          alertActions.error('Falha ao entrar no acionamento, tente novamente!')
        );
        setLoadItem(false);
        debounce(() => dispatch(alertActions.clear()), 3000);
        return;
      }

      if (result.id === 2) {
        setData(data);
        setModalOpen(true);
      } else if (result.id === 1) {
        data.status = 'input';
        await updateStatusAttendanceVonageTrigger(data, setLoadItem);
      }
    }, 3000);
  };

  //TODO: usar logica do cache aqui caso de certo
  const openSupport = async (acionamento) => {
    setLoadItem(true);
    debounce(async () => {
      const result = await acionamentoVonageService.verifyAttendance(
        acionamento._id
      );
      if (result?.err) {
        dispatch(
          alertActions.error('Falha ao entrar no acionamento, tente novamente!')
        );
        setLoadItem(false);
        debounce(() => dispatch(alertActions.clear()), 3000);
        return;
      }

      if (result.id === 2) {
        setDataSupport(acionamento);
        setModalOpenSupport(true);
      } else if (result.id === 1) {
        await handleConfirmNewAttendanceSupport(acionamento);
      }
    }, 3000);
  };
  const localUser = JSON.parse(localStorage.getItem('user'));

  const startSupport = async (data) => {
    const objectForFoward = {
      origin: {
        channel: 'acionamentos',
        received: 'central',
        from: '',
      },
      originProfessional: {
        name: '',
        id: '',
      },
    };

    objectForFoward.origin.from = localUser?.user?.id_perfil?.descricao;
    objectForFoward.originProfessional.name = localUser.user.nome;
    objectForFoward.originProfessional.id = localUser.user._id;
    const dataForFowarding = { ...data, ...objectForFoward };
    dispatch(actionForwarding.startSupport(dataForFowarding));
  };

  let removeDuplicated = (acionamento = []) => {
    let setArr = new Set();
    let filterArr = acionamento.filter((acionamento) => {
      let duplicatedAcionamento = setArr.has(acionamento.idAttendanceVonage);
      setArr.add(acionamento.idAttendanceVonage);
      return !duplicatedAcionamento;
    });
    return filterArr;
  };

  const paginateQueue = async (
    filter,
    pageNumber,
    pageSize = 5,
    objectRequest,
    objectQueryString,
    filterStatus,
    filterProfessionals,
    filterUnitsOfAttendance,
    codFila
  ) => {
    let fila = await queueItems.getPaginate(
      filter,
      pageNumber,
      pageSize,
      objectRequest,
      objectQueryString,
      filterStatus,
      filterProfessionals,
      filterUnitsOfAttendance,
      codFila
    );

    setReqPage(fila.page);
    setLoadRow(false);
    setLoading(false);

    if (pageNumber == 1) {
      const arrOrder = handleOrderAndRemoveDuplicated(fila?.row);
      fatherState.setState({ firstRow: arrOrder });
      window.scrollTo(0, 0);
    } else if (pageNumber > 1) {
      let oldArr = fatherState.state.firstRow;
      let newArr = fila?.row;
      let arrSave = [];
      fatherState.setState({ firstRow: [] });
      arrSave = arrSave.concat(oldArr, newArr);
      const newOrder = handleOrderAndRemoveDuplicated(arrSave);
      fatherState.setState({ firstRow: newOrder });
    }
  };

  const countAllQueue = (newSubMenuItems)=>{
    let countAllQueue = 0;
    newSubMenuItems.map((item)=>{
      const { qtd } = item;
      if(item?.idFila != 1 && qtd>= 0) countAllQueue += qtd
    })
    return countAllQueue
  }

  useEffect(() => {
    const payload = subMenuItemsBySocket;
    const queuesForCount = subMenu;
    const documentId = payload?.documentKey?._id;
    const qtd = payload?.fullDocument?.qtd;
    const activeQueues = receivedEnabledQueues?.enabledQueuesGet?.filas;
    let all = 0;
    const countQueues = queuesForCount?.map((item) => {
      if (!item?.qtd) item.qtd = 0;
      if (item?._id === documentId) {
        item.qtd = qtd;
      }
      return item;
    });

    const dontCount = [50, 1];
    countQueues?.map((el) => {
      if (activeQueues?.includes(el.idFila) && !dontCount.includes(el.idFila)) {
        all = all + el?.qtd;
      }
    });
    const countAll =
      countQueues?.length > 0 && Object.keys(payload)?.length > 0;
    if (countAll) countQueues[0].qtd = all;
  }, [subMenu, subMenuItemsBySocket]);

  useEffect(() => {
    if (
      socketInfos.fullDocument?.currentEditionAttendanceInfo?.attendanceType ===
        'IN_WAIT_FOR_DATE_SCHEDULE' &&
      new Date(socketInfos.fullDocument?.isoScheduleTime).setHours(0, 0, 0, 0) >
        new Date().setHours(0, 0, 0, 0)
    )
      return;

    if (socketInfos.operationType === 'updateAttendance') {
      let arr = fatherState.state.firstRow;
      let arrSave = arr.map((element) => {
        if (element._id === socketInfos._id) {
          element.currentEditionAttendanceInfo = socketInfos.updateAttendance;
        }
        return element;
      });
      const newOrder = handleOrderAndRemoveDuplicated(arrSave);
      fatherState.setState({ firstRow: newOrder });
    }

    if (socketInfos.operationType === 'delete') {
      let acionamentoDeleted = socketInfos.documentKey._id;
      let arr = fatherState.state.firstRow;
      let newArr = arr.filter(function (item) {
        return item._id !== acionamentoDeleted;
      });
      const newRow = handleOrderAndRemoveDuplicated(newArr);
      fatherState.setState({ firstRow: newRow });
    }
    if (socketInfos.operationType === 'update') {
      if (
        !!socketInfos?.fullDocument?.confirmedByForm &&
        socketInfos?.fullDocument?.currentEditionAttendanceInfo
          ?.attendanceType == 'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST'
      )
        return;

      if (socketInfos?.fullDocument?.forkedFrom) {
        socketInfos.fullDocument.idFila = 50;
      }
      let { documentKey } = socketInfos;
      fatherState.setState((prevState) => {
        const newAcionamentoQueueState = [...prevState.firstRow];
        let newAcionamentoQueue;

        const isPatientInOperation =
          socketInfos.updateDescription?.updatedFields?.statusUserInVonage ===
          'PATIENT_IN';

        if (
          filterNew?.includes(socketInfos?.fullDocument?.idFila) &&
          !refreshSearch &&
          isPatientInOperation
        ) {
          if (socketInfos.fullDocument.idFila == 50 && filterNew.length > 1)
            return;
          newAcionamentoQueueState.push(socketInfos.fullDocument);
        }

        if (newAcionamentoQueueState.length === 0) {
          if (
            filterNew?.includes(socketInfos?.fullDocument?.idFila) &&
            isPatientInOperation
          ) {
            newAcionamentoQueue = [socketInfos.fullDocument];
          } else {
            newAcionamentoQueue = [];
          }
        } else {
          if (
            filterNew?.includes(
              socketInfos?.fullDocument?.idFila &&
                !socketInfos?.fullDocument?.forkedFrom
            )
          ) {
            newAcionamentoQueueState.push(socketInfos?.fullDocument);
          }
          newAcionamentoQueue = newAcionamentoQueueState.map((row) => {
            if (row?._id === documentKey?._id) {
              return socketInfos.fullDocument;
            }
            return row;
          });
        }
        if (!filterNew?.includes(socketInfos?.fullDocument?.idFila)) {
          const newArray = newAcionamentoQueueState.filter(
            (item) => item._id !== socketInfos?.fullDocument?._id
          );
          newAcionamentoQueue = newArray;
        }

        const filteredAcionamentoQueue = filteredStatus(newAcionamentoQueue);
        const orderedAcionamentoQueue = orderRow(filteredAcionamentoQueue);
        const uniqueAcionamentoQueue = removeDuplicated(
          orderedAcionamentoQueue
        );

        return {
          firstRow: uniqueAcionamentoQueue,
        };
      });
    }

    //maozada no banco robot 3t
    if (socketInfos.operationType === 'replace') {
      let { documentKey, fullDocument } = socketInfos;

      fatherState.state?.firstRow.map((e, i) => {
        if (e._id == documentKey._id) {
          let update = fullDocument;
          let row = fatherState.state.firstRow;
          row[i] = { ...e, ...update };
          const newRow = handleOrderAndRemoveDuplicated(row);
          fatherState.setState({ firstRow: newRow });
        }
      });
    }
  }, [socketInfos]);

  const filteredStatus = (elements) => {
    if (filterStatus?.length > 0) {
      let newArr = [];
      elements.map((element) => {
        for (const filter of filterStatus) {
          if (
            element?.currentEditionAttendanceInfo?.attendanceType?.includes(
              filter
            )
          )
            newArr.push(element);
        }
      });
      return newArr;
    } else {
      return elements;
    }
  };

  const timerExecution = async () => {
    if (!cronometer) cronometer = await getTime();
    let dateadd = dayjs(cronometer).add(1, 's').format();
    cronometer = dateadd;
    setCronometer(cronometer);
  };

  const newWorker = () => {
    return new Worker(
      URL.createObjectURL(
        new Blob([
          `
      let counter = 0;

      const updateCounter = ()=>{
        counter++;
        self.postMessage(counter);
      }

      setInterval(updateCounter,1000)
    `,
        ])
      )
    );
  };
  useEffect(() => {
    const worker = newWorker();
    worker.addEventListener('message', timerExecution);
    dispatch(userActions.getEnabledQueues());
    getUnitsAndProfessionals();
  }, []);

  useEffect(() => {
    const isloader = filterNew === undefined;
    setLoadRow(isloader);

    if (pageNumber == 1 && filterNew !== undefined) {
      setLoadRow(true);
      paginateQueue(
        filterNew,
        pageNumber,
        sizePage,
        objectRequest,
        objectQueryString,
        filterStatus,
        filterProfessionals,
        filterUnitsOfAttendance,
        codfila
      );
    }

    if (pageNumber > 1 && filterNew !== undefined) {
      setLoading(true);
      setTimeout(() => {
        setLoadRow(true);
        paginateQueue(
          filterNew,
          pageNumber,
          sizePage,
          objectRequest,
          objectQueryString,
          filterStatus,
          filterProfessionals,
          filterUnitsOfAttendance
        );
      }, '1000');
    }
  }, [
    filterNew,
    pageNumber,
    filterStatus,
    objectRequest,
    filterProfessionals,
    filterUnitsOfAttendance,
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver((entities) => {
      if (entities.some((entry) => entry.isIntersecting))
        if (pageNumber < reqPage) {
          setPageNumber((currentPageInsideState) => currentPageInsideState + 1);
        }
    });
    observer.observe(document.querySelector('#scroll'));
    return () => observer.disconnect();
  }, [fatherState.state.firstRow]);
  let slicedArray = useMemo(
    () =>
      fatherState.state.firstRow?.length > 0 ? fatherState.state.firstRow : [],
    [fatherState.state.firstRow]
  );

  slicedArray.forEach((element) => {
    element.idade = moment().diff(element.birthDate, 'years');
    element.document = element?.document ? element.document : '';
    element.document = element.document.replace(/\undefined/g, '');
    element.document = element.document.replace(/\null/g, '');
  });

  const handleAddTrigger = () => {
    if (isObjectEmpty(editAcionamento)) history.push('/adm/acionamento/new');
    else {
      dispatch(acionamentoActions.clearAcionamentos());
      history.push('/adm/acionamento/new');
    }
  };

  const handleChangeSearch = (e) => {
    if (validations.validarCPF(e.target.value)) {
      setObjectQueryString(
        e.target.value.replace(/\D+/g, '').replace(/([0-9])/, '$1')
      );
      return;
    }
    setObjectQueryString(e.target.value);
  };

  const updatedTime = () => {
    let replacedDate = new Date().toISOString();
    return replacedDate;
  };

  const getAddress = async (cep) => {
    return await acionamentoService.GetAddressFromCep(cep);
  };

  const getUnitsAndProfessionals = async () => {
    const result =
      await acionamentoService.getUnitsAndProfessionalsAttendance();
    const units = result?.units?.map((unit) => {
      return {
        text: unit.name,
        checked: false,
        value: [unit.name],
      };
    });
    const professionals = result.professionals.map((professional) => {
      return {
        text: professional.name,
        checked: false,
        value: [professional.name],
      };
    });
    setProfessionals(professionals);
    setUnitsOfAttendance(units);
  };

  const editQueueItem = async (item) => {
    const editionTime = updatedTime();

    if (productsTriggerQueue.includes(item?.queue)) {
      const { localidade = '', uf = '' } = item.streetCep
        ? await getAddress(item.streetCep)
        : '';
      if (uf) item.country = 'Brasil';
      if (!item.state) item.state = uf ?? 'CEP não encontrado';
      if (!item.city) item.city = localidade ?? 'CEP não encontrado';
    }

    dispatch(
      acionamentoActions.editAcionamentos(
        {
          ...item,
          editionTime,
          edit: true,
        },
        setLoadItem,
        setLoadingButton
      )
    );
  };

  const validDocument = (document) => {
    if (validations.validarCPF(document)) {
      return document.replace(/\./g, '').replace('-', '');
    } else {
      return document;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setRefreshSearch(true);
    setPageNumber(1);
    setFilterNew([filter]);
    setObjectRequest({
      ...objectRequest,
      queryString:
        projection === 'document'
          ? validDocument(objectQueryString)
          : projection === 'birthDate'
          ? formatStringDate(objectQueryString)
          : objectQueryString,
      projection: projection,
    });

    setLoading(true);
  };

  const handleClearSearch = (e) => {
    setRefreshSearch(false);
    const defaultSearch =
      receivedEnabledQueues?.enabledQueuesGet?.filas?.length > 1
        ? receivedEnabledQueues?.enabledQueuesGet?.filas
        : ALL_QUEUES_IDS;
    setFilterNew(defaultSearch);
    e.preventDefault();
    setObjectRequest({
      ...objectRequest,
      queryString: '',
    });
    setObjectQueryString('');
    setPageNumber(1);
  };

  const handleOrderAndRemoveDuplicated = (array) => {
    return removeDuplicated(orderRow(array));
  };

  const formatAttendancesOrderView = (attendancesList) =>
    attendancesList
      ?.filter((action) => {
        /*
        n encaixa no fluxo, isso daqui invalida o status faltou
        e os agendados+faltou precisam continuar visiveis até as 00:00
        que é qnd vão para o historico.

        if (
          action.currentEditionAttendanceInfo.attendanceType ===
          'IN_WAIT_FOR_DATE_SCHEDULE'
        ) {
          return (
            new Date(action.isoScheduleTime) >
            new Date(new Date().setMinutes(new Date().getMinutes() - 10))
          );
        }
        */
        return action;
      })
      .sort((a, b) => {
        if (
          ['IN_WAIT_FOR_DATE_SCHEDULE', 'NO_SHOW'].includes(
            a.currentEditionAttendanceInfo?.attendanceType ||
              b.currentEditionAttendanceInfo?.attendanceType
          )
        ) {
          return (
            new Date(a.isoScheduleTime).getTime() -
            new Date(b.isoScheduleTime).getTime()
          );
        }

        return (
          new Date(a.currentEditionAttendanceInfo.dateStarted).getTime() -
          new Date(b.currentEditionAttendanceInfo.dateStarted).getTime()
        );
      })
      .sort(
        (a, b) =>
          QUEUES_ATTENDENCES_TYPES.indexOf(
            a.currentEditionAttendanceInfo.attendanceType
          ) -
          QUEUES_ATTENDENCES_TYPES.indexOf(
            b.currentEditionAttendanceInfo.attendanceType
          )
      );

  const orderRow = (attendances) => {
    let waitingReception = [];
    let inAttendanceReception = [];
    let waitingDoctor = [];
    let inAttendanceDoctor = [];
    let truClinicWaiting = [];
    let truClinicInAttendance = [];
    let vonageWaitingSupport = [];
    let truclinicWaitingSupport = [];
    let vonageInAttendanceSupport = [];
    let truclinicInAttendanceSupport = [];
    let noShow = [];
    let newQueue = [];
    let isSchedule = [];

    try {
      for (const attendance of attendances) {
        switch (attendance.currentEditionAttendanceInfo.attendanceType) {
          case 'IN_VONAGE_ATTENDANCE_RECEPCIONIST':
            inAttendanceReception.push(attendance);
            break;
          case 'IN_WAIT_ATTENDANCE_DOCTOR':
            waitingDoctor.push(attendance);
            break;
          case 'IN_TRUCLINIC_ATTENDANCE_RECEPCIONIST':
            truClinicInAttendance.push(attendance);
            break;
          case 'VONAGE_IN_WAIT_ATTENDANCE_SUPPORT':
            vonageWaitingSupport.push(attendance);
            break;
          case 'TRUCLINIC_IN_WAIT_ATTENDANCE_SUPPORT':
            truclinicWaitingSupport.push(attendance);
            break;
          case 'VONAGE_IN_ATTENDANCE_SUPPORT':
            vonageInAttendanceSupport.push(attendance);
            break;
          case 'TRUCLINIC_IN_ATTENDANCE_SUPPORT':
            truclinicInAttendanceSupport.push(attendance);
            break;
          case 'NO_SHOW':
            noShow.push(attendance);
            break;
          case 'IN_ATTENDANCE_DOCTOR':
            inAttendanceDoctor.push(attendance);
            break;
          case 'IN_WAIT_FOR_DATE_SCHEDULE':
            newQueue.push(attendance);
            break;
          default:
            break;
        }

        if (
          attendance.currentEditionAttendanceInfo.attendanceType ===
            'IN_VONAGE_WAIT_ATTENDANCE_RECEPCIONIST' &&
          attendance.currentEditionAttendanceInfo.waitingForDateSchedule ===
            false
        )
          waitingReception.push(attendance);

        if (
          attendance.currentEditionAttendanceInfo.attendanceType ===
            'IN_TRUCLINIC_WAIT_ATTENDANCE_RECEPCIONIST' &&
          attendance.currentEditionAttendanceInfo.waitingForDateSchedule ===
            false
        )
          truClinicWaiting.push(attendance);

        if (
          attendance.currentEditionAttendanceInfo.attendanceType ==
            'IN_VONAGE_PENDING_CONTACT' &&
          attendance.queue
        ) {
          newQueue.push(attendance);
        }
        if (
          attendance.currentEditionAttendanceInfo.attendanceType ==
            'IN_VONAGE_ATTENDANCE_CONFIRM_SCHEDULE' &&
          attendance.queue
        ) {
          newQueue.push(attendance);
        }

        if (
          attendance.currentEditionAttendanceInfo.waitingForDateSchedule ===
          true
        )
          isSchedule.push(attendance);
      }

      const attendancesList = [
        ...waitingReception,
        ...inAttendanceReception,
        ...waitingDoctor,
        ...inAttendanceDoctor,
        ...vonageWaitingSupport,
        ...vonageInAttendanceSupport,
        ...truClinicWaiting,
        ...truClinicInAttendance,
        ...truclinicWaitingSupport,
        ...truclinicInAttendanceSupport,
        ...isSchedule,
        ...newQueue,
        ...noShow,
      ];

      const ordered = formatAttendancesOrderView(attendancesList);
      return ordered;
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const formatStringDate = (str) => {
    return moment(str, 'DD/MM/YYYY').format('YYYY-MM-DD');
  };

  const handleConfirmNewAttendance = async () => {
    setLoadingButton(true);
    await updateStatusAttendanceVonageTrigger(dataAcionamento);
  };

  const handleConfirmNewAttendanceSupport = async (acionamento) => {
    setLoadingButton(true);
    const data =
      Object.keys(dataSupport).length > 0 ? dataSupport : acionamento;
    await startSupport(data);
    const tzoffset = new Date().getTimezoneOffset() * 60000;
    const editionTime = new Date(Date.now() - tzoffset).toISOString();
    dispatch(
      acionamentoSuporteActions.editScreenAcionamentosSupport({
        ...data,
        editionTime,
      })
    );
  };

  const handleClose = () => {
    dispatch(alertActions.clear());
  };

  if (!isObjectEmpty(dataSource) && !dataSource[0].hasOwnProperty('row')) {
    setLoading(true);
  }

  useEffect(() => {
    const cointainerFiltes = document.getElementById('containerPage');
    cointainerFiltes.addEventListener('click', (event) => {
      if (openProfessionals) {
        if (!event.target.closest('.professionals')) {
          setOpenProfessionals(false);
        }
      }
      if (openUnits) {
        if (!event.target.closest('.units')) {
          setOpenUnits(false);
        }
      }
      if (openStatus) {
        if (!event.target.closest('.containerStatus')) {
          setOpenStatus(false);
        }
      }
    });
  }, [openProfessionals, openUnits, openStatus]);

  const callback = useCallback(
    (filter) => {
      let filterQueue;
      if (filter != filterNew) {
        setFilterPage(filter);
        if (filter === 1) {
          filterQueue = saveUserQueue;
        } else {
          filterQueue = [filter];
        }
        setPageNumber(1);
        setFilterNew(filterQueue);
        fatherState.setState({ firstRow: [] });
      }
    },
    [filter, codfila, saveUserQueue]
  );

  if (filterStatus?.length > 0) {
    slicedArray = filteredStatus(slicedArray);
  }

  const user = JSON.parse(window.localStorage.user);
  const profile = user?.user?.id_perfil?.descricao;
  const handleCloseCancelScheduleModal = () => {
    setOpenScheduleCancel(false);
  };

  return (
    <>
      {useLoadItem && (
        <CustomLoader fixed={true}>
          <Loader background={'#007ACC'} />
        </CustomLoader>
      )}
      <ContainerPage id='containerPage'>
        <CancelScheduleModal
          open={openScheduleCancel}
          onClose={handleCloseCancelScheduleModal}
          selectedAttendance={itemSchedule || null}
        />
        <GridLayout>
          <Header>
            <ContainerSearch>
              <SearchInput
                menuItensDropdown={menuItensDropdown}
                optionValue={projection}
                handleChangeSearch={handleChangeSearch}
                handleClearSearch={handleClearSearch}
                value={objectQueryString}
                defaultValue={'Nome do paciente'}
                inputPlaceHolder={'Digite para pesquisar'}
                onClick={handleSearch}
                mask={mask}
                maskChar={''}
              />
            </ContainerSearch>
            {process.env.REACT_APP_SGH_SEARCH_ON === 'true' && (
              <ContainerUnitsAndProfessionalsFilters>
                <ContainerButtonOfUnits>
                  {filter == 9 && (
                    <CustomButton
                      onClick={(e) => {
                        setOpenUnits(!openUnits);
                      }}
                      //margin='0 0 0 10px'
                      size={'large'}
                      typeButtom={'greyRounded'}
                    >
                      <ContainerSpanInfos>
                        {counterUnits > 0 ? (
                          <span>{counterUnits}</span>
                        ) : (
                          <span> </span>
                        )}
                        <span>Unidades</span>
                        <span>
                          <ExpandMore fontSize='small' />
                        </span>
                      </ContainerSpanInfos>
                    </CustomButton>
                  )}
                  <ContainerFilterUnits className='units'>
                    {openUnits && (
                      <FilterGeneric
                        data={unitsOfAttendance ?? []}
                        filterName={'Unidades de Atendimento'}
                        setFilterStatus={setFilterUnitsOfAttendance}
                        setPageNumber={setPageNumber}
                        setOpenStatus={setOpenUnits}
                        countFilters={setCounterUnits}
                      />
                    )}
                  </ContainerFilterUnits>
                </ContainerButtonOfUnits>

                <ContainerButtonOfProfessionals>
                  {filter == 9 && (
                    <CustomButton
                      onClick={(e) => {
                        setOpenProfessionals(!openProfessionals);
                      }}
                      //margin='0 0 0 10x'
                      size={'large'}
                      typeButtom={'greyRounded'}
                    >
                      <ContainerSpanInfos>
                        {counterProfessionals > 0 ? (
                          <span>{counterProfessionals}</span>
                        ) : (
                          <span> </span>
                        )}
                        <span>Profissionais</span>
                        <span>
                          <ExpandMore fontSize='small' />
                        </span>
                      </ContainerSpanInfos>
                    </CustomButton>
                  )}
                  <ContainerFilterProfessionals className='professionals'>
                    {openProfessionals && (
                      <FilterGeneric
                        data={professionals ?? []}
                        filterName={'Profissionais'}
                        receivedStatus={[]}
                        setFilterStatus={setFilterProfessionals}
                        setPageNumber={setPageNumber}
                        setOpenStatus={setOpenProfessionals}
                        countFilters={setCounterProfessionals}
                      />
                    )}
                  </ContainerFilterProfessionals>
                </ContainerButtonOfProfessionals>
              </ContainerUnitsAndProfessionalsFilters>
            )}
            <CustomTitle
              width={'99%'}
              height={'100px'}
              className={'rubik'}
              pos={'space-between'}
              level={1}
            >
              <ContainerButton>
                {checkCreateEncounterPermission(profile) && (
                  <CustomButton
                    onClick={(e) => {
                      localStorage.removeItem('userEdition');
                      handleAddTrigger();
                    }}
                    size={'large'}
                    typeButtom={'primary'}
                  >
                    <IconButton src={Plus} alt='Adicionar' />{' '}
                    <span>Adicionar</span>
                  </CustomButton>
                )}
              </ContainerButton>
            </CustomTitle>
          </Header>

          <Main>
            <Alert
              open={open}
              type={type}
              message={message}
              onClose={() => handleClose()}
            />

            <Container>
              <CustomMenu
                typeMenu={'horizontal'}
                data={subMenu}
                parentCallBack={callback}
                receivedEnabledQueues={receivedEnabledQueues}
                controlOVerlay={controlOVerlay}
                title='Sala de espera'
                filter={filter}
                setFilterPage={setFilterPage}
                setPageNumber={setPageNumber}
                setCodFila={setCodFila}
              />
              <ContainerQueue>
                <QueueTable
                  cronometer={useCronometer}
                  messageForEmpty={messageForEmpty}
                  data={slicedArray}
                  setOpenScheduleCancel={setOpenScheduleCancel}
                  setItemSchedule={setItemSchedule}
                  columns={columns}
                  loading={loading}
                  loader={<Loader background={'#007ACC'} />}
                  defineEdit={defineEdit}
                  openSupport={openSupport}
                  typeProfile={checkCreateEncounterPermission(profile)}
                >
                  {openStatus && (
                    <FilterStatus
                      className={'containerStatus'}
                      receivedStatus={filterStatus}
                      setFilterStatus={setFilterStatus}
                      setPageNumber={setPageNumber}
                      setOpenStatus={setOpenStatus}
                    />
                  )}
                </QueueTable>
                <div style={{ marginTop: '150px' }} id='scroll'>
                  <h1></h1>
                </div>
                <ContainerLoader ref={loaderRef}>
                  {loadRow && <Loader background={'#007ACC'} />}
                </ContainerLoader>
              </ContainerQueue>
            </Container>
          </Main>
        </GridLayout>
      </ContainerPage>
      <Modal
        open={openModal}
        withActions
        text={`Chamada já está ${textModal}, Tem certeza que deseja entrar?`}
        withClose
        loading={loadingButton}
        loader={<Loader background={'#007ACC'} />}
        handleCancel={() => {
          setData({});
          setModalOpen(false);
          setLoadItem(false);
          setLoadingButton(false);
        }}
        handleConfirm={() => handleConfirmNewAttendance()}
        labelOk={'Sim'}
        labelCancel={'Não'}
      />
      <Modal
        open={openModalSupport}
        withActions
        text={`Chamada já está ${textModal}, Tem certeza que deseja entrar?`}
        withClose
        loading={loadingButton}
        loader={<Loader background={'#007ACC'} />}
        handleCancel={() => {
          setDataSupport({});
          setModalOpenSupport(false);
          setLoadItem(false);
          setLoadingButton(false);
        }}
        handleConfirm={() => handleConfirmNewAttendanceSupport()}
        labelOk={'Sim'}
        labelCancel={'Não'}
      />
    </>
  );
};

export default QueuePage;
