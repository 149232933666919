import moment from 'moment';
import {
  getDaysInMonth,
  formateDatesPerMonth,
  stringSplit,
  objDate,
} from '../utils/utils';
import { agendamentoService } from '../services/agendamento.service';

export const avaliableMonth = async (numberMonths, reverse, debug = false) => {
  let arrayDaysOfMonths = [];

  for (let i = 0; i < numberMonths; i++) {
    arrayDaysOfMonths.push(getDaysInMonth(objDate().month + i, objDate().year));
  }

  arrayDaysOfMonths = arrayDaysOfMonths.flatMap((element) => element);
  arrayDaysOfMonths = formateDatesPerMonth(arrayDaysOfMonths);

  try {
    var newData = [];
    let sucess = await agendamentoService.getSlots(
      moment().format('YYYY-MM-DD').toString(),
      await arrayDaysOfMonths[arrayDaysOfMonths.length - 1],
      3,
      2,
      'TELEPSICOLOGIA'
    );

    if ((sucess !== undefined && !sucess.error) || !sucess.stack) {
      newData = sucess.data.map((element) => {
        return stringSplit(element.startAt, ' ', 0);
      });

      newData = [...new Set(newData)];
    }

    let disabledDates = arrayDaysOfMonths.filter((element) => {
      if (reverse === true) return newData.includes(element);
      else return !newData.includes(element);
    });

    if (debug) {
    }

    return await disabledDates;
  } catch (error) {
    console.log('ERROR', error);
  }
};
