import styled from "styled-components"

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const StyledModalDataContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const StyledModalData = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledPatientDataContainer = styled.div`
  display: flex;
  gap: 40px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StyledPatientDataBox = styled.div`
  display: flex;
  gap: 5px;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`