// libs
import { useState, useEffect } from 'react';

import { FiCopy as FiCopyIcon } from 'react-icons/fi';
import { FiCheck as FiCheckIcon } from 'react-icons/fi';

import * as S from './styles';
import { Tooltip } from '@material-ui/core';

interface QueueCopyActionProps {
  dataToCopy: string | null;
}

export function QueueCopyAction({ dataToCopy }: QueueCopyActionProps) {
  const [showAnimation, setShowAnimation] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleClick = () => {
    if (!dataToCopy) return;

    navigator.clipboard.writeText(dataToCopy);

    if (timeoutId) return;

    setShowAnimation(true);

    const id = setTimeout(() => {
      setShowAnimation(false);
      setTimeoutId(null);
    }, 700);

    setTimeoutId(id);
  };

  useEffect(() => {
    if (timeoutId) {
      return () => clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  return (
    <S.ContainerButton>
      <Tooltip title='Copiar informações do atendimento' placement='bottom'>
        <div>
          <S.ButtonWrapper onClick={handleClick}>
            {!showAnimation ? <FiCopyIcon /> : <FiCheckIcon />}
          </S.ButtonWrapper>
        </div>
      </Tooltip>
    </S.ContainerButton>
  );
}
