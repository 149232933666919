import { useState, useEffect } from 'react';

import { useLocalStorage, useNavigator } from '../../../../hooks';

import { scalesService } from '../../../../services/core';

import actions from '../../../../redux/actions';
import { socket } from '../../../../../utils/socket';

const useLockStation = () => {
  const { push } = useNavigator();
  const [storedUser] = useLocalStorage('user');

  const [useLoading, setLoading] = useState(false);
  const [usePause, setPause] = useState(null);
  const [useBlock, setBlock] = useState(null);

  const formatString = (userName) =>
    String(userName).toLocaleUpperCase().trim();

  useEffect(() => {
    mountPause();
  }, []);

  useEffect(() => {
    socket.on('changeDataScaleSocket', async (payload) => {
      if (
        payload?.updateDescription?.updatedFields?.statusDescription === 'Livre'
      ) {
        const scaleSocketId = payload.documentKey?._id;
        const { data } = await scalesService.getUserIdByScale(scaleSocketId);

        //@TODO Remove this name equality verification after add userIdV2 into database
        if (
          formatString(data?.scaleSocket?.userName) ===
          formatString(storedUser?.user?.name)
        ) {
          push('/adm');
        }
      }
    });
  }, []);

  const verifyPauseStatus = async () => {
    return await actions.schedule.serviceStatus();
  };

  const mountPause = async (onModalFinish = false) => {
    let pauseData;

    if (onModalFinish) {
      const { data } = await verifyPauseStatus();
      pauseData = data;
    } else {
      setLoading(true);

      const { data } = await verifyPauseStatus();
      pauseData = data;

      setLoading(false);
    }

    if (pauseData?.status === 403) {
      setBlock({
        motive: pauseData.data.motive,
        show: true,
        requestUnlock: pauseData.data.requestUnlock,
      });
    }

    if (pauseData?.userId) {
      const { intervalType, intervalDuration, intervalCurrent, intervalStart } =
        pauseData;
      setPause({
        interval: intervalStart,
        typeRest: intervalType,
        timeRest: intervalDuration,
        timeCurrent: intervalCurrent,
      });
    }

    if (pauseData.status === 404) {
      push('/adm');
    }
  };

  const onPauseFinish = () => {
    setPause(null);
    mountPause(true);
  };

  return {
    useLoading,
    onPauseFinish,
    usePause,
    useBlock,
  };
};

export default useLockStation;
