import React from 'react';
import * as S from './styles';

type Props = {
  children: React.ReactNode;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
};

export const Form = ({ onSubmit, children }: Props) => {
  return <S.Form onSubmit={onSubmit}>{children}</S.Form>;
};
