import React from 'react';
import { Modal, ModalOverlay, CloseDiv, Close, InnerLayer } from './styles';
import CloseIcon from './Icon/close.svg';
import SucessIcon from '../assets/icons/success.svg';
import ErrorIcon from '../assets/icons/error-icon.svg';

const ModalAlert = ({ open, type, message, close }) => {
  const Icon =
    type === 'error' ? (
      <img src={ErrorIcon} alt='Erro' />
    ) : (
      <img src={SucessIcon} alt='Sucesso' />
    );
  return (
    <>
      {open && (
        <ModalOverlay>
          <Modal>
            <CloseDiv>
              <Close src={CloseIcon} onClick={close} />
            </CloseDiv>
            <InnerLayer>
              <div>
                <div>{message}</div>
              </div>
              <div>{Icon}</div>
            </InnerLayer>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};

export default ModalAlert;
