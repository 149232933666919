import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const subMenuService = {
  getAllSubmenuItems,
};

function getAllSubmenuItems(idFila, codFila) {
  // console.log(idFila, textoTeste);
  const user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user?.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      codFila,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/filas/all?idFila=${idFila}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}
