import scaleUser from './scaleUser';
import scaleCreate from './scaleCreate';
import scaleGrid from './scaleGrid';
import scaleEdit from './scaleEdit';
import scaleRead from './scaleRead';
import scaleListGrid from './scaleListGrid';
import scaleRemove from './scaleRemove';
import scaleGridUser from './scaleGridUser';

const data = {
  scaleUser,
  scaleCreate,
  scaleGrid,
  scaleEdit,
  scaleRead,
  scaleListGrid,
  scaleRemove,
  scaleGridUser,
};

export default data;
