import styled from 'styled-components'

export const StyledTabHeader = styled.div`
  background: #2699fb;
  color: white;

  span[class^="PrivateTabIndicator-root"] {
    background: #2699fb;
    height: 8px;
    filter: brightness(80%);
    border-radius: 2px;
  }
`

export const StyledTabs = styled.div`
  flex-grow: 1;
`

export const StyledTabContent = styled.div`
  padding: 20px 0px;
`
