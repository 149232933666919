import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { validations } from '../../helpers/validations';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormManualCancelUser from '../ManualCancelUser/FormManualCancelUser';

import Alert from '../Alert';
import AlertLink from '../AlertLink';

import { alertActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
class ManualCancelUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: '',
      cancelamentoUserData: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePayload = this.handlePayload.bind(this);
  }

  handlePayload(e) {
    e.preventDefault();
    alert(this.state.document);
  }
  handleChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (validations.invalidateSpecialCharacters(value) || value === '')
      this.setState({
        document: value,
      });
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  render() {
    const { cancelamentosUser, open, message, type } = this.props;

    return (
      <section>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              paddingBottom: '30px',
            }}
          >
            <h3>Registro Manual de Cancelamento</h3>
          </div>

          <FormManualCancelUser data={cancelamentosUser} />
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { cancelamentosUser } = state.cancelamentoUser;
  const { message, type, open, loading } = state.alert;

  return {
    cancelamentosUser,
    message,
    type,
    open,
    loading,
  };
}

const connectedRegisterPage = withStyles(useStyles)(
  connect(mapStateToProps)(ManualCancelUser)
);
export default connectedRegisterPage;
