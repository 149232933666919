import React, { Fragment, useState } from 'react';
import DialogModal from '../DialogModal';
import Carousel from '../../components/Carousel/Carousel';
import { actionNotification } from '../../actions';

const ModalComunicados = (props) => {
  let [confirmedIds, setConfimed] = useState([]);
  let ids = [];

  function closeModal() {
    setConfimed([]);
    props.close();
  }

  async function idAviso(ids) {
    let save2 = ids[0];
    let save = [...confirmedIds];
    save.push(save2);
    await setConfimed(save);
  }

  function handlerSubmitNotifications() {
    const userLogin = props.userLogin;
    let data = {};
    data._id = userLogin.user._id;
    data.token = userLogin.accessToken;
    data.idAviso = confirmedIds;
    data.user = userLogin.user;
    props.dispatch(actionNotification.NotificationsSave(data, props));
    setConfimed([]);
    props.close();
  }
  return (
    <Fragment>
      {props.children}
      <DialogModal
        title='Comunicados'
        open={props.open}
        handleCancel={closeModal}
        handleConfirm={() => handlerSubmitNotifications}
        footerpos='center'
        titlepos='center'
        withClose
        withActions
        typeButtomCancel={
          confirmedIds.length > 0 ? 'lightRounded' : 'lightBlue'
        }
        typeButtomConfirm={confirmedIds.length > 0 ? 'Blue' : 'lightRounded'}
        labelOk='Confirmar'
        labelCancel='Ler depois'
        buttomSize='small'
        fontButtonSize='10px'
        disable1={confirmedIds.length > 0 ? 'cancel' : ''}
      >
        <Carousel
          data={props.data}
          ids={ids}
          idAviso={(e) => {
            idAviso(e);
          }}
        />
      </DialogModal>
    </Fragment>
  );
};

export default ModalComunicados;
