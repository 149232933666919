export const gregorian_en_lowercase = {
  name: 'gregorian_en_lowercase',
  months: [
    ['janeiro', 'janeiro'],
    ['fevereiro', 'fevereiro'],
    ['março', 'mar'],
    ['abril', 'abr'],
    ['maio', 'mai'],
    ['junho', 'jun'],
    ['julho', 'jul'],
    ['agosto', 'ago'],
    ['setembro', 'set'],
    ['outubro', 'out'],
    ['novembro', 'nov'],
    ['dezembro', 'dez'],
  ],
  weekDays: [
    ['sábado', 'S'],
    ['domingo', 'D'],
    ['segunda', 'S'],
    ['terça', 'T'],
    ['quarta', 'Q'],
    ['quinta', 'Q'],
    ['sexta', 'S'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};
