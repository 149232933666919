import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  background: #fff;
  max-width: 100%;
  max-height: 100%;
  width: ${({ width }) => `${width}px`};
  overflow: auto;

  @media (max-height: 500px) {
    margin: 20px 0px;
  }

  @media (max-width: 900px) {
    width: 90%;
  }

  box-shadow: 0px 27px 41px #00000026;
  border-radius: 8px;
  opacity: 1;

  padding: 20px;
`;

export const StyledCloseDiv = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;

  margin-bottom: 10px;
`;

export const StyledInnerLayer = styled.div`
  width: 100%;
`;
