// libs
import { useState, useCallback } from 'react';

import { LoginActions } from '../../../actions/login.actions';

import { PageProps } from '../../types';
// assets
import * as S from '../../style';
import { Eye, ContainerPassword, InputTextPassword } from './style';
import EyeOpen from '../../../icons/icon_olho_aberto.svg';
import EyeClose from '../../../icons/icon_olho_fechado.svg';
import { ELoginSteps } from '../../../reducers/login.reducer';

export interface LoginFormEvent {
  username: string;
  password: string;
}

interface FormFieldsProps {
  updater: (obj: LoginFormEvent) => void;
  state: LoginFormEvent;
  isSubmitted: boolean;
  isLoginInvalid: boolean;
  pageProps: PageProps;
  disableButton: boolean;
}

export function FormFields({
  updater,
  state,
  isLoginInvalid,
  isSubmitted,
  pageProps,
  disableButton,
}: FormFieldsProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const handleTogglePassword = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  const parseState = (data: Partial<LoginFormEvent>) => {
    const parsed = Object.assign({}, state, data);

    updater(parsed);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    parseState({
      password: event.target.value,
    });
  };

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    parseState({
      username: event.target.value,
    });
  };

  const handleGoToRecoverPassword = useCallback(() => {
    pageProps.dispatch(LoginActions.ChangeStep(ELoginSteps.RECOVER_PASSWORD));
  }, []);

  return (
    <>
      <S.ContainerInput>
        <S.Label style={{ margin: '5px 0' }}>Usuário</S.Label>
        <S.InputText
          name='username'
          id='input-with-icon-adornment'
          onChange={handleChangeUsername}
          value={state.username}
          placeholder='Seu usuário'
          $showBorder={(isSubmitted && !state.username) || isLoginInvalid}
          $hasBoxShadow={isSubmitted && !state.username}
        />
      </S.ContainerInput>
      <S.Label>Senha</S.Label>
      <ContainerPassword
        $showBorder={(isSubmitted && !state.password) || isLoginInvalid}
      >
        <InputTextPassword
          name='password'
          id='password'
          onChange={handleChangePassword}
          value={state.password}
          placeholder='Sua senha'
          type={isPasswordVisible ? 'text' : 'password'}
          $hasBoxShadow={isSubmitted && !state.password}
        />
        <Eye
          src={isPasswordVisible ? EyeOpen : EyeClose}
          onClick={handleTogglePassword}
        />
      </ContainerPassword>
      <S.LabelContainer>
        <S.LabelBlue onClick={handleGoToRecoverPassword}>
          Esqueceu sua senha?
        </S.LabelBlue>
      </S.LabelContainer>
      <S.ContainerInput>
        <S.ButtonBlueContained type='submit' disabled={disableButton}>
          ENTRAR
        </S.ButtonBlueContained>
      </S.ContainerInput>
    </>
  );
}
