// @ts-nocheck
import React from 'react';
import { QueueRecordEntity } from '../../../domain/entities';
import * as paparse from 'papaparse';

export const downloadFile = (data: QueueRecordEntity[]) => {
  const array = dataCsv(data);
  const parseCSV = paparse;
  const response = parseCSV.unparse({ fields: reportHeaders, data: array });
  let dataBlob;
  dataBlob = response.replace(/undefined/g, '');
  dataBlob = response.replace(/null/g, '');
  const blob = new Blob([dataBlob], { type: 'text/csv;charset=utf-8;' });
  const a = document.createElement('a');
  a.setAttribute('download', 'historico.csv');
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const dataCsv = (data: QueueRecordEntity[]) =>
  data.reduce(
    (
      acc: QueueRecordEntity[keyof QueueRecordEntity],
      data: QueueRecordEntity
    ) => {
      const {
        email,
        convenio,
        convenioDescricao,
        descricaoProduto,
        name,
        lastName,
        document,
        documentType,
        rg,
        birthDate,
        phone,
        gender,
        nationality,
        naturalness,
        state,
        country,
        codPais,
        numberCard,
        city,
        disruptiveBehavior,
        socialName,
        idMotivoTriagem,
        motivoTriagem,
        deviceData,
        confirmationUser,
        confirmed,
        centralId,
        createIdCentral,
        sendCockPit,
        createDate,
        confirmedTime,
        editionTime,
        videoChamadaPlataforma,
        cabine,
        cancelUser,
        reason,
        docRemovedByEmail,
        docRemovedByLogin,
        docRemovedByNome,
        docRemovedByTelefone,
        externalId,
        idUserAdm,
        createPassage,
        idVida,
        startTime,
        idClient,
      } = data;
      acc.push([
        email,
        convenio,
        convenioDescricao,
        descricaoProduto,
        name,
        lastName,
        document,
        documentType,
        rg,
        birthDate,
        phone,
        gender,
        nationality,
        naturalness,
        state,
        country,
        codPais,
        numberCard,
        city,
        disruptiveBehavior,
        socialName,
        idMotivoTriagem,
        motivoTriagem,
        deviceData?.deviceType,
        deviceData?.OS,
        deviceData?.navigator,
        deviceData?.route,
        deviceData?.deviceBrand,
        deviceData?.appVersion,
        deviceData?.accessChannel,
        confirmationUser?._id,
        confirmationUser?.email,
        confirmationUser?.nome,
        confirmationUser?.login,
        confirmed,
        centralId,
        createIdCentral,
        sendCockPit,
        createDate,
        confirmedTime,
        editionTime,
        videoChamadaPlataforma,
        cabine,
        cancelUser?._id,
        cancelUser?.email,
        cancelUser?.nome,
        cancelUser?.login,
        cancelUser?.telefone,
        reason,
        docRemovedByEmail,
        docRemovedByLogin,
        docRemovedByNome,
        docRemovedByTelefone,
        externalId,
        idUserAdm,
        createPassage,
        idVida,
        startTime,
        idClient,
      ]);
      return acc;
    },
    []
  );

export const reportHeaders = [
  'Paciente_email',
  'Paciente_id_convenio',
  'Paciente_convenio_nome',
  'Paciente_descricao_produto',
  'Paciente_nome',
  'Paciente_sobrenome',
  'Paciente_documento',
  'Paciente_tipo_documento',
  'Paciente_rg',
  'Paciente_data_nascimento',
  'Paciente_telefone',
  'Paciente_genero',
  'Paciente_nacionalidade',
  'Paciente_naturalidade',
  'Paciente_estado',
  'Paciente_país',
  'Paciente_codigo_pais',
  'Paciente_numero_carteirinha',
  'Paciente_cidade',
  'Paciente_comportamento_disruptivo',
  'Paciente_nome_social',
  'Triagem_id',
  'Triagem_motivo',
  'Dispositivo_tipo',
  'Dispositivo_sistema_operacional',
  'Dispositivo_navegação',
  'Dispositivo_url',
  'Dispositivo_fabricante',
  'Dispositivo_aplicativo_versao',
  'Dispositivo_canal_de_acesso',
  'Confirmacao_usuario_id',
  'Confirmacao_usuario_email',
  'Confirmacao_usuario_nome',
  'Confirmacao_usuario_login',
  'Confirmacao_status',
  'Confirmacao_central_id',
  'Confirmacao_central_data',
  'Confirmacao_envio_cockpit',
  'Acionamento_data',
  'Acionamento_data_confirmacao',
  'Acionamento_data_edicao',
  'Acionamento_plataforma_chamada',
  'Acionamento_cabine',
  'Cancelamento_usuario_id',
  'Cancelamento_usuario_email',
  'Cancelamento_usuario_nome',
  'Cancelamento_usuario_login',
  'Cancelamento_usuario_telefone',
  'Cancelamento_motivo',
  'Documento_remocao_email',
  'Documento_remocao_login',
  'Documento_remocao_nome',
  'Documento_remocao_telefone',
  'Identificação_external',
  'Identificação_usuário_adm',
  'Criação_passagem',
  'Identificação_vida',
  'Tempo_inicio',
  'Identificação_cliente',
];
