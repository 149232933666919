import { CloseIcon, WarningIcon, Check2CircleIcon } from './icons'

import * as S from './styles'

type Props = {
  message: string
  severity: string
  onClose: () => void
}

export function Alert ({ message, severity, onClose }: Props) {
  return (
    <S.Wrapper severity={severity}>
      <S.Icon>
        {severity === 'error' && <WarningIcon />}
        {severity === 'success' && <Check2CircleIcon />}
      </S.Icon>
      <S.Message>
        {message}
      </S.Message>
      <S.Icon onClick={onClose} style={{
        cursor: 'pointer'
      }}>
        <CloseIcon />
      </S.Icon>
    </S.Wrapper>
  )
}
