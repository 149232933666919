import React from 'react';
import MenuPainel from '../components/MenuPainel';
import { Fila } from '../components/Fila';
import { ChangePassword } from '../components/ChangePassword';
import PaperForm from '../components/PaperForm';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import PropTypes from 'prop-types';
import { alertActions } from '../actions';
//import ServiceQueuePage from '../components/Lib/ServiceQueuePage/';

class Adm extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }
  render() {
    const { message, type, open } = this.props;
    return (
      <React.Fragment>
        <ChangePassword />
        <MenuPainel style={{ width: '100%' }} stepper='Acionamentos'>
          <Alert
            open={open}
            type={type}
            message={message}
            onClose={() => this.handleClose(type, message)}
          />
          <PaperForm
            margin={'20px 0'}
            widthGrid={'90%'}
            width={'100%'}
            elevation={24}
            componente={<Fila />}
          ></PaperForm>
        </MenuPainel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { message, type, open } = state.alert;
  return {
    message,
    type,
    open,
  };
}

Adm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(Adm);
export { connectedRegisterPage as Adm };
