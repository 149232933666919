import React from 'react';
import { ContainerLoaderThree } from './styles';

export const LoaderThreePoints = () => {
  return (
    <ContainerLoaderThree>
      <span></span>
      <span></span>
      <span></span>
    </ContainerLoaderThree>
  );
};
