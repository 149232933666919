import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyle from '../styles/globalStyle';
import { CssBaseline } from '@material-ui/core';

import { ReCaptchaProvider } from '../reCaptcha';

import { ConnectedLoginPage } from '../LoginTs';

class Login extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GlobalStyle />
        <ReCaptchaProvider>
          <ConnectedLoginPage />
        </ReCaptchaProvider>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedAssinar = Login;
export { connectedAssinar as Login };
