import React from 'react'

import { CustomText } from '../../../../components/ui'

const BoxInfo = ({ label, value }) => (
  <>
    <CustomText size='large'>
      {label}
    </CustomText>

    <CustomText color='dark' title={1} size='large'>
      {value}
    </CustomText>
  </>
)

export default BoxInfo