import styled from 'styled-components';

export const StyledContainerObservation = styled.div`
  width: 100%;

  padding: ${({ hasPadding }) => (hasPadding ? '1rem 0.5rem' : 'unset')};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const StyledContainerAddButton = styled.div`
  position: relative;
  bottom: 10px;
`;

export const StyledContainerCard = styled.div`
  padding: 1em;
  width: 100%;
`;

export const StyledContainerOverflow = styled.div`
  max-height: 430px;
  overflow-y: auto;
`;

export const StyledContainerFull = styled.div`
  width: 100%;
`;

export const StyledContainerBorder = styled.div`
  border-top: 1px solid #e5e5e5;
`;

export const StyledContainerSaveButton = styled.div`
  padding-bottom: 20px;
`;
