import { useEffect, useState } from 'react'

import * as materialIcons from '@material-ui/icons'

const useIcon = (icon) => {
	const [UseIcon, setIcon] = useState(null)

	useEffect(() => {
		const hasIcon = !!materialIcons[icon]

		if (icon && hasIcon) {
			const Icon = materialIcons[icon]
			setIcon(Icon)
		}
	}, [icon])

	return {
		UseIcon
	}
}

export default useIcon
