import styled from 'styled-components';

const styles = {
  mounted: {
    animation: 'inAnimation 250ms ease-in',
  },
  unmounted: {
    animation: 'outAnimation 270ms ease-out',
    animationFillMode: 'forwards',
  },
};

export const StyledFadeIn = styled.div`
  width: 100%;
  display: flex;

  ${({ visible }) => (visible ? styles.mounted : styles.unmounted)};

  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
