import React from 'react';

import {
  CustomButton,
  CustomSnackbar,
  CustomDivider,
  CustomPopover,
  CustomText,
} from '../../../../../../../../../Spread/components/ui';

import { CustomInput } from '../../../../../../../../../Spread/components/ui/webform';

import {
  StyledContainerObservation,
  StyledContainerAddButton,
  StyledContainerCard,
  StyledContainerFull,
  StyledContainerBorder,
  StyledContainerOverflow,
  StyledContainerSaveButton,
} from './styles';

import usePatientObservations from './hooks/usePatientObservations';

const PatientObservations = ({ data, onSave }) => {
  const { snack, trigger } = CustomSnackbar();

  const {
    useInputNewObs,
    useObsInEdition,
    useObsData,
    handleChange,
    handleAdd,
    handleEdit,
    handleDelete,
    handleInputNewObsChange,
    saveChanges,
  } = usePatientObservations(data, onSave, trigger);

  return (
    <StyledContainerFull>
      <StyledContainerCard>
        <CustomDivider space={0} />
        <StyledContainerObservation>
          <CustomInput
            value={useInputNewObs}
            onChange={handleInputNewObsChange}
            disabled={typeof useObsInEdition === 'number'}
            placeholder='Preencha a observação'
            type='textarea'
            full
            style='outlined'
          />
          <StyledContainerAddButton>
            <CustomButton
              type='outlined'
              icon='Add'
              onClick={handleAdd}
              disabled={typeof useObsInEdition === 'number'}
            />
          </StyledContainerAddButton>
        </StyledContainerObservation>

        {snack}

        <StyledContainerOverflow>
          {useObsData.map((e, i) => (
            <StyledContainerBorder key={i}>
              <StyledContainerObservation hasPadding>
                {useObsInEdition === i ? (
                  <ObservationEditing
                    handleEditChange={handleChange}
                    onSave={saveChanges}
                    text={e}
                    i={i}
                  />
                ) : (
                  <ObservationDefault
                    handleEdit={() => handleEdit(i)}
                    handleDelete={handleDelete}
                    text={e}
                    i={i}
                  />
                )}
              </StyledContainerObservation>
            </StyledContainerBorder>
          ))}
        </StyledContainerOverflow>
      </StyledContainerCard>
    </StyledContainerFull>
  );
};

const ObservationDefault = ({ handleEdit, handleDelete, text, i }) => (
  <>
    <CustomText>{text}</CustomText>
    <CustomPopover align='right'>
      <CustomButton
        type='outlined'
        color='warning'
        icon='Edit'
        iconDir='l'
        onClick={() => handleEdit(i)}
      />
      <CustomDivider space={0} />
      <CustomButton
        color='danger'
        type='outlined'
        icon='Delete'
        iconDir='l'
        onClick={() => handleDelete(i)}
      />
    </CustomPopover>
  </>
);

const ObservationEditing = ({ handleEditChange, onSave, text, i }) => (
  <>
    <CustomInput
      full
      style='outlined'
      type='textarea'
      value={text}
      onChange={(e) => handleEditChange(e.target.value, i)}
    />
    <StyledContainerSaveButton>
      <CustomButton
        icon='Check'
        iconDir='l'
        type='outlined'
        text='Salvar'
        onClick={onSave}
      />
    </StyledContainerSaveButton>
  </>
);

export default PatientObservations;
