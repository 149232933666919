import { REQUEST_TIMEOUT } from '../constants';
import { userService } from './user.service';

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const agendamentoTPsicolService = {
  getSchedules,
  paginateAllAgendamentosTPisicol,
  createSlot,
  getFilteredAllScheduleTPsicol,
  getAlternativeSchedules,
  getTelepsicoProgram,
  getDatesByDoctor,
};

async function getSchedules(payload) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.token,
    },
    body: JSON.stringify({
      ...payload,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/schedule/list/telepsichology`,
      requestOptions,
      8000
    )
    .then(userService.handleResponse);
}

function paginateAllAgendamentosTPisicol(
  pageSize = 5,
  page = 1,
  queryObject = {}
) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify({
      query: queryObject,
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/schedule/telepsychology/${pageSize}/${page}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

async function createSlot(form) {
  const { token } = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ ...form.schedule }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/schedule/dispose`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}

function getFilteredAllScheduleTPsicol(fields, page, qtd) {
  const user = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.accessToken,
      _id: user.user._id,
    },
    body: JSON.stringify(fields),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/schedule/telepsychology/filtro/${page}/${qtd}`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getTelepsicoProgram() {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return userService
    .fetchWithTimeout(
      URL_CONECTA + '/servico/schedule/program',
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

function getAlternativeSchedules(dateStart, dateFinish, specialty, state = 2) {
  const { token } = JSON.parse(window.localStorage.user);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return userService
    .fetchWithTimeout(
      URL_CONECTA +
        '/servico/schedule/listSchedule?dateStart=' +
        dateStart +
        '&dateFinish=' +
        dateFinish +
        '&specialty=' +
        specialty +
        '&scheduleType=TELEPSICOLOGIA&state=' +
        state,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse);
}

//obtem datas para passar no componente de datas para pular as datas vindas aqui

async function getDatesByDoctor(doctorRefId, scheduleType = 'TELEPSICOLOGIA') {
  const { token } = JSON.parse(window.localStorage.user);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ doctorRefId, scheduleType }),
  };

  const getService = await userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/schedule/dates-by-doctor`,
      requestOptions,
      REQUEST_TIMEOUT
    )
    .then(userService.handleResponse)
    .catch((error) => error);
  return getService;
}
