import React from 'react';

import {
  Container,
  ContainerInitialsName,
  ContainerNames,
  SmallText,
  DivActions,
  IconContent,
} from './styles';

import { AiOutlineWarning } from 'react-icons/ai';

const Avatar = ({
  picture,
  name,
  avatarColor,
  socialName,
  onClick,
  iconDisabled,
  RelogioIcon,
  isWarning,
  UndoIcon,
  iconReturned,
}) => {
  const setAvatarColor = () => '#E3E6F6';

  if (avatarColor === undefined) avatarColor = setAvatarColor();

  const initials = (str) => {
    const strSplited = str.split(' ');

    if (strSplited.length > 1) {
      let index;
      if (strSplited[strSplited.length - 1] === '') index = 2;
      else index = 1;
      return strSplited[0][0] + '' + strSplited[strSplited.length - index][0];
    }
    return name[0];
  };

  return (
    <>
      <Container>
        {picture ? (
          <div style={{ marginLeft: '27px' }}>
            <img src={picture} alt={'Foto de perfil'} />
          </div>
        ) : (
          <ContainerInitialsName
            backgroundColor={avatarColor}
            className={'rubik-medium'}
          >
            {socialName || socialName?.length > 1
              ? initials(socialName)
              : name || name?.length > 1
              ? initials(name)
              : ''}
          </ContainerInitialsName>
        )}

        <span>
          <ContainerNames>
            <DivActions>
              {iconDisabled && <IconContent>{RelogioIcon}</IconContent>}
              {iconReturned && <IconContent>{UndoIcon}</IconContent>}
              {isWarning && (
                <IconContent>
                  <AiOutlineWarning className='iconWarning' />
                </IconContent>
              )}
            </DivActions>
            {socialName ? (
              <>
                <p className={'rubik-medium'} id='profileName'>
                  {socialName}
                </p>
                <SmallText>{name}</SmallText>
              </>
            ) : (
              <p className={'rubik-medium'} id='profileName'>
                {name}
              </p>
            )}
          </ContainerNames>
        </span>
      </Container>
    </>
  );
};

export default Avatar;
